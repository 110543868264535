import { TextField, MenuItem } from '@mui/material';

export default function BooleanSelectField({
  input: { value, onChange, ...input },
  meta,
  trueLabel,
  falseLabel,
  onChange: callback,
  ...props
}) {
  return (
    <TextField
      size="small"
      select
      helperText={meta.touched && meta.error}
      error={meta.touched && meta.invalid}
      value={value.toString()}
      onChange={(event) => {
        onChange(JSON.parse(event.target.value));
        if (callback) {
          callback(event.target.value);
        }
      }}
      {...input}
      {...props}
    >
      <MenuItem value="true">{trueLabel}</MenuItem>
      <MenuItem value="false">{falseLabel}</MenuItem>
    </TextField>
  );
}
