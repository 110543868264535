import { useDispatch, useSelector } from 'react-redux';
import {
  FETCH_PAGED_EVENTS,
  FETCH_PAGED_EVENTS_CANCELLED,
  UPDATE_EVENTS_FILTER,
  UPDATE_EVENTS_QUERY,
} from '../../actions';

export default function usePaging({ eventType, headers }) {
  const dispatch = useDispatch();
  const event = useSelector((state) => state.events[eventType]);

  const { filter, query, total } = event;

  const filterOptionsPaths = headers
    .filter((header) => header.filter)
    .map((header) => header.serverKey || header.key);

  function updateFilter(update) {
    // don't fetch if only updating selections
    const refetch = update.selectedIdentifiers === undefined;

    onFilterChange(
      {
        ...filter,
        selectedIdentifiers: [], // always reset selections unless
        ...update, // the update will set new selections
      },
      refetch
    );
  }

  function handleFetch(event, changedQuery, params = filter) {
    dispatch({
      type: FETCH_PAGED_EVENTS_CANCELLED,
      payload: {
        eventType,
      },
    });

    const combinedQuery = {
      ...query,
      ...changedQuery,
    };

    dispatch({
      type: FETCH_PAGED_EVENTS,
      payload: {
        eventType,
        query: combinedQuery,
        filterOptionsPaths,
        filters: params.fields,
        page: params.page,
        perPage: params.rowsPerPage,
        order: params.order,
        orderBy: params.orderBy,
        orderByServerKey: params.orderByServerKey,
      },
    });
  }

  function handleCancel() {
    dispatch({
      type: FETCH_PAGED_EVENTS_CANCELLED,
      payload: {
        eventType,
      },
    });
  }

  function onFilterChange(filter, refetch = true) {
    dispatch({
      type: UPDATE_EVENTS_FILTER,
      payload: {
        filter,
        eventType,
      },
    });

    // only fetch if there was previous one, otherwise a sort will trigger a fetch
    // without any time filters
    if (refetch && total !== undefined) {
      handleFetch(null, query, filter);
    }
  }

  function handlePageChange(event, page) {
    updateFilter({ page });
  }

  function handleRowsPerPageChange(event) {
    updateFilter({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  }

  function handleOrderChange(order) {
    updateFilter({ order });
  }

  function handleOrderByChange(orderBy) {
    // we'll be sorting on the field on the server so any calculated field
    // like "miles" should use the original "kilometres" field
    const toMatch = Array.isArray(orderBy) ? orderBy.join(',') : orderBy;
    const orderByServerKey =
      headers.find((h) => h.key === toMatch)?.serverKey ?? orderBy;

    updateFilter({
      orderBy,
      orderByServerKey,
      order: 'asc',
    });
  }

  function handleQueryChange(query) {
    dispatch({
      type: UPDATE_EVENTS_QUERY,
      payload: { eventType, query },
    });
  }

  return {
    updateFilter,
    onFilterChange,
    handlePageChange,
    handleRowsPerPageChange,
    handleOrderChange,
    handleOrderByChange,
    handleQueryChange,
    handleCancel,
    handleFetch,
  };
}
