import {
  FILTER_VEHICLE_DAILY_UTILISATION,
  FETCH_VEHICLE_DAILY_UTILISATION,
  FETCH_VEHICLE_DAILY_UTILISATION_SUCCESS,
  FETCH_VEHICLE_DAILY_UTILISATION_FAILURE,
  FETCH_VEHICLE_DAILY_UTILISATION_CANCELLED,
  LOAD_VEHICLE_DAILY_UTILISATION_QUERY_SUCCESS,
  LOAD_VEHICLE_DAILY_UTILISATION_QUERY_FAILURE,
  FILTER_VEHICLE_HOURLY_UTILISATION,
  FETCH_VEHICLE_HOURLY_UTILISATION,
  FETCH_VEHICLE_HOURLY_UTILISATION_SUCCESS,
  FETCH_VEHICLE_HOURLY_UTILISATION_FAILURE,
  FETCH_VEHICLE_HOURLY_UTILISATION_CANCELLED,
  LOAD_VEHICLE_HOURLY_UTILISATION_QUERY_SUCCESS,
  LOAD_VEHICLE_HOURLY_UTILISATION_QUERY_FAILURE,
} from '../actions';

const hours = [...Array(24).keys()];
const days = [...Array(7).keys()];

const INITIAL_STATE = {
  daily: {
    data: [],
    groupBy: 'all',
    orderBy: 'group',
    order: 'asc',
    classifyBy: 'activity',
    chartType:
      window.config.reports?.vehicles?.daily?.defaultChartType || 'hours',
    filter: {
      registrationNumber: [],
      fleetNumber: [],
      role: [],
      type: [],
      areas: {},
      hour: hours,
      day: days,
    },
    query: {},
    groupByValues: ['all', 'date'],
    filterValues: {
      registrationNumber: [],
      fleetNumber: [],
      role: [],
      type: [],
      areas: {},
      hour: hours,
      day: days,
    },
    isLoading: false,
    isFiltering: false,
    error: null,
  },
  hourly: {
    data: [],
    classifyBy: 'activity',
    chartType:
      window.config.reports?.vehicles?.hourly?.defaultChartType || 'minutes',
    filter: {
      registrationNumber: [],
      fleetNumber: [],
      role: [],
      type: [],
      areas: {},
      hour: hours,
      day: days,
    },
    filterValues: {
      registrationNumber: [],
      fleetNumber: [],
      role: [],
      type: [],
      areas: {},
      hour: hours,
      day: days,
    },
  },
  isLoading: false,
  isFiltering: false,
  error: null,
};

export default function utilisationReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_VEHICLE_DAILY_UTILISATION:
    case FILTER_VEHICLE_DAILY_UTILISATION:
      return {
        ...state,
        daily: {
          ...state.daily,
          isLoading: action.type === FETCH_VEHICLE_DAILY_UTILISATION,
          isFiltering: action.type === FILTER_VEHICLE_DAILY_UTILISATION,
          error: null,
        },
      };
    case FETCH_VEHICLE_DAILY_UTILISATION_SUCCESS:
    case LOAD_VEHICLE_DAILY_UTILISATION_QUERY_SUCCESS:
      return {
        ...state,
        daily: {
          ...state.daily,
          ...action.payload,
          isLoading: false,
          isFiltering: false,
          error: null,
        },
      };
    case FETCH_VEHICLE_DAILY_UTILISATION_FAILURE:
    case LOAD_VEHICLE_DAILY_UTILISATION_QUERY_FAILURE:
      return {
        ...state,
        daily: {
          ...state.daily,
          isLoading: false,
          isFiltering: false,
          error: action.payload,
        },
      };
    case FETCH_VEHICLE_DAILY_UTILISATION_CANCELLED:
      return {
        ...state,
        daily: {
          ...state.daily,
          isLoading: false,
          isFiltering: false,
        },
      };
    case FETCH_VEHICLE_HOURLY_UTILISATION:
    case FILTER_VEHICLE_HOURLY_UTILISATION:
      return {
        ...state,
        hourly: {
          ...state.hourly,
          usedUnusedUnavailableClassification:
            action.payload.usedUnusedUnavailableClassification,
          isLoading: action.type === FETCH_VEHICLE_HOURLY_UTILISATION,
          isFiltering: action.type === FILTER_VEHICLE_HOURLY_UTILISATION,
          error: null,
        },
      };
    case FETCH_VEHICLE_HOURLY_UTILISATION_SUCCESS:
    case LOAD_VEHICLE_HOURLY_UTILISATION_QUERY_SUCCESS:
      return {
        ...state,
        hourly: {
          ...state.hourly,
          ...action.payload,
          isLoading: false,
          isFiltering: false,
          error: null,
        },
      };
    case FETCH_VEHICLE_HOURLY_UTILISATION_FAILURE:
    case LOAD_VEHICLE_HOURLY_UTILISATION_QUERY_FAILURE:
      return {
        ...state,
        hourly: {
          ...state.hourly,
          isLoading: false,
          isFiltering: false,
          error: action.payload,
        },
      };
    case FETCH_VEHICLE_HOURLY_UTILISATION_CANCELLED:
      return {
        ...state,
        hourly: {
          ...state.hourly,
          isLoading: false,
          isFiltering: false,
        },
      };
    default:
      return state;
  }
}
