import {
  Avatar,
  Paper,
  CardContent,
  Collapse,
  Divider,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  ListSubheader,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  Stack,
} from '@mui/material';
import {
  green,
  indigo,
  orange,
  red,
  teal,
  purple,
  brown,
  cyan,
} from '@mui/material/colors';
import {
  FilterList as FilterListIcon,
  Sort as SortIcon,
  GetApp as GetAppIcon,
  BarChart as GroupByIcon,
} from '@mui/icons-material';
import _ from 'lodash';
import { Fragment, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import {
  Bar,
  BarChart,
  Brush,
  Label,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import moment from 'moment';
import { useSnackbar } from '../../Snackbar';
import {
  FETCH_PERSON_DAILY_ACTIVITY,
  FETCH_PERSON_DAILY_ACTIVITY_CANCELLED,
  FILTER_PERSON_DAILY_ACTIVITY,
  LOAD_PERSON_DAILY_ACTIVITY_QUERY,
} from '../../../actions';
import Container from '../../Container';
import {
  Parameters,
  SelectMultiple,
  TablePagination,
  Table,
  CustomTooltip,
} from '../../controls';
import { getKeyLabel, getTextWidth } from '../../../data/constants';
import { downloadCSV } from '../../../apis/utilities';
import { useEffectOnce, useDebounce } from '../../../hooks';
import { ToggleListField } from '../../fields';

const {
  useReducedResourceInformation,
  baseType,
  showDoubleCrews,
  rowsPerPageOptions,
} = window.config;

const hours = [...Array(24).keys()];
const days = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];

const bars = [
  { name: 'onRadio', colour: green[500] },
  { name: 'driving', colour: purple[500] },
  { name: `inBase`, colour: brown[500] },
  { name: 'inHomeWard', colour: orange[500] },
  { name: 'respondingToIncidents', colour: red[500] },
  { name: 'attendingObjectives', colour: teal[500] },
  ...(showDoubleCrews ? [{ name: 'doubleCrewing', colour: cyan[500] }] : []),
];

const measureTypes = ['total', 'average', 'daily'];
const chartTypes = ['hours', 'percentage'];

const labels = new Map([
  ['onRadio', 'On Radio'],
  ['driving', 'Driving'],
  ['inBase', `In ${baseType.label}`],
  ['inHomeWard', 'In Home Ward'],
  ['respondingToIncidents', 'Responding to Incidents'],
  ['attendingObjectives', 'Attending Objectives'],
  ['doubleCrewing', 'Double Crewing'],
  ['totalDrivingMileage', 'Total Driving Mileage'],
  ['averageDrivingMileage', 'Average Driving Mileage'],
  ['dailyDrivingMileage', 'Daily Driving '],
  ['totalBaseVisits', `Total ${baseType.label} Visits`],
  ['averageBaseVisits', `Average ${baseType.label} Visits`],
  ['dailyBaseVisits', `Daily ${baseType.label} Visits`],
  ['hours', 'Hours'],
  ['percentage', 'Percentage'],
]);

export default function DailyActivity(props) {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.activity.daily.data, _.isEqual);
  const { groupBy, orderBy, order, filter, chartType, query } = useSelector(
    (state) => state.activity.daily
  );
  const isLoading = useSelector((state) => state.activity.daily.isLoading);
  const isFiltering = useSelector((state) => state.activity.daily.isFiltering);
  const error = useSelector((state) => state.activity.daily.error);
  const groupByValues = useSelector(
    (state) => state.activity.daily.groupByValues,
    _.isEqual
  );
  const filterValues = useSelector(
    (state) => state.activity.daily.filterValues,
    _.isEqual
  );
  const orderByValues = useSelector(
    (state) => state.activity.daily.orderByValues,
    _.isEqual
  );
  const [showFilter, setShowFilter] = useState(false);
  const [hiddenBars, setHiddenBars] = useState([]);
  const [sortMenuAnchor, setSortMenuAnchor] = useState(null);
  const [viewMenuAnchor, setViewMenuAnchor] = useState(null);
  const [mileageType, setMileageType] = useState('totalDrivingMileage');
  const [baseVisitCountType, setBaseVisitCountType] =
    useState('totalBaseVisits');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const [filterParams, setFilterParams] = useState({
    query,
    filter,
    chartType,
  });
  const debouncedFilterParams = useDebounce(filterParams, 1000);
  const snackbar = useSnackbar();

  useEffect(() => {
    dispatch({
      type: FILTER_PERSON_DAILY_ACTIVITY,
      payload: debouncedFilterParams,
    });
  }, [dispatch, debouncedFilterParams]);

  function formatGroup(value) {
    switch (groupBy) {
      case 'date':
        return moment(value).format('DD/MM/YYYY');
      case 'month':
        return moment(value).format('MMM YYYY');
      default:
        return value;
    }
  }

  const headers = [
    {
      label: `${getKeyLabel(groupBy)} (Count)`,
      key: 'group',
      type: 'text',
    },
    {
      label: 'On Radio (hours)',
      key: 'onRadio',
      type: 'number',
    },
    {
      label: `In ${baseType.label} (hours)`,
      key: `inBase`,
      type: 'number',
    },
    {
      label: 'Responding to Incidents (hours)',
      key: 'respondingToIncidents',
      type: 'number',
    },
    {
      label: 'Attending Objectives (hours)',
      key: 'attendingObjectives',
      type: 'number',
    },
    {
      label: 'Driving (hours)',
      key: 'driving',
      type: 'number',
    },
    {
      label: 'In Home Ward (hours)',
      key: 'inHomeWard',
      type: 'number',
    },
    {
      label: 'Double Crewing (hours)',
      key: 'doubleCrewing',
      type: 'number',
    },
    {
      label: 'Total Driving Mileage',
      key: 'totalDrivingMileage',
      type: 'number',
    },
    {
      label: 'Average Driving Mileage',
      key: 'averageDrivingMileage',
      type: 'number',
    },
    {
      label: 'Daily Driving Mileage',
      key: 'dailyDrivingMileage',
      type: 'number',
    },
    {
      label: `Total ${baseType.label} Visits`,
      key: `totalBaseVisits`,
      type: 'number',
    },
    {
      label: `Average ${baseType.label} Visits`,
      key: `averageBaseVisits`,
      type: 'number',
    },
    {
      label: `Daily ${baseType.label} Visits`,
      key: `dailyBaseVisits`,
      type: 'number',
    },
  ];

  useEffect(() => {
    if (error) {
      snackbar.notify('error', error);
    }
  }, [error, snackbar]);

  useEffectOnce(() => {
    dispatch({
      type: LOAD_PERSON_DAILY_ACTIVITY_QUERY,
      // payload: { filter, groupBy },
    });
  }, [filter, groupBy, dispatch]);

  function handleFilterToggle() {
    setShowFilter(!showFilter);
  }

  function handleFetch(event, query) {
    dispatch({
      type: FETCH_PERSON_DAILY_ACTIVITY,
      payload: {
        query,
        filter,
        groupBy,
        orderBy,
        order,
        chartType,
      },
    });
  }

  function handleCancel() {
    dispatch({
      type: FETCH_PERSON_DAILY_ACTIVITY_CANCELLED,
    });
  }

  function handleGroupByFieldChanged(groupBy) {
    updateTransformOptions({ groupBy });
    setViewMenuAnchor(null);
  }

  function handlePercentOrHoursClick(value) {
    updateTransformOptions({ chartType: value });
    setViewMenuAnchor(null);
  }

  function handleFilterFieldChanged(name, value) {
    const newFilter =
      name in filterParams.filter
        ? {
            ...filterParams.filter,
            [name]: value || [],
          }
        : {
            ...filterParams.filter,
            areas: {
              ...filterParams.filter.areas,
              [name]: value || [],
            },
          };

    updateTransformOptions({ filter: newFilter });
  }

  function handleMileageTypeFieldChanged(mileageType) {
    setMileageType(mileageType);
  }

  function handleBaseVisitCountTypeFieldChanged(baseVisitCountType) {
    setBaseVisitCountType(baseVisitCountType);
  }

  function handleLegendClick(selectedBar) {
    const index = hiddenBars.indexOf(selectedBar);

    if (index === -1) {
      setHiddenBars(hiddenBars.concat(selectedBar));
    } else {
      setHiddenBars(
        hiddenBars.slice(0, index).concat(hiddenBars.slice(index + 1))
      );
    }
  }

  function handleGroupMenuOpen(target) {
    setViewMenuAnchor(target);
  }

  function handleGroupMenuClose() {
    setViewMenuAnchor(null);
  }

  function handleSortMenuOpen(target) {
    setSortMenuAnchor(target);
  }

  function handleSortMenuClose() {
    setSortMenuAnchor(null);
  }

  function updateTransformOptions(optionChanges) {
    setFilterParams({
      query,
      filter,
      orderBy,
      order,
      groupBy,
      chartType,
      ...optionChanges,
    });
  }

  function handleSortItemClick(newOrderBy) {
    // tell the epic how does it feel about changing the sort order
    let newOrder = order;
    if (newOrderBy === orderBy) {
      newOrder = newOrder === 'asc' ? 'desc' : 'asc';
    }

    updateTransformOptions({ orderBy: newOrderBy, order: newOrder });
    setSortMenuAnchor(null);
  }

  function handlePageChange(event, newPage) {
    setPage(newPage);
  }

  function handleRowsPerPageChange(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  function handleOrderChange(order) {
    updateTransformOptions({ order });
  }

  function handleOrderByChange(orderBy) {
    updateTransformOptions({ orderBy, order: 'asc' });
  }

  async function handleDownloadClick() {
    const filename = 'Person Daily Activity.csv';

    downloadCSV(data, filename, headers);
  }

  const xAxisHeight =
    data.length === 0
      ? 0
      : getTextWidth(
          data.map((item) => item.group).sort((a, b) => b.length - a.length)[0],
          '12px Roboto'
        ) + 16;

  return (
    <Container title="Daily Activity">
      {isFiltering && (
        <LinearProgress
          sx={{ position: 'absolute', width: 1, height: 8 }}
          color="secondary"
        />
      )}
      <Parameters
        onFetch={handleFetch}
        onCancel={handleCancel}
        isFetching={isLoading && !isFiltering}
        value={query}
        sx={{ mt: 1, width: 264 }}
        pointEvent
        dateOnly
        utc
        person
      />
      <Box
        sx={{
          width: 1,
          height: 'calc(100vh - 48px)',
          overflowY: 'auto',
          overflowX: 'hidden',
          '& > :last-child': {
            mb: 1,
          },
        }}
      >
        <Helmet>
          <title>IR3 | Daily Activity</title>
        </Helmet>
        <Toolbar variant="dense" disableGutters sx={{ p: 1, pb: 0 }}>
          <Typography variant="subtitle1">Daily Activity</Typography>
          {/* <TextField
            size="small"
            select
            className={classes.groupByField}
            name="groupBy"
            value={groupBy}
            onChange={(event) => handleGroupByFieldChanged(event.target.value)}
          >
            <MenuItem value="all">All</MenuItem>
            {Object.values(groupByValues).map((value) => (
              <MenuItem key={value} value={value}>
                {getKeyLabel(value)}
              </MenuItem>
            ))}
          </TextField> */}
          <Box sx={{ flexGrow: 1 }} />
          <IconButton
            title={showFilter ? 'Hide filter' : 'Show filter'}
            onClick={() => handleFilterToggle()}
          >
            <FilterListIcon color={showFilter ? 'primary' : 'inherit'} />
          </IconButton>
          <IconButton
            title="Change view"
            aria-owns={viewMenuAnchor ? 'date-menu' : undefined}
            aria-haspopup="true"
            onClick={(event) => handleGroupMenuOpen(event.target)}
          >
            <GroupByIcon />
          </IconButton>
          <Menu
            anchorEl={viewMenuAnchor}
            open={Boolean(viewMenuAnchor)}
            onClose={() => handleGroupMenuClose()}
          >
            <ListSubheader disableSticky>Group by</ListSubheader>
            {Object.values(groupByValues).map((value) => (
              <MenuItem
                key={value}
                value={value}
                selected={value === groupBy}
                onClick={() => handleGroupByFieldChanged(value)}
              >
                {getKeyLabel(value)}
              </MenuItem>
            ))}
            <ListSubheader disableSticky>View as</ListSubheader>
            {chartTypes.map((value) => (
              <MenuItem
                key={value}
                value={value}
                selected={value === chartType}
                onClick={() => handlePercentOrHoursClick(value)}
              >
                {labels.get(value)}
              </MenuItem>
            ))}
          </Menu>
          <IconButton
            title="Sort by"
            aria-owns={sortMenuAnchor ? 'date-menu' : undefined}
            aria-haspopup="true"
            onClick={(event) => handleSortMenuOpen(event.target)}
          >
            <SortIcon />
          </IconButton>
          <Menu
            anchorEl={sortMenuAnchor}
            open={Boolean(sortMenuAnchor)}
            onClose={() => handleSortMenuClose()}
          >
            <ListSubheader disableSticky>Sort by</ListSubheader>

            {groupBy !== 'all' && (
              <MenuItem
                size="small"
                key={orderBy}
                selected={orderBy === 'group'}
                onClick={() => handleSortItemClick('group')}
              >
                {getKeyLabel(groupBy)}
              </MenuItem>
            )}
            <Divider />
            {orderByValues
              .concat([mileageType, baseVisitCountType])
              .map((key) => (
                <MenuItem
                  key={key}
                  onClick={() => handleSortItemClick(key)}
                  selected={key === orderBy}
                >
                  {labels.get(key)}
                </MenuItem>
              ))}
          </Menu>
          <IconButton
            title="Download data"
            disabled={data.length === 0}
            onClick={handleDownloadClick}
            size="large"
          >
            <GetAppIcon />
          </IconButton>
        </Toolbar>
        <Collapse in={showFilter} timeout="auto">
          <Stack spacing={1.5} sx={{ flex: 1, p: 1.5 }}>
            {useReducedResourceInformation ? (
              <SelectMultiple
                disabled={isFiltering}
                label="Staff ID"
                placeholder="Select..."
                value={filterParams.filter.code}
                onChange={(value) => handleFilterFieldChanged('code', value)}
                suggestions={filterValues.code.map((value) => ({
                  label: value,
                  value,
                }))}
                labelValue
              />
            ) : (
              <Fragment>
                <SelectMultiple
                  disabled={isFiltering}
                  label="Name"
                  placeholder="Select..."
                  value={filterParams.filter.name}
                  labelValue
                  onChange={(value) => handleFilterFieldChanged('name', value)}
                  suggestions={filterValues.name.map((value) => ({
                    label: value,
                    value,
                  }))}
                />
                <SelectMultiple
                  disabled={isFiltering}
                  label="Role"
                  placeholder="Select..."
                  value={filterParams.filter.role}
                  labelValue
                  onChange={(value) => handleFilterFieldChanged('role', value)}
                  suggestions={filterValues.role.map((value) => ({
                    label: value,
                    value,
                  }))}
                />
              </Fragment>
            )}
            {Object.entries(filterValues.areas).map((entry) => {
              return (
                <SelectMultiple
                  disabled={isFiltering}
                  key={entry[0]}
                  label={_.startCase(entry[0])}
                  placeholder="Select..."
                  value={filterParams.filter.areas[entry[0]] || []}
                  labelValue
                  onChange={(value) =>
                    handleFilterFieldChanged(entry[0], value)
                  }
                  suggestions={entry[1].map((value) => ({
                    label: value,
                    value,
                  }))}
                />
              );
            })}
            <ToggleListField
              disabled={isFiltering}
              input={{
                onChange: (value) => handleFilterFieldChanged('hour', value),
                value: filterParams.filter.hour,
              }}
              values={hours}
              label={'Hours'}
            />
            <ToggleListField
              disabled={isFiltering}
              input={{
                onChange: (value) => handleFilterFieldChanged('day', value),
                value: filterParams.filter.day,
              }}
              values={days}
              label={'Days'}
            />
          </Stack>
        </Collapse>
        <Paper sx={{ m: [0, 1], minWidth: 240, fontSize: 12 }}>
          <CardContent sx={{ p: 0, pt: 4 }}>
            <Box
              sx={{
                pl: 8,
                pr: 2,
                pb: 1,
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
              }}
            >
              {bars.map((bar) => (
                <Box
                  key={bar.name}
                  sx={{
                    p: 0.5,
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleLegendClick(bar.name)}
                >
                  <Avatar
                    sx={{
                      width: 12,
                      height: 12,
                      mr: 0.5,
                      bgcolor: !hiddenBars.includes(bar.name) && bar.colour,
                    }}
                  >
                    <Fragment />
                  </Avatar>
                  <Typography variant="caption">
                    {labels.get(bar.name)}
                  </Typography>
                </Box>
              ))}
            </Box>
            <ResponsiveContainer width="99%" height={520}>
              <BarChart
                data={data}
                margin={{ top: 0, right: 16, left: 0, bottom: 32 }}
                barGap={0}
                barCategoryGap={16}
              >
                <XAxis
                  dataKey="group"
                  tickFormatter={formatGroup}
                  angle={-90}
                  textAnchor="end"
                  interval={0}
                  height={xAxisHeight}
                >
                  <Label
                    value={getKeyLabel(groupBy)}
                    offset={36}
                    position="bottom"
                  />
                </XAxis>
                <YAxis>
                  <Label
                    value={chartType === 'percentage' ? 'Percentage' : 'Hours'}
                    angle={-90}
                    position="left"
                    offset={-24}
                  />
                </YAxis>
                <Tooltip
                  cursor={data.length > 0}
                  content={
                    <CustomTooltip
                      unit={chartType === 'percentage' ? '%' : 'hours'}
                      labelFormatter={formatGroup}
                    />
                  }
                />
                {data.length > 0 && <Brush dataKey="Date" height={24} />}
                {bars.map((bar) => (
                  <Bar
                    key={bar.name}
                    dataKey={bar.name}
                    name={labels.get(bar.name)}
                    fill={bar.colour}
                    hide={hiddenBars.includes(bar.name)}
                  />
                ))}
              </BarChart>
            </ResponsiveContainer>
          </CardContent>
        </Paper>
        <Toolbar sx={{ display: 'flex', justifyContent: 'center' }}>
          <FormControl component="fieldset">
            <RadioGroup
              row
              aria-label="mileage chart type"
              name="mileage chart type"
              value={mileageType}
              onChange={(e) => handleMileageTypeFieldChanged(e.target.value)}
            >
              {measureTypes.map((measureType) => (
                <FormControlLabel
                  key={measureType}
                  value={`${measureType}DrivingMileage`}
                  control={<Radio />}
                  label={labels.get(`${measureType}DrivingMileage`)}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Toolbar>
        <Paper sx={{ m: [0, 1], minWidth: 240, fontSize: 12 }}>
          <CardContent sx={{ p: 0, pt: 4 }}>
            <ResponsiveContainer width="99%" height={520}>
              <BarChart
                data={data}
                margin={{ top: 0, right: 16, left: 8, bottom: 32 }}
                barGap={0}
                barCategoryGap={4}
              >
                <XAxis
                  dataKey="group"
                  tickFormatter={formatGroup}
                  angle={-90}
                  textAnchor="end"
                  interval={0}
                  height={xAxisHeight}
                >
                  <Label
                    value={getKeyLabel(groupBy)}
                    offset={36}
                    position="bottom"
                  />
                </XAxis>
                <YAxis>
                  <Label
                    value="Miles"
                    angle={-90}
                    position="left"
                    offset={-16}
                  />
                </YAxis>
                <Tooltip
                  cursor={data.length > 0}
                  content={<CustomTooltip labelFormatter={formatGroup} />}
                />
                {data.length > 0 && <Brush dataKey="Name" height={24} />}
                {measureTypes.map((measureType) => (
                  <Bar
                    key={measureType}
                    dataKey={`${measureType}DrivingMileage`}
                    name={labels.get(`${measureType}DrivingMileage`)}
                    fill={indigo[800]}
                    hide={mileageType !== `${measureType}DrivingMileage`}
                  />
                ))}
              </BarChart>
            </ResponsiveContainer>
          </CardContent>
        </Paper>
        <Toolbar sx={{ display: 'flex', justifyContent: 'center' }}>
          <FormControl component="fieldset">
            <RadioGroup
              row
              aria-label="trip chart type"
              name="trip chart type"
              value={baseVisitCountType}
              onChange={(e) =>
                handleBaseVisitCountTypeFieldChanged(e.target.value)
              }
            >
              {measureTypes.map((measureType) => (
                <FormControlLabel
                  key={measureType}
                  value={`${measureType}BaseVisits`}
                  control={<Radio />}
                  label={labels.get(`${measureType}BaseVisits`)}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Toolbar>
        <Paper sx={{ m: [0, 1], minWidth: 240, fontSize: 12 }}>
          {/* <CardHeader subheader="Total by Name" /> */}
          <CardContent sx={{ p: 0, pt: 4 }}>
            <ResponsiveContainer width="99%" height={520}>
              <BarChart
                data={data}
                margin={{ top: 0, right: 16, left: 0, bottom: 32 }}
                barCategoryGap={4}
              >
                <XAxis
                  dataKey="group"
                  angle={-90}
                  textAnchor="end"
                  interval={0}
                  height={xAxisHeight}
                >
                  <Label
                    value={getKeyLabel(groupBy)}
                    offset={36}
                    position="bottom"
                  />
                </XAxis>
                <YAxis>
                  <Label
                    value="Visits"
                    angle={-90}
                    position="left"
                    offset={-24}
                  />
                </YAxis>
                <Tooltip
                  cursor={data.length > 0}
                  content={<CustomTooltip labelFormatter={formatGroup} />}
                />
                {data.length > 0 && <Brush dataKey="Name" height={24} />}
                {measureTypes.map((measureType) => (
                  <Bar
                    key={measureType}
                    dataKey={`${measureType}BaseVisits`}
                    name={labels.get(`${measureType}BaseVisits`)}
                    fill={indigo[800]}
                    hide={baseVisitCountType !== `${measureType}BaseVisits`}
                  />
                ))}
              </BarChart>
            </ResponsiveContainer>
          </CardContent>
        </Paper>
        <Toolbar variant="dense" disableGutters sx={{ p: 1, pb: 0 }}>
          <Typography variant="subtitle1">Daily Activity</Typography>
        </Toolbar>
        <Paper sx={{ m: [0, 1], minWidth: 240, fontSize: 12 }}>
          <Table
            styles={{
              tableContainer: {
                height: 'calc(100vh - 164px)',
                overflowY: 'scroll',
              },
              table: {
                minWidth: 750,
              },
            }}
            data={data}
            headers={headers}
            rowsPerPage={rowsPerPage}
            page={page}
            order={order}
            orderBy={orderBy}
            onOrderChange={handleOrderChange}
            onOrderByChange={handleOrderByChange}
          />
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </Paper>
      </Box>
    </Container>
  );
}
