import { Box, IconButton, Paper, Toolbar, Typography } from '@mui/material';
import { PlayArrow as PlayArrowIcon } from '@mui/icons-material';
import _ from 'lodash';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  getVehiclePolls,
  shortVehicleHeaders,
  telematicsBoxPollHeaders,
} from '../../apis/utilities';
import Container from '../Container';
import {
  ExportPicker,
  FilterPicker,
  Parameters,
  TablePagination,
  Table,
} from '../controls';
import { useSnackbar } from '../Snackbar';
import ChunkedDownloader from './ChunkedDownloader';
import usePaging from './usePaging';

const {
  rowsPerPageOptions,
  events: {
    eventFilters: { vehicleLocationVisits: eventFilters = [] } = {},
  } = {},
} = window.config;
const { extraVehicleLocationVisitHeaders } = window.reportHeaders;

const headers = [
  ...extraVehicleLocationVisitHeaders,
  { label: 'Location Type', key: 'location.type', type: 'text', filter: true },
  {
    label: 'Location Subtype',
    key: 'location.subtype',
    type: 'text',
    filter: true,
  },
  { label: 'Location Name', key: 'location.name', type: 'text', filter: true },
  { label: 'Start Time', key: 'startTime', type: 'date', filter: false },
  { label: 'End Time', key: 'endTime', type: 'date', filter: false },
  {
    label: 'Duration (minutes)',
    key: 'durationMinutes',
    serverKey: 'durationSeconds',
    type: 'number',
    filter: false,
  },
  {
    label: 'Distance (miles)',
    key: 'distanceMiles',
    serverKey: 'distanceKilometres',
    type: 'number',
    filter: false,
  },
];

function DownloadVehiclePollsLink({ entry }) {
  async function fetchDataToConvert() {
    const polls = await getVehiclePolls(
      entry.vehicle.telematicsBoxImei,
      entry.startTime,
      entry.endTime
    );
    const data = polls.map((poll) => ({
      ...poll,
      identificationNumber: entry.vehicle.identificationNumber,
      registrationNumber: entry.vehicle.registrationNumber,
      fleetNumber: entry.vehicle.fleetNumber,
      time: new Date(poll.time),
      vehicleRole: entry.vehicle.role,
      longitude: poll.position ? poll.position.coordinates[0] : 0,
      latitude: poll.position ? poll.position.coordinates[1] : 0,
    }));

    return data;
  }

  return (
    <ExportPicker
      title="download"
      fetchDataToConvert={fetchDataToConvert}
      filename="Vehicle Location Visits"
      headers={[...shortVehicleHeaders, ...telematicsBoxPollHeaders]}
    />
  );
}

function ReplayLink({ entry }) {
  const navigate = useNavigate();

  const handleViewClick = (identifier) => () => {
    navigate(`/replay/intersections/${identifier}`);
  };

  return (
    <IconButton
      title="View"
      size="small"
      aria-label="View"
      onClick={handleViewClick(entry.identifier)}
    >
      <PlayArrowIcon />
    </IconButton>
  );
}

const replayColumn = {
  label: '',
  key: 'replay',
  type: 'component',
  component: ReplayLink,
};

const downloadColumn = {
  label: '',
  key: 'download',
  type: 'component',
  component: DownloadVehiclePollsLink,
};

export default function VehicleLocationVisits() {
  const total = useSelector(
    (state) => state.events.vehicleLocationVisits.total
  );
  const totals = useSelector(
    (state) => state.events.vehicleLocationVisits.totals
  );
  const visits = useSelector(
    (state) => state.events.vehicleLocationVisits.list,
    _.isEqual
  );
  const isLoading = useSelector(
    (state) => state.events.vehicleLocationVisits.isLoading
  );
  const error = useSelector(
    (state) => state.events.vehicleLocationVisits.error
  );
  const filter = useSelector(
    (state) => state.events.vehicleLocationVisits.filter,
    _.isEqual
  );
  const query = useSelector(
    (state) => state.events.vehicleLocationVisits.query,
    _.isEqual
  );
  const snackbar = useSnackbar();
  const eventType = 'vehicleLocationVisits';
  const paging = usePaging({ eventType, headers });

  useEffect(() => {
    if (error) {
      snackbar.notify('error', error);
    }
  }, [error, snackbar]);

  // const filteredVisits = filterLocally(filter, visits);

  const filename = 'Vehicle Location Visits.csv';
  const csvMapping = (visit) => ({
    ...visit,
    startTime: new Date(visit.startTime),
    endTime: new Date(visit.endTime),
  });

  return (
    <Container title="Vehicle Location Visits">
      <Parameters
        onFetch={paging.handleFetch}
        onCancel={paging.handleCancel}
        isFetching={isLoading}
        value={query}
        onChange={paging.handleQueryChange}
        sx={{ mt: 1, width: 264 }}
        vehicle
        location
        eventFilters={eventFilters}
      />
      <Box
        sx={{
          flex: 1,
          height: 'calc(100vh - 48px)',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <Helmet>
          <title>IR3 | Vehicle Location Visits</title>
        </Helmet>
        <Toolbar variant="dense" disableGutters sx={{ p: 1, pb: 0 }}>
          <Typography sx={{ flexGrow: 1 }} variant="subtitle1">
            Vehicle Location Visits
          </Typography>
          <FilterPicker
            headers={headers}
            data={visits}
            filter={filter}
            filterValues={filter.filterValues}
            onFilterChange={paging.onFilterChange}
          />
          <ChunkedDownloader
            eventType={eventType}
            filename={filename}
            headers={headers}
            csvMapping={csvMapping}
          />
        </Toolbar>
        <Paper sx={{ m: [0, 1, 1], minWidth: 240 }}>
          <Box sx={isLoading ? { opacity: 0.5 } : undefined}>
            <Table
              styles={{
                tableContainer: {
                  height: 'calc(100vh - 172px)',
                  overflowY: 'scroll',
                },
                table: {
                  minWidth: 750,
                },
              }}
              data={visits}
              headers={headers.concat(replayColumn).concat(downloadColumn)}
              rowsPerPage={filter.rowsPerPage}
              page={0}
              order={filter.order}
              orderBy={filter.orderBy}
              onOrderChange={paging.handleOrderChange}
              onOrderByChange={paging.handleOrderByChange}
              totals={totals}
            />
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              component="div"
              count={total ?? 0}
              rowsPerPage={filter.rowsPerPage}
              page={filter.page}
              onPageChange={paging.handlePageChange}
              onRowsPerPageChange={paging.handleRowsPerPageChange}
            />
          </Box>
        </Paper>
      </Box>
    </Container>
  );
}
