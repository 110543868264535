import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import {
  Flag as FlagIcon,
  Person as PersonIcon,
  OutlinedFlag as OutlinedFlagIcon,
  PlayArrow as PlayArrowIcon,
} from '@mui/icons-material';
import { Helmet } from 'react-helmet-async';
import moment from 'moment';
import _ from 'lodash';
import humanizeDuration from 'humanize-duration';
import { RetrospectiveTypeIcon } from '../../../data/constants';
import { TagControl, ExportPicker } from '../../controls';
import { getVehiclePolls, vehiclePollHeaders } from '../../../apis/utilities';

const { useReducedResourceInformation } = window.config;

export default function VehicleTrip({
  color,
  background,
  identifier,
  driver,
  vehicle,
  startTime,
  endTime,
  maxSpeedKilometresPerHour,
  distanceKilometres,
}) {
  const navigate = useNavigate();

  function handleViewClick() {
    navigate(`/replay/trips/${identifier}`);
  }

  async function fetchDataToConvert() {
    const polls = await getVehiclePolls(
      vehicle.telematicsBoxImei,
      startTime,
      endTime
    );

    const data = polls.map((poll) => ({
      ...poll,
      ...(useReducedResourceInformation
        ? { staffId: driver.code, type: vehicle.type }
        : {
            name: driver.forenames + ' ' + driver.surname,
            personRole: driver.role,
            collarNumber: driver.collarNumber,
          }),
      identificationNumber: vehicle.identificationNumber,
      registrationNumber: vehicle.registrationNumber,
      fleetNumber: vehicle.fleetNumber,
      vehicleRole: vehicle.role,
      time: new Date(poll.time),
      speedLimitMilesPerHour: poll.speedLimitMilesPerHour,
    }));

    return data;
  }

  const cardHeaderButtons = (
    <Box>
      <IconButton
        aria-label="replay"
        title="View Replay"
        onClick={handleViewClick}
        size="large"
      >
        <PlayArrowIcon />
      </IconButton>
      <ExportPicker
        title="Download Vehicle Trip"
        fetchDataToConvert={fetchDataToConvert}
        filename="Vehicle Trip"
        headers={vehiclePollHeaders}
      />
    </Box>
  );

  return (
    <Card sx={{ m: 1, mr: 0 }}>
      <Helmet>
        <title>
          IR3 | Trip |{' '}
          {useReducedResourceInformation
            ? vehicle.fleetNumber
            : vehicle.registrationNumber}
        </title>
      </Helmet>
      <CardHeader
        avatar={
          <Avatar
            sx={{
              background,
              color,
            }}
            title="Vehicle Trip"
          >
            <RetrospectiveTypeIcon type="vehicleTrips" />
          </Avatar>
        }
        title={
          useReducedResourceInformation
            ? vehicle.fleetNumber
            : vehicle.registrationNumber
        }
        subheader={
          useReducedResourceInformation
            ? vehicle.identificationNumber
            : vehicle.fleetNumber
        }
        action={cardHeaderButtons}
      />
      <CardContent>
        <TagControl
          item={{ id: identifier }}
          type={'trips'}
          sx={{ pb: 2 }}
          label="Tags"
        />
        <Typography variant="subtitle2" color="textSecondary">
          Vehicle
        </Typography>
        <Table size="small" sx={{ mt: 1, mb: 2 }}>
          <TableBody>
            {!useReducedResourceInformation && (
              <TableRow>
                <TableCell>
                  <Typography variant="caption" color="textSecondary">
                    Role
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="caption">{vehicle.role}</Typography>
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell>
                <Typography variant="caption" color="textSecondary">
                  {useReducedResourceInformation
                    ? 'Home Location'
                    : 'Home Station'}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">{vehicle.homeStation}</Typography>
              </TableCell>
            </TableRow>
            {(vehicle.areas || []).map((area, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Typography variant="caption" color="textSecondary">
                    {_.startCase(area.type)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="caption">{area.name}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Fragment>
          <Typography variant="subtitle2" color="textSecondary">
            Driver
          </Typography>
          {driver?.code ? (
            <ListItem component="div" sx={{ my: 1 }}>
              <ListItemAvatar>
                <Avatar>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  useReducedResourceInformation
                    ? driver.code
                    : `${driver.forenames || ''}  ${driver.surname || ''}`
                }
                secondary={driver.collarNumber}
              />
            </ListItem>
          ) : (
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Typography variant="caption" color="textSecondary">
                      {driver?.identificationReference
                        ? 'Unrecognised Key'
                        : 'Unknown Driver'}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {driver?.identificationReference && (
                      <Typography variant="caption">
                        {driver.identificationReference}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}
        </Fragment>
        <Typography variant="subtitle2" color="textSecondary">
          Timeline
        </Typography>
        <Stepper orientation="vertical">
          <Step active>
            <StepLabel
              icon={
                <Avatar sx={{ width: 30, height: 30 }}>
                  <FlagIcon fontSize="inherit" />
                </Avatar>
              }
              optional={
                <Typography variant="caption">
                  {moment(startTime).format('DD/MM/YYYY, HH:mm:ss')}
                </Typography>
              }
            >
              Start
            </StepLabel>
            <StepContent />
          </Step>
          <Step active>
            <StepLabel
              icon={
                <Avatar sx={{ width: 30, height: 30 }}>
                  <OutlinedFlagIcon fontSize="inherit" />
                </Avatar>
              }
              optional={
                <Typography variant="caption">
                  {moment(endTime).format('DD/MM/YYYY, HH:mm:ss')}
                </Typography>
              }
            >
              End
            </StepLabel>
            <StepContent />
          </Step>
        </Stepper>
        <Typography variant="subtitle2" color="textSecondary">
          Metrics
        </Typography>
        <Table size="small" sx={{ mt: 1, mb: 2 }}>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography variant="caption" color="textSecondary">
                  Distance
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">
                  {`${_.round(distanceKilometres * 0.62137119, 2)} miles`}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="caption" color="textSecondary">
                  Maximum Speed
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">
                  {`${_.round(maxSpeedKilometresPerHour * 0.62137119, 2)} MPH`}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="caption" color="textSecondary">
                  Duration
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">
                  {humanizeDuration(moment(endTime).diff(moment(startTime)))}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}
