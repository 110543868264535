import {
  TextField,
  SelectField,
  CheckboxField,
  StyledField,
} from '../../fields';
import { Fragment } from 'react';

export default function CrimeParameters({ name, filters }) {
  return (
    <Fragment>
      <StyledField
        name={`${name}.number`}
        component={TextField}
        label="Number"
        type="text"
      />
      <StyledField
        name={`${name}.type^code`}
        component={SelectField}
        label="Type"
        values={(filters.types || []).map((item) => {
          return { value: item.code, label: item.name };
        })}
      />
      <StyledField
        name={`${name}.dispatchType^code`}
        component={SelectField}
        label="Dispatch Type"
        values={(filters.dispatchTypes || []).map((item) => {
          return { value: item.code, label: item.name };
        })}
      />
      <StyledField
        name={`${name}.classifications^code`}
        component={SelectField}
        label="Classification"
        values={(filters.classifications || []).map((item) => {
          return { value: item.code, label: item.name };
        })}
      />
      <StyledField
        name={`${name}.status`}
        component={SelectField}
        label="Status"
        values={(filters.statuses || []).map((item) => {
          return { value: item.code, label: item.name };
        })}
      />
      <StyledField
        name={`${name}.areas^bcu^name`}
        component={SelectField}
        label="BCU"
        values={(filters.bcus || []).map((item) => {
          return { value: item.name, label: item.name };
        })}
      />
      <StyledField
        name={`${name}.areas^localAuthority^name`}
        component={SelectField}
        label="Local Authority"
        values={(filters.localAuthorities || []).map((item) => {
          return { value: item.name, label: item.name };
        })}
      />
      <StyledField
        name={`${name}.areas^section^name`}
        component={SelectField}
        label="Section"
        values={(filters.sections || []).map((item) => {
          return { value: item.name, label: item.name };
        })}
      />
      <StyledField
        name={`${name}.areas^sector^name`}
        component={SelectField}
        label="Sector"
        values={(filters.sectors || []).map((item) => {
          return { value: item.name, label: item.name };
        })}
      />
      <StyledField
        name={`${name}.areas^beat^name`}
        component={SelectField}
        label="Beat"
        values={(filters.beats || []).map((item) => {
          return { value: item.name, label: item.name };
        })}
      />
      <StyledField
        name={`${name}.areas^ward^name`}
        component={SelectField}
        label="Ward"
        values={(filters.wards || []).map((item) => {
          return { value: item.name, label: item.name };
        })}
      />
      <StyledField
        name={`${name}.isConcluded`}
        component={CheckboxField}
        label="Concluded"
      />
    </Fragment>
  );
}
