import { Table, TableBody, TableHead, TableRow } from '@mui/material';
import DynamicTableCell from './DynamicTableCell';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { RECEIVE_TELEMATICS_BOX_POLL } from '../../../actions';
import { mapCanBusStatus, OnOffIcon, inputsStringToBinary } from './utilities';
import useLatestPoll from './useLatestPoll';
import _ from 'lodash';
import { Fragment } from 'react';

const {
  dioStates,
  dioOptions: { manualPolls },
} = window.config;

export default function InputsStatus({ imei }) {
  const latestPoll = useLatestPoll(imei);
  const dispatch = useDispatch();

  function pollHeaders() {
    return (
      <TableRow>
        <DynamicTableCell>Ignition</DynamicTableCell>
        <DynamicTableCell>Time</DynamicTableCell>
        {[1, 2, 3, 4, 5, 6].map((input) => {
          return <DynamicTableCell key={input}>{input}</DynamicTableCell>;
        })}
        <DynamicTableCell>CAN Bus</DynamicTableCell>
        <DynamicTableCell>Driver ID</DynamicTableCell>
        {manualPolls && <DynamicTableCell>Cached History</DynamicTableCell>}
      </TableRow>
    );
  }

  const rawInputOn = (inputsBin, i) => {
    return inputsBin[7 - i] === '1';
  };

  const time = new Date().toISOString();
  function sendPoll(change) {
    dispatch({
      type: RECEIVE_TELEMATICS_BOX_POLL,
      payload: {
        imei,
        poll: {
          ...latestPoll,
          identifier: imei + '-' + time,
          time,
          ...change,
        },
      },
    });
  }

  // TODO tidy this up, duplicate in DriverIDSection
  function sendCached(data) {
    const mostRecent = data.payload.telematicsBoxes[imei].mostRecentPoll;
    const cached = data.payload.telematicsBoxes[imei].cachedPolls;
    const allPolls = _.orderBy(
      _.uniqBy([mostRecent, ...cached].filter(Boolean), 'identifier'),
      'identifier'
    );

    // if the imei was just created, it won't have a mostRecentPoll
    allPolls.forEach(sendPoll);
  }

  if (!latestPoll) {
    sendPoll({ deviceProperties: { inputs: 192 } });
  }

  function pollValues() {
    if (!latestPoll) {
      return <Fragment>No poll received</Fragment>;
    }

    const time = latestPoll.time
      ? moment(latestPoll.time).format(`DD/MM/YY HH:mm:ss`)
      : '';

    const dioKeys = Object.keys(dioStates);

    return (
      <TableRow>
        <DynamicTableCell>
          <OnOffIcon
            style={{ margin: '0 auto' }}
            on={latestPoll.ignitionOn}
            onClick={
              manualPolls &&
              (() =>
                sendPoll({
                  ignitionOn: !latestPoll.ignitionOn,
                  diagnosticCode: latestPoll.ignitionOn ? '4' : '3',
                }))
            }
          />
        </DynamicTableCell>
        <DynamicTableCell>{time}</DynamicTableCell>
        {[0, 1, 2, 3, 4, 5].map((input) => {
          const key = dioKeys[input];
          const binaryInputs = inputsStringToBinary(
            latestPoll.deviceProperties.inputs
          );

          return (
            <DynamicTableCell key={input}>
              <OnOffIcon
                style={{ margin: '0 auto' }}
                on={rawInputOn(
                  binaryInputs,
                  input // 0-based as opposed to label which is 1-based
                )}
                onClick={
                  manualPolls &&
                  (() =>
                    sendPoll({
                      [key]: !latestPoll[key],
                      diagnosticCode: '7',
                      deviceProperties: {
                        ...latestPoll.deviceProperties,
                        inputs:
                          192 |
                          (latestPoll.deviceProperties.inputs ^
                            Math.pow(2, input)),
                        // Math.pow(2, input)
                      },
                    }))
                }
              />
            </DynamicTableCell>
          );
        })}
        <DynamicTableCell style={{ width: '20%' }}>
          {latestPoll
            ? mapCanBusStatus(latestPoll.deviceProperties.canBusStatus)
            : ''}
        </DynamicTableCell>
        <DynamicTableCell style={{ width: '20%' }}>
          {latestPoll.driver ? latestPoll.driver.identificationReference : ''}
        </DynamicTableCell>
        {manualPolls && (
          <DynamicTableCell>
            <OnOffIcon
              style={{ margin: '0 auto' }}
              on={latestPoll.cachedPolls?.length > 0}
              onClick={() =>
                sendCached({
                  payload: {
                    telematicsBoxes: {
                      [imei]: {
                        cachedPolls: [
                          {
                            time: new Date(Date.now() - 3000).toISOString(),
                            identifier:
                              imei +
                              '-' +
                              new Date(Date.now() - 3000).toISOString(),
                            diagnosticCode: '2',
                            ignitionOn: true,
                          },
                          {
                            time: new Date(Date.now() - 2000).toISOString(),
                            identifier:
                              imei +
                              '-' +
                              new Date(Date.now() - 2000).toISOString(),
                            diagnosticCode: '3',
                            ignitionOn: true,
                          },
                          {
                            time: new Date(Date.now() - 1000).toISOString(),
                            identifier:
                              imei +
                              '-' +
                              new Date(Date.now() - 1000).toISOString(),
                            diagnosticCode: '0',
                            ignitionOn: true,
                          },
                        ],
                        mostRecentPoll: {
                          ...latestPoll,
                          bufferCount: 1,
                          ignitionOn: true,
                          diagnosticCode: '2',
                        },
                      },
                    },
                  },
                })
              }
            />
          </DynamicTableCell>
        )}
      </TableRow>
    );
  }

  return (
    <Table>
      <TableHead>{pollHeaders()}</TableHead>
      <TableBody>{pollValues()}</TableBody>
    </Table>
  );
}
