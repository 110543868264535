import { Fragment } from 'react';
import { StyledField, SelectField } from '../../fields';

export default function IntelligenceParameters({ name, filters }) {
  return (
    <Fragment>
      <StyledField
        name={`${name}.classifications^code`}
        component={SelectField}
        label="Classification"
        values={(filters.classifications || []).map((item) => {
          return { value: item.code, label: item.name };
        })}
      />
      <StyledField
        name={`${name}.classifications2^code`}
        component={SelectField}
        label="Subclassification"
        values={(filters.subclassifications || []).map((item) => {
          return { value: item.code, label: item.name };
        })}
      />
      <StyledField
        name={`${name}.sourceReliability^code`}
        component={SelectField}
        label="Source Reliability"
        values={(filters.sourceReliabilities || []).map((item) => {
          return { value: item.code, label: item.name };
        })}
      />
      <StyledField
        name={`${name}.intelligenceReliability^code`}
        component={SelectField}
        label="Intelligence Reliability"
        values={(filters.intelligenceReliabilities || []).map((item) => {
          return { value: item.code, label: item.name };
        })}
      />
      <StyledField
        name={`${name}.areas^bcu^name`}
        component={SelectField}
        label="BCU"
        values={(filters.bcus || []).map((item) => {
          return { value: item.name, label: item.name };
        })}
      />
      <StyledField
        name={`${name}.areas^localAuthority^name`}
        component={SelectField}
        label="Local Authority"
        values={(filters.localAuthorities || []).map((item) => {
          return { value: item.name, label: item.name };
        })}
      />
      <StyledField
        name={`${name}.areas^section^name`}
        component={SelectField}
        label="Section"
        values={(filters.sections || []).map((item) => {
          return { value: item.name, label: item.name };
        })}
      />
      <StyledField
        name={`${name}.areas^sector^name`}
        component={SelectField}
        label="Sector"
        values={(filters.sectors || []).map((item) => {
          return { value: item.name, label: item.name };
        })}
      />
      <StyledField
        name={`${name}.areas^beat^name`}
        component={SelectField}
        label="Beat"
        values={(filters.beats || []).map((item) => {
          return { value: item.name, label: item.name };
        })}
      />
      <StyledField
        name={`${name}.areas^ward^name`}
        component={SelectField}
        label="Ward"
        values={(filters.wards || []).map((item) => {
          return { value: item.name, label: item.name };
        })}
      />
    </Fragment>
  );
}
