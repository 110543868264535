import {
  Avatar,
  CardHeader,
  CardActions,
  Toolbar,
  Button,
  Tab,
  Tabs,
  ListItem,
  ListSubheader,
  Divider,
  Box,
  Paper,
} from '@mui/material';
import { Skeleton } from '@mui/material';
import { Router as BoxIcon } from '@mui/icons-material';
import _ from 'lodash';
import moment from 'moment';
import { Fragment, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Form } from 'react-final-form';
import { useAuth } from '../../Auth';
import { usePrevious } from '../../../hooks';
// import {
//   Field,
//   // TextField,
//   // required,
//   SelectField,
//   CheckboxField,
// } from '../../fields';
import {
  FETCH_TELEMATICS_BOX_POLLS,
  START_TELEMATICS_BOX_POLLS_STREAM,
  END_TELEMATICS_BOX_POLLS_STREAM,
  UPDATE_TELEMATICS_BOX,
  DELETE_TELEMATICS_BOX,
  UPDATE_VEHICLE_IMEI,
} from '../../../actions';
import TelematicsBoxVehicles from './TelematicsBoxVehicles';
import DioTable from './DioTable';
import PollTable from './PollTable';
// import { vinErrors } from './utilities';
import { useCommissioningDialog } from './CommissioningDialog';
import useLatestPoll from './useLatestPoll';
import { DriverIdStatus } from './DriverIDSection';
import { TrackingStatus } from './TrackingStateControl';
import { Section, TelematicsBoxMap } from '../../controls';
import ConfirmationDialog from '../../dialogs/ConfirmationDialog';

const {
  dioOptions: { hideMap = false, showDriverId = true, showTracking = false },
} = window.config;

// const composeValidators = (...validators) => (value) =>
//   validators.reduce((error, validator) => error || validator(value), undefined);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index ? children : <Fragment />}
    </Box>
  );
}

export default function TelematicsBoxDetail() {
  const { id } = useParams();
  const auth = useAuth();
  const dispatch = useDispatch();
  const latestPoll = useLatestPoll(id);

  const boxesByImei = useSelector((state) => state.telematicsBoxes.boxesByImei);
  const box = boxesByImei[id];
  const { imei, polls, mostRecentTime } = box || {};
  const ordered = _.orderBy(polls || [], ['time'], ['desc']); // sort by time

  const [currentTab, setCurrentTab] = useState(0);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const commissioningDialog = useCommissioningDialog();

  const formRef = useRef();

  const socketIsSubscribed = useSelector(
    (state) => state.telematicsBoxes.socketIsSubscribed
  );

  // wipe state when different box
  const prevId = usePrevious(id);
  useEffect(() => {
    if (prevId !== id && formRef.current) {
      formRef.current.restart();
      formRef.current.initialize({
        identificationNumber: box?.identificationNumber,
        fleetNumber: box?.fleetNumber,
      });
    }
  }, [prevId, id, formRef, box]);

  // startup (only times when id, selectedXXX change)
  useEffect(() => {
    if (imei && !socketIsSubscribed) {
      let lastTime = moment(mostRecentTime || undefined);

      //console.log("Going to listen for " + imei + " around " + lastTime.format());
      // if there is a last response time, and it's not from today, query from then
      if (!lastTime.isValid() || lastTime > moment().subtract(1, 'days')) {
        lastTime = moment();
      }

      let start = lastTime.clone().utc().subtract(1, 'days');
      let end = lastTime.clone().utc();

      dispatch({
        type: FETCH_TELEMATICS_BOX_POLLS,
        payload: {
          imei,
          start,
          end,
        },
      });

      dispatch({
        type: START_TELEMATICS_BOX_POLLS_STREAM,
        payload: { imei },
      });
    }

    // shutdown
    return () => {
      if (socketIsSubscribed) {
        dispatch({
          type: END_TELEMATICS_BOX_POLLS_STREAM,
        });
      }
    };
  }, [imei, mostRecentTime, socketIsSubscribed, dispatch]);

  function onSubmit(box) {
    dispatch({
      type: UPDATE_TELEMATICS_BOX,
      payload: {
        imei,
        ...box,
      },
    });
  }

  function handleChangeTab(event, value) {
    setCurrentTab(value);
  }

  function a11yProps(index, alwaysEnabled = false) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  function renderSkeleton() {
    return (
      <Toolbar color="primary" disableGutters style={{ paddingRight: 12 }}>
        <CardHeader
          avatar={
            <Avatar>
              {<Skeleton variant="circular" width="100%" height="100%" />}
            </Avatar>
          }
          title={<Skeleton height={10} width="150px" />}
        />
      </Toolbar>
    );
  }

  function renderNotFound() {
    return (
      <Toolbar color="primary" disableGutters sx={{ pr: 3 }}>
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: 'error.main' }}>
              <BoxIcon />
            </Avatar>
          }
          title={`IMEI ${id} not found`}
        />
      </Toolbar>
    );
  }

  function deleteImei() {
    if (box.identificationNumber) {
      dispatch({
        type: UPDATE_VEHICLE_IMEI,
        payload: {
          vehicle: { identificationNumber: box.identificationNumber },
          telematicsBoxImei: '',
        },
      });
    }

    dispatch({
      type: DELETE_TELEMATICS_BOX,
      payload: imei,
    });
  }

  const canEdit = auth.getAuthorisation()?.telematicsBoxes?.write;
  const canCommission =
    auth.getAuthorisation()?.commissioning?.write &&
    auth.getAuthorisation()?.vehicles?.write;

  function renderBoxDetails() {
    return (
      <Form
        onSubmit={onSubmit}
        // debug={console.log}
        initialValues={box} // should reset the form when the id changes
        render={({
          handleSubmit,
          dirty,
          pristine,
          submitting,
          form: { restart },
          form,
        }) => {
          formRef.current = form;
          // const buttonsDisabled = pristine || submitting;

          return (
            <form onSubmit={handleSubmit}>
              <CardHeader
                avatar={
                  <Avatar>
                    <BoxIcon />
                  </Avatar>
                }
                action={
                  canCommission && (
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        commissioningDialog.show({ box });
                      }}
                      // sx={{ width: 150, mr: 1 }}
                    >
                      Commission
                    </Button>
                  )
                }
                title={id}
              />
              <Box>
                <Box sx={{ padding: 0 }}>
                  <Tabs
                    value={currentTab}
                    onChange={handleChangeTab}
                    indicatorColor="primary"
                    //textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    <Tab {...a11yProps(0)} label="Info" />
                    <Tab {...a11yProps(1, true)} label="Polls" />
                    <Tab {...a11yProps(2, true)} label="Events" />
                  </Tabs>
                  <TabPanel value={currentTab} index={0}>
                    {/* <Prompt when={dirty} message={"Unsaved changes will be lost, are you sure?"} /> */}
                    {/* <ListSubheader disableSticky>
                          Telematics Box
                        </ListSubheader>
                        <ListItem>
                          <Field
                            name="make"
                            className={classes.field}
                            label="Make"
                            component={SelectField}
                            values={[
                              { label: 'Huf', value: 'huf' },
                              { label: 'Astra', value: 'astra' },
                            ]}
                            disabled={!canEdit}
                          />
                          <Field
                            name="model"
                            className={classes.field}
                            label="Model"
                            component={SelectField}
                            values={[
                              { label: 'A', value: 'a' },
                              { label: 'B', value: 'b' },
                            ]}
                            disabled={!canEdit}
                          />
                          <Field
                            name="hardwareVersion"
                            className={classes.field}
                            label="Hardware Version"
                            component={SelectField}
                            values={[
                              { label: '1', value: '1' },
                              { label: '2', value: '2' },
                            ]}
                            disabled={!canEdit}
                          />
                        </ListItem>
                        <ListItem>
                          <Field
                            name="active"
                            className={classes.field}
                            label="Active"
                            component={CheckboxField}
                            disabled={!canEdit}
                          />
                        </ListItem>
                        <Divider /> */}
                    {showDriverId && (
                      <Fragment>
                        <ListSubheader disableSticky>Driver ID</ListSubheader>
                        <Section>
                          <DriverIdStatus imei={box?.imei} />
                        </Section>
                        <Divider />
                      </Fragment>
                    )}
                    {showTracking && (
                      <Fragment>
                        <ListSubheader disableSticky>Tracking</ListSubheader>
                        <Section>
                          <TrackingStatus imei={box?.imei} />
                        </Section>
                        <Divider />
                      </Fragment>
                    )}
                    <ListSubheader disableSticky>Status</ListSubheader>
                    <Section>
                      <Box sx={{ width: 1, maxWidth: '1024px' }}>
                        <PollTable polls={ordered} pollsToShow={1} />
                      </Box>
                    </Section>
                    <Divider />
                    <ListSubheader disableSticky>
                      Last State Change
                    </ListSubheader>
                    <Section>
                      <Box sx={{ width: 1, maxWidth: '1024px' }}>
                        <DioTable
                          polls={ordered}
                          pollsToShow={1}
                          // showTime={false}
                        />
                      </Box>
                    </Section>
                    <Divider />
                    {!hideMap && (
                      <Fragment>
                        <ListSubheader disableSticky>
                          Last known location
                        </ListSubheader>
                        <Box
                          sx={{
                            height: latestPoll?.position ? 500 : undefined,
                            p: latestPoll?.position ? 0 : 2,
                          }}
                        >
                          {latestPoll?.position ? (
                            <TelematicsBoxMap point={latestPoll.position} />
                          ) : (
                            'No location received'
                          )}
                        </Box>
                        <Divider />
                      </Fragment>
                    )}
                    <ListSubheader disableSticky>Vehicle</ListSubheader>
                    {/* <ListItem>
                          <Field
                            name="identificationNumber"
                            className={classes.field}
                            disabled={!!box?.identificationNumber}
                            // initialValue={box?.identificationNumber}
                            label="VIN"
                            validate={composeValidators(required, vinErrors)}
                          >
                            {(props) => (
                              <TextField
                                {...props}
                                value={
                                  box?.identificationNumber || props.input.value
                                }
                                inputProps={{
                                  className: classes.vinText,
                                  spellCheck: 'false',
                                }}
                              />
                            )}
                          </Field>
                          <Field
                            name="fleetNumber"
                            disabled={!!box?.fleetNumber}
                            value={box?.fleetNumber}
                            className={classes.field}
                            label="Fleet Number"
                          >
                            {(props) => (
                              <TextField
                                {...props}
                                value={box?.fleetNumber || props.input.value}
                                inputProps={{
                                  className: classes.vinText,
                                  spellCheck: 'false',
                                }}
                              />
                            )}
                          </Field>
                        </ListItem> */}
                    <ListItem>
                      <TelematicsBoxVehicles imei={id} />
                    </ListItem>
                    <Divider />
                    {/* <ListItem>
                        <Field
                          name="odometer"
                          className={classes.field}
                          label="Current Odometer Reading"
                          component={TextField}
                        />
                      </ListItem> */}
                  </TabPanel>
                  <TabPanel value={currentTab} index={1}>
                    <PollTable polls={ordered} pollsToShow={20} />
                  </TabPanel>
                  <TabPanel value={currentTab} index={2}>
                    <DioTable polls={ordered} pollsToShow={20} />
                  </TabPanel>
                </Box>
              </Box>
              {currentTab === 0 && canEdit && (
                <CardActions>
                  {/* <Button
                      type="submit"
                      className={classes.positiveButton}
                      disabled={buttonsDisabled}
                    >
                      Save
                    </Button>
                    <Button
                      className={classes.negativeButton}
                      disabled={buttonsDisabled}
                      onClick={restart}
                    >
                      Cancel
                    </Button> */}
                  <Button
                    color="error"
                    onClick={() => setShowConfirmDelete(true)}
                  >
                    Delete
                  </Button>
                </CardActions>
              )}
            </form>
          );
        }}
      />
    );
  }

  function renderContents() {
    if (!box) {
      if (Object.keys(boxesByImei).length > 0) {
        return renderNotFound();
      } else {
        return renderSkeleton();
      }
    } else {
      return renderBoxDetails();
    }
  }

  return (
    <Box>
      <Helmet>
        <title>IR3 | Telematics Box | {id}</title>
      </Helmet>
      <Paper sx={{ m: 1, minWidth: 240 }}>{renderContents()}</Paper>
      <ConfirmationDialog
        action="Delete"
        open={showConfirmDelete}
        itemId={imei}
        onOk={deleteImei}
        onCancel={() => setShowConfirmDelete(false)}
      />
    </Box>
  );
}
