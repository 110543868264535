import { tableCellClasses } from '@mui/material/TableCell';
import { TableCell, useMediaQuery } from '@mui/material';

export default function DynamicTableCell(props) {
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  if (isXSmall) {
    return (
      <TableCell
        sx={{
          p: 0,
          textAlign: 'center',
          borderRight: [1, 'solid', 'rgb(224,224,224)'],
          whiteSpace: 'pre-line',
          '&:last-child': {
            borderRight: 'none',
          },
        }}
        {...props}
      />
    );
  } else {
    return (
      <TableCell
        sx={{
          borderTop: [1, 'solid', 'rgb(224,224,224)'],
          borderBottom: 'none',
          p: 0.5,
          textAlign: 'center',
          [`&.${tableCellClasses.head}`]: {
            borderTop: 'none',
          },
        }}
        {...props}
      />
    );
  }
}
