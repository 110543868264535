export function indexArray(length) {
  return Array(length)
    .fill()
    .map((_, i) => i);
}

export function rearrange(arr, oldIndex, newIndex) {
  let tempArr = [...arr];

  const oldValue = tempArr[oldIndex];
  // if the newPosition is higher up, we need to move everything between down one
  if (newIndex > oldIndex) {
    for (let i = oldIndex; i < newIndex; i++) {
      tempArr[i] = tempArr[i + 1];
    }
    tempArr[newIndex] = oldValue;
  } else {
    // otherwise, move everything from newIndex to oldIndex up one
    for (let i = oldIndex; i > newIndex; i--) {
      tempArr[i] = tempArr[i - 1];
    }
    tempArr[newIndex] = oldValue;
  }

  return tempArr;
}
