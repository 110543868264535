import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Badge,
} from '@mui/material';
import { Person as PersonIcon } from '@mui/icons-material';
import { Steering as DriverIcon } from 'mdi-material-ui';
import _ from 'lodash';
import { ExportPicker } from '../controls';
import { getVehiclePolls, getPersonPolls } from '../../apis/utilities';

const { useReducedResourceInformation } = window.config;

export default function OccupantsDialog({ onClose, open, trip }) {
  const { vehicle, occupants, driver, startTime, endTime } = trip;

  async function fetchDataToConvert() {
    const vehiclePollsRequest = getVehiclePolls(
      vehicle.telematicsBoxImei,
      startTime,
      endTime
    );

    const radioPollsRequests = _.uniq(
      occupants.map((occupant) => occupant.radioSsi)
    ).map((radioSsi) => getPersonPolls(radioSsi, startTime, endTime));

    const allPolls = await Promise.all([
      vehiclePollsRequest,
      ...radioPollsRequests,
    ]);

    const mergedPolls = _.orderBy(
      allPolls.flatMap((polls) =>
        polls.map((poll) => ({
          time:
            typeof poll.time === 'string' || poll.time instanceof String
              ? new Date(poll.time)
              : poll.time,
          longitude: poll.position.coordinates[0],
          latitude: poll.position.coordinates[1],
          position: poll.position,
          deviceId: poll.imei ?? poll.ssi,
          speed: poll.speedMilesPerHour,
        }))
      ),
      'time'
    );

    return mergedPolls;
  }

  function showOccupants(occupants) {
    return occupants.length === 0 ? (
      <ListItem>No coincident radio polls found</ListItem>
    ) : (
      occupants
        .sort((a, b) => b.ratio - a.ratio)
        .map(
          (
            {
              code,
              collarNumber,
              forenames,
              surname,
              radioSsi,
              ratio,
              radioPollCount,
              distanceKilometres,
            },
            index
          ) => {
            const name = [
              forenames,
              surname,
              collarNumber && `[${collarNumber}]`,
            ]
              .filter(Boolean)
              .join(' ')
              .trim();

            function orRadioSsi(text) {
              return !!text ? text : radioSsi;
            }

            const picture = undefined;

            const percent = _.round(100 * ratio, 2);
            const distance = _.round(distanceKilometres * 0.62137119, 2);
            const secondary =
              distanceKilometres !== undefined
                ? `${radioPollCount} radio polls coincided for ${distance} miles, ${percent}% of trip`
                : `of ${radioPollCount} radio polls, ${percent}% coincide`;

            function showBadgeContent() {
              return code === driver?.code ? (
                <DriverIcon fontSize="small" />
              ) : undefined;
            }

            return (
              <ListItem key={index}>
                <ListItemAvatar>
                  <Badge badgeContent={showBadgeContent()}>
                    <Avatar src={picture}>
                      <PersonIcon />
                    </Avatar>
                  </Badge>
                </ListItemAvatar>
                <ListItemText
                  primary={orRadioSsi(
                    useReducedResourceInformation ? code : name
                  )}
                  secondary={secondary}
                />
              </ListItem>
            );
          }
        )
    );
  }

  return (
    <Dialog open={open}>
      <DialogTitle>Potential occupants</DialogTitle>
      <DialogContent>
        <List disablePadding>{showOccupants(occupants)}</List>
      </DialogContent>
      <DialogActions>
        {startTime && endTime && (
          <ExportPicker
            title="Download Combined Polls"
            fetchDataToConvert={fetchDataToConvert}
            filename="Trip Polls"
            //headers={vehiclePollsHeaders}
            headers={[
              { label: 'Time', key: 'time', type: 'date' },
              { label: 'Longitude', key: 'longitude', type: 'number' },
              { label: 'Latitude', key: 'latitude', type: 'number' },
              { label: 'Device', key: 'deviceId', type: 'text' },
            ]}
          />
        )}

        <Button onClick={onClose} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
