import {
  Avatar,
  Box,
  Button,
  Card,
  Paper,
  CardActions,
  CardActionArea,
  CardHeader,
  IconButton,
  ListSubheader,
  Divider,
  Typography,
} from '@mui/material';
import {
  RemoveCircle as RemoveCircleIcon,
  Person as PersonIcon,
} from '@mui/icons-material';
import { RadioHandheld as RadioHandheldIcon } from 'mdi-material-ui';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Form } from 'react-final-form';
import _ from 'lodash';
import { useAuth } from '../Auth';
import {
  CREATE_RADIO,
  DELETE_RADIO,
  FETCH_RADIO,
  FETCH_RADIO_SUCCESS,
  UPDATE_RADIO,
  REMOVE_RADIO,
} from '../../actions';
import { ConfirmationDialog } from '../dialogs';
import { StyledField, TextField, SelectField, required } from '../fields';
import { doesIdExist } from '../../apis/utilities';
import { Section } from '../controls';

const { radioTypes } = window.config;

export default function Radio() {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const radio = useSelector((state) => state.radios.radio, _.isEqual);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [removePerson, setRemovePerson] = useState(null);
  const auth = useAuth();
  const canEdit = auth.isAuthorised('people', true);

  useEffect(() => {
    if (id === 'new') {
      dispatch({
        type: FETCH_RADIO_SUCCESS,
        payload: null,
      });
    } else {
      dispatch({
        type: FETCH_RADIO,
        payload: id,
      });
    }
  }, [id, dispatch]);

  async function onSubmit(values) {
    if (radio) {
      dispatch({
        type: UPDATE_RADIO,
        payload: values,
      });
    } else {
      const exists = await doesIdExist('radios', values.ssi);
      if (exists) {
        return { ssi: 'Exists' };
      }

      dispatch({
        type: CREATE_RADIO,
        payload: values,
        navigate,
      });
    }
  }

  function handleDelete() {
    if (radio) {
      dispatch({
        type: DELETE_RADIO,
        payload: radio.ssi,
      });
    }
  }

  function isDisabled(fieldName) {
    return !canEdit || radio === null;
  }

  const handleRemoveClick = (person) => (event) => {
    event.preventDefault();

    setRemovePerson(person);
  };

  function handleRemove() {
    const body = {
      code: removePerson.code,
      radioSsi: null,
    };

    dispatch({
      type: REMOVE_RADIO,
      payload: { id, body },
    });

    setRemovePerson(null);
  }

  return (
    <Form
      initialValues={radio || {}}
      onSubmit={onSubmit}
      render={({
        handleSubmit,
        form: { reset },
        submitting,
        dirty,
        pristine,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <Helmet>
            <title>IR3 | Radio{values.ssi ? ` | ${values.ssi}` : ''}</title>
          </Helmet>
          <Paper sx={{ m: 1, minWidth: 240 }}>
            <CardHeader
              avatar={
                <Avatar aria-label="Radio">
                  <RadioHandheldIcon />
                </Avatar>
              }
              title={values.ssi}
              subheader={values.type}
            />
            <Box>
              <ListSubheader disableSticky>Key Information</ListSubheader>
              <Section>
                <StyledField
                  name="ssi"
                  component={TextField}
                  label="SSI"
                  validate={required}
                  disabled={!(canEdit && radio === null)}
                />
                <StyledField
                  name="type"
                  component={SelectField}
                  label="Type"
                  values={radioTypes}
                  disabled={isDisabled('type')}
                />
                <StyledField
                  name="label"
                  component={TextField}
                  label="Label"
                  disabled={isDisabled('label')}
                />
              </Section>
              <Divider />
              <ListSubheader disableSticky>Assigned People</ListSubheader>
              <Section>
                {radio && radio.people.length > 0 ? (
                  radio.people.map((person) => (
                    <Card
                      key={person.code}
                      variant="outlined"
                      sx={{ width: 320, mr: 1.5, mb: 1.5 }}
                    >
                      <CardActionArea
                        component={Link}
                        to={`/resources/people/${encodeURIComponent(
                          person.code
                        )}`}
                      >
                        <CardHeader
                          avatar={
                            <Avatar src={person.picture}>
                              <PersonIcon />
                            </Avatar>
                          }
                          action={
                            <IconButton
                              title="Remove"
                              onClick={handleRemoveClick(person)}
                              onMouseDown={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              <RemoveCircleIcon />
                            </IconButton>
                          }
                          title={`${person.forenames} ${person.surname}`}
                          subheader={person.collarNumber}
                        />
                      </CardActionArea>
                    </Card>
                  ))
                ) : (
                  <Typography variant="body2" color="text.secondary">
                    There is no one assigned to this radio
                  </Typography>
                )}
              </Section>
            </Box>
            <CardActions>
              <Button
                color="primary"
                type="submit"
                disabled={pristine || submitting}
              >
                Save
              </Button>
              <Button
                color="primary"
                disabled={pristine || submitting}
                onClick={reset}
              >
                Cancel
              </Button>
              <Button
                color="error"
                onClick={() => setDeleteOpen(true)}
                disabled={radio === null}
              >
                Delete
              </Button>
            </CardActions>
          </Paper>
          <ConfirmationDialog
            action="Delete"
            open={deleteOpen}
            itemId={values.ssi}
            onOk={handleDelete}
            onCancel={() => setDeleteOpen(false)}
          />

          <ConfirmationDialog
            action="Remove"
            open={!!removePerson}
            itemId={
              removePerson
                ? `${removePerson.forenames || ''} ${
                    removePerson.surname || ''
                  }` ||
                  removePerson.collarNumber ||
                  removePerson.code
                : ''
            }
            onOk={handleRemove}
            onCancel={() => setRemovePerson(null)}
          />
        </form>
      )}
    />
  );
}
