import { Avatar, Badge } from '@mui/material';
import { DataUsage as DataUsageIcon } from '@mui/icons-material';
import { isAfter, differenceInHours } from 'date-fns';

export default function ObjectiveAvatar({
  startTime,
  endTime,
  isActive,
  showBadge = true,
}) {
  const now = new Date();
  const start = new Date(startTime);
  const end = new Date(endTime);

  let hours = 0.0;
  let tip = '';
  let badgeColor = null;

  if (isAfter(now, end)) {
    hours = differenceInHours(now, end) / 24;
    tip = 'Days since end';
    badgeColor = 'error';
  } else if (isAfter(start, now)) {
    hours = differenceInHours(start, now) / 24;
    tip = 'Days until start';
    badgeColor = 'warning';
  } else {
    hours = differenceInHours(end, now) / 24;
    tip = 'Days until end';
    badgeColor = 'success';
  }

  return (
    <Badge
      color={badgeColor}
      badgeContent={
        showBadge ? (
          <div title={tip}>
            {hours > 99.0 ? '99+' : Math.round(hours).toString()}
          </div>
        ) : undefined
      }
    >
      <Avatar sx={isActive ? { bgcolor: 'primary.main' } : undefined}>
        <DataUsageIcon />
      </Avatar>
    </Badge>
  );
}
