import { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import {
  FETCH_FEATURE,
  FETCH_FEATURES,
  FETCH_LOCATION,
  FETCH_LOCATIONS,
} from '../../actions';
import { featureSubtypes, locationTypes } from '../../data/constants';
import { StyledField, SelectField, required } from '../fields';

export default function BoundaryFields({ type, subtype, mutators, disabled }) {
  const dispatch = useDispatch();
  const locations = useSelector(
    (state) => state.locations.locations,
    _.isEqual
  );
  const featureNames = useSelector(
    (state) => state.features.featureNames,
    _.isEqual
  );

  useEffect(() => {
    if (featureNames.length === 0) {
      dispatch({
        type: FETCH_FEATURES,
        payload: 'Perimeter',
      });
    }
  }, [featureNames, dispatch]);

  function getBoundarySubtypes(type) {
    switch (type) {
      case 'Perimeter':
        return Object.entries(featureSubtypes['perimeters']).map((item) => {
          return { value: item[0], label: item[1] };
        });
      case 'Location':
        return Object.values(locationTypes).map((item) => {
          return { value: item.name, label: item.name };
        });
      default:
        return [];
    }
  }

  function getBoundaries(type, subtype) {
    switch (type) {
      case 'Perimeter':
        return (featureNames || [])
          .filter((feature) => feature.subtype === subtype)
          .map((item) => {
            return {
              value: item.identifier,
              label: item.title || item.identifier,
            };
          });
      case 'Location':
        return (locations || []).map((item) => {
          return { value: item.code, label: item.name };
        });
      default:
        return [];
    }
  }

  function handleSubtypeChange(event) {
    mutators.clearValue('boundaryIdentifier');

    if (type === 'Location') {
      dispatch({
        type: FETCH_LOCATIONS,
        payload: event.target.value,
      });
    }
  }

  function handleIdentifierChange(event) {
    switch (type) {
      case 'Location':
        dispatch({
          type: FETCH_LOCATION,
          payload: event.target.value,
        });
        break;
      case 'Perimeter':
        dispatch({
          type: FETCH_FEATURE,
          payload: event.target.value,
        });
        break;
      default:
        break;
    }
  }

  return (
    <Fragment>
      <StyledField
        name="boundarySubtype"
        component={SelectField}
        label="Subtype"
        validate={required}
        values={getBoundarySubtypes(type)}
        onChange={handleSubtypeChange}
        disabled={disabled}
      />
      <StyledField
        name="boundaryIdentifier"
        component={SelectField}
        label="Name"
        values={getBoundaries(type, subtype)}
        validate={required}
        onChange={handleIdentifierChange}
        disabled={disabled}
      />
    </Fragment>
  );
}
