import { Box, Divider, Typography } from '@mui/material';
import { Fragment } from 'react';
import {
  Circle as LargeDistanceIcon,
  CircleMedium as SmallDistanceIcon,
} from 'mdi-material-ui';
import {
  BooleanSelectField,
  CheckboxField,
  ColorsField,
  DateTimeField,
  DebouncedTextField,
  Field,
  SelectField,
  SliderField,
  required,
} from '../fields';
import { compareLabels } from './constants';

const { areaTypes } = window.config;

export default function TypeParameters({ type, name, value }) {
  const relativeTime = () => (
    <Box sx={{ display: 'flex', flexAlign: 'row' }}>
      <Field
        name={`${name}.amountOfTime`}
        type="number"
        label="Amount"
        component={DebouncedTextField}
        inputProps={{ min: 1 }}
        sx={{ m: 1, width: 93 }}
        ignoreTouched
      />
      <Field
        name={`${name}.unitOfTime`}
        label="Unit"
        component={SelectField}
        defaultValue={'days'}
        values={['days', 'weeks', 'months'].map((type) => {
          return { value: type, label: type };
        })}
        sx={{ m: 1, width: 93 }}
        ignoreTouched
      />
      <Field
        name={`${name}.includeCurrentTime`}
        component={CheckboxField}
        label={`Include current ${(value.unitOfTime ?? '').slice(0, -1)}`}
        sx={{ m: 1, width: 200 }}
      />
    </Box>
  );

  const startAndEnd = () => (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'spaceAround',
        flexDirection: 'row',
        mt: 1,
        pr: 1,
      }}
    >
      <Box sx={{ mx: 1 }}>
        <Field
          label="Start"
          name={`${name}.startTime`}
          component={DateTimeField}
          maxDate={value.endTime}
        />
      </Box>
      <Box>
        <Field
          label="End"
          name={`${name}.endTime`}
          component={DateTimeField}
          minDate={value.startTime}
        />
      </Box>
    </Box>
  );

  const time = () => {
    return (
      <Fragment>
        <Typography variant="subtitle2" color="textSecondary">
          Time Period
        </Typography>
        <Box sx={{ ml: -1 }}>
          <Field
            name={`${name}.isRelativeTimePeriod`}
            title="Type"
            component={BooleanSelectField}
            trueLabel="Relative"
            falseLabel="Absolute"
            sx={{ m: 1, width: 200 }}
            defaultValue={false}
            // onChange={() => mutators.clearValue('timePeriod')}
          />
          {/* x ? y : z confuses RFF array & it puts end date value in unit */}
          {value.isRelativeTimePeriod && relativeTime()}
          {!value.isRelativeTimePeriod && startAndEnd()}
        </Box>
      </Fragment>
    );
  };

  switch (type) {
    case 'area':
      return (
        <Fragment>
          {time()}
          <Divider sx={{ mt: 2, mb: 1 }} />
          <Typography variant="subtitle2" color="textSecondary">
            Visualisation
          </Typography>
          <Field
            sx={{ mr: 4, ml: 1, mt: 1 }}
            label="Colour"
            name={`${name}.colors`}
            component={ColorsField}
            max={1}
          />
          <Divider sx={{ mt: 2, mb: 1 }} />
          <Typography variant="subtitle2" color="textSecondary">
            Area
          </Typography>
          <Field
            sx={{ mt: 1, minWidth: 128 }}
            label="Type"
            name={`${name}.areaType`}
            component={SelectField}
            values={areaTypes.sort(compareLabels)}
            ignoreTouched
            validate={required}
            datacy="areaType" // for testing
          />
        </Fragment>
      );
    case 'bubble':
      return (
        <Fragment>
          {time()}
          <Divider sx={{ mt: 2, mb: 1 }} />
          <Typography variant="subtitle2" color="textSecondary">
            Visualisation
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Field
              sx={{ mr: 4, ml: 1, mt: 1 }}
              label="Colour"
              name={`${name}.colors`}
              component={ColorsField}
              max={1}
            />
            <Box>
              <Box sx={{ mt: 0.5 }}>
                <Typography variant="caption" color="textSecondary">
                  Distance
                </Typography>
              </Box>
              <Box
                id="distance"
                sx={{
                  mt: -1.5,
                  mx: 1,
                  display: 'flex',
                  alignItems: 'center',
                  color: 'text.secondary',
                }}
              >
                <SmallDistanceIcon />
                <Field
                  sx={{ pr: 1, ml: 1, width: 240, flexGrow: 1 }}
                  fallbackValue={7} // default or initial are taken
                  marks
                  min={4}
                  max={10}
                  step={1}
                  name={`${name}.distance`}
                  component={SliderField}
                  // label="Distance"
                  type="number"
                />
                <LargeDistanceIcon />
              </Box>
            </Box>
          </Box>
        </Fragment>
      );
    case 'heat':
      return (
        <Fragment>
          {time()}
          <Divider sx={{ mt: 2, mb: 1 }} />
          <Typography variant="subtitle2" color="textSecondary">
            Visualisation
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box>
              <Field
                sx={{ mr: 4, ml: 1, mt: 1 }}
                label="Colours"
                name={`${name}.colors`}
                component={ColorsField}
                min={2}
              />
              <Field
                sx={{ mt: 2, mr: 1, width: 80 }}
                name={`${name}.blur`}
                component={DebouncedTextField}
                label="Blur"
                type="number"
                inputProps={{ min: 1, max: 20, step: 1 }}
              />
              <Field
                sx={{ mt: 2, mr: 1, width: 80 }}
                name={`${name}.radius`}
                component={DebouncedTextField}
                label="Radius"
                type="number"
                inputProps={{ min: 1, max: 20, step: 1 }}
              />
            </Box>
          </Box>
        </Fragment>
      );
    case 'shape':
      return (
        <Fragment>
          {time()}
          <Divider sx={{ mt: 2, mb: 1 }} />
          <Typography variant="subtitle2" color="textSecondary">
            Visualisation
          </Typography>
          <Field
            sx={{ mr: 4, ml: 1, mt: 1 }}
            label="Colour"
            name={`${name}.colors`}
            component={ColorsField}
            max={1}
          />
        </Fragment>
      );
    case 'file':
      return (
        <Fragment>
          <Typography variant="subtitle2" color="textSecondary">
            Visualisation
          </Typography>
          <Field
            sx={{ mr: 4, ml: 1, mt: 1 }}
            label="Colour"
            name={`${name}.colors`}
            component={ColorsField}
            max={1}
          />
        </Fragment>
      );
    default:
      return '';
  }
}
