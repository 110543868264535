import { Fragment, useState } from 'react';
import {
  Chip,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  TextField,
} from '@mui/material';
import { Autocomplete } from '@mui/material';

export default function ChipField({
  input: { onChange, value, ...input },
  meta,
  categoryIcons,
  defaultCategory,
  disabled,
  ...props
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  function handleAvatarClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleChange(event, value) {
    const [reference, ...existingValues] = value.slice().reverse();

    if (value.length === 0 || typeof reference === 'object') {
      onChange(value);
    } else {
      onChange([
        ...existingValues.reverse(),
        { category: 'Business', reference },
      ]);
    }
  }

  return (
    <Fragment>
      <Autocomplete
        fullWidth
        size="small"
        multiple
        freeSolo
        value={value || []}
        options={[]}
        disabled={disabled}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField
            {...params}
            {...props}
            helperText={meta.touched && (meta.error || meta.submitError)}
            error={meta.touched && meta.invalid}
          />
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              label={option.reference}
              {...getTagProps({ index })}
              onClick={categoryIcons && !disabled && handleAvatarClick}
              aria-owns={anchorEl ? 'category-menu' : undefined}
              aria-haspopup="true"
              icon={categoryIcons && categoryIcons[option.category]}
            />
          ))
        }
      />
      {categoryIcons && (
        <Menu
          id="category-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {Object.entries(categoryIcons).map((item) => (
            <MenuItem
              key={item[0]}
              onClick={() => {
                onChange(
                  value.map((chip) =>
                    chip.reference === anchorEl.textContent
                      ? {
                          category: item[0],
                          reference: anchorEl.textContent,
                        }
                      : chip
                  )
                );
                setAnchorEl(null);
              }}
            >
              <ListItemIcon>{item[1]}</ListItemIcon>
              <ListItemText primary={item[0]} />
            </MenuItem>
          ))}
        </Menu>
      )}
    </Fragment>
  );
}
