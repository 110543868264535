import { WeeklySchedulePicker } from '../controls';

export default function WeeklyScheduleField({
  input: { value, onChange },
  disabled,
  className,
  sx,
}) {
  return (
    <WeeklySchedulePicker
      value={value}
      onChange={onChange}
      disabled={disabled}
      className={className}
      sx={sx}
    />
  );
}
