import {
  Avatar,
  Box,
  Button,
  Collapse,
  Divider,
  IconButton,
  InputAdornment,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {
  Add as AddIcon,
  ArrowUpward as ArrowUpwardIcon,
  Person as PersonIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material';
import _ from 'lodash';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Outlet, useParams } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { FETCH_PEOPLE } from '../../actions';
import {
  downloadCSV,
  downloadJSON,
  getPeople,
  getPeopleAndHeaders,
} from '../../apis/utilities';
import { useAuth } from '../Auth';
import Container from '../Container';
import { NavLink, SearchBox } from '../controls';

const { useReducedResourceInformation, useDallasKeys, isFleet } = window.config;

function Row({ data, index, style }) {
  const [list, id, sortBy] = data;
  const item = list[index];

  function getSecondaryText() {
    if (!_.get(item, sortBy)) {
      return '';
    }

    switch (sortBy) {
      case 'surname':
      case 'code':
        return useReducedResourceInformation ? item.collarNumber : item.code;
      case 'collarNumber':
        return item.collarNumber;
      case 'lastPollTime':
        return format(new Date(item.lastPollTime), 'dd/MM/yyyy HH:mm:ss');
      default:
        return _.get(item, sortBy);
    }
  }

  return (
    <ListItemButton
      dense
      key={index}
      style={style}
      component={NavLink}
      to={`/resources/people/${encodeURIComponent(item.code)}`}
      selected={id === item.code}
    >
      <ListItemAvatar>
        <Avatar src={item.picture}>
          <PersonIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          useReducedResourceInformation
            ? item.code
            : `${item.forenames} ${item.surname}`
        }
        secondary={getSecondaryText()}
        primaryTypographyProps={{ noWrap: true }}
      />
    </ListItemButton>
  );
}

export default function PersonList() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const people = useSelector((state) => state.people.people, _.isEqual);
  const [searchText, setSearchText] = useState('');
  const [showSettings, setShowSettings] = useState(false);
  const [sortBy, setSortBy] = useState(
    useReducedResourceInformation ? 'code' : 'surname'
  );
  const [sortDesc, setSortDesc] = useState(false);
  const [filter, setFilter] = useState({
    rfid: '',
    radio: '',
    leaver: false,
  });
  const isXs = useMediaQuery((theme) => theme.breakpoints.only('xs'));
  const auth = useAuth();
  const canCreate = auth.isAuthorised('vehicles', true);

  const sortOptions = [
    ...(useReducedResourceInformation
      ? [{ label: 'Staff ID', value: 'code' }]
      : [
          { label: 'Payroll Number', value: 'code' },
          { label: 'Name', value: 'surname' },
          { label: 'Collar Number', value: 'collarNumber' },
        ]),
    ...(isFleet ? [] : [{ label: 'Last Poll Time', value: 'lastPollTime' }]),
  ];

  const filterOptions = {
    rfid: [
      { label: 'None', value: 'none' },
      { label: 'Multiple', value: 'multiple' },
    ],
    radio: [{ label: 'None', value: 'none' }],
    leaver: [
      { label: 'Hide', value: false },
      { label: 'Show', value: true },
    ],
  };

  useEffect(() => {
    dispatch({
      type: FETCH_PEOPLE,
    });
  }, [dispatch]);

  const filteredList = _.orderBy(
    people
      .filter(
        (item) =>
          item.searchString.indexOf(searchText.toLowerCase()) > -1 ||
          searchText === ''
      )
      .filter(
        (item) =>
          !filter.rfid ||
          (filter.rfid === 'none' && (item.rfidCards || []).length === 0) ||
          (filter.rfid === 'multiple' && (item.rfidCards || []).length > 1)
      )
      .filter(
        (item) =>
          !filter.radio || (filter.radioSsi === 'none' && !item.radioSsi)
      )
      .filter((item) => filter.leaver || !item.leavingDate),
    [sortBy],
    [sortDesc ? 'desc' : 'asc']
  );

  function handleSearchChange(event) {
    setSearchText(event.target.value);
  }

  function handleSettingsToggle() {
    setShowSettings(!showSettings);
  }

  function handleSortByChange(event) {
    setSortBy(event.target.value);
  }

  function handleSortToggle() {
    setSortDesc(!sortDesc);
  }

  const handleFilterChange = (field) => (event) => {
    setFilter({ ...filter, [field]: event.target.value });
  };

  async function handleCsvClick() {
    const data = await getPeopleAndHeaders();

    downloadCSV(data.people, 'people.csv', data.headers);
  }

  async function handleJsonClick() {
    const data = await getPeople();

    downloadJSON(data, 'people.json');
  }

  return (
    <Container title="People" showBack={isXs && id}>
      <Box sx={{ display: 'flex', height: 1, width: 1 }}>
        <Helmet>
          <title>IR3 | People</title>
        </Helmet>
        {(!isXs || !id) && (
          <Box
            sx={(theme) => ({
              display: 'flex',
              flexDirection: 'column',
              width: 280,
              [theme.breakpoints.down('sm')]: {
                width: 1,
              },
              height: 1,
            })}
          >
            <Box>
              <Stack
                direction="row"
                sx={{ p: 1, pr: 0.5 }}
                spacing={0.5}
                alignItems="center"
              >
                <SearchBox
                  value={searchText}
                  onChange={handleSearchChange}
                  count={`${filteredList.length}/${
                    people.filter((item) => filter.leaver || !item.leavingDate)
                      .length
                  }`}
                  sx={{ flexGrow: 1 }}
                />
                <IconButton
                  size="small"
                  title={showSettings ? 'Hide settings' : 'Show settings'}
                  onClick={handleSettingsToggle}
                >
                  <SettingsIcon
                    fontSize="inherit"
                    color={showSettings ? 'primary' : 'inherit'}
                  />
                </IconButton>
                {canCreate && (
                  <IconButton
                    title="Add new"
                    component={Link}
                    to={'/resources/people/new'}
                    size="small"
                  >
                    <Avatar
                      sx={{
                        color: 'secondary.contrastText',
                        backgroundColor: 'secondary.main',
                        width: 24,
                        height: 24,
                        fontSize: 16,
                      }}
                    >
                      <AddIcon fontSize="inherit" />
                    </Avatar>
                  </IconButton>
                )}
              </Stack>
              <Collapse in={showSettings} unmountOnExit>
                <Stack sx={{ p: 1 }} spacing={1.5}>
                  <TextField
                    size="small"
                    select
                    fullWidth
                    label="Sort by"
                    value={sortBy}
                    onChange={handleSortByChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <IconButton
                            title={sortDesc ? 'Descending' : 'Ascending'}
                            sx={(theme) => ({
                              transform: sortDesc
                                ? 'rotate(180deg)'
                                : 'rotate(0deg)',
                              transition: theme.transitions.create(
                                'transform',
                                {
                                  duration: theme.transitions.duration.shortest,
                                }
                              ),
                            })}
                            onClick={handleSortToggle}
                            size="small"
                          >
                            <ArrowUpwardIcon fontSize="inherit" />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  >
                    {sortOptions.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Stack>
                <Stack sx={{ px: 1 }} spacing={1.5}>
                  <Divider>
                    <Typography variant="subtitle2" color="textSecondary">
                      Filters
                    </Typography>
                  </Divider>
                  <TextField
                    size="small"
                    fullWidth
                    select
                    label={useDallasKeys ? 'Dallas Keys' : 'RFID Cards'}
                    value={filter.rfid}
                    onChange={handleFilterChange('rfid')}
                  >
                    <MenuItem value="">
                      <em>All</em>
                    </MenuItem>
                    {filterOptions.rfid.map((type) => (
                      <MenuItem key={type.value} value={type.value}>
                        {type.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    size="small"
                    fullWidth
                    select
                    label="Radio"
                    value={filter.radio}
                    onChange={handleFilterChange('radio')}
                  >
                    <MenuItem value="">
                      <em>All</em>
                    </MenuItem>
                    {filterOptions.radio.map((type) => (
                      <MenuItem key={type.value} value={type.value}>
                        {type.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    size="small"
                    fullWidth
                    select
                    label="Leavers"
                    value={filter.leaver}
                    onChange={handleFilterChange('leaver')}
                  >
                    {filterOptions.leaver.map((type) => (
                      <MenuItem key={type.value} value={type.value}>
                        {type.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <Divider>
                    <Typography variant="subtitle2" color="textSecondary">
                      Export
                    </Typography>
                  </Divider>
                  <Stack
                    direction="row"
                    // sx={{ p: 1 }}
                    spacing={1.5}
                    justifyContent="center"
                  >
                    <Button
                      fullWidth
                      color="primary"
                      variant="contained"
                      disableElevation
                      onClick={handleCsvClick}
                    >
                      CSV
                    </Button>
                    <Button
                      fullWidth
                      color="secondary"
                      variant="contained"
                      disableElevation
                      onClick={handleJsonClick}
                    >
                      JSON
                    </Button>
                  </Stack>
                  <Divider />
                </Stack>
              </Collapse>
            </Box>
            {filteredList.length > 0 && (
              <Box sx={{ height: 1 }}>
                <AutoSizer>
                  {({ width, height }) => (
                    <FixedSizeList
                      width={width}
                      height={height}
                      overscanCount={10}
                      itemData={[filteredList, id, sortBy]}
                      itemCount={filteredList.length}
                      itemSize={56}
                    >
                      {Row}
                    </FixedSizeList>
                  )}
                </AutoSizer>
              </Box>
            )}
          </Box>
        )}
        {(!isXs || id) && (
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              // width: 1,
              height: 1,
              overflow: 'auto',
            }}
          >
            <Outlet />
          </Box>
        )}
      </Box>
    </Container>
  );
}
