import { mergeMap, map, catchError } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { ofType } from 'redux-observable';
import moment from 'moment';
import {
  FETCH_REPLAY,
  FETCH_REPLAY_SUCCESS,
  FETCH_REPLAY_FAILURE,
} from '../actions';
import api from '../apis';
import { getHeaders, log } from '../apis/utilities';

const groupTypes = new Map([
  ['vehicle', 'vehicles'],
  ['location', 'locations'],
  ['event', 'events'],
  ['incident', 'incidents'],
  ['person', 'people'],
  ['objective', 'objectives'],
]);

function getInitialFeature(initialState) {
  const { stateKey, point, position, boundary, stateType, ...properties } =
    initialState;
  return {
    type: 'Feature',
    id: stateKey,
    geometry: point || position || boundary,
    properties: { ...properties, type: stateType },
  };
}

function getPaths(initialStates, stateChanges, type) {
  return initialStates
    .filter((state) => type === state.stateType)
    .map((state) => {
      const initialFeature = getInitialFeature(state);
      return {
        id: initialFeature.id,
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            initialFeature.geometry?.coordinates,
            ...stateChanges
              .filter(
                (change) =>
                  change.stateKey === initialFeature.id && change.position
              )
              .map((change) => change.position.coordinates),
          ].filter(Boolean), // the initial features may not have a geometry
        },
      };
    });
}

async function fetchReplayRequest(type, id) {
  const response = await api.get(`/replay/${type}/${id}`, {
    headers: getHeaders(),
  });

  const { initialStates, stateChanges } = response.data;

  // a location's start/end shouldn't determine the replay start/end so let's
  // get the min/max of moving object times and clamp the non-moving to it
  const movingObjects = ['vehicle', 'person'];
  let { startTime, endTime } = stateChanges?.[0];
  stateChanges
    .filter((change) => movingObjects.includes(change.stateType))
    .forEach((change) => {
      startTime = startTime > change.startTime ? change.startTime : startTime;
      endTime = endTime < change.endTime ? change.endTime : endTime;
    });

  // update any locations to start when the earliest moving object starts
  stateChanges
    .filter((change) => !movingObjects.includes(change.stateType))
    .forEach((change) => {
      change.startTime =
        startTime > change.startTime ? startTime : change.startTime;
      change.endTime = endTime < change.endTime ? endTime : change.endTime;
    });

  const replay = Array.from(
    new Set(
      stateChanges
        .map((stateChange) =>
          startTime > stateChange.startTime ? startTime : stateChange.startTime
        )
        .sort()
    )
  ).reduce(
    (replay, time) => {
      const frames = replay.frames.concat(
        stateChanges
          .filter(
            (stateChange) =>
              stateChange.startTime <= time && time < stateChange.endTime
          )
          .reduce(
            (frame, stateChange) => {
              const {
                stateKey,
                stateType,
                changeIndex,
                startTime,
                endTime,
                point,
                position,
                boundary,
                ...properties
              } = stateChange;
              const groupType = groupTypes.get(stateChange.stateType);

              if (!(groupType in frame.featureCollections)) {
                frame.featureCollections[groupType] = {
                  type: 'FeatureCollection',
                  features: [],
                };
              }

              const index = frame.featureCollections[
                groupType
              ].features.findIndex((feature) => feature.id === stateKey);

              if (index === -1) {
                const initialState = initialStates.find(
                  (state) => state.stateKey === stateKey
                );
                const initialFeature = getInitialFeature(initialState);
                frame.featureCollections[groupType].features =
                  frame.featureCollections[groupType].features.concat({
                    ...initialFeature,
                    geometry:
                      point || position || boundary || initialFeature.geometry,
                    properties: {
                      ...initialFeature.properties,
                      ...properties,
                    },
                  });
              } else {
                const existingFeature =
                  frame.featureCollections[groupType].features[index];
                frame.featureCollections[groupType].features =
                  frame.featureCollections[groupType].features
                    .slice(0, index)
                    .concat({
                      ...existingFeature,
                      geometry:
                        point ||
                        position ||
                        boundary ||
                        existingFeature.geometry,
                      properties: {
                        ...existingFeature.properties,
                        ...properties,
                      },
                    })
                    .concat(
                      frame.featureCollections[groupType].features.slice(
                        index + 1
                      )
                    );
              }

              return frame;
            },
            {
              time,
              featureCollections: {},
            }
          )
      );

      const vehiclePaths = getPaths(initialStates, stateChanges, 'vehicle');
      const personPaths = getPaths(initialStates, stateChanges, 'person');
      let paths = {};
      if (vehiclePaths.length > 0) {
        paths.vehicles = {
          type: 'FeatureCollection',
          features: vehiclePaths,
        };
      }
      if (personPaths.length > 0) {
        paths.people = {
          type: 'FeatureCollection',
          features: personPaths,
        };
      }

      return {
        ...replay,
        frames,
        paths,
      };
    },
    {
      id,
      frames: [],
      paths: {
        vehicles: {
          type: 'FeatureCollection',
          features: [],
        },
        people: {
          type: 'FeatureCollection',
          features: [],
        },
      },
    }
  );

  let frameBySecond = {},
    s = 0;
  const start = moment(startTime).startOf('second');
  replay.frames.forEach((frame, index) => {
    // how many seconds after the start is this frame?
    const diff = moment(frame.time).diff(start, 'seconds');

    // fill in all the seconds with the previous frame (-1 is ok as )
    for (let i = 0; i < diff; s++, i++) {
      frameBySecond[s] = index - 1;
    }

    start.add(diff, 'second');
  });
  frameBySecond[++s] = replay.frames.length - 1;

  log('View', 'Replay', { id, type });

  return {
    ...replay,
    frameBySecond,
  };
}

export function fetchReplayEpic(action$) {
  return action$.pipe(
    ofType(FETCH_REPLAY),
    mergeMap(({ payload: { type, id } }) =>
      from(fetchReplayRequest(type, id)).pipe(
        map((payload) => ({
          type: FETCH_REPLAY_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_REPLAY_FAILURE,
            payload,
          })
        )
      )
    )
  );
}
