import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import {
  Person as PersonIcon,
  PlayArrow as PlayArrowIcon,
} from '@mui/icons-material';
import { Helmet } from 'react-helmet-async';
import moment from 'moment';
import _ from 'lodash';
import { RetrospectiveTypeIcon } from '../../../data/constants';
import AccelerometerChart from '../../events/AccelerometerEvents/AccelerometerChart';
import { DownloadPollsLink } from '../../events/AccelerometerEvents/AccelerometerEvents';

const { useReducedResourceInformation } = window.config;

export default function AccelerometerEvent({
  color,
  background,
  vehicle,
  startTime,
  endTime,
  time,
  accelerometerData,
  maximumForces,
  driver,
  parentEvent,
  deviceProperties,
}) {
  const navigate = useNavigate();

  vehicle = vehicle || {};

  accelerometerData?.forEach(
    (a) =>
      a.time.includes('.000') &&
      (a.speedMilesPerHour = _.round(a.speedKilometresPerHour * 0.62137119, 2))
  );

  const eventDetails = {
    Start: startTime && moment(startTime).format('DD/MM/YYYY, HH:mm:ss'),
    'Event time': time && moment(time).format('DD/MM/YYYY, HH:mm:ss'),
    End: endTime && moment(endTime).format('DD/MM/YYYY, HH:mm:ss'),
    'Max Horizontal Force (g)': maximumForces?.horizontal,
    'Max Vertical Force (g)': maximumForces?.vertical,
    'Max Lateral Force (g)': maximumForces?.lateral,
    'Has Data': !accelerometerData && 'No',
  };

  function handleViewClick() {
    navigate(`/replay/trips/${parentEvent?.identifier}`);
  }

  const cardHeaderButtons = (
    <Box>
      {parentEvent?.identifier && (
        <IconButton
          aria-label="replay"
          title="View Replay"
          onClick={handleViewClick}
          size="large"
        >
          <PlayArrowIcon />
        </IconButton>
      )}
      <DownloadPollsLink
        entry={{
          hasData: !accelerometerData && 'No',
          data: accelerometerData,
        }}
      />
    </Box>
  );

  return (
    <Card sx={{ m: [1, 0.5, 1, 1] }}>
      <Helmet>
        <title>
          IR3 | Poll |{' '}
          {(useReducedResourceInformation
            ? vehicle.fleetNumber
            : vehicle.registrationNumber) || vehicle.telematicsBoxImei}
        </title>
      </Helmet>
      <CardHeader
        avatar={
          <Avatar
            style={{
              background,
              color,
            }}
            title="Vehicle Poll"
          >
            <RetrospectiveTypeIcon type="accelerometerAlerts" />
          </Avatar>
        }
        title={
          (useReducedResourceInformation
            ? vehicle.fleetNumber
            : vehicle.registrationNumber) || vehicle.telematicsBoxImei
        }
        subheader={
          useReducedResourceInformation
            ? vehicle.identificationNumber
            : vehicle.fleetNumber
        }
        action={cardHeaderButtons}
      />
      <CardContent>
        {vehicle && (
          <Fragment>
            <Typography variant="subtitle2" color="textSecondary">
              Vehicle
            </Typography>
            <Table size="small" sx={{ mt: 1, mb: 2 }}>
              <TableBody>
                {!useReducedResourceInformation && (
                  <TableRow>
                    <TableCell>
                      <Typography variant="caption" color="textSecondary">
                        Role
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="caption">{vehicle.role}</Typography>
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell>
                    <Typography variant="caption" color="textSecondary">
                      {useReducedResourceInformation
                        ? 'Home Location'
                        : 'Home Station'}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="caption">
                      {vehicle.homeStation}
                    </Typography>
                  </TableCell>
                </TableRow>
                {(vehicle.areas || []).map((area, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Typography variant="caption" color="textSecondary">
                        {_.startCase(area.type)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="caption">{area.name}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Fragment>
        )}
        {driver && (
          <Fragment>
            <Typography variant="subtitle2" color="textSecondary">
              Driver
            </Typography>
            <ListItem component="div">
              <ListItemAvatar>
                <Avatar>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  useReducedResourceInformation
                    ? driver.code
                    : `${driver.forenames || ''}  ${driver.surname || ''}`
                }
                secondary={
                  !!driver.collarNumber
                    ? driver.collarNumber
                    : driver.identificationReference
                }
              />
            </ListItem>
          </Fragment>
        )}
        <Typography variant="subtitle2" color="textSecondary">
          Event
        </Typography>
        <Table size="small" sx={{ mt: 1, mb: 2 }}>
          <TableBody>
            {Object.keys(eventDetails)
              .filter((title) => eventDetails[title])
              .map((title) => (
                <TableRow key={title}>
                  <TableCell>
                    <Typography variant="caption" color="textSecondary">
                      {title}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="caption">
                      {eventDetails[title]}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {accelerometerData && (
          <AccelerometerChart
            data={accelerometerData}
            triggerPoint={deviceProperties.triggerPoint}
          />
        )}
      </CardContent>
    </Card>
  );
}
