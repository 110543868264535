import {
  FETCH_HOME_STATIONS,
  FETCH_HOME_STATIONS_SUCCESS,
  FETCH_HOME_STATIONS_FAILURE,
  FETCH_WARDS,
  FETCH_WARDS_SUCCESS,
  FETCH_WARDS_FAILURE,
  FETCH_LOCATIONS,
  FETCH_LOCATIONS_SUCCESS,
  FETCH_LOCATIONS_FAILURE,
  FETCH_LOCATION,
  FETCH_LOCATION_SUCCESS,
  FETCH_LOCATION_FAILURE,
  CREATE_LOCATION,
  CREATE_LOCATION_SUCCESS,
  CREATE_LOCATION_FAILURE,
  UPDATE_LOCATION,
  UPDATE_LOCATION_SUCCESS,
  UPDATE_LOCATION_FAILURE,
  DELETE_LOCATION,
  DELETE_LOCATION_SUCCESS,
  DELETE_LOCATION_FAILURE,
} from '../actions';

const INITIAL_STATE = {
  homeStationNames: [],
  wardNames: [],
  locations: [],
  location: null,
  forceExtent: null,
  isLoading: false,
  error: null,
};

export default function locationsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_HOME_STATIONS:
      return { ...state, isLoading: true, error: null };
    case FETCH_HOME_STATIONS_SUCCESS:
      return {
        ...state,
        homeStationNames: action.payload,
        isLoading: false,
        error: null,
      };
    case FETCH_HOME_STATIONS_FAILURE:
      return {
        ...state,
        homeStationNames: [],
        isLoading: false,
        error: action.payload,
      };
    case FETCH_WARDS:
      return { ...state, isLoading: true, error: null };
    case FETCH_WARDS_SUCCESS:
      return {
        ...state,
        wardNames: action.payload,
        isLoading: false,
        error: null,
      };
    case FETCH_WARDS_FAILURE:
      return {
        ...state,
        wardNames: [],
        isLoading: false,
        error: action.payload,
      };
    case FETCH_LOCATIONS:
      return { ...state, isLoading: true, error: null };
    case FETCH_LOCATIONS_SUCCESS:
      return {
        ...state,
        locations: action.payload,
        isLoading: false,
        error: null,
      };
    case FETCH_LOCATIONS_FAILURE:
      return {
        ...state,
        locations: [],
        isLoading: false,
        error: action.payload,
      };
    case FETCH_LOCATION:
      return { ...state, isLoading: true, error: null };
    case FETCH_LOCATION_SUCCESS:
      return {
        ...state,
        location: action.payload,
        isLoading: false,
        error: null,
      };
    case FETCH_LOCATION_FAILURE:
      return {
        ...state,
        location: null,
        isLoading: false,
        error: action.payload,
      };
    case UPDATE_LOCATION:
      return { ...state, isLoading: true, error: null };
    case UPDATE_LOCATION_SUCCESS:
      return {
        ...state,
        location: action.payload,
        locations: state.locations.map((location) =>
          location.code === action.payload.code
            ? {
                code: action.payload.code,
                name: action.payload.name,
                type: action.payload.type,
                searchString: [action.payload.name, action.payload.code]
                  .join('+')
                  .toLowerCase(),
              }
            : location
        ),
        isLoading: false,
        error: null,
      };
    case UPDATE_LOCATION_FAILURE:
      return {
        ...state,
        location: null,
        isLoading: false,
        error: action.payload,
      };
    case CREATE_LOCATION:
      return { ...state, isLoading: true, error: null };
    case CREATE_LOCATION_SUCCESS:
      return {
        ...state,
        location: action.payload,
        locations: state.locations.concat({
          code: action.payload.code,
          name: action.payload.name,
          type: action.payload.type,
          searchString: [action.payload.name, action.payload.code]
            .join('+')
            .toLowerCase(),
        }),
        isLoading: false,
        error: null,
      };
    case CREATE_LOCATION_FAILURE:
      return {
        ...state,
        location: null,
        isLoading: false,
        error: action.payload,
      };
    case DELETE_LOCATION:
      return { ...state, isLoading: true, error: null };
    case DELETE_LOCATION_SUCCESS:
      const index = state.locations.findIndex(
        (location) => location.code === action.payload
      );

      return {
        ...state,
        location: null,
        locations: state.locations
          .slice(0, index)
          .concat(state.locations.slice(index + 1)),
        isLoading: false,
        error: null,
      };
    case DELETE_LOCATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
