import { Paper, IconButton, Toolbar, Typography, Box } from '@mui/material';
import { PlayArrow as PlayArrowIcon } from '@mui/icons-material';
import _ from 'lodash';
import moment from 'moment';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { useSnackbar } from '../Snackbar';
import Container from '../Container';
import { FilterPicker, Parameters, Table, TablePagination } from '../controls';
import ChunkedDownloader from './ChunkedDownloader';
import usePaging from './usePaging';

const { rowsPerPageOptions } = window.config;
const { extraDoubleCrewHeaders } = window.reportHeaders;

const headers = [
  ...extraDoubleCrewHeaders,
  { label: 'Start Time', key: 'startTime', type: 'date', filter: false },
  { label: 'End Time', key: 'endTime', type: 'date', filter: false },
  {
    label: 'Duration (minutes)',
    key: 'durationMinutes',
    serverKey: 'durationSeconds',
    type: 'number',
    filter: false,
  },
];

function ReplayLink({ entry }) {
  const navigate = useNavigate();

  const handleViewClick = (identifier) => () => {
    navigate(`/replay/personDoubleCrews/${identifier}`);
  };

  return (
    <IconButton
      title="View"
      size="small"
      aria-label="View"
      onClick={handleViewClick(entry.identifier)}
    >
      <PlayArrowIcon />
    </IconButton>
  );
}

const replayColumn = {
  label: '',
  key: 'replay',
  type: 'component',
  component: ReplayLink,
};

export default function DoubleCrews() {
  const total = useSelector((state) => state.events.doubleCrews.total);
  const doubleCrews = useSelector(
    (state) => state.events.doubleCrews.list,
    _.isEqual
  );
  const totals = useSelector(
    (state) => state.events.doubleCrews.totals,
    _.isEqual
  );
  const isLoading = useSelector((state) => state.events.doubleCrews.isLoading);
  const error = useSelector((state) => state.events.doubleCrews.error);
  const query = useSelector(
    (state) => state.events.doubleCrews.query,
    _.isEqual
  );
  const filter = useSelector(
    (state) => state.events.doubleCrews.filter,
    _.isEqual
  );
  const snackbar = useSnackbar();
  const eventType = 'doubleCrews';
  const paging = usePaging({ eventType, headers });

  useEffect(() => {
    if (error) {
      snackbar.notify('error', error);
    }
  }, [error, snackbar]);

  const filename = 'Double Crews.csv';
  const csvMapping = (doubleCrew) => ({
    ...doubleCrew,
    startTime: moment(doubleCrew.startTime).format('YYYY-MM-DD HH:mm:ss'),
    endTime: moment(doubleCrew.endTime).format('YYYY-MM-DD HH:mm:ss'),
  });

  return (
    <Container title="Double Crews">
      <Parameters
        onFetch={paging.handleFetch}
        onCancel={paging.handleCancel}
        isFetching={isLoading}
        sx={{ mt: 1, width: 264 }}
        value={query}
        onChange={paging.handleQueryChange}
      />
      <Box
        sx={{
          flex: 1,
          height: 'calc(100vh - 48px)',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <Helmet>
          <title>IR3 | Double Crews</title>
        </Helmet>
        <Toolbar variant="dense" disableGutters sx={{ p: 1, pb: 0 }}>
          <Typography sx={{ flexGrow: 1 }} variant="subtitle1">
            Double Crews
          </Typography>
          <FilterPicker
            headers={headers}
            data={doubleCrews}
            filter={filter}
            filterValues={filter.filterValues}
            onFilterChange={paging.onFilterChange}
          />
          <ChunkedDownloader
            eventType={eventType}
            filename={filename}
            headers={headers}
            csvMapping={csvMapping}
          />
        </Toolbar>
        <Paper sx={{ m: [0, 1, 1], minWidth: 240 }}>
          <Box sx={isLoading ? { opacity: 0.5 } : undefined}>
            <Table
              styles={{
                tableContainer: {
                  height: 'calc(100vh - 172px)',
                  overflowY: 'scroll',
                },
                table: {
                  minWidth: 750,
                },
              }}
              data={doubleCrews}
              headers={headers.concat(replayColumn)}
              rowsPerPage={filter.rowsPerPage}
              page={0}
              order={filter.order}
              orderBy={filter.orderBy}
              onOrderChange={paging.handleOrderChange}
              onOrderByChange={paging.handleOrderByChange}
              totals={totals}
            />
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              component="div"
              count={total ?? 0}
              rowsPerPage={filter.rowsPerPage}
              page={filter.page}
              onPageChange={paging.handlePageChange}
              onRowsPerPageChange={paging.handleRowsPerPageChange}
            />
          </Box>
        </Paper>
      </Box>
    </Container>
  );
}
