import { useRef, useContext, createContext, useState } from 'react';
import axios from 'axios';
import db from '../data/db';
import * as Sentry from '@sentry/react';
import { useNavigate } from 'react-router-dom';

const {
  authenticationRootUrl,
  tenantId,
  authenticationScheme,
  clientId,
  resource,
  apiRootUrl,
  apiPath,
  basename,
  sentry,
} = window.config;

const redirectUri = `${window.location.origin}${
  basename === '/' ? '' : basename
}/callback`;

const AuthContext = createContext();

export const useAuth = () => useRef(useContext(AuthContext)).current;

export function AuthProvider({ children }) {
  // this is purely used to update the component when auth is handled
  const [authAttempts, setAuthAttempts] = useState(0);
  const navigate = useNavigate();

  function login() {
    const state = Math.floor(Math.random() * 10000);
    localStorage.setItem('state', state);
    const authUrl = `${authenticationRootUrl}/${tenantId}/${authenticationScheme}/authorize?client_id=${clientId}&response_type=code&redirect_uri=${encodeURIComponent(
      redirectUri
    )}&response_mode=query&state=${state}&resource=${encodeURIComponent(
      resource
    )}`;
    window.location.href = authUrl;
  }

  async function getAccessToken(code) {
    const response = await axios.post(
      `${apiRootUrl}/${authenticationScheme}/token`,
      `grant_type=authorization_code&client_id=${clientId}&code=${code}&redirect_uri=${encodeURIComponent(
        redirectUri
      )}&resource=${encodeURIComponent(resource)}`,
      { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
    );

    return response.data;
  }

  async function getUserInfo() {
    const response = await axios.get(`${apiRootUrl}${apiPath}/userinfo`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        'Cache-control': 'no-cache, no-store, must-revalidate',
        Pragma: 'no-cache',
        Expires: 0,
      },
    });

    return response.data;
  }

  async function getRefreshedAccessToken() {
    const refreshToken = localStorage.getItem('refresh_token');
    const response = await axios.post(
      `${apiRootUrl}/${authenticationScheme}/token`,
      `grant_type=refresh_token&client_id=${clientId}&refresh_token=${refreshToken}&redirect_uri=${encodeURIComponent(
        redirectUri
      )}&resource=${encodeURIComponent(resource)}`,
      { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
    );

    return response.data;
  }

  async function handleAuthentication() {
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');
    const error = params.get('error');
    const sameState = params.get('state') === localStorage.getItem('state');

    if (sameState && code) {
      localStorage.removeItem('state');
      const authResult = await getAccessToken(code);
      if (
        authResult &&
        authResult.access_token &&
        authResult.id_token &&
        authResult.refresh_token
      ) {
        setSession(authResult);

        const sessionTimeoutId = window.setTimeout(
          handleRefresh,
          authResult.expires_in * 1000
        );
        localStorage.setItem('session_timeout_id', sessionTimeoutId);

        const userInfo = await getUserInfo();
        localStorage.setItem('emailAddress', userInfo.emailAddress);
        localStorage.setItem('surname', userInfo.surname);
        localStorage.setItem('forenames', userInfo.forenames);
        localStorage.setItem('displayName', userInfo.displayName);
        localStorage.setItem('username', userInfo.username);
        localStorage.setItem('identifiers', JSON.stringify(userInfo.groups));
        localStorage.setItem(
          'authorisation',
          JSON.stringify(userInfo.authorisation)
        );
        localStorage.setItem('person', JSON.stringify(userInfo.person));

        // Set user to identify in Sentry
        if (sentry.enable) {
          Sentry.setUser({ email: userInfo.username });
        }

        navigate('/', { replace: true });
      }
    } else if (error) {
      navigate('/', { replace: true });
      console.log(params.get('error_description'));
      alert(`Error: ${error}. Check the console for further details.`);
    }

    setAuthAttempts(authAttempts + 1);
  }

  function setupRefresh() {
    const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    const expiresIn = expiresAt - new Date().getTime();
    // console.log('existing token expires ' + new Date(JSON.parse(localStorage.getItem('expires_at'))).toLocaleString());

    if (expiresIn > 0) {
      const sessionTimeoutId = window.setTimeout(handleRefresh, expiresIn);
      localStorage.setItem('session_timeout_id', sessionTimeoutId);
      // console.log('refresh setup');
    } else if (expiresAt) {
      handleRefresh(true);
    }
  }

  async function handleRefresh(refreshScreen) {
    try {
      const authResult = await getRefreshedAccessToken();
      if (authResult && authResult.access_token && authResult) {
        refreshSession(authResult);
        const sessionTimeoutId = window.setTimeout(
          handleRefresh,
          authResult.expires_in * 1000
        );
        localStorage.setItem('session_timeout_id', sessionTimeoutId);

        const userInfo = await getUserInfo();
        localStorage.setItem('emailAddress', userInfo.emailAddress);
        localStorage.setItem('surname', userInfo.surname);
        localStorage.setItem('forenames', userInfo.forenames);
        localStorage.setItem('displayName', userInfo.displayName);
        localStorage.setItem('username', userInfo.username);
        localStorage.setItem('identifiers', JSON.stringify(userInfo.groups));
        localStorage.setItem(
          'authorisation',
          JSON.stringify(userInfo.authorisation)
        );
        localStorage.setItem('person', JSON.stringify(userInfo.person));

        if (refreshScreen) {
          window.location.reload(); // maybe?
        }
      } else {
        navigate('/', { replace: true });
        // console.log(authResult);
      }
    } catch (error) {
      navigate('/', { replace: true });
      console.log(error);
    }
  }

  function refreshSession(authResult) {
    // Set the time that the access token will expire at
    const expiresAt = JSON.stringify(
      authResult.expires_in * 1000 + new Date().getTime()
    );
    localStorage.setItem('access_token', authResult.access_token);
    if (authResult.refresh_token) {
      localStorage.setItem('refresh_token', authResult.refresh_token);
    }
    localStorage.setItem('expires_at', expiresAt);

    // console.log('session refreshed ' + new Date((authResult.expires_in * 1000) + new Date().getTime()).toLocaleString());
  }

  function setSession(authResult) {
    // Set the time that the access token will expire at
    const expiresAt = JSON.stringify(
      authResult.expires_in * 1000 + new Date().getTime()
    );
    localStorage.setItem('access_token', authResult.access_token);
    localStorage.setItem('id_token', authResult.id_token);
    localStorage.setItem('refresh_token', authResult.refresh_token);
    localStorage.setItem('expires_at', expiresAt);
    // navigate to the home route
    navigate('/', { replace: true });
  }

  async function logout() {
    axios.delete(`${apiRootUrl}/${authenticationScheme}/logout`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        'Cache-control': 'no-cache, no-store, must-revalidate',
        Pragma: 'no-cache',
        Expires: 0,
      },
    });

    // Clear access token and ID token from local storage
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('expires_at');

    localStorage.removeItem('emailAddress');
    localStorage.removeItem('surname');
    localStorage.removeItem('forenames');
    localStorage.removeItem('displayName');
    localStorage.removeItem('username');
    localStorage.removeItem('identifiers');
    localStorage.removeItem('authorisation');
    localStorage.removeItem('person');

    // Clear the next token refresh attempt and  remove the ID frome local storage
    const sessionTimeoutId = localStorage.getItem('session_timeout_id');
    window.clearTimeout(sessionTimeoutId);
    localStorage.removeItem('session_timeout_id');

    db.delete();

    const logoutUrl = `${authenticationRootUrl}/${tenantId}/${authenticationScheme}/logout`;
    window.location.href = logoutUrl;
  }

  function isAuthenticated() {
    // Check whether the current time is past the
    // access token's expiry time
    const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    return new Date().getTime() < expiresAt;
  }

  function getAuthorisation() {
    return {
      ...(JSON.parse(localStorage.getItem('authorisation')) || {}),
      ...(window.config.authorizationOverrides || {}),
    };
  }

  function isAuthorised(type, canEdit) {
    const authorisation = getAuthorisation();

    if (type === 'resources') {
      return canEdit
        ? (authorisation.vehicles || {}).write ||
            (authorisation.people || {}).write ||
            (authorisation.vehicles || {}).write ||
            (authorisation.telematicsBoxes || {}).write
        : (authorisation.vehicles || {}).read ||
            (authorisation.people || {}).read ||
            (authorisation.vehicles || {}).read ||
            (authorisation.telematicsBoxes || {}).read;
    } else {
      return canEdit
        ? (authorisation[type] || {}).write
        : (authorisation[type] || {}).read;
    }
  }

  function getUsername() {
    return localStorage.getItem('username');
  }

  function getDirectoryGroupIdentifiers() {
    const identifiers = JSON.parse(localStorage.getItem('identifiers'));

    return identifiers || [];
  }

  function getGroupNames() {
    return getAuthorisation().names || [];
  }

  function getProfile() {
    const person = JSON.parse(localStorage.getItem('person'));

    return person || {};
  }

  return (
    <AuthContext.Provider
      value={{
        login,
        handleAuthentication,
        logout,
        isAuthenticated,
        isAuthorised,
        setupRefresh,
        getUsername,
        getDirectoryGroupIdentifiers,
        getGroupNames,
        getProfile,
        getAuthorisation,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
