import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import _ from 'lodash';
import {
  FETCH_PEOPLE,
  FETCH_PEOPLE_SUCCESS,
  FETCH_PEOPLE_FAILURE,
  FETCH_PERSON,
  FETCH_PERSON_SUCCESS,
  FETCH_PERSON_FAILURE,
  CREATE_PERSON,
  CREATE_PERSON_SUCCESS,
  CREATE_PERSON_FAILURE,
  UPDATE_PERSON,
  UPDATE_PERSON_SUCCESS,
  UPDATE_PERSON_FAILURE,
  DELETE_PERSON,
  DELETE_PERSON_SUCCESS,
  DELETE_PERSON_FAILURE,
  FETCH_POST_HIERARCHY,
  FETCH_POST_HIERARCHY_SUCCESS,
  FETCH_POST_HIERARCHY_FAILURE,
} from '../actions';
import { fromAjax } from '../apis';
import {
  getHeaders,
  log,
  areasToGroups,
  groupsToAreas,
} from '../apis/utilities';

const { personRanks, useRestricted } = window.config;

export function fetchPeopleEpic(action$) {
  return action$.pipe(
    ofType(FETCH_PEOPLE),
    mergeMap(() =>
      fromAjax('/people', {
        params: {
          projection: {
            code: true,
            picture: true,
            forenames: true,
            surname: true,
            collarNumber: true,
            radioSsi: true,
            lastPollTime: true,
            rfidCards: true,
            role: true,
            homeStation: true,
            leavingDate: true,
          },
        },
        headers: getHeaders(),
      }).pipe(
        map(({ response }) => {
          const payload = (response || []).map((person) => {
            return {
              ...person,
              searchString: (
                `${person.forenames} ${person.surname}+` +
                `${_.join(
                  (person.rfidCards || []).map((card) => card.reference),
                  '+'
                )}+` +
                `${_.join(
                  _.values(
                    _.omit(person, [
                      'forenames',
                      'surname',
                      'rfidCards',
                      'picture',
                    ])
                  ),
                  '+'
                )}`
              ).toLowerCase(),
            };
          });

          log('Read', 'People');

          return {
            type: FETCH_PEOPLE_SUCCESS,
            payload,
          };
        }),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_PEOPLE_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

export function fetchPersonEpic(action$) {
  return action$.pipe(
    ofType(FETCH_PERSON),
    mergeMap(({ payload: id }) =>
      fromAjax(`/people/${id}`, {
        params: {
          projection: {
            code: true,
            picture: true,
            forenames: true,
            surname: true,
            collarNumber: true,
            emailAddress: true,
            supervisorCode: true,
            postNumber: true,
            rank: true,
            role: true,
            homeStation: true,
            wards: true,
            areas: true,
            skills: true,
            radioSsi: true,
            lastPollTime: true,
            rfidCards: true,
            ward: true,
            supervisorCodes: true,
            leavingDate: true,
            groups: true,
          },
        },
        headers: getHeaders(),
      }).pipe(
        map(({ response: { areas, groups, ...response } }) => {
          const payload = {
            ...response,
            areas:
              groups && Object.keys(groups).length > 0
                ? groupsToAreas(groups)
                : areas,
            groups:
              areas && (!groups || Object.keys(groups).length === 0)
                ? areasToGroups(areas)
                : groups,
          };

          log('Read', 'Person', { id });

          return {
            type: FETCH_PERSON_SUCCESS,
            payload,
          };
        }),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_PERSON_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

export function createPersonEpic(action$) {
  return action$.pipe(
    ofType(CREATE_PERSON),
    mergeMap(({ payload, navigate }) =>
      fromAjax(`/people`, {
        body: {
          ...payload,
          restricted:
            useRestricted && payload.restricted === undefined
              ? false
              : payload.restricted,
          rank: payload.rank
            ? personRanks.find((item) => item.code === payload.rank.code)
            : undefined,
        },
        method: 'POST',
        headers: { ...getHeaders(), 'Content-Type': 'application/json' },
      }).pipe(
        map(({ response: payload }) => {
          log('Create', 'Person', payload);

          navigate(`../${payload.code}`, {
            replace: true,
            state: { created: true },
          });

          return {
            type: CREATE_PERSON_SUCCESS,
            payload,
          };
        }),
        catchError(({ message: payload }) =>
          of({
            type: CREATE_PERSON_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

export function updatePersonEpic(action$) {
  return action$.pipe(
    ofType(UPDATE_PERSON),
    mergeMap(({ payload }) =>
      fromAjax(`/people/${payload.code}`, {
        body: {
          ...payload,
          rank: payload.rank
            ? personRanks.find((item) => item.code === payload.rank.code)
            : undefined,
        },
        method: 'PATCH',
        headers: {
          ...getHeaders(),
          'Content-Type': 'application/merge-patch+json',
        },
      }).pipe(
        map(({ response: payload }) => {
          log('Update', 'Person', payload);

          return {
            type: UPDATE_PERSON_SUCCESS,
            payload,
          };
        }),
        catchError(({ message: payload }) =>
          of({
            type: UPDATE_PERSON_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

export function deletePersonEpic(action$) {
  return action$.pipe(
    ofType(DELETE_PERSON),
    mergeMap(({ payload: id, navigate }) =>
      fromAjax(`/people/${id}`, {
        method: 'DELETE',
        headers: getHeaders(),
      }).pipe(
        map(({ response }) => {
          log('Delete', 'Person', { id });

          navigate('.');

          return {
            type: DELETE_PERSON_SUCCESS,
            payload: response.code,
          };
        }),
        catchError(({ message: payload }) =>
          of({
            type: DELETE_PERSON_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

export function fetchPostHierarchyEpic(action$) {
  return action$.pipe(
    ofType(FETCH_POST_HIERARCHY),
    mergeMap(() =>
      fromAjax('/groups/POSTS', {
        params: {
          query: {
            fields: ['data'],
          },
        },
        headers: getHeaders(),
      }).pipe(
        map(({ response }) => {
          return {
            type: FETCH_POST_HIERARCHY_SUCCESS,
            payload: response.data,
          };
        }),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_POST_HIERARCHY_FAILURE,
            payload,
          })
        )
      )
    )
  );
}
