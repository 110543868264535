import { Box, IconButton, InputAdornment, Typography } from '@mui/material';
import {
  AddCircle as AddIcon,
  ArrowUpward as ArrowUpwardIcon,
  RemoveCircle as RemoveIcon,
} from '@mui/icons-material';
import Field from './Field';
import SelectField from './SelectField';

function getSubjects(type) {
  switch (type) {
    case 'vehicleTrips':
    case 'vehicleIdles':
    case 'vehicleIdleCount':
      return [
        { label: 'Event', value: 'event' },
        { label: 'Vehicle', value: 'vehicle' },
        { label: 'Driver', value: 'driver' },
      ];
    case 'vehicleTime':
      return [
        { label: 'Vehicle', value: 'vehicle' },
        { label: 'Driver', value: 'driver' },
      ];
    case 'vehicleStops':
    case 'vehicleStopCount':
      return [
        { label: 'Event', value: 'event' },
        { label: 'Vehicle', value: 'vehicle' },
        { label: 'Last Driver', value: 'lastDriver' },
      ];
    case 'vehiclePolls':
      return [{ label: 'Vehicle', value: 'vehicle' }];
    case 'vehicleVisits':
    case 'vehicleCustomVisits':
    case 'vehicleVisitCount':
      return [
        { label: 'Location', value: 'location' },
        { label: 'Vehicle', value: 'vehicle' },
        { label: 'Driver', value: 'driver' },
        { label: 'Event', value: 'event' },
      ];
    case 'incidents':
    case 'incidentCount':
      return [{ label: 'Event', value: 'event' }];
    case 'personTrails':
    case 'personTime':
    case 'personPolls':
      return [
        { label: 'Person', value: 'person' },
        { label: 'Event', value: 'event' },
      ];
    case 'personVisits':
    case 'personCustomVisits':
    case 'personVisitCount':
      return [
        { label: 'Location', value: 'location' },
        { label: 'Person', value: 'person' },
        { label: 'Event', value: 'event' },
      ];
    case 'locations':
      return [
        { label: 'Location', value: 'location' },
        { label: 'Person', value: 'person' },
      ];
    case 'aggregates':
      return [{ label: 'Event', value: 'event' }];
    case 'accelerometerEvents':
      return [
        { label: 'Event', value: 'event' },
        { label: 'Vehicle', value: 'vehicle' },
      ];
    default:
      return [];
  }
}

function getFilterName(type, subject) {
  switch (subject) {
    case 'event':
      switch (type) {
        case 'vehicleTrips':
          return 'trip';
        case 'vehicleIdles':
        case 'vehicleIdleCount':
        case 'vehicleStops':
        case 'vehicleStopCount':
        case 'personVisits':
        case 'personCustomVisits':
        case 'vehicleVisits':
        case 'vehicleCustomVisits':
          return 'stop';
        case 'incidents':
        case 'incidentCount':
          return 'incident';
        case 'aggregates':
          return 'aggregate';
        case 'accelerometerEvents':
        case 'personPolls':
          return 'personPoll';
        case 'personTrails':
          return 'personTrail';
        default:
          return 'event';
      }
    case 'lastDriver':
    case 'driver':
      return 'person';
    default:
      return subject;
  }
}

function DirectionField({ input, position }) {
  function handleSortToggle() {
    const value = input.value === 'asc' ? 'desc' : 'asc';
    input.onChange(value);
  }

  return (
    <InputAdornment position="start">
      <IconButton
        title={input.value === 'desc' ? 'Descending' : 'Ascending'}
        sx={(theme) =>
          input.value === 'desc'
            ? {
                transform: 'rotate(180deg)',
                transition: theme.transitions.create('transform', {
                  duration: theme.transitions.duration.shortest,
                }),
              }
            : {
                transform: 'rotate(0deg)',
                transition: theme.transitions.create('transform', {
                  duration: theme.transitions.duration.shortest,
                }),
              }
        }
        onClick={handleSortToggle}
        size="small"
      >
        <ArrowUpwardIcon fontSize="inherit" />
      </IconButton>
    </InputAdornment>
  );
}

export default function SortField({
  fields,
  type,
  filters,
  label,
  clearValue,
}) {
  const subjects = getSubjects(type);

  function handleAddClick() {
    fields.push({ direction: 'asc' });
  }

  const handleRemoveClick = (index) => () => {
    fields.remove(index);
  };

  const handleSubjectChange = (name) => () => {
    clearValue(name);
  };

  return (
    <Box>
      <Typography variant="subtitle2" color="textSecondary" sx={{ mb: 0.5 }}>
        {label}
      </Typography>
      <Box>
        {fields.map((name, index) => {
          const subject = fields.value[index]?.subject;
          const fieldValues = subject
            ? Object.entries(filters[getFilterName(type, subject)] || {}).map(
                (filter) => ({
                  label: filter[1].label,
                  value: filter[0],
                })
              )
            : [];
          fieldValues.sort((a, b) => a.label.localeCompare(b.label));

          return (
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
              }}
              key={index}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  flex: 1,
                  maxWidth: 'calc(100% - 48px)',
                }}
              >
                <Field
                  name={`${name}.subject`}
                  component={SelectField}
                  label="Subject"
                  values={subjects}
                  sx={{ mr: 1, mb: 1, width: 160 }}
                  onChange={handleSubjectChange(`${name}.field`)}
                />
                <Field
                  name={`${name}.field`}
                  component={SelectField}
                  label="Field"
                  values={fieldValues}
                  sx={{ mr: 1, mb: 1, flex: 1 }}
                  InputProps={{
                    endAdornment: (
                      <Field
                        name={`${name}.direction`}
                        component={DirectionField}
                        position="start"
                      />
                    ),
                  }}
                />
              </Box>
              <IconButton
                sx={{ mt: 1 }}
                title="Remove"
                aria-label="Remove"
                onClick={handleRemoveClick(index)}
                size="large"
              >
                <RemoveIcon />
              </IconButton>
            </Box>
          );
        })}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
        }}
      >
        <Box sx={{ flex: 1 }} />
        <IconButton
          sx={{ mt: 1 }}
          title="Add"
          aria-label="Add"
          onClick={handleAddClick}
          size="large"
        >
          <AddIcon />
        </IconButton>
      </Box>
    </Box>
  );
}
