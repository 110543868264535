import { Box, Chip, Tooltip } from '@mui/material';
import _ from 'lodash';
import { Fragment } from 'react';

const { useReducedResourceInformation } = window.config;

export default function Occupants({ entry }) {
  if (!entry.occupants) {
    return <Fragment />;
  }

  const content = () =>
    entry.occupants
      .sort((a, b) => b.ratio - a.ratio)
      .map(
        (
          {
            code,
            collarNumber,
            forenames,
            surname,
            radioSsi,
            ratio,
            distanceKilometres,
          },
          index
        ) => {
          const name = [forenames, surname, collarNumber && `[${collarNumber}]`]
            .filter(Boolean)
            .join(' ')
            .trim();

          function orRadioSsi(text) {
            return !!text ? text : radioSsi;
          }

          const percent = _.round(100 * ratio);
          const distance = _.round(distanceKilometres * 0.62137119, 2);

          return (
            <tr key={index}>
              <td style={{ whiteSpace: 'nowrap' }} align="left">
                {orRadioSsi(useReducedResourceInformation ? code : name)}
              </td>
              <td style={{ whiteSpace: 'nowrap' }} align="right">
                {`${percent}%`}
              </td>
              <td style={{ whiteSpace: 'nowrap' }} align="right">
                {`${distance} mi`}
              </td>
            </tr>
          );
        }
      );

  return (
    <Tooltip
      sx={{ maxWidth: 'none' }}
      title={
        <Box sx={{ fontSize: '0.875rem', fontWeight: 'normal' }}>
          {entry.occupants.length ? (
            <table>
              <thead>
                <tr>
                  <th align="left">Name</th>
                  <th align="right">Portion</th>
                  <th align="right">Distance</th>
                </tr>
              </thead>
              <tbody>{content()}</tbody>
            </table>
          ) : (
            'No occupants detected'
          )}
        </Box>
      }
    >
      <Chip
        label={`${entry.occupants.length}`}
        color={entry.occupants.length ? 'primary' : 'default'}
      />
    </Tooltip>
  );
}
