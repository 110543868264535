import {
  Avatar,
  Paper,
  CardContent,
  Collapse,
  IconButton,
  Toolbar,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Box,
  Stack,
} from '@mui/material';
import {
  FilterList as FilterListIcon,
  GetApp as GetAppIcon,
} from '@mui/icons-material';
import _ from 'lodash';
import moment from 'moment';
import { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Area,
  AreaChart,
  Brush,
  Label,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Helmet } from 'react-helmet-async';
import { useSnackbar } from '../../Snackbar';
import {
  FETCH_VEHICLE_IN_BASE_TIME,
  FETCH_VEHICLE_IN_BASE_TIME_CANCELLED,
  LOAD_VEHICLE_IN_BASE_TIME,
} from '../../../actions';
import db from '../../../data/db';
import Container from '../../Container';
import { Parameters, SelectMultiple, CustomTooltip } from '../../controls';
import { randomHsl } from '../../../data/constants';
import { downloadCSV } from '../../../apis/utilities';

const { useReducedResourceInformation } = window.config;

const headers = [
  { label: 'VIN', key: 'identificationNumber' },
  ...(useReducedResourceInformation
    ? [
        { label: 'Fleet Number', key: 'fleetNumber' },
        { label: 'Vehicle Type', key: 'type' },
      ]
    : [
        { label: 'Registration', key: 'registrationNumber' },
        { label: 'Fleet Number', key: 'fleetNumber' },
        { label: 'Vehicle Role', key: 'role' },
      ]),
  { label: 'Location Name', key: 'locationName' },
  { label: 'Location Type', key: 'locationType' },
  { label: 'Hour', key: 'hour' },
  { label: 'Duration (hours)', key: 'durationHours' },
];

export default function HourlyInLocations(props) {
  const dispatch = useDispatch();
  const data = useSelector(
    (state) => state.reports.vehicleInBaseTime.data,
    _.isEqual
  );
  const isLoading = useSelector(
    (state) => state.reports.vehicleInBaseTime.isLoading
  );
  const error = useSelector((state) => state.reports.vehicleInBaseTime.error);
  const filter = useSelector(
    (state) => state.reports.vehicleInBaseTime.filter,
    _.isEqual
  );
  const filterValues = useSelector(
    (state) => state.reports.vehicleInBaseTime.filterValues,
    _.isEqual
  );
  const [showFilter, setShowFilter] = useState(false);
  const [hiddenLines, setHiddenLines] = useState([]);
  const snackbar = useSnackbar();

  useEffect(() => {
    if (error) {
      snackbar.notify('error', error);
    }
  }, [error, snackbar]);

  useEffect(() => {
    dispatch({
      type: LOAD_VEHICLE_IN_BASE_TIME,
      payload: filter,
    });
  }, [filter, dispatch]);

  function handleFilterToggle() {
    setShowFilter(!showFilter);
  }

  function handleFetch(event, query) {
    dispatch({
      type: FETCH_VEHICLE_IN_BASE_TIME,
      payload: { query, filter },
    });
  }

  function handleCancel() {
    dispatch({
      type: FETCH_VEHICLE_IN_BASE_TIME_CANCELLED,
    });
  }

  function handleFilterFieldChanged(name, value) {
    const newFilter =
      name in filter
        ? {
            ...filter,
            [name]: value || [],
          }
        : {
            ...filter,
            areas: {
              ...filter.areas,
              [name]: value || [],
            },
          };

    dispatch({
      type: LOAD_VEHICLE_IN_BASE_TIME,
      payload: newFilter,
    });
  }

  function handleLegendClick(selectedLine) {
    const index = hiddenLines.indexOf(selectedLine);

    if (index === -1) {
      setHiddenLines(hiddenLines.concat(selectedLine));
    } else {
      setHiddenLines(
        hiddenLines.slice(0, index).concat(hiddenLines.slice(index + 1))
      );
    }
  }

  function getAreaHeaders() {
    return Object.keys(filterValues.areas).map((key) => ({
      label: _.startCase(key),
      key,
    }));
  }

  async function handleDownloadClick() {
    const allHeaders = headers.concat(getAreaHeaders());
    const filename = 'Hourly In Locations.csv';
    const dataArray = await db.vehicleInBaseTime.toArray();
    const data = dataArray[0].map(
      ({ time, areas, durationSeconds, ...record }) => ({
        ...record,
        time: moment(time).format('YYYY-MM-DD HH:mm:ss'),
        durationHours: _.round(durationSeconds / 3600, 2),
        ...areas,
      })
    );

    downloadCSV(data, filename, allHeaders);
  }

  function getTableHeaders() {
    const headers = [];
    for (let i = 0; i < 24; i++) {
      const key = `${i.toString().padStart(2, '0')}:00`;
      headers.push(key);
    }
    return headers;
  }

  function buildDataForTable(data) {
    const result = {};
    for (let i = 0; i < 24; i++) {
      const key = `${i.toString().padStart(2, '0')}:00`;
      const hourObj = data[i];
      const objWithoutHour = _.omit(hourObj, ['Hour']);
      result[key] = objWithoutHour;
    }
    return result;
  }

  const tableData = buildDataForTable(data);
  const uniqueLocations = _.union(
    _.flatten(_.map(data, (e) => _.keys(e)))
  ).filter((key) => key !== 'Hour');

  return (
    <Container title="Hourly in Location">
      <Parameters
        onFetch={handleFetch}
        onCancel={handleCancel}
        isFetching={isLoading}
        sx={{ mt: 1, width: 264 }}
        dateOnly
        vehicle
        location
      />
      <Box
        sx={{
          width: 1,
          height: 'calc(100vh - 48px)',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <Helmet>
          <title>IR3 | Hourly in Location</title>
        </Helmet>
        <Toolbar variant="dense" disableGutters sx={{ p: 1, pb: 0 }}>
          <Typography sx={{ flexGrow: 1 }} variant="subtitle1">
            Average Vehicles in Location by Hour
          </Typography>
          <IconButton
            title={showFilter ? 'Hide filter' : 'Show filter'}
            onClick={() => handleFilterToggle()}
          >
            <FilterListIcon color={showFilter ? 'primary' : 'inherit'} />
          </IconButton>
          <IconButton
            title="Download data"
            disabled={data.length === 0}
            onClick={handleDownloadClick}
          >
            <GetAppIcon />
          </IconButton>
        </Toolbar>
        <Collapse in={showFilter} timeout="auto">
          <Stack spacing={1.5} sx={{ flex: 1, p: 1.5 }}>
            {!useReducedResourceInformation && (
              <SelectMultiple
                label="Registration Number"
                placeholder="Select..."
                value={filter.registrationNumber}
                labelValue
                onChange={(value) =>
                  handleFilterFieldChanged('registrationNumber', value)
                }
                suggestions={filterValues.registrationNumber.map((value) => ({
                  label: value,
                  value,
                }))}
              />
            )}

            <SelectMultiple
              label="Fleet Number"
              placeholder="Select..."
              value={filter.fleetNumber}
              labelValue
              onChange={(value) =>
                handleFilterFieldChanged('fleetNumber', value)
              }
              suggestions={filterValues.fleetNumber.map((value) => ({
                label: value,
                value,
              }))}
            />
            {useReducedResourceInformation ? (
              <SelectMultiple
                label="Type"
                placeholder="Select..."
                value={filter.type}
                labelValue
                onChange={(value) => handleFilterFieldChanged('type', value)}
                suggestions={filterValues.type.map((value) => ({
                  label: value,
                  value,
                }))}
              />
            ) : (
              <SelectMultiple
                label="Role"
                placeholder="Select..."
                value={filter.role}
                labelValue
                onChange={(value) => handleFilterFieldChanged('role', value)}
                suggestions={filterValues.role.map((value) => ({
                  label: value,
                  value,
                }))}
              />
            )}
            <SelectMultiple
              label="Location Name"
              placeholder="Select..."
              value={filter.locationName}
              labelValue
              onChange={(value) =>
                handleFilterFieldChanged('locationName', value)
              }
              suggestions={filterValues.locationName.map((value) => ({
                label: value,
                value,
              }))}
            />
            <SelectMultiple
              label="Location Type"
              placeholder="Select..."
              value={filter.locationType}
              labelValue
              onChange={(value) =>
                handleFilterFieldChanged('locationType', value)
              }
              suggestions={filterValues.locationType.map((value) => ({
                label: value,
                value,
              }))}
            />
            {Object.entries(filterValues.areas).map((entry) => {
              return (
                <SelectMultiple
                  key={entry[0]}
                  label={_.startCase(entry[0])}
                  placeholder="Select..."
                  value={filter.areas[entry[0]] || []}
                  labelValue
                  onChange={(value) =>
                    handleFilterFieldChanged(entry[0], value)
                  }
                  suggestions={entry[1].map((value) => ({
                    label: value,
                    value,
                  }))}
                />
              );
            })}
          </Stack>
        </Collapse>
        <Paper sx={{ m: [0, 1, 1], minWidth: 240, fontSize: 12 }}>
          <CardContent sx={{ p: 0, pt: 4 }}>
            <Box
              sx={{
                pl: 8,
                pr: 2,
                pb: 1,
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
              }}
            >
              {Object.keys(data).length > 1 &&
                Object.keys(data[0])
                  .filter((key) => key !== 'Hour')
                  .map((locationName, index) => {
                    const count = Object.keys(data[0]).length - 1;
                    const colour = randomHsl(index, count);
                    return (
                      <Box
                        key={locationName}
                        sx={{
                          p: 0.5,
                          display: 'flex',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                        onClick={() => handleLegendClick(locationName)}
                      >
                        <Avatar
                          sx={{
                            width: 12,
                            height: 12,
                            mr: 0.5,
                            bgcolor:
                              !hiddenLines.includes(locationName) && colour,
                          }}
                        >
                          <Fragment />
                        </Avatar>
                        <Typography variant="caption">
                          {locationName}
                        </Typography>
                      </Box>
                    );
                  })}
            </Box>
            <ResponsiveContainer width="99%" height={600}>
              <AreaChart
                data={Object.values(data)}
                margin={{ top: 0, right: 16, left: 0, bottom: 32 }}
              >
                <XAxis dataKey="Hour" interval={0}>
                  <Label value="Hour" offset={36} position="bottom" />
                </XAxis>
                <YAxis>
                  <Label
                    value="Count"
                    angle={-90}
                    position="left"
                    offset={-24}
                  />
                </YAxis>
                {Object.keys(data).length > 1 && (
                  <Tooltip
                    cursor={false}
                    content={
                      <CustomTooltip
                        unit="vehicles"
                        height={400}
                        captureMouseMove
                      />
                    }
                    wrapperStyle={{ pointerEvents: 'auto' }}
                  />
                )}
                {Object.keys(data).length > 1 && (
                  <Brush dataKey="Hour" height={24} />
                )}
                {Object.keys(data).length > 1 &&
                  Object.keys(data[0])
                    .filter((key) => key !== 'Hour')
                    .reverse()
                    .map((locationName, index) => {
                      const count = Object.keys(data[0]).length - 1;
                      const colour = randomHsl(index, count);
                      return (
                        <Area
                          key={locationName}
                          type="monotone"
                          stackId="1"
                          dataKey={locationName}
                          hide={hiddenLines.includes(locationName)}
                          stroke={colour}
                          fill={colour}
                        />
                      );
                    })}
              </AreaChart>
            </ResponsiveContainer>
          </CardContent>
        </Paper>
        <Toolbar variant="dense" disableGutters sx={{ p: 1, pb: 0 }}>
          <Typography sx={{ flexGrow: 1 }} variant="subtitle1">
            Hourly in Location
          </Typography>
        </Toolbar>
        <Paper key="table" sx={{ m: [0, 1, 1], minWidth: 240, fontSize: 12 }}>
          <TableContainer sx={{ overflowX: 'auto' }}>
            <Table stickyHeader sx={{ width: 1 }}>
              <TableHead>
                <TableRow>
                  <TableCell>Location</TableCell>
                  {getTableHeaders().map((h) => (
                    <TableCell key={h}>{h}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {uniqueLocations.map((key) => (
                  <TableRow key={key}>
                    <TableCell component="th" scope="row">
                      {key}
                    </TableCell>
                    {getTableHeaders().map((header, index) => (
                      <TableCell key={index}>
                        {tableData[header][key]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </Container>
  );
}
