import { useEffect } from 'react';
import { TablePagination as MuiTablePagination } from '@mui/material';

export default function TablePagination({
  rowsPerPageOptions,
  component,
  count,
  rowsPerPage,
  page,
  onPageChange,
  onRowsPerPageChange,
}) {
  const totalPages = ~~(count / rowsPerPage);

  useEffect(() => {
    if (page > totalPages) {
      onPageChange({}, Math.max(totalPages - 1, 0));
    }
  }, [page, totalPages, onPageChange]);

  return (
    <MuiTablePagination
      rowsPerPageOptions={rowsPerPageOptions}
      component={component}
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsPerPageChange}
    />
  );
}
