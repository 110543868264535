import { Fragment, useState, useRef, useEffect } from 'react';
import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import { Autorenew as AutorenewIcon } from '@mui/icons-material';
import { isValid } from 'date-fns';
import { DateTimePicker, DatePicker, SelectMultiple, Filter } from '.';
import { useOptions } from '../options';
import { spin } from '../../data/utilities';

function queryToSelections(query = {}) {
  const selections = {};

  Object.keys(query)
    .filter((key) => query[key])
    .forEach((key) => {
      if (query[key].$nin) {
        // if $nin is set it's the *Any option
        selections[key] = ['*Any'];
      } else if (
        query[key].$in?.includes(null) &&
        query[key].$in?.includes('')
      ) {
        // if $in contains null and '' it's the *None option
        selections[key] = [
          '*None',
          ...query[key].$in.filter((v) => v !== null && v !== ''),
        ];
      } else if (query[key].$in) {
        selections[key] = query[key].$in;
      } else {
        // it has some condition =, <, > etc
        selections[key] = Object.values(query[key])[0];
      }
    });

  return selections;
}

function queryToConditions(query = {}) {
  const conditions = {};

  Object.keys(query)
    .filter((key) => query[key])
    .forEach((key) => {
      const operator = Object.keys(query[key])[0];

      if (['$eq', '$ne', '$lt', '$lte', '$gt', '$gte'].includes(operator)) {
        conditions[key] = operator;
      }
    });

  return conditions;
}

export default function Parameters({
  onFetch,
  onCancel,
  isFetching,
  dateOnly,
  utc,
  pointEvent,
  value,
  onChange,
  className,
  style,
  sx,
  options: externalOptions,
  eventFilters = [],
  ...visibleFilters
}) {
  const [query, setQuery] = useState(value ?? {});
  const [selections, setSelections] = useState(queryToSelections(value));
  const [conditions, setConditions] = useState(queryToConditions(value));
  const [units, setUnits] = useState({});
  const [errors, setErrors] = useState({});
  const timeHeaderRef = useRef(null);
  const optionsProvider = useOptions();
  const options = !!externalOptions ? externalOptions : optionsProvider.options;

  const Picker = dateOnly ? DatePicker : DateTimePicker;
  const start = pointEvent ? 'time' : 'startTime';
  const end = pointEvent ? 'time' : 'endTime';

  // if (process.env.NODE_ENV === 'development') {
  //   query[start] = { $lte: new Date(2021, 1, 2) };
  //   query[end] = { $gte: new Date(2021, 0, 1), $lte: new Date(2021, 1, 2) };
  // }

  useEffect(() => {
    if (!!value) {
      setQuery(value);

      setSelections(queryToSelections(value));
      setConditions(queryToConditions(value));
    }
  }, [value]);

  function updateQuery(update) {
    const newQuery = { ...query, ...update };
    onChange?.(newQuery);
    setQuery(newQuery);
  }

  function handleClick(event) {
    const startError = !!query[end]?.$gte ? errors.startTime : 'Required';
    const endError = !!query[start]?.$lte ? errors.endTime : 'Required';

    setErrors({
      ...errors,
      startTime: startError,
      endTime: endError,
    });

    if (!!startError || !!endError) {
      timeHeaderRef.current.scrollIntoView();
    } else {
      if (isFetching) {
        onCancel(event);
      } else {
        // it's possible that someone has a cached query with a filter
        // that is no longer visible (e.g. due to a request for a
        // change to what filters are shown).
        // Make sure what is fetched is only what is visible WYSIWYF
        const visibleFieldNames = Object.fromEntries(
          options
            .filter((o) => visibleFilters[o.name])
            .flatMap((o) => o.fields.map((f) => [`${o.name}.${f.name}`, true]))
        );

        let visibleQuery = {};
        Object.keys(query).forEach((key) => {
          if (visibleFieldNames[key] || start === key || end === key) {
            visibleQuery[key] = query[key];
          }
        });

        onFetch(event, visibleQuery);
      }
    }
  }

  function convertOptionsToQuery(options) {
    const anyOption = options.some((v) => v === '*Any');
    const noneOption = options.some((v) => v === '*None');
    const normalOptions = options.filter((v) => !['*Any', '*None'].includes(v));
    const $in = normalOptions.length > 0 ? normalOptions : undefined;

    let query = {};
    if (anyOption) {
      query = { $nin: ['', null] };
    } else if (noneOption) {
      query = {
        $in: [...($in ?? []), '', null],
      };
    } else {
      query = $in ? { $in } : undefined;
    }

    return query;
  }

  function getErrorDescription(reason) {
    switch (reason) {
      case 'maxDate':
        return 'After end';
      case 'minDate':
        return 'Before start';
      case 'disableFuture':
        return 'Future';
      case 'invalidDate':
        return 'Invalid';
      default:
        return null;
    }
  }

  const handleFieldChange =
    (name) =>
    (value, condition = '$eq', unit) => {
      const conversions = {
        s: 1,
        m: 60,
        h: 3600,
        d: 86400,
      };

      setSelections({
        ...selections,
        [name]: value,
      });

      setConditions({
        ...conditions,
        [name]: condition,
      });

      if (unit) {
        setUnits({
          ...units,
          [name]: unit,
        });
      }

      let match = undefined;
      if (Array.isArray(value)) {
        match = convertOptionsToQuery(value);
      } else if (value !== undefined && value !== '' && value !== null) {
        match = { [condition]: unit ? value * conversions[unit] : value };
      }

      updateQuery({
        [name]: match,
      });
    };

  function handleStartTimeChange(value) {
    if (isValid(value)) {
      setErrors({
        ...errors,
        startTime: null,
      });
    }

    updateQuery({
      [end]: { $lte: query[end]?.$lte, $gte: value },
    });
  }

  function handleStartTimeError(reason, value) {
    setErrors({
      ...errors,
      startTime: getErrorDescription(reason),
    });
  }

  function handleEndTimeChange(value) {
    if (isValid(value)) {
      setErrors({
        ...errors,
        endTime: null,
      });
    }

    updateQuery({
      [start]: { $gte: query[start]?.$gte, $lte: value },
    });
  }

  function handleEndTimeError(reason, value) {
    setErrors({
      ...errors,
      endTime: getErrorDescription(reason),
    });
  }

  function getEventFilters() {
    return eventFilters.map((filter) => (
      <Filter
        key={filter.name}
        {...filter}
        value={selections[filter.name]}
        condition={conditions[filter.name]}
        unit={units[filter.name] ?? filter.unit}
        onChange={handleFieldChange(`${filter.name}`)}
        anyOption={true}
        noneOption={true}
        labelValue={true}
      />
    ));
  }

  const popperSx = {
    '& .MuiPaper-root': {
      position: 'relative !important',
      left: '260px !important',
      bottom: '40px !important',
    },
  };

  return (
    <Box className={className} style={style} sx={sx}>
      <Box
        sx={{
          pl: 1,
          pr: 0.5,
          display: 'flex',
          flexDirection: 'column',
          height: 1,
        }}
      >
        <Stack
          spacing={1.5}
          sx={{
            overflowY: 'auto',
            overflowX: 'hidden',
            flex: 1,
            backgroundColor: 'background.default',
            pb: 1.5,
          }}
        >
          <Typography
            variant="subtitle2"
            color="textSecondary"
            ref={timeHeaderRef}
          >
            Time Period
          </Typography>
          <Picker
            label="Start"
            size="small"
            value={query[end]?.$gte || null}
            onChange={handleStartTimeChange}
            onError={handleStartTimeError}
            clearable
            maxDate={query[start]?.$lte || new Date('2100-01-01')}
            error={!!errors.startTime}
            helperText={errors.startTime}
            utc={utc}
            disableFuture
            PopperProps={{
              sx: popperSx,
            }}
            // onBlur={alert}
          />
          <Picker
            label="End"
            size="small"
            value={query[start]?.$lte || null}
            onChange={handleEndTimeChange}
            onError={handleEndTimeError}
            clearable
            minDate={query[end]?.$gte || new Date('1900-01-01')}
            disableFuture
            error={!!errors.endTime}
            helperText={errors.endTime}
            utc={utc}
            PopperProps={{
              sx: popperSx,
            }}
          />
          {options
            .filter((field) => visibleFilters[field.name])
            .map((filter) => (
              <Fragment key={filter.name}>
                <Divider />
                <Typography variant="subtitle2" color="textSecondary">
                  {filter.label}
                </Typography>
                {filter.fields.map((field) => (
                  <SelectMultiple
                    key={field.name}
                    label={field.label}
                    placeholder="Select..."
                    value={selections[`${filter.name}.${field.name}`] || []}
                    onChange={handleFieldChange(`${filter.name}.${field.name}`)}
                    suggestions={field.values}
                    anyOption={true}
                    noneOption={true}
                    labelValue={true}
                  />
                ))}
              </Fragment>
            ))}
          {eventFilters?.length > 0 && (
            <Fragment>
              <Divider />
              <Typography variant="subtitle2" color="textSecondary">
                Event
              </Typography>
              {getEventFilters()}
            </Fragment>
          )}
        </Stack>
        <Divider />
        <Box sx={{ display: 'flex', pt: 1.5 }}>
          <Box sx={{ flexGrow: 1 }} />
          <Button
            sx={{ mb: 1 }}
            variant="contained"
            color={isFetching ? 'error' : 'primary'}
            onClick={handleClick}
            endIcon={
              <AutorenewIcon
                sx={
                  isFetching
                    ? { animation: `${spin} 2s linear infinite` }
                    : undefined
                }
              />
            }
          >
            {isFetching ? 'Cancel' : 'Fetch'}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
