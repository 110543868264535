import {
  List,
  ListItemIcon,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import { List as DefaultIcon } from '@mui/icons-material';
import _ from 'lodash';
import { liveIconsByTypeAndSubtype } from '../../data/constants';
import { pluralToSingularTypeMap } from './constants';

export default function SearchSummary({ searchTexts, hideEmpty = true }) {
  let searchTypesToShow = Object.keys(searchTexts);

  if (hideEmpty) {
    const nonEmptySearchTexts = searchTypesToShow.filter(
      (text) => !!searchTexts[text]?.trim()
    );

    if (nonEmptySearchTexts.length === 0) {
      return (
        <List>
          <ListItem>
            <Typography>Empty</Typography>
          </ListItem>
        </List>
      );
    }

    searchTypesToShow = nonEmptySearchTexts;
  }

  return (
    <List dense>
      {searchTypesToShow.map((type) => {
        const dict = liveIconsByTypeAndSubtype;
        const icon = dict[pluralToSingularTypeMap[type] || type]?.default || (
          <DefaultIcon />
        );

        return (
          <ListItem key={type}>
            {icon && <ListItemIcon>{icon}</ListItemIcon>}
            <ListItemText
              disableTypography
              primary={`${_.startCase(type)}: "${searchTexts[type]}"`}
            />
          </ListItem>
        );
      })}
    </List>
  );
}
