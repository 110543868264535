import { Fragment, useState } from 'react';
import Typography from '@mui/material/Typography';
import { FETCH_TRIPS, UPDATE_TRIP_CLASSIFICATION } from 'actions';
import { useAuth } from 'components/Auth';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { format, startOfDay, endOfDay, addDays, subDays } from 'date-fns';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Chip,
  IconButton,
  Box,
} from '@mui/material';
import { ClassificationDialog } from '../dialogs';
import {
  Label as LabelIcon,
  ChevronRight as ChevronRightIcon,
  ChevronLeft as ChevronLeftIcon,
} from '@mui/icons-material';
import { useLongPress } from '../../hooks';
import { FETCH_REPLAY } from '../../actions';
import ReplayMap from './IndividualReplayMap';

function LongPressableListItem(props) {
  const { onLongPress, ...otherProps } = props;
  const longPress = useLongPress(onLongPress, 500);

  return <ListItem {...otherProps} {...longPress} />;
}

export default function IndividualTrips() {
  const dispatch = useDispatch();
  const auth = useAuth();
  const { code } = auth.getProfile();

  const trips = useSelector((state) => state.events.trips.list, _.isEqual);
  const paths = useSelector((state) => state.replay.paths, _.isEqual);

  const [selectedListItem, setSelectedListItem] = useState(null);
  const [selectedDate, handleDateChange] = useState(new Date());
  const [tripIdToChangeClassification, setTripIdToChangeClassification] =
    useState(null);
  const [checkedIdentifiers, setCheckedIdentifiers] = useState([]);
  const [classificationOpen, setClassificationOpen] = useState(false);

  const isSelectedDateToday =
    startOfDay(selectedDate).getTime() === startOfDay(new Date()).getTime();

  const onItemChecked = (trip) => (event) => {
    event.stopPropagation();
    setCheckedIdentifiers((oldValue) => {
      if (event.target.checked) {
        return [...oldValue, trip.identifier];
      } else {
        return oldValue.filter((id) => id !== trip.identifier);
      }
    });
  };

  const handleClassificationClick = (trip) => () => {
    setTripIdToChangeClassification(trip);
  };

  const onAllItemsCheckboxClick = (event) => {
    const checked = event.target.checked;

    if (checked) {
      setCheckedIdentifiers(trips.map((trip) => trip.identifier));
    } else {
      setCheckedIdentifiers([]);
    }
  };

  function formatDate(date) {
    return format(date, 'hh:mm');
  }

  function formatDistance(distance) {
    return (Math.round(distance * 100) / 100).toFixed(2);
  }

  const handleTripClick = (trip) => () => {
    setSelectedListItem(trip);
    dispatch({
      type: FETCH_REPLAY,
      payload: { type: 'trips', id: trip.identifier },
    });
  };

  const tripList = trips.map((trip) => (
    <LongPressableListItem
      button
      disableRipple={tripIdToChangeClassification}
      onLongPress={handleClassificationClick(trip)}
      onClick={handleTripClick(trip)}
      key={trip.identifier}
    >
      <ListItemIcon>
        <Checkbox
          edge="start"
          checked={checkedIdentifiers.includes(trip.identifier)}
          onClick={onItemChecked(trip)}
          tabIndex={-1}
          disableRipple
          inputProps={{ 'aria-labelledby': trip.identifier }}
        />
      </ListItemIcon>
      <ListItemText
        sx={selectedListItem === trip ? { color: 'primary.main' } : undefined}
        primaryTypographyProps={{
          variant: 'body2',
          style: { lineHeight: 1.3, marginTop: 4 },
        }}
        primary={
          formatDate(new Date(trip.startTime)) +
          ' - ' +
          formatDate(new Date(trip.endTime)) +
          ' ' +
          formatDistance(trip.distanceMiles) +
          'M'
        }
        secondaryTypographyProps={{ component: 'span', noWrap: true }}
        secondary={trip.vehicle.registrationNumber}
      />
      {trip.classification !== 'None' && (
        <Chip
          label={trip.classification}
          onClick={handleClassificationClick(trip)}
        />
      )}
    </LongPressableListItem>
  ));

  function handleClassificationClose(classification) {
    if (classification) {
      dispatch({
        type: UPDATE_TRIP_CLASSIFICATION,
        payload: {
          id: tripIdToChangeClassification.identifier,
          classification: classification,
        },
      });
      updateQuery(selectedDate);
    }
    setTripIdToChangeClassification(null);
  }

  function updateQuery(newValue) {
    setCheckedIdentifiers([]);
    dispatch({
      type: FETCH_TRIPS,
      payload: {
        'driver.code': { $in: [code] },
        endTime: {
          $gte: startOfDay(new Date(newValue)).toISOString(),
        },
        startTime: { $lt: endOfDay(new Date(newValue)).toISOString() },
      },
    });
  }

  function handleLabelClassificationClick() {
    setClassificationOpen(true);
  }

  function handleLabelClassificationClose(classification) {
    if (classification) {
      checkedIdentifiers.forEach((id) =>
        dispatch({
          type: UPDATE_TRIP_CLASSIFICATION,
          payload: { id: id, classification },
        })
      );
    }
    setClassificationOpen(false);
  }

  return (
    <Fragment>
      <ReplayMap
        isMobilePreviewMap
        paths={paths}
        followedItemIndexes={[]}
        selectedItemIndex={{}}
        hoveredItemIndex={{}}
      />
      <Box sx={{ height: 1 }}>
        <Box sx={{ justifyContent: 'center', display: 'flex', pt: 1, mb: 0.5 }}>
          <IconButton
            sx={{ pt: 0.5, width: 64, textAlign: 'center' }}
            onClick={() => {
              handleDateChange(subDays(selectedDate, 1));
              updateQuery(subDays(selectedDate, 1));
            }}
            size="large"
          >
            <ChevronLeftIcon />
          </IconButton>
          <MuiDatePicker
            sx={{ width: 100 }}
            maxDate={new Date()}
            openTo="date"
            value={selectedDate}
            onChange={(newValue) => {
              const newDate = newValue.toDate();
              handleDateChange(newDate);
              updateQuery(newDate);
            }}
            inputProps={{
              style: { textAlign: 'center' },
            }}
          />
          <IconButton
            disabled={isSelectedDateToday}
            sx={{
              pt: 0.5,
              width: 64,
              textAlign: 'center',
              '& buttonDisabled': {
                color: 'grey.900',
              },
            }}
            onClick={() => {
              handleDateChange(addDays(selectedDate, 1));
              updateQuery(addDays(selectedDate, 1));
            }}
            size="large"
          >
            <ChevronRightIcon />
          </IconButton>
        </Box>
        <List
          sx={{
            mx: 2,
            pr: 2,
            overflowY: 'auto',
            display: 'inlineFlex',
            height: 800,
          }}
        >
          {tripList}
        </List>
        {checkedIdentifiers.length > 0 && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              gap: 104,
              alignItems: 'center',
              position: 'fixed',
              py: 0.5,
              bottom: 0,
              width: 1,
              bgcolor: 'common.white',
              opacity: 1,
            }}
          >
            <Checkbox
              checked={checkedIdentifiers.length === trips.length}
              onClick={onAllItemsCheckboxClick}
            />
            <Typography>{checkedIdentifiers.length} selected</Typography>
            <IconButton
              title="Classification"
              color="primary"
              onClick={handleLabelClassificationClick}
              size="large"
            >
              <LabelIcon />
            </IconButton>
          </Box>
        )}
        <ClassificationDialog
          open={!!tripIdToChangeClassification}
          onClose={handleClassificationClose}
        />
        <ClassificationDialog
          open={classificationOpen}
          onClose={handleLabelClassificationClose}
        />
      </Box>
    </Fragment>
  );
}
