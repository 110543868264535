import {
  Button,
  Paper,
  CardActions,
  CardHeader,
  Chip,
  Divider,
  ListSubheader,
  Box,
} from '@mui/material';
import { Person as PersonIcon } from '@mui/icons-material';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Form } from 'react-final-form';
import _ from 'lodash';
import { useAuth } from '../Auth';
import {
  CREATE_PERSON,
  DELETE_PERSON,
  FETCH_HOME_STATIONS,
  FETCH_POST_HIERARCHY,
  FETCH_PERSON,
  FETCH_PERSON_SUCCESS,
  UPDATE_PERSON,
} from '../../actions';
import { ConfirmationDialog } from '../dialogs';
import {
  Field,
  AvatarField,
  CheckboxField,
  TextField,
  SelectField,
  GroupsField,
  required,
  ChipField,
  DateTimeField,
  isDateValidOrEmpty,
  StyledField,
} from '../fields';
import { doesIdExist, getRfidErrors } from '../../apis/utilities';
import { Section } from 'components/controls';

const { personGroups, personEditableFields, useDallasKeys, useRestricted } =
  window.config;

export default function ReducedPerson() {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const person = useSelector((state) => state.people.person, _.isEqual);
  const homeStationNames = useSelector(
    (state) => state.locations.homeStationNames,
    _.isEqual
  );
  const [deleteOpen, setDeleteOpen] = useState(false);
  const auth = useAuth();
  const canEdit = auth.isAuthorised('people', true);

  useEffect(() => {
    if (id === 'new') {
      dispatch({
        type: FETCH_PERSON_SUCCESS,
        payload: null,
      });
    } else {
      dispatch({
        type: FETCH_PERSON,
        payload: id,
      });
    }
    dispatch({
      type: FETCH_HOME_STATIONS,
    });
    dispatch({
      type: FETCH_POST_HIERARCHY,
    });
  }, [id, dispatch]);

  async function onSubmit(values) {
    const rfidErrors = await getRfidErrors(values.code, values.rfidCards);

    if (rfidErrors) {
      return { rfidCards: rfidErrors };
    }

    if (person) {
      dispatch({
        type: UPDATE_PERSON,
        payload: values,
      });
    } else {
      const exists = await doesIdExist('people', values.code);
      if (exists) {
        return { code: 'Exists' };
      }

      dispatch({
        type: CREATE_PERSON,
        payload: values,
        navigate,
      });
    }
  }

  function handleDelete() {
    if (person) {
      dispatch({
        type: DELETE_PERSON,
        payload: person.code,
      });
    }
  }

  function isDisabled(fieldName) {
    return !(canEdit && (personEditableFields[fieldName] || person === null));
  }

  return (
    <Form
      initialValues={person || {}}
      onSubmit={onSubmit}
      render={({
        handleSubmit,
        form: { reset },
        submitting,
        dirty,
        pristine,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <Helmet>
            <title>IR3 | Person{values.code ? ` | ${values.code}` : ''}</title>
          </Helmet>
          <Paper sx={{ m: 1, minWidth: 240 }}>
            <CardHeader
              avatar={
                <Field
                  name="picture"
                  component={AvatarField}
                  icon={<PersonIcon />}
                  disabled={isDisabled('picture')}
                />
              }
              title={values.code}
              subheader={values.emailAddress}
            />
            <Box>
              <ListSubheader disableSticky>Key Information</ListSubheader>
              <Section>
                <StyledField
                  name="code"
                  component={TextField}
                  label="Staff ID"
                  validate={required}
                  disabled={!(canEdit && person === null)}
                />
                <StyledField
                  name="emailAddress"
                  type="email"
                  component={TextField}
                  label="AD Username"
                  disabled={isDisabled('emailAddress')}
                />
                <StyledField
                  name="leavingDate"
                  component={DateTimeField}
                  label="Leaving Date"
                  disabled={isDisabled('leavingDate') || id === 'new'}
                  validate={isDateValidOrEmpty}
                />
                {useRestricted && (
                  <StyledField
                    name="restricted"
                    component={CheckboxField}
                    label="Restricted"
                    disabled={isDisabled('restricted')}
                  />
                )}
              </Section>
              <Divider />
              <ListSubheader disableSticky>Supervisors</ListSubheader>
              <Section>
                {(values.supervisorCodes || []).map((code) => (
                  <Chip key={code} label={code} sx={{ mr: 1 }} />
                ))}
              </Section>
              <Divider />
              <ListSubheader disableSticky>Home</ListSubheader>
              <Section>
                <StyledField
                  name="homeStation"
                  component={SelectField}
                  label="Location"
                  values={homeStationNames.map((station) => ({
                    label: station.name,
                    value: station.code,
                  }))}
                  disabled={isDisabled('homeStation')}
                />
              </Section>
              <Divider />
              <ListSubheader disableSticky>Groups & Areas</ListSubheader>
              <Section>
                <Field
                  name="groups"
                  groups={personGroups}
                  component={GroupsField}
                  disabled={isDisabled('groups')}
                />
              </Section>
              <Divider />
              <ListSubheader disableSticky>
                {useDallasKeys ? 'Dallas Keys' : 'RFID Cards'}
              </ListSubheader>
              <Section>
                <Field
                  name={'rfidCards'}
                  component={ChipField}
                  defaultCategory="Business"
                  fullWidth
                  disabled={isDisabled('rfidCards')}
                />
              </Section>
            </Box>
            <CardActions>
              <Button
                color="primary"
                type="submit"
                disabled={pristine || submitting}
              >
                Save
              </Button>
              <Button
                color="primary"
                disabled={pristine || submitting}
                onClick={reset}
              >
                Cancel
              </Button>
              <Button
                color="error"
                onClick={() => setDeleteOpen(true)}
                disabled={person === null}
              >
                Delete
              </Button>
            </CardActions>
          </Paper>
          <ConfirmationDialog
            action="Delete"
            open={deleteOpen}
            itemId={values.code}
            onOk={handleDelete}
            onCancel={() => setDeleteOpen(false)}
          />
        </form>
      )}
    />
  );
}
