import {
  Avatar,
  Box,
  Collapse,
  Divider,
  IconButton,
  InputAdornment,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {
  Add as AddIcon,
  ArrowUpward as ArrowUpwardIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Outlet } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import _ from 'lodash';
import moment from 'moment';
import { FETCH_OBJECTIVES, FETCH_WARDS } from '../../actions';
import { NavLink, SearchBox, ObjectiveAvatar } from '../controls';
import Container from '../Container';
import { useAuth } from '../Auth';

function Row({ data, index, style }) {
  const [list, id, sortBy] = data;
  const item = list[index];

  return (
    <ListItemButton
      dense
      key={index}
      style={style}
      component={NavLink}
      to={`/objectives/${encodeURIComponent(item.identifier)}`}
      selected={item.identifier === decodeURIComponent(id)}
    >
      <ListItemAvatar>
        <ObjectiveAvatar
          type={item.type}
          startTime={item.startTime}
          endTime={item.endTime}
        />
      </ListItemAvatar>
      <ListItemText
        title={item.title}
        primary={item.title}
        secondary={
          sortBy.endsWith('Time')
            ? item[sortBy]
              ? moment(item[sortBy]).format('DD/MM/YYYY, HH:mm:ss')
              : ''
            : item.identifier
        }
      />
    </ListItemButton>
  );
}

export default function ObjectiveList() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const objectives = useSelector(
    (state) => state.objectives.objectives,
    _.isEqual
  );
  const [searchText, setSearchText] = useState('');
  const wards = useSelector((state) => state.locations.wardNames, _.isEqual);
  const [showSettings, setShowSettings] = useState(false);
  const [sortBy, setSortBy] = useState('title');
  const [sortDesc, setSortDesc] = useState(false);
  const [filter, setFilter] = useState({
    createdBy: '',
    ward: '',
    status: '',
  });
  const isXs = useMediaQuery((theme) => theme.breakpoints.only('xs'));
  const auth = useAuth();
  const canCreate = auth.isAuthorised('objectives', true);

  const sortOptions = [
    { label: 'Title', value: 'title' },
    { label: 'Identifier', value: 'identifier' },
    { label: 'Start Date', value: 'startTime' },
    { label: 'End Date', value: 'endTime' },
  ];

  // function areaFilter(objective) {
  //   if (filter.area.name === '') {
  //     return true;
  //   }

  //   if (!objective.areas) {
  //     return false;
  //   }

  //   return (
  //     objective.areas.filter(
  //       (area) =>
  //         area.type === filter.area.type && area.name === filter.area.name
  //     ).length > 0
  //   );
  // }

  function createdByFilter(objective) {
    if (filter.createdBy === '') {
      return true;
    }

    if (!objective.created) {
      return false;
    }

    return objective.created.userId === filter.createdBy;
  }

  function wardFilter(objective) {
    if (filter.ward === '') {
      return true;
    }

    if (!objective.wards) {
      return false;
    }

    return objective.wards.includes(filter.ward);
  }

  function statusFilter(objective) {
    const now = moment();

    switch (filter.status) {
      case 'Active':
        return (
          moment(objective.startTime) <= now && now < moment(objective.endTime)
        );
      case 'Upcoming':
        return moment(objective.startTime) > now;
      case 'Expired':
        return moment(objective.endTime) < now;
      default:
        return true;
    }
  }

  const filteredList = _.orderBy(
    objectives
      .filter(
        (objective) =>
          `${objective.title}+${objective.identifier}`
            .toLowerCase()
            .indexOf(searchText.toLowerCase()) > -1 || searchText === ''
      )
      // .filter(areaFilter)
      .filter(createdByFilter)
      .filter(wardFilter)
      .filter(statusFilter),
    [sortBy],
    [sortDesc ? 'desc' : 'asc']
  );

  useEffect(() => {
    if (wards.length === 0) {
      dispatch({
        type: FETCH_WARDS,
      });
    }
  }, [wards, dispatch]);

  useEffect(() => {
    dispatch({
      type: FETCH_OBJECTIVES,
    });
    setFilter({
      area: {
        type: '',
        name: '',
      },
      createdBy: '',
      ward: '',
      status: '',
    });
  }, [dispatch]);

  function handleSearchChange(event) {
    setSearchText(event.target.value);
  }

  function handleSortByChange(event) {
    setSortBy(event.target.value);
  }

  function handleSortToggle() {
    setSortDesc(!sortDesc);
  }

  function handleCreatedByChange(event) {
    setFilter({ ...filter, createdBy: event.target.value });
  }

  function handleWardChange(event) {
    setFilter({
      ...filter,
      ward: event.target.value,
    });
  }

  function handleStatusChange(event) {
    setFilter({ ...filter, status: event.target.value });
  }

  function handleSettingsToggle() {
    setShowSettings(!showSettings);
  }

  function getCreatorIds() {
    const ids = new Set(
      objectives
        .filter((objective) => objective.created)
        .map((objective) => objective.created.userId)
        .sort()
    );

    return Array.from(ids.values());
  }

  function getWards() {
    const ids = new Set(
      [].concat.apply(
        [],
        objectives
          .filter((objective) => 'wards' in objective)
          .map((objective) => objective.wards)
          .sort()
      )
    );

    const list = Array.from(ids.values()).map((id) => {
      const ward = wards.find((ward) => ward.code === id);

      return {
        value: id,
        label: ward ? ward.name : id,
      };
    });

    return list;
  }

  return (
    <Container title="Objectives" showBack={isXs && id}>
      <Box sx={{ display: 'flex', height: 1, width: 1 }}>
        <Helmet>
          <title>IR3 | Objectives</title>
        </Helmet>
        {(!isXs || !id) && (
          <Box
            sx={(theme) => ({
              display: 'flex',
              flexDirection: 'column',
              width: 280,
              [theme.breakpoints.down('sm')]: {
                width: 1,
              },
              height: 1,
            })}
          >
            <Box>
              <Stack
                direction="row"
                sx={{ p: 1, pr: 0.5 }}
                spacing={0.5}
                alignItems="center"
              >
                <SearchBox
                  value={searchText}
                  onChange={handleSearchChange}
                  count={`${filteredList.length}/${objectives.length}`}
                  sx={{ flexGrow: 1 }}
                />
                <IconButton
                  title={showSettings ? 'Hide settings' : 'Show settings'}
                  onClick={handleSettingsToggle}
                  size="small"
                >
                  <SettingsIcon
                    fontSize="inherit"
                    color={showSettings ? 'primary' : 'inherit'}
                  />
                </IconButton>
                {canCreate && (
                  <IconButton
                    title="Add new"
                    component={Link}
                    to={'/objectives/new'}
                    size="small"
                  >
                    <Avatar
                      sx={{
                        color: 'secondary.contrastText',
                        backgroundColor: 'secondary.main',
                        width: 24,
                        height: 24,
                        fontSize: 16,
                      }}
                    >
                      <AddIcon fontSize="inherit" />
                    </Avatar>
                  </IconButton>
                )}
              </Stack>
              <Collapse in={showSettings} unmountOnExit>
                <Stack sx={{ p: 1 }} spacing={1.5}>
                  <TextField
                    size="small"
                    select
                    fullWidth
                    label="Sort by"
                    value={sortBy}
                    onChange={handleSortByChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <IconButton
                            title={sortDesc ? 'Descending' : 'Ascending'}
                            sx={(theme) => ({
                              transform: sortDesc
                                ? 'rotate(180deg)'
                                : 'rotate(0deg)',
                              transition: theme.transitions.create(
                                'transform',
                                {
                                  duration: theme.transitions.duration.shortest,
                                }
                              ),
                            })}
                            onClick={handleSortToggle}
                            size="small"
                          >
                            <ArrowUpwardIcon fontSize="inherit" />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  >
                    {sortOptions.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Stack>
                <Stack sx={{ px: 1 }} spacing={1.5}>
                  <Divider>
                    <Typography variant="subtitle2" color="textSecondary">
                      Filters
                    </Typography>
                  </Divider>
                  <TextField
                    size="small"
                    fullWidth
                    select
                    label="Ward"
                    value={filter.ward}
                    onChange={handleWardChange}
                  >
                    <MenuItem value="">
                      <em>Any</em>
                    </MenuItem>
                    {getWards().map((ward) => (
                      <MenuItem key={ward.value} value={ward.value}>
                        {ward.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    size="small"
                    fullWidth
                    select
                    label="Created By"
                    value={filter.createdBy}
                    onChange={handleCreatedByChange}
                  >
                    <MenuItem value="">
                      <em>Any</em>
                    </MenuItem>
                    {getCreatorIds().map((name) => (
                      <MenuItem key={name} value={name}>
                        {name}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    size="small"
                    fullWidth
                    select
                    label="Status"
                    value={filter.status}
                    onChange={handleStatusChange}
                  >
                    <MenuItem value="">
                      <em>Any</em>
                    </MenuItem>
                    {['Active', 'Upcoming', 'Expired'].map((status) => (
                      <MenuItem key={status} value={status}>
                        {status}
                      </MenuItem>
                    ))}
                  </TextField>
                  <Divider />
                </Stack>
              </Collapse>
            </Box>
            {filteredList.length > 0 && (
              <Box sx={{ overflow: 'none', height: 1 }}>
                <AutoSizer>
                  {({ width, height }) => (
                    <FixedSizeList
                      width={width}
                      height={height}
                      overscanCount={10}
                      itemData={[filteredList, id, sortBy]}
                      itemCount={filteredList.length}
                      itemSize={56}
                    >
                      {Row}
                    </FixedSizeList>
                  )}
                </AutoSizer>
              </Box>
            )}
          </Box>
        )}
        {(!isXs || id) && (
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              // width: 1,
              height: 1,
              overflow: 'auto',
            }}
          >
            <Outlet />
          </Box>
        )}
      </Box>
    </Container>
  );
}
