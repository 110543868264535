import {
  Avatar,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Drawer,
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  Box,
} from '@mui/material';
import { blue } from '@mui/material/colors';
import {
  // DirectionsCar as VehicleIcon,
  // Person as PersonIcon,
  FilterList as FilterIcon,
  Refresh as RefreshIcon,
  Directions as DirectionsIcon,
} from '@mui/icons-material';
import Container from '../Container';
import { SelectMultiple } from '../controls';
import _ from 'lodash';
import { Collection, Feature, Map } from 'ol';
import { defaults as defaultControls } from 'ol/control';
import { applyTransform, createEmpty, extend } from 'ol/extent';
import { GeoJSON } from 'ol/format';
import { Point } from 'ol/geom';
import { defaults as defaultInteractions } from 'ol/interaction';
import { Vector as VectorLayer } from 'ol/layer';
import 'ol/ol.css';
import { getTransform, transform } from 'ol/proj';
import { Vector as VectorSource } from 'ol/source';
import { getDistance } from 'ol/sphere';
import { Icon, Style } from 'ol/style';
import { Fragment, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { FETCH_TELEMATICS_BOXES } from '../../actions';
import { mapGlyphsByTypeAndSubtype } from '../../data/constants';
import { usePrevious } from '../../hooks';
import locationGlyph from '../../img/baseline_my_location_black_24dp.png';
// import resourceShape from '../../img/resource.png';
import resourceShapeNoDir from '../../img/resource_no_dir.png';
import { setBaseLayers } from '../../mapStyles';
import avatarForItem from 'components/live/LiveList/avatarUtility';
import { ItemRows } from 'components/live/LiveList/ItemRows';

// const { isFleet } = window.config;
const { mapExtent } = window.config;

const vehicleGlyph = mapGlyphsByTypeAndSubtype.vehicle.default;

function defaultStyle(feature) {
  switch (feature.get('type')) {
    case 'vehicle':
      return (
        feature.get('filteredIn') && [
          new Style({
            image: new Icon({
              opacity: 1,
              src: resourceShapeNoDir, // resourceShape,
              scale: 0.05,
              color: feature.get('selected') ? blue[500] : 'black', // layerColours[layer].background,
              // rotation: (heading * Math.PI) / 180.0,
            }),
          }),
          new Style({
            image: new Icon({
              src: vehicleGlyph,
              color: 'white',
              scale: 0.5,
            }),
          }),
        ]
      );
    case 'location':
      return [
        new Style({
          image: new Icon({
            opacity: 1,
            src: resourceShapeNoDir, // resourceShape,
            scale: 0.05,
            color: 'white',
          }),
        }),
        new Style({
          image: new Icon({
            src: locationGlyph,
            color: 'white',
            scale: 0.5,
          }),
        }),
      ];

    default:
      return [];
  }
}

export default function Nearby() {
  const [showFilters, setShowFilters] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [selectedFeature, setSelectedFeature] = useState();
  const [selections, setSelections] = useState({});
  const [vehicles, setVehicles] = useState([]);

  const mapDiv = useRef(null);
  const mapRef = useRef(null);
  const vectorSource = useRef(null);

  const boxesByImei = useSelector((state) => state.telematicsBoxes.boxesByImei);

  const view = mapRef.current && mapRef.current.getView();

  const dispatch = useDispatch();

  const [geolocation, setGeoLocation] = useState();

  function handleRefreshClick() {
    // get the user's location
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        setGeoLocation(position);
      });
    }
  }

  // get the tbs when the location changes
  useEffect(() => {
    if (geolocation) {
      dispatch({ type: FETCH_TELEMATICS_BOXES });
    }
  }, [dispatch, geolocation]);

  const prevBoxesByImei = usePrevious(boxesByImei);
  const prevSelections = usePrevious(selections);
  useEffect(() => {
    if (
      mapRef.current &&
      (prevBoxesByImei !== boxesByImei || prevSelections !== selections)
    ) {
      function isFilteredIn(vehicle) {
        // return Math.random() > .5;
        return Object.keys(selections).every(
          (k) =>
            selections[k].length === 0 ||
            selections[k].map(({ value }) => value).includes(_.get(vehicle, k))
        );
      }

      vectorSource.current.clear();

      const vehicles = Object.values(boxesByImei).filter(
        (box) => !!box.identificationNumber && !box.disposalDate
      );
      setVehicles(vehicles);

      const vehicleFeatures = vehicles.map(
        (box) =>
          new Feature({
            geometry: new GeoJSON().readGeometry(box.lastPosition, {
              featureProjection: 'EPSG:3857',
            }),
            type: 'vehicle',
            properties: box,
            filteredIn: isFilteredIn(box),
          })
      );

      const { latitude = 0, longitude = 0 } = geolocation.coords || {};
      const locationCoords = transform(
        [longitude, latitude],
        'EPSG:4326',
        'EPSG:3857'
      );
      const locationFeature = new Feature({
        geometry: new Point(locationCoords),
        type: 'location',
      });

      const features = vehicleFeatures.concat(locationFeature);

      vectorSource.current.addFeatures(features);

      // get the top 10 nearest
      const vehicleFeaturesByDistance = _.orderBy(
        vehicleFeatures
          .filter((feature) => feature.get('filteredIn'))
          .map((feature) => ({
            feature,
            distance: getDistance(
              feature.getGeometry().getCoordinates(),
              locationCoords
            ),
          })),
        'distance',
        'asc'
      );

      let extent = createEmpty();
      extend(extent, locationFeature.getGeometry().getExtent());
      vehicleFeaturesByDistance.slice(0, 1).forEach(({ feature }) => {
        extend(extent, feature.getGeometry().getExtent());
      });

      view.fit(extent, { maxZoom: 16 });
    }
  }, [
    boxesByImei,
    prevBoxesByImei,
    geolocation,
    view,
    selections,
    prevSelections,
  ]);

  const prevSelectedFeature = usePrevious(selectedFeature);
  useEffect(() => {
    if (selectedFeature !== prevSelectedFeature) {
      setShowDrawer(true);
    }
  }, [selectedFeature, prevSelectedFeature]);

  useEffect(() => {
    if (!mapRef.current) {
      vectorSource.current = new VectorSource({
        features: new Collection([]),
      });

      mapRef.current = new Map({
        target: mapDiv.current,
        layers: [
          new VectorLayer({
            source: vectorSource.current,
            style: defaultStyle,
          }),
        ],
        interactions: defaultInteractions({
          // mouseWheelZoom: false,
          pinchRotate: false,
          altShiftDragRotate: false,
        }).extend([
          // new MouseWheelZoomInteraction({
          //   condition: altKeyOnly,
          // }),
        ]),
        controls: defaultControls({
          attribution: false,
          rotate: false,
          zoom: false,
        }),
      });

      const view = mapRef.current.getView();

      function handleClick(event) {
        const pixel = mapRef.current.getEventPixel(event.originalEvent);
        const features = mapRef.current.getFeaturesAtPixel(pixel);
        const feature = features[0]; // top one

        feature.setProperties({ selected: true });

        view.setCenter(feature.getGeometry().getCoordinates());

        setSelectedFeature(feature);
      }

      mapRef.current.on('singleclick', handleClick);

      view.fit(
        applyTransform(mapExtent, getTransform('EPSG:4326', 'EPSG:3857'))
      );

      setBaseLayers(mapRef.current);
    }
  }, []);

  function extraButtons() {
    function handleFilterClick() {
      setShowFilters(true);
    }

    function handleFilterDialogClose() {
      setShowFilters(false);
    }

    function labelValue(value) {
      return { label: value, value };
    }

    function handleFilterChanged(type, selection) {
      setSelections({
        ...selections,
        [type]: selection,
      });
    }

    // get unique roles
    const roles = _.uniq(vehicles.map((v) => v.role)).sort();
    // get unique area stuff
    let areas = {};
    vehicles.forEach((v) => {
      Object.keys(v.areas).forEach((areaKey) => {
        areas[areaKey] = areas[areaKey] || {};
        areas[areaKey][v.areas[areaKey]] = true;
      });
    });
    Object.keys(areas).forEach((areaKey) => {
      areas[areaKey] = _.uniq(Object.keys(areas[areaKey])).sort();
    });

    return (
      <Fragment>
        <IconButton
          sx={{
            color: 'primary.contrastText',
            bgcolor: 'primary.main',
          }}
          onClick={handleFilterClick}
          disabled={vehicles.length === 0}
          size="large"
        >
          <FilterIcon />
        </IconButton>
        <Dialog fullWidth onClose={handleFilterDialogClose} open={showFilters}>
          <DialogTitle>Filter Vehicles</DialogTitle>
          <DialogContent>
            <SelectMultiple
              label="Role"
              placeholder="Select..."
              value={selections.role || []}
              onChange={(value) => handleFilterChanged('role', value)}
              suggestions={roles.map(labelValue)}
            />
            {Object.keys(areas).map((key) => {
              const path = `areas.${key}`;
              return (
                <SelectMultiple
                  key={key}
                  label={_.startCase(key)}
                  placeholder="Select..."
                  value={selections[path] || []}
                  onChange={(value) => handleFilterChanged(path, value)}
                  suggestions={areas[key].map(labelValue)}
                />
              );
            })}
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }

  function handleCloseDrawer() {
    selectedFeature.setProperties({ selected: false });
    setSelectedFeature();
    setShowDrawer(false);
  }

  function handleDirectionsClick() {
    const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&origin=${[
      geolocation.coords?.latitude,
      geolocation.coords?.longitude,
    ].join()}&destination=${transform(
      selectedFeature.getGeometry().getCoordinates(),
      'EPSG:3857',
      'EPSG:4326'
    )
      .reverse()
      .join()}`;

    window.location.href = googleMapsUrl;
  }

  function FeatureDetails({ feature }) {
    if (feature) {
      // JSON.stringify(selectedFeature?.get('properties') || {})
      const item = selectedFeature?.get('properties');
      const rows = [
        { label: 'VIN', value: item.identificationNumber },
        { label: 'Role', value: item.role },
        ...Object.entries(item.areas).map(([label, value]) => ({
          label: _.startCase(label),
          value,
        })),
      ];

      return (
        <Card>
          <CardHeader
            avatar={avatarForItem(item, 'vehicles')}
            subheader={item.fleetNumber}
            title={item.registrationNumber}
            action={
              <IconButton
                aria-label="Directions"
                onClick={handleDirectionsClick}
                title="Directions"
                size="large"
              >
                <DirectionsIcon fontSize="small" />
              </IconButton>
            }
          />
          <CardContent>
            <Table size="small">
              <TableBody>{ItemRows(rows)}</TableBody>
            </Table>
          </CardContent>
        </Card>
      );
    } else {
      setShowDrawer(false);
    }

    return 'Details...';
  }

  return (
    <Container title="Nearby" extraButtonsRender={extraButtons}>
      <Helmet>
        <title>IR3 | Nearby</title>
      </Helmet>
      <Box sx={{ position: 'relative', width: 1, height: 1 }}>
        <Box id="map" sx={{ width: 1, height: 1 }} ref={mapDiv} />
      </Box>
      <Box
        sx={{
          bottom: 10,
          position: 'absolute',
          display: 'flex',
          justifyContent: 'center',
          width: 1,
        }}
      >
        <Avatar
          sx={{
            color: 'primary.contrastText',
            backgroundColor: 'primary.main',
          }}
        >
          <IconButton
            sx={{
              color: 'primary.contrastText',
              backgroundColor: 'primary.main',
            }}
            key="refresh"
            onClick={handleRefreshClick}
            size="large"
          >
            <RefreshIcon />
          </IconButton>
        </Avatar>
      </Box>
      <Drawer anchor="bottom" open={showDrawer} onClose={handleCloseDrawer}>
        <FeatureDetails feature={selectedFeature} />
      </Drawer>
    </Container>
  );
}
