import _ from 'lodash';
import { Box, useMediaQuery } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import TelematicsBoxList from './TelematicsBoxList';
import {
  FETCH_TELEMATICS_BOXES,
  END_TELEMATICS_BOX_POLLS_STREAM,
} from '../../../actions';
import Container from '../../Container';
import { useSnackbar } from '../../Snackbar';
import { log } from '../../../apis/utilities';
import { usePrevious } from '../../../hooks';
import { CommissioningDialogProvider } from './CommissioningDialog';

export default function TelematicsBoxPage() {
  const { id } = useParams();
  const prevId = usePrevious(id);
  const isXs = useMediaQuery((theme) => theme.breakpoints.only('xs'));

  // log when a new id is navigated to
  useEffect(() => {
    if (id && id !== prevId) {
      log('Read', 'Telematics Box', { id });
    }
  }, [id, prevId]);

  // get the tbs when starting up
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: FETCH_TELEMATICS_BOXES });

    return () => {
      dispatch({ type: END_TELEMATICS_BOX_POLLS_STREAM });
    };
  }, [dispatch]);

  const snackbar = useSnackbar();
  const error = useSelector((state) => state.telematicsBoxes.error, _.isEqual);
  useEffect(() => {
    if (error) {
      snackbar.notify('error', error);
    }
  }, [error, snackbar]);

  return (
    <CommissioningDialogProvider>
      <Container title="Telematics Boxes" showBack={isXs && id}>
        <Box sx={{ display: 'flex', height: 1, width: 1 }}>
          <Helmet>
            <title>IR3 | Telematics Boxes</title>
          </Helmet>
          {(!isXs || !id) && (
            <Box
              sx={(theme) => ({
                display: 'flex',
                flexDirection: 'column',
                width: 280,
                [theme.breakpoints.down('sm')]: {
                  width: 1,
                },
                height: 1,
              })}
            >
              <TelematicsBoxList />
            </Box>
          )}
          {(!isXs || id) && (
            <Box
              sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                // width: 1,
                height: 1,
                overflow: 'auto',
              }}
            >
              <Outlet />
            </Box>
          )}
        </Box>
      </Container>
    </CommissioningDialogProvider>
  );
}
