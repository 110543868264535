import { useRef, useEffect, useState } from 'react';
import { Box, Tooltip } from '@mui/material';

// https://stackoverflow.com/a/59364492/1350146
export default function OverflowTip({ title, children }) {
  // Create Ref
  const textElementRef = useRef();

  const compareSize = () => {
    const compare =
      textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
    setHover(compare);
  };

  // compare once and add resize listener on "componentDidMount"
  useEffect(() => {
    compareSize();
    window.addEventListener('resize', compareSize);
  }, []);

  // remove resize listener again on "componentWillUnmount"
  useEffect(
    () => () => {
      window.removeEventListener('resize', compareSize);
    },
    []
  );

  // Define state and function to update the value
  const [hoverStatus, setHover] = useState(false);

  return (
    <Tooltip
      title={title}
      disableHoverListener={!hoverStatus}
      sx={{ fontSize: '2em' }}
    >
      <Box
        ref={textElementRef}
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {children}
      </Box>
    </Tooltip>
  );
}
