import { MenuItem, TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import { UPDATE_TRIP_CLASSIFICATION } from '../../../actions';

const { tripClassifications } = window.config;

export default function Classification({ entry }) {
  const dispatch = useDispatch();

  const handleClassificationChange = (tripId) => (event) => {
    event.stopPropagation();

    dispatch({
      type: UPDATE_TRIP_CLASSIFICATION,
      payload: {
        id: tripId,
        classification: event.target.value,
      },
    });
  };

  return (
    <TextField
      size="small"
      select
      value={entry.classification}
      onChange={handleClassificationChange(entry.identifier)}
      inputProps={{ sx: { fontSize: 14 } }}
    >
      <MenuItem value="None">None</MenuItem>
      {tripClassifications.map((classification) => (
        <MenuItem key={classification.value} value={classification.value}>
          {classification.label}
        </MenuItem>
      ))}
    </TextField>
  );
}
