import { ListItemAvatar, ListItemButton, ListItemText } from '@mui/material';
import avatarForItem from './avatarUtility';

function TaskLiveListItem({ highlighted, onClick, item, style, onHoverItem }) {
  const idType = { id: item.number, type: 'tasks' };
  const handleHoverEnter = () => onHoverItem(idType);
  const handleHoverLeave = () => onHoverItem({});
  const handleClick = () => onClick(idType);

  return (
    <ListItemButton
      selected={highlighted}
      style={style}
      onClick={handleClick}
      onMouseEnter={handleHoverEnter}
      onMouseLeave={handleHoverLeave}
    >
      <ListItemAvatar>{avatarForItem(item, 'tasks')}</ListItemAvatar>
      <ListItemText
        primaryTypographyProps={{ variant: 'body2' }}
        primary={item.title}
        secondary={item.number}
      />
    </ListItemButton>
  );
}

export default TaskLiveListItem;
