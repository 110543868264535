// source organizer might remove this, here it is for reference
// import { CancelToken } from 'axios';
import { CancelToken } from 'axios';
import {
  parseISO,
  startOfDay,
  startOfMonth,
  startOfYear,
  formatISO,
} from 'date-fns';
import _ from 'lodash';
import moment from 'moment';
import { ofType } from 'redux-observable';
import { from, of, forkJoin } from 'rxjs';
import { catchError, map, mergeMap, takeUntil, tap } from 'rxjs/operators';
import * as math from 'mathjs';
import { NormalDistribution } from '../data/utilities';
import {
  FETCH_AGGREGATED_DRIVING_SCORES,
  FETCH_AGGREGATED_DRIVING_SCORES_CANCELLED,
  FETCH_AGGREGATED_DRIVING_SCORES_FAILURE,
  FETCH_AGGREGATED_DRIVING_SCORES_SUCCESS,
  FETCH_AUDIT_LOG_ENTRIES,
  FETCH_AUDIT_LOG_ENTRIES_CANCELLED,
  FETCH_AUDIT_LOG_ENTRIES_FAILURE,
  FETCH_AUDIT_LOG_ENTRIES_SUCCESS,
  FETCH_DRIVER_TRIPS_BY_DRIVER_CODE,
  FETCH_DRIVER_TRIPS_BY_DRIVER_CODE_CANCELLED,
  FETCH_DRIVER_TRIPS_BY_DRIVER_CODE_FAILURE,
  FETCH_DRIVER_TRIPS_BY_DRIVER_CODE_SUCCESS,
  FETCH_DRIVING_SCORES,
  FETCH_DRIVING_SCORES_CANCELLED,
  FETCH_DRIVING_SCORES_FAILURE,
  FETCH_DRIVING_SCORES_SUCCESS,
  FETCH_VEHICLES_IN_LOCATIONS,
  FETCH_VEHICLES_IN_LOCATIONS_CANCELLED,
  FETCH_VEHICLES_IN_LOCATIONS_FAILURE,
  FETCH_VEHICLES_IN_LOCATIONS_SUCCESS,
  FETCH_VEHICLE_AVAILABILITY,
  FETCH_VEHICLE_AVAILABILITY_CANCELLED,
  FETCH_VEHICLE_AVAILABILITY_FAILURE,
  FETCH_VEHICLE_AVAILABILITY_SUCCESS,
  FETCH_PERSON_AVAILABILITY,
  FETCH_PERSON_AVAILABILITY_CANCELLED,
  FETCH_PERSON_AVAILABILITY_FAILURE,
  FETCH_PERSON_AVAILABILITY_SUCCESS,
  FETCH_VEHICLE_IN_BASE_TIME,
  FETCH_VEHICLE_IN_BASE_TIME_CANCELLED,
  FETCH_VEHICLE_IN_BASE_TIME_FAILURE,
  FETCH_VEHICLE_IN_BASE_TIME_SUCCESS,
  FETCH_VEHICLE_ODOMETERS,
  FETCH_VEHICLE_ODOMETERS_FAILURE,
  FETCH_VEHICLE_ODOMETERS_SUCCESS,
  LOAD_AGGREGATED_DRIVING_SCORES,
  LOAD_AGGREGATED_DRIVING_SCORES_FAILURE,
  LOAD_AGGREGATED_DRIVING_SCORES_SUCCESS,
  LOAD_VEHICLES_IN_LOCATIONS,
  LOAD_VEHICLES_IN_LOCATIONS_FAILURE,
  LOAD_VEHICLES_IN_LOCATIONS_SUCCESS,
  LOAD_VEHICLE_IN_BASE_TIME,
  LOAD_VEHICLE_IN_BASE_TIME_FAILURE,
  LOAD_VEHICLE_IN_BASE_TIME_SUCCESS,
} from '../actions';
import api, { fromAjax } from '../apis';
import {
  getHeaders,
  log,
  reduceByType as reduceAreas,
} from '../apis/utilities';
import db, { fetchCachedData } from '../data/db';

const {
  useReducedResourceInformation,
  tripClassifications,
  baseType,
  minimumSpeedInfractionSeconds = 45,
} = window.config;
const exemptTripClassifications = tripClassifications
  .filter(({ exempt }) => exempt)
  .map(({ value }) => value);

let cancel;

function areasFilter(record, filter) {
  let areaMatch = true;

  Object.entries(filter.areas).forEach((keyValuePair) => {
    if (
      keyValuePair[1].length !== 0 &&
      !keyValuePair[1].includes(record.areas[keyValuePair[0]])
    ) {
      areaMatch = false;
    }
  });

  return areaMatch;
}

function filterValuesFromData(data, filter, filterFunction) {
  const { areas: _, ...fields } = filter;
  const filterExcludingThisAreaKey = { ...filter };
  const areaDictionaries = data.reduce((areas, record) => {
    Object.keys(record.areas).forEach((type) => {
      const value = record.areas[type];

      // skip undefined
      if (value) {
        // if we haven't seen the area type before and it has a value (no undefined)
        // create a new dictionary
        if (!(type in areas)) {
          areas[type] = {};
        }

        // if we don't already have this value, see if it would be added in
        // if everything but this area type were considered
        if (!areas[type][value]) {
          // exclude area type from the filtering so we can get all values under key
          // otherwise when you choose one value of areas[key] that's the only
          // one shown in the options
          filterExcludingThisAreaKey.areas = { ...filter.areas, [type]: [] };
          if (filterFunction(record, filterExcludingThisAreaKey)) {
            areas[type][value] = true;
          }
        }
      }
    });

    return areas;
  }, {});

  // map to areas = {type1: [value1, value2 ...], type2: ...}
  let areas = {};
  Object.keys(areaDictionaries).forEach((key) => {
    areas[key] = Object.keys(areaDictionaries[key]).sort();
  });

  let result = { areas };

  for (const key in fields) {
    const keyFilter = { ...filter, [key]: [] };
    let valueDictionary = {};
    data
      .filter((record) => filterFunction(record, keyFilter))
      .map((record) => record[key])
      .forEach((value) => {
        if (!valueDictionary[value]) {
          valueDictionary[value] = true;
        }
      });

    result[key] = Object.keys(valueDictionary).sort();
  }

  return result;
}

function epochHoursToHistogram(startEpochHour, endEpochHour, epochHours) {
  let availabilityAtEpochHour = {};
  let histogram = {};

  // epochHours looks like this:
  // [[442668, 442669], [442668, 442669, 442670], ...]
  // (an epoch hour is a specific hour represented as hours after 1/1/1970,
  // e.g. 1 is 1/1/1970 01:00, 24 is 2/1/1970 00:00)
  // the first array is the epoch hours that the first vehicle
  // was at this location, the second array is the hours the second vehicle
  // was at this location etc.
  // sum these up so we know how many vehicles were there per hour
  // {442668: 2, 442669: 2, 442670: 1, ...}
  epochHours.forEach((hourArray) => {
    hourArray.forEach(({ $numberDecimal: hour }) => {
      // there will be lots of overlap (stops that started before our start)
      // so only include ones within our range
      if (startEpochHour <= hour && hour <= endEpochHour) {
        availabilityAtEpochHour[hour] =
          (availabilityAtEpochHour[hour] || 0) + 1;
      }
    });
  });

  // for the time range, get a histogram of the instances a particular
  // count was at the location e.g.
  // let results = {
  //   "0": 1, // there were no vehicles at the location for 1 hour
  //   "2": 3, // there were 2 vehicles at the location for 3 hours
  //   "3": 5, // there were 3 vehicles at the location for 5  hours
  //   "4": 8  // there were 4 vehicles at the location for 8 hours
  // }
  let maxInstances = -1;
  for (let i = startEpochHour; i < endEpochHour; i++) {
    // if there's no vehicles at this hour, set it to 0
    if (!availabilityAtEpochHour[i]) {
      availabilityAtEpochHour[i] = 0;
    }
    let instances = availabilityAtEpochHour[i];
    if (instances > maxInstances) {
      maxInstances = instances;
    }

    histogram[instances] = (histogram[instances] || 0) + 1;
  }

  // make sure there are no missed ones e.g. 1,2,3,5 should have 4 in there
  for (let i = 0; i <= maxInstances; i++) {
    if (!histogram[i]) {
      histogram[i] = 0;
    }
  }

  // transform it a bit for recharts...
  return [
    Object.keys(availabilityAtEpochHour)
      .sort()
      .map((epochHour) => ({
        hour: moment.unix(epochHour * 3600),
        count: availabilityAtEpochHour[epochHour],
      })),
    Object.keys(histogram)
      .sort((a, b) => a - b) // sort numeric keys 1, 2, 10 instead of 1, 10, 2
      .map((count) => ({
        count,
        hours: histogram[count],
      })),
  ];
}

async function fetchVehicleAvailabilityRequest(
  startTime,
  endTime,
  filter,
  homeOnly,
  customConfidence
) {
  const isoStart = formatISO(startTime);
  const isoEnd = formatISO(endTime);
  // '$match': {
  //   'startTime': {
  //     $gte: '2020-07-01T00:00:00.000Z',// formatISO(startTime),
  //     $lt: '2020-07-31T00:00:00.000Z'// formatISO(endTime),
  //   },
  const grouping = useReducedResourceInformation ? 'type' : 'role';

  // using mongo aggregation we can match the stops we want (by dates)
  // then group by location and role
  let pipeline = [
    {
      $match: {
        startTime: {
          $lt: isoEnd,
        },
        endTime: {
          $gte: isoStart,
        },
        'locations.type': baseType.label,
        ...(homeOnly
          ? {
              $expr: {
                $or: [
                  {
                    $in: [
                      '$vehicle.homeStation',
                      '$locations.tranmanIdentifier',
                    ],
                  },
                  { $in: ['$vehicle.homeStation', '$locations.code'] },
                  { $in: ['$vehicle.homeStation', '$locations.name'] },
                ],
              },
            }
          : {}),
        // $expr: {
        //   $gte: [
        //     '$endTime',
        //     {
        //       $add: ['$startTime', 3600000],
        //     },
        //   ],
        // },
      },
    },
    {
      $project: {
        baseLocation: {
          $arrayElemAt: [
            {
              $filter: {
                input: '$locations',
                as: 'location',
                cond: { $eq: ['$$location.type', baseType.label] },
              },
            },
            0,
          ],
        },
        startTime: true,
        endTime: true,
        vehicle: true,
      },
    },
    {
      $group: {
        //null,
        _id: {
          locationCode: '$baseLocation.code',
          grouping: { $ifNull: [`$vehicle.${grouping}`, ''] },
        },

        // totalHoursStopped: { $sum: 1 },
        hours: {
          $push: {
            $map: {
              input: {
                $range: [
                  {
                    $floor: {
                      $divide: [
                        {
                          $subtract: [
                            '$startTime',
                            { $toDate: '1970-01-01T00:00:00.000Z' },
                          ],
                        },
                        1000,
                      ],
                    },
                  },
                  {
                    $floor: {
                      $divide: [
                        {
                          $subtract: [
                            '$endTime',
                            { $toDate: '1970-01-01T00:00:00.000Z' },
                          ],
                        },
                        1000,
                      ],
                    },
                  },
                  3600,
                ],
              },
              as: 'time',
              in: {
                $floor: {
                  $divide: [
                    {
                      $toDecimal: '$$time',
                    },
                    3600,
                  ],
                },
              },
            },
          },
        },
      },
    },
  ];

  const headers = getHeaders();
  const [locationsResult, vehiclesResult, telematicsResult, stopsResult] =
    await Promise.all([
      api.get('/locations', {
        params: {
          projection: { code: true, name: true, type: true },
        },
        headers,
      }),
      api.get('/vehicles', {
        params: {
          projection: { telematicsBoxImei: true, role: true, type: true },
        },
        headers,
      }),
      api.get('/telematicsBoxes', {
        params: {
          query: { events: { $exists: true } },
          projection: { imei: true, events: true, 'mostRecentPoll.time': true },
        },
        headers,
      }),
      api.get('/stops', {
        params: {
          pipeline: JSON.stringify(pipeline),
        },
        headers,
        cancelToken: new CancelToken((c) => {
          cancel = c;
        }),
      }),
    ]);

  const locationsByCode = _.keyBy(locationsResult.data, 'code');
  const vehiclesByImei = _.keyBy(vehiclesResult.data, 'telematicsBoxImei');

  // an epochHour is the number of hours since 1/1/1970
  function isoDateToEpochHour(isoDate) {
    if (!!isoDate) {
      return Math.floor(moment(isoDate).unix() / 3600);
    }

    return null;
  }
  const startEpochHour = isoDateToEpochHour(isoStart);
  const endEpochHour = isoDateToEpochHour(isoEnd);

  // STOPs are only created when a vehicle starts again, so there could be vehicles
  // that are currently at the location with no STOP. The most recent stop event of
  // the telematics box has the current location and from the start time of that event
  // we can work out how long it has been at the location (so far)
  const currentStops = telematicsResult.data
    .filter(
      (t) =>
        t.events &&
        t.events.some(
          (e) =>
            e.eventType === 'STOP' &&
            e.startTime < isoEnd &&
            e.locations?.length > 0
        )
    )
    .map((t) => {
      const stopEvent = t.events.find((e) => e.eventType === 'STOP');
      const startEpochHour = isoDateToEpochHour(
        // start from the later of when it arrived at location or the start of the query
        stopEvent.startTime > isoStart ? stopEvent.startTime : isoStart
      );
      return {
        locationCode: stopEvent.locations[0].code,
        grouping: vehiclesByImei[t.imei]?.[grouping] || '',
        hours: _.range(startEpochHour, endEpochHour, 1),
      };
    });

  const stops = stopsResult.data;

  function getStopKey(stop) {
    return stop.locationCode + stop.grouping;
  }

  const currentStopsByKey = _.groupBy(currentStops, getStopKey);

  let statsPerLocationAndGrouping = {};
  stops.forEach(({ _id: { locationCode, grouping }, hours }) => {
    const location = locationsByCode[locationCode];
    const stopKey = getStopKey({ locationCode, grouping });

    // add all the current stops for this location
    if (currentStopsByKey[stopKey]) {
      currentStopsByKey[stopKey].forEach((currentStop) => {
        hours.push(currentStop.hours);
      });
    }

    const [availabilities, histogram] = epochHoursToHistogram(
      startEpochHour,
      endEpochHour,
      hours
    );
    const instanceArray = Object.values(histogram).map((h) =>
      new Array(h.hours).fill(h.count)
    );
    const std = math.std(instanceArray);
    const mean = math.mean(instanceArray);

    const normalDistribution = new NormalDistribution(mean, std);
    function invp(p) {
      const result = normalDistribution.invCumulativeProbability(1 - p);
      return result > 0 ? result : 0;
    }

    statsPerLocationAndGrouping[stopKey] = {
      stopKey,
      location: location?.name || locationCode,
      // locationType: location?.type || 'Unknown',
      grouping,
      // p95: inv95 * std,
      // p975: 2.5 * std, //inv975 * std,
      // p99: inv99 * std,
      pCustom: invp(customConfidence / 100),
      p95: invp(0.95),
      p975: invp(0.975),
      p99: invp(0.99),
      std,
      mean,
      availabilities,
      histogram,
    };
  });

  const data = Object.values(statsPerLocationAndGrouping);

  // TODOJL!
  const filterOptions = {
    location: _.uniq(data.map((l) => l.location)).sort(),
    // locationType: _.uniq(data.map((l) => l.locationType)).sort(),
    grouping: _.uniq(data.map((l) => l.grouping)).sort(),
  };

  const filteredData = data.filter((record) =>
    Object.keys(filter).every(
      (key) =>
        (filter[key]?.length || 0) === 0 || filter[key].includes(record[key])
    )
  );

  const results = {
    filter,
    homeOnly,
    filterOptions,
    // ...getVehicleUtilisationFilterAndGroupByValues(data, filter),
    filteredData,
    data, //: getVehicleDailyUtilisation(filteredData, groupBy),
  };

  log('Read', 'Vehicle Availability', {
    startTime,
    endTime,
  });

  return results;
}

export function fetchVehicleAvailabilityEpic(action$) {
  return action$.pipe(
    ofType(FETCH_VEHICLE_AVAILABILITY),
    mergeMap(
      ({
        payload: { startTime, endTime, filter, homeOnly, customConfidence },
      }) =>
        from(
          fetchVehicleAvailabilityRequest(
            startTime,
            endTime,
            filter,
            homeOnly,
            customConfidence
          )
        ).pipe(
          map((payload) => ({
            type: FETCH_VEHICLE_AVAILABILITY_SUCCESS,
            payload,
          })),
          takeUntil(
            action$.pipe(
              ofType(FETCH_VEHICLE_AVAILABILITY_CANCELLED),
              tap((ev) => cancel('cancelled'))
            )
          ),
          catchError(({ message: payload }) =>
            of({
              type: FETCH_VEHICLE_AVAILABILITY_FAILURE,
              payload,
            })
          )
        )
    )
  );
}

async function fetchPeopleAvailabilityRequest(
  startTime,
  endTime,
  filter,
  customConfidence
) {
  const isoStart = formatISO(startTime);
  const isoEnd = formatISO(endTime);
  const grouping = useReducedResourceInformation ? 'type' : 'role';

  let pipeline = [
    {
      $match: {
        startTime: {
          $lt: isoEnd,
        },
        endTime: {
          $gte: isoStart,
        },
        'value.category': 'Available', //{ $exists: true }
        $expr: {
          // more than half an hour
          $gt: [{ $subtract: ['$endTime', '$startTime'] }, 30 * 60 * 1000],
        },
      },
    },
    {
      $project: {
        code: true,
        startTime: true,
        endTime: true,
        // category: "$value.category",
      },
    },
    {
      $group: {
        //null,
        _id: {
          code: '$code',
          // category: '$category'
        },
        hours: {
          $push: {
            $map: {
              input: {
                $range: [
                  {
                    $floor: {
                      $divide: [
                        {
                          $subtract: [
                            '$startTime',
                            { $toDate: '1970-01-01T00:00:00.000Z' },
                          ],
                        },
                        1000,
                      ],
                    },
                  },
                  {
                    $floor: {
                      $divide: [
                        {
                          $subtract: [
                            '$endTime',
                            { $toDate: '1970-01-01T00:00:00.000Z' },
                          ],
                        },
                        1000,
                      ],
                    },
                  },
                  3600,
                ],
              },
              as: 'time',
              in: {
                $floor: {
                  $divide: [
                    {
                      $toDecimal: '$$time',
                    },
                    3600,
                  ],
                },
              },
            },
          },
        },
      },
    },
    // { $sort: { _id: 1 } },
  ];

  const headers = getHeaders();
  const [locationsResult, peopleResult, attributionChangesResult] =
    await Promise.all([
      api.get('/locations', {
        params: {
          projection: { code: true, name: true, type: true },
        },
        headers,
      }),
      api.get('/people', {
        params: {
          projection: { code: true, homeStation: true, [grouping]: true },
        },
        headers,
      }),
      api.get('/personAttributions', {
        params: {
          pipeline: JSON.stringify(pipeline),
        },
        headers,
        cancelToken: new CancelToken((c) => {
          cancel = c;
        }),
      }),
    ]);

  const locationsByCode = _.keyBy(locationsResult.data, 'code');
  const peopleByCode = _.keyBy(peopleResult.data, 'code');

  // an epochHour is the number of hours since 1/1/1970
  function isoDateToEpochHour(isoDate) {
    if (!!isoDate) {
      return Math.floor(moment(isoDate).unix() / 3600);
    }

    return null;
  }
  const startEpochHour = isoDateToEpochHour(isoStart);
  const endEpochHour = isoDateToEpochHour(isoEnd);

  function getKey(stop) {
    return stop.homeStation + stop.grouping;
  }

  const attributionHoursByKey = {};
  attributionChangesResult.data.forEach((a) => {
    const person = peopleByCode[a._id?.code];

    if (person) {
      const key = getKey({
        homeStation: person.homeStation,
        grouping: person[grouping],
      });

      if (!attributionHoursByKey[key]) {
        attributionHoursByKey[key] = {
          key,
          homeStation: person.homeStation,
          grouping: person[grouping],
          hours: [],
        };
      }

      Array.prototype.push.apply(attributionHoursByKey[key].hours, a.hours);
    }
  });

  let statsPerHomeStationAndGrouping = {};
  Object.values(attributionHoursByKey).forEach(
    ({ key, homeStation, grouping, hours }) => {
      const location = locationsByCode[homeStation];

      const [availabilities, histogram] = epochHoursToHistogram(
        startEpochHour,
        endEpochHour,
        hours
      );
      const instanceArray = Object.values(histogram).map((h) =>
        new Array(h.hours).fill(h.count)
      );
      const std = math.std(instanceArray);
      const mean = math.mean(instanceArray);

      const normalDistribution = new NormalDistribution(mean, std);
      function invp(p) {
        const result = normalDistribution.invCumulativeProbability(1 - p);
        return result > 0 ? result : 0;
      }

      statsPerHomeStationAndGrouping[key] = {
        stopKey: key,
        homeStation: location?.name || homeStation,
        // locationType: location?.type || 'Unknown',
        grouping,
        // p95: inv95 * std,
        // p975: 2.5 * std, //inv975 * std,
        // p99: inv99 * std,
        pCustom: invp(customConfidence / 100),
        p95: invp(0.95),
        p975: invp(0.975),
        p99: invp(0.99),
        std,
        mean,
        availabilities,
        histogram,
      };
    }
  );

  const data = Object.values(statsPerHomeStationAndGrouping);

  // TODOJL!
  const filterOptions = {
    homeStation: _.uniq(data.map((l) => l.homeStation)).sort(),
    // locationType: _.uniq(data.map((l) => l.locationType)).sort(),
    grouping: _.uniq(data.map((l) => l.grouping)).sort(),
  };

  const filteredData = data.filter((record) =>
    Object.keys(filter).every(
      (key) =>
        (filter[key]?.length || 0) === 0 || filter[key].includes(record[key])
    )
  );

  const results = {
    filter,
    filterOptions,
    // ...getPeopleUtilisationFilterAndGroupByValues(data, filter),
    filteredData,
    data, //: getPeopleDailyUtilisation(filteredData, groupBy),
  };

  log('Read', 'People Availability', {
    startTime,
    endTime,
  });

  return results;
}

export function fetchPeopleAvailabilityEpic(action$) {
  return action$.pipe(
    ofType(FETCH_PERSON_AVAILABILITY),
    mergeMap(
      ({
        payload: { startTime, endTime, filter, homeOnly, customConfidence },
      }) =>
        from(
          fetchPeopleAvailabilityRequest(
            startTime,
            endTime,
            filter,
            homeOnly,
            customConfidence
          )
        ).pipe(
          map((payload) => ({
            type: FETCH_PERSON_AVAILABILITY_SUCCESS,
            payload,
          })),
          takeUntil(
            action$.pipe(
              ofType(FETCH_PERSON_AVAILABILITY_CANCELLED),
              tap((ev) => cancel('cancelled'))
            )
          ),
          catchError(({ message: payload }) =>
            of({
              type: FETCH_PERSON_AVAILABILITY_FAILURE,
              payload,
            })
          )
        )
    )
  );
}
/*
async function fetchUnknownDriverTripsRequest(startTime, endTime, cardUsed) {
  const response = await api.get('/trips', {
    params: {
      query: {
        'driver.code': '',
        startTime: { $lt: endTime },
        endTime: { $gt: startTime },
        distanceKilometres: { $gt: 0 },
        ...(cardUsed === 2
          ? {}
          : {
              'driver.identificationReference': Boolean(cardUsed)
                ? { $ne: '' }
                : '',
            }),
      },
      projection: {
        identifier: true,
        vehicle: true,
        driver: true,
        startTime: true,
        endTime: true,
        durationSeconds: true,
        distanceKilometres: true,
        maxSpeedKilometresPerHour: true,
        startLocations: true,
        endLocations: true,
        classification: true,
      },
    },
    headers: getHeaders(),
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
  });

  const trips = (response.data || []).map((trip) => {
    const startLocation = getPrimaryLocation(trip.startLocations);
    const endLocation = getPrimaryLocation(trip.endLocations);

    return {
      identifier: trip.identifier,
      serialNumber: trip.driver
        ? trip.driver.identificationReference || 'None'
        : 'None',
      identificationNumber: trip.vehicle.identificationNumber,
      registrationNumber: trip.vehicle.registrationNumber,
      fleetNumber: trip.vehicle.fleetNumber,
      role: trip.vehicle.role,
      type: trip.vehicle.type,
      imei: trip.vehicle.telematicsBoxImei,
      startTime: trip.startTime,
      endTime: trip.endTime,
      durationMinutes: trip.durationSeconds / 60,
      distanceMiles: trip.distanceKilometres * 0.62137119,
      maxSpeedMilesPerHour: trip.maxSpeedKilometresPerHour * 0.62137119,
      startLocationType: startLocation.type,
      startLocationName: startLocation.name,
      endLocationType: endLocation.type,
      endLocationName: endLocation.name,
    };
  });

  log('Read', 'Unknown Driver Trips', {
    startTime,
    endTime,
  });

  return trips;
}

export function fetchUnknownDriverTripsEpic(action$) {
  return action$.pipe(
    ofType(FETCH_UNKNOWN_DRIVER_TRIPS),
    mergeMap(({ payload: { startTime, endTime, cardUsed } }) =>
      from(fetchUnknownDriverTripsRequest(startTime, endTime, cardUsed)).pipe(
        map((payload) => ({
          type: FETCH_UNKNOWN_DRIVER_TRIPS_SUCCESS,
          payload,
        })),
        takeUntil(
          action$.pipe(
            ofType(FETCH_UNKNOWN_DRIVER_TRIPS_CANCELLED),
            tap((ev) => cancel('cancelled'))
          )
        ),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_UNKNOWN_DRIVER_TRIPS_FAILURE,
            payload,
          })
        )
      )
    )
  );
}
*/

async function fetchAuditLogEntriesRequest(startTime, endTime, userId) {
  const excludeList = [
    'Briefs',
    'Collections',
    'Vehicles',
    'People',
    'Collections',
    'Queries',
    'Plans',
    'Selections',
    'Locations',
    'Features',
    'Objectives',
    'Telematics Boxes',
    'Retrospectives',
    'Telematics Box Polls',
  ];

  const response = await api.get('/audits', {
    params: {
      query: {
        time: { $gte: startTime, $lt: endTime },
        user: userId ? userId : undefined,
      },
      projection: {
        user: true,
        dataType: true,
        time: true,
        action: true,
        parameters: true,
      },
    },
    headers: getHeaders(),
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
  });

  const data = response.data
    .filter((entry) => !excludeList.includes(entry.dataType))
    .map(({ user, ...entry }) => {
      const parameters = entry.parameters || {};

      return {
        ...entry,
        userId: user,
        itemId:
          parameters.id ||
          parameters.identifier ||
          parameters.code ||
          parameters.identificationNumber ||
          null,
        dataType: entry.dataType
          ? entry.dataType.replace('Query', 'Collection')
          : null,
        startTime:
          parameters.startTime || parameters.startTime
            ? new Date(parameters.startTime || parameters.startTime)
            : null,
        endTime:
          parameters.endTime || parameters.endTime
            ? new Date(parameters.endTime || parameters.endTime)
            : null,
      };
    });

  log('Read', 'Audit Log Entries', {
    startTime,
    endTime,
    userId,
  });

  return _.orderBy(data, ['time'], ['desc']);
}

export function fetchAuditLogEntriesEpic(action$) {
  return action$.pipe(
    ofType(FETCH_AUDIT_LOG_ENTRIES),
    mergeMap(({ payload: { startTime, endTime, userId } }) =>
      from(fetchAuditLogEntriesRequest(startTime, endTime, userId)).pipe(
        map((payload) => ({
          type: FETCH_AUDIT_LOG_ENTRIES_SUCCESS,
          payload,
        })),
        takeUntil(
          action$.pipe(
            ofType(FETCH_AUDIT_LOG_ENTRIES_CANCELLED),
            tap((ev) => cancel('cancelled'))
          )
        ),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_AUDIT_LOG_ENTRIES_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

function vehicleInBaseFilter(record, filter) {
  if (
    filter.registrationNumber.length !== 0 &&
    !filter.registrationNumber.includes(record.registrationNumber)
  ) {
    return false;
  }

  if (
    filter.fleetNumber.length !== 0 &&
    !filter.fleetNumber.includes(record.fleetNumber)
  ) {
    return false;
  }

  if (filter.role.length !== 0 && !filter.role.includes(record.role)) {
    return false;
  }

  if (filter.type.length !== 0 && !filter.type.includes(record.type)) {
    return false;
  }

  if (
    filter.locationName.length !== 0 &&
    !filter.locationName.includes(record.locationName)
  ) {
    return false;
  }

  if (
    filter.locationType.length !== 0 &&
    !filter.locationType.includes(record.locationType)
  ) {
    return false;
  }

  return areasFilter(record, filter);
}

function getEmptyByHourByBase(locationNames) {
  const hours = Array(24)
    .fill()
    .map((_, index) => index);

  const byHourByBase = {};
  for (let hour of hours) {
    byHourByBase[hour] = { Hour: moment({ hour }).format('HH:mm') };
    for (let locationName of locationNames.sort()) {
      byHourByBase[hour][locationName] = 0;
    }
  }
  return byHourByBase;
}

function getVehicleInBaseFilterValues(data, filter) {
  const { areas: _, ...fields } = filter;
  const result = { areas: {} };
  const areas = Array.from(
    new Set([].concat(...data.map((record) => Object.keys(record.areas))))
  );

  for (const key in fields) {
    const keyFilter = { ...filter, [key]: [] };
    result[key] = Array.from(
      new Set(
        data
          .filter((record) => vehicleInBaseFilter(record, keyFilter))
          .map((record) => record[key])
      )
    )
      .filter((value) => value !== undefined)
      .sort();
  }

  for (const key of areas) {
    const keyFilter = { ...filter, areas: { ...filter.areas, [key]: [] } };
    result.areas[key] = Array.from(
      new Set(
        data
          .filter((record) => vehicleInBaseFilter(record, keyFilter))
          .map((record) => record.areas[key])
      )
    )
      .filter((value) => value !== undefined)
      .sort();
  }

  return result;
}

function getVehicleInBaseTime(data) {
  const locationsNames = Array.from(
    new Set(data.map((record) => record.locationName))
  );
  const dates = data.map((record) => record.hour);
  const maxDate = new Date(Math.max.apply(null, dates));
  const minDate = new Date(Math.min.apply(null, dates));
  const count =
    moment(maxDate)
      .startOf('day')
      .diff(moment(minDate).startOf('day'), 'days') + 1;

  const byHourByBase = getEmptyByHourByBase(locationsNames);

  for (let record of data) {
    byHourByBase[moment(record.hour).hour()][record.locationName] +=
      record.durationSeconds / 3600;
  }

  for (let hour in byHourByBase) {
    for (let locationName in byHourByBase[hour]) {
      if (locationName !== 'Hour') {
        byHourByBase[hour][locationName] = _.round(
          byHourByBase[hour][locationName] / count,
          2
        );
      }
    }
  }

  return byHourByBase;
}

async function fetchVehicleInBaseTimeRequest(query, filter) {
  const response = await api.get('/intersections', {
    params: {
      query,
      projection: {
        identifier: true,
        vehicle: true,
        startTime: true,
        endTime: true,
        durationSeconds: true,
        location: true,
      },
    },
    headers: getHeaders(),
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
  });

  const data = []
    .concat(
      ...response.data.map(
        ({
          startTime,
          endTime,
          vehicle: {
            identificationNumber,
            registrationNumber,
            fleetNumber,
            role,
            type,
            areas,
          },
          location: { name: locationName, type: locationType },
        }) => {
          const count = moment(endTime)
            .startOf('hour')
            .add(1, 'hour')
            .diff(moment(startTime).startOf('hour'), 'hours');
          const reducedAreas = reduceAreas(areas);

          if (count === 1) {
            return [
              {
                identificationNumber,
                registrationNumber,
                fleetNumber,
                role,
                type,
                areas: reducedAreas,
                locationName,
                locationType,
                hour: moment(startTime).startOf('hour').toDate(),
                durationSeconds: moment(endTime).diff(
                  moment(startTime),
                  'seconds'
                ),
              },
            ];
          } else if (count === 2) {
            return [
              {
                identificationNumber,
                registrationNumber,
                fleetNumber,
                role,
                type,
                areas: reducedAreas,
                locationName,
                locationType,
                hour: moment(startTime).startOf('hour').toDate(),
                durationSeconds: moment(startTime)
                  .add(1, 'hour')
                  .startOf('hour')
                  .diff(moment(startTime), 'seconds'),
              },
              {
                identificationNumber,
                registrationNumber,
                fleetNumber,
                role,
                type,
                areas: reducedAreas,
                locationName,
                locationType,
                hour: moment(endTime).startOf('hour').toDate(),
                durationSeconds: moment(endTime).diff(
                  moment(endTime).startOf('hour'),
                  'seconds'
                ),
              },
            ];
          } else {
            return [
              {
                identificationNumber,
                registrationNumber,
                fleetNumber,
                role,
                type,
                areas: reducedAreas,
                locationName,
                locationType,
                hour: moment(startTime).startOf('hour').toDate(),
                durationSeconds: moment(startTime)
                  .add(1, 'hour')
                  .startOf('hour')
                  .diff(moment(startTime), 'seconds'),
              },
              ...Array(count - 2)
                .fill()
                .map((_, index) => ({
                  identificationNumber,
                  registrationNumber,
                  fleetNumber,
                  role,
                  type,
                  areas: reducedAreas,
                  locationName,
                  locationType,
                  hour: moment(startTime)
                    .startOf('hour')
                    .add(index + 1, 'hours')
                    .toDate(),
                  durationSeconds: 3600,
                })),
              {
                identificationNumber,
                registrationNumber,
                fleetNumber,
                role,
                type,
                areas: reducedAreas,
                locationName,
                locationType,
                hour: moment(endTime).startOf('hour').toDate(),
                durationSeconds: moment(endTime).diff(
                  moment(endTime).startOf('hour'),
                  'seconds'
                ),
              },
            ];
          }
        }
      )
    )
    .filter((record) =>
      moment(record.hour).isBetween(
        moment.utc(moment(query.endTime.$gte).format('YYYY-MM-DD HH:mm:ss')),
        moment.utc(moment(query.startTime.$lte).format('YYYY-MM-DD HH:mm:ss')),
        'day',
        '[]'
      )
    );

  await db.vehicleInBaseTime.clear();
  await db.vehicleInBaseTime.add(data);
  await db.parameters.put({ store: 'vehicleInBaseTime', query });

  const filteredData = data.filter((record) =>
    vehicleInBaseFilter(record, filter)
  );

  const results = {
    filter,
    filterValues: getVehicleInBaseFilterValues(data, filter),
    data: getVehicleInBaseTime(filteredData),
  };

  log('Read', 'Vehicle In Base Time', query);

  return results;
}

export function fetchVehicleInBaseTimeEpic(action$) {
  return action$.pipe(
    ofType(FETCH_VEHICLE_IN_BASE_TIME),
    mergeMap(({ payload: { query, filter } }) =>
      from(fetchVehicleInBaseTimeRequest(query, filter)).pipe(
        map((payload) => ({
          type: FETCH_VEHICLE_IN_BASE_TIME_SUCCESS,
          payload,
        })),
        takeUntil(
          action$.pipe(
            ofType(FETCH_VEHICLE_IN_BASE_TIME_CANCELLED),
            tap((ev) => cancel('cancelled'))
          )
        ),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_VEHICLE_IN_BASE_TIME_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function loadVehicleInBaseTimeRequest(filter) {
  const reportName = 'vehicleInBaseTime';
  const data = await fetchCachedData(reportName);
  const parameters = await db.parameters.get(reportName);

  const filteredData = data.filter((record) =>
    vehicleInBaseFilter(record, filter)
  );

  const results = {
    filter,
    filterValues: getVehicleInBaseFilterValues(data, filter),
    data: getVehicleInBaseTime(filteredData),
  };

  log('Load', 'Vehicle In Base Time', parameters);

  return results;
}

export function loadVehicleInBaseTimeEpic(action$) {
  return action$.pipe(
    ofType(LOAD_VEHICLE_IN_BASE_TIME),
    mergeMap(({ payload: filter }) =>
      from(loadVehicleInBaseTimeRequest(filter)).pipe(
        map((payload) => ({
          type: LOAD_VEHICLE_IN_BASE_TIME_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: LOAD_VEHICLE_IN_BASE_TIME_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

function drivingScoresFilter(record, filter) {
  if (filter.code.length !== 0 && !filter.code.includes(record.code)) {
    return false;
  }

  if (filter.name.length !== 0 && !filter.name.includes(record.name)) {
    return false;
  }

  if (
    filter.collarNumber.length !== 0 &&
    !filter.collarNumber.includes(record.collarNumber)
  ) {
    return false;
  }

  if (filter.role.length !== 0 && !filter.role.includes(record.role)) {
    return false;
  }

  return areasFilter(record, filter);
}

function getDrivingScoresFilterValues(data, filter) {
  const { areas: _, ...fields } = filter;
  const result = { areas: {} };
  const filterExcludingThisAreaKey = { ...filter };
  const areaDictionaries = data.reduce((areas, record) => {
    Object.keys(record.areas).forEach((type) => {
      const value = record.areas[type];

      // skip undefined
      if (value) {
        // if we haven't seen the area type before and it has a value (no undefined)
        // create a new dictionary
        if (!(type in areas)) {
          areas[type] = {};
        }

        // if we don't already have this value, see if it would be added in
        // if everything but this area type were considered
        if (!areas[type][value]) {
          // exclude area type from the filtering so we can get all values under key
          // otherwise when you choose one value of areas[key] that's the only
          // one shown in the options
          filterExcludingThisAreaKey.areas = { ...filter.areas, [type]: [] };
          if (drivingScoresFilter(record, filterExcludingThisAreaKey)) {
            areas[type][value] = true;
          }
        }
      }
    });

    return areas;
  }, {});

  // map to areas = {type1: [value1, value2 ...], type2: ...}
  result.areas = Object.fromEntries(
    Object.keys(areaDictionaries).map((key) => [
      key,
      Object.keys(areaDictionaries[key]).sort(),
    ])
  );

  // this is neat but unfortunately causes a crash if there are too many records
  // "maximum call stack size exceeded"
  //  Array.from(
  //     new Set([].concat(...data.map(record => Object.keys(record.areas))))
  //   );

  // for (const key of areas) {
  //   const keyFilter = { ...filter, areas: { ...filter.areas, [key]: [] } };
  //   result.areas[key] = Array.from(
  //     new Set(
  //       data
  //         .filter(record => drivingScoresFilter(record, keyFilter))
  //         .map(record => record.areas[key])
  //     )
  //   )
  //     .filter(Boolean)
  //     .sort(); // remove undefined with filter(Boolean)
  // }

  for (const key in fields) {
    const keyFilter = { ...filter, [key]: [] };
    result[key] = Array.from(
      new Set(
        data
          .filter((record) => drivingScoresFilter(record, keyFilter))
          .map((record) => record[key])
      )
    )
      .filter((value) => value !== undefined)
      .sort();
  }

  return result;
}

function calculateScore(record) {
  const drivingSeconds =
    record.drivingSeconds || record.tripsDurationSeconds || record.seconds || 0;
  const accelerationSeconds =
    record.harshAccelerationSeconds ||
    record.excessAccelerationDurationSeconds ||
    record.excessAccelSeconds ||
    0;
  const brakingSeconds =
    record.harshBrakingSeconds ||
    record.excessBrakingDurationSeconds ||
    record.excessBrakeSeconds ||
    0;
  const corneringSeconds =
    record.harshCorneringSeconds ||
    record.excessCorneringDurationSeconds ||
    record.excessCorneringSeconds ||
    0;
  const speedingSeconds =
    record.speedingSeconds || record.speedInfractionDurationSeconds || 0;

  return !drivingSeconds || drivingSeconds === 0
    ? 0
    : // JL perf: this is expensive: _.round(
      ((drivingSeconds -
        speedingSeconds -
        accelerationSeconds -
        brakingSeconds -
        corneringSeconds) /
        drivingSeconds) *
        100;
  //,2);
}

function getAggregatedDrivingScores(
  rawData,
  timeAggregation = 'days',
  tripsOnly = false
) {
  // moment.format and date-fns.format too slow!
  // const timeAggregationFormat = {
  //   days: 'dd/MM/yyyy',
  //   months: 'MMM',
  //   years: 'yyyy'
  // }[timeAggregation];

  // TODOJL should I be getting UTCYear... UTC stuff in general
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const timeAggregationFormatFunction = {
    days: (d) => `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`,
    months: (d) => monthNames[d.getMonth()],
    years: (d) => d.getFullYear(),
  }[timeAggregation];
  const dateKeyFunction = (d) =>
    d.getFullYear() * 10000 + d.getMonth() * 100 + d.getDate();

  // the ... operator is expensive
  // const totalTemplate = {
  //   drivingSeconds: 0,
  //   harshAccelerationSeconds: 0,
  //   harshBrakingSeconds: 0,
  //   harshCorneringSeconds: 0,
  //   speedInfractions: 0,
  //   speedInfractionDurationSeconds: 0,
  //   lowestScore: 100,
  //   highestScore: 0
  // };

  const subTotalKeys = [
    'statsAll',
    'statsWithEmergencyEquipment',
    'statsWithoutEmergencyEquipment',
  ];

  function addRecordToAccumulatorEntry(accEntry, record) {
    accEntry.drivingSeconds += record.tripsDurationSeconds;
    accEntry.harshAccelerationSeconds +=
      record.excessAccelerationDurationSeconds;
    accEntry.harshBrakingSeconds += record.excessBrakingDurationSeconds;
    accEntry.harshCorneringSeconds += record.excessCorneringDurationSeconds;
    accEntry.speedInfractions += record.speedInfractions;
    accEntry.speedInfractionDurationSeconds +=
      record.speedInfractionDurationSeconds;
    accEntry.lowestScore = Math.min(accEntry.lowestScore, record.score);
    accEntry.highestScore = Math.max(accEntry.highestScore, record.score);
  }

  function addProportionsToRecord(record) {
    record.excessAccelerationRatio =
      record.excessAccelerationDurationSeconds / record.tripsDurationSeconds;
    record.excessBrakingRatio =
      record.excessBrakingDurationSeconds / record.tripsDurationSeconds;
    record.excessCorneringRatio =
      record.excessCorneringDurationSeconds / record.tripsDurationSeconds;
    record.speedInfractionRatio =
      record.speedInfractionDurationSeconds / record.tripsDurationSeconds;
  }

  const data = {};

  subTotalKeys.forEach((key) => {
    if (tripsOnly) {
      data[key] = {
        trips: rawData
          .filter((d) => d[key].trips > 0)
          .map(
            ({
              startTime,
              endTime,
              code,
              collarNumber,
              fleetNumber,
              registrationNumber,
              identificationNumber,
              name,
              ...record
            }) => ({
              startTime,
              endTime,
              name,
              code,
              collarNumber,
              fleetNumber,
              registrationNumber,
              identificationNumber,
              score: record[key].score,
              durationSeconds: record[key].tripsDurationSeconds,
              mileage: record[key].tripsDistanceMileage,
            })
          )
          .sort((a, b) => a.startTime - b.startTime),
      };
    } else {
      data[key] = rawData.reduce(
        (accumulator, record) => {
          // use date-fns instead
          const startTime = new Date(record.time);

          record[key].score = calculateScore(record[key]);
          record[key].startTime = startTime;
          // record[key].endTime = endTime;

          // record[key].label = format(startTime, timeAggregationFormat);
          record[key].label = timeAggregationFormatFunction(startTime);
          addProportionsToRecord(record[key]);

          // skip ones that have a score of 0 - no trips for example...
          if (record[key].score === 0) {
            return accumulator;
          }

          addRecordToAccumulatorEntry(accumulator.totals, record[key]);

          // trend: records by date essentially
          // const dateKey = format(startTime, 'yyyyMMdd');
          const dateKey = dateKeyFunction(startTime);

          let currentTrendItem = accumulator.trend[dateKey];
          if (!currentTrendItem) {
            currentTrendItem = {
              label: record[key].label, // JL perf: format is kinda expensive (see "Date")
              date: startTime,

              drivingSeconds: 0,
              harshAccelerationSeconds: 0,
              harshBrakingSeconds: 0,
              harshCorneringSeconds: 0,
              speedInfractions: 0,
              speedInfractionDurationSeconds: 0,
              lowestScore: 100,
              highestScore: 0,
            };
            accumulator.trend[dateKey] = currentTrendItem;
          }
          addRecordToAccumulatorEntry(currentTrendItem, record[key]);

          // driver: records by driver
          const driverKey = record.code || 'Unknown';
          if (driverKey) {
            let currentDriver = accumulator.drivers[driverKey];
            if (!currentDriver) {
              currentDriver = {
                code: record.code,
                name: record.name,
                collarNumber: record.collarNumber,
                role: record.role,
                areas: record.areas,
                mileage: 0,
                aggregatedTrips: [],

                drivingSeconds: 0,
                harshAccelerationSeconds: 0,
                harshBrakingSeconds: 0,
                harshCorneringSeconds: 0,
                speedInfractions: 0,
                speedInfractionDurationSeconds: 0,
                lowestScore: 100,
                highestScore: 0,
              };
              accumulator.drivers[driverKey] = currentDriver;
            }
            currentDriver.aggregatedTrips.push(record[key]);
            addRecordToAccumulatorEntry(currentDriver, record[key]);
          }
          return accumulator;
        },
        {
          totals: {
            drivingSeconds: 0,
            harshAccelerationSeconds: 0,
            harshBrakingSeconds: 0,
            harshCorneringSeconds: 0,
            speedInfractions: 0,
            speedInfractionDurationSeconds: 0,
            lowestScore: 100,
            highestScore: 0,
          },
          drivers: {},
          trend: [],
        }
      );

      data[key].totals.averageScore = calculateScore(data[key].totals);

      data[key].trend = Object.keys(data[key].trend)
        .sort()
        .map((dateKey) => {
          const {
            label,
            date,
            highestScore,
            lowestScore,
            drivingSeconds,
            harshAccelerationSeconds,
            harshBrakingSeconds,
            harshCorneringSeconds,
            speedInfractionDurationSeconds,
          } = data[key].trend[dateKey];

          return {
            date,
            label,
            Lowest: lowestScore,
            Highest: highestScore,
            Average: calculateScore({
              drivingSeconds,
              harshAccelerationSeconds,
              harshBrakingSeconds,
              harshCorneringSeconds,
              speedInfractionDurationSeconds,
            }),
          };
        });

      Object.values(data[key].drivers).forEach((driver) => {
        driver.averageScore = calculateScore(driver);
      });
    }
  });

  return data;
}

const accelFields = {
  trips: true,
  tripsDurationSeconds: true,
  tripsDistanceKilometres: true,

  tripsWithEmergencyEquipment: true,
  tripsWithEmergencyEquipmentDurationSeconds: true,
  tripsWithEmergencyEquipmentDistanceKilometres: true,

  speedInfractions: true,
  speedInfractionsDurationSeconds: true,

  speedInfractionsWithEmergencyEquipment: true,
  speedInfractionsWithEmergencyEquipmentDurationSeconds: true,

  idlingDurationSeconds: true,
  idlingWithEmergencyEquipmentDurationSeconds: true,
};

const renamedAccelFields = {
  trips: '$accelTrips',
  tripsDurationSeconds: '$accelTripsDurationSeconds',
  tripsDistanceKilometres: '$accelTripsDistanceKilometres',

  tripsWithEmergencyEquipment: '$accelTripsWithEmergencyEquipment',
  tripsWithEmergencyEquipmentDurationSeconds:
    '$accelTripsWithEmergencyEquipmentDurationSeconds',
  tripsWithEmergencyEquipmentDistanceKilometres:
    '$accelTripsWithEmergencyEquipmentDistanceKilometres',

  speedInfractions: '$accelSpeedInfractions',
  speedInfractionsDurationSeconds: '$accelSpeedInfractionsDurationSeconds',

  speedInfractionsWithEmergencyEquipment:
    '$accelSpeedInfractionsWithEmergencyEquipment',
  speedInfractionsWithEmergencyEquipmentDurationSeconds:
    '$accelSpeedInfractionsWithEmergencyEquipmentDurationSeconds',

  idlingDurationSeconds: '$accelIdlingDurationSeconds',
  idlingWithEmergencyEquipmentDurationSeconds:
    '$accelIdlingWithEmergencyEquipmentDurationSeconds',
};

async function fetchAggregatedDrivingScores(
  startTime,
  endTime,
  filter,
  timeAggregation = null,
  driverCode = null,
  tripsOnly = false
) {
  timeAggregation = timeAggregation?.toLowerCase();

  const timeAggregationUrls = {
    days: 'personDailySummaries',
    months: 'personMonthlySummaries',
    years: 'personYearlySummaries',
  };

  const startOfAggregationFunctions = {
    days: startOfDay,
    months: startOfMonth,
    years: startOfYear,
  };

  let url, startOfFunction, fields;
  if (timeAggregationUrls[timeAggregation]) {
    url = timeAggregationUrls[timeAggregation];
    startOfFunction = startOfAggregationFunctions[timeAggregation];
    fields = renamedAccelFields;
  } else {
    url = 'vehicleTripAccelerationSummaries';
    startOfFunction = startOfDay;
    fields = accelFields;
  }

  startTime = startOfFunction(startTime);
  endTime = startOfFunction(endTime);

  const response = await api.get(`/${url}`, {
    params: {
      query: tripsOnly
        ? {
            'driver.code': driverCode ?? null,
            startTime: { $lt: endTime },
            endTime: { $gte: startTime },
          }
        : {
            time: { $gte: startTime, $lt: endTime },
          },
      projection: {
        identifier: true,
        time: true,
        startTime: true,
        endTime: true,
        fleetNumber: '$vehicle.fleetNumber',
        registrationNumber: '$vehicle.registrationNumber',
        identificationNumber: '$vehicle.identificationNumber',
        person: true,
        driver: true,
        // loggedInSeconds: true,
        // inBaseSeconds: true,
        // attendingObjectiveSeconds: true,
        // inHomeWardSeconds: true,
        // doubleCrewingSeconds: true,
        // baseVisits: true,

        // respondingToIncidentSeconds: true,

        ...fields,

        excessAccelerationDurationSeconds: true,
        excessBrakingDurationSeconds: true,
        excessCorneringDurationSeconds: true,

        excessAccelerationWithEmergencyEquipmentDurationSeconds: true,
        excessBrakingWithEmergencyEquipmentDurationSeconds: true,
        excessCorneringWithEmergencyEquipmentDurationSeconds: true,
      },
    },
    headers: getHeaders(),
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
  });

  const MILES_PER_KM = 0.62137119;
  const data = response.data.map((record) => {
    record.person = record.person ?? record.driver;
    record.person.areas = reduceAreas(record.person.areas);

    const { forenames, surname } = record.person;
    const hasName = !!forenames || !!surname;
    const name = hasName
      ? `${forenames} ${surname}`
      : record.person.identificationReference ?? 'Unknown';

    // api has WithEmergencyEquipment separate, let's put them in objects
    // that have similar keys so don't have too much repetition down the line
    const statsWithoutEmergencyEquipment = {
      trips: record.trips,
      tripsDurationSeconds: record.tripsDurationSeconds,
      tripsDistanceMileage: record.tripsDistanceKilometres * MILES_PER_KM,
      speedInfractions: record.speedInfractions,
      speedInfractionDurationSeconds: record.speedInfractionsDurationSeconds,
      excessAccelerationDurationSeconds:
        record.excessAccelerationDurationSeconds,
      excessBrakingDurationSeconds: record.excessBrakingDurationSeconds,
      excessCorneringDurationSeconds: record.excessCorneringDurationSeconds,
    };

    const statsWithEmergencyEquipment = {
      trips: record.tripsWithEmergencyEquipment,
      tripsDurationSeconds: record.tripsWithEmergencyEquipmentDurationSeconds,
      tripsDistanceMileage:
        record.tripsWithEmergencyEquipmentDistanceKilometres * MILES_PER_KM,
      speedInfractions: record.speedInfractionsWithEmergencyEquipment,
      speedInfractionDurationSeconds:
        record.speedInfractionsWithEmergencyEquipmentDurationSeconds,
      excessAccelerationDurationSeconds:
        record.excessAccelerationWithEmergencyEquipmentDurationSeconds,
      excessBrakingDurationSeconds:
        record.excessBrakingWithEmergencyEquipmentDurationSeconds,
      excessCorneringDurationSeconds:
        record.excessCorneringWithEmergencyEquipmentDurationSeconds,
    };

    const statsAll = {
      trips: record.trips + record.tripsWithEmergencyEquipment,
      tripsDurationSeconds:
        record.tripsDurationSeconds +
        record.tripsWithEmergencyEquipmentDurationSeconds,
      tripsDistanceMileage:
        (record.tripsDistanceKilometres +
          record.tripsWithEmergencyEquipmentDistanceKilometres) *
        MILES_PER_KM,
      speedInfractions:
        record.speedInfractions + record.speedInfractionsWithEmergencyEquipment,
      speedInfractionDurationSeconds:
        record.speedInfractionsDurationSeconds +
        record.speedInfractionsWithEmergencyEquipmentDurationSeconds,
      excessAccelerationDurationSeconds:
        record.excessAccelerationDurationSeconds +
        record.excessAccelerationWithEmergencyEquipmentDurationSeconds,
      excessBrakingDurationSeconds:
        record.excessBrakingDurationSeconds +
        record.excessBrakingWithEmergencyEquipmentDurationSeconds,
      excessCorneringDurationSeconds:
        record.excessCorneringDurationSeconds +
        record.excessCorneringWithEmergencyEquipmentDurationSeconds,
    };

    // calculate scores for each
    [
      statsAll,
      statsWithEmergencyEquipment,
      statsWithoutEmergencyEquipment,
    ].forEach((stats) => (stats.score = calculateScore(stats)));

    return {
      code: record.person.code,
      name,
      collarNumber: record.person?.collarNumber || 'Unknown',
      role: record.person?.role || 'Unknown',
      areas: record.person.areas,
      time: record.time,
      startTime: timeAggregation ? null : parseISO(record.startTime),
      endTime: timeAggregation ? null : parseISO(record.endTime),
      fleetNumber: timeAggregation ? null : record.fleetNumber,
      registrationNumber: timeAggregation ? null : record.registrationNumber,
      identificationNumber: timeAggregation
        ? null
        : record.identificationNumber,
      statsAll,
      statsWithEmergencyEquipment,
      statsWithoutEmergencyEquipment,
    };
  });

  const filteredData = data.filter((record) =>
    drivingScoresFilter(record, filter)
  );

  const results = {
    filter,
    filterValues: getDrivingScoresFilterValues(data, filter),
    data: getAggregatedDrivingScores(filteredData, timeAggregation, tripsOnly),
    unfilteredData: data,
    startTime,
    endTime,
    timeAggregation,
  };

  log('Read', 'Aggregated Driving Scores', {
    startTime: startTime,
    endTime: endTime,
  });

  return results;
}

export function fetchAggregatedDrivingScoresEpic(action$) {
  return action$.pipe(
    ofType(FETCH_AGGREGATED_DRIVING_SCORES),
    mergeMap(({ payload: { startTime, endTime, filter, timeAggregation } }) =>
      from(
        fetchAggregatedDrivingScores(
          startTime,
          endTime,
          filter,
          timeAggregation
        )
      ).pipe(
        map((payload) => ({
          type: FETCH_AGGREGATED_DRIVING_SCORES_SUCCESS,
          payload,
        })),
        takeUntil(
          action$.pipe(
            ofType(FETCH_AGGREGATED_DRIVING_SCORES_CANCELLED),
            tap(() => cancel('cancelled'))
          )
        ),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_AGGREGATED_DRIVING_SCORES_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function loadAggregatedDrivingScoresRequest({
  unfilteredData = [],
  startTime,
  endTime,
  timeAggregation,
  filter,
}) {
  const filteredData = unfilteredData.filter((record) =>
    drivingScoresFilter(record, filter)
  );

  const results = {
    filter,
    filterValues: getDrivingScoresFilterValues(unfilteredData, filter),
    data: getAggregatedDrivingScores(filteredData, timeAggregation),
    unfilteredData,
    startTime,
    endTime,
    timeAggregation,
  };

  log('Load', 'Aggregated Driving Scores', {
    startTime: startTime,
    endTime: endTime,
  });

  return results;
}

export function loadAggregatedDrivingScoresEpic(action$, state$) {
  return action$.pipe(
    ofType(LOAD_AGGREGATED_DRIVING_SCORES),
    mergeMap(({ payload: filter }) =>
      from(
        loadAggregatedDrivingScoresRequest({
          ...state$.value.reports.aggregatedDrivingScores,
          filter,
        })
      ).pipe(
        map((payload) => ({
          type: LOAD_AGGREGATED_DRIVING_SCORES_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: LOAD_AGGREGATED_DRIVING_SCORES_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

export function fetchDrivingScoresEpic(action$) {
  return action$.pipe(
    ofType(FETCH_DRIVING_SCORES),
    mergeMap(
      (
        {
          payload: {
            startTime,
            endTime, //collarNumber, //emergencyEquipmentUsed,
            filter,
            driverCode,
            timeAggregation,
            tripsOnly = true,
          },
        } //addDayToEndTime,
      ) =>
        from(
          fetchAggregatedDrivingScores(
            startTime,
            endTime,
            filter,
            timeAggregation,
            driverCode,
            tripsOnly
          )
        ).pipe(
          map((payload) => ({
            type: FETCH_DRIVING_SCORES_SUCCESS,
            payload,
          })),
          takeUntil(
            action$.pipe(
              ofType(FETCH_DRIVING_SCORES_CANCELLED),
              tap(() => cancel('cancelled'))
            )
          ),
          catchError(({ message: payload }) =>
            of({
              type: FETCH_DRIVING_SCORES_FAILURE,
              payload,
            })
          )
        )
    )
  );
}

export function fetchDriverTripsEpic(action$) {
  return action$.pipe(
    ofType(FETCH_DRIVER_TRIPS_BY_DRIVER_CODE),
    mergeMap(({ payload: { driverCode, startTime, endTime, excludeExempt } }) =>
      forkJoin({
        infractions: fromAjax('/speedInfractions', {
          params: {
            query: {
              startTime: { $lt: endTime },
              endTime: { $gte: startTime },
              'driver.code': driverCode ?? null,
              durationSeconds: { $gte: minimumSpeedInfractionSeconds || 45 },
            },
            projection: {
              identifier: true,
              durationSeconds: true,
              distanceKilometres: true,
              parentEvent: true,
              equipmentActivations: true,
            },
          },
          headers: getHeaders(),
        }),
        trips: fromAjax('/trips', {
          params: {
            query: {
              startTime: { $lt: endTime },
              endTime: { $gte: startTime },
              'driver.code': driverCode ?? null,
              ...(excludeExempt
                ? { classification: { $nin: exemptTripClassifications } }
                : {}),
            },
            projection: {
              identifier: true,
              startTime: true,
              endTime: true,
              classification: true,
              distanceKilometres: true,
              driver: true,
              fleetNumber: '$vehicle.fleetNumber',
              registrationNumber: '$vehicle.registrationNumber',
              identificationNumber: '$vehicle.identificationNumber',
              'vehicle.telematicsBoxImei': true,
              durationSeconds: true,
              drivingSeconds: '$durationSeconds',
              equipmentActivations: true,
            },
          },
          headers: getHeaders(),
        }),
      }).pipe(
        map(
          ({
            infractions: { response: infractionsResponse },
            trips: { response: tripResponse },
          }) => {
            const groupedSpeedInfractions = _.groupBy(
              infractionsResponse,
              'parentEvent'
            );

            const trips = (tripResponse || []).map((trip) => {
              const emergencyEquipmentUsed =
                trip.equipmentActivations.emergencyOn;

              const infractions =
                groupedSpeedInfractions[trip.identifier] || [];
              let speeding = {
                speedInfractions: infractions.length,
                speedInfractionsDurationSeconds: 0,
                speedInfractionsWithEmergencyEquipment: 0,
                speedInfractionsWithEmergencyEquipmentDurationSeconds: 0,
              };

              infractions.forEach((infraction) => {
                speeding.speedInfractionsDurationSeconds +=
                  infraction.durationSeconds;

                if (speeding.equipmentActivations?.emergencyEquipmentOn) {
                  speeding.speedInfractionsWithEmergencyEquipment += 1;
                  speeding.speedInfractionsWithEmergencyEquipmentDurationSeconds +=
                    infraction.durationSeconds;
                }
              });

              return {
                ...trip,
                emergencyEquipmentUsed,
                ...speeding,
                mileage: trip.distanceKilometres * 0.62137119,
              };
            });

            log('Read', 'Driver Trips', { driverCode, startTime, endTime });

            return {
              type: FETCH_DRIVER_TRIPS_BY_DRIVER_CODE_SUCCESS,
              payload: { driverCode, trips },
            };
          }
        ),

        // from(
        //   fetchDriverTripsByDriverCode(
        //     driverCode,
        //     startTime,
        //     endTime,
        //     excludeExempt
        //   )
        // ).pipe(
        //   map((payload) => ({
        //     type: FETCH_DRIVER_TRIPS_BY_DRIVER_CODE_SUCCESS,
        //     payload,
        //   })),
        takeUntil(
          action$.pipe(
            ofType(FETCH_DRIVER_TRIPS_BY_DRIVER_CODE_CANCELLED),
            tap((ev) => cancel('cancelled'))
          )
        ),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_DRIVER_TRIPS_BY_DRIVER_CODE_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

function vehiclesInLocationFilter(record, filter) {
  if (
    filter.registrationNumber.length !== 0 &&
    !filter.registrationNumber.includes(record.registrationNumber)
  ) {
    return false;
  }

  if (
    filter.fleetNumber.length !== 0 &&
    !filter.fleetNumber.includes(record.fleetNumber)
  ) {
    return false;
  }

  if (filter.role.length !== 0 && !filter.role.includes(record.role)) {
    return false;
  }

  if (filter.type.length !== 0 && !filter.type.includes(record.type)) {
    return false;
  }

  if (
    filter.locationName.length !== 0 &&
    !filter.locationName.includes(record.locationName)
  ) {
    return false;
  }

  if (
    filter.locationType.length !== 0 &&
    !filter.locationType.includes(record.locationType)
  ) {
    return false;
  }

  return areasFilter(record, filter);
}

function getVehiclesInLocationFilterValues(data, filter) {
  return filterValuesFromData(data, filter, vehiclesInLocationFilter);
}

function getVehiclesInLocations(data, startTime, endTime) {
  if (!startTime || !endTime || (data || []).length === 0) {
    return [];
  }

  const locationCountChanges = data.reduce((accumulator, record) => {
    if (!(record.locationName in accumulator)) {
      accumulator[record.locationName] = {};
    }

    // quick way to change the Date() to milliseconds timestamp, this
    // is necessary so it can be used as a key, otherwise using Date()
    // as a key will be converted to a string & won't work for sorting
    // i.e. "Friday ... " < x && x < "Tuesday ... "
    const timeKey = +record.time;
    const minuteKey = timeKey - (timeKey % 60000);

    if (!(minuteKey in accumulator[record.locationName])) {
      accumulator[record.locationName][minuteKey] = {
        residentVehicles: 0,
        visitorVehicles: 0,
      };
    }

    accumulator[record.locationName][minuteKey][
      record.atHome ? 'residentVehicles' : 'visitorVehicles'
    ] += record.change;

    return accumulator;
  }, {});

  const startEpoch = +startTime; // shorthand to change Date() to epoch time
  const endEpoch = +endTime;
  const locationTimelines = Object.entries(locationCountChanges).map(
    (record) => {
      const entries = Object.entries(record[1]);

      let residentTally = 0;
      let visitorTally = 0;
      let locationTally = [];

      entries.forEach(([time, { residentVehicles, visitorVehicles }]) => {
        residentTally += residentVehicles;
        visitorTally += visitorVehicles;

        locationTally.push({
          time, //: new Date(date).getTime(),
          residentCount: residentTally,
          visitorCount: visitorTally,
        });
      });

      const values = _.sortBy(locationTally, ['time']).filter(
        ({ time }) => startEpoch <= time && time < endEpoch
      );

      return {
        name: record[0],
        values,
      };
    }
  );

  return locationTimelines;
}

async function fetchVehiclesInLocationsRequest(query, filter) {
  const reportName = 'vehiclesInLocations';
  const parameters = await db.parameters.get(reportName);
  let data;

  if (_.isEqual(parameters?.query, query)) {
    data = await fetchCachedData(reportName);
  } else {
    const response = await api.get('/intersections', {
      params: {
        query,
        projection: {
          identifier: true,
          vehicle: true,
          startTime: true,
          endTime: true,
          durationSeconds: true,
          location: true,
        },
      },
      headers: getHeaders(),
      cancelToken: new CancelToken((c) => {
        cancel = c;
      }),
    });

    // + is shorthand to change it to epoch time (int seconds)
    const start = +parseISO(query.endTime.$gte);
    const end = +parseISO(query.startTime.$lte);

    const mapped = response.data
      .map(
        ({
          startTime: eventStartTime,
          endTime: eventEndTime,
          vehicle: {
            registrationNumber,
            fleetNumber,
            role,
            type,
            areas,
            homeStation,
          },
          location: {
            name: locationName,
            type: locationType,
            code: locationCode,
          },
        }) => {
          const reducedAreas = reduceAreas(areas);
          const atHome =
            homeStation === locationName || homeStation === locationCode;

          return [
            {
              registrationNumber,
              fleetNumber,
              role,
              type,
              areas: reducedAreas,
              locationName,
              locationType,
              time: start,
              change: 0,
              atHome,
            },
            {
              registrationNumber,
              fleetNumber,
              role,
              type,
              areas: reducedAreas,
              locationName,
              locationType,
              time: +parseISO(eventStartTime),
              change: 1,
              atHome,
            },
            {
              registrationNumber,
              fleetNumber,
              role,
              type,
              areas: reducedAreas,
              locationName,
              locationType,
              time: +parseISO(eventEndTime),
              change: -1,
              atHome,
            },
            {
              registrationNumber,
              fleetNumber,
              role,
              type,
              areas: reducedAreas,
              locationName,
              locationType,
              time: end,
              change: 0,
              atHome,
            },
          ];
        }
      )
      .flatMap((x) => x);

    data = _.sortBy(mapped, ['time']);

    await db.vehiclesInLocations.clear();
    await db.vehiclesInLocations.add(data);
    await db.parameters.put({
      store: 'vehiclesInLocations',
      query,
    });
  }

  const filteredData = data.filter((record) =>
    vehiclesInLocationFilter(record, filter)
  );

  const results = {
    filter,
    filterValues: getVehiclesInLocationFilterValues(data, filter),
    data: getVehiclesInLocations(
      filteredData,
      query.endTime.$gte,
      query.startTime.$lte
    ),
  };

  log('Read', 'Vehicle In Locations', query);

  return results;
}

export function fetchVehiclesInLocationsEpic(action$) {
  return action$.pipe(
    ofType(FETCH_VEHICLES_IN_LOCATIONS),
    mergeMap(({ payload: { query, filter } }) =>
      from(fetchVehiclesInLocationsRequest(query, filter)).pipe(
        map((payload) => ({
          type: FETCH_VEHICLES_IN_LOCATIONS_SUCCESS,
          payload,
        })),
        takeUntil(
          action$.pipe(
            ofType(FETCH_VEHICLES_IN_LOCATIONS_CANCELLED),
            tap((ev) => cancel('cancelled'))
          )
        ),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_VEHICLES_IN_LOCATIONS_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function loadVehiclesInLocationsRequest(filter) {
  const reportName = 'vehiclesInLocations';
  const data = await fetchCachedData(reportName);
  const parameters = await db.parameters.get(reportName);

  const filteredData = data.filter((record) =>
    vehiclesInLocationFilter(record, filter)
  );

  const results = {
    filter,
    filterValues: getVehiclesInLocationFilterValues(data, filter),
    data: getVehiclesInLocations(
      filteredData,
      parameters ? new Date(parameters.query.endTime.$gte) : null,
      parameters ? new Date(parameters.query.startTime.$lte) : null
    ),
  };

  log('Load', 'Vehicle In Locations', parameters);

  return results;
}

export function loadVehiclesInLocationsEpic(action$) {
  return action$.pipe(
    ofType(LOAD_VEHICLES_IN_LOCATIONS),
    mergeMap(({ payload: filter }) =>
      from(loadVehiclesInLocationsRequest(filter)).pipe(
        map((payload) => ({
          type: LOAD_VEHICLES_IN_LOCATIONS_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: LOAD_VEHICLES_IN_LOCATIONS_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function fetchVehicleOdometers(date) {
  const response = await api.get('/vehicleOdometers', {
    params: {
      time: date,
    },
    headers: getHeaders(),
  });

  const readings = (response.data || []).map(
    ({
      latestPoll,
      lastReadingPoll,
      lastOdometerReading,
      calculatedOdometerReading,
      ...reading
    }) => {
      return {
        ...reading,
        readingTime: lastOdometerReading
          ? new Date(lastOdometerReading.time)
          : null,
        latestPollTime: latestPoll ? new Date(latestPoll.time) : null,
        pollAfterGapHours: lastReadingPoll
          ? _.round(lastReadingPoll.odometerReadingDifferenceSeconds / 3600, 2)
          : null,
        pollAfterReadingTime: lastReadingPoll
          ? new Date(lastReadingPoll.time)
          : null,
        readingMiles: lastOdometerReading
          ? _.round(lastOdometerReading.distanceKilometres * 0.62137119, 2)
          : null,
        pollAfterReadingMiles: lastReadingPoll
          ? _.round(lastReadingPoll.distanceKilometres * 0.62137119, 2)
          : null,
        latestPollMiles: latestPoll
          ? _.round(latestPoll.distanceKilometres * 0.62137119, 2)
          : null,
        calculatedMiles: calculatedOdometerReading
          ? _.round(
              calculatedOdometerReading.distanceKilometres * 0.62137119,
              2
            )
          : null,
      };
    }
  );

  log('Read', 'Vehicle Mileage', {
    date,
  });

  return readings;
}

export function fetchVehicleOdometersEpic(action$) {
  return action$.pipe(
    ofType(FETCH_VEHICLE_ODOMETERS),
    mergeMap(({ payload: date }) =>
      from(fetchVehicleOdometers(date)).pipe(
        map((payload) => ({
          type: FETCH_VEHICLE_ODOMETERS_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_VEHICLE_ODOMETERS_FAILURE,
            payload,
          })
        )
      )
    )
  );
}
