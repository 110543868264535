import { Paper, IconButton, Toolbar, Typography, Box } from '@mui/material';
import { GetApp as GetAppIcon } from '@mui/icons-material';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { useSnackbar } from '../../Snackbar';
import {
  FETCH_ACCELEROMETER_EVENTS,
  FETCH_ACCELEROMETER_EVENTS_CANCELLED,
  UPDATE_ACCELEROMETER_EVENTS_FILTER,
  UPDATE_EVENTS_QUERY,
} from '../../../actions';
import Container from '../../Container';
import {
  FilterPicker,
  Parameters,
  TablePagination,
  Table,
} from '../../controls';
import { downloadCSV } from '../../../apis/utilities';
import AccelerometerChart from './AccelerometerChart';
import EventPreviewMap from '../../controls/EventPreviewMap';
import { filterLocally } from '../../../data/utilities';

const {
  rowsPerPageOptions,
  events: {
    eventFilters: { accelerometerEvents: eventFilters = [] } = {},
  } = {},
} = window.config;
const { extraAccelerometerEventHeaders } = window.reportHeaders;

const headers = [
  ...extraAccelerometerEventHeaders,
  { label: 'Time', key: 'time', type: 'date', filter: false },
  { label: 'Has Data', key: 'hasData', type: 'text', filter: true },
  {
    label: 'Maximum Horizontal Force (g)',
    key: 'maxHorizontalForce',
    type: 'number',
    filter: false,
  },
  {
    label: 'Maximum Vertical Force (g)',
    key: 'maxVerticalForce',
    type: 'number',
    filter: false,
  },
  {
    label: 'Maximum Lateral Force (g)',
    key: 'maxLateralForce',
    type: 'number',
    filter: false,
  },
];

export function DownloadPollsLink({ entry }) {
  const handleDownloadDataClick = (sourceData) => () => {
    const filename = 'Accelerometer Event Data.csv';
    const data = (sourceData || []).map(
      ({ position: { coordinates }, headingDegrees, time, ...event }) => ({
        ...event,
        time: moment(time).format('YYYY-MM-DD HH:mm:ss.S'),
        headingDegrees: moment(time).millisecond() === 0 ? headingDegrees : '',
        longitude: moment(time).millisecond() === 0 ? coordinates[0] : '',
        latitude: moment(time).millisecond() === 0 ? coordinates[1] : '',
      })
    );

    downloadCSV(data, filename, eventHeaders);
  };

  return (
    <IconButton
      title="Download accelerometer data"
      disabled={entry.hasData === 'No'}
      onClick={handleDownloadDataClick(entry.data)}
      size="large"
    >
      <GetAppIcon />
    </IconButton>
  );
}

const eventHeaders = [
  { label: 'Longitude', key: 'longitude' },
  { label: 'Latitude', key: 'latitude' },
  { label: 'Heading (degrees)', key: 'headingDegrees' },
  { label: 'Speed (mph)', key: 'speedMilesPerHour' },
  { label: 'Time', key: 'time' },
  { label: 'Horizontal Force (g)', key: 'horizontalForce' },
  { label: 'Vertical Force (g)', key: 'verticalForce' },
  { label: 'Lateral Force (g)', key: 'lateralForce' },
];

function Event({ entry }) {
  const [hoveredChartPoint, setHoveredChartPoint] = useState(null);

  return (
    <Box sx={{ display: 'flex', px: 2, pb: 2 }}>
      <Box sx={{ width: 320, height: 320 }}>
        {entry.point && (
          <EventPreviewMap
            path={entry.path}
            point={entry.point}
            hoveredChartPoint={hoveredChartPoint}
            mapType="accelerometerEvents"
          />
        )}
      </Box>
      <Box sx={{ flex: 1, minWidth: 320, height: 320 }}>
        {entry.data ? (
          <AccelerometerChart
            data={entry.data}
            triggerPoint={entry.triggerPoint}
            onChartHover={setHoveredChartPoint}
          />
        ) : (
          <Box
            sx={{
              height: 320,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box>
              <Typography variant="caption" sx={{ color: 'text.disabled' }}>
                No data
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default function AccelerometerEvents() {
  const dispatch = useDispatch();
  const accelerometerEvents = useSelector(
    (state) => state.events.accelerometerEvents.list,
    _.isEqual
  );
  const filter = useSelector(
    (state) => state.events.accelerometerEvents.filter,
    _.isEqual
  );
  const query = useSelector(
    (state) => state.events.accelerometerEvents.query,
    _.isEqual
  );
  const isLoading = useSelector(
    (state) => state.events.accelerometerEvents.isLoading
  );
  const error = useSelector((state) => state.events.accelerometerEvents.error);
  const snackbar = useSnackbar();

  useEffect(() => {
    if (error) {
      snackbar.notify('error', error);
    }
  }, [error, snackbar]);

  function handleFetch(event, query) {
    dispatch({
      type: FETCH_ACCELEROMETER_EVENTS,
      payload: query,
    });
  }

  function handleCancel() {
    dispatch({
      type: FETCH_ACCELEROMETER_EVENTS_CANCELLED,
    });
  }

  function updateFilter(update) {
    onFilterChange({
      ...filter,
      ...update,
    });
  }

  function onFilterChange(payload) {
    dispatch({
      type: UPDATE_ACCELEROMETER_EVENTS_FILTER,
      payload,
    });
  }

  function handlePageChange(event, page) {
    updateFilter({ page });
  }

  function handleRowsPerPageChange(event) {
    updateFilter({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  }

  function handleOrderChange(order) {
    updateFilter({ order });
  }

  function handleOrderByChange(orderBy) {
    updateFilter({
      orderBy,
      order: 'asc',
    });
  }

  function handleDownloadClick() {
    const filename = 'Accelerometer Events.csv';
    const data = filteredAccelerometerEvents.map(
      ({ time, point, ...accelerometerEvent }) => ({
        ...accelerometerEvent,
        time: moment(time).format('YYYY-MM-DD HH:mm:ss'),
        latitude: point ? point.coordinates[1] : '',
        longitude: point ? point.coordinates[0] : '',
      })
    );

    downloadCSV(data, filename, [
      ...headers,
      ...[
        { label: 'Longitude', key: 'longitude', type: 'number' },
        { label: 'Latitude', key: 'latitude', type: 'number' },
      ],
    ]);
  }

  const handleQueryChange = (query) => {
    dispatch({
      type: UPDATE_EVENTS_QUERY,
      payload: { eventType: 'accelerometerEvents', query },
    });
  };

  const filteredAccelerometerEvents = filterLocally(
    filter,
    accelerometerEvents
  );

  return (
    <Container title="Accelerometer Events">
      <Parameters
        onFetch={handleFetch}
        onCancel={handleCancel}
        isFetching={isLoading}
        value={query}
        onChange={handleQueryChange}
        sx={{ mt: 1, width: 264 }}
        vehicle
        driver
        pointEvent
        eventFilters={eventFilters}
      />
      <Box
        sx={{
          flex: 1,
          height: 'calc(100vh - 48px)',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <Helmet>
          <title>IR3 | Accelerometer Events</title>
        </Helmet>
        <Toolbar variant="dense" disableGutters sx={{ p: 1, pb: 0 }}>
          <Typography sx={{ flexGrow: 1 }} variant="subtitle1">
            Accelerometer Events
          </Typography>
          <FilterPicker
            headers={headers}
            data={accelerometerEvents}
            filter={filter}
            onFilterChange={onFilterChange}
          />
          <IconButton
            title="Download data"
            disabled={filteredAccelerometerEvents.length === 0}
            onClick={handleDownloadClick}
            size="large"
          >
            <GetAppIcon />
          </IconButton>
        </Toolbar>
        <Paper sx={{ m: [0, 1, 1], minWidth: 240 }}>
          <Table
            styles={{
              tableContainer: {
                height: 'calc(100vh - 172px)',
                overflowY: 'scroll',
              },
              table: {
                minWidth: 750,
              },
            }}
            data={filteredAccelerometerEvents}
            headers={[
              {
                label: '',
                key: 'expand',
                type: 'expand',
                component: Event,
              },
              ...headers,
              {
                label: '',
                key: 'polls',
                type: 'component',
                component: DownloadPollsLink,
              },
            ]}
            rowsPerPage={filter.rowsPerPage}
            page={filter.page}
            keyName="identifier"
            order={filter.order}
            orderBy={filter.orderBy}
            onOrderChange={handleOrderChange}
            onOrderByChange={handleOrderByChange}
          />
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={filteredAccelerometerEvents.length}
            rowsPerPage={filter.rowsPerPage}
            page={filter.page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </Paper>
      </Box>
    </Container>
  );
}
