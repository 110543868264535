import {
  Box,
  IconButton,
  Paper,
  Popover,
  Toolbar,
  Typography,
} from '@mui/material';
import { GetApp as GetAppIcon } from '@mui/icons-material';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import {
  FETCH_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT,
  FETCH_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT_CANCELLED,
  UPDATE_EVENTS_QUERY,
  UPDATE_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT_FILTER,
} from '../../actions';
import { downloadCSV } from '../../apis/utilities';
import Container from '../Container';
import { FilterPicker, Parameters, Table, TablePagination } from '../controls';
import { useSnackbar } from '../Snackbar';
import { filterLocally } from '../../data/utilities';

const { rowsPerPageOptions } = window.config;

const { extraMILHeaders } = window.reportHeaders;

const headers = [
  {
    label: '',
    key: 'expand',
    type: 'expand',
    component: Diagnostics,
    filter: false,
  },
  ...extraMILHeaders,
  { label: 'Start Time', key: 'startTime', type: 'date' },
  { label: 'End Time', key: 'endTime', type: 'date' },
  {
    label: 'Activation Time',
    key: 'malfunctionIndicatorLightOnTime',
    type: 'date',
  },
];

const diagnosticHeaders = [
  {
    label: 'On-Board Diagnostics Time',
    key: 'time',
    type: 'date',
  },
  {
    label: 'Area',
    key: 'area',
    type: 'text',
  },
  {
    label: 'Class',
    key: 'class',
    type: 'text',
  },
  {
    label: 'Code',
    key: 'code',
    type: 'text',
  },
  {
    label: 'Confirmed',
    key: 'isConfirmed',
    type: 'text',
  },
];

function Diagnostics({ entry }) {
  if (entry.diagnostics.length > 0) {
    return (
      <Table
        data={entry.diagnostics}
        headers={diagnosticHeaders}
        rowsPerPage={entry.diagnostics.length}
        page={0}
        footer={[]}
        keyName="identifier"
      />
    );
  }
  return null;
}

export default function MalfunctionIndicatorLight() {
  const dispatch = useDispatch();
  const trips = useSelector(
    (state) => state.events.malfunctionIndicatorLight.list,
    _.isEqual
  );
  const isLoading = useSelector(
    (state) => state.events.malfunctionIndicatorLight.isLoading
  );
  const error = useSelector(
    (state) => state.events.malfunctionIndicatorLight.error
  );
  const filter = useSelector(
    (state) => state.events.malfunctionIndicatorLight.filter,
    _.isEqual
  );
  const query = useSelector(
    (state) => state.events.malfunctionIndicatorLight.query,
    _.isEqual
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const snackbar = useSnackbar();

  useEffect(() => {
    if (error) {
      snackbar.notify('error', error);
    }
  }, [error, snackbar]);

  function handleFetch(event, query) {
    dispatch({
      type: FETCH_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT,
      payload: query,
    });
  }

  function handleCancel() {
    dispatch({
      type: FETCH_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT_CANCELLED,
    });
  }

  function handleQueryChange(query) {
    dispatch({
      type: UPDATE_EVENTS_QUERY,
      payload: {
        eventType: 'malfunctionIndicatorLight',
        query,
      },
    });
  }

  function handleFilterClose() {
    setAnchorEl(null);
  }

  function updateFilter(update) {
    onFilterChange({
      ...filter,
      ...update,
    });
  }

  function onFilterChange(payload) {
    dispatch({
      type: UPDATE_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT_FILTER,
      payload,
    });
  }

  function handlePageChange(event, page) {
    updateFilter({ page });
  }

  function handleRowsPerPageChange(event) {
    updateFilter({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  }

  function handleOrderChange(order) {
    updateFilter({ order });
  }

  function handleOrderByChange(orderBy) {
    updateFilter({
      orderBy,
      order: 'asc',
    });
  }

  function handleDownloadClick() {
    const filename = 'Malfunction Indicator Light.csv';
    const data = filteredTrips.map((trip) => ({
      ...trip,
    }));

    downloadCSV(data, filename, headers);
  }

  const filteredTrips = filterLocally(filter, trips);

  return (
    <Container title="Malfunction Indicator Light">
      <Parameters
        onFetch={handleFetch}
        onCancel={handleCancel}
        isFetching={isLoading}
        value={query}
        onChange={handleQueryChange}
        sx={{ mt: 1, width: 264 }}
        vehicle
        pointEvent
      />
      <Box
        sx={{
          flex: 1,
          height: 'calc(100vh - 48px)',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <Helmet>
          <title>IR3 | Malfunction Indicator Light</title>
        </Helmet>
        <Toolbar variant="dense" disableGutters sx={{ p: 1, pb: 0 }}>
          <Typography sx={{ flexGrow: 1 }} variant="subtitle1">
            Malfunction Indicator Light
          </Typography>
          <FilterPicker
            headers={headers}
            data={trips}
            filter={filter}
            onFilterChange={onFilterChange}
          />
          <IconButton
            disabled={filteredTrips.length === 0}
            title="Download"
            onClick={handleDownloadClick}
            size="large"
          >
            <GetAppIcon />
          </IconButton>
          <Popover
            id="filter-popover"
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleFilterClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          ></Popover>
        </Toolbar>
        <Paper sx={{ m: [0, 1, 1], minWidth: 240 }}>
          <Table
            styles={{
              tableContainer: {
                height: 'calc(100vh - 172px)',
                overflowY: 'scroll',
              },
              table: {
                minWidth: 750,
              },
            }}
            data={filteredTrips}
            headers={headers}
            rowsPerPage={filter.rowsPerPage}
            page={filter.page}
            order={filter.order}
            orderBy={filter.orderBy}
            onOrderChange={handleOrderChange}
            onOrderByChange={handleOrderByChange}
          />
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={filteredTrips.length}
            rowsPerPage={filter.rowsPerPage}
            page={filter.page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </Paper>
      </Box>
    </Container>
  );
}
