import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Badge,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import {
  Add as AddIcon,
  Close as CloseIcon,
  Done as DoneIcon,
  PlayArrow as PlayArrowIcon,
  PinDrop as PinDropIcon,
} from '@mui/icons-material';
import { Helmet } from 'react-helmet-async';
import moment from 'moment';
import { RetrospectiveTypeIcon } from '../../../data/constants';

export default function Incident({
  color,
  background,
  description,
  number,
  responseCategory,
  grade,
  openedTime,
  type,
  assignedTime,
  attendedTime,
  closedTime,
  closingCodes,
  reference,
  date,
  address,
  locations,
}) {
  const navigate = useNavigate();

  function handleViewClick() {
    navigate(`/replay/incidents/${number}`);
  }

  const details = [
    //   { label: 'Type', value: incident.type && incident.type.name },
    //   { label: 'Status', value: incident.status },
    { label: 'Address', value: address },
    { label: 'Description', value: description },
    //   { label: 'Tags', value: incident.tags.join(',') },
    //   ...areasToLabelAccessors(incident.areas)
    { label: 'Response Category', value: responseCategory?.name },
    ...(locations || []).map((location) => ({
      label: location.type,
      value: location.name,
    })),
  ];

  const ref = `${reference}, ${date.split('-').reverse().join('/')}`;
  const title = `Incident ${ref}`;
  const secondary = `${type?.code ?? ''} ${type?.name ?? ''}`;

  return (
    <Card sx={{ m: [1, 0.5, 1, 1] }}>
      <Helmet>
        <title>IR3 | Incident | {ref}</title>
      </Helmet>
      <CardHeader
        avatar={
          <Badge
            badgeContent={
              responseCategory && responseCategory.code
                ? responseCategory.code
                : grade || '?'
            }
            color="primary"
          >
            <Avatar
              title={responseCategory.name || `Grade ${grade}`}
              style={{
                background,
                color,
              }}
            >
              <RetrospectiveTypeIcon type="incidents" />
            </Avatar>
          </Badge>
        }
        title={title}
        subheader={secondary}
        action={
          <IconButton
            aria-label="replay"
            title="View Replay"
            onClick={handleViewClick}
            size="large"
          >
            <PlayArrowIcon />
          </IconButton>
        }
      />
      <CardContent>
        <Typography variant="subtitle2" color="textSecondary">
          Details
        </Typography>
        <Table size="small">
          <TableBody>
            {details.map(({ label, value }) => (
              <TableRow key={label}>
                <TableCell>
                  <Typography variant="caption" color="textSecondary">
                    {label}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="caption">{value}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Typography variant="subtitle2" color="textSecondary">
          Timeline
        </Typography>
        <Stepper orientation="vertical" sx={{ mt: 2, ml: 2 }}>
          <Step active>
            <StepLabel
              icon={
                <Avatar
                  sx={{
                    width: 30,
                    height: 30,
                    color: 'common.white',
                    bgcolor: 'error.main',
                  }}
                >
                  <AddIcon fontSize="inherit" />
                </Avatar>
              }
              optional={
                <Typography variant="caption">
                  {moment(openedTime).format('DD/MM/YYYY, HH:mm:ss')}
                </Typography>
              }
            >
              Opened
            </StepLabel>
            <StepContent>
              {type && <Typography variant="caption">{type.code}</Typography>}
            </StepContent>
          </Step>
          {assignedTime && (
            <Step active>
              <StepLabel
                icon={
                  <Avatar
                    c
                    sx={{
                      width: 30,
                      height: 30,
                      color: 'common.white',
                      backgroundColor: 'warning.main',
                    }}
                  >
                    <DoneIcon fontSize="inherit" />
                  </Avatar>
                }
                optional={
                  <Typography variant="caption">
                    {moment(assignedTime).format('DD/MM/YYYY, HH:mm:ss')}
                  </Typography>
                }
              >
                Assigned
              </StepLabel>
              <StepContent />
            </Step>
          )}
          {attendedTime && (
            <Step active>
              <StepLabel
                icon={
                  <Avatar
                    sx={{
                      width: 30,
                      height: 30,
                      color: 'common.white',
                      backgroundColor: 'success.main',
                    }}
                  >
                    <PinDropIcon fontSize="inherit" />
                  </Avatar>
                }
                optional={
                  <Typography variant="caption">
                    {moment(attendedTime).format('DD/MM/YYYY, HH:mm:ss')}
                  </Typography>
                }
              >
                Attended
              </StepLabel>
              <StepContent />
            </Step>
          )}
          {closedTime && (
            <Step active>
              <StepLabel
                icon={
                  <Avatar
                    sx={{
                      width: 30,
                      height: 30,
                      color: 'common.white',
                      backgroundColor: 'grey.500',
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </Avatar>
                }
                optional={
                  <Typography variant="caption">
                    {moment(closedTime).format('DD/MM/YYYY, HH:mm:ss')}
                  </Typography>
                }
              >
                Closed
              </StepLabel>
              <StepContent>
                {!closingCodes
                  ? ''
                  : (closingCodes || []).map((cc, index) => (
                      <Typography variant="caption" key={index}>
                        {cc.code}
                      </Typography>
                    ))}
              </StepContent>
            </Step>
          )}
        </Stepper>
      </CardContent>
    </Card>
  );
}
