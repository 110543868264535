import {
  Avatar,
  Paper,
  CardContent,
  Collapse,
  IconButton,
  Toolbar,
  Typography,
  Box,
  Stack,
} from '@mui/material';
import {
  FilterList as FilterListIcon,
  GetApp as GetAppIcon,
} from '@mui/icons-material';
import { blue, amber } from '@mui/material/colors';
import _ from 'lodash';
import moment from 'moment';
import { Fragment, useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Area,
  AreaChart,
  Brush,
  Label,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Helmet } from 'react-helmet-async';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { useSnackbar } from '../../Snackbar';
import {
  FETCH_VEHICLES_IN_LOCATIONS,
  FETCH_VEHICLES_IN_LOCATIONS_CANCELLED,
  LOAD_VEHICLES_IN_LOCATIONS,
} from '../../../actions';
import db from '../../../data/db';
import Container from '../../Container';
import { Parameters, SelectMultiple, CustomTooltip } from '../../controls';
import { downloadCSV } from '../../../apis/utilities';

const { useReducedResourceInformation } = window.config;

const headers = [
  ...(useReducedResourceInformation
    ? [
        { label: 'Fleet Number', key: 'fleetNumber' },
        { label: 'Vehicle Type', key: 'type' },
      ]
    : [
        { label: 'Registration', key: 'registrationNumber' },
        { label: 'Fleet Number', key: 'fleetNumber' },
        { label: 'Vehicle Role', key: 'role' },
      ]),
  { label: 'Location Name', key: 'locationName' },
  { label: 'Location Type', key: 'locationType' },
  { label: 'Time', key: 'time' },
  { label: 'Change', key: 'change' },
];

export default function TimelineInLocations() {
  const dispatch = useDispatch();
  const data = useSelector(
    (state) => state.reports.vehiclesInLocations.data,
    _.isEqual
  );
  const sortedData = useMemo(() => _.sortBy(data ?? [], 'time'), [data]);
  const isLoading = useSelector(
    (state) => state.reports.vehiclesInLocations.isLoading
  );
  const error = useSelector((state) => state.reports.vehiclesInLocations.error);
  const filter = useSelector(
    (state) => state.reports.vehiclesInLocations.filter,
    _.isEqual
  );
  const filterValues = useSelector(
    (state) => state.reports.vehiclesInLocations.filterValues,
    _.isEqual
  );
  const [showFilter, setShowFilter] = useState(false);
  const [hiddenAreas, setHiddenAreas] = useState([]);
  const snackbar = useSnackbar();

  useEffect(() => {
    if (error) {
      snackbar.notify('error', error);
    }
  }, [error, snackbar]);

  // useEffect(() => {
  //   dispatch({
  //     type: LOAD_VEHICLES_IN_LOCATIONS,
  //     payload: filter,
  //   });
  // }, [filter, dispatch]);

  function handleFilterToggle() {
    setShowFilter(!showFilter);
  }

  function handleFetch(event, query) {
    dispatch({
      type: FETCH_VEHICLES_IN_LOCATIONS,
      payload: { query, filter },
    });
  }

  function handleCancel() {
    dispatch({
      type: FETCH_VEHICLES_IN_LOCATIONS_CANCELLED,
    });
  }

  function handleFilterFieldChanged(name, value) {
    const newFilter =
      name in filter
        ? {
            ...filter,
            [name]: value || [],
          }
        : {
            ...filter,
            areas: {
              ...filter.areas,
              [name]: value || [],
            },
          };

    dispatch({
      type: LOAD_VEHICLES_IN_LOCATIONS,
      payload: newFilter,
    });
  }

  function handleLegendClick(selectedArea) {
    const index = hiddenAreas.indexOf(selectedArea);

    if (index === -1) {
      setHiddenAreas(hiddenAreas.concat(selectedArea));
    } else {
      setHiddenAreas(
        hiddenAreas.slice(0, index).concat(hiddenAreas.slice(index + 1))
      );
    }
  }

  function getAreaHeaders() {
    return Object.keys(filterValues.areas).map((key) => ({
      label: _.startCase(key),
      key,
    }));
  }

  function formatLabel(epochSeconds) {
    // + is shorthand to convert string to number
    return moment(new Date(+epochSeconds)).format('DD/MM HH:mm:ss');
  }

  async function handleDownloadClick() {
    const allHeaders = headers.concat(getAreaHeaders());
    const filename = 'Timeline In Locations.csv';
    const dataArray = await db.vehiclesInLocations.toArray();
    const data = dataArray[0]
      .filter(({ change }) => change !== 0)
      .map(({ time, areas, ...record }) => ({
        ...record,
        time: moment(time).format('YYYY-MM-DD HH:mm:ss'),
        ...areas,
      }));

    downloadCSV(data, filename, allHeaders);
  }

  function Row({ data, index, style }) {
    const series = data[index];

    return (
      <Box key={series.name} style={style}>
        <Typography sx={{ p: [1, 2] }} variant="subtitle2">
          {series.name}
        </Typography>
        <Paper sx={{ m: 1, minWidth: 240, fontSize: 12 }}>
          <CardContent sx={{ p: 0, pt: 4 }}>
            <ResponsiveContainer width="99%" height={200}>
              <AreaChart
                data={series.values}
                margin={{ top: 8, right: 16, left: 0, bottom: 32 }}
              >
                <XAxis
                  dataKey="time"
                  type="number"
                  domain={['dataMin', 'dataMax']}
                  tickFormatter={formatLabel}
                >
                  <Label value="Time" position="bottom" offset={36} />
                </XAxis>
                <YAxis interval={0} allowDecimals={false}>
                  <Label
                    value="Count"
                    angle={-90}
                    position="left"
                    offset={-24}
                  />
                </YAxis>
                <Tooltip
                  content={
                    <CustomTooltip
                      unit="vehicles"
                      labelFormatter={formatLabel}
                    />
                  }
                />
                <Brush dataKey="time" height={24} tickFormatter={formatLabel} />
                <Area
                  type="stepAfter"
                  dataKey="residentCount"
                  name="Home Vehicles"
                  dot={false}
                  stroke={blue[500]}
                  fill={blue[500]}
                  strokeWidth={1}
                  stackId="1"
                  hide={hiddenAreas.includes('resident')}
                  isAnimationActive={false}
                />
                <Area
                  type="stepAfter"
                  dataKey="visitorCount"
                  name="Visiting Vehicles"
                  dot={false}
                  stroke={amber[500]}
                  fill={amber[500]}
                  strokeWidth={1}
                  stackId="1"
                  hide={hiddenAreas.includes('visitor')}
                  isAnimationActive={false}
                />
                );
              </AreaChart>
            </ResponsiveContainer>
          </CardContent>
        </Paper>
      </Box>
    );
  }

  return (
    <Container title="Timeline in Location">
      <Parameters
        onFetch={handleFetch}
        onCancel={handleCancel}
        isFetching={isLoading}
        sx={{ mt: 1, width: 264 }}
        vehicle
        location
      />
      <Box
        sx={{
          flex: 1,
          height: 'calc(100vh - 48px)',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <Helmet>
          <title>IR3 | Timeline in Location</title>
        </Helmet>
        <Toolbar variant="dense" disableGutters sx={{ p: 1, pb: 0 }}>
          <Typography sx={{ flexGrow: 1 }} variant="subtitle1">
            Vehicles in Location Timelines
          </Typography>
          <Box
            sx={{
              pl: 8,
              pr: 2,
              pb: 1,
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                p: 0.5,
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={() => handleLegendClick('resident')}
            >
              <Avatar
                sx={{ width: 12, height: 12, mr: 0.5 }}
                style={{
                  backgroundColor:
                    !hiddenAreas.includes('resident') && blue[500],
                }}
              >
                <Fragment />
              </Avatar>
              <Typography variant="caption">Home Vehicles</Typography>
            </Box>
            <Box
              sx={{
                p: 0.5,
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={() => handleLegendClick('visitor')}
            >
              <Avatar
                sx={{ width: 12, height: 12, mr: 0.5 }}
                style={{
                  backgroundColor:
                    !hiddenAreas.includes('visitor') && amber[500],
                }}
              >
                <Fragment />
              </Avatar>
              <Typography variant="caption">Visiting Vehicles</Typography>
            </Box>
          </Box>
          <IconButton
            title={showFilter ? 'Hide filter' : 'Show filter'}
            onClick={() => handleFilterToggle()}
          >
            <FilterListIcon color={showFilter ? 'primary' : 'inherit'} />
          </IconButton>
          <IconButton
            title="Download data"
            disabled={data.length === 0}
            onClick={handleDownloadClick}
          >
            <GetAppIcon />
          </IconButton>
        </Toolbar>
        <Collapse in={showFilter} timeout="auto">
          <Stack spacing={1.5} sx={{ flex: 1, p: 1.5 }}>
            {!useReducedResourceInformation && (
              <SelectMultiple
                label="Registration Number"
                placeholder="Select..."
                value={filter.registrationNumber}
                labelValue
                onChange={(value) =>
                  handleFilterFieldChanged('registrationNumber', value)
                }
                suggestions={filterValues.registrationNumber.map((value) => ({
                  label: value,
                  value,
                }))}
              />
            )}
            <SelectMultiple
              label="Fleet Number"
              placeholder="Select..."
              value={filter.fleetNumber}
              labelValue
              onChange={(value) =>
                handleFilterFieldChanged('fleetNumber', value)
              }
              suggestions={filterValues.fleetNumber.map((value) => ({
                label: value,
                value,
              }))}
            />
            {useReducedResourceInformation ? (
              <SelectMultiple
                label="Type"
                placeholder="Select..."
                value={filter.type}
                labelValue
                onChange={(value) => handleFilterFieldChanged('type', value)}
                suggestions={filterValues.type.map((value) => ({
                  label: value,
                  value,
                }))}
              />
            ) : (
              <SelectMultiple
                label="Role"
                placeholder="Select..."
                value={filter.role}
                labelValue
                onChange={(value) => handleFilterFieldChanged('role', value)}
                suggestions={filterValues.role.map((value) => ({
                  label: value,
                  value,
                }))}
              />
            )}
            <SelectMultiple
              label="Location Name"
              placeholder="Select..."
              value={filter.locationName}
              labelValue
              onChange={(value) =>
                handleFilterFieldChanged('locationName', value)
              }
              suggestions={filterValues.locationName.map((value) => ({
                label: value,
                value,
              }))}
            />
            <SelectMultiple
              label="Location Type"
              placeholder="Select..."
              value={filter.locationType}
              labelValue
              onChange={(value) =>
                handleFilterFieldChanged('locationType', value)
              }
              suggestions={filterValues.locationType.map((value) => ({
                label: value,
                value,
              }))}
            />
            {Object.entries(filterValues.areas).map((entry) => {
              return (
                <SelectMultiple
                  key={entry[0]}
                  label={_.startCase(entry[0])}
                  placeholder="Select..."
                  value={filter.areas[entry[0]] || []}
                  labelValue
                  onChange={(value) =>
                    handleFilterFieldChanged(entry[0], value)
                  }
                  suggestions={entry[1].map((value) => ({
                    label: value,
                    value,
                  }))}
                />
              );
            })}
          </Stack>
        </Collapse>
        {sortedData.length > 0 && (
          <Box
            sx={{ overflow: 'none', flex: 1, height: 'calc(100vh - 112px)' }}
          >
            <AutoSizer>
              {({ width, height }) => (
                <FixedSizeList
                  width={width}
                  height={height}
                  overscanCount={2}
                  itemData={sortedData}
                  itemCount={sortedData.length}
                  itemSize={330}
                >
                  {Row}
                </FixedSizeList>
              )}
            </AutoSizer>
          </Box>
        )}
      </Box>
    </Container>
  );
}
