import { useEffect, useState } from 'react';
import { alpha, Autocomplete, InputAdornment, TextField } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { FETCH_GAZETTEER_RESULTS } from '../../actions';

export default function GazetteerSearch({
  onChange,
  onHighlightChange,
  onInputChange,
  customResults,
  customRender,
  customToChangeEvent,
  className,
  sx,
}) {
  const results = useSelector((state) => state.gazetteer.results);
  const [combinedOptions, setCombinedOptions] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setCombinedOptions([...(customResults ?? []), ...results]);
  }, [results, customResults]);

  function handleInputChange(event) {
    const { value } = event.target;

    onInputChange?.(value);

    if (!!value) {
      dispatch({
        type: FETCH_GAZETTEER_RESULTS,
        payload: value,
      });
    }
  }

  function toChangeEvent(selection) {
    if (selection) {
      if (customResults?.indexOf(selection) >= 0) {
        return customToChangeEvent?.(selection) ?? {};
      } else if (results.indexOf(selection) >= 0) {
        const {
          boundingbox: boundsStrings,
          lon: lonString,
          lat: latString,
        } = selection;

        const bounds = boundsStrings.map(parseFloat);
        // change [51 52 -1 1]
        // to [-1, 51, 1, 52]
        // nominatim says it has bbox like this: bbox = left,bottom,right,top
        // but it and osm do [bottom, top, left, right] in practice
        // https://wiki.openstreetmap.org/wiki/Bounding_Box
        const extent = bounds?.length === 4 && [
          bounds[2],
          bounds[0],
          bounds[3],
          bounds[1],
        ];

        return {
          extent,
          lat: !!latString && parseFloat(latString),
          lon: !!lonString && parseFloat(lonString),
        };
      }
    }

    return {};
  }

  function renderOption(props, option, state) {
    return (
      <div {...props} key={props.id}>
        {customResults?.indexOf(option) >= 0
          ? customRender(option, props)
          : option.display_name}
      </div>
    );
  }

  function handleChange(event, selection) {
    onChange?.(toChangeEvent(selection));
  }

  function handleHighlightChange(event, selection, reason) {
    reason === 'keyboard' && onHighlightChange?.(toChangeEvent(selection));
  }

  function getOptionLabel(option) {
    return option?.display_name ?? ''; //customRender?.(option) ?? '';
  }

  const debouncedHandleHighlight = _.debounce(handleHighlightChange, 300);

  return (
    <Autocomplete
      // className={className}
      // freeSolo
      onInputChange={handleInputChange}
      onChange={handleChange}
      onHighlightChange={debouncedHandleHighlight}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      // the default filterOptions function will filter out any non-string values
      filterOptions={(options) => options}
      // disablePortal
      id="combo-box-demo"
      options={combinedOptions}
      // renderInput={(params) => <SearchBox {...params} label="Search..." />}
      renderInput={({ InputProps, ...params }) => {
        return (
          <TextField
            {...params}
            size="small"
            placeholder="Search..."
            variant="outlined"
            type="search"
            InputProps={{
              ...InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="inherit" />
                </InputAdornment>
              ),
            }}
            className={className}
            sx={{
              borderRadius: 1,
              pl: 1,
              bgcolor: alpha('#fff', 0.85),
              '&:hover': { bgcolor: alpha('#fff', 0.9) },
              '& .MuiOutlinedInput-root': {
                '& > fieldset': {
                  border: 'none',
                },
              },
              '& .MuiOutlinedInput-root:hover': {
                '& > fieldset': {
                  border: 'none',
                },
              },
              ...sx,
            }}
          />
        );
      }}
    />
  );
}
