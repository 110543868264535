import { Fragment, useState } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Label,
  ReferenceArea,
  ResponsiveContainer,
} from 'recharts';
import { blue, red, orange, green } from '@mui/material/colors';
import moment from 'moment';
import { CustomTooltip } from '../../controls';
import { Avatar, Box, Typography } from '@mui/material';

const lines = [
  {
    name: 'Speed',
    colour: red[500],
    dataKey: 'speedMilesPerHour',
    yAxisId: 'speed',
    dot: { color: 'red', r: 2 },
  },
  {
    name: 'Horizontal Force',
    colour: green[500],
    dataKey: 'horizontalForce',
    yAxisId: 'g',
    dot: false,
  },
  {
    name: 'Vertical Force',
    colour: orange[500],
    dataKey: 'verticalForce',
    yAxisId: 'g',
    dot: false,
  },
  {
    name: 'Lateral Force',
    colour: blue[500],
    dataKey: 'lateralForce',
    yAxisId: 'g',
    dot: false,
  },
];

export default function AccelerometerChart({
  data,
  triggerPoint,
  onChartHover = () => {},
  dateFormat,
  margin,
}) {
  const [hiddenLines, setHiddenLines] = useState([]);

  function handleLegendClick(selectedLine) {
    const index = hiddenLines.indexOf(selectedLine);

    if (index === -1) {
      setHiddenLines(hiddenLines.concat(selectedLine));
    } else {
      setHiddenLines(
        hiddenLines.slice(0, index).concat(hiddenLines.slice(index + 1))
      );
    }
  }

  function formatLabel(timeStr) {
    return moment(timeStr).format(dateFormat ?? 'DD/MM HH:mm:ss:SSS');
  }

  return (
    <Fragment>
      <Box
        sx={{
          pl: 8,
          pr: 2,
          pb: 1,
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
        }}
      >
        {lines.map((line) => (
          <Box
            key={line.name}
            sx={{
              p: 0.5,
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={() => handleLegendClick(line.name)}
          >
            <Avatar
              sx={{
                width: 12,
                height: 12,
                mr: 0.5,
                bgcolor: !hiddenLines.includes(line.name) && line.colour,
              }}
            >
              <Fragment />
            </Avatar>
            <Typography variant="caption">{line.name}</Typography>
          </Box>
        ))}
      </Box>
      <ResponsiveContainer height={280}>
        <LineChart
          data={data}
          onMouseMove={(props) =>
            onChartHover(props?.activePayload?.[0]?.payload?.position)
          }
          onMouseLeave={() => onChartHover(null)}
          margin={margin}
        >
          <XAxis dataKey="time" tickFormatter={formatLabel}>
            <Label value="Time" position="bottom" offset={36} />
          </XAxis>
          <YAxis yAxisId="g">
            <Label
              value="Force (g)"
              angle={-90}
              position="insideLeft"
              offset={16}
            />
          </YAxis>
          <YAxis yAxisId="speed" orientation="right">
            <Label
              value="Speed (mph)"
              angle={90}
              position="insideRight"
              offset={16}
            />
          </YAxis>
          <Tooltip content={<CustomTooltip labelFormatter={formatLabel} />} />
          {lines.map((line) => (
            <Line
              key={line.dataKey}
              yAxisId={line.yAxisId}
              type="monotone"
              name={line.name}
              dataKey={line.dataKey}
              stroke={line.colour}
              strokeWidth={1}
              dot={line.dot}
              hide={hiddenLines.includes(line.name)}
              connectNulls={true}
            />
          ))}
          <ReferenceArea
            x1={data[triggerPoint * 10].time}
            x2={data[triggerPoint * 10 + 9].time}
            yAxisId="g"
          />
        </LineChart>
      </ResponsiveContainer>
    </Fragment>
  );
}
