import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from '@mui/material';
import { Place as PlaceIcon } from '@mui/icons-material';

export default function FileItemDetails({
  primaryItemKey,
  secondaryItemKey,
  itemData,
}) {
  return (
    <Card sx={{ m: [1, 0.5, 1, 1], fontSize: 12 }}>
      <CardHeader
        avatar={
          <Avatar title="Item">
            <PlaceIcon />
          </Avatar>
        }
        title={itemData[primaryItemKey]}
        subheader={itemData[secondaryItemKey]}
      />
      <CardContent>
        <Table size="small">
          <TableBody>
            {Object.entries(itemData).map(([key, value]) => (
              <TableRow key={key}>
                <TableCell>
                  <Typography variant="caption" color="textSecondary">
                    {key}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="caption">{value}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}
