import { Avatar, Card, CardContent, CardHeader } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { LocationTypeIcon } from '../../../data/constants';

export default function Location({ color, background, name, type, subtype }) {
  return (
    <Card sx={{ m: [1, 0.5, 1, 1] }}>
      <Helmet>
        <title>IR3 | Location | {name}</title>
      </Helmet>
      <CardHeader
        avatar={
          <Avatar
            style={{
              background,
              color,
            }}
            title={type}
          >
            <LocationTypeIcon type={type} />
          </Avatar>
        }
        title={name}
        subheader={subtype}
      />
      <CardContent></CardContent>
    </Card>
  );
}
