import { Box, CircularProgress } from '@mui/material';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from './Auth';

export default function Callback() {
  const location = useLocation();
  const auth = useAuth();

  useEffect(() => {
    if (/code|error/.test(location.search)) {
      auth.handleAuthentication();
    }
  });

  return (
    <Box
      sx={{
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        bgcolor: 'background.default',
      }}
    >
      <CircularProgress sx={{ m: 2 }} size={200} thickness={2} />
    </Box>
  );
}
