import { mergeMap, map, catchError } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { ofType } from 'redux-observable';
import {
  FETCH_FEATURES,
  FETCH_FEATURES_SUCCESS,
  FETCH_FEATURES_FAILURE,
  FETCH_FEATURE,
  FETCH_FEATURE_SUCCESS,
  FETCH_FEATURE_FAILURE,
  CREATE_FEATURE,
  CREATE_FEATURE_SUCCESS,
  CREATE_FEATURE_FAILURE,
  UPDATE_FEATURE,
  UPDATE_FEATURE_SUCCESS,
  UPDATE_FEATURE_FAILURE,
  DELETE_FEATURE,
  DELETE_FEATURE_SUCCESS,
  DELETE_FEATURE_FAILURE,
  FETCH_FEATURE_COLLECTION,
  FETCH_FEATURE_COLLECTION_SUCCESS,
  FETCH_FEATURE_COLLECTION_FAILURE,
  SAVE_FEATURE_COLLECTION,
  SAVE_FEATURE_COLLECTION_SUCCESS,
  SAVE_FEATURE_COLLECTION_FAILURE,
} from '../actions';
import api, { fromAjax } from '../apis';
import { getFeatures, getHeaders, log } from '../apis/utilities';

async function fetchFeaturesRequest(type) {
  const indexName = ['Perimeter', 'Path', 'Marker'].includes(type)
    ? 'type'
    : 'subtype';

  const response = await api.get('/features', {
    params: {
      query:
        type !== 'All'
          ? {
              [indexName]: type,
            }
          : undefined,
      projection: { identifier: true, title: true, subtype: true },
    },
    headers: getHeaders(),
  });

  response.data.sort((a, b) => (a.title || '').localeCompare(b.title | ''));

  log('Read', 'Features', { type });

  return response.data;
}

export function fetchFeaturesEpic(action$) {
  return action$.pipe(
    ofType(FETCH_FEATURES),
    mergeMap(({ payload: type }) =>
      from(fetchFeaturesRequest(type)).pipe(
        map((payload) => ({
          type: FETCH_FEATURES_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_FEATURES_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function fetchFeatureRequest(id) {
  const response = await api.get(`/features/${id}`, {
    params: {
      projection: {
        identifier: true,
        type: true,
        subtype: true,
        title: true,
        description: true,
        areas: true,
        startTime: true,
        endTime: true,
        recurrence: true,
        complianceSeconds: true,
        collections: true,
        geometry: true,
        created: true,
        lastEdit: true,
      },
    },
    headers: getHeaders(),
  });

  log('Read', 'Feature', { id });

  return response.data;
}

export function fetchFeatureEpic(action$) {
  return action$.pipe(
    ofType(FETCH_FEATURE),
    mergeMap(({ payload: id }) =>
      from(fetchFeatureRequest(id)).pipe(
        map((payload) => ({
          type: FETCH_FEATURE_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_FEATURE_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

export function createFeatureEpic(action$) {
  return action$.pipe(
    ofType(CREATE_FEATURE),
    mergeMap(({ payload: body, navigate }) =>
      fromAjax(`/features`, {
        body,
        method: 'POST',
        headers: { ...getHeaders(), 'Content-Type': 'application/json' },
      }).pipe(
        map(({ response: payload }) => {
          log('Create', 'Feature', payload);

          navigate(`../${payload.identifier}`, {
            replace: true,
            state: { created: true },
          });

          return {
            type: CREATE_FEATURE_SUCCESS,
            payload,
          };
        }),
        catchError(({ message: payload }) =>
          of({
            type: CREATE_FEATURE_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function updateFeatureRequest(values) {
  api.patch(
    `/features/${values.id}`,
    {
      ...values,
    },
    {
      headers: {
        ...getHeaders(),
        'Content-Type': 'application/merge-patch+json',
      },
    }
  );

  return values;
}

export function updateFeatureEpic(action$) {
  return action$.pipe(
    ofType(UPDATE_FEATURE),
    mergeMap(({ payload: values }) =>
      from(updateFeatureRequest(values)).pipe(
        map((payload) => ({
          type: UPDATE_FEATURE_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: UPDATE_FEATURE_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

export function deleteFeatureEpic(action$) {
  return action$.pipe(
    ofType(DELETE_FEATURE),
    mergeMap(({ payload: id, navigate }) =>
      fromAjax(`/features/${id}`, {
        method: 'DELETE',
        headers: getHeaders(),
      }).pipe(
        map(({ response }) => {
          log('Delete', 'Feature', { id });

          navigate('.');

          return {
            type: DELETE_FEATURE_SUCCESS,
            payload: response.identifier,
          };
        }),
        catchError(({ message: payload }) =>
          of({
            type: DELETE_FEATURE_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function fetchFeatureCollectionRequest(id) {
  const featureCollection = await getFeatures(id);

  log('Read', 'Collection Features', { id });

  return featureCollection;
}

export function fetchFeatureCollectionEpic(action$) {
  return action$.pipe(
    ofType(FETCH_FEATURE_COLLECTION),
    mergeMap(({ payload: id }) =>
      from(fetchFeatureCollectionRequest(id)).pipe(
        map((payload) => ({
          type: FETCH_FEATURE_COLLECTION_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_FEATURE_COLLECTION_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function updateFeatureCollectionRequest(featureCollection) {
  await api.all(
    featureCollection.features.map(async (feature) => {
      const values = {
        ...feature.properties,
        geometry: feature.geometry,
      };

      return values.identifier
        ? api.patch(`/features/${values.identifier}`, values, {
            headers: {
              ...getHeaders(),
              'Content-Type': 'application/merge-patch+json',
            },
          })
        : api.post('/features', values, {
            headers: {
              ...getHeaders(),
              'Content-Type': 'application/merge-patch+json',
            },
          });
    })
  );
}

export function saveFeatureCollectionEpic(action$) {
  return action$.pipe(
    ofType(SAVE_FEATURE_COLLECTION),
    mergeMap(({ payload: featureCollection }) =>
      from(updateFeatureCollectionRequest(featureCollection)).pipe(
        map((payload) => ({
          type: SAVE_FEATURE_COLLECTION_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: SAVE_FEATURE_COLLECTION_FAILURE,
            payload,
          })
        )
      )
    )
  );
}
