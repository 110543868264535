import { AppBar, Box, IconButton, Toolbar, Typography } from '@mui/material';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { LoginAvatar, MenuButton } from './controls';

export default function Container({ showBack, title, children }) {
  const navigate = useNavigate();

  function handleBackClick() {
    navigate(-1);
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <AppBar position="static" sx={{ transform: 'translateZ(0)' }}>
        <Toolbar disableGutters variant="dense" sx={{ px: 1.5 }}>
          {showBack ? (
            <IconButton
              color="inherit"
              edge="start"
              sx={{ ml: 0.25, mr: 1 }}
              onClick={handleBackClick}
              size="large"
            >
              <ArrowBackIcon />
            </IconButton>
          ) : (
            <MenuButton color="inherit" edge="start" sx={{ ml: 0.25, mr: 1 }} />
          )}
          <Typography variant="h6" color="inherit" sx={{ flex: 1 }}>
            {title}
          </Typography>
          <LoginAvatar />
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          display: 'flex',
          height: 1,
          overflow: 'hidden',
          backgroundColor: 'background.default',
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
