import { mergeMap, map, catchError } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { ofType } from 'redux-observable';
import {
  FETCH_BRIEFS,
  FETCH_BRIEFS_SUCCESS,
  FETCH_BRIEFS_FAILURE,
  FETCH_BRIEF,
  FETCH_BRIEF_SUCCESS,
  FETCH_BRIEF_FAILURE,
  CREATE_BRIEF,
  CREATE_BRIEF_SUCCESS,
  CREATE_BRIEF_FAILURE,
  UPDATE_BRIEF,
  UPDATE_BRIEF_SUCCESS,
  UPDATE_BRIEF_FAILURE,
  DELETE_BRIEF,
  DELETE_BRIEF_SUCCESS,
  DELETE_BRIEF_FAILURE,
  FETCH_BRIEF_COLLECTIONS,
  FETCH_BRIEF_COLLECTIONS_SUCCESS,
  FETCH_BRIEF_COLLECTIONS_FAILURE,
  FETCH_BRIEF_OBJECTIVES,
  FETCH_BRIEF_OBJECTIVES_SUCCESS,
  FETCH_BRIEF_OBJECTIVES_FAILURE,
} from '../actions';
import api, { fromAjax } from '../apis';
import {
  getSelectionEvents,
  getFeatures,
  getHeaders,
  getQueryEvents,
  getFeatureBoundary,
  getLocationBoundary,
  log,
  addBriefCollections,
  removeBriefCollections,
} from '../apis/utilities';

async function fetchBriefsRequest() {
  const response = await api.get('/briefs', {
    params: {
      projection: {
        identifier: true,
        title: true,
      },
      sort: { title: 1 },
    },
    headers: getHeaders(),
  });

  log('Read', 'Briefs');

  return response.data;
}

export function fetchBriefsEpic(action$) {
  return action$.pipe(
    ofType(FETCH_BRIEFS),
    mergeMap(() =>
      from(fetchBriefsRequest()).pipe(
        map((payload) => ({
          type: FETCH_BRIEFS_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_BRIEFS_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function fetchBriefRequest(id) {
  const headers = getHeaders();

  const response = await api.get(`/briefs/${id}`, {
    params: {
      projection: {
        identifier: true,
        title: true,
        type: true,
        areas: true,
        description: true,
        created: true,
        lastEdit: true,
      },
    },
    headers,
  });

  const plansResponse = await api.get('/plans', {
    params: {
      query: {
        briefs: id,
      },
    },
    headers,
  });

  const queriesResponse = await api.get('/queries', {
    params: {
      query: {
        briefs: id,
      },
    },
    headers,
  });

  const tagsResponse = await api.get('/tags', {
    params: {
      query: {
        briefs: id,
      },
    },
    headers,
  });

  const brief = {
    ...response.data,

    plans: plansResponse.data.map((plan) => plan.identifier),
    initialPlans: plansResponse.data.map((plan) => plan.identifier),

    queries: queriesResponse.data.map((query) => query.identifier),
    initialQueries: queriesResponse.data.map((query) => query.identifier),

    tags: tagsResponse.data.map((tag) => tag.identifier),
    initialTags: tagsResponse.data.map((tag) => tag.identifier),
  };

  log('Read', 'Brief', { id });

  return brief;
}

export function fetchBriefEpic(action$) {
  return action$.pipe(
    ofType(FETCH_BRIEF),
    mergeMap(({ payload: id }) =>
      from(fetchBriefRequest(id)).pipe(
        map((payload) => ({
          type: FETCH_BRIEF_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_BRIEF_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

export function createBriefEpic(action$) {
  return action$.pipe(
    ofType(CREATE_BRIEF),
    mergeMap(({ payload: { plans, queries, tags, ...body }, navigate }) =>
      fromAjax(`/briefs`, {
        body,
        method: 'POST',
        headers: { ...getHeaders(), 'Content-Type': 'application/json' },
      }).pipe(
        map(({ response: payload }) => {
          log('Create', 'Brief', payload);

          navigate(`../${payload.identificationNumber}`, {
            replace: true,
            state: { created: true },
          });

          return {
            type: CREATE_BRIEF_SUCCESS,
            payload: {
              ...payload,
              plans,
              initialPlans: [],
              queries,
              initialQueries: [],
              tags,
              initialTags: [],
            },
          };
        }),
        catchError(({ message: payload }) =>
          of({
            type: CREATE_BRIEF_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function updateBriefRequest(values) {
  const {
    plans,
    initialPlans,
    queries,
    initialQueries,
    tags,
    initialTags,
    ...others
  } = values;

  const brief = {
    ...others,
  };

  const addedPlans = plans.filter(
    (identifier) => !initialPlans.includes(identifier)
  );
  const removedPlans = initialPlans.filter(
    (identifier) => !plans.includes(identifier)
  );

  const addedQueries = queries.filter(
    (identifier) => !initialQueries.includes(identifier)
  );
  const removedQueries = initialQueries.filter(
    (identifier) => !queries.includes(identifier)
  );

  const addedTags = tags.filter(
    (identifier) => !initialTags.includes(identifier)
  );
  const removedTags = initialTags.filter(
    (identifier) => !tags.includes(identifier)
  );

  await addBriefCollections(addedPlans, 'plans', brief);
  await addBriefCollections(addedQueries, 'queries', brief);
  await addBriefCollections(addedTags, 'tags', brief);

  await removeBriefCollections(removedPlans, 'plans', brief);
  await removeBriefCollections(removedQueries, 'queries', brief);
  await removeBriefCollections(removedTags, 'tags', brief);

  await api.patch(`/briefs/${brief.identifier}`, brief, {
    headers: {
      ...getHeaders(),
      'Content-Type': 'application/merge-patch+json',
    },
  });

  return {
    ...brief,
    plans,
    initialPlans: plans,
    queries,
    initialQueries: queries,
    tags,
    initialTags: tags,
  };
}

export function updateBriefEpic(action$) {
  return action$.pipe(
    ofType(UPDATE_BRIEF),
    mergeMap(({ payload: values }) =>
      from(updateBriefRequest(values)).pipe(
        map((payload) => ({
          type: UPDATE_BRIEF_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: UPDATE_BRIEF_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

export function deleteBriefEpic(action$) {
  return action$.pipe(
    ofType(DELETE_BRIEF),
    mergeMap(({ payload: id, navigate }) =>
      fromAjax(`/briefs/${id}`, {
        method: 'DELETE',
        headers: getHeaders(),
      }).pipe(
        map(({ response }) => {
          log('Delete', 'Brief', { id });

          navigate('.');

          return {
            type: DELETE_BRIEF_SUCCESS,
            payload: response.identifier,
          };
        }),
        catchError(({ message: payload }) =>
          of({
            type: DELETE_BRIEF_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

const briefCollectionsQueryParams = (id) => {
  return {
    query: { briefs: id },
    projection: {
      identifier: true,
      title: true,
      type: true,
      description: true,
      startTime: true,
      endTime: true,
      areas: true,
      briefs: true,
      boundary: true,
      subtype: true,
    },
  };
};

async function fetchBriefCollectionsRequest(id) {
  const plansResponse = await api.get(`/plans`, {
    params: briefCollectionsQueryParams(id),
    headers: getHeaders(),
  });

  const queriesResponse = await api.get(`/queries`, {
    params: briefCollectionsQueryParams(id),
    headers: getHeaders(),
  });

  const tagsResponse = await api.get(`/tags`, {
    params: briefCollectionsQueryParams(id),
    headers: getHeaders(),
  });

  const allResponses = {
    data: [
      ...plansResponse.data,
      ...queriesResponse.data,
      ...tagsResponse.data,
    ],
  };

  const collectionList = await Promise.all(
    allResponses.data.map(async (collection) => {
      switch (collection.type) {
        case 'Plan':
          return {
            ...collection,
            items: {
              boundary: {
                type: 'FeatureCollection',
                features: [
                  {
                    type: 'Feature',
                    id: 0,
                    properties: {
                      typeId: 'boundary',
                      collectionId: collection.identifier,
                      collectionTypeId: 'plans',
                    },
                    geometry: collection.boundary,
                  },
                ],
              },
              features: await getFeatures(collection.identifier),
            },
          };
        case 'Selection':
          return {
            ...collection,
            items: await getSelectionEvents(collection.identifier),
          };
        case 'Query':
          return {
            ...collection,
            items: await getQueryEvents(collection.identifier),
          };
        default:
          return collections;
      }
    })
  );

  const collections = collectionList.reduce(
    (accumulator, collection) => {
      switch (collection.type) {
        case 'Plan':
          return {
            ...accumulator,
            plans: {
              ...accumulator.plans,
              [collection.identifier]: collection,
            },
          };
        case 'Selection':
          return {
            ...accumulator,
            selections: {
              ...accumulator.selections,
              [collection.identifier]: collection,
            },
          };
        case 'Query':
          return {
            ...accumulator,
            queries: {
              ...accumulator.queries,
              [collection.identifier]: collection,
            },
          };
        default:
          return accumulator;
      }
    },
    { plans: {}, selections: {}, queries: {}, tags: {} }
  );

  log('Read', 'Brief Collections', { id });

  return collections;
}

export function fetchBriefCollectionsEpic(action$) {
  return action$.pipe(
    ofType(FETCH_BRIEF_COLLECTIONS),
    mergeMap(({ payload: id }) =>
      from(fetchBriefCollectionsRequest(id)).pipe(
        map((payload) => ({
          type: FETCH_BRIEF_COLLECTIONS_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_BRIEF_COLLECTIONS_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function fetchBriefObjectivesRequest(id) {
  const response = await api.get(`/objectives`, {
    params: {
      query: { briefs: id },
      projection: {
        identifier: true,
        title: true,
        type: true,
        description: true,
        startTime: true,
        endTime: true,
        schedule: true,
        complianceSeconds: true,
        requiredVisits: true,
        requiredFrequency: true,
        areas: true,
        briefs: true,
        boundaryType: true,
        boundarySubtype: true,
        boundaryIdentifier: true,
        boundary: true,
        created: true,
        lastEdit: true,
        occurrenceNumber: true,
      },
    },
    headers: getHeaders(),
  });

  const objectives = await Promise.all(
    response.data.map(async (objective) => {
      switch (objective.boundaryType) {
        case 'Custom':
          return objective;
        case 'Location':
          return {
            ...objective,
            boundary: await getLocationBoundary(objective.boundaryIdentifier),
          };
        case 'Perimeter':
          return {
            ...objective,
            boundary: await getFeatureBoundary(objective.boundaryIdentifier),
          };
        default:
          return objective;
      }
    })
  );

  const objectiveFeatureCollection = {
    type: 'FeatureCollection',
    features: objectives.map(({ boundary, ...properties }, index) => {
      return {
        type: 'Feature',
        id: index,
        properties: {
          ...properties,
          typeId: 'objectives',
        },
        geometry: boundary,
      };
    }),
  };

  log('Read', 'Brief Objectives', { id });

  return objectiveFeatureCollection;
}

export function fetchBriefObjectivesEpic(action$) {
  return action$.pipe(
    ofType(FETCH_BRIEF_OBJECTIVES),
    mergeMap(({ payload: id }) =>
      from(fetchBriefObjectivesRequest(id)).pipe(
        map((payload) => ({
          type: FETCH_BRIEF_OBJECTIVES_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_BRIEF_OBJECTIVES_FAILURE,
            payload,
          })
        )
      )
    )
  );
}
