import CrimeParameters from './CrimeParameters';
import IncidentParameters from './IncidentParameters';
import IntelligenceParameters from './IntelligenceParameters';
import StopCheckParameters from './StopCheckParameters';

export default function Parameters({ name, source, filters }) {
  switch (source) {
    case 'Incidents':
      return <IncidentParameters name={name} filters={filters} />;
    case 'Crimes':
      return <CrimeParameters name={name} filters={filters} />;
    case 'Intelligence':
      return <IntelligenceParameters name={name} filters={filters} />;
    case 'Stop Checks':
      return <StopCheckParameters name={name} filters={filters} />;
    default:
      return null;
  }
}
