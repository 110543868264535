import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Section = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingBottom: theme.spacing(1),
  display: 'flex',
  flexWrap: 'wrap',
}));
