import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import {
  FETCH_RADIOS,
  FETCH_RADIOS_SUCCESS,
  FETCH_RADIOS_FAILURE,
  FETCH_RADIO,
  FETCH_RADIO_SUCCESS,
  FETCH_RADIO_FAILURE,
  CREATE_RADIO,
  CREATE_RADIO_SUCCESS,
  CREATE_RADIO_FAILURE,
  UPDATE_RADIO,
  UPDATE_RADIO_SUCCESS,
  UPDATE_RADIO_FAILURE,
  DELETE_RADIO,
  DELETE_RADIO_SUCCESS,
  DELETE_RADIO_FAILURE,
  REMOVE_RADIO,
  REMOVE_RADIO_SUCCESS,
  REMOVE_RADIO_FAILURE,
} from '../actions';
import { fromAjax } from '../apis';
import { getHeaders, log } from '../apis/utilities';

export function fetchRadiosEpic(action$) {
  return action$.pipe(
    ofType(FETCH_RADIOS),
    mergeMap(() =>
      fromAjax('/radios', {
        params: {
          pipeline: [
            {
              $project: {
                ssi: true,
                type: true,
                label: true,
                firstContactTime: true,
                lastPoll: { $first: '$lastPolls' },
              },
            },
            {
              $lookup: {
                from: 'people',
                localField: 'ssi',
                foreignField: 'radioSsi',
                as: 'people',
              },
            },
            {
              $set: {
                people: {
                  $filter: {
                    input: '$people',
                    as: 'person',
                    cond: { $ne: ['$$person.deleted', true] },
                  },
                },
              },
            },
            { $set: { assignmentCount: { $size: '$people' } } },
            { $unset: 'people' },
          ],
        },
        headers: getHeaders(),
      }).pipe(
        map(({ response: payload }) => {
          log('Read', 'Radios');

          return {
            type: FETCH_RADIOS_SUCCESS,
            payload,
          };
        }),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_RADIOS_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

export function fetchRadioEpic(action$) {
  return action$.pipe(
    ofType(FETCH_RADIO),
    mergeMap(({ payload: id }) =>
      fromAjax('/radios', {
        params: {
          pipeline: [
            {
              $match: {
                ssi: id,
              },
            },
            {
              $project: {
                ssi: true,
                type: true,
                label: true,
                lastPoll: { $first: '$lastPolls' },
                firstContactTime: true,
              },
            },
            {
              $lookup: {
                from: 'people',
                localField: 'ssi',
                foreignField: 'radioSsi',
                as: 'people',
              },
            },
            {
              $set: {
                people: {
                  $filter: {
                    input: '$people',
                    as: 'person',
                    cond: { $ne: ['$$person.deleted', true] },
                  },
                },
              },
            },
          ],
        },
        headers: getHeaders(),
      }).pipe(
        map(({ response: [payload] }) => {
          log('Read', 'Radio', { id });

          return {
            type: FETCH_RADIO_SUCCESS,
            payload,
          };
        }),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_RADIO_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

export function createRadioEpic(action$) {
  return action$.pipe(
    ofType(CREATE_RADIO),
    mergeMap(({ payload: body, navigate }) =>
      fromAjax(`/radios`, {
        body,
        method: 'POST',
        headers: { ...getHeaders(), 'Content-Type': 'application/json' },
      }).pipe(
        map(({ response: payload }) => {
          log('Create', 'Radio', payload);

          navigate(`../${payload.ssi}`, {
            replace: true,
            state: { created: true },
          });

          return {
            type: CREATE_RADIO_SUCCESS,
            payload,
          };
        }),
        catchError(({ message: payload }) =>
          of({
            type: CREATE_RADIO_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

export function updateRadioEpic(action$) {
  return action$.pipe(
    ofType(UPDATE_RADIO),
    mergeMap(({ payload: { people, lastPoll, ...body } }) =>
      fromAjax(`/radios/${body.ssi}`, {
        body,
        method: 'PATCH',
        headers: {
          ...getHeaders(),
          'Content-Type': 'application/merge-patch+json',
        },
      }).pipe(
        map(({ response: payload }) => {
          log('Update', 'Radio', payload);

          return {
            type: UPDATE_RADIO_SUCCESS,
            payload: { people, lastPoll, ...payload },
          };
        }),
        catchError(({ message: payload }) =>
          of({
            type: UPDATE_RADIO_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

export function deleteRadioEpic(action$) {
  return action$.pipe(
    ofType(DELETE_RADIO),
    mergeMap(({ payload: id, navigate }) =>
      fromAjax(`/radios/${id}`, {
        method: 'DELETE',
        headers: getHeaders(),
      }).pipe(
        map(({ response }) => {
          log('Delete', 'Radio', { id });

          navigate('.');

          return {
            type: DELETE_RADIO_SUCCESS,
            payload: response.ssi,
          };
        }),
        catchError(({ message: payload }) =>
          of({
            type: DELETE_RADIO_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

export function removeRadioEpic(action$) {
  return action$.pipe(
    ofType(REMOVE_RADIO),
    mergeMap(({ payload: { id, body } }) =>
      fromAjax(`/people/${body.code}`, {
        body,
        method: 'PATCH',
        headers: {
          ...getHeaders(),
          'Content-Type': 'application/merge-patch+json',
        },
      }).pipe(
        map(({ response: { code } }) => {
          log('Update', 'Person', code);

          return {
            type: REMOVE_RADIO_SUCCESS,
            payload: { id, code },
          };
        }),
        catchError(({ message: payload }) =>
          of({
            type: REMOVE_RADIO_FAILURE,
            payload,
          })
        )
      )
    )
  );
}
