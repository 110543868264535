import { Box, FormHelperText, IconButton } from '@mui/material';
import {
  Cancel as CancelIcon,
  Create as CreateIcon,
  ZoomIn as ZoomInIcon,
  ZoomOut as ZoomOutIcon,
  ZoomOutMap as ZoomOutMapIcon,
} from '@mui/icons-material';
import { Collection, Feature, Map } from 'ol';
import { defaults as defaultControls } from 'ol/control';
// import DragPanInteraction from 'ol/interaction/DragPan';
import { altKeyOnly } from 'ol/events/condition';
import { applyTransform } from 'ol/extent';
import { GeoJSON, GPX, IGC, KML, TopoJSON } from 'ol/format';
import {
  defaults as defaultInteractions,
  DoubleClickZoom as DoubleClickZoomInteraction,
  DragAndDrop as DragAndDropInteraction,
  Draw as DrawInteraction,
  Modify as ModifyInteraction,
  MouseWheelZoom as MouseWheelZoomInteraction,
  Select as SelectInteraction,
} from 'ol/interaction';
import { Vector as VectorLayer } from 'ol/layer';
import 'ol/ol.css';
import { getTransform } from 'ol/proj';
import { Vector as VectorSource } from 'ol/source';
import { Circle, Fill, Stroke, Style } from 'ol/style';
import { useState, useEffect, useRef } from 'react';
import { setBaseLayers, getMapView } from '../../mapStyles';
import { MapLayerSwitcher } from '../controls';

const { simplificationTolerance, mapExtent, maxZoom } = window.config;

const defaultStyle = new Style({
  fill: new Fill({
    color: 'rgba(100,100,100,0.5)',
  }),
  stroke: new Stroke({
    color: 'rgb(0,0,0)',
    width: 2,
  }),
  image: new Circle({
    radius: 8,
    fill: new Fill({
      color: 'rgb(0,0,0)',
    }),
  }),
});

const selectStyle = new Style({
  fill: new Fill({
    color: 'rgba(33,150,243,0.5)',
  }),
  stroke: new Stroke({
    color: 'rgb(33,150,243)',
    width: 2,
  }),
  image: new Circle({
    radius: 8,
    fill: new Fill({
      color: 'rgb(33,150,243)',
    }),
  }),
});

export default function GeometryField({ input, meta, geoType }) {
  const [zoomInDisabled, setZoomInDisabled] = useState(false);
  const [zoomOutDisabled, setZoomOutDisabled] = useState(false);
  const [fitDisabled, setFitDisabled] = useState(false);
  const [drawActive, setDrawActive] = useState(false);
  // const [refitMap, setRefitMap] = useState(false);
  const mapDiv = useRef(null);
  const map = useRef(null);
  const select = useRef(null);
  const draw = useRef(null);
  const modify = useRef(null);
  const doubleClickZoom = useRef(null);
  const dragAndDrop = useRef(null);
  const vectorSource = useRef(null);

  function resetMapControls() {
    setZoomInDisabled(
      map.current.getView().getZoom() === map.current.getView().getMaxZoom()
    );
    setZoomOutDisabled(
      map.current.getView().getZoom() === map.current.getView().getMinZoom()
    );
    setFitDisabled(false);
    // setRefitMap(false);
  }

  useEffect(() => {
    if (!map.current) {
      vectorSource.current = new VectorSource({
        features: new Collection([]),
      });

      select.current = new SelectInteraction({ style: selectStyle });
      modify.current = new ModifyInteraction({
        features: select.current.getFeatures(),
      });

      modify.current.on('modifyend', (event) => {
        const geometry = new GeoJSON().writeGeometryObject(
          event.features.getArray()[0].getGeometry(),
          { featureProjection: 'EPSG:3857', rightHanded: true }
        );
        input.onChange(geometry);
      });

      doubleClickZoom.current = new DoubleClickZoomInteraction();

      dragAndDrop.current = new DragAndDropInteraction({
        formatConstructors: [GPX, GeoJSON, IGC, KML, TopoJSON],
      });
      dragAndDrop.current.on('addfeatures', (event) => {
        const geometry = new GeoJSON().writeGeometryObject(
          event.features[0].getGeometry().simplify(simplificationTolerance),
          { featureProjection: 'EPSG:3857', rightHanded: true }
        );
        // setRefitMap(true);
        input.onChange(geometry);
      });

      draw.current = new DrawInteraction({
        source: vectorSource.current,
        type: geoType,
      });
      draw.current.on('drawend', () => {
        map.current.removeInteraction(draw.current);

        setDrawActive(false);
        setFitDisabled(false);

        setTimeout(() => {
          map.current.addInteraction(select.current);
          map.current.addInteraction(doubleClickZoom.current);
          map.current.addInteraction(dragAndDrop.current);

          const geo = new GeoJSON().writeGeometryObject(
            vectorSource.current.getFeatures()[0].getGeometry(),
            { featureProjection: 'EPSG:3857', rightHanded: true }
          );

          input.onChange(geo);
        }, 0);
      });

      map.current = new Map({
        target: mapDiv.current,
        layers: [
          new VectorLayer({
            source: vectorSource.current,
            style: defaultStyle,
          }),
        ],
        interactions: defaultInteractions({
          doubleClickZoom: false,
          mouseWheelZoom: false,
          pinchRotate: false,
          altShiftDragRotate: false,
        }).extend([
          doubleClickZoom.current,
          select.current,
          modify.current,
          dragAndDrop.current,
          new MouseWheelZoomInteraction({
            condition: altKeyOnly,
          }),
        ]),
        view: getMapView(),
        controls: defaultControls({
          attribution: false,
          rotate: false,
          zoom: false,
        }),
      });

      setBaseLayers(map.current);

      map.current.on('moveend', resetMapControls);

      if (input.value) {
        if (vectorSource.current.getFeatures().length > 0) {
          vectorSource.current.getFeatures()[0].setGeometry(
            new GeoJSON().readGeometry(input.value, {
              featureProjection: 'EPSG:3857',
            })
          );
        } else {
          vectorSource.current.addFeature(
            new Feature(
              new GeoJSON().readGeometry(input.value, {
                featureProjection: 'EPSG:3857',
              })
            )
          );
        }

        const geometry = new GeoJSON().readGeometry(input.value, {
          featureProjection: 'EPSG:3857',
        });
        map.current.getView().fit(geometry, { maxZoom: maxZoom });
      } else {
        map.current
          .getView()
          .fit(
            applyTransform(mapExtent, getTransform('EPSG:4326', 'EPSG:3857'))
          );
      }
      resetMapControls();
    }
  }, [geoType, input]);

  useEffect(() => {
    if (vectorSource.current.getFeatures().length > 0) {
      if (input.value) {
        vectorSource.current.getFeatures()[0].setGeometry(
          new GeoJSON().readGeometry(input.value, {
            featureProjection: 'EPSG:3857',
          })
        );
      } else {
        vectorSource.current.clear();
      }
    } else {
      if (input.value) {
        vectorSource.current.addFeature(
          new Feature(
            new GeoJSON().readGeometry(input.value, {
              featureProjection: 'EPSG:3857',
            })
          )
        );
      } else {
        // do nothing
      }
    }

    if (meta.pristine) {
      if (input.value) {
        const geometry = new GeoJSON().readGeometry(input.value, {
          featureProjection: 'EPSG:3857',
        });
        map.current.getView().fit(geometry, { maxZoom: maxZoom });
      } else {
        map.current
          .getView()
          .fit(
            applyTransform(mapExtent, getTransform('EPSG:4326', 'EPSG:3857'))
          );
      }
      resetMapControls();
    }
  }, [input.value, meta.pristine]);

  function handleZoomInClick() {
    if (map.current) {
      map.current.getView().setZoom(map.current.getView().getZoom() + 1);
      resetMapControls();
    }
  }

  function handleZoomOutClick() {
    if (map.current) {
      map.current.getView().setZoom(map.current.getView().getZoom() - 1);
      resetMapControls();
    }
  }

  function handleFitClick() {
    if (vectorSource.current && vectorSource.current.getFeatures().length > 0) {
      map.current
        .getView()
        .fit(vectorSource.current.getExtent(), { maxZoom: maxZoom });
      resetMapControls();
    }
  }

  function handleDrawClick() {
    vectorSource.current.clear();
    select.current.getFeatures().clear();

    if (drawActive) {
      setDrawActive(false);
      setFitDisabled(false);

      map.current.addInteraction(select.current);
      map.current.addInteraction(doubleClickZoom.current);
      map.current.addInteraction(dragAndDrop.current);
      map.current.removeInteraction(draw.current);

      input.onChange(undefined);
    } else {
      setDrawActive(true);
      setFitDisabled(true);

      map.current.removeInteraction(select.current);
      map.current.removeInteraction(doubleClickZoom.current);
      map.current.removeInteraction(dragAndDrop.current);
      map.current.addInteraction(draw.current);
    }
  }

  return (
    <Box
      sx={{
        position: 'relative',
        width: 1,
        minWidth: 240,
        height: 'calc(100vh - 254px)',
        color: 'common.white',
      }}
    >
      <Box id="map" sx={{ width: 1, height: 1 }} ref={mapDiv} />
      <Box
        sx={{
          top: 8,
          left: 8,
          borderRadius: 1,
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'rgba(255,255,255,.8)',
        }}
      >
        <IconButton
          title="Zoom In"
          aria-label="Zoom In"
          disabled={zoomInDisabled}
          onClick={handleZoomInClick}
          size="large"
        >
          <ZoomInIcon />
        </IconButton>
        <IconButton
          title="Zoom Out"
          aria-label="Zoom Out"
          disabled={zoomOutDisabled}
          onClick={handleZoomOutClick}
          size="large"
        >
          <ZoomOutIcon />
        </IconButton>
        <IconButton
          title="Fit"
          aria-label="Fit"
          disabled={fitDisabled}
          onClick={handleFitClick}
          size="large"
        >
          <ZoomOutMapIcon />
        </IconButton>
        <IconButton
          title={drawActive ? 'Cancel' : 'Draw'}
          aria-label="Draw"
          onClick={handleDrawClick}
          size="large"
        >
          {drawActive ? <CancelIcon /> : <CreateIcon />}
        </IconButton>
      </Box>
      <MapLayerSwitcher mapRef={map} />
      {meta.touched && meta.invalid && (
        <FormHelperText
          sx={{ position: 'absolute', top: 5, right: 5, zIndex: 1 }}
          error
        >
          {meta.error}
        </FormHelperText>
      )}
    </Box>
  );
}
