import { useState } from 'react';
import { IconButton, Menu, Box } from '@mui/material';
import {
  Tile as TileLayer,
  Image as ImageLayer,
  Group as LayerGroup,
} from 'ol/layer';
import { Layers as LayersIcon } from '@mui/icons-material';
import MapLayersList from './MapLayerList';

function getMapLayers(mapRef) {
  return (
    mapRef.current
      ?.getLayers()
      ?.getArray()
      ?.filter(
        (layer) =>
          layer instanceof TileLayer ||
          layer instanceof ImageLayer ||
          layer instanceof LayerGroup
      )
      ?.filter((layer) => layer.get('title')) ?? []
  );
}

function MapLayerSwitcher({ mapRef }) {
  const [layerAnchorEl, setLayerAnchorEl] = useState(null);
  const [mapStyle, setMapStyle] = useState();
  const mapLayers = getMapLayers(mapRef);

  function handleLayerMenuClick(event) {
    setLayerAnchorEl(event.currentTarget);
  }

  function handleLayerMenuClose() {
    setLayerAnchorEl(null);
  }

  return (
    <Box>
      <Menu
        getContentAnchorEl={null}
        open={Boolean(layerAnchorEl)}
        anchorEl={layerAnchorEl}
        onClose={handleLayerMenuClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box>
          <MapLayersList
            mapRef={mapRef}
            mapLayers={mapLayers}
            mapStyle={mapStyle}
            onMapStyleChange={setMapStyle}
          />
        </Box>
      </Menu>
      <Box
        sx={{
          position: 'absolute',
          bottom: 8,
          left: 8,
          borderRadius: 1,
          backgroundColor: 'rgba(255,255,255,.8)',
        }}
      >
        {mapLayers.length > 1 && (
          <IconButton
            onClick={handleLayerMenuClick}
            title="Layers"
            size="large"
          >
            <LayersIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  );
}

export default MapLayerSwitcher;
