import { Box, IconButton } from '@mui/material';
import {
  ArrowBack as BackIcon,
  // JLSETTINGS Settings as SettingsIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { MenuButton } from '../controls';
import { typeIcons } from './constants';

function TypeSelectorBar({
  showBack,
  onTypeChange,
  onSettingsSelected,
  type,
  authorisedTypes,
}) {
  const navigate = useNavigate();

  const shownTypes = Object.keys(typeIcons).filter((type) =>
    authorisedTypes.includes(type)
  );
  const squashed = shownTypes.length > 6;

  return (
    <Box
      sx={(theme) => ({
        minHeight: 48,
        maxHeight: 48,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: 420,
        [theme.breakpoints.down('sm')]: {
          width: 1,
        },
        ...(squashed ? { px: 2, justifyContent: 'space-between' } : {}),
      })}
    >
      {showBack ? (
        <IconButton
          sx={(theme) =>
            squashed
              ? {
                  width: 24,
                  height: 24,
                  [theme.breakpoints.down('sm')]: {
                    // margin: -12,
                    padding: 2, // ultimately controls the space left/right of the icons
                  },
                }
              : undefined
          }
          color="inherit"
          aria-label="Back"
          //style={{ marginLeft: -12 }}
          onClick={() => navigate(-1)}
          size="large"
        >
          <BackIcon />
        </IconButton>
      ) : (
        <MenuButton
          color="inherit"
          sx={(theme) =>
            squashed
              ? {
                  width: 24,
                  height: 24,
                  [theme.breakpoints.down('sm')]: {
                    // margin: -12,
                    padding: 2, // ultimately controls the space left/right of the icons
                  },
                }
              : undefined
          }
        />
      )}
      {shownTypes.map((key) => {
        const Icon = typeIcons[key];
        return (
          <IconButton
            key={key}
            color={key === type ? 'secondary' : 'inherit'}
            sx={(theme) =>
              squashed
                ? {
                    width: 24,
                    height: 24,
                    [theme.breakpoints.down('sm')]: {
                      // margin: -12,
                      padding: 2, // ultimately controls the space left/right of the icons
                    },
                  }
                : undefined
            }
            onClick={() => onTypeChange(key)}
            title={_.startCase(key)}
            size="large"
          >
            <Icon />
          </IconButton>
        );
      })}
    </Box>
  );
}

export default TypeSelectorBar;
