import {
  Avatar,
  Box,
  Button,
  Paper,
  CardActions,
  CardHeader,
  Divider,
  ListSubheader,
  Typography,
} from '@mui/material';
import { DataUsage as DataUsageIcon } from '@mui/icons-material';
import moment from 'moment';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import _ from 'lodash';
import { useSnackbar } from '../Snackbar';
import {
  FETCH_OBJECTIVE,
  FETCH_OBJECTIVE_SUCCESS,
  UPDATE_OBJECTIVE,
  CREATE_OBJECTIVE,
  DELETE_OBJECTIVE,
  FETCH_FEATURE,
  FETCH_LOCATION,
  FETCH_LOCATIONS,
  FETCH_PEOPLE,
  UPDATE_ATTENDANCES_QUERY,
} from '../../actions';
import { doesIdExist } from '../../apis/utilities';
import { ConfirmationDialog } from '../dialogs';
import { Section } from '../controls';
import {
  Field,
  TextField,
  SelectField,
  ContentField,
  DateField,
  BoundaryField,
  GroupsField,
  WeeklyScheduleField,
  required,
  requiredInclusiveDateAfter,
  requiredInclusiveDateBefore,
  validateIdentifier,
  StyledField,
} from '../fields';

import { useAuth } from '../Auth';
import BoundaryFields from './BoundaryFields';

const { maxUploadSize, occurrenceNumberOnObjectives, personGroups } =
  window.config;

const mutators = {
  clearValue: ([name], state, { changeValue }) => {
    changeValue(state, name, () => undefined);
  },
  resetFilter: ({ 1: name }, state, { changeValue }) => {
    function wipeSelections(filter) {
      delete filter.value;
      return filter;
    }

    changeValue(state, name, wipeSelections);
  },
};

export default function Objective() {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const objective = useSelector(
    (state) => state.objectives.objective,
    _.isEqual
  );
  const error = useSelector((state) => state.objectives.error);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const snackbar = useSnackbar();
  const auth = useAuth();
  const canEdit = auth.isAuthorised('objectives', true);

  useEffect(() => {
    if (error) {
      snackbar.notify('error', error);
    }
  }, [error, snackbar]);

  useEffect(() => {
    if (id === 'new') {
      dispatch({
        type: FETCH_OBJECTIVE_SUCCESS,
        payload: null,
      });
    } else {
      dispatch({
        type: FETCH_OBJECTIVE,
        payload: id,
      });
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (
      objective &&
      objective.boundaryType === 'Location' &&
      objective.boundarySubtype
    ) {
      dispatch({
        type: FETCH_LOCATIONS,
        payload: objective.boundarySubtype,
      });

      if (objective.boundaryIdentifier) {
        dispatch({
          type: FETCH_LOCATION,
          payload: objective.boundaryIdentifier,
        });
      }
    }

    if (
      objective &&
      objective.boundaryType === 'Perimeter' &&
      objective.boundaryIdentifier
    ) {
      dispatch({
        type: FETCH_FEATURE,
        payload: objective.boundaryIdentifier,
      });
    }
  }, [objective, dispatch]);

  useEffect(() => {
    dispatch({
      type: FETCH_PEOPLE,
    });
  }, [dispatch]);

  function handleDelete() {
    if (objective) {
      dispatch({
        type: DELETE_OBJECTIVE,
        payload: objective.identifier,
      });
    }
  }

  async function validate(values) {
    const errors = {};

    if (!objective) {
      if (values.identifier) {
        errors.identifier = validateIdentifier(values.identifier);

        const exists = await doesIdExist('objectives', values.identifier);
        if (exists) {
          errors.identifier = 'Exists';
        }
      }
    }

    const blob = new Blob([JSON.stringify(values)], {
      type: 'application/json',
    });

    if (blob.size > maxUploadSize) {
      snackbar.notify('warning', 'Unable to save, content greater than 5MB');
      errors.description = 'Content greater than 5MB';
    }

    // start/end validation
    errors.startTime = requiredInclusiveDateBefore(values.endTime)(
      values.startTime
    );
    errors.endTime = requiredInclusiveDateAfter(values.startTime)(
      values.endTime
    );

    return errors;
  }

  function onSubmit(values) {
    if (objective) {
      dispatch({
        type: UPDATE_OBJECTIVE,
        payload: values,
      });
    } else {
      dispatch({
        type: CREATE_OBJECTIVE,
        payload: values,
        navigate,
      });
    }
  }

  function navigateToAttendances() {
    dispatch({
      type: UPDATE_ATTENDANCES_QUERY,
      payload: {
        'objective.identifier': {
          $in: [id],
        },
        startTime: {
          $lte: new Date(objective.endTime),
        },
        endTime: {
          $gte: new Date(objective.startTime),
        },
      },
    });

    navigate(`/events/people/attendances`);
  }

  return (
    <Form
      initialValues={objective || {}}
      validate={validate}
      mutators={mutators}
      onSubmit={onSubmit}
      render={({
        handleSubmit,
        form: { reset },
        submitting,
        dirty,
        pristine,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <Helmet>
            <title>
              IR3 | Objective{values.title ? ` | ${values.title}` : ''}
            </title>
          </Helmet>
          <Paper sx={{ m: 1, minWidth: 240 }}>
            <CardHeader
              avatar={
                <Avatar aria-label="Objective">
                  <DataUsageIcon />
                </Avatar>
              }
              title={values.title}
              subheader={values.identifier}
            />
            <Box>
              {values.created && (
                <Box sx={{ pl: 2 }}>
                  <Typography variant="caption">
                    {`Created by ${values.created.userId} ${moment(
                      values.created.time
                    ).format('DD/MM/YYYY, HH:mm')}`}
                  </Typography>
                </Box>
              )}
              {values.lastEdit && (
                <Box sx={{ pl: 2 }}>
                  <Typography variant="caption">
                    {`Last edited by ${values.lastEdit.userId} ${moment(
                      values.lastEdit.time
                    ).format('DD/MM/YYYY, HH:mm')}`}
                  </Typography>
                </Box>
              )}
              <ListSubheader disableSticky>Key Information</ListSubheader>
              <Section>
                <StyledField
                  name="title"
                  component={TextField}
                  label="Title"
                  validate={required}
                  disabled={!canEdit}
                />
                <StyledField
                  name="identifier"
                  component={TextField}
                  label="Identifier"
                  disabled={objective !== null || !canEdit}
                />
                {occurrenceNumberOnObjectives && (
                  <StyledField
                    name="occurrenceNumber"
                    component={TextField}
                    label="Occurrence Number"
                    disabled={!canEdit}
                  />
                )}
              </Section>
              <Divider />
              <ListSubheader disableSticky>Description</ListSubheader>
              <Section>
                <Field
                  name="description"
                  component={ContentField}
                  sx={{ flex: 1, mb: 1 }}
                  validate={required}
                  disabled={!canEdit}
                />
              </Section>
              <Divider />
              <ListSubheader disableSticky>Active Time</ListSubheader>
              <Section>
                <StyledField
                  name="startTime"
                  component={DateField}
                  label="Start Date"
                  maxDate={new Date(values.endTime) || new Date('2100-01-01')}
                  disabled={!canEdit}
                />
                <StyledField
                  name="endTime"
                  component={DateField}
                  label="End Date"
                  minDate={new Date(values.startTime) || new Date('1900-01-01')}
                  disabled={!canEdit}
                />
              </Section>
              <Divider />
              <ListSubheader disableSticky>Weekly Schedule</ListSubheader>
              <Section>
                <Field
                  sx={{ pb: 1 }}
                  name="schedule"
                  component={WeeklyScheduleField}
                  disabled={!canEdit}
                />
              </Section>
              <Divider />
              <ListSubheader disableSticky>Requirements</ListSubheader>
              <Section>
                <StyledField
                  name="requiredVisits"
                  component={TextField}
                  type="number"
                  label="Number of Visits"
                />
                <StyledField
                  name="requiredFrequency"
                  component={SelectField}
                  label="Frequency"
                  values={['total', 'daily', 'hourly'].map((item) => ({
                    value: item,
                    label: _.startCase(item),
                  }))}
                />
                <Divider
                  sx={{ mr: 1 }}
                  orientation="vertical"
                  variant="middle"
                  flexItem
                />
                <StyledField
                  name="complianceSeconds"
                  component={TextField}
                  type="number"
                  label="Compliant Minutes"
                  format={(value) =>
                    value == null || value === '' ? '' : value / 60
                  }
                  parse={(value) => (value === '' ? '' : value * 60)}
                  inputProps={{ min: 0 }}
                />
              </Section>
              <Divider />
              <ListSubheader disableSticky>Visible to</ListSubheader>
              <Section>
                <Field
                  name="visibleTo"
                  groups={personGroups}
                  component={GroupsField}
                  disabled={!canEdit}
                />
              </Section>
              <Divider />
              <ListSubheader disableSticky>Applicable to</ListSubheader>
              <Section>
                <Field
                  name="applicableTo"
                  groups={personGroups}
                  component={GroupsField}
                  disabled={!canEdit}
                />
              </Section>
              <Divider />
              <ListSubheader disableSticky>Boundary</ListSubheader>
              <Section>
                <StyledField
                  name="boundaryType"
                  component={SelectField}
                  label="Type"
                  validate={required}
                  values={['Perimeter', 'Location', 'Custom'].map((item) => {
                    return { value: item, label: item };
                  })}
                  onChange={() => {
                    mutators.clearValue('boundarySubtype');
                    mutators.clearValue('boundaryIdentifier');
                    mutators.clearValue('boundary');
                  }}
                  disabled={!canEdit}
                />
                {values.boundaryType !== 'Custom' && (
                  <BoundaryFields
                    type={values.boundaryType}
                    subtype={values.boundarySubtype}
                    mutators={mutators}
                    disabled={!canEdit}
                  />
                )}
              </Section>
              <BoundaryField type={values.boundaryType} disabled={!canEdit} />
            </Box>
            <CardActions disableSpacing>
              {canEdit && (
                <Button
                  color="primary"
                  type="submit"
                  disabled={pristine || submitting}
                >
                  Save
                </Button>
              )}
              {canEdit && (
                <Button
                  color="primary"
                  disabled={pristine || submitting}
                  onClick={reset}
                >
                  Cancel
                </Button>
              )}
              <Button
                color="primary"
                onClick={navigateToAttendances}
                disabled={dirty || submitting || objective === null}
              >
                Attendances
              </Button>
              {canEdit && (
                <Button
                  color="error"
                  onClick={() => setDeleteOpen(true)}
                  disabled={objective === null}
                >
                  Delete
                </Button>
              )}
            </CardActions>
          </Paper>
          <ConfirmationDialog
            action="Delete"
            open={deleteOpen}
            itemId={values.title || values.identifier}
            onOk={handleDelete}
            onCancel={() => setDeleteOpen(false)}
          />
        </form>
      )}
    />
  );
}
