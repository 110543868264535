import { Fragment } from 'react';
import { Autocomplete, InputAdornment, TextField } from '@mui/material';
import { SelectMultiple, Adornment } from '.';

const conditions = [
  { label: '=', value: '$eq' },
  { label: String.fromCharCode(8800), value: '$ne' }, // != so chrome can debug
  { label: '>', value: '$gt' },
  { label: String.fromCharCode(8805), value: '$gte' }, // >= so chrome can debug
  { label: '<', value: '$lt' },
  { label: String.fromCharCode(8804), value: '$lte' }, // <= so chrome can debug
];

const units = [
  { label: 's', value: 's' },
  { label: 'm', value: 'm' },
  { label: 'h', value: 'h' },
  { label: 'd', value: 'd' },
];

function milesToKilometres(value) {
  if (value === undefined || value === '') {
    return '';
  }

  return parseFloat(value) * 1.609344;
}

function kilometresToMiles(value) {
  if (value === undefined || value === '') {
    return '';
  }

  return Math.round(parseFloat(value) * 0.62137119);
}

function parseFloatOrBlank(value) {
  value = parseFloat(value);
  if (isNaN(value)) {
    return '';
  }

  return value;
}

const NumericTextField = (props) => (
  <TextField
    size="small"
    {...props}
    onChange={(event) => props.onChange(parseFloatOrBlank(event.target.value))}
  />
);

export default function Filter({
  type,
  values,
  unit,
  filterOptions,
  onlyEqual,
  parse,
  format,
  anyOption,
  noneOption,
  labelValue,
  onChange,
  value,
  condition = '$eq',
  label = 'Value',
}) {
  label = label ?? (type === 'multiselect' ? 'Values' : 'Value');

  switch (type) {
    case 'number':
      return (
        <Fragment>
          <NumericTextField
            onChange={(value) => onChange(parse?.(value) ?? value, condition)}
            value={format ? format(value) : value ?? ''}
            label={label}
            type="number"
            InputProps={{
              startAdornment: onlyEqual ? undefined : (
                <Adornment
                  value={condition}
                  values={conditions}
                  onChange={(condition) => onChange(value, condition)}
                  position="start"
                />
              ),
              endAdornment: unit ? (
                <InputAdornment position="end">{unit}</InputAdornment>
              ) : undefined,
            }}
          />
        </Fragment>
      );
    case 'miles':
      return (
        <Fragment>
          <NumericTextField
            onChange={(miles) => onChange(milesToKilometres(miles), condition)}
            value={value ? kilometresToMiles(value) : ''}
            label={label}
            type="number"
            InputProps={{
              startAdornment: (
                <Adornment
                  value={condition}
                  values={conditions}
                  onChange={(condition) => onChange(value, condition)}
                  position="start"
                />
              ),
              endAdornment: unit ? (
                <InputAdornment position="end">
                  {unit ?? 'miles'}
                </InputAdornment>
              ) : undefined,
            }}
          />
        </Fragment>
      );
    case 'duration':
      return (
        <Fragment>
          <NumericTextField
            onChange={(value) => onChange(value, condition, unit)}
            value={value ?? ''}
            label={label}
            type="number"
            InputProps={{
              startAdornment: (
                <Adornment
                  value={condition}
                  values={conditions}
                  onChange={(condition) => onChange(value, condition, unit)}
                  position="start"
                />
              ),
              endAdornment: (
                <Adornment
                  value={unit ?? 's'}
                  values={units}
                  onChange={(unit) => onChange(value, condition, unit)}
                  position="end"
                />
              ),
            }}
          />
        </Fragment>
      );
    case 'select':
      return (
        <Autocomplete
          size="small"
          value={values.find((o) => o.value === value) ?? null}
          options={values}
          getOptionLabel={(option) => option.label}
          onChange={(i, option) => onChange(option?.value ?? null)}
          renderInput={(params) => (
            <TextField
              size="small"
              {...params}
              sx={{ flex: 1 }}
              label={label}
            />
          )}
        />
      );
    case 'multiselect':
    default:
      return (
        <SelectMultiple
          size="small"
          onChange={onChange}
          value={value}
          label={label}
          suggestions={values || []}
          filterOptions={filterOptions}
          anyOption={anyOption}
          noneOption={noneOption}
          labelValue={labelValue}
        />
      );
  }
}
