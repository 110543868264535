import { Box, Paper, Toolbar, Typography } from '@mui/material';
import _ from 'lodash';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import Container from '../Container';
import { FilterPicker, Parameters, TablePagination, Table } from '../controls';
import { useSnackbar } from '../Snackbar';
import EventPreviewMap from '../controls/EventPreviewMap';
import { filterLocally } from '../../data/utilities';
import ChunkedDownloader from './ChunkedDownloader';
import usePaging from './usePaging';

const {
  rowsPerPageOptions,
  events: { eventFilters: { stops: eventFilters = [] } = {} } = {},
} = window.config;
const { extraStopHeaders } = window.reportHeaders;

const headers = [
  ...extraStopHeaders,
  {
    label: 'Start Time',
    key: 'startTime',
    type: 'date',
    filter: false,
  },
  {
    label: 'End Time',
    key: 'endTime',
    type: 'date',
    filter: false,
  },
  {
    label: 'Duration (minutes)',
    key: 'durationMinutes',
    serverKey: 'durationSeconds',
    type: 'number',
    filter: false,
    // Footer: _.round(
    //   filteredstops
    //     .map((stop) => stop.durationMinutes)
    //     .reduce((a, b) => a + b, 0) || 0,
    //   2
    // ).toString(),
  },
  {
    label: 'Home Location',
    key: 'vehicle.homeStation',
    type: 'text',
    filter: true,
  },
  { label: 'Location Name', key: 'location.name', type: 'text', filter: true },
  { label: 'Location Type', key: 'location.type', type: 'text', filter: true },
];

function Event({ entry }) {
  return (
    <Box sx={{ display: 'flex', px: 2, pb: 2 }}>
      <Box sx={{ width: 320, height: 320 }}>
        {entry.point && <EventPreviewMap point={entry.point} mapType="stops" />}
      </Box>
    </Box>
  );
}

export default function Stops() {
  const isLoading = useSelector((state) => state.events.stops.isLoading);
  const error = useSelector((state) => state.events.stops.error);
  const stops = useSelector((state) => state.events.stops.list, _.isEqual);
  const totals = useSelector((state) => state.events.stops.totals);
  const filter = useSelector((state) => state.events.stops.filter, _.isEqual);
  const query = useSelector((state) => state.events.stops.query, _.isEqual);
  const total = useSelector((state) => state.events.stops.total);
  const snackbar = useSnackbar();
  const eventType = 'stops';

  const paging = usePaging({ eventType, headers });

  useEffect(() => {
    if (error) {
      snackbar.notify('error', error);
    }
  }, [error, snackbar]);

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      paging.updateFilter({
        selectedIdentifiers: filterLocally(filter, stops).map(
          (n) => n.identifier
        ),
      });
    } else {
      paging.updateFilter({
        selectedIdentifiers: [],
      });
    }
  }

  function handleSelectClick(id) {
    const index = filter.selectedIdentifiers.indexOf(id);

    if (index === -1) {
      paging.updateFilter({
        selectedIdentifiers: filter.selectedIdentifiers.concat(id),
      });
    } else {
      paging.updateFilter({
        selectedIdentifiers: filter.selectedIdentifiers
          .slice(0, index)
          .concat(filter.selectedIdentifiers.slice(index + 1)),
      });
    }
  }

  // const filteredStops = filterLocally(filter, stops);

  const filename = 'Vehicle Stops.csv';
  const csvMapping = (stop) => ({
    ...stop,
    startTime: new Date(stop.startTime),
    endTime: new Date(stop.endTime),
  });

  return (
    <Container title="Stops">
      <Parameters
        onFetch={paging.handleFetch}
        onCancel={paging.handleCancel}
        isFetching={isLoading}
        value={query}
        onChange={paging.handleQueryChange}
        sx={{ mt: 1, width: 264 }}
        vehicle
        lastDriver
        eventFilters={eventFilters}
      />
      <Box
        sx={{
          flex: 1,
          height: 'calc(100vh - 48px)',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <Helmet>
          <title>IR3 | Stops</title>
        </Helmet>
        <Toolbar variant="dense" disableGutters sx={{ p: 1, pb: 0 }}>
          <Typography sx={{ flexGrow: 1 }} variant="subtitle1">
            Stops
          </Typography>
          <FilterPicker
            headers={headers}
            data={stops}
            filter={filter}
            filterValues={filter.filterValues}
            onFilterChange={paging.onFilterChange}
          />
          <ChunkedDownloader
            eventType={eventType}
            filename={filename}
            headers={headers}
            csvMapping={csvMapping}
          />
        </Toolbar>
        <Paper sx={{ m: [0, 1, 1], minWidth: 240 }}>
          <Box sx={isLoading ? { opacity: 0.5 } : undefined}>
            <Table
              styles={{
                tableContainer: {
                  height: 'calc(100vh - 172px)',
                  overflowY: 'scroll',
                },
                table: {
                  minWidth: 750,
                },
              }}
              data={stops} //{filteredStops}
              headers={[
                {
                  label: '',
                  key: 'expand',
                  type: 'expand',
                  component: Event,
                },
                ...headers,
              ]}
              rowsPerPage={filter.rowsPerPage}
              page={0} //{filter.page} // server-side pagination will fake the page we're on
              keyName="identifier"
              selectedKeys={filter.selectedIdentifiers}
              onSelectAllClick={handleSelectAllClick}
              onSelectClick={handleSelectClick}
              order={filter.order}
              orderBy={filter.orderBy}
              onOrderChange={paging.handleOrderChange}
              onOrderByChange={paging.handleOrderByChange}
              totals={totals}
            />
          </Box>
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={total ?? 0} //{filteredStops.length}
            rowsPerPage={filter.rowsPerPage}
            page={filter.page}
            onPageChange={paging.handlePageChange}
            onRowsPerPageChange={paging.handleRowsPerPageChange}
          />
        </Paper>
      </Box>
    </Container>
  );
}
