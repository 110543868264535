import { GroupsPicker } from '../controls';

export default function GroupsField({
  input: { value, onChange },
  groups,
  disabled,
  readOnly,
}) {
  return (
    <GroupsPicker
      value={value}
      onChange={onChange}
      groups={groups}
      disabled={disabled}
      readOnly={readOnly}
    />
  );
}
