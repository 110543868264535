import _ from 'lodash';
import {
  END_LIVE_STREAM_SUCCESS,
  FETCH_LIVE_LOCATION_LOOKUPS_SUCCESS,
  FETCH_FEATURE_COLLECTION,
  FETCH_FEATURE_COLLECTION_FAILURE,
  FETCH_FEATURE_COLLECTION_SUCCESS,
  FETCH_LIVE_INCIDENT,
  FETCH_LIVE_INCIDENT_SUCCESS,
  FETCH_LIVE_LOCATION_SUCCESS,
  FETCH_LIVE_VEHICLE_SUCCESS,
  FETCH_LIVE_ESTIMATED_TIME_OF_ARRIVAL_SUCCESS,
  FETCH_LIVE_PERSON_SUCCESS,
  FETCH_LIVE_INCIDENT_FAILURE,
  FETCH_LIVE_LOCATION_FAILURE,
  FETCH_LIVE_VEHICLE_FAILURE,
  FETCH_LIVE_ESTIMATED_TIME_OF_ARRIVAL_FAILURE,
  DELETE_LIVE_ESTIMATED_TIME_OF_ARRIVAL,
  FETCH_LIVE_PERSON_FAILURE,
  FILTER_LIVE_LIST,
  INVALIDATE_FEATURE_COLLECTION,
  START_LIVE_STREAM,
  START_LIVE_STREAM_SUCCESS,
  START_LIVE_STREAM_FAILURE,
  LIVE_STREAM_WARNING,
  UPDATE_LIVE_ADVANCED_FILTERS,
  UPDATE_LIVE_FOLLOWED,
  UPDATE_LIVE_FILTER_OVERRIDE,
  UPDATE_LIVE_FOLLOW_OVERRIDE,
  UPDATE_LIVE_LAYER_VISIBILITIES,
  UPDATE_LIVE_RESOURCES,
  // UPDATE_LIVE_FILTERS,
  UPDATE_LIVE_SEARCHTEXTS,
  UPDATE_LIVE_SORTS,
  FETCH_LIVE_VIEWS_SUCCESS,
  FETCH_LIVE_VIEWS_FAILURE,
  ADD_LIVE_VIEW_FAILURE,
  DELETE_LIVE_VIEW_FAILURE,
  UPDATE_LIVE_VIEW_KEY,
  UPDATE_LIVE_SETTINGS,
  ADD_TAG_ITEM,
  ADD_TAG_ITEM_SUCCESS,
  ADD_TAG_ITEM_FAILURE,
  DELETE_TAG_ITEM,
  DELETE_TAG_ITEM_SUCCESS,
  DELETE_TAG_ITEM_FAILURE,
  CREATE_TAG,
  CREATE_TAG_SUCCESS,
  CREATE_TAG_FAILURE,
  FETCH_TAGS,
  FETCH_TAGS_SUCCESS,
  DELETE_TAG_SUCCESS,
  DELETE_TAG_FAILURE,
  UPDATE_TAG_SUCCESS,
  UPDATE_TAG_FAILURE,
  UPDATE_LIVE_STATUS,
  UPDATE_LIVE_RESOURCES_IN_LOCATIONS,
} from '../actions/types';
import { liveFilters, FOLLOW_TAG } from '../data/constants';
import {
  addTagItem,
  addResourceInLocations,
  deleteTagItem,
  createLiveTag,
  getUserTags,
} from '../components/live/utilities';

const lf = liveFilters;

const LAYER_VISIBILITIES = 'liveLayerVisibilities';
const ADVANCED_FILTERS = 'liveAdvancedFilters';
const SORTS = 'liveSorts';
const SEARCH_TEXTS = 'liveSearchTexts';
const SETTINGS = 'liveSettings';
const VIEW = 'liveView';

const storedState = {
  layerVisibilities: localStorage.getItem(LAYER_VISIBILITIES),
  searchTexts: localStorage.getItem(SEARCH_TEXTS),
  advancedFilters: localStorage.getItem(ADVANCED_FILTERS),
  sorts: localStorage.getItem(SORTS),
  settings: localStorage.getItem(SETTINGS),
  view: localStorage.getItem(VIEW),
};

function getStored(stored, defaultValue) {
  try {
    return (stored && JSON.parse(stored)) || defaultValue;
  } catch {
    return defaultValue;
  }
}

const INITIAL_STATE = {
  people: {},
  vehicles: {},
  incidents: {},
  events: {},
  accelerometerEvents: {},
  plans: {},
  locations: {},
  callSigns: {},
  tasks: {},
  objectives: {},
  filteredInIdsByType: {},
  filteredInIdsByTypeOverride: null,
  enabled: true,
  followedIdsByType: {},
  followedIdsByTypeOverride: null,
  searchTexts: getStored(storedState.searchTexts, {}),
  layerVisibilities: getStored(storedState.layerVisibilities, {}),
  // advancedFilters look like this:
  // { vehicles: [{field: 'role', condition: '$eq', value: ['Beat', 'CID']}]}
  advancedFilters: getStored(storedState.advancedFilters, {}),
  filters: Object.fromEntries(
    Object.keys(lf).map((type) => [
      type,
      Object.fromEntries(
        Object.keys(lf[type]).map((key) => [lf[type][key].value, []])
      ),
    ])
  ),
  sorts: getStored(storedState.sorts, {}),
  socketError: null,
  socketWarning: null,
  planFeatures: null,
  generalError: null,
  vehicleRecordsById: {},
  estimatedTimeOfArrivals: [],
  personRecordsById: {},
  locationRecordsById: {},
  tags: {
    [FOLLOW_TAG]: {
      id: FOLLOW_TAG,
      searchString: FOLLOW_TAG.toLowerCase(),
      itemsByType: {},
      type: 'follow',
    },
  },
  liveViews: undefined,
  selectedLiveViewIdentifier: getStored(storedState.view, undefined),
  selectedLiveViewKey: 0,
  settings: getStored(storedState.settings, {
    // showLabels: false,
    // centre map on select
    // follow halo
    // hide filtered/stale
    // high contrast labels/colour labels to match icon
    // diff parked icon?
    // show filtered labels
  }),
  subscribing: false,
  status: undefined,
};

export default function liveReducer(state = INITIAL_STATE, action) {
  if (!state.enabled) {
    return state;
  }

  // console.log(action.type);
  switch (action.type) {
    case UPDATE_LIVE_RESOURCES:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_LIVE_RESOURCES_IN_LOCATIONS:
      addResourceInLocations(state, 'vehicles');
      addResourceInLocations(state, 'people');
      return {
        ...state,
      };
    case FILTER_LIVE_LIST:
      return {
        ...state,
        filteredInIdsByType: action.payload,
      };
    case UPDATE_LIVE_FILTER_OVERRIDE:
      return {
        ...state,
        filteredInIdsByTypeOverride: action.payload,
      };
    case UPDATE_LIVE_FOLLOW_OVERRIDE:
      return {
        ...state,
        followedIdsByTypeOverride: action.payload,
      };
    case UPDATE_LIVE_FOLLOWED:
      return {
        ...state,
        followedIdsByType: action.payload,
        tags: {
          ...state.tags,
          [FOLLOW_TAG]: {
            id: FOLLOW_TAG,
            searchString: FOLLOW_TAG.toLowerCase(),
            itemsByType: action.payload,
            type: 'follow',
          },
        },
      };
    // case UPDATE_LIVE_FILTERS:
    //   return { ...state, filters: action.payload };
    case UPDATE_LIVE_SEARCHTEXTS:
      localStorage.setItem(SEARCH_TEXTS, JSON.stringify(action.payload));
      return { ...state, searchTexts: action.payload };
    case UPDATE_LIVE_LAYER_VISIBILITIES:
      localStorage.setItem(LAYER_VISIBILITIES, JSON.stringify(action.payload));
      return { ...state, layerVisibilities: action.payload };
    case START_LIVE_STREAM:
      return {
        ...state,
        subscribing: true,
        //status: "Connecting to live data, please wait..."
      };
    case START_LIVE_STREAM_SUCCESS:
      return {
        ...state,
        subscribing: false,
        //status: "Connected to live data."
      };
    case START_LIVE_STREAM_FAILURE:
      return { ...state, socketError: action.payload, subscribing: false };
    case LIVE_STREAM_WARNING:
      return { ...state, socketWarning: action.payload };
    case FETCH_LIVE_INCIDENT:
    case FETCH_LIVE_INCIDENT_FAILURE:
    case FETCH_LIVE_INCIDENT_SUCCESS:
      return {
        ...state,
        incidents: {
          ...state.incidents,
          [action.payload.id]: action.payload,
        },
      };
    case FETCH_LIVE_VEHICLE_SUCCESS:
      return {
        ...state,
        vehicleRecordsById: {
          //_.mapKeys(action.payload, 'identificationNumber'),
          ...state.vehicleRecordsById,
          [action.payload.identificationNumber]: action.payload,
        },
      };
    case FETCH_LIVE_ESTIMATED_TIME_OF_ARRIVAL_SUCCESS:
      const removeExistingVin = state.estimatedTimeOfArrivals.filter(
        (eta) => eta?.vin !== action.payload?.vin
      );
      return {
        ...state,
        estimatedTimeOfArrivals: [...removeExistingVin, action.payload],
      };
    case DELETE_LIVE_ESTIMATED_TIME_OF_ARRIVAL:
      const vehiclesToRemove = action.payload;
      const newEtas = vehiclesToRemove
        ? state.estimatedTimeOfArrivals.filter(
            (eta) => !vehiclesToRemove.includes(eta?.vin)
          )
        : [];
      return {
        ...state,
        estimatedTimeOfArrivals: newEtas,
      };
    case FETCH_LIVE_PERSON_SUCCESS:
      return {
        ...state,
        personRecordsById: {
          // _.mapKeys(action.payload, 'code'),
          ...state.personRecordsById,
          [action.payload.code]: action.payload,
        },
      };
    case FETCH_LIVE_LOCATION_SUCCESS:
      return {
        ...state,
        locationRecordsById: {
          //_.mapKeys(action.payload, 'code'),
          ...state.locationRecordsById,
          [action.payload.code]: action.payload,
        },
      };
    case UPDATE_LIVE_VIEW_KEY:
      action.payload
        ? localStorage.setItem(VIEW, JSON.stringify(action.payload))
        : localStorage.removeItem(VIEW);
      return {
        ...state,
        selectedLiveViewIdentifier: action.payload,
        selectedLiveViewKey: state.selectedLiveViewKey + 1,
      };
    case UPDATE_LIVE_ADVANCED_FILTERS:
      localStorage.setItem(ADVANCED_FILTERS, JSON.stringify(action.payload));
      return {
        ...state,
        advancedFilters: action.payload,
      };
    case UPDATE_LIVE_SORTS:
      localStorage.setItem(SORTS, JSON.stringify(action.payload));
      return { ...state, sorts: action.payload };
    case END_LIVE_STREAM_SUCCESS:
      // console.log('End stream', action.payload);
      // wipe the resources
      return { ...state, [action.payload]: {} };
    case FETCH_FEATURE_COLLECTION:
      return { ...state, generalError: null };
    case FETCH_FEATURE_COLLECTION_SUCCESS:
      return {
        ...state,
        planFeatures: action.payload.features,
        generalError: null,
      };
    case FETCH_FEATURE_COLLECTION_FAILURE:
      return {
        ...state,
        planFeatures: null,
        generalError: action.payload,
      };
    case INVALIDATE_FEATURE_COLLECTION:
      return {
        ...state,
        planFeatures: null,
        generalError: null,
      };
    case FETCH_LIVE_VEHICLE_FAILURE:
      return {
        ...state,
        generalError: `Error fetching vehicle ${action.payload}`,
      };
    case FETCH_LIVE_ESTIMATED_TIME_OF_ARRIVAL_FAILURE:
      return {
        ...state,
        generalError: `Error fetching ETA ${action.payload}`,
      };
    case FETCH_LIVE_PERSON_FAILURE:
      return {
        ...state,
        generalError: `Error fetching person ${action.payload}`,
      };
    case FETCH_LIVE_LOCATION_FAILURE:
      return {
        ...state,
        generalError: `Error fetching location ${action.payload}`,
      };
    case FETCH_LIVE_VIEWS_FAILURE:
      return {
        ...state,
        generalError: `Error fetching views ${action.payload}`,
        liveViews: state.liveViews ? state.liveViews : [],
      };
    case DELETE_LIVE_VIEW_FAILURE:
      return {
        ...state,
        generalError: `Error deleting view ${action.payload}`,
      };
    case ADD_LIVE_VIEW_FAILURE:
      return {
        ...state,
        generalError: `Error adding view ${action.payload}`,
      };

    // tags
    case ADD_TAG_ITEM:
    case ADD_TAG_ITEM_SUCCESS: {
      const { type, value: id, id: tag } = action.payload;
      return {
        ...state,
        tags: addTagItem(state.tags, type, id, tag),
      };
    }
    case ADD_TAG_ITEM_FAILURE:
      return {
        ...state,
        generalError: `Error adding new tag ${action.payload}`,
      };
    case DELETE_TAG_ITEM:
    case DELETE_TAG_ITEM_SUCCESS: {
      const { type, value: id, id: tag } = action.payload;
      return {
        ...state,
        tags: deleteTagItem(state.tags, type, id, tag),
      };
    }
    case DELETE_TAG_ITEM_FAILURE: {
      const { message, type, id, tag, op } = action.payload;
      return {
        ...state,
        generalError: `Error ${op} ${tag} tag on ${type}/${id}: ${message}`,
      };
    }
    case CREATE_TAG:
    case CREATE_TAG_SUCCESS: {
      const { items, identifier: tag } = action.payload;
      if (items) {
        const type = Object.keys(items);
        const id = items[type[0]];
        return {
          ...state,
          tags: addTagItem(state.tags, type, id, tag),
        };
      }
      return { ...state };
    }
    case CREATE_TAG_FAILURE:
      return {
        ...state,
        generalError: `Error adding new tag ${action.payload}`,
      };
    case FETCH_TAGS:
    case FETCH_TAGS_SUCCESS: {
      const userTags = getUserTags(action.payload);
      let liveTags = { ...state.tags };
      if (userTags.length > 0) {
        userTags.forEach((t) => {
          const liveTag = createLiveTag(liveTags, t.type, t.id, t.tag);
          // add user's pre-selected tags only once and when its empty.
          if (_.has(state.tags, t.tag)) {
            return;
          }
          liveTags = { ...liveTags, [t.tag]: liveTag };
        });
      }
      return {
        ...state,
        tags: { ...liveTags },
      };
    }
    case DELETE_TAG_SUCCESS: {
      const newTags = { ...state.tags };
      delete newTags[action.payload];
      return {
        ...state,
        tags: { ...newTags },
      };
    }
    case DELETE_TAG_FAILURE:
      return {
        ...state,
        generalError: `Error deleting tag ${action.payload}`,
      };
    case UPDATE_TAG_SUCCESS: {
      const userTags = getUserTags([action.payload]);
      let liveTags = { ...state.tags };
      delete liveTags[action.payload.identifier];
      userTags.forEach((t) => {
        const liveTag = createLiveTag(liveTags, t.type, t.id, t.tag);
        liveTags = { ...liveTags, [t.tag]: liveTag };
      });
      return {
        ...state,
        tags: { ...liveTags },
      };
    }
    case UPDATE_TAG_FAILURE:
      return {
        ...state,
        generalError: `Error adding new tag ${action.payload}`,
      };
    // End tags

    case FETCH_LIVE_VIEWS_SUCCESS:
      return {
        ...state,
        liveViews: action.payload,
      };
    case UPDATE_LIVE_SETTINGS:
      const settings = {
        ...state.settings,
        ...action.payload,
      };
      localStorage.setItem(SETTINGS, JSON.stringify(settings));

      return {
        ...state,
        settings,
      };
    case UPDATE_LIVE_STATUS:
      return {
        ...state,
        status: action.payload,
      };
    case FETCH_LIVE_LOCATION_LOOKUPS_SUCCESS:
      return {
        ...state,
        locations: action.payload,
      };
    default:
      return state;
  }
}
