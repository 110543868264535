import { Fragment, useState } from 'react';
import { Box, Button, IconButton, Popover } from '@mui/material';
import {
  Autorenew as AutorenewIcon,
  GetApp as GetAppIcon,
} from '@mui/icons-material';
import { downloadCSV, downloadGeoJSON } from '../../apis/utilities';
import { spin } from '../../data/utilities';

export default function ExportPicker({
  fetchDataToConvert,
  filename,
  headers,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isFetchingCSV, setFetchingCSV] = useState(false);
  const [isFetchingGeoJSON, setFetchingGeoJSON] = useState(false);

  function handleClick(event) {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }

  function handleClose(event) {
    event.stopPropagation();
    setAnchorEl(null);
  }

  async function handleCSVClick(event) {
    event.stopPropagation();
    setFetchingCSV(true);
    const data = await fetchDataToConvert();
    downloadCSV(data, `${filename}.csv`, headers);
    setFetchingCSV(false);
  }

  async function handleGeoJSONClick(event) {
    event.stopPropagation();
    setFetchingGeoJSON(true);
    const data = await fetchDataToConvert();
    downloadGeoJSON(data, `${filename}.json`);
    setFetchingGeoJSON(false);
  }

  const open = Boolean(anchorEl);
  const id = open ? 'type-popover' : undefined;

  return (
    <Fragment>
      <IconButton
        aria-describedby={id}
        onClick={handleClick}
        title={'Download Polls'}
        size="large"
      >
        <GetAppIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box sx={{ m: [1, 0, 1, 1] }}>
          <Button
            color="primary"
            title="CSV is a format you can use in spreadsheets"
            variant="contained"
            sx={
              isFetchingCSV
                ? {
                    mr: 1,
                    bgcolor: 'error.main',
                    '&:hover': { bgcolor: 'error.dark' },
                    color: 'common.white',
                  }
                : { mr: 1 }
            }
            onClick={handleCSVClick}
            endIcon={
              <AutorenewIcon
                sx={
                  isFetchingCSV
                    ? { animation: `${spin} 2s linear infinite` }
                    : undefined
                }
              />
            }
          >
            {isFetchingCSV ? 'Cancel' : 'CSV'}
          </Button>
          <Button
            color="secondary"
            title="GeoJSON is a format you can use in GIS applications"
            variant="contained"
            sx={
              isFetchingGeoJSON
                ? {
                    mr: 1,
                    bgcolor: 'error.main',
                    '&:hover': { bgcolor: 'error.dark' },
                    color: 'common.white',
                  }
                : { mr: 1 }
            }
            onClick={handleGeoJSONClick}
            endIcon={
              <AutorenewIcon
                sx={
                  isFetchingGeoJSON
                    ? {
                        animation: `${spin} 2s linear infinite`,
                      }
                    : undefined
                }
              />
            }
          >
            {isFetchingGeoJSON ? 'Cancel' : 'GEOJSON'}
          </Button>
        </Box>
      </Popover>
    </Fragment>
  );
}
