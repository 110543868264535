import { DateTimePicker } from '../controls';

export default function DateTimeField({
  input: { value, ...input },
  meta,
  ...props
}) {
  return (
    <DateTimePicker
      value={value || null}
      ampm={false}
      helperText={meta.touched && meta.error}
      error={meta.touched && meta.invalid}
      {...input}
      {...props}
    />
  );
}
