import { useState, createContext, useCallback, useContext } from 'react';
import { fetchOptions } from '../apis/utilities';

const OptionsContext = createContext();

// export const useOptions = () => useRef(useContext(OptionsContext)).current;
export const useOptions = () => useContext(OptionsContext);

export function OptionsProvider({ children }) {
  const [options, setOptions] = useState([]);

  const fetch = useCallback(async () => {
    const value = await fetchOptions();

    setOptions(value);
  }, []);

  return (
    <OptionsContext.Provider value={{ options, fetch }}>
      {children}
    </OptionsContext.Provider>
  );
}
