import { useLocation, useRoutes } from 'react-router-dom';
import { useAuth } from './Auth';
import { routes, isAllowed, isAuthorised } from '../routing';

export default function PermittedRoutes() {
  const auth = useAuth();
  const location = useLocation();

  let element = useRoutes(
    routes
      .filter(({ key, path }) => isAuthorised(key, path, auth, location))
      .filter(({ key }) => isAllowed(key, auth))
  );

  return element;
}
