import { Done as DoneIcon, DoneAll as DoneAllIcon } from '@mui/icons-material';
import { Avatar, Box, Checkbox } from '@mui/material';
import { enGB } from 'date-fns/locale';

function ScheduleIcon({ day, hour }) {
  const locale = enGB;

  if (day === -1 && hour === -1) {
    return <DoneAllIcon fontSize="inherit" />;
  }

  if (day === -1) {
    return hour;
  }

  if (hour === -1) {
    return locale.localize.day(day)[0];
  }

  return <DoneIcon fontSize="inherit" />;
}

export default function WeeklySchedulePicker({
  value,
  onChange,
  disabled,
  className,
  sx,
  scale = 1,
}) {
  const days = !!value
    ? value
    : Array(7)
        .fill()
        .map(() => Array(24).fill(false));

  function isAnyChecked() {
    return days.flat().some((hour) => hour);
  }

  function isDayChecked(day) {
    return days[day].some((hour) => hour);
  }

  function isHourChecked(hour) {
    return days.map((hours) => hours[hour]).some((hour) => hour);
  }

  function toggleAll() {
    onChange(
      Array(7)
        .fill()
        .map(() => Array(24).fill(!isAnyChecked()))
    );
  }

  function toggleDay(day) {
    onChange([
      ...days.slice(0, day),
      Array(24).fill(!isDayChecked(day)),
      ...days.slice(day + 1),
    ]);
  }

  function toggleHour(hour) {
    onChange([
      ...days.map((hours) => [
        ...hours.slice(0, hour),
        !isHourChecked(hour),
        ...hours.slice(hour + 1),
      ]),
    ]);
  }

  function toggle(day, hour) {
    onChange([
      ...days.slice(0, day),
      [
        ...days[day].slice(0, hour),
        !days[day][hour],
        ...days[day].slice(hour + 1),
      ],
      ...days.slice(day + 1),
    ]);
  }

  function isChecked(day, hour) {
    if (day === -1 && hour === -1) {
      return isAnyChecked();
    }

    if (day === -1) {
      return isHourChecked(hour);
    }

    if (hour === -1) {
      return isDayChecked(day);
    }

    return days[day][hour];
  }

  const handleChange = (day, hour) => () => {
    if (day === -1 && hour === -1) {
      toggleAll();
    } else if (day === -1) {
      toggleHour(hour);
    } else if (hour === -1) {
      toggleDay(day);
    } else {
      toggle(day, hour);
    }
  };

  return (
    <Box className={className} sx={sx}>
      {[...Array(8).keys()].map((_, row) => {
        const day = row - 1;

        return (
          <Box key={day} sx={{ lineHeight: 0 }}>
            {[...Array(25).keys()].map((_, column) => {
              const hour = column - 1;

              return (
                <Checkbox
                  key={hour}
                  disabled={disabled}
                  checked={isChecked(day, hour)}
                  onChange={handleChange(day, hour)}
                  value="all"
                  sx={{ p: 0.5 * scale }}
                  icon={
                    <Avatar
                      sx={{
                        width: 24 * scale,
                        height: 24 * scale,
                        fontSize: 12 * scale,
                      }}
                    >
                      <ScheduleIcon day={day} hour={hour} />
                    </Avatar>
                  }
                  checkedIcon={
                    <Avatar
                      sx={{
                        color:
                          day === -1 || hour === -1
                            ? 'primary.contrastText'
                            : 'secondary.contrastText',
                        bgcolor:
                          day === -1 || hour === -1
                            ? 'primary.main'
                            : 'secondary.main',
                        width: 24 * scale,
                        height: 24 * scale,
                        fontSize: 12 * scale,
                      }}
                    >
                      <ScheduleIcon day={day} hour={hour} />
                    </Avatar>
                  }
                />
              );
            })}
          </Box>
        );
      })}
    </Box>
  );
}
