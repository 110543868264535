import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { SelectMultiple, TablePagination, Table } from '../../../controls';
import {
  Avatar,
  Paper,
  CardHeader,
  Collapse,
  IconButton,
  TextField,
  Toolbar,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  useMediaQuery,
  Box,
  Stack,
} from '@mui/material';
import { red, amber } from '@mui/material/colors';
import {
  ArrowBack as ArrowBackIcon,
  FilterList as FilterListIcon,
  GetApp as GetAppIcon,
  Timer as TimerIcon,
  Autorenew as AutorenewIcon,
  Router as RouterIcon,
} from '@mui/icons-material';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { useSnackbar } from '../../../Snackbar';
import {
  UPDATE_TELEMATICS_BOXES_FILTER,
  // UPDATE_TELEMATICS_BOXES_SORT,
} from '../../../../actions';
import Container from '../../../Container';
import { TelematicsBoxMap } from '../../../controls';
import TelematicsBoxChart from './TelematicsBoxChart';
import {
  decodeSignalStrength,
  decodeGpsValidPollCount,
  signalStrengthIcon,
  OnOffIcon,
} from '../../../resources/TelematicsBoxList/utilities';
import { FETCH_TELEMATICS_BOXES } from '../../../../actions';
import { getPrimaryLocation, downloadCSV } from '../../../../apis/utilities';

const { rowsPerPageOptions } = window.config;

const staticStyles = {
  badValue: {
    backgroundcolor: red[500],
    color: '#FFF',
    padding: '3px',
  },
  warningValue: {
    backgroundColor: amber[500],
    color: '#FFF',
    padding: '3px',
  },
};

const getHeadersByType = (boxes, property) => {
  const headersByType = boxes.reduce((headers, box) => {
    Object.keys(box[property] || {}).forEach((key) => {
      if (!(key in headers)) {
        headers[key] = {
          label: _.startCase(key),
          key,
          accessor: (b) => b?.[property]?.[key], // for csv
          type: 'text',
          filterable: true,
        };
      }

      // have to copy the property to the main box object as
      // mui table doesn't have an accesor function
      box[key] = box[property][key];
    });

    return headers;
  }, {});

  return Object.values(headersByType);
};

// go through the data and create a header for each area type found
const getAreaHeaders = (boxes) => getHeadersByType(boxes, 'areas');
const memoisedAreaHeaders = _.memoize(getAreaHeaders);
// same for each location they are at
const getLocationHeaders = (boxes) => {
  const locationTypeSuggestions = [];
  const locationNameSuggestions = [];

  boxes.forEach((box) => {
    const primary = getPrimaryLocation(box.locations);
    locationTypeSuggestions.push((box.locationType = primary.type));
    locationNameSuggestions.push((box.locationName = primary.name));
  });

  return [
    {
      label: 'Location Type',
      key: 'locationType',
      accessor: (b) => b.locationType,
      suggestions: _.uniq(locationTypeSuggestions).sort(),
      type: 'text',
      filterable: true,
    },
    {
      label: 'Location Name',
      key: 'locationName',
      accessor: (b) => b.locationName,
      suggestions: _.uniq(locationNameSuggestions).sort(),
      type: 'text',
      filterable: true,
    },
  ];
};
const memoisedLocationHeaders = _.memoize(getLocationHeaders);

// create filters from the data getting all of the suggestions using the accessor
// const notNullOption = '<NOT NULL>';
function getFilters(boxes, headers) {
  const filters = headers
    .filter((header) => header.filterable)
    .map(({ key, label, accessor }) => {
      const suggestions = _.uniq(_.map(boxes, accessor || key)).sort();
      if (suggestions.includes(undefined)) {
        // get rid of the undefined one
        suggestions.splice(suggestions.indexOf(undefined), 1);
        // put the <NOT NULL> one at the start </NOT>
        // suggestions.unshift(notNullOption);
        // add a blank one so can select the undefined ones
        // suggestions.push('');
      }

      return {
        label,
        key,
        accessor: accessor || ((b) => b[key]),
        suggestions,
      };
    });

  return _.mapKeys(filters, 'key');
}
const memoisedGetFilters = _.memoize(getFilters);

const labelValue = (value) => ({ label: value, value });
const memoizedValues = _.memoize((x) => Object.values(x));

function gpsValidPollCountString(value) {
  if (value === -1) {
    return 'N/A';
  }

  return _.round(100 * value).toString() + '%';
}

function TelematicsBoxLink({ entry }) {
  const navigate = useNavigate();

  function handleClick() {
    navigate(`/resources/telematicsboxes/${entry.imei}`);
  }

  return (
    <IconButton title="View" aria-label="View" onClick={handleClick}>
      <RouterIcon />
    </IconButton>
  );
}

function DrillDown({ entry }) {
  return (
    <Box sx={{ display: 'flex', px: 2, pb: 2 }}>
      <Box sx={{ width: 320, height: 320 }}>
        <TelematicsBoxMap point={entry.lastPosition} />
      </Box>
      <Box sx={{ flex: 1, height: 320, minWidth: 320 }}>
        <TelematicsBoxChart
          imei={entry.imei}
          lastContact={entry.mostRecentTime}
        />
      </Box>
    </Box>
  );
}

const headers = [
  {
    label: 'IMEI',
    key: 'imei',
    type: 'text',
    filterable: true,
  },
  {
    label: 'Last Contact Time',
    key: 'mostRecentTime',
    type: 'date',
  },
  {
    label: 'Disposal Date',
    key: 'disposalDate',
    type: 'date',
  },
  {
    label: 'Fleet Number',
    key: 'fleetNumber',
    type: 'text',
    filterable: true,
  },
  {
    label: 'Registration',
    key: 'registrationNumber',
    type: 'text',
    filterable: true,
  },
  {
    label: 'Ignition',
    key: 'ignitionOn',
    type: 'boolean',
    format: (value) => (
      // <span>
      <OnOffIcon on={value} />
      // </span>
    ),
  },
  {
    label: 'Battery (V)',
    key: 'batteryVoltage',
    type: 'number',
    format: (value) => (
      <span
        style={
          value == null
            ? staticStyles.warningValue
            : value < 8.5
            ? staticStyles.badValue
            : {}
        }
      >
        {value == null ? 'N/A' : value}
      </span>
    ),
  },
  {
    label: 'Signal Strength',
    key: 'decodedSignalStrength',
    type: 'number',
    format: (value) => signalStrengthIcon(value, true),
  },
  {
    label: 'Valid GPS Rate',
    key: 'decodedGpsValidPollCount',
    type: 'number',
    format: (value) => (
      <span
        style={
          value === -1
            ? staticStyles.warningValue
            : value < 0.6
            ? staticStyles.badValue
            : {}
        }
      >
        {gpsValidPollCountString(value)}
      </span>
    ),
  },
];

export default function LastContact() {
  const navigate = useNavigate();

  const snackbar = useSnackbar();
  const error = useSelector((state) => state.telematicsBoxes.error, _.isEqual);
  useEffect(() => {
    if (error) {
      snackbar.notify('error', error);
    }
  }, [error, snackbar]);

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('Group');

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

  function handlePageChange(event, newPage) {
    setPage(newPage);
  }

  function handleRowsPerPageChange(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  function handleOrderChange(order) {
    setOrder(order);
  }

  function handleOrderByChange(orderBy) {
    setOrderBy(orderBy);
    setOrder('asc');
  }

  const isXs = useMediaQuery((theme) => theme.breakpoints.only('xs'));

  // get all the tbs from the app state & convert to array
  const telematicsBoxes = useSelector(
    (state) => state.telematicsBoxes.boxesByImei
  );

  // no accessor any more so go through and decode each
  // box's signalStrength and gps count
  Object.values(telematicsBoxes).forEach((box) => {
    box.decodedSignalStrength = decodeSignalStrength(box.signalStrength);
    box.decodedGpsValidPollCount = decodeGpsValidPollCount(
      box.gpsValidPollCount
    );
  });

  const boxes = memoizedValues(telematicsBoxes);

  // headers and filters are created from data (dynamic area & suggestions)
  const allHeaders = headers
    .concat(memoisedAreaHeaders(boxes))
    .concat(memoisedLocationHeaders(boxes));
  const filters = memoisedGetFilters(boxes, allHeaders);

  const dispatch = useDispatch();
  // get tbs when starting up
  useEffect(() => {
    dispatch({ type: FETCH_TELEMATICS_BOXES });
  }, [dispatch]);

  const isLoading = useSelector(
    (state) => state.telematicsBoxes.allBoxRequestInProgress
  );
  // const tableSort = useSelector((state) => state.telematicsBoxes.tableSort);
  // const updateTableSort = (tableSort) => {
  //   dispatch({
  //     type: UPDATE_TELEMATICS_BOXES_SORT,
  //     payload: tableSort,
  //   });
  // };

  const filterSelections = useSelector(
    (state) => state.telematicsBoxes.filterSelections
  );
  const [localLastContactDays, setLocalLastContactDays] = useState(
    filterSelections.lastContactDays
  );
  const updateFilterValues = (filterValues) => {
    dispatch({
      type: UPDATE_TELEMATICS_BOXES_FILTER,
      payload: filterValues,
    });
  };

  function handleLastContactDaysChange(event) {
    const days = parseInt(event.target.value) || 0;
    // pretend that lastContactDays is a server param only
    // updateFilterValues({
    //   ...filterSelections,
    //   lastContactDays: days < 0 ? 0 : days
    // });
    setLocalLastContactDays(days < 0 ? 0 : days);
  }

  function handleFilterFieldChanged(name, values) {
    updateFilterValues({
      ...filterSelections,
      [name]: values,
    });
  }

  function handleRefreshClick() {
    if (localLastContactDays !== filterSelections.lastContactDays) {
      updateFilterValues({
        ...filterSelections,
        lastContactDays: localLastContactDays,
      });
    }
    dispatch({ type: FETCH_TELEMATICS_BOXES });
  }

  // filter whenever the selections or the data changes
  const [filteredBoxes, setFilteredBoxes] = useState([]);
  useEffect(() => {
    const filteredBoxes = boxes.filter((box) =>
      // every filter must be true to let the box through
      Object.keys(filterSelections).every((key) => {
        if (key === 'lastContactDays') {
          // if the box doesn't have a time or the filter is set to 0, let it through
          // otherwise check the mostRecentTime is over the threshold set in days
          return (
            !box.mostRecentTime ||
            filterSelections.lastContactDays === 0 ||
            moment.utc().diff(moment(box.mostRecentTime), 'd') >
              filterSelections.lastContactDays
          );
        } else if (key === 'disposed') {
          const disposedSelection = filterSelections[key];

          switch (disposedSelection) {
            case 'Yes':
              return !!box.disposalDate;
            case 'No':
              return !box.disposalDate;
            default:
              return true;
          }
        } else {
          let value = filters[key].accessor(box) || box[key];

          // if they want items that have any value and it has a value
          if (filterSelections[key].includes('*Any') && !!value) {
            return true;
          }

          // if they want items that have no value and it has no value
          if (filterSelections[key].includes('*None') && !value) {
            return true;
          }

          // if there are no selections for this filter, let it through
          // otherwise check that the value for this property of the box
          // is included in the list of selections, if so let it through
          // otherwise if it's
          return (
            filterSelections[key].length === 0 ||
            filterSelections[key].includes(value ?? '')
          );
        }
      })
    );

    setFilteredBoxes(filteredBoxes);
  }, [filterSelections, boxes, filters]);

  const [showFilter, setShowFilter] = useState(false);
  function handleFilterToggle() {
    setShowFilter(!showFilter);
  }

  function handleBackClick() {
    navigate(-1);
  }

  function handleDownloadClick() {
    // unsortable headers (icon link) don't go in CSV download
    const headers = allHeaders.filter((f) => !(f.sortable === false));
    const dateFormat = 'YYYY-MM-DD';
    const filename = `Last Telematics Contact ${moment()
      .add(-filterSelections.lastContactDays, 'd')
      .format(dateFormat)} - ${moment().format(dateFormat)}.csv`;
    const data = filteredBoxes.map(
      ({
        imei,
        mostRecentTime,
        fleetNumber,
        registrationNumber,
        ignitionOn,
        batteryVoltage,
        signalStrength,
        gpsValidPollCount,
        areas,
        locationType,
        locationName,
      }) => ({
        imei: `="${imei}"`, // `
        mostRecentTime: mostRecentTime
          ? moment(mostRecentTime).format('YYYY-MM-DD HH:mm:ss')
          : 'N/A',
        fleetNumber,
        registrationNumber,
        ignitionOn: ignitionOn ? 'On' : 'Off',
        batteryVoltage,
        decodedSignalStrength:
          _.round((100 * decodeSignalStrength(signalStrength)) / 4).toString() +
          '%',
        decodedGpsValidPollCount: gpsValidPollCountString(
          decodeGpsValidPollCount(gpsValidPollCount)
        ),
        ...areas,
        locationType,
        locationName,
      })
    );

    downloadCSV(data, filename, headers);
  }

  return (
    <Container title="Last Contact">
      <Box
        sx={{
          width: 1,
          height: 'calc(100vh - 48px)',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <Paper sx={{ m: 1, minWidth: 240 }}>
          <Helmet>
            <title>IR3 | Last Contact</title>
          </Helmet>
          <Toolbar variant="dense" disableGutters sx={{ p: 1, pb: 0 }}>
            <CardHeader
              avatar={
                isXs ? (
                  <IconButton onClick={handleBackClick} size="large">
                    <ArrowBackIcon />
                  </IconButton>
                ) : (
                  <Avatar aria-label="Last Contact">
                    <TimerIcon />
                  </Avatar>
                )
              }
              sx={{ flexGrow: 1 }}
              title="Last Contact"
            />
            Minimum days since last contact:
            <TextField
              size="small"
              id="contactDays"
              value={localLastContactDays}
              onChange={handleLastContactDaysChange}
              type="number"
              sx={{ m: 1, width: 80 }}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
            />
            <IconButton
              title={isLoading ? 'Loading' : 'Fetch'}
              onClick={handleRefreshClick}
              disabled={isLoading}
            >
              <AutorenewIcon />
            </IconButton>
            <IconButton
              title={showFilter ? 'Hide filter' : 'Show filter'}
              onClick={() => handleFilterToggle()}
            >
              <FilterListIcon color={showFilter ? 'primary' : 'inherit'} />
            </IconButton>
            <IconButton
              title="Download data"
              disabled={filteredBoxes.length === 0}
              onClick={handleDownloadClick}
            >
              <GetAppIcon />
            </IconButton>
          </Toolbar>
          <Collapse in={showFilter} timeout="auto">
            <Stack spacing={1.5} sx={{ flex: 1, p: 1.5 }}>
              {Object.values(filters).map(({ label, key, suggestions }) => (
                <SelectMultiple
                  anyOption={key !== 'imei'}
                  noneOption={key !== 'imei'}
                  key={key}
                  fullWidth
                  label={label}
                  placeholder="Select..."
                  value={filterSelections[key] || []}
                  labelValue
                  onChange={(value) => handleFilterFieldChanged(key, value)}
                  suggestions={suggestions.map(labelValue)}
                />
              ))}
              <FormControl fullWidth>
                <InputLabel id="disposed-label">
                  Held by Disposed Vehicle
                </InputLabel>
                <Select
                  labelId="disposed-label"
                  value={filterSelections['disposed'] ?? ''}
                  onChange={(e) =>
                    handleFilterFieldChanged('disposed', e.target.value)
                  }
                >
                  <MenuItem value="No">No</MenuItem>
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="Yes & No">Yes & No</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </Collapse>
          <Table
            styles={{
              tableContainer: {
                height: 'calc(100vh - 188px)',
                overflowY: 'scroll',
              },
              table: {
                minWidth: 750,
              },
            }}
            data={filteredBoxes.map((box) => ({ ...box }))}
            headers={[
              {
                label: '',
                key: 'expand',
                type: 'expand',
                component: DrillDown,
              },
              ...allHeaders,
              {
                label: 'Link',
                key: 'link',
                type: 'component',
                component: TelematicsBoxLink,
              },
            ]}
            rowsPerPage={rowsPerPage}
            page={page}
            keyName="imei"
            order={order}
            orderBy={orderBy}
            onOrderChange={handleOrderChange}
            onOrderByChange={handleOrderByChange}
          />
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={filteredBoxes.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </Paper>
      </Box>
    </Container>
  );
}
