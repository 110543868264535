import { Snackbar, Button, Box } from '@mui/material';
import { useEffect, useState } from 'react';
// import { StrictMode } from 'react';
import { SideNavProvider } from './SideNav';
import { SnackbarProvider } from './Snackbar';
import { useOptions } from './options';
import { useAuth } from './Auth';
import Maintenance from './Maintenance';
import { log } from '../apis/utilities';
import { CommissioningDialogProvider } from './resources/TelematicsBoxList/CommissioningDialog';
import PermittedRoutes from './PermittedRoutes';

const { autoLogin, promptText, maintenance } = window.config;

export default function App() {
  const auth = useAuth();
  const [accepted, setAccepted] = useState(localStorage.getItem('accepted'));
  const fetch = useOptions().fetch;
  const isAuthenticated = auth.isAuthenticated();

  useEffect(() => {
    auth.setupRefresh();
  }, [auth]);

  useEffect(() => {
    if (autoLogin && !auth.isAuthenticated()) {
      auth.login();
    }
  }, [auth]);

  useEffect(() => {
    if (isAuthenticated) {
      fetch();
    }
  }, [fetch, isAuthenticated]);

  function handleAccept() {
    localStorage.setItem('accepted', true);
    setAccepted(true);

    log('Accept', 'Prompt');
  }

  if (maintenance) {
    return <Maintenance />;
  }

  return (
    <SnackbarProvider>
      <SideNavProvider>
        <CommissioningDialogProvider>
          <PermittedRoutes />
          {auth.isAuthenticated() && promptText && (
            <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              open={!accepted}
              ContentProps={{
                'aria-describedby': 'message-id',
              }}
              message={
                <Box component="span" id="message-id">
                  {promptText}
                </Box>
              }
              action={
                <Button color="secondary" size="small" onClick={handleAccept}>
                  ACCEPT
                </Button>
              }
            />
          )}
        </CommissioningDialogProvider>
      </SideNavProvider>
    </SnackbarProvider>
  );
}
