import {
  Avatar,
  Box,
  Badge,
  Collapse,
  Divider,
  IconButton,
  InputAdornment,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  ListItemButton,
} from '@mui/material';
import {
  ArrowUpward as ArrowUpwardIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material';
import { RadioHandheld as RadioHandheldIcon } from 'mdi-material-ui';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Outlet, useParams } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import _ from 'lodash';
import { format } from 'date-fns';
import { FETCH_RADIOS } from '../../actions';
import Container from '../Container';
import { NavLink, SearchBox } from '../controls';

const { radioTypes } = window.config;

function Row({ data, index, style }) {
  const [list, id, sortBy] = data;
  const item = list[index];

  function getBadgeColor(count) {
    return count === 0 ? 'warning' : count === 1 ? 'success' : 'error';
  }

  function getSecondaryText() {
    if (!_.get(item, sortBy)) {
      return '';
    }

    switch (sortBy) {
      case 'ssi':
      case 'assignmentCount':
        return item.type;
      case 'lastPoll.time':
      case 'firstContactTime':
        return format(new Date(_.get(item, sortBy)), 'dd/MM/yyyy HH:mm:ss');
      default:
        return _.get(item, sortBy);
    }
  }

  return (
    <ListItemButton
      dense
      key={index}
      style={style}
      component={NavLink}
      to={`/resources/radios/${item.ssi}`}
      selected={id === item.ssi}
    >
      <ListItemAvatar>
        <Badge
          showZero
          badgeContent={item.assignmentCount}
          color={getBadgeColor(item.assignmentCount)}
        >
          <Avatar>
            <RadioHandheldIcon />
          </Avatar>
        </Badge>
      </ListItemAvatar>
      <ListItemText primary={item.ssi} secondary={getSecondaryText()} />
    </ListItemButton>
  );
}

export default function RadioList() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const radios = useSelector((state) => state.radios.radios, _.isEqual);
  const [searchText, setSearchText] = useState('');
  const [showSettings, setShowSettings] = useState(false);
  const [sortBy, setSortBy] = useState('ssi');
  const [sortDesc, setSortDesc] = useState(false);
  const [filter, setFilter] = useState({
    type: '',
    assignments: '',
  });
  const isXs = useMediaQuery((theme) => theme.breakpoints.only('xs'));

  const sortOptions = [
    { label: 'SSI', value: 'ssi' },
    { label: 'Type', value: 'type' },
    { label: 'Label', value: 'label' },
    { label: 'Last Poll Time', value: 'lastPoll.time' },
    { label: 'First Contact Time', value: 'firstContactTime' },
    { label: 'Assignments', value: 'assignmentCount' },
  ];

  useEffect(() => {
    dispatch({
      type: FETCH_RADIOS,
    });
  }, [dispatch]);

  const filteredList = _.orderBy(
    radios
      .filter(
        (item) =>
          item.ssi.indexOf(searchText.toLowerCase()) > -1 || searchText === ''
      )
      .filter((item) => !filter.type || filter.type === item.type)
      .filter(
        (item) =>
          !filter.assignments ||
          (filter.assignments === 'none' && item.assignmentCount === 0) ||
          (filter.assignments === 'multiple' && item.assignmentCount > 1)
      ),
    [sortBy],
    [sortDesc ? 'desc' : 'asc']
  );

  function handleSettingsToggle() {
    setShowSettings(!showSettings);
  }

  function handleSearchChange(event) {
    setSearchText(event.target.value);
  }

  function handleSortByChange(event) {
    setSortBy(event.target.value);
  }

  function handleSortToggle() {
    setSortDesc(!sortDesc);
  }
  const handleFilterChange = (field) => (event) => {
    setFilter({ ...filter, [field]: event.target.value });
  };

  return (
    <Container title="Radios" showBack={isXs && id}>
      <Box sx={{ display: 'flex', height: 1, width: 1 }}>
        <Helmet>
          <title>IR3 | Radios</title>
        </Helmet>
        {(!isXs || !id) && (
          <Box
            sx={(theme) => ({
              display: 'flex',
              flexDirection: 'column',
              width: 280,
              [theme.breakpoints.down('sm')]: {
                width: 1,
              },
              height: 1,
            })}
          >
            <Box>
              <Stack
                direction="row"
                sx={{ p: 1, pr: 0.5 }}
                spacing={0.5}
                alignItems="center"
              >
                <SearchBox
                  value={searchText}
                  onChange={handleSearchChange}
                  count={`${filteredList.length}/${radios.length}`}
                  sx={{ flexGrow: 1 }}
                />
                <IconButton
                  size="small"
                  title={showSettings ? 'Hide settings' : 'Show settings'}
                  onClick={handleSettingsToggle}
                >
                  <SettingsIcon
                    fontSize="inherit"
                    color={showSettings ? 'primary' : 'inherit'}
                  />
                </IconButton>
              </Stack>
              <Collapse in={showSettings} unmountOnExit>
                <Stack sx={{ p: 1 }} spacing={1.5}>
                  <TextField
                    size="small"
                    select
                    fullWidth
                    label="Sort by"
                    value={sortBy}
                    onChange={handleSortByChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <IconButton
                            title={sortDesc ? 'Descending' : 'Ascending'}
                            sx={(theme) => ({
                              transform: sortDesc
                                ? 'rotate(180deg)'
                                : 'rotate(0deg)',
                              transition: theme.transitions.create(
                                'transform',
                                {
                                  duration: theme.transitions.duration.shortest,
                                }
                              ),
                            })}
                            onClick={handleSortToggle}
                            size="small"
                          >
                            <ArrowUpwardIcon fontSize="inherit" />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  >
                    {sortOptions.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Stack>
                <Stack sx={{ px: 1 }} spacing={1.5}>
                  <Divider>
                    <Typography variant="subtitle2" color="textSecondary">
                      Filters
                    </Typography>
                  </Divider>
                  <TextField
                    size="small"
                    fullWidth
                    select
                    label="Type"
                    value={filter.type}
                    onChange={handleFilterChange('type')}
                  >
                    <MenuItem value="">
                      <em>Any</em>
                    </MenuItem>
                    {radioTypes.map((type) => (
                      <MenuItem key={type.value} value={type.value}>
                        {type.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    size="small"
                    fullWidth
                    select
                    label="Assignments"
                    value={filter.assignments}
                    onChange={handleFilterChange('assignments')}
                  >
                    <MenuItem value="">
                      <em>Any</em>
                    </MenuItem>
                    <MenuItem value="none">None</MenuItem>
                    <MenuItem value="multiple">Multiple</MenuItem>
                  </TextField>
                  <Divider />
                </Stack>
              </Collapse>
            </Box>
            {filteredList.length > 0 && (
              <Box sx={{ height: 1 }}>
                <AutoSizer>
                  {({ width, height }) => (
                    <FixedSizeList
                      height={height}
                      overscanCount={10}
                      itemData={[filteredList, id, sortBy]}
                      itemCount={filteredList.length}
                      itemSize={56}
                      width={width}
                    >
                      {Row}
                    </FixedSizeList>
                  )}
                </AutoSizer>
              </Box>
            )}
          </Box>
        )}
        {(!isXs || id) && (
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              // width: 1,
              height: 1,
              overflow: 'auto',
            }}
          >
            <Outlet />
          </Box>
        )}
      </Box>
    </Container>
  );
}
