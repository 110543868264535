import moment from 'moment';

// the test may have started at 12:00 but at 11:59 the ignition was on so we
// shouldn't ask the user to turn the ignition on again or wait for a new poll
// looking back over the last lookBackMinutes what was the most recent poll
function preparePolls(polls, startTime, lookBackMinutes) {
  let includedPolls = [];

  if (lookBackMinutes) {
    const lookBack = moment(startTime).add(-lookBackMinutes, 'm');
    let newestPollBeforeStart = (polls || [])
      .filter((p) => moment(p.time) >= lookBack) // get polls between the lookback period
      .filter((p) => moment(p.time) < moment(startTime)) // and the start of the test
      .sort((a, b) => b.time.localeCompare(a.time))?.[0]; // sort newest to oldest // and get the newest one

    // include everything from the most recent poll before the start (or the start)
    includedPolls = polls.filter(
      (p) => moment(p.time) >= moment(newestPollBeforeStart?.time || startTime)
    );
  } else {
    includedPolls = polls.filter((p) => moment(p.time) >= moment(startTime));
  }

  // sort old to new
  includedPolls = includedPolls.sort((a, b) => a.time.localeCompare(b.time));

  return includedPolls;
}

function verifyAsyncSteps(steps, polls, verified) {
  let stepKeys = Object.keys(steps);
  const asyncSteps = stepKeys.filter((key) => steps[key].async);

  polls.forEach((poll) => {
    asyncSteps.forEach((key) => {
      const step = steps[key];

      // async is complete if checkFunction or property match (no time check)
      const stepComplete =
        step.checkFunction?.(poll) ||
        Object.keys(step).every((k) => step[k] === poll?.[k]);

      if (stepComplete) {
        verified[key] = {
          label: 'VERIFIED',
          evidence: poll,
        };
      }
    });
  });
}

export default function verifySteps({
  steps,
  polls: unpreparedPolls,
  startTime,
  overridden = {},
  lookBackMinutes = 0,
}) {
  const stepKeys = Object.keys(steps);
  let verifiedSteps = { ...overridden };

  // trim the start of the polls to lookBackMinutes before starting the verification
  // and sort from old to new
  let polls = preparePolls(unpreparedPolls, startTime, lookBackMinutes);

  // async steps can be verified at any time
  verifyAsyncSteps(steps, polls, verifiedSteps);

  // append a fake poll at the end with the time of now so time based steps
  // can complete without having to wait for a future poll
  const lastPoll = polls[polls.length - 1] || {};
  const nowPoll = {
    ...lastPoll,
    time: new moment(),
  };
  const pollsPlusNow = [...polls, nowPoll];

  // iterate through both the polls and the remaining steps keys
  //
  // if the step is a check function, increment the poll index after the check
  // if the step is a time function, try it out with each second between last
  // poll and this one & only advance the poll index if the time function didn't succeed
  //
  // after any step is verified, it will move onto the next step to do
  let pollIndex = 0;
  let prevPoll;
  let prevTime = moment(startTime);
  const nextPoll = (poll) => {
    pollIndex++;
    prevPoll = poll;
  };

  while (
    Object.keys(verifiedSteps).length < stepKeys.length &&
    pollIndex < pollsPlusNow.length
  ) {
    let stepComplete = false;
    const poll = pollsPlusNow[pollIndex];

    // get the next unverified step
    const key = stepKeys.find((key) => !verifiedSteps[key]);
    const step = steps[key];

    if (step.timerFunction) {
      // try the timer function with prevPoll for each second between prevPoll and poll

      // if prevPoll should restart the timer, it'll do so for each second between so
      // just move onto the next poll
      if (
        step.restartTimerIfFunction &&
        step.restartTimerIfFunction(prevPoll)
      ) {
        prevTime = moment(poll.time);
        nextPoll(poll);
      } else {
        // the timer function could have elapsed before the current poll came in
        // so test it with every second between the previous poll and the current one
        // using the previous poll as the point in time (as it was correct between prev
        // and current)
        let seconds = moment(poll.time).diff(prevTime, 's');
        for (let s = 0; s <= seconds; s++) {
          if (step.timerFunction(s, prevPoll)) {
            stepComplete = true;
            let end = moment(prevTime).add(s, 's');

            verifiedSteps[key] = {
              label: 'VERIFIED',
              start: prevTime,
              end,
            };

            prevTime = end;

            break;
          }
        }

        // if the timer didn't complete, try the next poll (further in time)
        if (!stepComplete) {
          nextPoll(poll);
        }
        // if the timer did complete, don't advance the poll index as the current poll
        // may be relevant for the next step
      }
    } else {
      stepComplete =
        step.checkFunction?.(poll, prevPoll) ||
        Object.keys(step).every((k) => step[k] === poll?.[k]);

      if (stepComplete) {
        verifiedSteps[key] = {
          label: 'VERIFIED',
          evidence: {
            current: poll,
            previous: prevPoll,
          },
        };

        // if the next step is a timer, it should start counting from here
        prevTime = moment(poll.time);
      }

      // verified or not, we always move onto the next poll when doing non-timer steps
      nextPoll(poll);
    }
  }

  return verifiedSteps;
}
