import {
  Avatar,
  Paper,
  CardContent,
  Collapse,
  IconButton,
  Toolbar,
  Typography,
  Box,
  Stack,
} from '@mui/material';
import {
  green,
  orange,
  red,
  teal,
  purple,
  brown,
  cyan,
} from '@mui/material/colors';
import {
  FilterList as FilterListIcon,
  GetApp as GetAppIcon,
} from '@mui/icons-material';
import _ from 'lodash';
import { Fragment, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import {
  Bar,
  BarChart,
  Brush,
  Label,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useSnackbar } from '../../Snackbar';
import {
  FETCH_PERSON_HOURLY_ACTIVITY,
  FETCH_PERSON_HOURLY_ACTIVITY_CANCELLED,
  LOAD_PERSON_HOURLY_ACTIVITY,
} from '../../../actions';
import Container from '../../Container';
import { Parameters, SelectMultiple, CustomTooltip } from '../../controls';
import { downloadCSV } from '../../../apis/utilities';

const { useReducedResourceInformation, showDoubleCrews } = window.config;

const bars = [
  { name: 'On Radio', colour: green[500] },
  { name: 'Driving', colour: purple[500] },
  { name: 'In Base', colour: brown[500] },
  { name: 'In Home Ward', colour: orange[500] },
  { name: 'Responding to Incidents', colour: red[500] },
  { name: 'Attending Objectives', colour: teal[500] },
  ...(showDoubleCrews ? [{ name: 'Double Crewing', colour: cyan[500] }] : []),
];

export default function HourlyActivity(props) {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.activity.hourly.data, _.isEqual);
  const isLoading = useSelector((state) => state.activity.hourly.isLoading);
  const error = useSelector((state) => state.activity.hourly.error);
  const filter = useSelector(
    (state) => state.activity.hourly.filter,
    _.isEqual
  );
  const filterValues = useSelector(
    (state) => state.activity.hourly.filterValues,
    _.isEqual
  );
  const [showFilter, setShowFilter] = useState(false);
  const [hiddenBars, setHiddenBars] = useState([]);
  const snackbar = useSnackbar();

  useEffect(() => {
    if (error) {
      snackbar.notify('error', error);
    }
  }, [error, snackbar]);

  useEffect(() => {
    dispatch({
      type: LOAD_PERSON_HOURLY_ACTIVITY,
      payload: filter,
    });
  }, [filter, dispatch]);

  function handleFilterToggle() {
    setShowFilter(!showFilter);
  }

  function handleFetch(event, query) {
    dispatch({
      type: FETCH_PERSON_HOURLY_ACTIVITY,
      payload: { query, filter },
    });
  }

  function handleCancel() {
    dispatch({
      type: FETCH_PERSON_HOURLY_ACTIVITY_CANCELLED,
    });
  }

  function handleFilterFieldChanged(name, value) {
    const newFilter =
      name in filter
        ? {
            ...filter,
            [name]: value || [],
          }
        : {
            ...filter,
            areas: {
              ...filter.areas,
              [name]: value || [],
            },
          };

    dispatch({
      type: LOAD_PERSON_HOURLY_ACTIVITY,
      payload: newFilter,
    });
  }

  function handleLegendClick(selectedBar) {
    const index = hiddenBars.indexOf(selectedBar);

    if (index === -1) {
      setHiddenBars(hiddenBars.concat(selectedBar));
    } else {
      setHiddenBars(
        hiddenBars.slice(0, index).concat(hiddenBars.slice(index + 1))
      );
    }
  }

  async function handleDownloadClick() {
    const filename = 'Person Hourly Activity.csv';

    downloadCSV(data, filename);
  }

  return (
    <Container title="Hourly Activity">
      <Parameters
        onFetch={handleFetch}
        onCancel={handleCancel}
        isFetching={isLoading}
        sx={{ mt: 1, width: 264 }}
        pointEvent
        dateOnly
        person
      />
      <Box
        sx={{
          width: 1,
          height: 'calc(100vh - 48px)',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <Helmet>
          <title>IR3 | Hourly Activity</title>
        </Helmet>
        <Toolbar variant="dense" disableGutters sx={{ p: 1, pb: 0 }}>
          <Typography sx={{ flexGrow: 1 }} variant="subtitle1">
            Average Hourly Activity by Hour
          </Typography>
          <IconButton
            title={showFilter ? 'Hide filter' : 'Show filter'}
            onClick={() => handleFilterToggle()}
          >
            <FilterListIcon color={showFilter ? 'primary' : 'inherit'} />
          </IconButton>
          <IconButton
            title="Download data"
            disabled={data.length === 0}
            onClick={handleDownloadClick}
          >
            <GetAppIcon />
          </IconButton>
        </Toolbar>
        <Collapse in={showFilter} timeout="auto">
          <Stack spacing={1.5} sx={{ flex: 1, p: 1.5 }}>
            {useReducedResourceInformation ? (
              <SelectMultiple
                label="Staff ID"
                placeholder="Select..."
                value={filter.code}
                labelValue
                onChange={(value) => handleFilterFieldChanged('code', value)}
                suggestions={filterValues.code.map((value) => ({
                  label: value,
                  value,
                }))}
              />
            ) : (
              <Fragment>
                <SelectMultiple
                  label="Name"
                  placeholder="Select..."
                  value={filter.name}
                  labelValue
                  onChange={(value) => handleFilterFieldChanged('name', value)}
                  suggestions={filterValues.name.map((value) => ({
                    label: value,
                    value,
                  }))}
                />
                <SelectMultiple
                  label="Role"
                  placeholder="Select..."
                  value={filter.role}
                  labelValue
                  onChange={(value) => handleFilterFieldChanged('role', value)}
                  suggestions={filterValues.role.map((value) => ({
                    label: value,
                    value,
                  }))}
                />
              </Fragment>
            )}
            {Object.entries(filterValues.areas).map((entry) => {
              return (
                <SelectMultiple
                  key={entry[0]}
                  label={_.startCase(entry[0])}
                  placeholder="Select..."
                  value={filter.areas[entry[0]] || []}
                  labelValue
                  onChange={(value) =>
                    handleFilterFieldChanged(entry[0], value)
                  }
                  suggestions={entry[1].map((value) => ({
                    label: value,
                    value,
                  }))}
                />
              );
            })}
          </Stack>
        </Collapse>
        <Paper
          sx={{
            m: [0, 1, 1],
            minWidth: 240,
            fontSize: 12,
          }}
        >
          <CardContent sx={{ p: 0, pt: 4 }}>
            <Box
              sx={{
                pl: 8,
                pr: 2,
                pb: 1,
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
              }}
            >
              {bars.map((bar) => (
                <Box
                  key={bar.name}
                  sx={{
                    p: 0.5,
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleLegendClick(bar.name)}
                >
                  <Avatar
                    sx={{
                      width: 12,
                      height: 12,
                      mr: 0.5,
                      bgcolor: !hiddenBars.includes(bar.name) && bar.colour,
                    }}
                  >
                    <Fragment />
                  </Avatar>
                  <Typography variant="caption">{bar.name}</Typography>
                </Box>
              ))}
            </Box>
            <ResponsiveContainer width="99%" height={600}>
              <BarChart
                data={data}
                margin={{ top: 0, right: 16, left: 0, bottom: 32 }}
                barGap={0}
                barCategoryGap={4}
              >
                <XAxis dataKey="Hour">
                  <Label value="Hour" offset={36} position="bottom" />
                </XAxis>
                <YAxis>
                  <Label
                    value="Minutes"
                    angle={-90}
                    position="left"
                    offset={-24}
                  />
                </YAxis>
                <Tooltip
                  cursor={data.length > 0}
                  content={<CustomTooltip unit="minutes" />}
                />
                {data.length > 0 && <Brush dataKey="Hour" height={24} />}
                {bars.map((bar) => (
                  <Bar
                    key={bar.name}
                    dataKey={bar.name}
                    fill={bar.colour}
                    hide={hiddenBars.includes(bar.name)}
                  />
                ))}
              </BarChart>
            </ResponsiveContainer>
          </CardContent>
        </Paper>
      </Box>
    </Container>
  );
}
