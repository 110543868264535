import { Box, IconButton, Paper, Toolbar, Typography } from '@mui/material';
import { Label as LabelIcon, Person as PersonIcon } from '@mui/icons-material';
import _ from 'lodash';
import { Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import {
  FETCH_PEOPLE,
  UPDATE_TRIP_CLASSIFICATION,
  UPDATE_TRIP_DRIVER,
} from '../../../actions';
import { getPerson } from '../../../apis/utilities';
import Container from '../../Container';
import {
  FilterPicker,
  Parameters,
  TablePagination,
  Table,
} from '../../controls';
import { ClassificationDialog, PersonDialog } from '../../dialogs';
import { useSnackbar } from '../../Snackbar';
import Classification from './Classification';
import DownloadPollsLink from './DownloadPollsLink';
import ReplayLink from './ReplayLink';
import Occupants from './Occupants';
import ChunkedDownloader from '../ChunkedDownloader';
import { filterLocally } from '../../../data/utilities';
import usePaging from '../usePaging';

const {
  allowDriverChange,
  rowsPerPageOptions,
  events: {
    eventFilters: { trips: eventFilters = [] } = {},
    showTripOccupants = false,
  } = {},
} = window.config;
const { extraTripHeaders } = window.reportHeaders;

const headers = [
  ...extraTripHeaders,
  {
    label: 'Classification',
    key: 'classification',
    type: 'component',
    filter: true,
    component: Classification,
  },
  {
    label: 'Start Location Type',
    key: 'startLocation.type',
    type: 'text',
    filter: true,
  },
  {
    label: 'Start Location Name',
    key: 'startLocation.name',
    type: 'text',
    filter: true,
  },
  {
    label: 'End Location Type',
    key: 'endLocation.type',
    type: 'text',
    filter: true,
  },
  {
    label: 'End Location Name',
    key: 'endLocation.name',
    type: 'text',
    filter: true,
  },
  {
    label: 'Start Time',
    key: 'startTime',
    type: 'date',
    filter: false,
  },
  {
    label: 'End Time',
    key: 'endTime',
    type: 'date',
    filter: false,
  },
  {
    label: 'Duration (minutes)',
    key: 'durationMinutes',
    serverKey: 'durationSeconds',
    type: 'number',
    filter: false,
  },
  {
    label: 'Distance (miles)',
    key: 'distanceMiles',
    serverKey: 'distanceKilometres',
    type: 'number',
    filter: false,
  },
  {
    label: 'Maximum Speed (mph)',
    key: 'maxSpeedMilesPerHour',
    serverKey: 'maxSpeedKilometresPerHour',
    type: 'number',
    filter: false,
  },
  showTripOccupants && {
    label: 'Detected Occupants',
    key: 'occupants',
    type: 'component',
    align: 'right',
    component: Occupants,
  },
  {
    label: '',
    key: 'replay',
    type: 'component',
    filter: false,
    component: ReplayLink,
  },
  {
    label: '',
    key: 'polls',
    type: 'component',
    filter: false,
    component: DownloadPollsLink,
  },
].filter(Boolean);

export default function Trips() {
  const dispatch = useDispatch();
  const people = useSelector((state) => state.people.people, _.isEqual);
  const trips = useSelector((state) => state.events.trips.list, _.isEqual);
  const total = useSelector((state) => state.events.trips.total);
  const totals = useSelector((state) => state.events.trips.totals);
  const isLoading = useSelector((state) => state.events.trips.isLoading);
  const error = useSelector((state) => state.events.trips.error);
  const filter = useSelector((state) => state.events.trips.filter, _.isEqual);
  const query = useSelector((state) => state.events.trips.query, _.isEqual);
  const [classificationOpen, setClassificationOpen] = useState(false);
  const [driverOpen, setDriverOpen] = useState(false);
  const snackbar = useSnackbar();
  const eventType = 'trips';

  const paging = usePaging({ eventType, headers });

  useEffect(() => {
    dispatch({
      type: FETCH_PEOPLE,
    });
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      snackbar.notify('error', error);
    }
  }, [error, snackbar]);

  function handleClassificationClick() {
    setClassificationOpen(true);
  }

  function handleClassificationClose(classification) {
    if (classification) {
      filter.selectedIdentifiers.forEach((tripId) =>
        dispatch({
          type: UPDATE_TRIP_CLASSIFICATION,
          payload: { id: tripId, classification },
        })
      );
    }
    setClassificationOpen(false);
  }

  function handleDriverClick() {
    setDriverOpen(true);
  }

  async function handleDriverClose(driverId) {
    if (driverId) {
      const driver = await getPerson(driverId);

      filter.selectedIdentifiers.forEach((tripId) =>
        dispatch({
          type: UPDATE_TRIP_DRIVER,
          payload: {
            id: tripId,
            driver: { assigned: true, ...driver },
          },
        })
      );
    }
    setDriverOpen(false);
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      paging.updateFilter({
        selectedIdentifiers: filterLocally(filter, trips).map(
          (n) => n.identifier
        ),
      });
    } else {
      paging.updateFilter({
        selectedIdentifiers: [],
      });
    }
  }

  function handleSelectClick(id) {
    const index = filter.selectedIdentifiers.indexOf(id);

    if (index === -1) {
      paging.updateFilter({
        selectedIdentifiers: filter.selectedIdentifiers.concat(id),
      });
    } else {
      paging.updateFilter({
        selectedIdentifiers: filter.selectedIdentifiers
          .slice(0, index)
          .concat(filter.selectedIdentifiers.slice(index + 1)),
      });
    }
  }

  const csvMapping = (trip) => ({
    ...trip,
    startTime: new Date(trip.startTime),
    endTime: new Date(trip.endTime),
  });

  return (
    <Container title="Trips">
      <Parameters
        onFetch={paging.handleFetch}
        onCancel={paging.handleCancel}
        isFetching={isLoading}
        value={query}
        onChange={paging.handleQueryChange}
        sx={{ mt: 1, width: 264 }}
        vehicle
        driver
        rfidCard
        eventFilters={eventFilters}
      />
      <Box
        sx={{
          flex: 1,
          height: 'calc(100vh - 48px)',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <Helmet>
          <title>IR3 | Trips</title>
        </Helmet>
        <Toolbar variant="dense" disableGutters sx={{ p: 1, pb: 0 }}>
          <Typography sx={{ flexGrow: 1 }} variant="subtitle1">
            Trips
          </Typography>
          {filter.selectedIdentifiers.length > 0 && (
            <Fragment>
              <Typography>
                {filter.selectedIdentifiers.length} selected
              </Typography>
              <IconButton
                title="Classification"
                onClick={handleClassificationClick}
                size="large"
              >
                <LabelIcon />
              </IconButton>
              {allowDriverChange && (
                <IconButton
                  title="Driver"
                  onClick={handleDriverClick}
                  size="large"
                >
                  <PersonIcon />
                </IconButton>
              )}
            </Fragment>
          )}
          <FilterPicker
            headers={headers}
            data={trips}
            filter={filter}
            filterValues={filter.filterValues}
            onFilterChange={paging.onFilterChange}
          />
          <ChunkedDownloader
            eventType={'trips'}
            filename={'Vehicle Trips.csv'}
            headers={headers}
            csvMapping={csvMapping}
          />
        </Toolbar>
        <Paper sx={{ m: [0, 1, 1], minWidth: 240 }}>
          <Box sx={isLoading ? { opacity: 0.5 } : undefined}>
            <Table
              selectMode="multi"
              styles={{
                tableContainer: {
                  height: 'calc(100vh - 172px)',
                  overflowY: 'scroll',
                },
                table: {
                  minWidth: 750,
                },
              }}
              data={trips} // {filteredTrips}
              headers={headers}
              rowsPerPage={filter.rowsPerPage}
              page={0} //{filter.page} // server-side pagination will fake the page we're on
              keyName="identifier"
              selectedKeys={filter.selectedIdentifiers}
              onSelectAllClick={handleSelectAllClick}
              onSelectClick={handleSelectClick}
              order={filter.order}
              orderBy={filter.orderBy}
              onOrderChange={paging.handleOrderChange}
              onOrderByChange={paging.handleOrderByChange}
              totals={totals}
            />
          </Box>
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={total ?? 0} //{filteredTrips.length}
            rowsPerPage={filter.rowsPerPage}
            page={filter.page}
            onPageChange={paging.handlePageChange}
            onRowsPerPageChange={paging.handleRowsPerPageChange}
          />
          <ClassificationDialog
            open={classificationOpen}
            onClose={handleClassificationClose}
          />
          <PersonDialog
            open={driverOpen}
            onClose={handleDriverClose}
            people={people}
          />
        </Paper>
      </Box>
    </Container>
  );
}
