import {
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  IconButton,
  Table,
  TableBody,
  Typography,
  Avatar,
  Chip,
  Box,
  Stack,
} from '@mui/material';
import {
  LocationSearching as FollowIcon,
  Edit as EditIcon,
} from '@mui/icons-material';
import NavigationIcon from '@mui/icons-material/Navigation';
import RvHookupIcon from '@mui/icons-material/RvHookup';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import WarningIcon from '@mui/icons-material/Warning';
import WbIncandescentIcon from '@mui/icons-material/WbIncandescent';
import _ from 'lodash';
import {
  AlarmLight as AlarmLightIcon,
  Engine as EngineIcon,
  CarLightHigh as HeadlightsFlashIcon,
  // Speedometer as SpeedometerIcon,
} from 'mdi-material-ui';
import moment from 'moment';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useSelector, useDispatch } from 'react-redux';
import avatarForItem from './avatarUtility';
import IncidentLiveListItem from './IncidentLiveListItem';
import PeopleLiveListItem from './PeopleLiveListItem';
import CallSignLiveListItem from './CallSignLiveListItem';
import { areasToLabelAccessors, toLabelAccessors, ItemRows } from './ItemRows';
import {
  FETCH_LIVE_VEHICLE,
  FETCH_LIVE_ESTIMATED_TIME_OF_ARRIVAL,
} from '../../../actions/types';
import { usePrevious } from '../../../hooks';
import { TagControl } from '../../controls';

import { amber, blue, grey, red } from '@mui/material/colors';

// sample object
/*
areas: Array(1)
  0: {name: "West Midlands", type: "region"}

// assume the same as officers
assignments:
  callSign:
    category: "Available"
    code: "BT2000"
    status: "On Duty"
    time: "2019-01-01T03:00:00Z"
  incident:
    number: "DEV-111111-1111"
    time: "2019-01-01T03:00:00Z"


currentLocations: Array(1)
  0: {code: "TEST", name: "Test Boundary", type: "Force Boundary"}

driver:
  areas: (6) [{...}, {...}, {...}, {...}, {...}, {...}]
  category: "Constable"
  class: "Driver"
  code: "EMP10005"
  collarNumber: "1563"
  forenames: "Forename"
  identificationCategory: "Driver"
  identificationMethod: "rfid"
  identificationReference: "BBBBCCCCCC999F"
  identificationTime: "2018-06-01T08:56:35.000Z"
  role: "Specalist Operations Territorial Support Officer"
  skills: Array(10)
    0: {name: "NPoCC - CBRN Lic Search Offr", type: "skill"}
    1: {name: "NPoCC - CBRN Responder", type: "skill"}
    2: {name: "NPoCC - Level 2 PSU Officer", type: "skill"}
    3: {name: "NPoCC - Licensed Search Officer", type: "skill"}
    4: {name: "NPoCC - TASER X2 SPEC TRAIN OFF", type: "skill"}
    5: {name: "SW - 4x4 Off Road", type: "skill"}
    6: {name: "SW - 4x4 On Road", type: "skill"}
    7: {name: "SW - Method of Entry", type: "skill"}
    8: {name: "SW - Police Constable Tutor", type: "skill"}
    9: {name: "SW - Taser Officer", type: "skill"}
  length: 10
  surname: "Surname"

fleetNumber: "HA0786"
headingDegrees: 199
homeStation: "Hilton Park Outstation"
id: "HA0786"
identificationNumber: "HA0786"
ignitionOn: true
lastDriver:
  category: ""
  code: ""
  collarNumber: ""
  forenames: ""
  identificationCategory: ""
  identificationMethod: ""
  identificationReference: ""
  identificationTime: "2018-06-01T07:09:07.000Z"
  skills: []
  surname: ""

lastIgnitionOffTime: "2018-06-01T08:56:35.000Z"
lastPollTime: "2019-06-01T00:00:00.000Z"
make: "Landrover"
malfunctionIndicatorLightOn: false
model: "Discovery"
position:
  coordinates: (2) [-2.290308, 52.994519]
  type: "Point"

registrationNumber: "LJ15LTE"
role: "Traffic Officer"
speedKilometresPerHour: 0
tagChanged: true
telematicsBoxImei: "357207051045995"
*/

const {
  useReducedResourceInformation,
  showEstimatedTimeOfArrival,
  etaRequestDelay,
  speedRuleLabels,
} = window.config;
const knownDioStates = [
  'beaconsOn',
  'sirensOn',
  'amberLightsOn',
  'trailerOn',
  'rearBlueLightsOn',
  'rearRedLightsOn',
  'headlightsFlashOn',
  'malfunctionIndicatorLightOn',
];

function VehicleLiveItem({
  item,
  onFollowToggle,
  followedIdsByType,
  onSubItemClick,
  onSubItemHover,
  hoveredId,
}) {
  const navigate = useNavigate();
  const vehicleRecord = useSelector(
    (state) => state.live.vehicleRecordsById[item.id]
  );

  const dispatch = useDispatch();
  const prevId = usePrevious(item.id);
  useEffect(() => {
    if (prevId !== item.id && !vehicleRecord) {
      dispatch({
        type: FETCH_LIVE_VEHICLE,
        payload: item.id,
      });
    }
  }, [dispatch, item.id, prevId, vehicleRecord]);

  const { speeding, reverseGeocode, currentSpeedRules } = item;
  let speedLimit = undefined;
  const knownLimit = !(reverseGeocode?.unknownLimit ?? true);
  if (knownLimit) {
    speedLimit = Math.round(
      reverseGeocode?.speedLimitKilometresPerHour * 0.62137119
    );
  }

  const incidents = useSelector((state) => state.live.incidents);
  const incident = useMemo(() => {
    return (
      item.assignments?.incident?.number &&
      (incidents[item.assignments.incident.number] || {
        id: item.assignments.incident.number,
      })
    );
  }, [item, incidents]);

  const callSigns = useSelector((state) => state.live.callSigns);
  const callSign = callSigns[item.assignments?.callSign?.code];

  // Get Estimated time of arrival
  const estimatedTimeOfArrival = useSelector(
    (state) => state.live.estimatedTimeOfArrivals
  ).find((eta) => eta?.vin === item.id);
  const [nextEtaRequest, setNextEtaRequest] = useState(
    moment().subtract(1, 'seconds')
  ); // subtract one second to make the first request
  const previousIncident = usePrevious(incident);

  useEffect(() => {
    if (showEstimatedTimeOfArrival) {
      const callSignStatus = item.assignments?.callSign?.status;
      const checkCallSignsStatus = callSignStatus !== 'At Scene';

      // if incident assigned set the delay to make immediate ETA request
      if (previousIncident === undefined && incident) {
        setNextEtaRequest(moment().subtract(1, 'seconds'));
      }

      if (
        incident &&
        checkCallSignsStatus &&
        moment().isAfter(nextEtaRequest)
      ) {
        setNextEtaRequest(moment().add(etaRequestDelay, 'seconds'));
        dispatch({
          type: FETCH_LIVE_ESTIMATED_TIME_OF_ARRIVAL,
          payload: { vehicle: item, incident },
        });
      }
    }
  }, [dispatch, item, incident, nextEtaRequest, previousIncident]);

  const people = useSelector((state) => state.live.people);
  const driver = item.ignitionOn ? item.driver : item.lastDriver;
  const matchedDriver = driver && people[driver.code];
  if (matchedDriver) {
    matchedDriver.id = matchedDriver.code;
  }

  const type = 'vehicles';
  const following = followedIdsByType?.[type]?.[item.id];

  function getDriverSection(driverName, driver) {
    driver = matchedDriver || driver;
    if (!driver) {
      return null;
    }

    let title = driverName;
    if (title === null || title.match(/^ *$/) !== null) {
      title = driver.code;
    }

    function handleClick() {
      if (matchedDriver) {
        onSubItemClick({ id: matchedDriver.id, type: 'people' });
      } // o/w nothing to nav to
    }

    return (
      <PeopleLiveListItem
        onClick={matchedDriver ? handleClick : undefined}
        onHoverItem={matchedDriver ? onSubItemHover : undefined}
        item={matchedDriver || { id: '_nomatch', name: driverName, role: '' }}
        highlighted={hoveredId === driver.code}
        onFollowToggle={onFollowToggle}
        followedIdsByType={followedIdsByType}
        hideFollow={!matchedDriver}
      />
      // <ListItem>
      //   <ListItemAvatar>
      //     <Avatar title="Person">{replayItemTypeIcons['person']}</Avatar>
      //   </ListItemAvatar>
      //   <ListItemText
      //     primaryTypographyProps={{ variant: 'body2' }}
      //     primary={title}
      //     secondary={driver.role}
      //   />
      // </ListItem>
    );
  }

  const speedRulesTooltip = item.currentSpeedRules
    ?.map((r) => speedRuleLabels?.[r] ?? r)
    .join(', ');

  const sections = {
    status: [
      // these are already in as status icons
      // { label: 'Ignition', value: item.ignitionOn ? 'On' : 'Off' },
      // {
      //   label: 'Malfunction Indicator Light',
      //   value: item.malfunctionIndicatorLightOn ? 'On' : 'Off'
      // },
      ...Object.keys(window.config.dioStates)
        .filter((key) => !knownDioStates.includes(key)) // filter out ones covered by icons
        .map(
          (key) =>
            item[key] !== undefined && {
              label: window.config.dioStates[key],
              value: item[key] ? 'On' : 'Off',
            }
        )
        .filter(Boolean),
      // {
      //   label: 'Speed',
      //   value: _.round(item.speedKilometresPerHour * 0.62137199, 2) + ' MPH',
      //   valueStyle: item.speeding ? { color: red[700] } : undefined,
      // },
      item.speedLimitTag && {
        label: 'Speed limit',
        value: item.speedLimitTag.toUpperCase(),
      },
      {
        label: 'Last poll time',
        value:
          item.lastPollTime &&
          moment(item.lastPollTime).format('DD/MM/YYYY HH:mm:ss'),
      },
      {
        label: 'Last ignition off time',
        value:
          item.lastIgnitionOffTime &&
          moment(item.lastIgnitionOffTime).format('DD/MM/YYYY HH:mm:ss'),
      },
      item.currentSpeedRules?.length > 0 && {
        label: `Speed ${
          item.currentSpeedRules.length > 1 ? 'rules' : 'rule'
        } broken`,
        value: speedRulesTooltip,
      },
    ].filter(Boolean),
    detail: [
      {
        label: 'Description',
        value: [item.colour, item.make, item.model].filter(Boolean).join(' '),
      },
      ...(useReducedResourceInformation
        ? [{ label: 'Home Location', value: item.homeStation }]
        : [
            { label: 'Role', value: item.role },
            { label: 'Home Station', value: item.homeStation },
          ]),
      // these are already in the avatar, no need to repeat
      // { label: "Fleet Number", value: item.fleetNumber },
      // { label: "Registration", value: item.registrationNumber },
      { label: 'Type', value: item.type },
      // these are covered by description
      // { label: 'Make', value: item.make },
      // { label: 'Model', value: item.model },
      // { label: 'Colour', value: item.colour },
      item.equipment?.length > 0
        ? { label: 'Equipment', value: item.equipment?.join(', ') || '' }
        : false,
      { label: 'IMEI', value: item.telematicsBoxImei },
      ...areasToLabelAccessors(item.areas),
    ].filter(Boolean),
    driver: item.ignitionOn && getDriverSection(item.driverName, item.driver),
    lastDriver:
      !item.ignitionOn && getDriverSection(item.driverName, item.lastDriver),
    locations: toLabelAccessors(item.currentLocations, 'type', 'name'),
  };

  if (showEstimatedTimeOfArrival && estimatedTimeOfArrival && incident) {
    sections.status.push({
      label: 'Estimated Time of Arrival',
      value: estimatedTimeOfArrival.time ? estimatedTimeOfArrival.eta : '',
    });
  }
  const link = `/resources/${type}/${item.id}`;

  return (
    <Card sx={{ m: 1 }}>
      <Helmet>
        <title>
          {`IR3 | Live | Vehicles | 
          ${
            useReducedResourceInformation
              ? item.fleetNumber
              : item.registrationNumber
          }`}
        </title>
      </Helmet>
      <CardHeader
        avatar={avatarForItem(item, 'vehicles', item.type)}
        title={
          useReducedResourceInformation
            ? item.fleetNumber
            : item.registrationNumber
        }
        subheader={
          useReducedResourceInformation
            ? item.identificationNumber
            : item.fleetNumber
        }
        action={
          <Fragment>
            <IconButton
              aria-label="Edit"
              onClick={() => navigate(link)}
              title="Edit"
              size="large"
            >
              <EditIcon fontSize="small" />
            </IconButton>

            <IconButton
              title={following ? 'Stop following' : 'Follow'}
              onClick={(e) => {
                e.stopPropagation();
                onFollowToggle('vehicles', item.id);
              }}
              size="large"
            >
              <FollowIcon
                titleAccess="Follow"
                fontSize="small"
                color={following ? 'primary' : 'disabled'}
              />
            </IconButton>
          </Fragment>
        }
      />
      {vehicleRecord && vehicleRecord.picture && (
        <CardMedia style={{ height: 420 }} image={vehicleRecord.picture} />
      )}
      <CardContent>
        <Stack direction="row" spacing={1}>
          <NavigationIcon
            // fromCharCode so degrees symbol doesn't stop chrome debugger mapping
            titleAccess={
              'Heading ' + item.headingDegrees + String.fromCharCode(176)
            }
            style={{
              transform: `rotate(${item.headingDegrees}deg)`,
              transformOrigin: ['50%', '50%'],
            }}
          />
          {item.ignitionOn !== undefined && (
            <VpnKeyIcon
              titleAccess={'Ignition ' + (item.ignitionOn ? 'on' : 'off')}
              htmlColor={item.ignitionOn ? amber[700] : grey[400]}
            />
          )}
          {item.sirensOn !== undefined && (
            <VolumeUpIcon
              titleAccess={'Siren ' + (item.sirensOn ? 'on' : 'off')}
              htmlColor={item.sirensOn ? amber[700] : grey[400]}
            />
          )}
          {item.beaconsOn !== undefined && (
            <AlarmLightIcon
              titleAccess={'Beacons ' + (item.beaconsOn ? 'on' : 'off')}
              htmlColor={item.beaconsOn ? amber[700] : grey[400]}
            />
          )}
          {item.headlightsFlashOn !== undefined && (
            <HeadlightsFlashIcon
              titleAccess={
                'Headlights flash ' + (item.headlightsFlashOn ? 'on' : 'off')
              }
              htmlColor={item.headlightsFlashOn ? amber[700] : grey[400]}
            />
          )}
          {item.malfunctionIndicatorLightOn !== undefined && (
            <EngineIcon
              titleAccess={
                'Malfunction Indicator Light ' +
                (item.malfunctionIndicatorLightOn ? 'on' : 'off')
              }
              htmlColor={
                item.malfunctionIndicatorLightOn ? amber[700] : grey[400]
              }
            />
          )}
          {item.accelerometerAlert !== undefined && (
            <WarningIcon
              htmlColor={item.accelerometerAlert ? amber[700] : grey[400]}
            />
          )}

          {item.amberLightsOn !== undefined && (
            <AlarmLightIcon
              titleAccess={'Amber lights ' + item.amberLightsOn ? 'on' : 'off'}
              htmlColor={item.amberLightsOn ? amber[700] : grey[400]}
            />
          )}
          {item.trailerOn !== undefined && ( //nature rv_hookup
            <RvHookupIcon
              titleAccess={'Trailer ' + (item.trailerOn ? 'on' : 'off')}
              htmlColor={item.trailerOn ? amber[700] : grey[400]}
            />
          )}
          {item.rearBlueLightsOn !== undefined && (
            <WbIncandescentIcon
              titleAccess={
                'Rear Blue Lights ' + (item.rearBlueLightsOn ? 'on' : 'off')
              }
              htmlColor={item.rearBlueLightsOn ? blue[500] : grey[400]}
            />
          )}
          {item.rearRedLightsOn !== undefined && (
            <WbIncandescentIcon
              titleAccess={
                'Rear Red Lights ' + (item.rearRedLightsOn ? 'on' : 'off')
              }
              htmlColor={item.rearRedLightsOn ? red[500] : grey[400]}
            />
          )}
          {/* {item.currentSpeedRules?.length > 0 && (
            <div
              style={{
                color: red[700],
                display: 'flex',
                alignItems: 'end',
              }}
              title={speedRulesTooltip}
            >
              <SpeedometerIcon
                title={speedRulesTooltip}
                className={classes.statusIconLarge}
                htmlColor={red[700]}
                style={{ marginRight: 0 }}
              />
              {item.currentSpeedRules?.length > 1 &&
                'x' + item.currentSpeedRules.length}
            </div>
          )} */}
          <Box sx={{ flex: 1 }} />
          <Chip
            title={speedRulesTooltip}
            size="small"
            sx={{
              '.MuiChip-avatarSmall': {
                bgcolor: 'background.default',
                color: speeding ? 'error.main' : undefined,
                marginLeft: '3px',
              },
            }}
            color={currentSpeedRules?.length ? 'error' : undefined}
            avatar={
              speedLimit && (speeding || currentSpeedRules?.length) ? (
                <Avatar>
                  <Box
                    sx={{ color: currentSpeedRules?.length && 'text.primary' }}
                  >
                    {speedLimit}
                  </Box>
                </Avatar>
              ) : undefined
            }
            label={`${Math.round(
              item.speedKilometresPerHour * 0.62137119
            )} MPH`}
          />
        </Stack>
        <TagControl item={item} type={type} sx={{ py: 2 }} label="Tags" />
        {item.assignments && (
          <Fragment>
            {item.assignments.callSign && (
              <Fragment>
                <Typography variant="subtitle2" color="textSecondary">
                  Call Sign
                </Typography>
                <CallSignLiveListItem
                  onClick={onSubItemClick}
                  onHoverItem={onSubItemHover}
                  item={callSign}
                />
              </Fragment>
            )}
            {incident && (
              <Fragment>
                <Typography variant="subtitle2" color="textSecondary">
                  Assigned Incident
                </Typography>
                <IncidentLiveListItem
                  onClick={onSubItemClick}
                  onHoverItem={onSubItemHover}
                  onFollowToggle={onFollowToggle}
                  followedIdsByType={followedIdsByType}
                  item={incident}
                  highlighted={hoveredId === incident.id}
                />
              </Fragment>
            )}
          </Fragment>
        )}
        {Object.keys(sections)
          .filter(
            (key) =>
              sections[key] &&
              (!Array.isArray(sections[key]) || sections[key].length > 0)
          )
          .map((key) => (
            <Fragment key={key}>
              <Typography variant="subtitle2" color="textSecondary">
                {_.startCase(key)}
              </Typography>
              {Array.isArray(sections[key]) ? (
                <Table size="small" sx={{ mt: 1, mb: 2 }}>
                  <TableBody>{ItemRows(sections[key], item)}</TableBody>
                </Table>
              ) : (
                sections[key]
              )}
            </Fragment>
          ))}
      </CardContent>
    </Card>
  );
}

export default VehicleLiveItem;
