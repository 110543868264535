import { Box, IconButton } from '@mui/material';
import {
  ZoomIn as ZoomInIcon,
  ZoomOut as ZoomOutIcon,
  ZoomOutMap as ZoomOutMapIcon,
} from '@mui/icons-material';
import { Collection, Feature, Map } from 'ol';
import { defaults as defaultControls } from 'ol/control';
import { altKeyOnly } from 'ol/events/condition';
import { applyTransform } from 'ol/extent';
import { GeoJSON } from 'ol/format';
import {
  defaults as defaultInteractions,
  MouseWheelZoom as MouseWheelZoomInteraction,
} from 'ol/interaction';
import { Vector as VectorLayer } from 'ol/layer';
import 'ol/ol.css';
import { getTransform } from 'ol/proj';
import { Vector as VectorSource } from 'ol/source';
import { Fill, Stroke, Style } from 'ol/style';
import { useState, useEffect, useRef } from 'react';
import { setBaseLayers, getMapView } from '../../mapStyles';
import { MapLayerSwitcher } from '../controls';

const { mapExtent, maxZoom } = window.config;

const defaultStyle = new Style({
  fill: new Fill({
    color: 'rgba(100,100,100,0.5)',
  }),
  stroke: new Stroke({
    color: 'rgb(0,0,0)',
    width: 2,
  }),
});

export default function BoundaryMap({ boundary }) {
  const [zoomInDisabled, setZoomInDisabled] = useState(false);
  const [zoomOutDisabled, setZoomOutDisabled] = useState(false);

  const mapDiv = useRef(null);
  const vectorSource = useRef(null);
  const map = useRef(null);

  function resetMapControls() {
    setZoomInDisabled(
      map.current.getView().getZoom() === map.current.getView().getMaxZoom()
    );
    setZoomOutDisabled(
      map.current.getView().getZoom() === map.current.getView().getMinZoom()
    );
  }

  useEffect(() => {
    if (!map.current) {
      vectorSource.current = new VectorSource({
        features: new Collection([]),
      });

      map.current = new Map({
        target: mapDiv.current,
        layers: [
          new VectorLayer({
            source: vectorSource.current,
            style: defaultStyle,
          }),
        ],
        interactions: defaultInteractions({
          mouseWheelZoom: false,
          pinchRotate: false,
          altShiftDragRotate: false,
        }).extend([
          new MouseWheelZoomInteraction({
            condition: altKeyOnly,
          }),
        ]),
        view: getMapView(),
        controls: defaultControls({
          attribution: false,
          rotate: false,
          zoom: false,
        }),
      });

      setBaseLayers(map.current);
    }
  }, []);

  useEffect(() => {
    vectorSource.current.clear();

    if (boundary) {
      vectorSource.current.addFeature(
        new Feature(
          new GeoJSON().readGeometry(boundary, {
            featureProjection: 'EPSG:3857',
          })
        )
      );

      const geometry = new GeoJSON().readGeometry(boundary, {
        featureProjection: 'EPSG:3857',
      });
      map.current.getView().fit(geometry, { maxZoom: maxZoom });
    } else {
      map.current
        .getView()
        .fit(applyTransform(mapExtent, getTransform('EPSG:4326', 'EPSG:3857')));
      resetMapControls();
    }
  }, [boundary]);

  function handleZoomInClick() {
    if (map.current) {
      map.current.getView().setZoom(map.current.getView().getZoom() + 1);
      resetMapControls();
    }
  }

  function handleZoomOutClick() {
    if (map.current) {
      map.current.getView().setZoom(map.current.getView().getZoom() - 1);
      resetMapControls();
    }
  }

  function handleFitClick() {
    if (vectorSource.current && vectorSource.current.getFeatures().length > 0) {
      map.current
        .getView()
        .fit(vectorSource.current.getExtent(), { maxZoom: maxZoom });
      resetMapControls();
    }
  }

  return (
    <Box
      sx={{
        position: 'relative',
        width: 1,
        minWidth: 240,
        height: 'calc(100vh - 254px)',
        color: 'common.white',
      }}
    >
      <Box id="map" sx={{ width: 1, height: 1 }} ref={mapDiv} />
      <Box
        sx={{
          top: 8,
          left: 8,
          borderRadius: 1,
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'rgba(255,255,255,.8)',
        }}
      >
        <IconButton
          title="Zoom In"
          aria-label="Zoom In"
          disabled={zoomInDisabled}
          onClick={handleZoomInClick}
          size="large"
        >
          <ZoomInIcon />
        </IconButton>
        <IconButton
          title="Zoom Out"
          aria-label="Zoom Out"
          disabled={zoomOutDisabled}
          onClick={handleZoomOutClick}
          size="large"
        >
          <ZoomOutIcon />
        </IconButton>
        <IconButton
          title="Fit"
          aria-label="Fit"
          onClick={handleFitClick}
          size="large"
        >
          <ZoomOutMapIcon />
        </IconButton>
      </Box>
      <MapLayerSwitcher mapRef={map} />
    </Box>
  );
}
