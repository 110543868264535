import {
  AccountBalance as AccountBalanceIcon,
  // AssignmentTurnedIn as AssignmentTurnedInIcon,
  // Assignment as AssignmentIcon,
  // Search as SearchIcon,
  BarChart as BarChartIcon,
  Build as BuildIcon,
  Business as BusinessIcon,
  ChromeReaderMode as ChromeReaderModeIcon,
  // CropSquare as CropSquareIcon,
  DirectionsWalk as DirectionsWalkIcon,
  EmojiTransportation as EmojiTransportationIcon,
  Event as EventIcon,
  ExploreOff as ExploreOffIcon,
  FiberManualRecord as FiberManualRecordIcon,
  Timer as TimerIcon,
  // Place as PlaceIcon,
  // ShowChart as ShowChartIcon,
  Schedule as ScheduleIcon,
  DataUsage as DataUsageIcon,
  DirectionsCar as DirectionsCarIcon,
  // EventSeat as EventSeatIcon,
  // FormatShapes as FormatShapesIcon,
  Group as GroupIcon,
  History as HistoryIcon,
  Home as HomeIcon,
  Language as LanguageIcon,
  //LibraryBooks as LibraryBooksIcon,
  LocationCity as LocationCityIcon,
  LocalHospital as LocalHospitalIcon,
  NewReleases as NewReleasesIcon,
  Person as PersonIcon,
  Router as RouterIcon,
  School as SchoolIcon,
  Terrain as TerrainIcon,
  Timeline as TimelineIcon,
  // Work as WorkIcon,
  Nfc as NfcIcon,
  VpnKey as VpnKeyIcon,
  People as PeopleIcon,
  Star as StarIcon,
  More as MoreIcon,
  GpsFixed as NearbyIcon,
  Snooze as SnoozeIcon,
  HowToReg as AttendanceIcon,
} from '@mui/icons-material';
import {
  CarSide as CarSideIcon,
  CarTractionControl as CarTractionControlIcon,
  Counter as CounterIcon,
  Garage as GarageIcon,
  RadioHandheld as RadioHandheldIcon,
  // Steering as SteeringIcon,
  // AccountQuestion as AccountQuestionIcon,
  Speedometer as SpeedometerIcon,
  AxisArrow as AxisArrowIcon,
  CarBrakeParking as CarBrakeParkingIcon,
  AlertCircle as AlertCircleIcon,
  EngineOutline as EngineOutlineIcon,
  AccountCancel as PeopleAvailabilityIcon,
} from 'mdi-material-ui';
import { isMobile } from 'react-device-detect';
import Home from './components/Home';
// import BriefList from './components/briefs/BriefList';
// import PlanList from './components/plans/PlanList';
import TagList from './components/tags/TagList';
import QueryList from './components/queries/QueryList';
// import FeatureList from './components/features/FeatureList';
import ObjectiveList from './components/objectives/ObjectiveList';
import Objective from './components/objectives/Objective';
// import BriefContents from './components/briefs/BriefContents';
import Callback from './components/Callback';
// import PlanContents from './components/plans/PlanContents';
// import QueryContents from './components/queries/QueryContents';
import Live from './components/live/Live';
import PersonList from './components/resources/PersonList';
import RadioList from './components/resources/RadioList';
import RfidCardList from './components/resources/RfidCardList';
import TelematicsBoxPage from './components/resources/TelematicsBoxList';
import VehicleList from './components/resources/VehicleList';
import LocationList from './components/locations/LocationList';
import Replay from './components/replay/Replay';
import Retrospective from './components/retrospective/Retrospective';
// import Tasks from './components/Tasks';
import Nearby from './components/nearby/Nearby';
import {
  Trips,
  SpeedInfractions,
  AccelerometerEvents,
  VehicleLocationVisits,
  MalfunctionIndicatorLight,
  OnBoardDiagnostics,
  PersonLocationVisits,
  Trails,
  DoubleCrews,
  Outages,
  Stops,
  Idles,
  Attendances,
} from './components/events';
import {
  DailyUtilisation,
  HourlyUtilisation,
  HourlyInLocations,
  TimelineInLocations,
  Mileage,
  Availability,
} from './components/reports/vehiclesReports';
import {
  DailyActivity,
  HourlyActivity,
  DriverBehaviourDashboard,
  Availability as PeopleAvailability,
} from './components/reports/peopleReports';
import { LastContact } from './components/reports/telematicsBoxesReports';
import AuditReport from './components/reports/AuditReport';
import NotFound from './components/NotFound';
import IndividualDriving from './components/individual/IndividualDriving';
import Tag from './components/tags/Tag';
import Query from './components/queries/Query';
import Vehicle from 'components/resources/Vehicle';
import Person from 'components/resources/Person';
import TelematicsBoxDetail from 'components/resources/TelematicsBoxList/TelematicsBoxDetail';
import Radio from 'components/resources/Radio';
import ReducedVehicle from 'components/resources/ReducedVehicle';
import ReducedPerson from 'components/resources/ReducedPerson';
import RfidCard from 'components/resources/RfidCard';
import Location from 'components/locations/Location';
import { Fragment } from 'react';

const {
  baseType,
  showLive,
  isFleet,
  showDoubleCrews,
  useDallasKeys,
  useReducedResourceInformation,
  // unknownDriverTripsGroup,
} = window.config;

export const routes = isMobile
  ? [
      { path: '/', element: <Home /> },
      { path: '/nearby', element: <Nearby /> },
      { path: '/individual/individualDriving', element: <IndividualDriving /> },
    ]
  : [
      { key: 'home', path: '/', element: <Home />, errorElement: <NotFound /> },
      {
        key: 'callback',
        path: '/callback',
        element: <Callback />,
      },
      {
        key: 'replay',
        path: '/replay/:type/:id',
        element: <Replay />,
      },
      {
        key: 'live',
        path: '/live/*',
        element: <Live />,
        children: [
          {
            path: ':type/*',
            element: <Fragment />,
            children: [
              {
                path: ':id/*',
                element: <Fragment />,
                children: [{ path: ':subId', element: <Fragment /> }],
              },
            ],
          },
        ],
      },
      {
        key: 'retrospective',
        path: '/retrospective/*',
        element: <Retrospective />,
        children: [
          {
            path: ':id/*',
            element: <Fragment />,
            children: [
              {
                path: ':layerIndex/*',
                element: <Fragment />,
                children: [
                  {
                    path: ':itemIndex/*',
                    element: <Fragment />,
                    children: [
                      { path: ':subItemIndex', element: <Fragment /> },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      // {
      //   key: 'tasks',
      //   path: '/tasks/*',
      //   element: <Tasks/>,
      // },
      // {
      //   key: 'planContents',
      //   path: '/plans/:id/contents',
      //   element: <PlanContents/>,
      // },
      // {
      //   key: 'queryContents',
      //   path: '/queries/:id/contents',
      //   element: <QueryContents/>,
      // },
      // {
      //   key: 'briefContents',
      //   path: '/briefs/:id/contents',
      //   element: <BriefContents/>,
      // },
      // {
      //   key: 'briefs',
      //   path: '/briefs/*',
      //   element: <BriefList/>,
      // },
      // {
      //   key: 'plans',
      //   path: '/plans/*',
      //   element: <PlanList/>,
      // },
      {
        key: 'tags',
        path: '/tags/*',
        element: <TagList />,
        children: [{ path: ':id', element: <Tag /> }],
      },
      {
        key: 'queries',
        path: '/queries/*',
        element: <QueryList />,
        children: [{ path: ':id', element: <Query /> }],
      },
      // {
      //   key: 'features',
      //   path: '/features/:featureType/*',
      //   element: <FeatureList/>,
      // },
      {
        key: 'objectives',
        path: '/objectives/*',
        element: <ObjectiveList />,
        children: [{ path: ':id', element: <Objective /> }],
      },
      {
        key: 'vehicles',
        path: '/resources/vehicles/*',
        element: <VehicleList />,
        children: [
          {
            path: ':id',
            element: useReducedResourceInformation ? (
              <ReducedVehicle />
            ) : (
              <Vehicle />
            ),
          },
        ],
      },
      {
        key: 'people',
        path: '/resources/people/*',
        element: <PersonList />,
        children: [
          {
            path: ':id',
            element: useReducedResourceInformation ? (
              <ReducedPerson />
            ) : (
              <Person />
            ),
          },
        ],
      },
      {
        key: 'telematicsBoxes',
        path: '/resources/telematicsboxes/*',
        element: <TelematicsBoxPage />,
        children: [{ path: ':id', element: <TelematicsBoxDetail /> }],
      },
      {
        key: 'radios',
        path: '/resources/radios/*',
        element: <RadioList />,
        children: [{ path: ':id', element: <Radio /> }],
      },
      {
        key: 'rfidCards',
        path: `/resources/${useDallasKeys ? 'dallaskeys' : 'rfidcards'}/*`,
        element: <RfidCardList />,
        children: [{ path: ':id', element: <RfidCard /> }],
      },
      {
        key: 'locations',
        path: '/locations/:locationType/*',
        element: <LocationList />,
        children: [{ path: ':id', element: <Location /> }],
      },
      {
        key: 'dailyUtilisation',
        path: '/reports/vehicles/dailyutilisation',
        element: <DailyUtilisation />,
      },
      {
        key: 'hourlyUtilisation',
        path: '/reports/vehicles/hourlyutilisation',
        element: <HourlyUtilisation />,
      },
      {
        key: 'hourlyInLocations',
        path: '/reports/vehicles/hourlyinlocations',
        element: <HourlyInLocations />,
      },
      {
        key: 'timelineInLocations',
        path: '/reports/vehicles/timelineinlocations',
        element: <TimelineInLocations />,
      },
      {
        key: 'mileage',
        path: '/reports/vehicles/mileage',
        element: <Mileage />,
      },
      {
        key: 'availability',
        path: '/reports/vehicles/availability',
        element: <Availability />,
      },
      {
        key: 'dailyActivity',
        path: '/reports/people/dailyactivity',
        element: <DailyActivity />,
      },
      {
        key: 'hourlyActivity',
        path: '/reports/people/hourlyactivity',
        element: <HourlyActivity />,
      },
      // {
      //   key: 'unknownDriverTrips',
      //   path: '/reports/driving/unknowndrivertrips',
      //   element: UnknownDriverTrips,
      // },
      {
        key: 'doubleCrews',
        path: '/events/people/doublecrews',
        element: <DoubleCrews />,
      },
      {
        key: 'driverBehaviour',
        path: '/reports/driving/behaviour',
        element: <DriverBehaviourDashboard />,
      },
      {
        key: 'peopleAvailability',
        path: '/reports/people/availability',
        element: <PeopleAvailability />,
      },
      {
        key: 'outages',
        path: '/events/telematicsboxes/outages',
        element: <Outages />,
      },
      {
        key: 'lastContact',
        path: '/reports/telematicsboxes/lastcontact',
        element: <LastContact />,
      },
      {
        key: 'audit',
        path: '/reports/audit',
        element: <AuditReport />,
      },
      {
        key: 'trips',
        path: '/events/vehicles/trips',
        element: <Trips />,
      },
      {
        key: 'stops',
        path: '/events/vehicles/stops',
        element: <Stops />,
      },
      {
        key: 'idles',
        path: '/events/vehicles/idles',
        element: <Idles />,
      },
      {
        key: 'speedInfractions',
        path: '/events/vehicles/speedinfractions',
        element: <SpeedInfractions />,
      },
      {
        key: 'accelerometerEvents',
        path: '/events/vehicles/accelerometerevents',
        element: <AccelerometerEvents />,
      },
      {
        key: 'vehicleLocationVisits',
        path: '/events/vehicles/locationvisits',
        element: <VehicleLocationVisits />,
      },
      {
        key: 'malfunctionIndicatorLight',
        path: '/events/vehicles/malfunctionindicatorlight',
        element: <MalfunctionIndicatorLight />,
      },
      {
        key: 'onBoardDiagnostics',
        path: '/events/vehicles/onboarddiagnostics',
        element: <OnBoardDiagnostics />,
      },
      {
        key: 'personLocationVisits',
        path: '/events/people/locationvisits',
        element: <PersonLocationVisits />,
      },
      {
        key: 'trails',
        path: '/events/people/trails',
        element: <Trails />,
      },
      {
        key: 'attendances',
        path: '/events/people/attendances',
        element: <Attendances />,
      },
    ];

export function isAllowed(key, auth) {
  const whitelist = ['home', 'callback'];

  if (whitelist.includes(key)) {
    return true;
  }

  if (!auth || !auth.isAuthenticated()) {
    return false;
  }

  // const groupNames = auth.getGroupNames();

  switch (key) {
    case 'live':
      return showLive;
    // case 'briefs':
    case 'objectives':
      //case 'features':
      return auth.isAuthorised('objectives', false) && !isFleet;
    case 'tags':
      return auth.isAuthorised('tags', false);
    // case 'plans':
    //return auth.isAuthorised('plans', false);
    // case 'queries':
    //return auth.isAuthorised('queries', false);
    case 'locations':
      return auth.isAuthorised('locations', false);
    case 'doubleCrews':
      return !isFleet && showDoubleCrews;
    // case 'unknownDriverTrips':
    //   return (
    //     (!!unknownDriverTripsGroup &&
    //       (groupNames || []).includes(unknownDriverTripsGroup)) ||
    //     !unknownDriverTripsGroup
    //   );
    default:
      return true;
  }
}

export const siteMap = isMobile
  ? [
      { key: 'home', label: 'Home', Icon: HomeIcon, path: '/' },
      {
        key: 'nearby',
        label: 'Nearby',
        Icon: NearbyIcon,
        path: '/nearby',
      },
      {
        key: 'individualDriving',
        label: 'Driving',
        Icon: PersonIcon,
        path: '/individual/individualDriving',
      },
    ]
  : [
      { key: 'home', label: 'Home', Icon: HomeIcon, path: '/' },
      { key: 'live', label: 'Live', Icon: LanguageIcon, path: '/live' },

      {
        key: 'retrospective',
        label: 'Retrospective',
        Icon: HistoryIcon,
        path: '/retrospective',
      },
      //{ key: 'briefs', label: 'Briefs', Icon: WorkIcon, path: '/briefs' },
      //{ key: 'plans', label: 'Plans', Icon: AssignmentIcon, path: '/plans' },
      { key: 'tags', label: 'Tags', Icon: MoreIcon, path: '/tags' },
      //{ key: 'queries', label: 'Queries', Icon: SearchIcon, path: '/queries' },
      // {
      //   key: 'tasks',
      //   label: 'Tasks',
      //   Icon: AssignmentTurnedInIcon,
      //   path: '/tasks',
      // },
      // {
      //   key: 'features',
      //   label: 'Features',
      //   Icon: FormatShapesIcon,
      //   children: [
      //     {
      //       key: 'perimeters',
      //       label: 'Perimeters',
      //       Icon: CropSquareIcon,
      //       path: '/features/perimeters',
      //     },
      //     {
      //       key: 'paths',
      //       label: 'Paths',
      //       Icon: ShowChartIcon,
      //       path: '/features/paths',
      //     },
      //     {
      //       key: 'markers',
      //       label: 'Markers',
      //       Icon: PlaceIcon,
      //       path: '/features/markers',
      //     },
      //   ],
      // },
      {
        key: 'objectives',
        label: 'Objectives',
        Icon: DataUsageIcon,
        path: '/objectives',
      },
      {
        key: 'resources',
        label: 'Resources',
        Icon: GroupIcon,
        children: [
          {
            key: 'vehicles',
            label: 'Vehicles',
            Icon: DirectionsCarIcon,
            path: '/resources/vehicles',
          },
          {
            key: 'people',
            label: 'People',
            Icon: PersonIcon,
            path: '/resources/people',
          },
          {
            key: 'telematicsBoxes',
            label: 'Telematics Boxes',
            Icon: RouterIcon,
            path: '/resources/telematicsboxes',
          },
          {
            key: 'radios',
            label: 'Radios',
            Icon: RadioHandheldIcon,
            path: '/resources/radios',
          },
          {
            key: 'rfidCards',
            label: useDallasKeys ? 'Dallas Keys' : 'RFID Cards',
            Icon: useDallasKeys ? VpnKeyIcon : NfcIcon,
            path: `/resources/${useDallasKeys ? 'dallaskeys' : 'rfidcards'}`,
          },
          // {
          //   key: 'posts',
          //   label: 'Posts',
          //   Icon: EventSeatIcon,
          //   path: '/resources/posts',
          // },
        ],
      },
      {
        key: 'locations',
        label: 'Locations',
        Icon: LocationCityIcon,
        children: [
          {
            key: 'bases',
            label: `${baseType.label}s`,
            Icon: BusinessIcon,
            path: `/locations/${baseType.value}s`,
          },
          {
            key: 'workshops',
            label: 'Workshops',
            Icon: BuildIcon,
            path: '/locations/workshops',
          },
          {
            key: 'wards',
            label: 'Wards',
            Icon: TerrainIcon,
            path: '/locations/wards',
          },
          {
            key: 'hospitals',
            label: 'Hospitals',
            Icon: LocalHospitalIcon,
            path: '/locations/hospitals',
          },
          {
            key: 'courts',
            label: 'Courts',
            Icon: AccountBalanceIcon,
            path: '/locations/courts',
          },
          {
            key: 'schools',
            label: 'Schools',
            Icon: SchoolIcon,
            path: '/locations/schools',
          },
        ],
      },
      {
        key: 'reports',
        label: 'Reports',
        Icon: BarChartIcon,
        children: [
          {
            key: 'vehicleReports',
            label: 'Vehicles',
            Icon: DirectionsCarIcon,
            children: [
              {
                key: 'dailyUtilisation',
                label: 'Daily Utilisation',
                Icon: EventIcon,
                path: '/reports/vehicles/dailyutilisation',
              },
              {
                key: 'hourlyUtilisation',
                label: 'Hourly Utilisation',
                Icon: ScheduleIcon,
                path: '/reports/vehicles/hourlyutilisation',
              },
              {
                key: 'hourlyInLocations',
                label: `Hourly in Location`,
                Icon: GarageIcon,
                path: '/reports/vehicles/hourlyinlocations',
              },
              {
                key: 'timelineInLocations',
                label: `Timeline in Location`,
                Icon: TimelineIcon,
                path: '/reports/vehicles/timelineinlocations',
              },
              {
                key: 'mileage',
                label: 'Mileage',
                Icon: CounterIcon,
                path: '/reports/vehicles/mileage',
              },
              {
                key: 'availability',
                label: 'Availability',
                Icon: EmojiTransportationIcon,
                path: '/reports/vehicles/availability',
              },
            ],
          },
          {
            key: 'peopleReports',
            label: 'People',
            Icon: PersonIcon,
            children: [
              {
                key: 'driverBehaviour',
                label: 'Driver Behaviour',
                Icon: StarIcon,
                path: '/reports/driving/behaviour',
              },
              {
                key: 'dailyActivity',
                label: 'Daily Activity',
                Icon: EventIcon,
                path: '/reports/people/dailyactivity',
              },
              {
                key: 'hourlyActivity',
                label: 'Hourly Activity',
                Icon: ScheduleIcon,
                path: '/reports/people/hourlyactivity',
              },
              {
                key: 'peopleAvailability',
                label: 'Availability',
                Icon: PeopleAvailabilityIcon,
                path: '/reports/people/availability',
              },
            ],
          },
          {
            key: 'telematicsBoxReports',
            label: 'Telematics Boxes',
            Icon: RouterIcon,
            children: [
              {
                key: 'lastContact',
                label: 'Last Contact',
                Icon: TimerIcon,
                path: '/reports/telematicsboxes/lastcontact',
              },
            ],
          },
          {
            key: 'audit',
            label: 'Audit',
            Icon: ChromeReaderModeIcon,
            path: '/reports/audit',
          },
        ],
      },
      {
        key: 'events',
        label: 'Events',
        Icon: NewReleasesIcon,
        children: [
          {
            key: 'vehicleEvents',
            label: 'Vehicles',
            Icon: DirectionsCarIcon,
            children: [
              {
                key: 'trips',
                label: 'Trips',
                Icon: CarTractionControlIcon,
                path: '/events/vehicles/trips',
              },
              {
                key: 'stops',
                label: 'Stops',
                Icon: CarBrakeParkingIcon,
                path: '/events/vehicles/stops',
              },
              {
                key: 'idles',
                label: 'Idles',
                Icon: SnoozeIcon,
                path: '/events/vehicles/idles',
              },
              {
                key: 'speedInfractions',
                label: 'Speed Infractions',
                Icon: SpeedometerIcon,
                path: '/events/vehicles/speedinfractions',
              },
              {
                key: 'accelerometerEvents',
                label: 'Accelerometer Events',
                Icon: AxisArrowIcon,
                path: '/events/vehicles/accelerometerevents',
              },
              {
                key: 'vehicleLocationVisits',
                label: 'Location Visits',
                Icon: CarSideIcon,
                path: '/events/vehicles/locationvisits',
              },
              {
                key: 'malfunctionIndicatorLight',
                label: 'Malfunction Indicator',
                Icon: AlertCircleIcon,
                path: '/events/vehicles/malfunctionindicatorlight',
              },
              {
                key: 'onBoardDiagnostics',
                label: 'On-Board Diagnostics',
                Icon: EngineOutlineIcon,
                path: '/events/vehicles/onboarddiagnostics',
              },
            ],
          },
          {
            key: 'peopleEvents',
            label: 'People',
            Icon: PersonIcon,
            children: [
              {
                key: 'trails',
                label: 'Trails',
                Icon: FiberManualRecordIcon,
                path: '/events/people/trails',
              },
              {
                key: 'personLocationVisits',
                label: 'Location Visits',
                Icon: DirectionsWalkIcon,
                path: '/events/people/locationvisits',
              },
              {
                key: 'doubleCrews',
                label: 'Double Crews',
                Icon: PeopleIcon,
                path: '/events/people/doublecrews',
              },
              {
                key: 'attendances',
                label: 'Objective Attendances',
                Icon: AttendanceIcon,
                path: '/events/people/attendances',
              },
            ],
          },
          // {
          //   key: 'locationEvents',
          //   label: 'Locations',
          //   Icon: LocationCityIcon,
          //   children: [],
          // },
          {
            key: 'telematicsBoxEvents',
            label: 'Telematics Boxes',
            Icon: RouterIcon,
            children: [
              {
                key: 'outages',
                label: 'Outages',
                Icon: ExploreOffIcon,
                path: '/events/telematicsboxes/outages',
              },
            ],
          },
        ],
      },
    ];

// if at a certail level, there are no other routes, then allow access
//
// e.g. routes = ['/locations/bases'] path = '/locations/bases/BASE1'
//      should be allowed
//
// e.g. routes = ['/locations/bases/BASE2'] path = '/locations/bases/BASE1'
//      not allowed as routes specifies only BASE2 should be allowed at this level
function routesPermitPath(routes = [], path) {
  const parts = path.split('/');

  let matches = [...routes];
  for (let i = 1; i < parts.length; i++) {
    const route = parts.slice(0, i + 1).join('/');

    matches = matches.filter((r) => r.startsWith(route));
    if (matches.length === 0) {
      return false;
    }

    const moreSpecificRoutes = matches.filter(
      (r) => r.length > route.length + 1
    );
    if (moreSpecificRoutes.length === 0) {
      return true;
    }
    // otherwise go to the next part of the path
  }

  return true;
}

// filter authorised site map
export function getAuthorisedSiteMap(auth) {
  const authorisedSiteMap = [];
  const routes = auth.getAuthorisation().routes;
  const homeRoute = siteMap.find((item) => item.path === '/');
  authorisedSiteMap.push(homeRoute); // home route always visible

  // authorised everything if routes has '/'
  if (routes?.includes('/')) {
    return siteMap;
  }

  // routes can have many levels, e.g. /live, /events/vehicles/trips
  // we need to trim the siteMap to match all of the routes.
  // for the second and third levels, we need to exclude children if at least one is specified
  // e.g. if routes says /events - all events can be shown
  // if routes says /events/vehicles - only vehicle events can be shown
  // if routes says /events/vehicles/trips - only vehicle trip events can be shown

  function recursivelyFilterChildren(link) {
    // if link.path is defined we have a real link to check, otherwise it's just a parent
    if (link.path !== undefined) {
      if (routesPermitPath(routes, link.path)) {
        return link;
      } else {
        return undefined;
      }
    } else if (Array.isArray(link.children)) {
      // if it's a parent, there are children, check each of their paths
      const allowedChildren = link.children
        .map(recursivelyFilterChildren)
        .filter(Boolean);

      // if there are some valid child paths, this parent is valid so return it
      if (allowedChildren.length > 0) {
        return {
          ...link,
          children: allowedChildren,
        };
      }
    }

    // otherwise this link/node has nothing of interest, don't show in the site map
    return undefined;
  }

  return siteMap.map(recursivelyFilterChildren).filter(Boolean);
}

export function isAuthorised(key, path, auth, location) {
  // if the user profile doesn't have routes array then user only allow access the home route
  const routes = auth.getAuthorisation().routes;

  /** Please use the below sample routes to test the access
    Example - not having '/reports' in user's routes list shouldn't give access to any routes start with '/reports 
    such as '/reports/vehicles/utilisation', '/reports/people/driverbehaviour', etc. 
    If the routes contains '/', give access to everything.
    Add/remove routes for more tests.
  **/
  /*
  const routes = [
    '/live',
    '/retrospective',
    '/tags',
    '/objectives',
    '/resources',
    '/locations',
    '/reports',
    '/events',
  ];
  */

  const whitelist = ['home', 'callback', 'notFound'];

  // auhorise whitelist routes
  if (whitelist.includes(key)) {
    return true;
  }

  // authorised for everything or matches the actual path (i.e. with no :params:)
  if (routes?.includes('/') || routesPermitPath(routes, location.pathname)) {
    return true;
  }

  // check user profile routes list and allow access
  const pathNames = path
    ?.split('/')
    .filter((x) => x)
    .join('/')
    .split('/');
  const route = pathNames?.length > 0 ? pathNames[0] : undefined;
  //if (routes?.includes(`/${route}`)) {
  if (routes?.some((r) => r === `/${route}` || path.startsWith(r))) {
    return true;
  }

  return false;
}

export function isVisible(key, auth) {
  if (!auth || !auth.isAuthenticated()) {
    return false;
  }

  // const groupNames = auth.getGroupNames();

  if (isMobile) {
    return ['home', 'nearby', 'individualDriving'].includes(key);
  } else {
    switch (key) {
      case 'live':
        return showLive;
      // case 'briefs':
      // case 'plans':
      // case 'queries':
      // case 'features':
      case 'objectives':
        return auth.isAuthorised('objectives', false) && !isFleet;
      case 'tags':
        return auth.isAuthorised('tags', false);
      // case 'tasks':
      //   return false;
      case 'resources':
        return auth.isAuthorised('resources', false);
      case 'locations':
        return auth.isAuthorised('locations', false);
      case 'wards':
        return !isFleet;
      case 'radios':
        return !isFleet;
      // case 'posts':
      case 'exclusions':
        return true;
      case 'reports':
        return auth.isAuthorised('vehicles', false);
      case 'peopleReports':
      case 'driverBehaviour':
        return true;
      case 'dailyActivity':
        return !isFleet;
      case 'peopleEvents':
        return !isFleet;
      case 'doubleCrews':
        return !isFleet && showDoubleCrews;
      // case 'unknownDriverTrips':
      //   return (
      //     (!!unknownDriverTripsGroup &&
      //       (groupNames || []).includes(unknownDriverTripsGroup)) ||
      //     !unknownDriverTripsGroup
      //   );
      case 'hourlyActivity':
        return false;
      default:
        return true;
    }
  }
}
