import { IconButton, CircularProgress, Box } from '@mui/material';
import {
  GetApp as DownloadIcon,
  Cancel as CancelIcon,
} from '@mui/icons-material';
import { DownloadOff as DownloadDisallowedIcon } from 'mdi-material-ui';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DOWNLOAD_EVENTS, DOWNLOAD_EVENTS_CANCELLED } from '../../actions';
import { downloadCSV } from '../../apis/utilities';
import { useSnackbar } from '../Snackbar';
import { usePrevious } from '../../hooks';

const { events: { itemDownloadLimit = 10000 } = {} } = window.config;

export default function ChunkedDownloader({
  eventType,
  filename,
  headers,
  csvMapping,
}) {
  const dispatch = useDispatch();
  const snackbar = useSnackbar();
  const event = useSelector((state) => state.events[eventType]);

  const { isDownloading, downloadProportion, download, total, query, filter } =
    event;
  const { fields: filters, order, orderByServerKey } = filter;

  const tooMuchToDownload = total > itemDownloadLimit;

  function handleDownloadClick() {
    if (tooMuchToDownload) {
      snackbar.notify(
        'error',
        'Too many items to download, try filtering results'
      );
    } else {
      dispatch({
        type: DOWNLOAD_EVENTS,
        payload: {
          eventType,
          query,
          filters,
          order,
          orderByServerKey,
        },
      });
    }
  }

  function handleDownloadCancel() {
    dispatch({
      type: DOWNLOAD_EVENTS_CANCELLED,
      payload: { eventType },
    });
  }

  const wasDownloading = usePrevious(isDownloading);
  useEffect(() => {
    if (wasDownloading && !isDownloading && download) {
      const data = download.map(csvMapping || ((item) => item));

      downloadCSV(data, filename, headers);
    }
  }, [
    download,
    isDownloading,
    wasDownloading,
    downloadProportion,
    filename,
    csvMapping,
    headers,
  ]);

  return (
    <Box sx={{ display: 'grid' }}>
      <CircularProgress
        sx={{ gridArea: 'inner-div' }}
        size={48}
        variant={
          isDownloading && downloadProportion === 0
            ? 'indeterminate'
            : 'determinate'
        }
        value={!isDownloading ? 0 : 100 * downloadProportion}
      />
      <IconButton
        sx={{ gridArea: 'inner-div' }}
        disabled={!total}
        title="Download"
        onClick={isDownloading ? handleDownloadCancel : handleDownloadClick}
        size="large"
      >
        {isDownloading ? (
          <CancelIcon />
        ) : tooMuchToDownload ? (
          <DownloadDisallowedIcon />
        ) : (
          <DownloadIcon />
        )}
      </IconButton>
    </Box>
  );
}
