import {
  Avatar,
  Badge,
  Box,
  Button,
  Collapse,
  Divider,
  IconButton,
  InputAdornment,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {
  ArrowUpward as ArrowUpwardIcon,
  Router as RouterIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material';
import { format } from 'date-fns';
import _ from 'lodash';
import moment from 'moment';
import { memo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { NavLink, SearchBox } from '../../controls';
import { usePrevious } from '../../../hooks';
import { downloadCSV, downloadJSON } from '../../../apis/utilities';

const dateFormat = 'DD/MM/YYYY, HH:mm:ss';
const { useReducedResourceInformation } = window.config;

// so useMemo doesn't think these are new strings on compare
const badgeError = 'error';
const badgePrimary = 'primary';

// when this was in the FastList component, useMemo didn't work, I think
// because it was reset every time FastList was rendered
const Row = ({ data, index, style }) => {
  const [list, id, sortBy] = data;
  const item = list[index];
  const badgeNumber = item.isMultiAssigned
    ? item.multiAssignments.length
    : item.identificationNumber
    ? 1
    : 0;
  const badgeColor = item.isMultiAssigned ? badgeError : badgePrimary;

  return (
    <ListItemButton
      dense
      key={item.imei}
      style={style}
      component={NavLink}
      to={`/resources/telematicsboxes/${encodeURIComponent(item.imei)}`}
      selected={id === item.imei}
    >
      <ListItemAvatar>
        {badgeNumber > 0 ? (
          <Badge badgeContent={badgeNumber} color={badgeColor}>
            <Avatar>
              <RouterIcon />
            </Avatar>
          </Badge>
        ) : (
          <Avatar>
            <RouterIcon />
          </Avatar>
        )}
      </ListItemAvatar>
      <ListItemText
        primary={item.imei}
        secondary={
          sortBy === 'mostRecentTime'
            ? !!item[sortBy] && moment(item[sortBy]).format(dateFormat)
            : sortBy === 'imei'
            ? ''
            : item[sortBy]
        }
      />
    </ListItemButton>
  );
};

// rerendering the list every time a key was pressed was slow,
// results in nothing appearing in text box until .5s later
// this will only render it if the list changes
const FastList = memo(({ filteredList, id, sortBy }) => {
  return (
    filteredList.length > 0 && (
      <Box sx={{ height: 1 }}>
        <AutoSizer>
          {({ width, height }) => (
            <FixedSizeList
              height={height}
              overscanCount={10}
              itemData={[filteredList, id, sortBy]}
              itemCount={filteredList.length}
              itemSize={60}
              width={width}
            >
              {Row}
            </FixedSizeList>
          )}
        </AutoSizer>
      </Box>
    )
  );
});

function filterList(list, searchTerm, filter, propsToSearch, sortBy, sortDesc) {
  return _.orderBy(
    list
      .filter(
        (item) =>
          !searchTerm ||
          propsToSearch.some((prop) =>
            _.get(item, prop)?.toLowerCase()?.includes(searchTerm.toLowerCase())
          )
      )
      .filter((item) => {
        switch (filter) {
          case 'noVehicle':
            return !item.identificationNumber;
          case 'neverPolled':
            return !item.mostRecentTime;
          case 'multiAssign':
            return item.isMultiAssigned;
          default:
            return true;
        }
      }),
    [sortBy],
    [sortDesc ? 'desc' : 'asc']
  );
}

export default function TelematicsBoxList() {
  const { id } = useParams();
  const boxes = useSelector((state) => state.telematicsBoxes.boxesByImei);
  const [filterText, setFilterText] = useState('');
  const [filteredList, setFilteredList] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [showSettings, setShowSettings] = useState(false);
  const [sortBy, setSortBy] = useState('imei');
  const [sortDesc, setSortDesc] = useState(false);
  const isXs = useMediaQuery((theme) => theme.breakpoints.only('xs'));

  const sortOptions = [
    ...(useReducedResourceInformation
      ? []
      : [{ label: 'Registration', value: 'registrationNumber' }]),
    { label: 'Fleet Number', value: 'fleetNumber' },
    { label: 'Last Poll Time', value: 'mostRecentTime' },
    { label: 'VIN', value: 'identificationNumber' },
    { label: 'IMEI', value: 'imei' },
  ];

  const propsToSearch = Object.values(sortOptions)
    .map((o) => o.value)
    .filter((s) => s !== 'mostRecentTime');

  const filters = [
    { label: 'All', value: 'all' },
    { label: 'No vehicle', value: 'noVehicle' },
    { label: 'Never polled', value: 'neverPolled' },
    { label: 'Multiple assignments', value: 'multiAssign' },
  ];

  const prevBoxes = usePrevious(boxes);
  useEffect(() => {
    if (boxes !== prevBoxes) {
      setFilteredList(
        filterList(
          Object.values(boxes),
          searchText,
          filterText,
          propsToSearch,
          sortBy,
          sortDesc
        )
      );
    }
  }, [
    boxes,
    searchText,
    filterText,
    propsToSearch,
    sortBy,
    sortDesc,
    prevBoxes,
  ]);

  function updateList({
    search = searchText,
    filter = filterText,
    sortProp = sortBy,
    sortDescending = sortDesc,
  }) {
    setFilteredList(
      filterList(
        Object.values(boxes),
        search,
        filter,
        propsToSearch,
        sortProp,
        sortDescending
      )
    );
  }

  const debouncedFilterList = _.debounce(updateList, 300, { trailing: true });

  function handleSearchChange(event) {
    const term = event.target.value;

    setSearchText(term);
    debouncedFilterList({ search: term });
  }

  function handleFilterChange(event) {
    const term = event.target.value;

    setFilterText(term);
    updateList({ filter: term });
  }

  function handleSortByChange(event) {
    const prop = event.target.value;

    setSortBy(prop);
    updateList({ sortProp: prop });
  }

  function handleSortToggle() {
    setSortDesc(!sortDesc);
    updateList({ sortDescending: !sortDesc });
  }

  function handleSettingsToggle() {
    setShowSettings(!showSettings);
  }

  async function handleCsvClick() {
    downloadCSV(
      Object.values(boxes).map((b) => ({
        ...b,
        //imei: `\t${b.imei}`,
        lastPollTime: b.mostRecentTime
          ? format(new Date(b.mostRecentTime), 'dd/MM/yyyy HH:mm:ss')
          : '',
      })),
      `Telematics Boxes ${moment().format('YYYY-MM-DD')}.csv`,
      [
        { label: 'IMEI', key: 'imei' },
        { label: 'Last poll time', key: 'lastPollTime' },
        { label: 'Fleet Number', key: 'fleetNumber' },
        ...(useReducedResourceInformation
          ? []
          : [{ label: 'Registration', key: 'registrationNumber' }]),
        { label: 'VIN', key: 'identificationNumber' },
      ]
    );
  }

  async function handleJsonClick() {
    downloadJSON(Object.values(boxes), 'telematicsBoxes.json');
  }

  return (
    (!isXs || !id) && (
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          width: 280,
          [theme.breakpoints.down('sm')]: {
            width: 1,
          },
          height: 1,
        })}
      >
        <Box>
          <Stack
            direction="row"
            sx={{ p: 1, pr: 0.5 }}
            spacing={0.5}
            alignItems="center"
          >
            <SearchBox
              value={searchText}
              onChange={handleSearchChange}
              count={`${filteredList.length}/${Object.keys(boxes).length}`}
              sx={{ flexGrow: 1 }}
            />
            <IconButton
              size="small"
              title={showSettings ? 'Hide settings' : 'Show settings'}
              onClick={handleSettingsToggle}
            >
              <SettingsIcon
                fontSize="inherit"
                color={showSettings ? 'primary' : 'inherit'}
              />
            </IconButton>
          </Stack>
          <Collapse in={showSettings} unmountOnExit>
            <Stack sx={{ p: 1 }} spacing={1.5}>
              <TextField
                size="small"
                select
                fullWidth
                label="Filter"
                value={filterText}
                onChange={handleFilterChange}
              >
                {filters.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                size="small"
                select
                fullWidth
                label="Sort by"
                value={sortBy}
                onChange={handleSortByChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        title={sortDesc ? 'Descending' : 'Ascending'}
                        sx={(theme) => ({
                          transform: sortDesc
                            ? 'rotate(180deg)'
                            : 'rotate(0deg)',
                          transition: theme.transitions.create('transform', {
                            duration: theme.transitions.duration.shortest,
                          }),
                        })}
                        onClick={handleSortToggle}
                        size="small"
                      >
                        <ArrowUpwardIcon fontSize="inherit" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              >
                {sortOptions.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>
              <Divider>
                <Typography variant="subtitle2" color="textSecondary">
                  Export
                </Typography>
              </Divider>
              <Stack
                direction="row"
                // sx={{ p: 1 }}
                spacing={1.5}
                justifyContent="center"
              >
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  disableElevation
                  onClick={handleCsvClick}
                >
                  CSV
                </Button>
                <Button
                  fullWidth
                  color="secondary"
                  variant="contained"
                  disableElevation
                  onClick={handleJsonClick}
                >
                  JSON
                </Button>
              </Stack>
              <Divider />
            </Stack>
          </Collapse>
        </Box>
        <FastList filteredList={filteredList} id={id} sortBy={sortBy} />
      </Box>
    )
  );
}
