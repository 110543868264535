import LiveListItem from './LiveListItem';

function PlanLiveListIcon(props) {
  return (
    <LiveListItem
      type="plans"
      primaryPath="title"
      secondaryPath="id"
      hideFollow={!props.item.boundary}
      {...props}
    />
  );
}
export default PlanLiveListIcon;
