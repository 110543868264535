import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  // CardMedia,
  Typography,
} from '@mui/material';
import {
  FilterCenterFocus as FocusIcon,
  GpsFixed as FollowAllIcon,
} from '@mui/icons-material';
import _ from 'lodash';
import { Fragment, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_LIVE_FILTER_OVERRIDE } from '../../../actions';
import { liveFilters } from '../../../data/constants';
import { usePrevious } from '../../../hooks';
import avatarForItem from './avatarUtility';
import IncidentListItem from './IncidentLiveListItem';
import PeopleListItem from './PeopleLiveListItem';
import VehicleListItem from './VehicleLiveListItem';

// TODO refactor as it's shared with livelist index.js
const typeToListItemMap = {
  vehicles: VehicleListItem,
  people: PeopleListItem,
  incidents: IncidentListItem,
};

function CallSignDetail({
  item: callSign,
  onSubItemClick,
  onSubItemHover,
  onFollowToggle,
  onFollowBulk,
  followedIdsByType,
  hoveredId,
}) {
  // need access to all the resources for any that have this tag
  const state = useSelector((state) => state.live);

  const filterOverride = useSelector(
    (state) => state.live.filteredInIdsByTypeOverride
  );

  const dispatch = useDispatch();

  const idsByType = {};
  Object.keys(callSign.itemsByType).forEach((type) => {
    const idsOfType = Object.keys(callSign.itemsByType[type]);
    if (idsOfType.length > 0) {
      idsByType[type] = idsOfType;
    }
  });

  // if the tag overrides the filter it'll be the owner
  const callSignOverridesFilter = filterOverride?.owner === callSign.id;

  const override = useMemo(
    () => ({
      // make every type filtered out
      ...Object.fromEntries(Object.keys(liveFilters).map((k) => [k, {}])),
      // except for this call sign's items and this call sign
      ...callSign.itemsByType,
      callSigns: { [callSign.id]: true },
      // and set the owner of the override (when we nav away etc.)
      owner: callSign.id,
    }),
    [callSign]
  );

  const allFollowed = Object.keys(override)
    .filter((type) => !['owner', 'callSigns'].includes(type))
    .every((type) =>
      Object.keys(override[type]).every((id) => followedIdsByType[type]?.[id])
    );

  function handleFilterToggle() {
    dispatch({
      type: UPDATE_LIVE_FILTER_OVERRIDE,
      payload: callSignOverridesFilter ? null : override,
    });
  }

  const handleFollowAllToggle = () => {
    const newFollow = !allFollowed;

    onFollowBulk(_.omit(override, ['owner', 'callSigns']), newFollow);
  };

  // if the tagged items change (e.g. they hadn't fully loaded yet)
  // re-dispatch the overrides if necessary
  const prevOverride = usePrevious(override);
  useEffect(() => {
    if (!_.isEqual(override, prevOverride)) {
      if (callSignOverridesFilter) {
        dispatch({
          type: UPDATE_LIVE_FILTER_OVERRIDE,
          payload: override,
        });
      }
    }
  }, [dispatch, prevOverride, override, callSignOverridesFilter]);

  const orderedTypes = {
    incidents: 'Incident',
    people: 'People',
    vehicles: 'Vehicles',
  };

  return (
    <Card sx={{ m: 1 }}>
      <Helmet>
        <title>{`IR3 | Live | Call Signs | ${callSign.id}`}</title>
      </Helmet>
      <CardHeader
        avatar={avatarForItem(callSign, 'callSigns')}
        title={callSign.id}
        subheader={callSign.status}
        action={
          <Fragment>
            <IconButton
              aria-label="Focus on call sign"
              onClick={handleFilterToggle}
              title={`Focus on call sign`}
              size="large"
            >
              <FocusIcon
                fontSize="small"
                color={callSignOverridesFilter ? 'primary' : 'inherit'}
              />
            </IconButton>
            <IconButton
              aria-label="Toggle follow call sign"
              onClick={handleFollowAllToggle}
              title={`Toggle follow call sign`}
              size="large"
            >
              <FollowAllIcon
                fontSize="small"
                color={allFollowed ? 'primary' : 'inherit'}
              />
            </IconButton>
          </Fragment>
        }
      />
      <CardContent>
        {Object.keys(orderedTypes)
          .filter(
            (type) => Object.keys(callSign.itemsByType[type] || {}).length > 0
          )
          .map((type) => (
            <Fragment key={type}>
              <Typography variant="subtitle2" color="textSecondary">
                {orderedTypes[type]}
              </Typography>
              {Object.keys(callSign.itemsByType[type]).map((id) => {
                // try to find the item
                let item = state[type]?.[id];

                // if it's an incident it may be too old to have been retrieved
                // use a placeholder to lazy-load it
                if (!item && type === 'incidents') {
                  item = { id };
                }

                const ListComponent = typeToListItemMap[type];

                return (
                  <ListComponent
                    key={id}
                    onClick={onSubItemClick}
                    highlighted={hoveredId === id}
                    onHoverItem={onSubItemHover}
                    onFollowToggle={onFollowToggle}
                    followedIdsByType={followedIdsByType}
                    item={item}
                    // hideFollow={true}
                  />
                );
              })}
            </Fragment>
          ))}
      </CardContent>
    </Card>
  );
}

export default CallSignDetail;
