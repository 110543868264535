import { Fragment, useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  Typography,
  IconButton,
} from '@mui/material';
import { LocationSearching as FollowIcon } from '@mui/icons-material';
import moment from 'moment';
import _ from 'lodash';
import { Helmet } from 'react-helmet-async';
import { useSelector, useDispatch } from 'react-redux';
import avatarForItem from './avatarUtility';
import VehicleLiveListItem from './VehicleLiveListItem';
import PeopleLiveListItem from './PeopleLiveListItem';
import { areasToLabelAccessors, ItemRows, toLabelAccessors } from './ItemRows';
import { FETCH_LIVE_ACCEL_POLL } from '../../../actions/types';
import AccelerometerChart from '../../events/AccelerometerEvents/AccelerometerChart';

const { useReducedResourceInformation } = window.config;
// example object
// $type: "add"
// eventType: "ACCELEROMETERALERT"
// identifier: "ACCELEROMETERALERT-2018-05-17T13:50:35.000Z-HA0812"
// locations: Array(1)
// 0: {code: "OS_WMIDQNT", name: "West Mids RCC & Quinton Outstation", type: "Base"}
// people: Array(0)
// length: 0
// point:
// $reql_type$: "GEOMETRY"
// coordinates: (2) [-2.009744, 52.453684]
// type: "Point"
// searchString: "accelerometeralert+accelerometeralert-2018-05-17t13:50:35.000z-ha0812+os_wmidqnt+west mids rcc & quinton outstation+base++geometry++point+2018-05-17t13:50:35.000z+west midlands+region+ha0812+west mids rcc & quinton outstation+ha0812+bw17 ztf+traffic officer+359394057295853+add"
// tagChanged: true
// time: "2018-05-17T13:50:35.000Z"
// vehicle:
// areas: Array(1)
// 0: {name: "West Midlands", type: "region"}
// fleetNumber: "HA0812"
// homeStation: "West Mids RCC & Quinton Outstation"
// identificationNumber: "HA0812"
// registrationNumber: "BW17 ZTF"
// role: "Traffic Officer"
// telematicsBoxImei: "359394057295853"

function EventLiveItem({
  item,
  onSubItemClick,
  onSubItemHover,
  onFollowToggle,
  followedIdsByType,
  hoveredId,
}) {
  const type = 'events';
  const following = followedIdsByType?.[type]?.[item.id];
  const hideFollow = !item.point;

  const handleFollowToggle = () => {
    onFollowToggle(type, item.id);
  };

  // find associated vehicle
  const vehicles = useSelector((state) => state.live.vehicles);
  const vehicle = vehicles[item.vehicle.identificationNumber];

  const people = useSelector((state) => state.live.people);
  const matchedDriver = item.driver && people[item.driver.code];

  const fullAccelEvents = useSelector(
    (state) => state.live.accelerometerEvents
  );
  const [matchedAccelData, setMatchedAccelData] = useState();
  useEffect(() => {
    if (!matchedAccelData && fullAccelEvents) {
      const data = Object.values(fullAccelEvents).find(
        (d) =>
          d.vehicle.identificationNumber ===
            item.vehicle.identificationNumber &&
          d.startTime <= item.time &&
          item.time <= d.endTime
      );

      if (data) {
        // change to mph
        data.accelerometerData.forEach(
          (a) =>
            a.time.includes('.000') &&
            (a.speedMilesPerHour = _.round(
              a.speedKilometresPerHour * 0.62137119,
              2
            ))
        );
        setMatchedAccelData(data);
      }
    }
  }, [fullAccelEvents, item, matchedAccelData]);

  function getDriverSection(driverName, driver) {
    driver = matchedDriver || driver;
    if (!driver) {
      return <Fragment />;
    }

    let title = driverName;
    if (title === null || title.match(/^ *$/) !== null) {
      title = driver.code;
    }

    function handleClick() {
      if (matchedDriver) {
        onSubItemClick({ id: matchedDriver.id, type: 'people' });
      } // o/w nothing to nav to
    }

    return (
      <PeopleLiveListItem
        onClick={matchedDriver ? handleClick : undefined}
        onHoverItem={matchedDriver ? onSubItemHover : undefined}
        item={matchedDriver || { id: '_nomatch', name: driverName, role: '' }}
        highlighted={hoveredId === driver.code}
        onFollowToggle={onFollowToggle}
        followedIdsByType={followedIdsByType}
        hideFollow={!matchedDriver}
      />
    );
  }

  const dispatch = useDispatch();
  useEffect(() => {
    if (item.poll === undefined) {
      dispatch({
        type: FETCH_LIVE_ACCEL_POLL,
        payload: item,
      });
    }
  }, [item, dispatch]);

  const sections = {
    details: [
      {
        label: 'Event time',
        value: moment(item.time).format('DD/MM/YYYY HH:mm:ss'),
      },
      { label: 'Type', value: item.eventType },
      ...toLabelAccessors(item.locations, 'type', 'name'),
      item.poll?.speedKilometresPerHour && {
        label: 'Speed',
        value: `${_.round(
          item.poll.speedKilometresPerHour * 0.62137199,
          2
        )} MPH`,
      },
      ...Object.keys(window.config.dioStates).map(
        (key) =>
          item.poll?.[key] !== undefined && {
            label: window.config.dioStates[key],
            value: item.poll[key] ? 'On' : 'Off',
          }
      ),
      item.poll?.error && {
        label: 'Error',
        value: 'unable to retrive poll details',
      },
      !matchedAccelData && { label: 'Has Event Data', value: 'No' },
    ].filter(Boolean),
    vehicle: [
      { label: 'Fleet Number', value: item.vehicle.fleetNumber },
      ...(useReducedResourceInformation
        ? [{ label: 'Home Location', value: item.vehicle.homeStation }]
        : [
            { label: 'Registration', value: item.vehicle.registrationNumber },
            { label: 'Role', value: item.vehicle.role },
            { label: 'Home Station', value: item.vehicle.homeStation },
            {
              label: 'Identification Number',
              value: item.vehicle.identificationNumber,
            },
          ]),
      { label: 'IMEI', value: item.vehicle.telematicsBoxImei },
      ...areasToLabelAccessors(item.vehicle.areas),
    ],
    data: [
      {
        label: 'Max Horizontal Force (g)',
        value: matchedAccelData?.maximumForces?.horizontal,
      },
      {
        label: 'Max Vertical Force (g)',
        value: matchedAccelData?.maximumForces?.vertical,
      },
      {
        label: 'Max Lateral Force (g)',
        value: matchedAccelData?.maximumForces?.lateral,
      },
    ],
  };

  return (
    <Card sx={{ m: 1 }}>
      <Helmet>
        <title>{`IR3 | Live | Events | ${item.identifier}`}</title>
      </Helmet>
      <CardHeader
        avatar={avatarForItem(item, 'events')}
        title={
          useReducedResourceInformation
            ? item.vehicle.fleetNumber
            : item.vehicle.registrationNumber
        }
        subheader={moment(item.time).format('DD/MM/YYYY HH:mm')}
        action={
          <Fragment>
            {!hideFollow && (
              <IconButton
                aria-label="Toggle follow event"
                onClick={handleFollowToggle}
                title="Toggle follow event"
                size="large"
              >
                <FollowIcon
                  fontSize="small"
                  color={following ? 'primary' : 'disabled'}
                />
              </IconButton>
            )}
          </Fragment>
        }
      />
      <CardContent>
        <Typography variant="subtitle2" color="textSecondary">
          Alert Details
        </Typography>
        <Table size="small" sx={{ mt: 1, mb: 2 }}>
          <TableBody>{ItemRows(sections.details)}</TableBody>
        </Table>
        {matchedAccelData?.accelerometerData && (
          <Fragment>
            <Typography variant="subtitle2" color="textSecondary">
              Event Data
            </Typography>
            <Table size="small" sx={{ mt: 1, mb: 2 }}>
              <TableBody>{ItemRows(sections.data)}</TableBody>
            </Table>
            <AccelerometerChart
              data={matchedAccelData.accelerometerData}
              triggerPoint={matchedAccelData.deviceProperties.triggerPoint}
              dateFormat={'HH:mm:ss'}
              margin={{ top: 5, right: -5, bottom: 0, left: -5 }}
            />
          </Fragment>
        )}
        <Typography variant="subtitle2" color="textSecondary">
          Driver
        </Typography>
        {getDriverSection(item.driverName, item.driver)}
        <Typography variant="subtitle2" color="textSecondary">
          Vehicle
        </Typography>
        {vehicle ? (
          <VehicleLiveListItem
            key={vehicle.id}
            onClick={onSubItemClick}
            highlighted={hoveredId === vehicle.id}
            onHoverItem={onSubItemHover}
            onFollowToggle={onFollowToggle}
            followedIdsByType={followedIdsByType}
            item={vehicle}
            hideLive={false}
          />
        ) : (
          <Table size="small" sx={{ mt: 1, mb: 2 }}>
            <TableBody>{ItemRows(sections.vehicle)}</TableBody>
          </Table>
        )}
      </CardContent>
    </Card>
  );
}

export default EventLiveItem;
