// source organizer might remove this, here it is for reference
// import { CancelToken } from 'axios';
import { CancelToken } from 'axios';
import _ from 'lodash';
import moment from 'moment';
import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, map, mergeMap, takeUntil, tap } from 'rxjs/operators';
import {
  FILTER_PERSON_DAILY_ACTIVITY,
  FETCH_PERSON_DAILY_ACTIVITY,
  FETCH_PERSON_DAILY_ACTIVITY_CANCELLED,
  FETCH_PERSON_DAILY_ACTIVITY_FAILURE,
  FETCH_PERSON_DAILY_ACTIVITY_SUCCESS,
  FETCH_PERSON_HOURLY_ACTIVITY,
  FETCH_PERSON_HOURLY_ACTIVITY_CANCELLED,
  FETCH_PERSON_HOURLY_ACTIVITY_FAILURE,
  FETCH_PERSON_HOURLY_ACTIVITY_SUCCESS,
  LOAD_PERSON_DAILY_ACTIVITY_QUERY,
  LOAD_PERSON_DAILY_ACTIVITY_QUERY_FAILURE,
  LOAD_PERSON_DAILY_ACTIVITY_QUERY_SUCCESS,
  LOAD_PERSON_HOURLY_ACTIVITY,
  LOAD_PERSON_HOURLY_ACTIVITY_FAILURE,
  LOAD_PERSON_HOURLY_ACTIVITY_SUCCESS,
} from '../actions';
import api from '../apis';
import {
  getHeaders,
  log,
  reduceByType as reduceAreas,
  areasFilter,
  getGroupKey,
} from '../apis/utilities';
import db, { fetchCachedData } from '../data/db';

const { useReducedResourceInformation } = window.config;

let cancel;

function warnOf(type) {
  return (error) => {
    console.error(error);

    return of({
      type,
      payload: error.message,
    });
  };
}

function dayOfWeekStartingMonday(time) {
  const dayOfWeekStartingSunday = new Date(time).getDay();

  return (dayOfWeekStartingSunday + 6) % 7;
}

function personActivityDataFilter(record, filter) {
  if (filter.code.length !== 0 && !filter.code.includes(record.code)) {
    return false;
  }

  if (filter.name.length !== 0 && !filter.name.includes(record.name)) {
    return false;
  }

  if (filter.role.length !== 0 && !filter.role.includes(record.role)) {
    return false;
  }

  if (filter.hour.length !== 24) {
    // can't reject the entire record but can filter a copy of hourly
    record.filteredHourly = _.pick(record.hourly, filter.hour);
  } else {
    delete record.filteredHourly;
  }

  if (filter.day.length !== 7 && !filter.day.includes(record.day)) {
    return false;
  }

  return areasFilter(record, filter);
}

function getPersonActivityFilterAndGroupByValues(data, filter) {
  const { areas: _, ...fields } = filter;
  const result = { areas: {} };
  const areas = Array.from(
    new Set([].concat(...data.map((record) => Object.keys(record.areas))))
  );

  for (const key in fields) {
    const keyFilter = { ...filter, [key]: [] };
    result[key] = Array.from(
      new Set(
        data
          .filter((record) => personActivityDataFilter(record, keyFilter))
          .map((record) => record[key])
      )
    )
      .filter((value) => value !== undefined)
      .sort();
  }

  for (const area of areas) {
    const keyFilter = { ...filter, areas: { ...filter.areas, [area]: [] } };
    result.areas[area] = Array.from(
      new Set(
        data
          .filter((record) => personActivityDataFilter(record, keyFilter))
          .map((record) => record.areas[area])
      )
    )
      .filter((value) => value !== undefined)
      .sort();
  }

  return {
    filterValues: result,
    groupByValues: useReducedResourceInformation
      ? ['all', 'date', 'code', ...areas]
      : ['all', 'date', 'name', 'role', ...areas],
  };
}

function getPersonDailyActivity(
  rawData,
  groupBy,
  orderBy,
  order,
  chartType,
  hoursInADay
) {
  const groupedData =
    rawData.length === 0
      ? new Map()
      : rawData.reduce((accumulator, record) => {
          const groupKey = getGroupKey(groupBy, record);
          let current = accumulator.get(groupKey);

          if (!current) {
            current = {
              group: groupKey,
              loggedInSeconds: [],
              inBaseSeconds: [],
              respondingToIncidentSeconds: [],
              attendingObjectiveSeconds: [],
              tripsDurationSeconds: [],
              inHomeWardSeconds: [],
              doubleCrewingSeconds: [],
              baseVisits: [],
              tripsDistanceKilometres: [],
              names: [],
            };
            accumulator.set(groupKey, current);
          }

          let proxy = record;
          // if some hourly filtering was happening, we need to get
          // the properties by summing up the hourly ones
          if (record.filteredHourly) {
            proxy.loggedInSeconds = 0;
            proxy.inBaseSeconds = 0;
            proxy.respondingToIncidentSeconds = 0;
            proxy.attendingObjectiveSeconds = 0;
            proxy.tripsDurationSeconds = 0;
            proxy.inHomeWardSeconds = 0;
            proxy.doubleCrewingSeconds = 0;
            proxy.baseVisits = 0;
            proxy.tripsDistanceKilometres = 0;

            Object.keys(record.filteredHourly).forEach((hour) => {
              Object.keys(record.filteredHourly[hour] ?? {}).forEach((key) => {
                proxy[key] += record.filteredHourly[hour][key];
              });
            });
          }

          current.loggedInSeconds.push(proxy.loggedInSeconds);
          current.inBaseSeconds.push(proxy.inBaseSeconds);
          current.respondingToIncidentSeconds.push(
            proxy.respondingToIncidentSeconds
          );
          current.attendingObjectiveSeconds.push(
            proxy.attendingObjectiveSeconds
          );
          current.tripsDurationSeconds.push(proxy.tripsDurationSeconds);
          current.inHomeWardSeconds.push(proxy.inHomeWardSeconds);
          current.doubleCrewingSeconds.push(proxy.doubleCrewingSeconds);
          current.baseVisits.push(proxy.baseVisits);
          current.tripsDistanceKilometres.push(proxy.tripsDistanceKilometres);

          if (!current.names.includes(record.name)) {
            current.names.push(record.name);
          }

          return accumulator;
        }, new Map());

  function average(groupedValues) {
    return groupedValues.reduce((a, b) => a + b, 0) / groupedValues.length;
  }

  function roundedAverageAsPercentage(groupedValues) {
    const averageSeconds = average(groupedValues);
    const averageHours = averageSeconds / 3600;
    const percent = (100 * averageHours) / hoursInADay;

    return _.round(percent, 2);
  }

  function roundedAverage(groupedValues) {
    const averageSeconds = average(groupedValues);
    const averageHours = averageSeconds / 3600;

    return _.round(averageHours, 2);
  }

  const averageFunction =
    chartType === 'percentage' ? roundedAverageAsPercentage : roundedAverage;

  const groupedArray = Array.from(groupedData.values());
  const singularGroups = groupedArray.every((v) => v.names.length === 1);
  const countText = (count) => (singularGroups ? '' : `(${count})`);
  const data = groupedArray.map((group) => ({
    group:
      groupBy === 'date'
        ? `${moment(group.group).format('DD/MM/YYYY')} ${countText(
            group.names.length
          )}`
        : `${group.group} ${countText(group.names.length)}`,
    // 'Group Size': group.names.length,
    onRadio: averageFunction(group.loggedInSeconds),
    inBase: averageFunction(group.inBaseSeconds),
    respondingToIncidents: averageFunction(group.respondingToIncidentSeconds),
    attendingObjectives: averageFunction(group.attendingObjectiveSeconds),
    driving: averageFunction(group.tripsDurationSeconds),
    inHomeWard: averageFunction(group.inHomeWardSeconds),
    doubleCrewing: averageFunction(group.doubleCrewingSeconds),
    totalDrivingMileage: _.round(
      group.tripsDistanceKilometres.reduce((a, b) => a + b, 0) * 0.62137119,
      2
    ),
    averageDrivingMileage: _.round(
      (group.tripsDistanceKilometres.reduce((a, b) => a + b, 0) * 0.62137119) /
        group.names.length,
      2
    ),
    dailyDrivingMileage: _.round(
      average(group.tripsDistanceKilometres) * 0.62137119,
      2
    ),
    totalBaseVisits: _.round(
      group.baseVisits.reduce((a, b) => a + b, 0),
      2
    ),
    averageBaseVisits: _.round(average(group.baseVisits), 2),
    dailyBaseVisits: _.round(average(group.baseVisits), 2),
  }));
  data.sort((a, b) =>
    groupBy === 'date'
      ? moment(a.group, 'DD/MM/YYYY').diff(moment(b.group, 'DD/MM/YYYY'))
      : (a.group || '').localeCompare(b.group)
  );

  if (orderBy === 'date' || orderBy === 'month') {
    data.sort((a, b) =>
      moment(a.group, 'DD/MM/YYYY').diff(moment(b.group, 'DD/MM/YYYY'))
    );

    return order === 'asc' ? data : data.reverse();
  } else {
    return _.orderBy(data, orderBy, order);
  }
}

async function fetchPersonDailyActivityRequest(
  query,
  filter,
  groupBy,
  orderBy,
  order,
  chartType
) {
  const reportName = 'personDailyActivity';
  const cachedParameters = await db.parameters.get(reportName);

  if (
    !_.isEmpty(cachedParameters?.query) &&
    _.isEqual(cachedParameters?.query, query)
  ) {
    return getPersonDailyActivityCachedData(
      reportName,
      query,
      filter,
      groupBy,
      orderBy,
      order,
      chartType
    );
  } else if (!_.isEmpty(query)) {
    return fetchPersonDailyActivityData(
      reportName,
      query,
      filter,
      groupBy,
      orderBy,
      order,
      chartType
    );
  }
}

async function fetchPersonDailyActivityData(
  reportName,
  query,
  filter,
  groupBy,
  orderBy,
  order,
  chartType
) {
  const response = await api.get('/personDailySummaries', {
    params: {
      query,
      projection: {
        time: true,
        hourly: true,
        person: true,
        loggedInSeconds: true,
        inBaseSeconds: true,
        attendingObjectiveSeconds: true,
        inHomeWardSeconds: true,
        doubleCrewingSeconds: true,
        baseVisits: true,
        respondingToIncidentSeconds: true,
        trips: true,
        tripsDurationSeconds: true,
        tripsDistanceKilometres: true,
      },
    },
    headers: getHeaders(),
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
  });

  const data = response.data.map(
    ({
      person: { areas, collarNumber, forenames, surname, role } = {},
      time,
      ...record
    }) => ({
      ...record,
      name: `[${collarNumber}] ${forenames} ${surname}`,
      role,
      time,
      day: dayOfWeekStartingMonday(time),
      areas: reduceAreas(areas),
    })
  );

  await db.personDailyActivity.clear();
  await db.personDailyActivity.bulkAdd(data);
  await db.parameters.put({ store: reportName, query });

  const filteredData = data.filter((record) =>
    personActivityDataFilter(record, filter)
  );

  const results = {
    query,
    filter,
    groupBy,
    orderBy,
    order,
    chartType,
    ...getPersonActivityFilterAndGroupByValues(data, filter),
    data: getPersonDailyActivity(
      filteredData,
      groupBy,
      orderBy,
      order,
      chartType,
      filter.hour?.length ?? 24
    ),
  };

  log('Read', 'Person Daily Activity', query);

  return results;
}

async function getPersonDailyActivityCachedData(
  reportName,
  query,
  filter,
  groupBy,
  orderBy,
  order,
  chartType
) {
  const data = await fetchCachedData(reportName);
  const filteredData = data.filter((record) =>
    personActivityDataFilter(record, filter)
  );

  const results = {
    query,
    filter,
    groupBy,
    orderBy,
    order,
    chartType,
    ...getPersonActivityFilterAndGroupByValues(data, filter),
    data: getPersonDailyActivity(
      filteredData,
      groupBy,
      orderBy,
      order,
      chartType,
      filter.hour?.length ?? 24
    ),
  };

  log('Load', 'Person Daily Activity', query);

  return results;
}

export function fetchPersonDailyActivityEpic(action$) {
  return action$.pipe(
    ofType(FETCH_PERSON_DAILY_ACTIVITY, FILTER_PERSON_DAILY_ACTIVITY),
    mergeMap(
      ({ payload: { query, filter, groupBy, orderBy, order, chartType } }) =>
        from(
          fetchPersonDailyActivityRequest(
            query,
            filter,
            groupBy,
            orderBy,
            order,
            chartType
          )
        ).pipe(
          map((payload) => ({
            type: FETCH_PERSON_DAILY_ACTIVITY_SUCCESS,
            payload,
          })),
          takeUntil(
            action$.pipe(
              ofType(FETCH_PERSON_DAILY_ACTIVITY_CANCELLED),
              tap((ev) => cancel('cancelled'))
            )
          ),
          catchError(warnOf(FETCH_PERSON_DAILY_ACTIVITY_FAILURE))
        )
    )
  );
}

async function loadPersonDailyActivityRequest() {
  const reportName = 'personDailyActivity';
  const parameters = await db.parameters.get(reportName);

  const results = {
    query: parameters?.query || {},
  };

  log('Load', 'Person Daily Activity', parameters);

  return results;
}

export function loadPersonDailyActivityEpic(action$) {
  return action$.pipe(
    ofType(LOAD_PERSON_DAILY_ACTIVITY_QUERY),
    mergeMap(() =>
      from(loadPersonDailyActivityRequest()).pipe(
        map((payload) => ({
          type: LOAD_PERSON_DAILY_ACTIVITY_QUERY_SUCCESS,
          payload,
        })),
        catchError(warnOf(LOAD_PERSON_DAILY_ACTIVITY_QUERY_FAILURE))
      )
    )
  );
}

function getPersonHourlyActivity(rawData) {
  const groupedData =
    rawData.length === 0
      ? new Map()
      : rawData.reduce((accumulator, record) => {
          const hour = moment(record.time).hour();
          let current = accumulator.get(hour);

          if (!current) {
            current = {
              hour,
              loggedInSeconds: [],
              inBaseSeconds: [],
              respondingToIncidentSeconds: [],
              attendingObjectiveSeconds: [],
              tripsDurationSeconds: [],
              inHomeWardSeconds: [],
              doubleCrewingSeconds: [],
            };
            accumulator.set(hour, current);
          }

          current.loggedInSeconds.push(record.loggedInSeconds);
          current.inBaseSeconds.push(record.inBaseSeconds);
          current.respondingToIncidentSeconds.push(
            record.respondingToIncidentSeconds
          );
          current.attendingObjectiveSeconds.push(
            record.attendingObjectiveSeconds
          );
          current.tripsDurationSeconds.push(record.tripsDurationSeconds);
          current.inHomeWardSeconds.push(record.inHomeWardSeconds);
          current.doubleCrewingSeconds.push(record.doubleCrewingSeconds);

          return accumulator;
        }, new Map());

  const data = Array.from(groupedData.values()).map((group) => ({
    Hour: moment({ hour: group.hour }).format('HH:mm'),
    onRadio: _.round(
      group.loggedInSeconds.reduce((a, b) => a + b, 0) /
        group.loggedInSeconds.length /
        60,
      2
    ),
    inBase: _.round(
      group.inBaseSeconds.reduce((a, b) => a + b, 0) /
        group.inBaseSeconds.length /
        60,
      2
    ),
    respondingToIncidents: _.round(
      group.respondingToIncidentSeconds.reduce((a, b) => a + b, 0) /
        group.respondingToIncidentSeconds.length /
        60,
      2
    ),
    attendingObjectives: _.round(
      group.attendingObjectiveSeconds.reduce((a, b) => a + b, 0) /
        group.attendingObjectiveSeconds.length /
        60,
      2
    ),
    driving: _.round(
      group.tripsDurationSeconds.reduce((a, b) => a + b, 0) /
        group.tripsDurationSeconds.length /
        60,
      2
    ),
    inHomeWard: _.round(
      group.inHomeWardSeconds.reduce((a, b) => a + b, 0) /
        group.inHomeWardSeconds.length /
        60,
      2
    ),
    doubleCrewing: _.round(
      group.doubleCrewingSeconds.reduce((a, b) => a + b, 0) /
        group.doubleCrewingSeconds.length /
        60,
      2
    ),
  }));
  data.sort((a, b) => a.Hour.localeCompare(b.Hour));

  return data;
}

async function fetchPersonHourlyActivityRequest(query, filter) {
  const response = await api.get('/personHourlySummaries', {
    params: {
      query,
      projection: {
        time: true,
        person: true,
        loggedInSeconds: true,
        inBaseSeconds: true,
        attendingObjectiveSeconds: true,
        inHomeWardSeconds: true,
        doubleCrewingSeconds: true,
        baseVisits: true,
        respondingToIncidentSeconds: true,
        trips: true,
        tripsDurationSeconds: true,
        tripsDistanceKilometres: true,
      },
    },
    headers: getHeaders(),
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
  });

  const data = response.data.map(
    ({ areas, collarNumber, name, ...record }) => ({
      ...record,
      name: `[${collarNumber}] ${name}`,
      areas: reduceAreas(areas),
    })
  );

  await db.personHourlyActivity.clear();
  await db.personHourlyActivity.bulkAdd(data);
  await db.parameters.put({
    store: 'personHourlyActivity',
    query,
  });

  const filteredData = data.filter((record) =>
    personActivityDataFilter(record, filter)
  );

  const results = {
    filter,
    ...getPersonActivityFilterAndGroupByValues(data, filter),
    data: getPersonHourlyActivity(filteredData),
  };

  log('Read', 'Person Hourly Activity', query);

  return results;
}

export function fetchPersonHourlyActivityEpic(action$) {
  return action$.pipe(
    ofType(FETCH_PERSON_HOURLY_ACTIVITY),
    mergeMap(({ payload: { query, filter } }) =>
      from(fetchPersonHourlyActivityRequest(query, filter)).pipe(
        map((payload) => ({
          type: FETCH_PERSON_HOURLY_ACTIVITY_SUCCESS,
          payload,
        })),
        takeUntil(
          action$.pipe(
            ofType(FETCH_PERSON_HOURLY_ACTIVITY_CANCELLED),
            tap((ev) => cancel('cancelled'))
          )
        ),
        catchError(warnOf(FETCH_PERSON_HOURLY_ACTIVITY_FAILURE))
      )
    )
  );
}

async function loadPersonHourlyActivityRequest(filter) {
  const data = await db.personHourlyActivity.toArray();
  const parameters = await db.parameters.get('personHourlyActivity');

  const filteredData = data.filter((record) =>
    personActivityDataFilter(record, filter)
  );

  const results = {
    filter,
    ...getPersonActivityFilterAndGroupByValues(data, filter),
    data: getPersonHourlyActivity(filteredData),
  };

  log('Load', 'Person Hourly Activity', parameters);

  return results;
}

export function loadPersonHourlyActivityEpic(action$) {
  return action$.pipe(
    ofType(LOAD_PERSON_HOURLY_ACTIVITY),
    mergeMap(({ payload: filter }) =>
      from(loadPersonHourlyActivityRequest(filter)).pipe(
        map((payload) => ({
          type: LOAD_PERSON_HOURLY_ACTIVITY_SUCCESS,
          payload,
        })),
        catchError(warnOf(LOAD_PERSON_HOURLY_ACTIVITY_FAILURE))
      )
    )
  );
}
