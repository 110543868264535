import { combineReducers } from 'redux';
import briefs from './briefsReducer';
import plans from './plansReducer';
import tags from './tagsReducer';
import queries from './queriesReducer';
import features from './featuresReducer';
import filters from './filtersReducer';
import locations from './locationsReducer';
import objectives from './objectivesReducer';
import people from './peopleReducer';
import replay from './replayReducer';
import retrospectives from './retrospectivesReducer';
import telematicsBoxes from './telematicsBoxesReducer';
import vehicles from './vehiclesReducer';
import reports from './reportsReducer';
import live from './liveReducer';
import utilisation from './utilisationReducer';
import activity from './activityReducer';
import events from './eventsReducer';
import gazetteer from './gazetteerReducer';
import radios from './radiosReducer';
import rfidCards from './rfidCardsReducer';

const rootReducer = combineReducers({
  vehicles,
  people,
  locations,
  briefs,
  objectives,
  features,
  telematicsBoxes,
  filters,
  replay,
  retrospectives,
  reports,
  live,
  utilisation,
  activity,
  events,
  tags,
  plans,
  queries,
  gazetteer,
  radios,
  rfidCards,
});

export default rootReducer;
