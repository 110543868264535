import {
  DOWNLOAD_EVENTS,
  DOWNLOAD_EVENTS_PROGRESS,
  DOWNLOAD_EVENTS_SUCCESS,
  DOWNLOAD_EVENTS_FAILURE,
  DOWNLOAD_EVENTS_CANCELLED,
  FETCH_PAGED_EVENTS,
  FETCH_PAGED_EVENTS_SUCCESS,
  FETCH_PAGED_EVENTS_FAILURE,
  FETCH_PAGED_EVENTS_CANCELLED,
  FETCH_TRIPS,
  FETCH_TRIPS_SUCCESS,
  FETCH_TRIPS_FAILURE,
  FETCH_TRIPS_CANCELLED,
  UPDATE_EVENTS_FILTER,
  UPDATE_EVENTS_QUERY,
  UPDATE_TRIP_CLASSIFICATION,
  UPDATE_TRIP_CLASSIFICATION_SUCCESS,
  UPDATE_TRIP_CLASSIFICATION_FAILURE,
  UPDATE_TRIP_DRIVER,
  UPDATE_TRIP_DRIVER_SUCCESS,
  UPDATE_TRIP_DRIVER_FAILURE,
  FETCH_SPEED_INFRACTIONS,
  FETCH_SPEED_INFRACTIONS_SUCCESS,
  FETCH_SPEED_INFRACTIONS_FAILURE,
  FETCH_SPEED_INFRACTIONS_CANCELLED,
  UPDATE_SPEED_INFRACTIONS_FILTER,
  UPDATE_SPEED_INFRACTIONS_QUERY,
  FETCH_ACCELEROMETER_EVENTS,
  FETCH_ACCELEROMETER_EVENTS_SUCCESS,
  FETCH_ACCELEROMETER_EVENTS_FAILURE,
  FETCH_ACCELEROMETER_EVENTS_CANCELLED,
  UPDATE_ACCELEROMETER_EVENTS_FILTER,
  UPDATE_ACCELEROMETER_EVENTS_QUERY,
  FETCH_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT,
  FETCH_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT_SUCCESS,
  FETCH_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT_FAILURE,
  FETCH_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT_CANCELLED,
  UPDATE_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT_FILTER,
  FETCH_ATTENDANCES,
  FETCH_ATTENDANCES_SUCCESS,
  FETCH_ATTENDANCES_FAILURE,
  FETCH_ATTENDANCES_CANCELLED,
  UPDATE_ATTENDANCES_FILTER,
  UPDATE_ATTENDANCES_QUERY,
} from '../actions';

const { rowsPerPageOptions } = window.config;

const INITIAL_STATE = {
  trips: {
    list: [],
    isLoading: false,
    isDownloading: false,
    downloadProportion: 0,
    error: null,
    query: {},
    filter: {
      fields: {},
      selectedIdentifiers: [],
      page: 0,
      rowsPerPage: rowsPerPageOptions[0],
      order: 'asc',
      orderBy: 'startTime',
      orderByServerKey: 'startTime',
    },
  },
  speedInfractions: {
    list: [],
    isLoading: false,
    error: null,
    query: {},
    filter: {
      page: 0,
      rowsPerPage: rowsPerPageOptions[0],
      order: 'asc',
      orderBy: 'startTime',
      fields: {
        driverCode: null,
        driverName: null,
        forenames: null,
        surname: null,
        collarNumber: null,
        personRole: null,
        registrationNumber: null,
        fleetNumber: null,
        role: null,
        type: null,
        vehicleType: null,
        homeStation: null,
        classification: null,
        emergencyEquipmentUsed: null,
        areas: null,
      },
    },
  },
  accelerometerEvents: {
    list: [],
    isLoading: false,
    error: null,
    query: {},
    filter: {
      fields: {
        registrationNumber: null,
        fleetNumber: null,
        vehicleRole: null,
        vehicleType: null,
        hasData: null,
      },
      page: 0,
      rowsPerPage: rowsPerPageOptions[0],
      order: 'asc',
      orderBy: 'time',
    },
  },
  vehicleLocationVisits: {
    list: [],
    isLoading: false,
    error: null,
    query: {},
    filter: {
      fields: {
        registrationNumber: null,
        fleetNumber: null,
        role: null,
        type: null,
        locationType: null,
        locationSubtype: null,
        locationName: null,
      },
      page: 0,
      rowsPerPage: rowsPerPageOptions[0],
      order: 'asc',
      orderBy: 'startTime',
      orderByServerKey: 'startTime',
    },
  },
  stops: {
    list: [],
    isLoading: false,
    isDownloading: false,
    downloadProportion: 0,
    error: null,
    query: {},
    filter: {
      fields: {},
      page: 0,
      rowsPerPage: rowsPerPageOptions[0],
      order: 'asc',
      orderBy: 'startTime',
      orderByServerKey: 'startTime',
    },
  },
  idles: {
    list: [],
    isLoading: false,
    error: null,
    query: {},
    filter: {
      fields: {
        registrationNumber: null,
        fleetNumber: null,
        role: null,
        type: null,
        locationType: null,
        locationSubtype: null,
        locationName: null,
      },
      page: 0,
      rowsPerPage: rowsPerPageOptions[0],
      order: 'asc',
      orderBy: 'startTime',
      orderByServerKey: 'startTime',
    },
  },
  malfunctionIndicatorLight: {
    list: [],
    isLoading: false,
    error: null,
    query: {},
    filter: {
      fields: {
        registrationNumber: null,
        fleetNumber: null,
        role: null,
        type: null,
        homeStation: null,
        areas: null,
      },
      page: 0,
      rowsPerPage: rowsPerPageOptions[0],
      order: 'asc',
      orderBy: 'startTime',
      orderByServerKey: 'startTime',
    },
  },
  onBoardDiagnostics: {
    list: [],
    isLoading: false,
    error: null,
    query: {},
    filter: {
      fields: {
        registrationNumber: null,
        fleetNumber: null,
        role: null,
        type: null,
      },
      page: 0,
      rowsPerPage: rowsPerPageOptions[0],
      order: 'asc',
      orderBy: 'time',
      orderByServerKey: 'time',
    },
  },
  trails: {
    list: [],
    isLoading: false,
    error: null,
    query: {},
    filter: {
      fields: {
        code: null,
        forenames: null,
        surname: null,
        collarNumber: null,
        role: null,
        startLocationType: null,
        startLocationName: null,
        endLocationType: null,
        endLocationName: null,
      },
      page: 0,
      rowsPerPage: rowsPerPageOptions[0],
      order: 'asc',
      orderBy: 'startTime',
      orderByServerKey: 'startTime',
    },
  },
  personLocationVisits: {
    list: [],
    isLoading: false,
    error: null,
    query: {},
    filter: {
      fields: {
        code: null,
        forenames: null,
        surname: null,
        collarNumber: null,
        role: null,
        rank: null,
        locationType: null,
        locationName: null,
      },
      page: 0,
      rowsPerPage: rowsPerPageOptions[0],
      order: 'asc',
      orderBy: 'startTime',
      orderByServerKey: 'startTime',
    },
  },
  doubleCrews: {
    list: [],
    isLoading: false,
    error: null,
    query: {},
    filter: {
      fields: {
        primaryCode: null,
        primaryName: null,
        primaryCollarNumber: null,
        primaryRole: null,
        secondaryCode: null,
        secondaryName: null,
        secondaryCollarNumber: null,
        secondaryRole: null,
      },
      page: 0,
      rowsPerPage: rowsPerPageOptions[0],
      order: 'asc',
      orderBy: 'startTime',
      orderByServerKey: 'startTime',
    },
  },
  attendances: {
    list: [],
    isLoading: false,
    error: null,
    query: {},
    filter: {
      page: 0,
      rowsPerPage: rowsPerPageOptions[0],
      order: 'asc',
      orderBy: 'startTime',
      orderByServerKey: 'startTime',
      fields: {
        code: null,
        forenames: null,
        surname: null,
        role: null,
        collarNumber: null,
        identifier: null,
        title: null,
        type: null,
      },
    },
  },
  outages: {
    list: [],
    isLoading: false,
    error: null,
    query: {},
    filter: {
      fields: {
        imei: null,
        registrationNumber: null,
        fleetNumber: null,
        role: null,
        type: null,
        startLocationType: null,
        startLocationName: null,
        endLocationType: null,
        endLocationName: null,
      },
      page: 0,
      rowsPerPage: rowsPerPageOptions[0],
      order: 'asc',
      orderBy: 'startTime',
      orderByServerKey: 'startTime',
    },
  },
};

export default function eventsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case DOWNLOAD_EVENTS:
      return {
        ...state,
        [action.payload.eventType]: {
          ...state[action.payload.eventType],
          isDownloading: true,
          downloadProportion: 0,
        },
      };
    case DOWNLOAD_EVENTS_PROGRESS:
      return {
        ...state,
        [action.payload.eventType]: {
          ...state[action.payload.eventType],
          downloadProportion: action.payload.progress,
        },
      };
    case DOWNLOAD_EVENTS_SUCCESS:
      return {
        ...state,
        [action.payload.eventType]: {
          ...state[action.payload.eventType],
          downloadProportion: 1,
          isDownloading: false,
          download: action.payload.download,
        },
      };
    case DOWNLOAD_EVENTS_FAILURE:
    case DOWNLOAD_EVENTS_CANCELLED:
      return {
        ...state,
        [action.payload.eventType]: {
          ...state[action.payload.eventType],
          error:
            action.type === DOWNLOAD_EVENTS_FAILURE
              ? action.payload
              : state[action.payload.eventType].error,
          downloadProportion: 0,
          isDownloading: false,
        },
      };
    case UPDATE_TRIP_DRIVER:
    case UPDATE_TRIP_CLASSIFICATION:
      return {
        ...state,
        trips: {
          ...state.trips,
          isLoading: true,
        },
      };

    case FETCH_PAGED_EVENTS:
      return {
        ...state,
        [action.payload.eventType]: {
          ...state[action.payload.eventType],
          isLoading: true,
          error: null,
          filter: {
            ...state[action.payload.eventType].filter,
            page: action.payload.page,
            rowsPerPage: action.payload.perPage,
            order: action.payload.order,
            orderBy: action.payload.orderBy,
            orderByServerKey: action.payload.orderByServerKey,
          },
        },
      };
    case FETCH_PAGED_EVENTS_SUCCESS:
      return {
        ...state,
        [action.payload.eventType]: {
          ...state[action.payload.eventType],
          list: action.payload.list,
          total: action.payload.total,
          totals: action.payload.totals,
          filter: {
            ...state[action.payload.eventType].filter,
            filterValues: action.payload.filterValues,
          },
          isLoading: false,
          error: null,
        },
      };
    case FETCH_PAGED_EVENTS_FAILURE:
      return {
        ...state,
        [action.payload.eventType]: {
          ...state[action.payload.eventType],
          isLoading: false,
          error: action.payload.message,
        },
      };
    case FETCH_PAGED_EVENTS_CANCELLED:
      return {
        ...state,
        [action.payload.eventType]: {
          ...state[action.payload.eventType],
          isLoading: false,
        },
      };

    case FETCH_TRIPS:
      return {
        ...state,
        trips: {
          ...state.trips,
          isLoading: true,
          error: null,
          filter: {
            ...state.trips.filter,
            page: action.payload.page,
            rowsPerPage: action.payload.perPage,
            order: action.payload.order,
            orderBy: action.payload.orderBy,
            orderByServerKey: action.payload.orderByServerKey,
          },
        },
      };
    case FETCH_TRIPS_SUCCESS:
      return {
        ...state,
        trips: {
          ...state.trips,
          list: action.payload.list,
          total: action.payload.total,
          totals: action.payload.totals,
          filter: {
            ...state.trips.filter,
            filterValues: action.payload.filterValues,
          },
          isLoading: false,
          error: null,
        },
      };
    case FETCH_TRIPS_FAILURE:
    case UPDATE_TRIP_CLASSIFICATION_FAILURE:
    case UPDATE_TRIP_DRIVER_FAILURE:
      return {
        ...state,
        trips: {
          ...state.trips,
          isLoading: false,
          error: action.payload,
        },
      };
    case FETCH_TRIPS_CANCELLED:
      return {
        ...state,
        trips: {
          ...state.trips,
          isLoading: false,
        },
      };
    case UPDATE_EVENTS_FILTER:
      return {
        ...state,
        [action.payload.eventType]: {
          ...state[action.payload.eventType],
          filter: action.payload.filter,
        },
      };
    case UPDATE_EVENTS_QUERY:
      return {
        ...state,
        [action.payload.eventType]: {
          ...state[action.payload.eventType],
          query: action.payload.query,
        },
      };
    case UPDATE_TRIP_CLASSIFICATION_SUCCESS:
      return {
        ...state,
        trips: {
          ...state.trips,
          list: state.trips.list.map((trip) =>
            trip.identifier === action.payload.id
              ? {
                  ...trip,
                  classification: action.payload.classification,
                }
              : trip
          ),
          isLoading: false,
          error: null,
        },
      };
    case UPDATE_TRIP_DRIVER_SUCCESS:
      return {
        ...state,
        trips: {
          ...state.trips,
          list: state.trips.list.map((trip) =>
            trip.identifier === action.payload.id
              ? {
                  ...trip,
                  driver: action.payload.driver,
                }
              : trip
          ),
          isLoading: false,
          error: null,
        },
      };
    case FETCH_SPEED_INFRACTIONS:
      return {
        ...state,
        speedInfractions: {
          ...state.speedInfractions,
          isLoading: true,
          error: null,
        },
      };
    case FETCH_SPEED_INFRACTIONS_SUCCESS:
      return {
        ...state,
        speedInfractions: {
          ...state.speedInfractions,
          list: action.payload,
          isLoading: false,
          error: null,
        },
      };
    case FETCH_SPEED_INFRACTIONS_FAILURE:
      return {
        ...state,
        speedInfractions: {
          ...state.speedInfractions,
          isLoading: false,
          error: action.payload,
        },
      };
    case FETCH_SPEED_INFRACTIONS_CANCELLED:
      return {
        ...state,
        speedInfractions: {
          ...state.speedInfractions,
          isLoading: false,
        },
      };
    case UPDATE_SPEED_INFRACTIONS_FILTER:
      return {
        ...state,
        speedInfractions: {
          ...state.speedInfractions,
          filter: action.payload,
        },
      };
    case UPDATE_SPEED_INFRACTIONS_QUERY:
      return {
        ...state,
        speedInfractions: {
          ...state.speedInfractions,
          query: action.payload,
        },
      };
    case FETCH_ACCELEROMETER_EVENTS:
      return {
        ...state,
        accelerometerEvents: {
          ...state.accelerometerEvents,
          isLoading: true,
          error: null,
        },
      };
    case FETCH_ACCELEROMETER_EVENTS_SUCCESS:
      return {
        ...state,
        accelerometerEvents: {
          ...state.accelerometerEvents,
          list: action.payload,
          isLoading: false,
          error: null,
        },
      };
    case FETCH_ACCELEROMETER_EVENTS_FAILURE:
      return {
        ...state,
        accelerometerEvents: {
          ...state.accelerometerEvents,
          isLoading: false,
          error: action.payload,
        },
      };
    case FETCH_ACCELEROMETER_EVENTS_CANCELLED:
      return {
        ...state,
        accelerometerEvents: {
          ...state.accelerometerEvents,
          isLoading: false,
        },
      };
    case UPDATE_ACCELEROMETER_EVENTS_FILTER:
      return {
        ...state,
        accelerometerEvents: {
          ...state.accelerometerEvents,
          filter: action.payload,
        },
      };
    case UPDATE_ACCELEROMETER_EVENTS_QUERY:
      return {
        ...state,
        accelerometerEvents: {
          ...state.accelerometerEvents,
          query: action.payload,
        },
      };
    case FETCH_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT:
      return {
        ...state,
        malfunctionIndicatorLight: {
          ...state.malfunctionIndicatorLight,
          isLoading: true,
          error: null,
        },
      };
    case FETCH_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT_SUCCESS:
      return {
        ...state,
        malfunctionIndicatorLight: {
          ...state.malfunctionIndicatorLight,
          list: action.payload,
          isLoading: false,
          error: null,
        },
      };
    case FETCH_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT_FAILURE:
      return {
        ...state,
        malfunctionIndicatorLight: {
          ...state.malfunctionIndicatorLight,
          isLoading: false,
          error: action.payload,
        },
      };
    case FETCH_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT_CANCELLED:
      return {
        ...state,
        malfunctionIndicatorLight: {
          ...state.malfunctionIndicatorLight,
          isLoading: false,
        },
      };
    case UPDATE_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT_FILTER:
      return {
        ...state,
        malfunctionIndicatorLight: {
          ...state.malfunctionIndicatorLight,
          filter: action.payload,
        },
      };
    case FETCH_ATTENDANCES:
      return {
        ...state,
        attendances: {
          ...state.attendances,
          isLoading: true,
          error: null,
        },
      };
    case FETCH_ATTENDANCES_SUCCESS:
      return {
        ...state,
        attendances: {
          ...state.attendances,
          list: action.payload,
          isLoading: false,
          error: null,
        },
      };
    case FETCH_ATTENDANCES_FAILURE:
      return {
        ...state,
        attendances: {
          ...state.attendances,
          isLoading: false,
          error: action.payload,
        },
      };
    case FETCH_ATTENDANCES_CANCELLED:
      return {
        ...state,
        attendances: {
          ...state.attendances,
          isLoading: false,
        },
      };
    case UPDATE_ATTENDANCES_FILTER:
      return {
        ...state,
        attendances: {
          ...state.attendances,
          filter: action.payload,
        },
      };
    case UPDATE_ATTENDANCES_QUERY:
      return {
        ...state,
        attendances: {
          ...state.attendances,
          query: action.payload,
        },
      };
    default:
      return state;
  }
}
