import {
  Avatar,
  Card,
  CardHeader,
  CardContent,
  Divider,
  ListItemAvatar,
  ListItemText,
  ListItemButton,
  Box,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { Link, useParams } from 'react-router-dom';
import { getPrimaryText, getSecondaryText } from '../constants';
import { RetrospectiveTypeIcon } from '../../../data/constants';

export default function Cluster({
  color,
  background,
  subItems,
  count,
  id: clusterId,
  lat,
  lon,
  originalSource,
}) {
  const { id, layerIndex, itemIndex } = useParams();

  function Row({ data, index, style }) {
    const feature = {
      properties: {
        ...data[index],
        source: originalSource,
      },
    };

    return (
      <ListItemButton
        dense
        key={index}
        style={style}
        component={Link}
        to={`/retrospective/${
          id || 'untitled'
        }/${layerIndex}/${itemIndex}/${index}`}
        // onMouseEnter={() => {
        //   // because ie11 is a pos and this event fires every time the mouse moves inside an element
        //   if (hoveredItemIndex.itemIndex !== index) {
        //     onHover({
        //       layerIndex: parseInt(layerIndex),
        //       // itemIndex: index
        //       itemIndex: feature.id,
        //     });
        //   }
        // }}
        // onMouseLeave={() => onHover({})}
      >
        <ListItemAvatar>
          <Avatar
            style={{
              background,
              color,
            }}
          >
            <RetrospectiveTypeIcon type={originalSource} />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={getPrimaryText(feature)}
          secondary={getSecondaryText(feature)}
        />
      </ListItemButton>
    );
  }

  return (
    <Card sx={{ m: [1, 0.5, 1, 1] }}>
      <Helmet>
        <title>IR3 | Cluster | {`${clusterId}`}</title>
      </Helmet>
      <CardHeader
        avatar={
          <Avatar
            style={{
              background,
              color,
            }}
            title="Cluster"
          >
            <RetrospectiveTypeIcon type="clusters" />
          </Avatar>
        }
        title={`${count} item${count === 1 ? '' : 's'} in cluster`}
        subheader={clusterId}
      />
      <Divider />
      <CardContent sx={{ p: 0 }}>
        <Box
          sx={{
            display: 'flex',
            flex: [1, 1, 'auto'],
            flexDirection: 'column',
            overflow: 'hidden',
          }}
        >
          <Box
            sx={{
              overflowY: 'auto',
              overflowX: 'hidden',
              height: 'calc(100vh - 161px)',
            }}
          >
            <AutoSizer>
              {({ width, height }) => (
                <FixedSizeList
                  width={width}
                  height={height}
                  overscanCount={10}
                  itemData={subItems ?? []}
                  itemCount={subItems?.length ?? 0}
                  itemSize={56}
                  // onItemsRendered={debouncedHandleItemsRenderedChange}
                >
                  {Row}
                </FixedSizeList>
              )}
            </AutoSizer>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}
