import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from '@mui/material';

export default function DatePicker({
  error,
  helperText,
  utc,
  onChange,
  value,
  className,
  sx,
  ...outerProps
}) {
  function handleChange(newValue) {
    onChange(
      utc && newValue
        ? new Date(newValue.getTime() - newValue.getTimezoneOffset() * 60000)
        : newValue
    );
  }

  return (
    <MuiDatePicker
      {...outerProps}
      onChange={handleChange}
      value={
        value && utc
          ? new Date(value.getTime() + value.getTimezoneOffset() * 60000)
          : value
      }
      renderInput={(props) => (
        <TextField
          size="small"
          {...props}
          className={className}
          sx={sx}
          error={error}
          helperText={helperText}
        />
      )}
    />
  );
}
