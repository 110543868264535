import {
  Avatar,
  Box,
  Button,
  Collapse,
  Divider,
  IconButton,
  InputAdornment,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {
  Add as AddIcon,
  ArrowUpward as ArrowUpwardIcon,
  DirectionsCar as CarIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, Outlet } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Helmet } from 'react-helmet-async';
import { FixedSizeList } from 'react-window';
import _ from 'lodash';
import { format } from 'date-fns';
import { useAuth } from '../Auth';
import { FETCH_VEHICLES } from '../../actions';
import {
  checkLuhn,
  checkVin,
  downloadCSV,
  downloadJSON,
  getVehicles,
  getVehiclesAndHeaders,
} from '../../apis/utilities';
import Container from '../Container';
import { NavLink, SearchBox } from '../controls';
import { CommissioningDialogProvider } from './TelematicsBoxList/CommissioningDialog';

const { useReducedResourceInformation } = window.config;

function Row({ data, index, style }) {
  const [list, id, sortBy] = data;
  const item = list[index];

  function getSecondaryText() {
    if (!_.get(item, sortBy)) {
      return '';
    }

    switch (sortBy) {
      case 'registrationNumber':
      case 'fleetNumber':
        return useReducedResourceInformation
          ? item.identificationNumber
          : item.fleetNumber;
      case 'lastPollTime':
        return format(new Date(item.lastPollTime), 'dd/MM/yyyy HH:mm:ss');
      default:
        return _.get(item, sortBy);
    }
  }

  return (
    <ListItemButton
      dense
      key={index}
      style={style}
      component={NavLink}
      to={`/resources/vehicles/${encodeURIComponent(
        item.identificationNumber
      )}`}
      selected={id === item.identificationNumber}
    >
      <ListItemAvatar>
        <Avatar src={item.picture}>
          <CarIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          useReducedResourceInformation
            ? item.fleetNumber
            : item.registrationNumber
        }
        secondary={getSecondaryText()}
      />
    </ListItemButton>
  );
}

export default function VehicleList() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const vehicles = useSelector((state) => state.vehicles.vehicles, _.isEqual);
  const [searchText, setSearchText] = useState('');
  const [showSettings, setShowSettings] = useState(false);
  const [sortBy, setSortBy] = useState(
    useReducedResourceInformation ? 'fleetNumber' : 'registrationNumber'
  );
  const [sortDesc, setSortDesc] = useState(false);
  const [filter, setFilter] = useState({
    imei: '',
    vin: '',
    disposed: false,
  });
  const isXs = useMediaQuery((theme) => theme.breakpoints.only('xs'));
  const auth = useAuth();
  const canCreate = auth.isAuthorised('vehicles', true);

  const sortOptions = [
    ...(useReducedResourceInformation
      ? []
      : [{ label: 'Registration', value: 'registrationNumber' }]),
    { label: 'Fleet Number', value: 'fleetNumber' },
    { label: 'Last Poll Time', value: 'lastPollTime' },
    { label: 'VIN', value: 'identificationNumber' },
  ];

  const filterOptions = {
    imei: [
      { label: 'None', value: 'none' },
      { label: 'Invalid', value: 'invalid' },
    ],
    vin: [{ label: 'Invalid', value: 'invalid' }],
    disposed: [
      { label: 'Hide', value: false },
      { label: 'Show', value: true },
    ],
  };

  useEffect(() => {
    dispatch({
      type: FETCH_VEHICLES,
    });
  }, [dispatch]);

  // .filter((item) => {
  //   switch (filter) {
  //     case 'all':
  //       return true;
  //     case 'disposed':
  //       return !!item.disposalDate;
  //     case 'noImei':
  //       return !item.telematicsBoxImei;
  //     case 'invalidImei':
  //       return item.telematicsBoxImei
  //         ? !checkLuhn(item.telematicsBoxImei)
  //         : false;
  //     case 'invalidVin':
  //       return !checkVin(item.identificationNumber);
  //     default:
  //       return true;
  //   }
  // })

  const filteredList = _.orderBy(
    vehicles
      .filter(
        (item) =>
          item.searchString.indexOf(searchText.toLowerCase()) > -1 ||
          searchText === ''
      )
      .filter(
        (item) =>
          !filter.imei ||
          (filter.imei === 'none' && !item.telematicsBoxImei) ||
          (filter.imei === 'invalid' &&
            item.telematicsBoxImei &&
            !checkLuhn(item.telematicsBoxImei))
      )
      .filter(
        (item) =>
          !filter.vin ||
          (filter.vin === 'invalid' &&
            item.identificationNumber &&
            !checkVin(item.identificationNumber))
      )
      .filter((item) => filter.disposed || !item.disposalDate),
    [sortBy],
    [sortDesc ? 'desc' : 'asc']
  );

  function handleSearchChange(event) {
    setSearchText(event.target.value);
  }

  function handleSettingsToggle() {
    setShowSettings(!showSettings);
  }

  function handleSortByChange(event) {
    setSortBy(event.target.value);
  }

  function handleSortToggle() {
    setSortDesc(!sortDesc);
  }

  const handleFilterChange = (field) => (event) => {
    setFilter({ ...filter, [field]: event.target.value });
  };

  async function handleCsvClick() {
    const data = await getVehiclesAndHeaders();

    downloadCSV(data.vehicles, 'vehicles.csv', data.headers);
  }

  async function handleJsonClick() {
    const data = await getVehicles();

    downloadJSON(data, 'vehicles.json');
  }

  return (
    <CommissioningDialogProvider>
      <Container title="Vehicles" showBack={isXs && id}>
        <Box sx={{ display: 'flex', height: 1, width: 1 }}>
          <Helmet>
            <title>IR3 | Vehicles</title>
          </Helmet>
          {(!isXs || !id) && (
            <Box
              sx={(theme) => ({
                display: 'flex',
                flexDirection: 'column',
                width: 280,
                [theme.breakpoints.down('sm')]: {
                  width: 1,
                },
                height: 1,
              })}
            >
              <Box>
                <Stack
                  direction="row"
                  sx={{ p: 1, pr: 0.5 }}
                  spacing={0.5}
                  alignItems="center"
                >
                  <SearchBox
                    value={searchText}
                    onChange={handleSearchChange}
                    count={`${filteredList.length}/${
                      vehicles.filter(
                        (item) => filter.disposed || !item.disposalDate
                      ).length
                    }`}
                    sx={{ flexGrow: 1 }}
                  />
                  <IconButton
                    size="small"
                    title={showSettings ? 'Hide settings' : 'Show settings'}
                    onClick={handleSettingsToggle}
                  >
                    <SettingsIcon
                      fontSize="inherit"
                      color={showSettings ? 'primary' : 'inherit'}
                    />
                  </IconButton>
                  {canCreate && (
                    <IconButton
                      title="Add new"
                      component={Link}
                      to={'/resources/vehicles/new'}
                      size="small"
                    >
                      <Avatar
                        sx={{
                          color: 'secondary.contrastText',
                          backgroundColor: 'secondary.main',
                          width: 24,
                          height: 24,
                          fontSize: 16,
                        }}
                      >
                        <AddIcon fontSize="inherit" />
                      </Avatar>
                    </IconButton>
                  )}
                </Stack>
                <Collapse in={showSettings} unmountOnExit>
                  <Stack sx={{ p: 1 }} spacing={1.5}>
                    <TextField
                      size="small"
                      select
                      fullWidth
                      label="Sort by"
                      value={sortBy}
                      onChange={handleSortByChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <IconButton
                              title={sortDesc ? 'Descending' : 'Ascending'}
                              sx={(theme) => ({
                                transform: sortDesc
                                  ? 'rotate(180deg)'
                                  : 'rotate(0deg)',
                                transition: theme.transitions.create(
                                  'transform',
                                  {
                                    duration:
                                      theme.transitions.duration.shortest,
                                  }
                                ),
                              })}
                              onClick={handleSortToggle}
                              size="small"
                            >
                              <ArrowUpwardIcon fontSize="inherit" />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    >
                      {sortOptions.map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Stack>
                  <Stack sx={{ px: 1 }} spacing={1.5}>
                    <Divider>
                      <Typography variant="subtitle2" color="textSecondary">
                        Filters
                      </Typography>
                    </Divider>
                    <TextField
                      size="small"
                      fullWidth
                      select
                      label="IMEI"
                      value={filter.imei}
                      onChange={handleFilterChange('imei')}
                    >
                      <MenuItem value="">
                        <em>All</em>
                      </MenuItem>
                      {filterOptions.imei.map((type) => (
                        <MenuItem key={type.value} value={type.value}>
                          {type.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      size="small"
                      fullWidth
                      select
                      label="VIN"
                      value={filter.vin}
                      onChange={handleFilterChange('vin')}
                    >
                      <MenuItem value="">
                        <em>All</em>
                      </MenuItem>
                      {filterOptions.vin.map((type) => (
                        <MenuItem key={type.value} value={type.value}>
                          {type.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      size="small"
                      fullWidth
                      select
                      label="Disposed"
                      value={filter.disposed}
                      onChange={handleFilterChange('disposed')}
                    >
                      {filterOptions.disposed.map((type) => (
                        <MenuItem key={type.value} value={type.value}>
                          {type.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    <Divider>
                      <Typography variant="subtitle2" color="textSecondary">
                        Export
                      </Typography>
                    </Divider>
                    <Stack
                      direction="row"
                      // sx={{ p: 1 }}
                      spacing={1.5}
                      justifyContent="center"
                    >
                      <Button
                        fullWidth
                        color="primary"
                        variant="contained"
                        disableElevation
                        onClick={handleCsvClick}
                      >
                        CSV
                      </Button>
                      <Button
                        fullWidth
                        color="secondary"
                        variant="contained"
                        disableElevation
                        onClick={handleJsonClick}
                      >
                        JSON
                      </Button>
                    </Stack>
                    <Divider />
                  </Stack>
                </Collapse>
              </Box>
              {filteredList.length > 0 && (
                <Box sx={{ height: 1 }}>
                  <AutoSizer>
                    {({ width, height }) => (
                      <FixedSizeList
                        width={width}
                        height={height}
                        overscanCount={10}
                        itemData={[filteredList, id, sortBy]}
                        itemCount={filteredList.length}
                        itemSize={56}
                      >
                        {Row}
                      </FixedSizeList>
                    )}
                  </AutoSizer>
                </Box>
              )}
            </Box>
          )}
          {(!isXs || id) && (
            <Box
              sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                // width: 1,
                height: 1,
                overflow: 'auto',
              }}
            >
              <Outlet />
            </Box>
          )}
        </Box>
      </Container>
    </CommissioningDialogProvider>
  );
}
