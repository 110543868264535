import { TextField } from '@mui/material';
import { DateTimePicker as MuiDateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

export default function DateTimePicker({
  helperText,
  error,
  className,
  sx,
  ...outerProps
}) {
  return (
    <MuiDateTimePicker
      {...outerProps}
      ampm={false}
      renderInput={(props) => (
        <TextField
          size="small"
          {...props}
          className={className}
          sx={sx}
          helperText={helperText}
          error={error}
        />
      )}
    />
  );
}
