import {
  AppBar,
  Box,
  Breadcrumbs,
  Drawer,
  IconButton,
  Paper,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {
  ArrowBack as ArrowBackIcon,
  CreateNewFolder as CreateNewFolderIcon,
  Save as SaveIcon,
  ViewList as ViewListIcon,
  Autorenew as AutorenewIcon,
  Block as FetchDisallowedIcon,
} from '@mui/icons-material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Form, FormSpy } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { FolderOpen as FolderOpenIcon } from 'mdi-material-ui';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { Helmet } from 'react-helmet-async';
import { sourceFilterMapping } from './SourceFilters';
import { useSnackbar } from '../Snackbar';
import {
  CLEAR_RETROSPECTIVE,
  CREATE_RETROSPECTIVE,
  DELETE_RETROSPECTIVE,
  FETCH_RETROSPECTIVE,
  UPDATE_RETROSPECTIVE,
  SYNC_RETROSPECTIVE_FORM,
  FETCH_RETROSPECTIVE_LAYER,
  FETCH_RETROSPECTIVE_LAYER_CANCELLED,
  FETCH_RETROSPECTIVE_LAYER_BOUNDARY,
  FETCH_RETROSPECTIVE_ITEM,
  FETCH_RETROSPECTIVE_SUBITEM,
  FETCH_LOCATIONS,
  FETCH_FEATURES,
  FETCH_OBJECTIVES,
  FETCH_VEHICLES,
  FETCH_PEOPLE,
  FETCH_HOME_STATIONS,
  FETCH_TELEMATICS_BOXES,
  FETCH_RFID_CARDS,
  FETCH_RADIOS,
  UPDATE_RETROSPECTIVE_LAYER_VIRTUALIZATION,
  UPDATE_RETROSPECTIVE_LAYER_FILE,
  ESTIMATE_RETROSPECTIVE_LAYER_RESULT_COUNT,
  ESTIMATE_RETROSPECTIVE_LAYER_RESULT_COUNT_CANCELLED,
} from '../../actions';
import { LoginAvatar, MenuButton } from '../controls';
import OpenDialog from './OpenDialog';
import LayerList from './LayerList';
import RetrospectiveMap from './RetrospectiveMap';
import SaveDialog from './SaveDialog';
import ItemList from './ItemList';
import Item from './Item';
import {
  getBoundaries,
  getFilters,
  exceedsLimits,
  orderAndFilterFeatures,
  totalExceedsLimits,
} from './constants';
import MapVirtualisationDialog from './MapVirtualisationDialog';
import {
  required,
  requiredDateAfter,
  requiredDateBefore,
  requiredNotNegative,
} from 'components/fields';
import { getRelativeTimePeriod } from '../../apis/utilities';
import { useEffectOnce } from '../../hooks';
import ConfirmationDialog from '../dialogs/ConfirmationDialog';

const IsPaper = ({ condition, className, sx, children }) =>
  condition ? (
    <Paper className={className} sx={sx}>
      {children}
    </Paper>
  ) : (
    children
  );

// needs to be outside of Retrospective or causes infinite loop
function validate(values) {
  let errors = {};
  if (!values.title) {
    errors.title = 'Required';
  }

  let layersErrors = [];
  (values.layers || []).forEach((layer, index) => {
    const customVisit = ['vehicleCustomVisits', 'personCustomVisits'].includes(
      layer.source
    );

    // if it's a customVisit a non-None boundary is required
    const boundaryTypeValidation = customVisit
      ? (value) => required(value === 'None' ? undefined : value)
      : () => undefined;
    // customVisit or not, if boundary type isn't None it needs other values
    const boundaryValidation = (value) =>
      [undefined, 'None', 'Custom'].includes(layer.boundaryType)
        ? undefined
        : required(value);
    const boundaryCustomValidation = (value) =>
      layer.boundaryType === 'Custom' ? required(value) : undefined;

    const { isRelativeTimePeriod } = layer;

    let layerErrors = {
      type: required(layer.type),
      source: required(layer.source),

      ...(isRelativeTimePeriod
        ? {
            amountOfTime: requiredNotNegative(layer.amountOfTime),
            unitOfTime: required(layer.unitOfTime),
          }
        : {
            startTime: requiredDateBefore(layer.endTime)(layer.startTime),
            endTime: requiredDateAfter(layer.startTime)(layer.endTime),
          }),

      // layer type dependent validation
      ...(() => {
        switch (layer.type) {
          case 'area':
            return {
              areaType: required(layer.areaType),
            };
          case 'bubble':
            return {
              distance: requiredNotNegative(layer.distance),
            };
          case 'heat':
            return {
              precision: requiredNotNegative(layer.precision),
              blur: requiredNotNegative(layer.blur),
              radius: requiredNotNegative(layer.radius),
            };
          default:
            return {};
        }
      })(),

      boundaryType: boundaryTypeValidation(layer.boundaryType),
      boundarySubtype: boundaryValidation(layer.boundarySubtype),
      boundaryIdentifier: boundaryValidation(layer.boundaryIdentifier),
      boundaryGeometry: boundaryCustomValidation(layer.boundaryGeometry),
    };

    const layerHasErrors = Object.values(layerErrors).some((value) => !!value);
    layersErrors.push(
      layerHasErrors ? _.pickBy(layerErrors, (value) => !!value) : undefined
    );
  });

  if (layersErrors.length > 0) {
    errors.layers = layersErrors;
  }

  return errors;
}

export default function Retrospective() {
  const navigate = useNavigate();
  const { id, layerIndex, itemIndex, subItemIndex } = useParams();
  const dispatch = useDispatch();
  const retrospective = useSelector(
    (state) => state.retrospectives.retrospective,
    _.isEqual
  );
  const loadingLayers = useSelector(
    (state) => state.retrospectives.loadingLayers
  );
  const estimatingLayers = useSelector(
    (state) => state.retrospectives.estimatingLayers
  );
  const isLoading = useSelector((state) => state.retrospectives.isLoading);
  const boundaries = useSelector(getBoundaries, _.isEqual);
  const item = useSelector((state) => state.retrospectives.item);
  const subItem = useSelector((state) => state.retrospectives.subItem);
  const error = useSelector((state) => state.retrospectives.error);
  const filters = useSelector(getFilters, _.isEqual);
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [openOpen, setOpenOpen] = useState(false);
  const [saveOpen, setSaveOpen] = useState(false);
  const [expandedLayerIndex, setExpandedLayerIndex] = useState(null);
  const [hoveredItemIndex, setHoveredItemIndex] = useState({});
  const [drawIndex, setDrawIndex] = useState(null);
  const [newOpen, setNewOpen] = useState(false);
  const oldFormValuesRef = useRef(null);
  const isXs = useMediaQuery((theme) => theme.breakpoints.only('xs'));
  const snackbar = useSnackbar();

  useEffect(() => {
    if (error) {
      snackbar.notify('error', error);
    }
  }, [error, snackbar]);

  useEffect(() => {
    if (
      retrospective.layers.length > 0 &&
      layerIndex &&
      itemIndex &&
      retrospective.layers[layerIndex]
    ) {
      const { originalMatch, originalPrecision, ...layer } =
        retrospective.layers[layerIndex];

      const feature = orderAndFilterFeatures(layer)[itemIndex];

      if (feature) {
        dispatch({
          type: FETCH_RETROSPECTIVE_ITEM,
          payload: {
            ...feature.properties,
            ...(originalMatch && { originalMatch }),
            ...(originalPrecision && { originalPrecision }),
          },
        });
      }
    }
  }, [dispatch, retrospective.layers, layerIndex, itemIndex]);

  useEffect(() => {
    if (item && subItemIndex) {
      if (item.subItems?.[subItemIndex]) {
        dispatch({
          type: FETCH_RETROSPECTIVE_SUBITEM,
          payload: item.subItems[subItemIndex],
        });
      }
    }
  }, [dispatch, retrospective.layers, layerIndex, item, subItemIndex]);

  useEffect(() => {
    dispatch({
      type: FETCH_LOCATIONS,
      payload: 'All',
    });
    dispatch({
      type: FETCH_FEATURES,
      payload: 'Perimeter',
    });
    dispatch({
      type: FETCH_OBJECTIVES,
      payload: 'All',
    });
    dispatch({
      type: FETCH_VEHICLES,
      payload: 'All',
    });
    dispatch({
      type: FETCH_PEOPLE,
      payload: 'All',
    });
    dispatch({
      type: FETCH_HOME_STATIONS,
    });
    dispatch({
      type: FETCH_TELEMATICS_BOXES,
    });
    dispatch({
      type: FETCH_RADIOS,
    });
    dispatch({
      type: FETCH_RFID_CARDS,
    });
  }, [dispatch]);

  useEffect(() => {
    if (retrospective.identifier && retrospective.identifier !== id) {
      navigate(`/retrospective/${retrospective.identifier}`, { replace: true });
    }
  }, [navigate, retrospective.identifier, id]);

  useEffect(() => {
    if (
      layerIndex &&
      (!retrospective.layers[layerIndex] ||
        !retrospective.layers[layerIndex].featureCollection)
    ) {
      navigate(`/retrospective/${retrospective.identifier}`, { replace: true });
    }
  }, [navigate, id, layerIndex, retrospective]);

  // we need to FETCH_RETROSPECTIVE in 2 cases only:
  // 1) this useEffectOnce hook - when we come from an url containing a retrospective id;
  // 2) handleOpenClose further down this file - when we use OpenDialog to open a saved retrospective
  //    (only if it's different than the one we have already loaded)
  useEffectOnce(() => {
    if (id && id !== 'untitled' && retrospective.identifier !== id) {
      dispatch({
        type: FETCH_RETROSPECTIVE,
        payload: id,
      });
    }
  });

  function handleDrawerOpen() {
    setDrawerOpen(true);
  }

  function handleDrawerClose() {
    setDrawerOpen(false);
  }

  // if you change source the form may have some filters that aren't relevant
  // e.g. changing from vehicle visits to person visits makes the vehicle filters
  // irrelevant, remove these
  function relevantFilters(layer) {
    const mapping = sourceFilterMapping[layer?.source];

    if (mapping && layer.filters) {
      const allowed = Object.entries(mapping).map(
        ([key, id]) => (id = typeof id === 'string' ? id : key)
      );

      let relevant = {};
      Object.keys(layer.filters)
        .filter((k) => allowed.includes(k))
        .forEach((key) => (relevant[key] = layer.filters[key]));

      return relevant;
    } else {
      return undefined;
    }
  }

  const totalLimitsExceeded = !!totalExceedsLimits(retrospective.layers);
  function refreshLayer(layer, index) {
    if (!exceedsLimits(layer)) {
      dispatch({
        type: FETCH_RETROSPECTIVE_LAYER,
        payload: {
          index,
          layer,
          filters: relevantFilters(layer),
        },
      });
    }
  }

  function handleRefreshAllClick() {
    if (!totalLimitsExceeded) {
      retrospective.layers.forEach((layer, index) => {
        refreshLayer(layer, index);
      });
    }
  }

  function handleRefresh(index, layer) {
    if (loadingLayers.includes(index)) {
      dispatch({
        type: FETCH_RETROSPECTIVE_LAYER_CANCELLED,
        payload: index,
      });
    } else {
      refreshLayer(layer, index);
    }
  }

  function handleSaveClick() {
    setSaveOpen(true);
  }

  function handleNewRetroClick() {
    setNewOpen(true);
  }

  const handleNewClick = (reset) => () => {
    setNewOpen(false);
    reset();
    dispatch({
      type: CLEAR_RETROSPECTIVE,
    });
    navigate(`/retrospective`);
  };

  function handleOpenClick() {
    setOpenOpen(true);
  }

  function handleOpenClose(identifier) {
    if (identifier && identifier !== retrospective.identifier) {
      setExpandedLayerIndex(null);
      dispatch({
        type: FETCH_RETROSPECTIVE,
        payload: identifier,
      });
    }
    setOpenOpen(false);
  }

  function handleSaveClose() {
    setSaveOpen(false);
  }

  function handleSubmit(values) {
    if (saveOpen) {
      try {
        if (values.identifier && values.title === retrospective.title) {
          dispatch({
            type: UPDATE_RETROSPECTIVE,
            payload: values,
          });
        } else {
          const { identifier, ...other } = values;
          dispatch({
            type: CREATE_RETROSPECTIVE,
            payload: other,
            navigate,
          });
        }
        setSaveOpen(false);
        snackbar.notify('success', 'Retrospective saved');
      } catch (error) {
        console.error(error);
      }
    }
  }

  function handleDelete(identifier) {
    if (identifier === id) {
      dispatch({
        type: CLEAR_RETROSPECTIVE,
      });

      navigate(`/retrospective`);
    }

    dispatch({
      type: DELETE_RETROSPECTIVE,
      payload: identifier,
    });
  }

  function handleBackClick() {
    navigate(-1);
  }

  function handleSelect({ layerIndex, itemIndex }) {
    if (Number.isInteger(itemIndex)) {
      navigate(`/retrospective/${id || 'untitled'}/${layerIndex}/${itemIndex}`);
    } else if (Number.isInteger(layerIndex)) {
      navigate(`/retrospective/${id || 'untitled'}/${layerIndex}`);
    } else if (id) {
      navigate(`/retrospective/${id}`);
    } else {
      navigate('/retrospective');
    }
  }

  function handleHover(index) {
    setHoveredItemIndex(index);
  }

  function handleDrawStart(index) {
    setDrawIndex(index);
  }

  const handleDrawEnd = (setValue) => (index, geometry) => {
    setDrawIndex(null);
    setValue(`layers[${index}].boundaryGeometry`, geometry);
  };

  const handleSearchTextChange = (setValue) => (text) => {
    setValue(`layers[${layerIndex}].searchText`, text);
  };

  function handleBoundaryChange(index, layer) {
    dispatch({
      type: FETCH_RETROSPECTIVE_LAYER_BOUNDARY,
      payload: {
        index,
        layer,
      },
    });
  }

  function handleVirtualizationChange({
    index,
    virtualize = true,
    window = [],
  }) {
    dispatch({
      type: UPDATE_RETROSPECTIVE_LAYER_VIRTUALIZATION,
      payload: {
        index,
        virtualize,
        window,
      },
    });
  }

  const onFileOpen = (file) => {
    dispatch({
      type: UPDATE_RETROSPECTIVE_LAYER_FILE,
      payload: {
        index: expandedLayerIndex,
        file,
      },
    });
  };

  return (
    <Form
      initialValues={retrospective}
      initialValuesEqual={_.isEqual}
      onSubmit={handleSubmit}
      validate={validate}
      mutators={{
        clearValue: ([name], state, { changeValue }) => {
          changeValue(state, name, () => undefined);
        },
        setValue: ([name, value], state, { changeValue }) => {
          changeValue(state, name, () => value);
        },
        resetFilter: ({ 1: name }, state, { changeValue }) => {
          function wipeSelections(filter) {
            delete filter.value;
            return filter;
          }

          changeValue(state, name, wipeSelections);
        },
        ...arrayMutators,
        // insert doesn't work, workaround here
        // https://github.com/final-form/react-final-form-arrays/issues/138
        insertAt: ([name, index, value], state, { changeValue }) => {
          changeValue(state, name, (array) => {
            const copy = [...(array || [])];
            copy.splice(index, 0, value);
            return copy;
          });
        },
      }}
      render={({
        handleSubmit,
        form: { reset, mutators },
        submitting,
        values,
        errors,
      }) => {
        const layer = values.layers[layerIndex];
        const secondaryContent =
          layerIndex && layer ? (
            itemIndex && layer.featureCollection ? (
              <Box
                sx={{
                  overflowY: 'auto',
                }}
              >
                {subItem && subItemIndex !== undefined ? (
                  <Item
                    item={subItem}
                    colors={layer.colors}
                    isLoading={isLoading}
                    primaryItemKey={layer.primaryItemKey}
                    secondaryItemKey={layer.secondaryItemKey}
                  />
                ) : (
                  <Item
                    item={item}
                    colors={layer.colors}
                    isLoading={isLoading}
                    primaryItemKey={layer.primaryItemKey}
                    secondaryItemKey={layer.secondaryItemKey}
                  />
                )}
              </Box>
            ) : (
              <ItemList
                hoveredItemIndex={hoveredItemIndex}
                onHover={handleHover}
                layer={layer}
                clearValue={mutators.clearValue}
                onSearchTextChange={handleSearchTextChange(mutators.setValue)}
                onMapWindowChange={handleVirtualizationChange}
                primaryItemKey={layer.primaryItemKey}
                secondaryItemKey={layer.secondaryItemKey}
              />
            )
          ) : (
            <FieldArray
              name="layers"
              component={LayerList}
              hoveredItemIndex={hoveredItemIndex}
              onRefresh={handleRefresh}
              loadingLayers={loadingLayers}
              estimatingLayers={estimatingLayers}
              onSelect={handleSelect}
              onDraw={handleDrawStart}
              boundaries={boundaries}
              onBoundaryChange={handleBoundaryChange}
              clearValue={mutators.clearValue}
              setValue={mutators.setValue}
              expandedLayerIndex={expandedLayerIndex}
              onExpanded={setExpandedLayerIndex}
              onVirtualizationChange={handleVirtualizationChange}
              filters={filters}
              errors={errors}
              onFileOpen={onFileOpen}
              layerOrder={values.layerOrder}
            />
          );

        return (
          <form onSubmit={handleSubmit}>
            <Helmet>
              <title>
                IR3 |{' '}
                {values.title
                  ? `Retrospective | ${values.title}`
                  : 'Retrospective'}
              </title>
            </Helmet>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100vh',
              }}
            >
              <AppBar position="static">
                <Toolbar variant="dense" sx={{ px: 1.5 }} disableGutters>
                  {layerIndex ? (
                    <IconButton
                      color="inherit"
                      title="Back"
                      aria-label="Back"
                      onClick={handleBackClick}
                      sx={{ ml: 0.25, mr: 1 }}
                      size="large"
                    >
                      <ArrowBackIcon />
                    </IconButton>
                  ) : (
                    <MenuButton
                      color="inherit"
                      edge="start"
                      sx={{ ml: 0.25, mr: 1 }}
                    />
                  )}
                  <Breadcrumbs
                    aria-label="breadcrumb"
                    sx={{
                      flex: 1,
                      color: 'common.white',
                    }}
                  >
                    <Typography
                      variant="h6"
                      color="inherit"
                      component={Link}
                      sx={{ textDecoration: 'none' }}
                      to={id ? `/retrospective/${id}` : '/retrospective'}
                    >
                      {retrospective.title || 'Retrospective'}
                    </Typography>
                    {layerIndex && layer && (
                      <Typography
                        variant="h6"
                        color="inherit"
                        component={Link}
                        sx={{ textDecoration: 'none' }}
                        to={`/retrospective/${id}/${layerIndex}`}
                      >
                        {layer.label || layerIndex}
                      </Typography>
                    )}
                    {itemIndex && layer && layer.featureCollection && (
                      <Typography variant="h6" color="inherit">
                        {
                          orderAndFilterFeatures(layer)[itemIndex]?.properties
                            ?.id
                        }
                      </Typography>
                    )}
                  </Breadcrumbs>
                  {isXs && (
                    <IconButton
                      title="Details"
                      color="inherit"
                      aria-label="Details"
                      sx={{ mr: 0 }}
                      onClick={handleDrawerOpen}
                      size="large"
                    >
                      <ViewListIcon />
                    </IconButton>
                  )}
                  <IconButton
                    title="New"
                    color="inherit"
                    onClick={handleNewRetroClick}
                    sx={{ mr: 0 }}
                    size="large"
                  >
                    <CreateNewFolderIcon />
                  </IconButton>
                  <IconButton
                    title="Open"
                    color="inherit"
                    onClick={handleOpenClick}
                    sx={{ mr: 0 }}
                    size="large"
                  >
                    <FolderOpenIcon />
                  </IconButton>
                  <IconButton
                    title="Save"
                    color="inherit"
                    onClick={handleSaveClick}
                    disabled={submitting}
                    sx={{ mr: 0 }}
                    size="large"
                  >
                    <SaveIcon />
                  </IconButton>
                  <IconButton
                    title="Fetch All"
                    color="inherit"
                    onClick={handleRefreshAllClick}
                    sx={{ mr: 1 }}
                    disabled={totalLimitsExceeded}
                    size="large"
                  >
                    {totalLimitsExceeded ? (
                      <FetchDisallowedIcon />
                    ) : (
                      <AutorenewIcon />
                    )}
                  </IconButton>
                  <LoginAvatar />
                </Toolbar>
              </AppBar>
              <Box
                sx={{
                  display: 'flex',
                  // flex: 1,
                  height: 1,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    height: 1,
                    width: 1,
                  }}
                >
                  {!isXs && (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: 470,
                        height: 'calc(100vh - 50px)',
                        // overflow: 'hidden'
                      }}
                    >
                      {secondaryContent}
                    </Box>
                  )}
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      flex: 1,
                    }}
                  >
                    <IsPaper
                      condition={!isXs}
                      sx={{
                        height: 1,
                        m: [1, 1, 1, 1],
                        minWidth: 250,
                      }}
                    >
                      <RetrospectiveMap
                        layers={values.layers}
                        layerOrder={values.layerOrder}
                        hoveredItemIndex={hoveredItemIndex}
                        onHover={handleHover}
                        selectedItemIndex={{ layerIndex, itemIndex }}
                        onSelect={handleSelect}
                        drawIndex={drawIndex}
                        onDrawEnd={handleDrawEnd(mutators.setValue)}
                        expandedLayerIndex={expandedLayerIndex}
                      />
                    </IsPaper>
                  </Box>
                </Box>
              </Box>
            </Box>
            {isXs && (
              <Drawer
                anchor="bottom"
                open={drawerOpen}
                onClose={handleDrawerClose}
                variant="persistent"
              >
                <Box
                  sx={{
                    maxHeight: '80vh',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  {secondaryContent}
                </Box>
              </Drawer>
            )}
            <OpenDialog
              open={openOpen}
              onClose={handleOpenClose}
              onDelete={handleDelete}
            />
            <SaveDialog
              open={saveOpen}
              onClose={handleSaveClose}
              onSave={handleSubmit}
            />
            <MapVirtualisationDialog layers={retrospective?.layers} />
            <ConfirmationDialog
              action="Delete"
              title="Creating new retrospective"
              text="This will erase all unsaved layers and create a brand new retrospective"
              okButtonText="ok"
              open={newOpen}
              onOk={handleNewClick(reset)}
              onCancel={() => setNewOpen(false)}
            />
            <FormSpy
              subscription={{
                values: true,
                errors: true,
              }}
              onChange={(state) => {
                // for debug
                // const diff = function (obj1, obj2) {
                //   return _.reduce(
                //     obj1,
                //     function (result, value, key) {
                //       if (_.isPlainObject(value)) {
                //         result[key] = diff(value, obj2[key]);
                //       } else if (!_.isEqual(value, obj2[key])) {
                //         result[key] = [value, obj2[key]];
                //       }
                //       return result;
                //     },
                //     {}
                //   );
                // };

                // console.log('***');
                // console.log(
                //   diff(
                //     // oldFormValuesRef.current?.layers?.[0],
                //     // state.values?.layers?.[0]
                //     oldFormValuesRef.current,
                //     state.values
                //   )
                // );

                // console.log(
                //   diff(
                //     oldFormValuesRef.current?.layers?.[0],
                //     state.values?.layers?.[0]
                //   )
                // );

                if (!_.isEqual(oldFormValuesRef.current, state.values)) {
                  function removeEmptyFilters(filters) {
                    if (!filters) {
                      return {};
                    }

                    let result = {};
                    Object.keys(filters).forEach((key) => {
                      const definedFilters = filters[key].filter(({ value }) =>
                        Array.isArray(value)
                          ? value.length > 0
                          : !!value || value === 0
                      );

                      if (definedFilters.length > 0) {
                        result[key] = definedFilters;
                      }
                    });

                    return result;
                  }

                  // re-estimate data for each layer that changed
                  function layerChanged(curr, prev) {
                    const dataFields = [
                      'startTime',
                      'endTime',
                      'areaType',
                      'boundaryGeometry',
                      'filters',
                      'source',
                      'precision',
                    ];
                    curr = _.pick(curr, dataFields);
                    prev = _.pick(prev, dataFields);

                    curr.filters = removeEmptyFilters(curr.filters);
                    prev.filters = removeEmptyFilters(prev.filters);

                    return !_.isEqual(curr, prev);
                  }

                  state.values.layers.forEach((layer, index) => {
                    // update start/end time if relative time chosen
                    if (
                      layer.isRelativeTimePeriod &&
                      layer.amountOfTime &&
                      layer.unitOfTime
                    ) {
                      const relative = getRelativeTimePeriod({
                        isRetrospective: true,
                        includeCurrent: layer.includeCurrentTime,
                        amount: layer.amountOfTime,
                        unit: layer.unitOfTime,
                      });
                      layer.startTime = relative.startTime;
                      layer.endTime = relative.endTime;
                    }

                    if (
                      layer.source !== 'file' &&
                      layerChanged(
                        layer,
                        oldFormValuesRef.current?.layers?.[index]
                      )
                    ) {
                      // don't estimate if there are errors...
                      if (!state.errors.layers?.[index]) {
                        dispatch({
                          type: ESTIMATE_RETROSPECTIVE_LAYER_RESULT_COUNT,
                          payload: {
                            index,
                            layer,
                            filters: relevantFilters(layer),
                          },
                        });
                      } else {
                        dispatch({
                          type: ESTIMATE_RETROSPECTIVE_LAYER_RESULT_COUNT_CANCELLED,
                          payload: {
                            index,
                            layer,
                          },
                        });
                      }
                    }
                  });

                  oldFormValuesRef.current = state.values;
                }

                dispatch({
                  type: SYNC_RETROSPECTIVE_FORM,
                  payload: state.values,
                });
              }}
            />
          </form>
        );
      }}
    />
  );
}
