import {
  Box,
  Button,
  CardActions,
  CardHeader,
  Divider,
  ListSubheader,
  Menu,
  MenuItem,
  Paper,
} from '@mui/material';
import {
  Business as BusinessIcon,
  Face as FaceIcon,
  Person as PersonIcon,
  SwapHoriz as SwapHorizIcon,
} from '@mui/icons-material';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import {
  CREATE_PERSON,
  DELETE_PERSON,
  FETCH_HOME_STATIONS,
  FETCH_PERSON,
  FETCH_PERSON_SUCCESS,
  FETCH_WARDS,
  UPDATE_PERSON,
  UPDATE_EVENTS_QUERY,
} from '../../actions';
import { doesIdExist, getRfidErrors, ssiValid } from '../../apis/utilities';
import { useAuth } from '../Auth';
import { Section, TagControl } from '../controls';
import { ConfirmationDialog } from '../dialogs';
import {
  AutosuggestField,
  AvatarField,
  CheckboxField,
  ChipField,
  Field,
  GroupsField,
  required,
  SelectField,
  SelectMultipleField,
  TextField,
  TypesField,
  DateTimeField,
  isDateValidOrEmpty,
  StyledField,
} from '../fields';
import { useSnackbar } from '../Snackbar';

const {
  personRoles,
  personRanks,
  personGroups,
  personSkills,
  personEditableFields,
  useDallasKeys,
  useRestricted,
  isFleet,
} = window.config;

const categoryIcons = {
  Business: <BusinessIcon />,
  Commute: <SwapHorizIcon />,
  Personal: <FaceIcon />,
};

export default function Person() {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const person = useSelector((state) => state.people.person, _.isEqual);
  const error = useSelector((state) => state.people.error);
  const people = useSelector((state) => state.people.people, _.isEqual);
  const homeStationNames = useSelector(
    (state) => state.locations.homeStationNames,
    _.isEqual
  );
  const wards = useSelector((state) => state.locations.wardNames, _.isEqual);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const auth = useAuth();
  const snackbar = useSnackbar();
  const canEdit = auth.isAuthorised('people', true);

  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (error) {
      snackbar.notify('error', error);
    }
  }, [error, snackbar]);

  useEffect(() => {
    if (id === 'new') {
      dispatch({
        type: FETCH_PERSON_SUCCESS,
        payload: null,
      });
    } else {
      dispatch({
        type: FETCH_PERSON,
        payload: id,
      });
    }
    dispatch({
      type: FETCH_HOME_STATIONS,
    });
    dispatch({
      type: FETCH_WARDS,
    });
  }, [id, dispatch]);

  async function onSubmit(values) {
    const validSsi = await ssiValid(values.radioSsi, values.code);
    if (!validSsi) {
      return { radioSsi: 'In use' };
    }

    const rfidErrors = await getRfidErrors(values.code, values.rfidCards);

    if (rfidErrors) {
      return { rfidCards: rfidErrors };
    }

    if (person) {
      dispatch({
        type: UPDATE_PERSON,
        payload: values,
      });
    } else {
      const exists = await doesIdExist('people', values.code);
      if (exists) {
        return { code: 'Exists' };
      }

      dispatch({
        type: CREATE_PERSON,
        payload: values,
        navigate,
      });
    }
  }

  function handleEventsClick(event) {
    setAnchorEl(event.currentTarget);
  }
  function handleEventsClose() {
    setAnchorEl(null);
  }

  const navigateToEvents = (eventType, pathname, type) => () => {
    const key = type === 'people' ? 'person.code' : 'driver.code';
    dispatch({
      type: UPDATE_EVENTS_QUERY,
      payload: {
        eventType,
        query: {
          [key]: {
            $in: [id],
          },
        },
      },
    });

    navigate(`/events/${type}/${pathname || eventType.toLowerCase()}`);
  };

  function handleDelete() {
    if (person) {
      dispatch({
        type: DELETE_PERSON,
        payload: person.code,
      });
    }
  }

  function isDisabled(fieldName) {
    return !(canEdit && (personEditableFields[fieldName] || person === null));
  }

  return (
    <Form
      initialValues={person || {}}
      onSubmit={onSubmit}
      render={({
        handleSubmit,
        form: { reset },
        submitting,
        dirty,
        pristine,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <Helmet>
            <title>
              IR3 | Person
              {values.forenames
                ? ` | ${values.forenames || ''}  ${values.surname || ''}`
                : ''}
            </title>
          </Helmet>
          <Paper sx={{ m: 1, minWidth: 240 }}>
            <CardHeader
              avatar={
                <Field
                  name="picture"
                  component={AvatarField}
                  icon={<PersonIcon />}
                  disabled={isDisabled('picture')}
                />
              }
              title={`${values.forenames || ''}  ${values.surname || ''}`}
              subheader={values.collarNumber}
            />
            <Box>
              <ListSubheader disableSticky>Key Information</ListSubheader>
              <Section>
                <StyledField
                  name="code"
                  component={TextField}
                  label="Payroll Number"
                  validate={required}
                  disabled={!(canEdit && person === null)}
                />
                <StyledField
                  name="forenames"
                  component={TextField}
                  label="Forenames"
                  validate={required}
                  disabled={isDisabled('forenames')}
                />
                <StyledField
                  name="surname"
                  component={TextField}
                  label="Surname"
                  validate={required}
                  disabled={isDisabled('surname')}
                />
                <StyledField
                  name="collarNumber"
                  component={TextField}
                  label="Collar Number"
                  validate={required}
                  disabled={isDisabled('collarNumber')}
                />
                <StyledField
                  name="rank.code"
                  component={SelectField}
                  label="Rank"
                  values={personRanks.map((rank) => ({
                    label: rank.name,
                    value: rank.code,
                  }))}
                  disabled={isDisabled('rank')}
                />
                <StyledField
                  name="role"
                  component={SelectField}
                  label="Role"
                  validate={required}
                  values={personRoles}
                  disabled={isDisabled('role')}
                />
                <StyledField
                  name="emailAddress"
                  type="email"
                  component={TextField}
                  label="Email"
                  disabled={isDisabled('emailAddress')}
                />
                <StyledField
                  name="supervisorCode"
                  component={AutosuggestField}
                  label="Supervisor"
                  disabled={isDisabled('supervisorCode')}
                  suggestions={people
                    .sort((a, b) =>
                      `${a.surname}, ${a.forenames}`.localeCompare(
                        `${b.surname}, ${b.forenames}`
                      )
                    )
                    .map((person) => ({
                      label: `${person.forenames} ${person.surname}`,
                      value: person.code,
                    }))}
                />
                <StyledField
                  name="leavingDate"
                  component={DateTimeField}
                  label="Leaving Date"
                  disabled={isDisabled('leavingDate') || id === 'new'}
                  validate={isDateValidOrEmpty}
                />
                {useRestricted && (
                  <StyledField
                    name="restricted"
                    component={CheckboxField}
                    label="Restricted"
                    disabled={isDisabled('restricted')}
                  />
                )}
              </Section>
              <Divider />
              <ListSubheader disableSticky>Home</ListSubheader>
              <Section>
                <StyledField
                  name="homeStation"
                  component={SelectField}
                  label="Station"
                  values={homeStationNames.map((station) => ({
                    label: station.name,
                    value: station.code,
                  }))}
                  disabled={isDisabled('homeStation')}
                />
                <Field
                  name="wards"
                  label="Wards"
                  sx={{ pb: 1.5 }}
                  fullWidth
                  component={SelectMultipleField}
                  suggestions={wards.map((ward) => ({
                    label: ward.name,
                    value: ward.code,
                  }))}
                  disabled={isDisabled('wards')}
                />
              </Section>
              <Divider />
              <ListSubheader disableSticky>Groups & Areas</ListSubheader>
              <Section>
                <Field
                  name="groups"
                  groups={personGroups}
                  component={GroupsField}
                  disabled={isDisabled('groups')}
                />
              </Section>
              <Divider />
              <ListSubheader disableSticky>Skills</ListSubheader>
              <Section>
                <Field
                  name="skills"
                  types={personSkills}
                  component={TypesField}
                  disabled={isDisabled('skills')}
                />
              </Section>
              <Divider />
              <ListSubheader disableSticky>Radio</ListSubheader>
              <Section>
                <StyledField
                  name="radioSsi"
                  component={TextField}
                  label="SSI"
                  disabled={isDisabled('radioSsi')}
                />
                <StyledField
                  label="Last Poll Time"
                  name="lastPollTime"
                  component={TextField}
                  format={(value) =>
                    value ? moment(value).fromNow() : 'never'
                  }
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Section>
              <Divider />
              <ListSubheader disableSticky>
                {useDallasKeys ? 'Dallas Keys' : 'RFID Cards'}
              </ListSubheader>
              <Section>
                <Field
                  name={'rfidCards'}
                  sx={{ pb: 1.5 }}
                  component={ChipField}
                  categoryIcons={categoryIcons}
                  defaultCategory="Business"
                  disabled={isDisabled('rfidCards')}
                />
              </Section>
              <Divider />
              <ListSubheader disableSticky>Tags</ListSubheader>
              <Section>
                <TagControl item={{ id }} type={'people'} sx={{ pb: 1.5 }} />
              </Section>
            </Box>
            <CardActions>
              <Button
                color="primary"
                type="submit"
                disabled={pristine || submitting}
              >
                Save
              </Button>
              <Button
                color="primary"
                disabled={pristine || submitting}
                onClick={reset}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                aria-owns={anchorEl ? 'events-menu' : undefined}
                aria-haspopup="true"
                onClick={handleEventsClick}
                disabled={dirty || submitting || person === null}
              >
                Events
              </Button>
              <Menu
                id="events-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleEventsClose}
              >
                {!isFleet && (
                  <MenuItem
                    onClick={navigateToEvents('trails', 'trails', 'people')}
                  >
                    Trails
                  </MenuItem>
                )}
                {!isFleet && (
                  <MenuItem
                    onClick={navigateToEvents(
                      'personLocationVisits',
                      'locationVisits',
                      'people'
                    )}
                  >
                    Location Visits
                  </MenuItem>
                )}
                {!isFleet && (
                  <MenuItem
                    onClick={navigateToEvents(
                      'doubleCrews',
                      'doubleCrews',
                      'people'
                    )}
                  >
                    Double Crews
                  </MenuItem>
                )}
                {!isFleet && (
                  <MenuItem
                    onClick={navigateToEvents(
                      'attendances',
                      'attendances',
                      'people'
                    )}
                  >
                    Attendances
                  </MenuItem>
                )}
                <MenuItem
                  onClick={navigateToEvents('trips', 'trips', 'vehicles')}
                >
                  Trips
                </MenuItem>
                <MenuItem
                  onClick={navigateToEvents('stops', 'stops', 'vehicles')}
                >
                  Stops
                </MenuItem>
                <MenuItem
                  onClick={navigateToEvents('idles', 'idles', 'vehicles')}
                >
                  Idles
                </MenuItem>

                <MenuItem
                  onClick={navigateToEvents(
                    'speedInfractions',
                    'speedInfractions',
                    'vehicles'
                  )}
                >
                  Speed Infractions
                </MenuItem>
                <MenuItem
                  onClick={navigateToEvents(
                    'accelerometerEvents',
                    'accelerometerEvents',
                    'vehicles'
                  )}
                >
                  Accelerometer Events
                </MenuItem>
              </Menu>
              {canEdit && (
                <Button
                  color="error"
                  onClick={() => setDeleteOpen(true)}
                  disabled={person === null}
                >
                  Delete
                </Button>
              )}
            </CardActions>
          </Paper>
          <ConfirmationDialog
            action="Delete"
            open={deleteOpen}
            itemId={values.name || values.collarNumber}
            onOk={handleDelete}
            onCancel={() => setDeleteOpen(false)}
          />
        </form>
      )}
    />
  );
}
