import {
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  Typography,
  IconButton,
  Box,
} from '@mui/material';
import {
  LocationSearching as FollowIcon,
  Edit as EditIcon,
} from '@mui/icons-material';
import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import moment from 'moment';
import _ from 'lodash';
import { ItemRows, areasToLabelAccessors } from './ItemRows';
import avatarForItem from './avatarUtility';
import {
  ContentViewer,
  TagControl,
  WeeklySchedulePicker,
} from '../../controls';

// example object
/*
$type: "add"
areas: Array(1)
  0:
    name: "City Centre"
    type: "district"
boundaryIdentifier: "OS_BRTN"
boundarySubtype: "Base"
boundaryType: "Location"
briefs: Array(2)
  0: "OPS"
  1: "AAA"
complianceSeconds: 1200
occurrenceNumber: "OCUR123"
days: Array(7)
  0: 0
  1: 1
  2: 2
  3: 3
  4: 4
  5: 5
  6: 6
description: "<p><img src="data:image/jpeg;base64,/9j/4AAQSkZJRg"
endTime: "2021-04-11T10:59:00.000Z"
hours: Array(12)
  0: 0
  1: 2
  2: 4
  3: 6
  4: 8
  5: 10
  6: 12
  7: 14
  8: 16
  9: 18
  10: 20
  11: 22
id: "FO"
identifier: "FO"
lastEdit:
  time: "2019-12-03T11:39:59.286Z"
  userId: "john.leonard@landaconsultants.com"
searchString: "[object object]+os_brtn+base+location+ops,aaa+1200"
startTime: "2018-09-09T23:00:00.000Z"
title: "First Objective"
type: "Patrol"
// visits have:
requiredFrequency: 'daily',
requiredVisits: 5,

*/

function ObjectiveLiveItem({ item, onFollowToggle, followedIdsByType }) {
  const navigate = useNavigate();
  const hideFollow = !item.boundary;
  const type = 'objectives';
  const following = followedIdsByType?.[type]?.[item.id];

  const rowItems = [
    !!item.occurrenceNumber && {
      label: 'Occurrence Number',
      value: item.occurrenceNumber,
    },
    {
      label: 'Start',
      value: item.startTime && moment(item.startTime).format('DD/MM/YYYY'),
    },
    { label: 'End', value: moment(item.endTime).format('DD/MM/YYYY') },
    item.complianceSeconds && {
      label: 'Compliant Minutes',
      value: `${item.complianceSeconds / 60} m`,
    },
    item.requiredVisits && {
      label: 'Visits',
      value: item.requiredVisits,
    },
    item.requiredFrequency && {
      label: 'Frequency',
      value: _.startCase(item.requiredFrequency),
    },
    // item.briefs && { label: 'Briefs', value: item.briefs.join(', ') },
    ...areasToLabelAccessors(item.areas),
  ].filter(Boolean);

  const link = `/${type}/${item.id}`;

  return (
    <Card sx={{ m: 1 }}>
      <Helmet>
        <title>{`IR3 | Live | Objectives | ${item.title || item.id}`}</title>
      </Helmet>
      <CardHeader
        avatar={avatarForItem(item, 'objectives')}
        title={item.title}
        subheader={item.id}
        action={
          <Fragment>
            <IconButton
              aria-label="Edit"
              onClick={() => navigate(link)}
              title="Edit"
              size="large"
            >
              <EditIcon fontSize="small" />
            </IconButton>
            {!hideFollow && (
              <IconButton
                aria-label="Toggle follow"
                onClick={() => onFollowToggle(type, item.id)}
                title="Toggle follow"
                size="large"
              >
                <FollowIcon
                  fontSize="small"
                  color={following ? 'primary' : 'inherit'}
                />
              </IconButton>
            )}
          </Fragment>
        }
      />
      <CardContent>
        <TagControl
          live
          item={item}
          type={'objectives'}
          sx={{ py: 2 }}
          label="Tags"
        />
        <Typography variant="subtitle2" color="textSecondary">
          Description
        </Typography>
        <Box sx={{ p: 1 }}>
          <ContentViewer content={item.description} />
        </Box>
        <Typography variant="subtitle2" color="textSecondary">
          Details
        </Typography>
        <Table size="small" sx={{ mt: 1, mb: 2 }}>
          <TableBody>{ItemRows(rowItems, item)}</TableBody>
        </Table>
        <Typography variant="subtitle2" color="textSecondary">
          Schedule
        </Typography>
        {/* <div className={classes.timeContainer}> */}
        <Box sx={{ pt: 1 }}>
          <WeeklySchedulePicker value={item.schedule} disabled scale={0.46} />
        </Box>
        {/* </div> */}
      </CardContent>
    </Card>
  );
}

export default ObjectiveLiveItem;
