import {
  Avatar,
  Paper,
  CardContent,
  Collapse,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  FormControl,
  FormControlLabel,
  ListSubheader,
  RadioGroup,
  Radio,
  Box,
  Stack,
} from '@mui/material';
import {
  green,
  indigo,
  orange,
  red,
  teal,
  lightBlue,
  purple,
} from '@mui/material/colors';
import {
  FilterList as FilterListIcon,
  GetApp as GetAppIcon,
  Sort as SortIcon,
  BarChart as BarChartIcon,
} from '@mui/icons-material';
import _ from 'lodash';
import { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import {
  Bar,
  BarChart,
  Brush,
  Label,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import moment from 'moment';
import { useSnackbar } from '../../Snackbar';
import {
  FETCH_VEHICLE_DAILY_UTILISATION,
  FILTER_VEHICLE_DAILY_UTILISATION,
  FETCH_VEHICLE_DAILY_UTILISATION_CANCELLED,
  LOAD_VEHICLE_DAILY_UTILISATION_QUERY,
} from '../../../actions';
import {
  SelectMultiple,
  Table,
  TablePagination,
  Parameters,
  CustomTooltip,
} from '../../controls';
import Container from '../../Container';
import { getKeyLabel, getTextWidth } from '../../../data/constants';
import { downloadCSV } from '../../../apis/utilities';
import { useEffectOnce, useDebounce } from '../../../hooks';
import { ToggleListField } from '../../fields';

const { useReducedResourceInformation, rowsPerPageOptions, homeOtherSplit } =
  window.config;

const hours = [...Array(24).keys()];
const days = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];

const commonLabels = [
  ['unaccountable', 'Unaccounted'],
  ['totalMileage', 'Total Mileage'],
  ['averageMileage', 'Average Mileage'],
  ['dailyMileage', 'Daily Mileage'],
  ['totalTrips', 'Total Trips'],
  ['averageTrips', 'Average Trips'],
  ['dailyTrips', 'Daily Trips'],
  ['hours', 'Hours'],
  ['percentage', 'Percentage'],
  ['activity', 'Activity'],
  ['status', 'Status'],
];

const classificationSettings = {
  activity: {
    bars: [
      ...(homeOtherSplit
        ? [
            { name: 'stoppedHomeBase', colour: red[800] },
            { name: 'stoppedOtherBase', colour: red[500] },
            { name: 'idleHomeBase', colour: purple[700] },
            { name: 'idleOtherBase', colour: purple[300] },
          ]
        : [
            { name: 'stoppedBase', colour: red[800] },
            { name: 'idleBase', colour: red[500] },
          ]),
      { name: 'stoppedWorkshop', colour: orange[800] },
      { name: 'idleWorkshop', colour: orange[500] },
      { name: 'stoppedElsewhere', colour: teal[800] },
      { name: 'idleElsewhere', colour: teal[500] },
      { name: 'moving', colour: green[500] },
      { name: 'unaccountable', colour: lightBlue[400] },
    ],
    labels: new Map([
      ...(homeOtherSplit
        ? [
            ['stoppedHomeBase', 'Stopped @ Home Base'],
            ['stoppedOtherBase', 'Stopped @ Other Base'],
            ['idleHomeBase', 'Idle @ Home Base'],
            ['idleOtherBase', 'Idle @ Other Base'],
          ]
        : [
            ['stoppedBase', 'Stopped @ Base'],
            ['idleBase', 'Idle @ Base'],
          ]),
      ['stoppedWorkshop', 'Stopped @ Workshop'],
      ['idleWorkshop', 'Idle @ Workshop'],
      ['stoppedElsewhere', 'Stopped Elsewhere'],
      ['idleElsewhere', 'Idle Elsewhere'],
      ['moving', 'Moving'],
      ...commonLabels,
    ]),
    classificationHeaders: [
      {
        label: 'Moving (hours)',
        key: 'moving',
        type: 'number',
      },
      ...(homeOtherSplit
        ? [
            {
              label: 'Stopped @ Home Base (hours)',
              key: 'stoppedHomeBase',
              type: 'number',
            },
            {
              label: 'Stopped @ Other Base (hours)',
              key: 'stoppedOtherBase',
              type: 'number',
            },
          ]
        : [
            {
              label: 'Stopped @ Base (hours)',
              key: 'stoppedBase',
              type: 'number',
            },
          ]),
      {
        label: 'Stopped @ Workshop (hours)',
        key: 'stoppedWorkshop',
        type: 'number',
      },
      {
        label: 'Stopped Elsewhere (hours)',
        key: 'stoppedElsewhere',
        type: 'number',
      },
      ...(homeOtherSplit
        ? [
            {
              label: 'Idle @ Home Base (hours)',
              key: 'idleHomeBase',
              type: 'number',
            },
            {
              label: 'Idle @ Other Base (hours)',
              key: 'idleOtherBase',
              type: 'number',
            },
          ]
        : [
            {
              label: 'Idle @ Base (hours)',
              key: 'idleBase',
              type: 'number',
            },
          ]),
      {
        label: 'Idle @ Workshop (hours)',
        key: 'idleWorkshop',
        type: 'number',
      },
      {
        label: 'Idle Elsewhere (hours)',
        key: 'idleElsewhere',
        type: 'number',
      },
      {
        label: 'Unaccounted (hours)',
        key: 'unaccountable',
        type: 'number',
      },
    ],
    orderByValues: [
      ...(homeOtherSplit
        ? [
            'stoppedHomeBase',
            'stoppedOtherBase',
            'idleHomeBase',
            'idleOtherBase',
          ]
        : ['stoppedBase', 'idleBase']),
      'stoppedWorkshop',
      'idleWorkshop',
      'stoppedElsewhere',
      'idleElsewhere',
      'moving',
      'unaccountable',
    ],
  },
  status: {
    bars: [
      { name: 'unused', colour: red[800] },
      { name: 'unavailable', colour: orange[800] },
      { name: 'used', colour: green[500] },
      { name: 'unaccountable', colour: lightBlue[400] },
    ],
    labels: new Map([
      ['unused', 'Unused'],
      ['unavailable', 'Unavailable'],
      ['used', 'Used'],
      ...commonLabels,
    ]),
    classificationHeaders: [
      {
        label: 'Unused (hours)',
        key: 'unused',
        type: 'number',
      },
      {
        label: 'Unavailable (hours)',
        key: 'unavailable',
        type: 'number',
      },
      {
        label: 'Used (hours)',
        key: 'used',
        type: 'number',
      },
      {
        label: 'Unaccounted (hours)',
        key: 'unaccountable',
        type: 'number',
      },
    ],
    orderByValues: ['unused', 'unavailable', 'used', 'unaccountable'],
  },
};

const measureTypes = ['total', 'average', 'daily'];
const chartTypes = ['hours', 'percentage'];

export default function DailyUtilisation(props) {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.utilisation.daily.data, _.isEqual);
  const { groupBy, orderBy, order, filter, classifyBy, chartType } =
    useSelector((state) => state.utilisation.daily);
  const query = useSelector(
    (state) => state.utilisation.daily.query,
    _.isEqual
  );
  const isLoading = useSelector((state) => state.utilisation.daily.isLoading);
  const isFiltering = useSelector(
    (state) => state.utilisation.daily.isFiltering
  );
  const error = useSelector((state) => state.utilisation.daily.error);
  const groupByValues = useSelector(
    (state) => state.utilisation.daily.groupByValues,
    _.isEqual
  );
  const filterValues = useSelector(
    (state) => state.utilisation.daily.filterValues,
    _.isEqual
  );
  const [showFilter, setShowFilter] = useState(false);
  const [hiddenBars, setHiddenBars] = useState([]);
  const [sortMenuAnchor, setSortMenuAnchor] = useState(null);
  const [viewMenuAnchor, setViewMenuAnchor] = useState(null);
  const [mileageType, setMileageType] = useState('totalMileage');
  const [tripCountType, setTripCountType] = useState('totalTrips');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const snackbar = useSnackbar();

  const { bars, labels, classificationHeaders, orderByValues } =
    classificationSettings[classifyBy];

  const [filterParams, setFilterParams] = useState({
    query,
    filter,
    classifyBy,
    chartType,
  });
  const debouncedFilterParams = useDebounce(filterParams, 1000);

  useEffect(() => {
    dispatch({
      type: FILTER_VEHICLE_DAILY_UTILISATION,
      payload: debouncedFilterParams,
    });
  }, [dispatch, debouncedFilterParams]);

  function formatGroup(value) {
    switch (groupBy) {
      case 'date':
        return moment(value).format('DD/MM/YYYY');
      case 'month':
        return moment(value).format('MMM YYYY');
      default:
        return value;
    }
  }

  const headers = [
    {
      label: `${getKeyLabel(groupBy)}`,
      key: 'group',
      type: groupBy === 'date' ? 'date' : 'text',
      format: formatGroup,
    },
    {
      label: 'Vehicle Count',
      key: 'count',
      type: 'number',
    },
    ...classificationHeaders,
    {
      label: 'Total Mileage',
      key: 'totalMileage',
      type: 'number',
    },
    {
      label: 'Average Mileage',
      key: 'averageMileage',
      type: 'number',
    },
    {
      label: 'Daily Mileage',
      key: 'dailyMileage',
      type: 'number',
    },
    {
      label: 'Total Trips',
      key: 'totalTrips',
      type: 'number',
    },
    {
      label: 'Average Trips',
      key: 'averageTrips',
      type: 'number',
    },
    {
      label: 'Daily Trips',
      key: 'dailyTrips',
      type: 'number',
    },
  ];

  useEffect(() => {
    if (error) {
      snackbar.notify('error', error);
    }
  }, [error, snackbar]);

  useEffectOnce(() => {
    dispatch({
      type: LOAD_VEHICLE_DAILY_UTILISATION_QUERY,
    });
  }, [dispatch]);

  function handleFilterToggle() {
    setShowFilter(!showFilter);
  }

  function handleFetch(event, query) {
    dispatch({
      type: FETCH_VEHICLE_DAILY_UTILISATION,
      payload: {
        query,
        filter,
        groupBy,
        orderBy,
        order,
        classifyBy,
        chartType,
      },
    });
  }

  function handleCancel() {
    dispatch({
      type: FETCH_VEHICLE_DAILY_UTILISATION_CANCELLED,
    });
  }

  function handleGroupByFieldChanged(groupBy) {
    updateTransformOptions({ groupBy });
    setViewMenuAnchor(null);
  }

  function handlePercentOrHoursClick(value) {
    setViewMenuAnchor(null);
    updateTransformOptions({ chartType: value });
  }

  function handleClassificationClick(value) {
    setViewMenuAnchor(null);
    updateTransformOptions({ classifyBy: value });
  }

  function handleFilterFieldChanged(name, values) {
    const newFilter =
      name in filterParams.filter
        ? {
            ...filterParams.filter,
            [name]: values || [],
          }
        : {
            ...filterParams.filter,
            areas: {
              ...filterParams.filter.areas,
              [name]: values || [],
            },
          };

    updateTransformOptions({ filter: newFilter });
  }

  function handleMileageTypeFieldChanged(mileageType) {
    setMileageType(mileageType);
  }

  function handleTripCountTypeFieldChanged(tripCountType) {
    setTripCountType(tripCountType);
  }

  function handleLegendClick(selectedBar) {
    const index = hiddenBars.indexOf(selectedBar);

    if (index === -1) {
      setHiddenBars(hiddenBars.concat(selectedBar));
    } else {
      setHiddenBars(
        hiddenBars.slice(0, index).concat(hiddenBars.slice(index + 1))
      );
    }
  }

  function handleViewMenuOpen(event) {
    setViewMenuAnchor(event.target);
  }

  function handleViewMenuClose() {
    setViewMenuAnchor(null);
  }

  function handleSortMenuOpen(target) {
    setSortMenuAnchor(target);
  }

  function handleSortMenuClose() {
    setSortMenuAnchor(null);
  }

  function updateTransformOptions(optionChanges) {
    setFilterParams({
      query,
      filter,
      orderBy,
      order,
      groupBy,
      classifyBy,
      chartType,
      ...optionChanges,
    });
  }

  function handleSortItemClick(newOrderBy) {
    // tell the epic how does it feel about changing the sort order
    let newOrder = order;
    if (newOrderBy === orderBy) {
      newOrder = newOrder === 'asc' ? 'desc' : 'asc';
    }

    updateTransformOptions({ orderBy: newOrderBy, order: newOrder });
    setSortMenuAnchor(null);
  }

  function handlePageChange(event, newPage) {
    setPage(newPage);
  }

  function handleRowsPerPageChange(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  function handleOrderChange(order) {
    updateTransformOptions({ order });
  }

  function handleOrderByChange(orderBy) {
    updateTransformOptions({ orderBy, order: 'asc' });
  }

  async function handleDownloadClick() {
    const filename = 'Vehicle Daily Utilisation.csv';

    downloadCSV(
      data.map(({ group, ...record }) => ({
        group: formatGroup(group),
        ...record,
      })),
      filename,
      headers
    );
  }

  const xAxisHeight =
    data.length === 0
      ? 0
      : getTextWidth(
          data
            .map((item) => formatGroup(item.group))
            .sort((a, b) => b.length - a.length)[0],
          '12px Roboto'
        ) + 24;

  return (
    <Fragment>
      <Container title="Daily Utilisation">
        {isFiltering && (
          <LinearProgress
            sx={{ position: 'absolute', width: 1, height: 8 }}
            color="secondary"
          />
        )}
        <Parameters
          onFetch={handleFetch}
          onCancel={handleCancel}
          isFetching={isLoading && !isFiltering}
          value={query}
          sx={{ mt: 1, width: 264 }}
          pointEvent
          dateOnly
          utc
          vehicle
        />
        <Box
          sx={{
            flex: 1,
            height: 'calc(100vh - 48px)',
            overflowY: 'auto',
            overflowX: 'hidden',
            '& > :last-child': {
              mb: 1,
            },
          }}
        >
          <Helmet>
            <title>IR3 | Daily Utilisation</title>
          </Helmet>
          <Toolbar variant="dense" disableGutters sx={{ p: 1, pb: 0 }}>
            <Typography variant="subtitle1">Daily Utilisation</Typography>
            <Box sx={{ flexGrow: 1 }} />
            <IconButton
              title={showFilter ? 'Hide filter' : 'Show filter'}
              onClick={handleFilterToggle}
            >
              <FilterListIcon color={showFilter ? 'primary' : 'inherit'} />
            </IconButton>
            <IconButton
              title="Change view"
              aria-owns={viewMenuAnchor ? 'date-menu' : undefined}
              aria-haspopup="true"
              onClick={(event) => handleViewMenuOpen(event)}
            >
              <BarChartIcon />
            </IconButton>
            <Menu
              anchorEl={viewMenuAnchor}
              open={Boolean(viewMenuAnchor)}
              onClose={handleViewMenuClose}
            >
              <ListSubheader disableSticky>Group by</ListSubheader>
              {Object.values(groupByValues).map((value) => (
                <MenuItem
                  key={value}
                  value={value}
                  selected={value === groupBy}
                  onClick={() => handleGroupByFieldChanged(value)}
                >
                  {getKeyLabel(value)}
                </MenuItem>
              ))}
              <ListSubheader disableSticky>View as</ListSubheader>
              {chartTypes.map((value) => (
                <MenuItem
                  key={value}
                  value={value}
                  selected={value === chartType}
                  onClick={() => handlePercentOrHoursClick(value)}
                >
                  {labels.get(value)}
                </MenuItem>
              ))}
              <ListSubheader disableSticky>Classify by</ListSubheader>
              {Object.keys(classificationSettings).map((value) => (
                <MenuItem
                  key={value}
                  value={value}
                  selected={value === classifyBy}
                  onClick={() => handleClassificationClick(value)}
                >
                  {labels.get(value)}
                </MenuItem>
              ))}
            </Menu>
            <IconButton
              title="Sort by"
              aria-owns={sortMenuAnchor ? 'date-menu' : undefined}
              aria-haspopup="true"
              onClick={(event) => handleSortMenuOpen(event.target)}
            >
              <SortIcon />
            </IconButton>
            <Menu
              anchorEl={sortMenuAnchor}
              open={Boolean(sortMenuAnchor)}
              onClose={() => handleSortMenuClose()}
            >
              <ListSubheader disableSticky>Sort by</ListSubheader>

              {groupBy !== 'all' && (
                <MenuItem
                  onClick={() => handleSortItemClick('group')}
                  selected={'group' === orderBy}
                >
                  {getKeyLabel(groupBy)}
                </MenuItem>
              )}
              {orderByValues
                .concat([mileageType, tripCountType])
                .map((value) => (
                  <MenuItem
                    key={value}
                    onClick={() => handleSortItemClick(value)}
                    selected={value === orderBy}
                  >
                    {labels.get(value)}
                  </MenuItem>
                ))}
            </Menu>
            <IconButton
              title="Download data"
              disabled={data.length === 0}
              onClick={handleDownloadClick}
            >
              <GetAppIcon />
            </IconButton>
          </Toolbar>
          <Collapse in={showFilter} timeout="auto">
            <Stack spacing={1.5} sx={{ flex: 1, p: 1.5 }}>
              {!useReducedResourceInformation && (
                <SelectMultiple
                  disabled={isFiltering}
                  label="Registration Number"
                  placeholder="Select..."
                  value={filterParams.filter.registrationNumber}
                  labelValue
                  onChange={(value) => {
                    handleFilterFieldChanged('registrationNumber', value);
                  }}
                  suggestions={filterValues.registrationNumber.map((value) => ({
                    label: value,
                    value,
                  }))}
                />
              )}
              <SelectMultiple
                disabled={isFiltering}
                label="Fleet Number"
                placeholder="Select..."
                value={filterParams.filter.fleetNumber}
                labelValue
                onChange={(value) =>
                  handleFilterFieldChanged('fleetNumber', value)
                }
                suggestions={filterValues.fleetNumber.map((value) => ({
                  label: value,
                  value,
                }))}
              />
              {useReducedResourceInformation ? (
                <SelectMultiple
                  disabled={isFiltering}
                  label="Type"
                  placeholder="Select..."
                  value={filterParams.filter.type}
                  labelValue
                  onChange={(value) => handleFilterFieldChanged('type', value)}
                  suggestions={filterValues.type.map((value) => ({
                    label: value,
                    value,
                  }))}
                />
              ) : (
                <SelectMultiple
                  disabled={isFiltering}
                  label="Role"
                  placeholder="Select..."
                  value={filterParams.filter.role}
                  labelValue
                  onChange={(value) => handleFilterFieldChanged('role', value)}
                  suggestions={filterValues.role.map((value) => ({
                    label: value,
                    value,
                  }))}
                />
              )}
              {Object.entries(filterValues.areas).map((entry) => (
                <SelectMultiple
                  disabled={isFiltering}
                  key={entry[0]}
                  label={getKeyLabel(entry[0])}
                  placeholder="Select..."
                  value={filterParams.filter.areas[entry[0]] || []}
                  labelValue
                  onChange={(value) =>
                    handleFilterFieldChanged(entry[0], value)
                  }
                  suggestions={entry[1].map((value) => ({
                    label: value,
                    value,
                  }))}
                />
              ))}
              <ToggleListField
                disabled={isFiltering}
                input={{
                  onChange: (value) => handleFilterFieldChanged('hour', value),
                  value: filterParams.filter.hour,
                }}
                values={hours}
                label={'Hours'}
              />
              <ToggleListField
                disabled={isFiltering}
                input={{
                  onChange: (value) => handleFilterFieldChanged('day', value),
                  value: filterParams.filter.day,
                }}
                values={days}
                label={'Days'}
              />
            </Stack>
          </Collapse>
          <Paper sx={{ m: [0, 1], minWidth: 240, fontSize: 12 }}>
            <CardContent sx={{ p: 0, pt: 4 }}>
              <Box
                sx={{
                  pl: 8,
                  pr: 2,
                  pb: 1,
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                }}
              >
                {bars.map((bar) => (
                  <Box
                    key={bar.name}
                    sx={{
                      p: 0.5,
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={() => handleLegendClick(bar.name)}
                  >
                    <Avatar
                      sx={{
                        width: 12,
                        height: 12,
                        mr: 0.5,
                        bgcolor: !hiddenBars.includes(bar.name) && bar.colour,
                      }}
                    >
                      <Fragment />
                    </Avatar>
                    <Typography variant="caption">
                      {labels.get(bar.name)}
                    </Typography>
                  </Box>
                ))}
              </Box>
              <ResponsiveContainer width="99%" height={520}>
                <BarChart
                  data={data}
                  margin={{ top: 0, right: 16, left: 0, bottom: 32 }}
                  barCategoryGap={4}
                >
                  <XAxis
                    dataKey="group"
                    tickFormatter={formatGroup}
                    angle={-90}
                    textAnchor="end"
                    interval={0}
                    height={xAxisHeight}
                  >
                    <Label
                      value={getKeyLabel(groupBy)}
                      offset={36}
                      position="bottom"
                    />
                  </XAxis>
                  <YAxis>
                    <Label
                      value={
                        chartType === 'percentage' ? 'Percentage' : 'Hours'
                      }
                      angle={-90}
                      position="left"
                      offset={-24}
                    />
                  </YAxis>
                  <Tooltip
                    cursor={false}
                    content={
                      <CustomTooltip
                        unit={chartType === 'percentage' ? '%' : 'hours'}
                        labelFormatter={formatGroup}
                      />
                    }
                  />
                  {data.length > 0 && <Brush dataKey="group" height={24} />}
                  {bars.map((bar) => (
                    <Bar
                      key={bar.name}
                      dataKey={bar.name}
                      name={labels.get(bar.name)}
                      stackId="a"
                      fill={bar.colour}
                      hide={hiddenBars.includes(bar.name)}
                    />
                  ))}
                </BarChart>
              </ResponsiveContainer>
            </CardContent>
          </Paper>
          <Toolbar sx={{ display: 'flex', justifyContent: 'center' }}>
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="mileage chart type"
                name="mileage chart type"
                value={mileageType}
                onChange={(e) => handleMileageTypeFieldChanged(e.target.value)}
              >
                {measureTypes.map((measureType) => (
                  <FormControlLabel
                    key={measureType}
                    value={`${measureType}Mileage`}
                    control={<Radio />}
                    label={labels.get(`${measureType}Mileage`)}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Toolbar>
          <Paper sx={{ m: [0, 1], minWidth: 240, fontSize: 12 }}>
            <CardContent sx={{ p: 0, pt: 4 }}>
              <ResponsiveContainer width="99%" height={520}>
                <BarChart
                  data={data}
                  margin={{ top: 32, right: 16, left: 32, bottom: 32 }}
                  barCategoryGap={4}
                >
                  <XAxis
                    dataKey="group"
                    tickFormatter={formatGroup}
                    angle={-90}
                    textAnchor="end"
                    interval={0}
                    height={xAxisHeight}
                  >
                    <Label
                      value={getKeyLabel(groupBy)}
                      offset={36}
                      position="bottom"
                    />
                  </XAxis>
                  <YAxis>
                    <Label
                      value="Miles"
                      angle={-90}
                      position="left"
                      offset={8}
                    />
                  </YAxis>
                  <Tooltip
                    cursor={false}
                    content={<CustomTooltip labelFormatter={formatGroup} />}
                  />
                  {data.length > 0 && <Brush dataKey="group" height={24} />}
                  {measureTypes.map((measureType) => (
                    <Bar
                      key={measureType}
                      dataKey={`${measureType}Mileage`}
                      name={labels.get(`${measureType}Mileage`)}
                      fill={indigo[800]}
                      hide={mileageType !== `${measureType}Mileage`}
                    />
                  ))}
                </BarChart>
              </ResponsiveContainer>
            </CardContent>
          </Paper>
          <Toolbar sx={{ display: 'flex', justifyContent: 'center' }}>
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="trip chart type"
                name="trip chart type"
                value={tripCountType}
                onChange={(e) =>
                  handleTripCountTypeFieldChanged(e.target.value)
                }
              >
                {measureTypes.map((measureType) => (
                  <FormControlLabel
                    key={measureType}
                    value={`${measureType}Trips`}
                    control={<Radio />}
                    label={labels.get(`${measureType}Trips`)}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Toolbar>
          <Paper sx={{ m: [0, 1], minWidth: 240, fontSize: 12 }}>
            <CardContent sx={{ p: 0, pt: 4 }}>
              <ResponsiveContainer width="99%" height={520}>
                <BarChart
                  data={data}
                  margin={{ top: 32, right: 16, left: 32, bottom: 32 }}
                  barCategoryGap={4}
                >
                  <XAxis
                    dataKey="group"
                    tickFormatter={formatGroup}
                    angle={-90}
                    textAnchor="end"
                    interval={0}
                    height={xAxisHeight}
                  >
                    <Label
                      value={getKeyLabel(groupBy)}
                      offset={36}
                      position="bottom"
                    />
                  </XAxis>
                  <YAxis>
                    <Label
                      value="Trips"
                      angle={-90}
                      position="left"
                      offset={8}
                    />
                  </YAxis>
                  <Tooltip
                    cursor={false}
                    content={<CustomTooltip labelFormatter={formatGroup} />}
                  />
                  {data.length > 0 && <Brush dataKey="group" height={24} />}
                  {measureTypes.map((measureType) => (
                    <Bar
                      key={measureType}
                      dataKey={`${measureType}Trips`}
                      name={labels.get(`${measureType}Trips`)}
                      fill={indigo[800]}
                      hide={tripCountType !== `${measureType}Trips`}
                    />
                  ))}
                </BarChart>
              </ResponsiveContainer>
            </CardContent>
          </Paper>
          <Toolbar variant="dense" disableGutters sx={{ p: 1, pb: 0 }}>
            <Typography variant="subtitle1">Daily Utilisation</Typography>
          </Toolbar>
          <Paper sx={{ m: [0, 1], minWidth: 240, fontSize: 12 }}>
            <Table
              data={data}
              headers={headers}
              rowsPerPage={rowsPerPage}
              page={page}
              dense={true}
              order={order}
              orderBy={orderBy}
              onOrderChange={handleOrderChange}
              onOrderByChange={handleOrderByChange}
            />
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
            />
          </Paper>
        </Box>
      </Container>
    </Fragment>
  );
}
