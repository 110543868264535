import { useState, useRef, useContext, createContext, Fragment } from 'react';
import {
  Box,
  Collapse,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { NavLink } from 'react-router-dom';
import { useAuth } from './Auth';
import ir3Logo from '../svg/ir3Logo.svg';
import { getAuthorisedSiteMap, isVisible } from '../routing';

const SideNavContext = createContext();

export const useSideNav = () => useRef(useContext(SideNavContext)).current;

function SideNav({ isOpen, close }) {
  const [expandedNodes, setExpandedNodes] = useState([]);
  const auth = useAuth();

  function isExpanded(key) {
    return expandedNodes.includes(key);
  }

  const toggleNode = (key) => () => {
    if (isExpanded(key)) {
      setExpandedNodes(
        expandedNodes.filter((expandedKey) => expandedKey !== key)
      );
    } else {
      setExpandedNodes(expandedNodes.concat(key));
    }
  };

  function Tree({ siteMap, level }) {
    return siteMap
      .filter(({ key }) => isVisible(key, auth))
      .map(({ key, label, Icon, path, children }) =>
        !!path ? (
          <ListItemButton
            key={key}
            dense
            end={path === '/'}
            component={NavLink}
            to={path}
            sx={{
              '&.active': {
                color: 'primary.main',
                bgcolor: 'primary.background',
                '& svg': {
                  color: 'primary.main',
                },
              },
              borderRadius: 1,
              m: 1,
              height: 40,
              px: 2,
            }}
            onClick={close}
          >
            <ListItemIcon>
              <Icon />
            </ListItemIcon>
            <ListItemText
              primary={label}
              primaryTypographyProps={{
                sx: {
                  fontWeight: 500,
                },
              }}
            />
          </ListItemButton>
        ) : (
          <Fragment key={key}>
            <ListItemButton
              dense
              onClick={toggleNode(key)}
              sx={{ borderRadius: 0.5, m: 1, height: 40, px: 2 }}
            >
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText
                primary={label}
                primaryTypographyProps={{
                  sx: {
                    fontWeight: 500,
                  },
                }}
              />
              <ExpandMoreIcon
                sx={(theme) => ({
                  transform: isExpanded(key)
                    ? 'rotate(180deg)'
                    : 'rotate(0deg)',
                  marginLeft: 'auto',
                  transition: theme.transitions.create('transform', {
                    duration: theme.transitions.duration.shortest,
                  }),
                })}
              />
            </ListItemButton>
            <Collapse in={isExpanded(key)} timeout="auto" unmountOnExit>
              <List component="div" disablePadding sx={{ pl: level + 1 }}>
                <Tree siteMap={children} level={level + 1} />
              </List>
            </Collapse>
          </Fragment>
        )
      );
  }

  return (
    <Drawer open={isOpen} onClose={close}>
      <Box sx={{ width: 280, flex: 'initial' }}>
        <Box
          sx={{ pt: 1, pl: 2, pr: 2, alignItems: 'center', display: 'flex' }}
        >
          <img src={ir3Logo} alt="IR3 Logo" style={{ height: 24 }} />
          <Typography
            variant="h5"
            sx={{
              pl: 0.5,
              fontWeight: 'bold',
              color: 'primary.main',
              flex: 1,
            }}
          >
            IR3
          </Typography>
          <Typography variant="caption" sx={{ right: 1 }}>
            v{process.env.REACT_APP_VERSION}
          </Typography>
        </Box>
        <List disablePadding sx={{ pr: 0 }}>
          <Tree siteMap={getAuthorisedSiteMap(auth)} level={0} />
        </List>
      </Box>
    </Drawer>
  );
}

export function SideNavProvider({ children }) {
  const [isOpen, setIsOpen] = useState(false);

  function open() {
    setIsOpen(true);
  }

  function close() {
    setIsOpen(false);
  }

  return (
    <SideNavContext.Provider
      value={{
        open,
        close,
        isOpen,
      }}
    >
      <SideNav isOpen={isOpen} close={close} />
      {children}
    </SideNavContext.Provider>
  );
}
