import { alpha, Box, IconButton } from '@mui/material';
import {
  ZoomIn as ZoomInIcon,
  ZoomOut as ZoomOutIcon,
  ZoomOutMap as ZoomOutMapIcon,
} from '@mui/icons-material';
import { Collection, Feature, Map } from 'ol';
import { defaults as defaultControls } from 'ol/control';
import { altKeyOnly } from 'ol/events/condition';
import { GeoJSON } from 'ol/format';
import {
  defaults as defaultInteractions,
  MouseWheelZoom as MouseWheelZoomInteraction,
} from 'ol/interaction';
import { Vector as VectorLayer } from 'ol/layer';
import 'ol/ol.css';
import { Vector as VectorSource } from 'ol/source';
import { Fill, Style, Circle } from 'ol/style';
import { useState, useEffect, useRef } from 'react';
import { setBaseLayers, getMapView } from '../../mapStyles';
import { usePrevious } from '../../hooks';
import MapLayerSwitcher from './MapLayerSwitcher';

function defaultStyle(feature) {
  switch (feature.get('name')) {
    case 'point':
      return [
        new Style({
          image: new Circle({
            radius: 8,
            fill: new Fill({
              color: 'rgb(33,150,243)',
            }),
          }),
        }),
        // new Style({
        //   image: new Icon({
        //     src: RouterIcon,
        //     scale: 0.5
        //   })
        // })
      ];
    default:
      return [];
  }
}

export default function TelematicsBoxMap({ point }) {
  const [zoomInDisabled, setZoomInDisabled] = useState(false);
  const [zoomOutDisabled, setZoomOutDisabled] = useState(false);
  const prevPoint = usePrevious(point);

  const mapDiv = useRef(null);
  const vectorSource = useRef(null);
  const map = useRef(null);

  function resetMapControls() {
    setZoomInDisabled(
      map.current.getView().getZoom() === map.current.getView().getMaxZoom()
    );
    setZoomOutDisabled(
      map.current.getView().getZoom() === map.current.getView().getMinZoom()
    );
  }

  useEffect(() => {
    if (!map.current) {
      vectorSource.current = new VectorSource({
        features: new Collection([]),
      });

      map.current = new Map({
        target: mapDiv.current,
        layers: [
          new VectorLayer({
            source: vectorSource.current,
            style: defaultStyle,
          }),
        ],
        interactions: defaultInteractions({
          mouseWheelZoom: false,
          pinchRotate: false,
          altShiftDragRotate: false,
        }).extend([
          new MouseWheelZoomInteraction({
            condition: altKeyOnly,
          }),
        ]),
        view: getMapView(),
        controls: defaultControls({
          attribution: false,
          rotate: false,
          zoom: false,
        }),
      });

      setBaseLayers(map.current);
    }
  }, []);

  useEffect(() => {
    vectorSource.current.clear();

    if (point) {
      const geometry = new GeoJSON().readGeometry(point, {
        featureProjection: 'EPSG:3857',
      });

      vectorSource.current.addFeature(
        new Feature({
          geometry,
          name: 'point',
        })
      );

      if (!prevPoint) {
        map.current.getView().fit(geometry, { maxZoom: 15 });
      } else {
        map.current.getView().setCenter(geometry.getCoordinates());
      }
      resetMapControls();
    }
  }, [point, prevPoint]);

  function handleZoomInClick() {
    if (map.current) {
      map.current.getView().setZoom(map.current.getView().getZoom() + 1);
      resetMapControls();
    }
  }

  function handleZoomOutClick() {
    if (map.current) {
      map.current.getView().setZoom(map.current.getView().getZoom() - 1);
      resetMapControls();
    }
  }

  function handleFitClick() {
    if (vectorSource.current && vectorSource.current.getFeatures().length > 0) {
      map.current
        .getView()
        .fit(vectorSource.current.getExtent(), { maxZoom: 15 });
      resetMapControls();
    }
  }

  return (
    <Box sx={{ position: 'relative', width: 1, height: 1 }}>
      <Box id="map" sx={{ width: 1, height: 1 }} ref={mapDiv} />
      <Box
        sx={{
          top: 8,
          left: 8,
          borderRadius: 1,
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          bgcolor: alpha('#fff', 0.85),
        }}
      >
        <IconButton
          title="Zoom In"
          aria-label="Zoom In"
          disabled={zoomInDisabled}
          onClick={handleZoomInClick}
          size="large"
        >
          <ZoomInIcon />
        </IconButton>
        <IconButton
          title="Zoom Out"
          aria-label="Zoom Out"
          disabled={zoomOutDisabled}
          onClick={handleZoomOutClick}
          size="large"
        >
          <ZoomOutIcon />
        </IconButton>
        <IconButton
          title="Fit"
          aria-label="Fit"
          onClick={handleFitClick}
          size="large"
        >
          <ZoomOutMapIcon />
        </IconButton>
      </Box>
      <MapLayerSwitcher mapRef={map} />
    </Box>
  );
}
