import { Fragment, useState } from 'react';
import {
  Box,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from '@mui/material';
import {
  AddCircle as AddIcon,
  RemoveCircle as RemoveIcon,
} from '@mui/icons-material';
import moment from 'moment';
import { useForm } from 'react-final-form';
import Field from './Field';
import SelectField from './SelectField';
import SelectMultipleField from './SelectMultipleField';
import AutosuggestField from './AutosuggestField';
import TextField from './TextField';
import DateField from './DateField';
import DateTimeField from './DateTimeField';
import AdornmentField from './AdornmentField';
import { requiredValidDate } from './validators';

const conditions = [
  { label: '=', value: '$eq' },
  { label: String.fromCharCode(8800), value: '$ne' }, // != so chrome can debug
  { label: '>', value: '$gt' },
  { label: String.fromCharCode(8805), value: '$gte' }, // >= so chrome can debug
  { label: '<', value: '$lt' },
  { label: String.fromCharCode(8804), value: '$lte' }, // <= so chrome can debug
];

const timeConditions = [
  { label: String.fromCharCode(8805), value: '$gte' }, // >= so chrome can debug
  { label: '<', value: '$lt' },
];

const units = [
  { label: 's', value: 's' },
  { label: 'm', value: 'm' },
  { label: 'h', value: 'h' },
  { label: 'd', value: 'd' },
];

function milesToKilometres(value) {
  if (value === undefined || value === '') {
    return '';
  }

  return parseFloat(value) * 1.609344;
}

function kilometresToMiles(value) {
  if (value === undefined || value === '') {
    return '';
  }

  return Math.round(parseFloat(value) * 0.62137119);
}

function parseFloatOrBlank(value) {
  value = parseFloat(value);
  if (isNaN(value)) {
    return '';
  }

  return value;
}

function NumericTextField(props) {
  // html type numeric accepts multiple -'s and .'s for some reason
  // I'll make sure it's only ever a float here before onChange to form
  const [internalValue, setInternalValue] = useState(
    props?.input?.value?.toString() ?? '0'
  );

  function handleChange(event) {
    const newValue = event.target.value;

    // start with zero or one minus  ^-?
    // then any number of digits [0-9]*
    // then zero or one decimal point \.?
    // then end with number of digits [0-9]*
    if (/^-?[0-9]*\.?[0-9]*$/.test(newValue)) {
      // it's a valid float, set our internal value fire input.onChange
      setInternalValue(newValue);
      props.input.onChange(parseFloatOrBlank(event.target.value));
    }
  }

  return (
    <TextField
      {...props}
      value={internalValue}
      type="text"
      onChange={handleChange}
    />
  );
}

const numericTypes = ['number', 'miles', 'duration', 'date', 'datetime'];
export function ValueField({
  name,
  type,
  values,
  unit,
  styles,
  filterOptions,
  onlyEqual,
  parse,
  format,
  anyOption,
  noneOption,
  labelValue,
  label = 'Value',
  showNonExistantAsError,
}) {
  label = label ?? (type === 'multiselect' ? 'Values' : 'Value');

  switch (type) {
    case 'autocomplete':
      return (
        <Field
          name={`${name}.value`}
          component={AutosuggestField}
          label={label}
          sx={{ flex: 1 }}
          suggestions={values || []}
          styles={styles}
        />
      );
    case 'select':
      return (
        <Field
          name={`${name}.value`}
          component={SelectField}
          label={label}
          values={values || []}
          styles={styles}
          sx={{ flex: 1 }}
        />
      );
    case 'multiselect':
      return (
        <Field
          name={`${name}.value`}
          component={SelectMultipleField}
          label={label}
          sx={{ flex: 1 }}
          suggestions={values || []}
          filterOptions={filterOptions}
          styles={styles}
          anyOption={anyOption}
          noneOption={noneOption}
          labelValue={labelValue}
          showNonExistantAsError={showNonExistantAsError}
        />
      );
    case 'number':
      return (
        <Fragment>
          <Field
            name={`${name}.value`}
            component={NumericTextField}
            label={label}
            // type="number"
            parse={parse}
            format={format}
            sx={{ flex: 1 }}
            InputProps={{
              startAdornment: onlyEqual ? undefined : (
                <Field
                  name={`${name}.condition`}
                  component={AdornmentField}
                  position="start"
                  values={conditions}
                />
              ),
              endAdornment: unit ? (
                <InputAdornment position="end">{unit}</InputAdornment>
              ) : undefined,
            }}
          />
        </Fragment>
      );
    case 'miles':
      return (
        <Fragment>
          <Field
            name={`${name}.value`}
            component={NumericTextField}
            label={label}
            type="number"
            sx={{ flex: 1 }}
            InputProps={{
              startAdornment: (
                <Field
                  name={`${name}.condition`}
                  component={AdornmentField}
                  position="start"
                  values={conditions}
                />
              ),
              endAdornment: unit ? (
                <InputAdornment position="end">{unit}</InputAdornment>
              ) : undefined,
            }}
            parse={milesToKilometres}
            format={kilometresToMiles}
          />
        </Fragment>
      );
    case 'duration':
      return (
        <Fragment>
          <Field
            name={`${name}.value`}
            component={NumericTextField}
            label={label}
            type="number"
            sx={{ flex: 1 }}
            InputProps={{
              startAdornment: (
                <Field
                  name={`${name}.condition`}
                  component={AdornmentField}
                  position="start"
                  values={conditions}
                />
              ),
              endAdornment: (
                <Field
                  name={`${name}.unit`}
                  component={AdornmentField}
                  position="end"
                  values={units}
                />
              ),
            }}
          />
        </Fragment>
      );
    case 'date':
      return (
        <Fragment>
          <Field
            name={`${name}.value`}
            label={label}
            component={DateField}
            sx={{ flex: 1 }}
            // as this is a filter it is more performant to save the filter value as an
            // ISO string instead of converting every item's value to a moment to compare
            parse={
              parse ||
              ((value) =>
                moment(value).isValid()
                  ? value.startOf('day').toISOString()
                  : value)
            }
            validate={requiredValidDate}
            InputProps={{
              startAdornment: onlyEqual ? undefined : (
                <Field
                  name={`${name}.condition`}
                  component={AdornmentField}
                  position="start"
                  values={timeConditions}
                />
              ),
            }}
          />
        </Fragment>
      );
    case 'datetime':
      return (
        <Fragment>
          <Field
            name={`${name}.value`}
            label={label}
            component={DateTimeField}
            sx={{ flex: 1 }}
            // as this is a filter it is more performant to save the filter value as an
            // ISO string instead of converting every item's value to a moment to compare
            parse={(value) =>
              moment(value).isValid() ? value.toISOString() : value
            }
            validate={requiredValidDate}
            InputProps={{
              startAdornment: (
                <Field
                  name={`${name}.condition`}
                  component={AdornmentField}
                  position="start"
                  values={timeConditions}
                />
              ),
            }}
          />
        </Fragment>
      );
    case 'text':
    default:
      return (
        <Field
          name={`${name}.value`}
          component={TextField}
          label={label}
          type="text"
          sx={{ flex: 1 }}
        />
      );
  }
}

export default function FilterField({ fields, filters, label }) {
  // use form mutators as a workaround for retro filter bug
  // https://github.com/final-form/react-final-form-arrays/issues/156
  const form = useForm();
  const formPush = form.mutators.push;
  const formRemove = form.mutators.remove;

  function handleAddClick() {
    // fields.push({ condition: '$eq' });
    formPush(fields.name, { condition: '$eq' });
  }

  const handleRemoveClick = (index) => () => {
    // fields.remove(index);
    formRemove(fields.name, index);
  };

  return (
    <Box>
      <Typography variant="subtitle2" color="textSecondary" sx={{ mb: 0.5 }}>
        {label}
      </Typography>
      <Box>
        {fields.map((name, index) => {
          const thisField = fields.value[index];
          const fieldValues =
            Object.entries(filters)
              // in the field option, only show ones that aren't picked elsewhere
              // so allow it if it's this field OR it's not in any other field value
              // OR it's numeric; it's possible to have speed > 30 and < 60 for example
              .filter(
                ([key]) =>
                  thisField.field === key ||
                  !fields.value.some((f) => f.field === key) ||
                  numericTypes.includes(filters[key].type)
              )
              .map((filter) => ({
                label: filter[1].label,
                value: filter[0],
              })) || [];

          fieldValues.sort((a, b) => a.label.localeCompare(b.label));

          const filter = filters[thisField.field] || {};

          // if the type is date/time constrain the condition as it doesn't have =
          if (
            !filter.onlyEqual &&
            (filter.type === 'date' || filter.type === 'datetime')
          ) {
            if (
              thisField &&
              !timeConditions.find(({ value }) => value === thisField.condition)
            ) {
              fields.update(index, {
                ...thisField,
                condition: timeConditions[0].value,
              });
            }
          }

          function handleFieldChange() {
            // resetFilter if that mutator exists
            if (fields?.resetFilter) {
              fields.resetFilter(name);
            }
          }

          return (
            <Stack
              direction="row"
              sx={{ py: 1 }}
              spacing={1}
              alignItems="center"
              key={`${index}_${fields.length}`}
            >
              {/* <Field
                  name={`${name}.subject`}
                  component={SelectField}
                  label="Subject"
                  values={}
                  className={classes.fieldField}
                  // if the field changes, wipe the selections it had
                  onChange={wipeFilter}
                /> */}
              {!!!filter.type && (
                <Field
                  name={`${name}.field`}
                  sx={{ flex: 1 }}
                  component={SelectField}
                  label="Field"
                  values={fieldValues}
                  // sx={{ mr: 1, mb: 1, flex: 1 }}
                  // if the field changes, wipe the selections it had
                  onChange={handleFieldChange}
                />
              )}
              {filter.type && (
                <ValueField
                  name={name}
                  type={filter.type}
                  values={filter.values || []}
                  unit={filter.unit}
                  styles={filter.styles}
                  filterOptions={filter.filterOptions}
                  onlyEqual={filter.onlyEqual}
                  parse={filter.parse}
                  format={filter.format}
                  anyOption={filter.anyOption}
                  noneOption={filter.noneOption}
                  labelValue={filter.labelValue}
                  label={filter.label}
                  showNonExistantAsError={filter.showNonExistantAsError}
                />
              )}
              <IconButton
                // sx={{ mt: 1 }}
                title="Remove"
                aria-label="Remove"
                onClick={handleRemoveClick(index)}
              >
                <RemoveIcon fontSize="inherit" />
              </IconButton>
            </Stack>
          );
        })}
      </Box>
      {(fields.length < Object.keys(filters).length ||
        Object.values(filters).some((filter) =>
          numericTypes.includes(filter.type)
        )) && (
        <Stack direction="row" sx={{ py: 1 }} spacing={1} alignItems="center">
          <Box style={{ flex: 1 }} />
          <IconButton title="Add" aria-label="Add" onClick={handleAddClick}>
            <AddIcon fontSize="inherit" />
          </IconButton>
        </Stack>
      )}
    </Box>
  );
}
