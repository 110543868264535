import moment from 'moment';
import { add, startOfYear } from 'date-fns';
import {
  FETCH_VEHICLE_AVAILABILITY,
  FETCH_VEHICLE_AVAILABILITY_SUCCESS,
  FETCH_VEHICLE_AVAILABILITY_FAILURE,
  FETCH_VEHICLE_AVAILABILITY_CANCELLED,
  FILTER_PERSON_AVAILABILITY,
  FETCH_PERSON_AVAILABILITY,
  FETCH_PERSON_AVAILABILITY_CANCELLED,
  FETCH_PERSON_AVAILABILITY_FAILURE,
  FETCH_PERSON_AVAILABILITY_SUCCESS,
  FILTER_VEHICLE_AVAILABILITY,
  UPDATE_VEHICLE_AVAILABILITY_HOME_ONLY,
  FETCH_AUDIT_LOG_ENTRIES,
  FETCH_AUDIT_LOG_ENTRIES_SUCCESS,
  FETCH_AUDIT_LOG_ENTRIES_FAILURE,
  FETCH_AUDIT_LOG_ENTRIES_CANCELLED,
  FETCH_VEHICLE_IN_BASE_TIME,
  FETCH_VEHICLE_IN_BASE_TIME_SUCCESS,
  FETCH_VEHICLE_IN_BASE_TIME_FAILURE,
  FETCH_VEHICLE_IN_BASE_TIME_CANCELLED,
  LOAD_VEHICLE_IN_BASE_TIME,
  LOAD_VEHICLE_IN_BASE_TIME_SUCCESS,
  LOAD_VEHICLE_IN_BASE_TIME_FAILURE,
  FETCH_DRIVING_SCORES,
  FETCH_DRIVING_SCORES_SUCCESS,
  FETCH_DRIVING_SCORES_FAILURE,
  FETCH_DRIVING_SCORES_CANCELLED,
  FETCH_AGGREGATED_DRIVING_SCORES,
  FETCH_AGGREGATED_DRIVING_SCORES_SUCCESS,
  FETCH_AGGREGATED_DRIVING_SCORES_FAILURE,
  FETCH_AGGREGATED_DRIVING_SCORES_CANCELLED,
  UPDATE_AGGREGATED_DRIVING_SCORE_FILTER,
  LOAD_AGGREGATED_DRIVING_SCORES,
  LOAD_AGGREGATED_DRIVING_SCORES_SUCCESS,
  FETCH_DRIVER_TRIPS_BY_DRIVER_CODE,
  FETCH_DRIVER_TRIPS_BY_DRIVER_CODE_SUCCESS,
  FETCH_DRIVER_TRIPS_BY_DRIVER_CODE_FAILURE,
  FETCH_DRIVER_TRIPS_BY_DRIVER_CODE_CANCELLED,
  LOAD_AGGREGATED_DRIVING_SCORES_FAILURE,
  UPDATE_FILTER_TIME_AGGREGATION,
  UPDATE_SELECTED_AGGREGATED_TRIPS_DRIVER,
  FETCH_VEHICLES_IN_LOCATIONS,
  FETCH_VEHICLES_IN_LOCATIONS_SUCCESS,
  FETCH_VEHICLES_IN_LOCATIONS_FAILURE,
  FETCH_VEHICLES_IN_LOCATIONS_CANCELLED,
  LOAD_VEHICLES_IN_LOCATIONS,
  LOAD_VEHICLES_IN_LOCATIONS_SUCCESS,
  LOAD_VEHICLES_IN_LOCATIONS_FAILURE,
  FETCH_VEHICLE_ODOMETERS,
  FETCH_VEHICLE_ODOMETERS_SUCCESS,
  FETCH_VEHICLE_ODOMETERS_FAILURE,
  UPDATE_VEHICLE_ODOMETERS_FILTER,
} from '../actions';

const drivingScoresDataTemplate = {
  totals: {
    drivingSeconds: 0,
    harshAccelerationSeconds: 0,
    harshBrakingSeconds: 0,
    harshCorneringSeconds: 0,
    lowestScore: 0,
    highestScore: 0,
    averageScore: 0,
  },
  drivers: [],
  trips: [],
  trend: [],
};

const { rowsPerPageOptions } = window.config;

const INITIAL_STATE = {
  drivingScores: {
    data: {
      ...drivingScoresDataTemplate,
    },
    filter: {
      code: [],
      name: [],
      collarNumber: [],
      role: [],
      areas: {},
      // emergencyEquipmentUsed: 0
    },
    filterValues: {
      code: [],
      name: [],
      collarNumber: [],
      role: [],
      areas: {},
    },
    isLoading: false,
    error: null,
  },
  aggregatedDrivingScores: {
    data: {
      statsAll: { ...drivingScoresDataTemplate },
      statsWithEmergencyEquipment: { ...drivingScoresDataTemplate },
      statsWithoutEmergencyEquipment: { ...drivingScoresDataTemplate },
    },
    filter: {
      code: [],
      name: [],
      collarNumber: [],
      role: [],
      areas: {},
      // TODOJL add time aggregation (daily, weekly) and use of emergency equipment here?
    },
    filterValues: {
      code: [],
      name: [],
      collarNumber: [],
      role: [],
      areas: {},
    },
    isLoading: false,
    error: null,
    startTime: add(startOfYear(new Date()), { years: -10 }),
    endTime: add(startOfYear(new Date()), { years: 1 }),
    timeAggregation: 'years',
    selectedDriverCode: null,
    selectedDriverTripsKey: null,
  },
  vehicleInBaseTime: {
    data: [],
    filter: {
      registrationNumber: [],
      fleetNumber: [],
      role: [],
      type: [],
      locationName: [],
      locationType: [],
      areas: {},
    },
    filterValues: {
      registrationNumber: [],
      fleetNumber: [],
      role: [],
      type: [],
      locationName: [],
      locationType: [],
      areas: {},
    },
    isLoading: false,
    error: null,
  },
  vehiclesInLocations: {
    data: [],
    filter: {
      registrationNumber: [],
      fleetNumber: [],
      role: [],
      type: [],
      locationName: [],
      locationType: [],
      areas: {},
    },
    filterValues: {
      registrationNumber: [],
      fleetNumber: [],
      role: [],
      type: [],
      locationName: [],
      locationType: [],
      areas: {},
    },
    isLoading: false,
    error: null,
  },
  mileage: {
    data: [],
    isLoading: false,
    error: null,
    filter: {
      page: 0,
      rowsPerPage: rowsPerPageOptions[0],
      order: 'asc',
      orderBy: 'startTime',
      date: moment().startOf('day').toDate(),
      fields: {
        registrationNumber: null,
        fleetNumber: null,
      },
    },
  },
  vehicleAvailability: {
    homeOnly: true,
    data: [],
    filteredData: [],
    filter: {
      location: [],
      locationType: [],
      grouping: [],
    },
    filterOptions: {
      location: [],
      locationType: [],
      grouping: [],
    },
    isLoading: false,
    error: null,
  },
  personAvailability: {
    data: [],
    filteredData: [],
    filter: {
      homeStation: [],
      grouping: [],
    },
    filterOptions: {
      homeStation: [],
      grouping: [],
    },
    isLoading: false,
    error: null,
  },
  outages: [],
  auditLogEntries: [],
  doubleCrews: [],
  driverTrips: [],
  isLoading: false,
  error: null,
};

export default function reportsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_VEHICLE_AVAILABILITY:
      return {
        ...state,
        vehicleAvailability: {
          ...state.vehicleAvailability,
          filteredData: [],
          data: [],
          isLoading: true,
          error: null,
        },
      };
    case FETCH_VEHICLE_AVAILABILITY_SUCCESS:
      return {
        ...state,
        vehicleAvailability: {
          ...state.vehicleAvailability,
          ...action.payload,
          isLoading: false,
          error: null,
        },
      };
    case FETCH_VEHICLE_AVAILABILITY_FAILURE:
      return {
        ...state,
        vehicleAvailability: {
          ...state.vehicleAvailability,
          filteredData: [],
          data: [],
          isLoading: false,
          error: action.payload,
        },
      };
    case FETCH_VEHICLE_AVAILABILITY_CANCELLED:
      return {
        ...state,
        vehicleAvailability: {
          ...state.vehicleAvailability,
          filteredData: [],
          data: [],
          isLoading: false,
          error: null,
        },
      };
    case FILTER_VEHICLE_AVAILABILITY:
      return {
        ...state,
        vehicleAvailability: {
          ...state.vehicleAvailability,
          filter: action.payload.filter,
          filteredData: state.vehicleAvailability.data.filter((record) =>
            Object.keys(action.payload.filter).every(
              (key) =>
                action.payload.filter[key].length === 0 ||
                action.payload.filter[key].includes(record[key])
            )
          ),
          isLoading: false,
          error: null,
        },
      };
    case UPDATE_VEHICLE_AVAILABILITY_HOME_ONLY:
      return {
        ...state,
        vehicleAvailability: {
          ...state.vehicleAvailability,
          homeOnly: action.payload,
        },
      };
    case FETCH_PERSON_AVAILABILITY:
      return {
        ...state,
        personAvailability: {
          ...state.personAvailability,
          filteredData: [],
          data: [],
          isLoading: true,
          error: null,
        },
      };
    case FETCH_PERSON_AVAILABILITY_SUCCESS:
      return {
        ...state,
        personAvailability: {
          ...state.personAvailability,
          ...action.payload,
          isLoading: false,
          error: null,
        },
      };
    case FETCH_PERSON_AVAILABILITY_FAILURE:
      return {
        ...state,
        personAvailability: {
          ...state.personAvailability,
          filteredData: [],
          data: [],
          isLoading: false,
          error: action.payload,
        },
      };
    case FETCH_PERSON_AVAILABILITY_CANCELLED:
      return {
        ...state,
        personAvailability: {
          ...state.personAvailability,
          filteredData: [],
          data: [],
          isLoading: false,
          error: null,
        },
      };
    case FILTER_PERSON_AVAILABILITY:
      return {
        ...state,
        personAvailability: {
          ...state.personAvailability,
          filter: action.payload.filter,
          filteredData: state.personAvailability.data.filter((record) =>
            Object.keys(action.payload.filter).every(
              (key) =>
                action.payload.filter[key].length === 0 ||
                action.payload.filter[key].includes(record[key])
            )
          ),
          isLoading: false,
          error: null,
        },
      };
    case FETCH_VEHICLE_IN_BASE_TIME:
    case LOAD_VEHICLE_IN_BASE_TIME:
      return {
        ...state,
        vehicleInBaseTime: {
          ...state.vehicleInBaseTime,
          isLoading: true,
          error: null,
        },
      };
    case FETCH_VEHICLE_IN_BASE_TIME_SUCCESS:
    case LOAD_VEHICLE_IN_BASE_TIME_SUCCESS:
      return {
        ...state,
        vehicleInBaseTime: { ...action.payload, isLoading: false, error: null },
      };
    case FETCH_VEHICLE_IN_BASE_TIME_FAILURE:
    case LOAD_VEHICLE_IN_BASE_TIME_FAILURE:
      return {
        ...state,
        vehicleInBaseTime: {
          ...state.vehicleInBaseTime,
          isLoading: false,
          error: action.payload,
        },
      };
    case FETCH_VEHICLE_IN_BASE_TIME_CANCELLED:
      return {
        ...state,
        vehicleInBaseTime: {
          ...state.vehicleInBaseTime,
          isLoading: false,
        },
      };
    case FETCH_DRIVING_SCORES:
      return {
        ...state,
        drivingScores: {
          ...state.drivingScores, // keep blank filter etc.
          data: {}, // wipe data
          isLoading: true,
          error: null,
        },
      };
    case FETCH_DRIVING_SCORES_SUCCESS:
      return {
        ...state,
        drivingScores: { ...action.payload, isLoading: false, error: null },
      };
    case FETCH_DRIVING_SCORES_FAILURE:
      return {
        ...state,
        drivingScores: {
          ...state.drivingScores,
          isLoading: false,
          error: action.payload,
        },
      };
    case FETCH_DRIVING_SCORES_CANCELLED:
      return {
        ...state,
        drivingScores: {
          ...state.drivingScores,
          isLoading: false,
        },
      };
    case FETCH_AGGREGATED_DRIVING_SCORES:
    case LOAD_AGGREGATED_DRIVING_SCORES:
      return {
        ...state,
        aggregatedDrivingScores: {
          ...state.aggregatedDrivingScores,
          isLoading: true,
          error: null,
        },
      };
    case FETCH_AGGREGATED_DRIVING_SCORES_SUCCESS:
    case LOAD_AGGREGATED_DRIVING_SCORES_SUCCESS:
      return {
        ...state,
        aggregatedDrivingScores: {
          ...state.aggregatedDrivingScores,
          ...action.payload,
          isLoading: false,
          error: null,
        },
      };
    case FETCH_AGGREGATED_DRIVING_SCORES_FAILURE:
    case LOAD_AGGREGATED_DRIVING_SCORES_FAILURE:
      return {
        ...state,
        aggregatedDrivingScores: {
          ...state.aggregatedDrivingScores,
          isLoading: false,
          error: action.payload,
        },
      };
    case FETCH_AGGREGATED_DRIVING_SCORES_CANCELLED:
      return {
        ...state,
        aggregatedDrivingScores: {
          ...state.aggregatedDrivingScores,
          isLoading: false,
        },
      };
    case UPDATE_AGGREGATED_DRIVING_SCORE_FILTER:
      return {
        ...state,
        aggregatedDrivingScores: {
          ...state.aggregatedDrivingScores,
          filter: action.payload,
        },
      };
    case UPDATE_FILTER_TIME_AGGREGATION:
      const { timeAggregation, startTime, endTime } = action.payload;
      return {
        ...state,
        aggregatedDrivingScores: {
          ...state.aggregatedDrivingScores,
          timeAggregation,
          startTime,
          endTime,
        },
      };
    case UPDATE_SELECTED_AGGREGATED_TRIPS_DRIVER:
      return {
        ...state,
        aggregatedDrivingScores: {
          ...state.aggregatedDrivingScores,
          selectedDriverCode: action.payload.selectedDriverCode,
          selectedDriverTripsKey: action.payload.selectedDriverTripsKey,
        },
      };
    case FETCH_DRIVER_TRIPS_BY_DRIVER_CODE:
    case FETCH_DRIVER_TRIPS_BY_DRIVER_CODE_CANCELLED:
      return {
        ...state,
        driverTrips: [],
      };
    case FETCH_DRIVER_TRIPS_BY_DRIVER_CODE_FAILURE:
      return {
        ...state,
        driverTrips: [],
        error: action.payload,
      };
    case FETCH_DRIVER_TRIPS_BY_DRIVER_CODE_SUCCESS:
      return {
        ...state,
        // I only care about one driver at a time so this is ok for now.
        driverTrips: action.payload.trips,
      };
    case FETCH_VEHICLES_IN_LOCATIONS:
    case LOAD_VEHICLES_IN_LOCATIONS:
      return {
        ...state,
        vehiclesInLocations: {
          ...state.vehiclesInLocations,
          isLoading: true,
          error: null,
        },
      };
    case FETCH_VEHICLES_IN_LOCATIONS_SUCCESS:
    case LOAD_VEHICLES_IN_LOCATIONS_SUCCESS:
      return {
        ...state,
        vehiclesInLocations: {
          ...action.payload,
          isLoading: false,
          error: null,
        },
      };
    case FETCH_VEHICLES_IN_LOCATIONS_FAILURE:
    case LOAD_VEHICLES_IN_LOCATIONS_FAILURE:
      return {
        ...state,
        vehiclesInLocations: {
          ...state.vehiclesInLocations,
          data: [],
          isLoading: false,
          error: action.payload,
        },
      };
    case FETCH_VEHICLES_IN_LOCATIONS_CANCELLED:
      return {
        ...state,
        vehiclesInLocations: {
          ...state.vehiclesInLocations,
          isLoading: false,
        },
      };
    case FETCH_AUDIT_LOG_ENTRIES:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case FETCH_AUDIT_LOG_ENTRIES_SUCCESS:
      return {
        ...state,
        auditLogEntries: action.payload,
        isLoading: false,
        error: null,
      };
    case FETCH_AUDIT_LOG_ENTRIES_FAILURE:
      return {
        ...state,
        auditLogEntries: [],
        isLoading: false,
        error: action.payload,
      };
    case FETCH_AUDIT_LOG_ENTRIES_CANCELLED:
      return {
        ...state,
        isLoading: false,
      };
    case FETCH_VEHICLE_ODOMETERS:
      return {
        ...state,
        mileage: {
          ...state.mileage,
          isLoading: true,
          error: null,
        },
      };
    case FETCH_VEHICLE_ODOMETERS_SUCCESS:
      return {
        ...state,
        mileage: {
          ...state.mileage,
          data: action.payload,
          isLoading: false,
          error: null,
        },
      };
    case FETCH_VEHICLE_ODOMETERS_FAILURE:
      return {
        ...state,
        mileage: {
          ...state.mileage,
          data: [],
          isLoading: false,
          error: action.payload,
        },
      };
    case UPDATE_VEHICLE_ODOMETERS_FILTER:
      return {
        ...state,
        mileage: {
          ...state.mileage,
          filter: action.payload,
        },
      };
    default:
      return state;
  }
}
