import { Box, Paper, Toolbar, Typography } from '@mui/material';
import _ from 'lodash';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from '../Snackbar';
import { Helmet } from 'react-helmet-async';
import Container from '../Container';
import { FilterPicker, Parameters, TablePagination, Table } from '../controls';
import ChunkedDownloader from './ChunkedDownloader';
import usePaging from './usePaging';

const { rowsPerPageOptions } = window.config;
const { extraOutageHeaders } = window.reportHeaders;

const headers = [
  {
    label: 'IMEI',
    key: 'vehicle.telematicsBoxImei',
    type: 'text',
    filter: true,
  },
  ...extraOutageHeaders,
  {
    label: 'Start Location Type',
    key: 'startLocation.type',
    type: 'text',
    filter: true,
  },
  {
    label: 'Start Location Name',
    key: 'startLocation.name',
    type: 'text',
    filter: true,
  },
  {
    label: 'End Location Type',
    key: 'endLocation.type',
    type: 'text',
    filter: true,
  },
  {
    label: 'End Location Name',
    key: 'endLocation.name',
    type: 'text',
    filter: true,
  },
  { label: 'Start Time', key: 'startTime', type: 'date', filter: false },
  { label: 'End Time', key: 'endTime', type: 'date', filter: false },
  {
    label: 'Duration (minutes)',
    key: 'durationMinutes',
    serverKey: 'durationSeconds',
    type: 'number',
    filter: false,
  },
  {
    label: 'Distance (miles)',
    key: 'distanceMiles',
    serverKey: 'distanceKilometres',
    type: 'number',
    filter: false,
  },
];

export default function Outages() {
  const total = useSelector((state) => state.events.outages.total);
  const outages = useSelector((state) => state.events.outages.list, _.isEqual);
  const filter = useSelector((state) => state.events.outages.filter, _.isEqual);
  const query = useSelector((state) => state.events.outages.query, _.isEqual);
  const isLoading = useSelector((state) => state.events.outages.isLoading);
  const error = useSelector((state) => state.events.outages.error);
  const snackbar = useSnackbar();
  const eventType = 'outages';
  const paging = usePaging({ eventType, headers });

  useEffect(() => {
    if (error) {
      snackbar.notify('error', error);
    }
  }, [error, snackbar]);

  const filename = 'Telematics Box Outages.csv';
  const csvMapping = (outage) => ({
    ...outage,
    startTime: new Date(outage.startTime),
    endTime: new Date(outage.endTime),
  });

  // candidate for totals....
  // ************************
  // const footer = [
  //   {
  //     value: 'Totals',
  //     type: 'text',
  //     colSpan: useReducedResourceInformation ? 9 : 10,
  //   },
  //   {
  //     value: _.round(
  //       filteredOutages
  //         .map((outage) => outage.durationMinutes)
  //         .reduce((a, b) => a + b, 0) || 0,
  //       2
  //     ),
  //     type: 'number',
  //   },
  //   {
  //     value: _.round(
  //       filteredOutages
  //         .map((outage) => outage.distanceMiles)
  //         .reduce((a, b) => a + b, 0) || 0.0,
  //       2
  //     ),
  //     type: 'number',
  //   },
  // ];

  return (
    <Container title="Outages">
      <Parameters
        onFetch={paging.handleFetch}
        onCancel={paging.handleCancel}
        isFetching={isLoading}
        value={query}
        onChange={paging.handleQueryChange}
        sx={{ mt: 1, width: 264 }}
        vehicle
      />
      <Box
        sx={{
          flex: 1,
          height: 'calc(100vh - 48px)',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <Helmet>
          <title>IR3 | Outages</title>
        </Helmet>
        <Toolbar>
          <Typography sx={{ flexGrow: 1 }} variant="subtitle1">
            Outages
          </Typography>
          <FilterPicker
            headers={headers}
            data={outages}
            filter={filter}
            filterValues={filter.filterValues}
            onFilterChange={paging.onFilterChange}
          />
          <ChunkedDownloader
            eventType={eventType}
            filename={filename}
            headers={headers}
            csvMapping={csvMapping}
          />
        </Toolbar>
        <Paper sx={{ m: [0, 1, 1], minWidth: 240 }}>
          <Box sx={isLoading ? { opacity: 0.5 } : undefined}>
            <Table
              styles={{
                tableContainer: {
                  height: 'calc(100vh - 172px)',
                  overflowY: 'scroll',
                },
                table: {
                  minWidth: 750,
                },
              }}
              data={outages}
              headers={headers}
              // footer={footer}
              rowsPerPage={filter.rowsPerPage}
              page={0}
              order={filter.order}
              orderBy={filter.orderBy}
              onOrderChange={paging.handleOrderChange}
              onOrderByChange={paging.handleOrderByChange}
            />
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              component="div"
              count={total ?? 0}
              rowsPerPage={filter.rowsPerPage}
              page={filter.page}
              onPageChange={paging.handlePageChange}
              onRowsPerPageChange={paging.handleRowsPerPageChange}
            />
          </Box>
        </Paper>
      </Box>
    </Container>
  );
}
