import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import {
  ArrowUpward as ArrowUpwardIcon,
  Delete as DeleteIcon,
  History as HistoryIcon,
  Sort as SortIcon,
} from '@mui/icons-material';
import moment from 'moment';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { FETCH_RETROSPECTIVES } from '../../actions';
import { ConfirmationDialog } from '../dialogs';
import { SearchBox } from '../controls';

const sortOptions = [
  { label: 'Title', value: 'title' },
  { label: 'Last Edit', value: 'lastEdit.time' },
  { label: 'Created', value: 'created.time' },
];

function descendingComparator(a, b, orderBy) {
  if (_.get(b, orderBy, '') < _.get(a, orderBy, '')) {
    return -1;
  }
  if (_.get(b, orderBy, '') > _.get(a, orderBy, '')) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function OpenDialog({ open, onClose, onDelete }) {
  const dispatch = useDispatch();
  const retrospectives = useSelector(
    (state) => state.retrospectives.retrospectives,
    _.isEqual
  );
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [selectedTitle, setSelectedTitle] = useState('');
  const [selectedIdentifier, setSelectedIdentifier] = useState(null);
  const [filterText, setFilterText] = useState('');
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('title');
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    dispatch({
      type: FETCH_RETROSPECTIVES,
    });
  }, [dispatch]);

  function handleClose() {
    onClose();
  }

  function handleOpenClick() {
    onClose(selectedIdentifier);
  }

  function handleListItemClick(event) {
    setSelectedIdentifier(event.currentTarget.dataset.identifier);
  }

  function handleListItemDoubleClick(event) {
    onClose(event.currentTarget.dataset.identifier);
  }

  function handleFilterTextChange(event) {
    setFilterText(event.target.value);
  }

  function handleDeleteClick(event) {
    const title = retrospectives.find(
      (retrospective) =>
        retrospective.identifier === event.currentTarget.dataset.identifier
    ).title;

    setSelectedIdentifier(event.currentTarget.dataset.identifier);
    setSelectedTitle(title);
    setDeleteOpen(true);
  }

  function handleDelete() {
    onDelete(selectedIdentifier);

    setSelectedIdentifier(null);
    setSelectedTitle('');
    setDeleteOpen(false);
  }

  function handleSortClick(event) {
    setAnchorEl(event.currentTarget);
  }

  const handleSortMenuItemClick = (newOrderBy) => (event) => {
    if (newOrderBy === orderBy) {
      setOrder(order === 'asc' ? 'desc' : 'asc');
    } else {
      setOrderBy(newOrderBy);
      setOrder('asc');
    }
  };

  function handleSortClose() {
    setAnchorEl(null);
  }

  return (
    <Dialog onClose={handleClose} open={open}>
      <SearchBox
        value={filterText}
        onChange={handleFilterTextChange}
        sx={{ width: 300, p: 1 }}
      />
      <DialogContent sx={{ p: 0 }}>
        <List disablePadding>
          {stableSort(
            retrospectives.filter((retrospective) =>
              retrospective.title.toLowerCase().includes(filterText)
            ),
            getComparator(order, orderBy)
          ).map((retrospective) => (
            <ListItemButton
              data-identifier={retrospective.identifier}
              onClick={handleListItemClick}
              onDoubleClick={handleListItemDoubleClick}
              selected={selectedIdentifier === retrospective.identifier}
              key={retrospective.identifier}
            >
              <ListItemAvatar>
                <Avatar>
                  <HistoryIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={retrospective.title}
                secondary={
                  orderBy !== 'title' &&
                  _.get(retrospective, orderBy) &&
                  moment(
                    _.get(retrospective, orderBy, retrospective.created.time)
                  ).format('DD/MM/YYYY, HH:mm')
                }
              />
              <ListItemSecondaryAction>
                <IconButton
                  title="Delete"
                  aria-label="Delete"
                  data-identifier={retrospective.identifier}
                  onClick={handleDeleteClick}
                  color="error"
                  size="large"
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItemButton>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <IconButton
          title="Sort"
          aria-label="Sort"
          size="small"
          onClick={handleSortClick}
        >
          <SortIcon />
        </IconButton>
        <Menu
          id="sort-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleSortClose}
        >
          {sortOptions.map((option) => (
            <MenuItem
              key={option.value}
              onClick={handleSortMenuItemClick(option.value)}
            >
              {option.label}
              {orderBy === option.value && (
                <ListItemSecondaryAction>
                  <ArrowUpwardIcon
                    color="action"
                    fontSize="small"
                    sx={(theme) => ({
                      transform:
                        order === 'desc' ? 'rotate(180deg)' : 'rotate(0deg)',
                      // marginLeft: 'auto',
                      transition: theme.transitions.create('transform', {
                        duration: theme.transitions.duration.shortest,
                      }),
                    })}
                  />
                </ListItemSecondaryAction>
              )}
            </MenuItem>
          ))}
        </Menu>
        <div style={{ flex: 1 }} />
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleOpenClick}
          color="primary"
          disabled={!selectedIdentifier}
        >
          Open
        </Button>
      </DialogActions>
      <ConfirmationDialog
        action="Delete"
        open={deleteOpen}
        itemId={selectedTitle}
        onOk={handleDelete}
        onCancel={() => setDeleteOpen(false)}
      />
    </Dialog>
  );
}
