import { Alert, Snackbar as MuiSnackbar } from '@mui/material';
import { useState, useRef, useContext, createContext } from 'react';
import _ from 'lodash';

const SnackbarContext = createContext();

export const useSnackbar = () => useRef(useContext(SnackbarContext)).current;

function Snackbar({ notification, clear }) {
  function handleSnackbarClose(event, reason) {
    if (reason === 'clickaway') {
      return;
    }

    clear();
  }

  return (
    <MuiSnackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={notification.message !== ''}
      autoHideDuration={6000}
      onClose={handleSnackbarClose}
    >
      <Alert
        variant="filled"
        severity={notification.type}
        onClose={handleSnackbarClose}
      >
        {notification.message}
      </Alert>
    </MuiSnackbar>
  );
}

export function SnackbarProvider({ children }) {
  const [notification, setNotification] = useState({
    type: 'info',
    message: '',
  });

  function notify(type, message) {
    if (!_.isEqual({ type, message }, notification)) {
      setNotification({ type, message });
    }
  }

  function clear() {
    setNotification({ type: notification.type, message: '' });
  }

  return (
    <SnackbarContext.Provider
      value={{
        notification,
        notify,
        clear,
      }}
    >
      {children}
      <Snackbar notification={notification} clear={clear} />
    </SnackbarContext.Provider>
  );
}
