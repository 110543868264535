import { Fragment, cloneElement } from 'react';
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import {
  Error as ErrorIcon,
  Input as InputIcon,
  Person as PersonIcon,
  VpnKey as VpnKeyIcon,
  VolumeUp as VolumeUpIcon,
  Navigation as NavigationIcon,
  Warning as WarningIcon,
  WbIncandescent as WbIncandescentIcon,
} from '@mui/icons-material';
import { Helmet } from 'react-helmet-async';
import { amber } from '@mui/material/colors';
import {
  Engine as EngineIcon,
  AlarmLight as AlarmLightIcon,
  CarSide as CarSideIcon,
  RadioHandheld as RadioHandheldIcon,
} from 'mdi-material-ui';
import moment from 'moment';
import _ from 'lodash';
import { RetrospectiveTypeIcon } from '../../../data/constants';

const { useReducedResourceInformation } = window.config;

export default function VehiclePoll({
  color,
  background,
  vehicle,
  imei,
  time,
  headingDegrees,
  speedKilometresPerHour,
  malfunctionIndicatorLightOn,
  accelerometerAlert,
  ignitionOn,
  driver,
  sirensOn,
  beaconsOn,
  rearBlueLightsOn,
  rearRedLightsOn,
  strikeButtonOn,
  frontPWEOn,
  rearPWEOn,
  airwaveOn,
  ancillaryEquipmentOn,
}) {
  const theme = useTheme();

  return (
    <Card sx={{ m: [1, 0.5, 1, 1] }}>
      <Helmet>
        <title>
          IR3 | Poll |{' '}
          {(useReducedResourceInformation
            ? (vehicle || {}).fleetNumber
            : (vehicle || {}).registrationNumber) || imei}
        </title>
      </Helmet>
      <CardHeader
        avatar={
          <Avatar
            style={{
              background,
              color,
            }}
            title="Vehicle Poll"
          >
            <RetrospectiveTypeIcon type="vehiclePolls" />
          </Avatar>
        }
        title={
          (useReducedResourceInformation
            ? (vehicle || {}).fleetNumber
            : (vehicle || {}).registrationNumber) || imei
        }
        subheader={
          useReducedResourceInformation
            ? (vehicle || {}).identificationNumber
            : (vehicle || {}).fleetNumber
        }
      />
      <CardContent>
        {vehicle && (
          <Fragment>
            <Typography variant="subtitle2" color="textSecondary">
              Vehicle
            </Typography>
            <Table size="small" sx={{ mt: 1, mb: 2 }}>
              <TableBody>
                {!useReducedResourceInformation && (
                  <TableRow>
                    <TableCell>
                      <Typography variant="caption" color="textSecondary">
                        Role
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="caption">{vehicle.role}</Typography>
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell>
                    <Typography variant="caption" color="textSecondary">
                      {useReducedResourceInformation
                        ? 'Home Location'
                        : 'Home Station'}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="caption">
                      {vehicle.homeStation}
                    </Typography>
                  </TableCell>
                </TableRow>
                {(vehicle.areas || []).map((area, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Typography variant="caption" color="textSecondary">
                        {_.startCase(area.type)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="caption">{area.name}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Fragment>
        )}
        {driver && (
          <Fragment>
            <Typography variant="subtitle2" color="textSecondary">
              Driver
            </Typography>
            <ListItem component="div">
              <ListItemAvatar>
                <Avatar>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  useReducedResourceInformation
                    ? driver.code
                    : `${driver.forenames || ''}  ${driver.surname || ''}`
                }
                secondary={driver.collarNumber}
              />
            </ListItem>
          </Fragment>
        )}
        <Typography variant="subtitle2" color="textSecondary">
          Status
        </Typography>
        <Grid
          container
          spacing={2}
          justifyContent="space-around"
          alignItems="center"
          sx={{ mt: 1, mb: 2 }}
        >
          <Grid item xs={4} sx={{ textAlign: 'center' }}>
            <Typography variant="caption">
              {moment(time).format('DD/MM/YYYY, HH:mm:ss')}
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ textAlign: 'center' }}>
            <Typography variant="caption">
              {`${Math.round(speedKilometresPerHour * 0.62137119)} MPH`}
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            sx={{ textAlign: 'center' }}
            title={`Heading ${headingDegrees}°`}
          >
            <NavigationIcon
              style={{
                transform: `rotate(${headingDegrees}deg)`,
                transformOrigin: ['50%', '50%'],
              }}
              color="action"
            />
          </Grid>
          {ignitionOn !== undefined && (
            <Grid item xs={2} sx={{ textAlign: 'center' }} title="Ignition">
              <VpnKeyIcon
                htmlColor={
                  ignitionOn ? amber[700] : theme.palette.action.disabled
                }
              />
            </Grid>
          )}
          {malfunctionIndicatorLightOn !== undefined && (
            <Grid item xs={2} title="Malfunction Indicator Light">
              <EngineIcon
                htmlColor={
                  malfunctionIndicatorLightOn
                    ? amber[700]
                    : theme.palette.action.disabled
                }
              />
            </Grid>
          )}
          {accelerometerAlert !== undefined && (
            <Grid item xs={2} title="Accelerometer">
              <WarningIcon
                htmlColor={
                  accelerometerAlert
                    ? amber[700]
                    : theme.palette.action.disabled
                }
              />
            </Grid>
          )}
          {sirensOn !== undefined && (
            <Grid item xs={2} title="Sirens">
              <VolumeUpIcon
                htmlColor={
                  sirensOn ? amber[700] : theme.palette.action.disabled
                }
              />
            </Grid>
          )}
          {beaconsOn !== undefined && (
            <Grid item xs={2} title="Beacon">
              <AlarmLightIcon
                htmlColor={
                  beaconsOn ? amber[700] : theme.palette.action.disabled
                }
              />
            </Grid>
          )}
          {rearBlueLightsOn !== undefined && (
            <Grid item xs={2} title="Rear Blues">
              <WbIncandescentIcon
                color={rearBlueLightsOn ? 'primary' : 'disabled'}
              />
            </Grid>
          )}
          {rearRedLightsOn !== undefined && (
            <Grid item xs={2} title="Rear Reds">
              <WbIncandescentIcon
                color={rearRedLightsOn ? 'error' : 'disabled'}
              />
            </Grid>
          )}
          {strikeButtonOn !== undefined && (
            <Grid item xs={2} title="Strike Button">
              <ErrorIcon
                htmlColor={
                  strikeButtonOn ? amber[700] : theme.palette.action.disabled
                }
              />
            </Grid>
          )}
          {(frontPWEOn !== undefined || rearPWEOn !== undefined) && (
            <Grid
              item
              xs={2}
              title={`PWE: front ${frontPWEOn ? 'on' : 'off'}, rear ${
                rearPWEOn ? 'on' : 'off'
              }`}
            >
              <CarSideIcon
                component={(svgProps) => {
                  return (
                    <svg {...svgProps}>
                      <defs>
                        <linearGradient id="gradient1">
                          <stop
                            offset="50%"
                            stopColor={
                              rearPWEOn
                                ? theme.palette.primary.dark
                                : theme.palette.action.disabled
                            }
                          />
                          <stop
                            offset="50%"
                            stopColor={
                              frontPWEOn
                                ? theme.palette.primary.dark
                                : theme.palette.action.disabled
                            }
                          />
                        </linearGradient>
                      </defs>
                      {cloneElement(svgProps.children[0], {
                        fill: 'url(#gradient1)',
                      })}
                    </svg>
                  );
                }}
              />
            </Grid>
          )}
          {airwaveOn !== undefined && (
            <Grid item xs={2} title="Airwave Radio">
              <RadioHandheldIcon
                htmlColor={
                  airwaveOn ? amber[700] : theme.palette.action.disabled
                }
              />
            </Grid>
          )}
          {ancillaryEquipmentOn !== undefined && (
            <Grid item xs={2} title="Ancillary Equipment">
              <InputIcon
                htmlColor={
                  ancillaryEquipmentOn
                    ? amber[700]
                    : theme.palette.action.disabled
                }
              />
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}
