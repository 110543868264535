import { Box, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import Container from './Container';
import ir3Logo from '../svg/ir3Logo.svg';
import lbaLogo from '../svg/lbaLogo.svg';

const { announcement } = window.config;

export default function Home() {
  return (
    <Container title="Home">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: 'calc(100vh - 96px)',
          flex: 1,
        }}
      >
        <Helmet>
          <title>IR3 | Home</title>
        </Helmet>
        <Box sx={{ mt: 8, mb: 2 }}>
          <Box
            sx={{
              maxWidth: 600,
              margin: [0, 'auto'],
              p: [8, 0, 6],
            }}
          >
            <Typography align="center">
              <img src={ir3Logo} alt="IR3 Logo" style={{ height: 200 }} />
            </Typography>
            <Typography
              component="h1"
              sx={{
                fontWeight: 'bold',
              }}
              variant="h1"
              align="center"
              color="primary"
              gutterBottom
            >
              IR3
            </Typography>
            <Typography
              variant="h6"
              align="center"
              color="textSecondary"
              component="p"
            >
              IR3 records a complete picture of resource behaviour in response
              to demand. It uses spatial analysis algorithms to classify
              resource activity in real time and retrospectively. IR3 can inform
              strategic and tactical decision making to optimise resource
              allocation and productivity against prioritised demand.
            </Typography>
          </Box>
          {announcement && (
            <Box sx={{ p: [2, 6] }}>
              <Typography variant="subtitle1" sx={{ color: 'error.main' }}>
                Announcement
              </Typography>
              <Typography variant="body1">{announcement}</Typography>
            </Box>
          )}
        </Box>
        <Box
          sx={(theme) => ({
            p: 2,
            mt: 'auto',
            borderTop: `1px solid ${theme.palette.divider}`,
          })}
        >
          <Typography
            variant="subtitle1"
            align="center"
            color="textSecondary"
            component="p"
          >
            {`© ${new Date().getFullYear()} `}
            <img
              src={lbaLogo}
              alt="L&A Consultants"
              style={{ height: 30, verticalAlign: 'middle' }}
            />
          </Typography>
        </Box>
      </Box>
    </Container>
  );
}
