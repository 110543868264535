import { IconButton } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import { useSideNav } from '../SideNav';

export default function MenuButton(props) {
  const sideNav = useSideNav();

  return (
    <IconButton
      id="menu"
      aria-label="Menu"
      onClick={sideNav.open}
      {...props}
      size="large"
    >
      <MenuIcon />
    </IconButton>
  );
}
