import { useState } from 'react';
import {
  Autocomplete,
  Box,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  TextField,
} from '@mui/material';
import {
  AddCircle as AddIcon,
  RemoveCircle as RemoveIcon,
} from '@mui/icons-material';

export default function GroupsPicker({
  value,
  onChange,
  groups,
  disabled,
  readOnly,
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleCodesChange = (type) => (event, selections) => {
    onChange({
      ...value,
      [type]: selections,
    });
  };

  const handleDeleteClick = (type) => () => {
    const { [type]: typeValue, ...newValue } = value;
    onChange({
      ...newValue,
    });
  };

  const handleAddMenuClicked = (type) => () => {
    onChange({
      ...value,
      [type]: [],
    });
    setAnchorEl(null);
  };

  function handleAddClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleAddMenuClose() {
    setAnchorEl(null);
  }

  const getStyle = (type, option) =>
    type in groups && groups[type].values.map((v) => v.value).includes(option)
      ? undefined
      : { bgcolor: 'error.light' };

  const getOptionLabel = (type) => (option) =>
    (groups[type]?.values ?? []).find((val) => val.value === option)?.label ||
    option;

  const renderTags = (type) => (value, getTagProps) =>
    value.map((option, index) => (
      <Chip
        size="small"
        sx={getStyle(type, option)}
        label={getOptionLabel(type)(option)}
        {...getTagProps({ index })}
      />
    ));

  return (
    <Box sx={{ width: 1 }}>
      {Object.entries(value).map((entry) => (
        <Box sx={{ display: 'flex', pb: 1.5 }} key={entry[0]}>
          <Autocomplete
            fullWidth
            size="small"
            multiple
            options={(groups[entry[0]]?.values ?? [])
              .sort((a, b) => a.label.localeCompare(b.label))
              .map((option) => option.value)}
            getOptionLabel={getOptionLabel(entry[0])}
            renderTags={renderTags(entry[0])}
            value={value[entry[0]]}
            onChange={handleCodesChange(entry[0])}
            disabled={disabled}
            renderInput={(params) => (
              <TextField
                {...params}
                label={groups[entry[0]]?.label ?? entry[0]}
                readOnly={readOnly}
                disabled={disabled}
              />
            )}
          />
          {!readOnly && !disabled && (
            <IconButton
              sx={{ ml: 1.5 }}
              aria-label="Remove"
              title="Remove"
              onClick={handleDeleteClick(entry[0])}
              size="small"
            >
              <RemoveIcon />
            </IconButton>
          )}
        </Box>
      ))}
      {(Object.entries(groups) || []).filter(
        (entry) => !(entry[0] in (value || {}))
      ).length > 0 && (
        <Box sx={{ display: 'flex', pb: 1.5 }}>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton
            aria-label="Add"
            title="Add"
            onClick={handleAddClick}
            size="small"
            disabled={disabled}
          >
            <AddIcon />
          </IconButton>
          <Menu
            id="add-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleAddMenuClose}
          >
            {(Object.entries(groups) || [])
              .filter((entry) => !(entry[0] in (value || {})))
              .sort((a, b) => a[1].label.localeCompare(b[1].label))
              .map((entry) => (
                <MenuItem
                  key={entry[0]}
                  onClick={handleAddMenuClicked(entry[0])}
                  value={entry[0]}
                >
                  {entry[1].label}
                </MenuItem>
              ))}
          </Menu>
        </Box>
      )}
    </Box>
  );
}
