import { Avatar } from '@mui/material';
import _ from 'lodash';

import {
  liveIconsByTypeAndSubtype,
  mapPolygonIconsByTypeAndSubtype,
} from '../../../data/constants';
import { pluralToSingularTypeMap } from '../constants';
import { getStatusIconColours } from '../../../mapStyles';

function getAvatarContent(item, type) {
  switch (type) {
    case 'incident':
      // explicitly requested that it's not EME PRI etc.
      // "In case it wasn't clear the circles with numbers in them are incidents. The number is the grade."
      return item.grade || '?';
    case 'location':
    case 'vehicle':
    case 'objective':
    case 'tag':
      return (
        liveIconsByTypeAndSubtype[type][item.type] ||
        liveIconsByTypeAndSubtype[type]['default']
      );
    default:
      return liveIconsByTypeAndSubtype[type]['default'];
  }
}

export function clipPathForType(type, subtype) {
  type = pluralToSingularTypeMap[type] || type;
  const sides =
    mapPolygonIconsByTypeAndSubtype[type]?.[subtype] ||
    mapPolygonIconsByTypeAndSubtype[type]?.default ||
    mapPolygonIconsByTypeAndSubtype[type];

  if (sides > 2) {
    const angle = (2 * Math.PI) / sides;
    const startAngle = sides % 2 === 0 ? angle / 2 : Math.PI * 1.5 - angle;

    let points = [];

    let shapeScale = sides < 5 ? 1.2 : 1;
    const translate = sides === 3 ? 0.15 : 0;

    function transform(trig) {
      return `${100 * ((1 + shapeScale * trig) / 2)}%`;
    }

    for (let i = 0; i < sides; i++) {
      const a = startAngle + angle * i;
      points.push(
        `${transform(Math.cos(a))} ${transform(Math.sin(a) + translate)}`
      );
    }

    return `polygon(${points.join()})`;
  }
}

export default function avatarForItem(item, type, subtype) {
  let status = item.iconStatus || item.status;

  // for consistency elsewhere outside live - I might change this -
  // the type is singular not plural
  type = pluralToSingularTypeMap[type] || type;
  // outline isnt used
  const [, background, color] = getStatusIconColours(type, status);
  const clipPath = clipPathForType(type, subtype);

  return (
    <Avatar
      variant={clipPath ? 'square' : 'circular'}
      title={_.startCase(status)}
      style={{
        background,
        color,
        clipPath,
      }}
    >
      {getAvatarContent(item, type)}
    </Avatar>
  );
}
