import { DatePicker } from '../controls';

export default function DateField({
  input: { value, ...input },
  meta,
  ...props
}) {
  return (
    <DatePicker
      value={value || null}
      helperText={meta.touched && meta.error}
      error={meta.touched && meta.invalid}
      {...input}
      {...props}
    />
  );
}
