import {
  FETCH_RADIOS,
  FETCH_RADIOS_SUCCESS,
  FETCH_RADIOS_FAILURE,
  FETCH_RADIO,
  FETCH_RADIO_SUCCESS,
  FETCH_RADIO_FAILURE,
  CREATE_RADIO,
  CREATE_RADIO_SUCCESS,
  CREATE_RADIO_FAILURE,
  UPDATE_RADIO,
  UPDATE_RADIO_SUCCESS,
  UPDATE_RADIO_FAILURE,
  DELETE_RADIO,
  DELETE_RADIO_SUCCESS,
  DELETE_RADIO_FAILURE,
  REMOVE_RADIO,
  REMOVE_RADIO_SUCCESS,
  REMOVE_RADIO_FAILURE,
} from '../actions';

const INITIAL_STATE = {
  radios: [],
  radio: null,
  isLoading: false,
  error: null,
};

export default function radiosReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_RADIOS:
      return { ...state, isLoading: true, error: null };
    case FETCH_RADIOS_SUCCESS:
      return {
        ...state,
        radios: action.payload,
        isLoading: false,
        error: null,
      };
    case FETCH_RADIOS_FAILURE:
      return { ...state, radios: [], isLoading: false, error: action.payload };
    case FETCH_RADIO:
      return { ...state, isLoading: true, error: null };
    case FETCH_RADIO_SUCCESS:
      return {
        ...state,
        radio: action.payload,
        isLoading: false,
        error: null,
      };
    case FETCH_RADIO_FAILURE:
      return {
        ...state,
        radio: null,
        isLoading: false,
        error: action.payload,
      };
    case CREATE_RADIO:
      return { ...state, isLoading: true, error: null };
    case CREATE_RADIO_SUCCESS:
      return {
        ...state,
        radio: { people: [], ...action.payload },
        radios: state.radios.concat({
          assignmentCount: 0,
          ...action.payload,
        }),
        isLoading: false,
        error: null,
      };
    case CREATE_RADIO_FAILURE:
      return {
        ...state,
        radio: null,
        isLoading: false,
        error: action.payload,
      };
    case DELETE_RADIO:
      return { ...state, isLoading: true, error: null };
    case DELETE_RADIO_SUCCESS:
      return {
        ...state,
        radio: null,
        radios: state.radios.filter((radio) => radio.ssi !== action.ssi),
        isLoading: false,
        error: null,
      };
    case DELETE_RADIO_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case UPDATE_RADIO:
      return { ...state, isLoading: true, error: null };
    case UPDATE_RADIO_SUCCESS:
      const { people, ...payload } = action.payload;

      return {
        ...state,
        radio: action.payload,
        radios: state.radios.map((radio) =>
          radio.ssi === payload.ssi
            ? {
                assignmentCount: people.length,
                ...payload,
              }
            : radio
        ),
        isLoading: false,
        error: null,
      };
    case UPDATE_RADIO_FAILURE:
      return {
        ...state,
        radio: null,
        isLoading: false,
        error: action.payload,
      };
    case REMOVE_RADIO:
      return { ...state, isLoading: true, error: null };
    case REMOVE_RADIO_SUCCESS:
      return {
        ...state,
        radio: {
          ...state.radio,
          people: state.radio.people.filter(
            (person) => person.code !== action.payload.code
          ),
        },
        radios: state.radios.map((radio) => ({
          ...radio,
          assignmentCount:
            radio.ssi === action.payload.id
              ? radio.assignmentCount - 1
              : radio.assignmentCount,
        })),
        isLoading: false,
        error: null,
      };
    case REMOVE_RADIO_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
