import { Fragment } from 'react';
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { Error as ErrorIcon } from '@mui/icons-material';
import { Helmet } from 'react-helmet-async';
import moment from 'moment';
import _ from 'lodash';
import { RetrospectiveTypeIcon } from '../../../data/constants';

const { useReducedResourceInformation } = window.config;

export default function PersonPoll({
  color,
  background,
  ssi,
  time,
  emergencyButtonOn,
  person = {},
}) {
  let name;
  if (useReducedResourceInformation) {
    name = person.code;
  } else if (!!person.forenames || !!person.surname) {
    name = `${person.forenames || ''}  ${person.surname || ''}`;
  }
  name = name ?? ssi;

  return (
    <Card sx={{ m: [1, 0.5, 1, 1] }}>
      <Helmet>
        <title>IR3 | Poll | {name}</title>
      </Helmet>
      <CardHeader
        avatar={
          <Avatar
            style={{
              background,
              color,
            }}
            title="Person Poll"
          >
            <RetrospectiveTypeIcon type="personPolls" />
          </Avatar>
        }
        title={name}
        subheader={person.collarNumber}
      />
      <CardContent>
        {person && (
          <Fragment>
            <Typography variant="subtitle2" color="textSecondary">
              Person
            </Typography>
            <Table size="small" sx={{ mt: 1, mb: 2 }}>
              <TableBody>
                {!useReducedResourceInformation && person.role && (
                  <TableRow>
                    <TableCell>
                      <Typography variant="caption" color="textSecondary">
                        Role
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="caption">{person.role}</Typography>
                    </TableCell>
                  </TableRow>
                )}
                {person.homeStation && (
                  <TableRow>
                    <TableCell>
                      <Typography variant="caption" color="textSecondary">
                        {useReducedResourceInformation
                          ? 'Home Location'
                          : 'Home Station'}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="caption">
                        {person.homeStation}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                {(person.areas || []).map((area, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Typography variant="caption" color="textSecondary">
                        {_.startCase(area.type)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="caption">{area.name}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell>
                    <Typography variant="caption" color="textSecondary">
                      Radio SSI
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="caption">{person.radioSsi}</Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Fragment>
        )}
        <Typography variant="subtitle2" color="textSecondary">
          Status
        </Typography>
        <Grid
          container
          spacing={2}
          justifyContent="space-around"
          alignItems="center"
          sx={{ mt: 1 }}
        >
          <Grid item xs={4} sx={{ textAlign: 'center' }}>
            <Typography variant="caption">
              {moment(time).format('DD/MM/YYYY, HH:mm:ss')}
            </Typography>
          </Grid>
          {emergencyButtonOn !== undefined && (
            <Grid item xs={4}>
              <ErrorIcon
                color={emergencyButtonOn ? 'error' : 'disabled'}
                title="Emergency"
              />
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}
