import { combineEpics } from 'redux-observable';
import * as briefEpics from './briefEpics';
import * as planEpics from './planEpics';
import * as tagEpics from './tagEpics';
import * as queryEpics from './queryEpics';
import * as featureEpics from './featureEpics';
import * as filterEpics from './filterEpics';
import * as locationEpics from './locationEpics';
import * as objectiveEpics from './objectiveEpics';
import * as personEpics from './personEpics';
import * as replayEpics from './replayEpics';
import * as reportEpics from './reportEpics';
import * as retrospectiveEpics from './retrospectiveEpics';
import * as vehicleEpics from './vehicleEpics';
import * as liveEpics from './liveEpics';
import * as telematicsBoxEpics from './telematicsBoxEpics';
import * as utilisationEpics from './utilisationEpics';
import * as activityEpics from './activityEpics';
import * as eventEpics from './eventEpics';
import * as gazetteerEpics from './gazetteerEpics';
import * as radioEpics from './radioEpics';
import * as rfidCardEpics from './rfidCardEpics';

const rootEpic = combineEpics(
  ...Object.values(briefEpics),
  ...Object.values(planEpics),
  ...Object.values(tagEpics),
  ...Object.values(queryEpics),
  ...Object.values(featureEpics),
  ...Object.values(filterEpics),
  ...Object.values(locationEpics),
  ...Object.values(objectiveEpics),
  ...Object.values(personEpics),
  ...Object.values(replayEpics),
  ...Object.values(reportEpics),
  ...Object.values(retrospectiveEpics),
  ...Object.values(vehicleEpics),
  ...Object.values(liveEpics),
  ...Object.values(telematicsBoxEpics),
  ...Object.values(utilisationEpics),
  ...Object.values(activityEpics),
  ...Object.values(eventEpics),
  ...Object.values(gazetteerEpics),
  ...Object.values(radioEpics),
  ...Object.values(rfidCardEpics)
);

export default rootEpic;
