import {
  Avatar,
  Button,
  Card,
  CardHeader,
  CardActions,
  CardContent,
  CircularProgress,
  Collapse,
  Divider,
  IconButton,
  Typography,
  useTheme,
  FormControl,
  FormHelperText,
  Box,
  Toolbar,
} from '@mui/material';
import {
  Add as AddIcon,
  Close as CloseIcon,
  Create as CreateIcon,
  Delete as DeleteIcon,
  FileCopy as CopyIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
  Autorenew as AutorenewIcon,
  ExpandMore as ExpandMoreIcon,
  Block as FetchDisallowedIcon,
  AttachFileOutlined as AttachFileOutlinedIcon,
  DashboardCustomizeTwoTone as DashboardCustomizeTwoToneIcon,
} from '@mui/icons-material';
import {
  Grid as HighPrecisionIcon,
  GridLarge as LowPrecisionIcon,
  SortVariantLock as VirtualiseMapOnIcon,
  SortVariantLockOpen as VirtualiseMapOffIcon,
  // MapLegend as VirtualiseMapIcon,
} from 'mdi-material-ui';
import _ from 'lodash';
import { Fragment, useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import SourceFilters from './SourceFilters';
import TypeParameters from './TypeParameters';
import { ConfirmationDialog } from '../dialogs';
import {
  Field,
  SelectField,
  DebouncedTextField,
  IconButtonField,
  SliderField,
  AutosuggestField,
} from '../fields';
import {
  types,
  sources,
  compareLabels,
  tooManyMapItems,
  exceedsLimits,
  totalExceedsLimits,
  defaultLayerValues,
} from './constants';
import { indexArray, rearrange } from './utilities';
import { useFilePicker } from 'use-file-picker';

const { isFleet } = window.config;

const boundaryTypes = isFleet
  ? [
      { label: 'None', value: 'None' },
      { label: 'Custom', value: 'Custom' },
      { label: 'Location', value: 'Location' },
    ]
  : [
      { label: 'None', value: 'None' },
      { label: 'Custom', value: 'Custom' },
      { label: 'Location', value: 'Location' },
      { label: 'Objective', value: 'Objective' },
      { label: 'Perimeter', value: 'Perimeter' },
    ];

const atLeastOneBoundaryTypes = boundaryTypes.filter((b) => b.value !== 'None');

export default function LayerList({
  fields,
  onSelect,
  hoveredItemIndex,
  onVisibilityToggle,
  onRefresh,
  loadingLayers,
  estimatingLayers,
  boundaries,
  onBoundaryChange,
  clearValue,
  setValue,
  expandedLayerIndex,
  onExpanded,
  onDraw,
  onVirtualizationChange,
  filters,
  errors,
  onFileOpen,
  layerOrder,
}) {
  const [deleteLayer, setDeleteLayer] = useState(null);
  const theme = useTheme();

  const selectedFileExtension = fields.value
    .filter((item) => item.type === 'file')
    .map((item) => item.source)[0];

  const acceptedFileExtensions =
    selectedFileExtension === 'kml' ? '.kml' : ['.json', '.geojson'];

  const [openFileSelector, fileOpenResult] = useFilePicker({
    multiple: false,
    readAs: 'Text',
    maxFileSize: 5, //in megabytes
    accept: [acceptedFileExtensions],
    readFilesContent: true,
  });

  // Only invoke onFileOpen when filesContent or filesError change
  useEffect(() => {
    if (
      !_.isEmpty(fileOpenResult.filesContent) ||
      !_.isEmpty(fileOpenResult.errors)
    ) {
      onFileOpen({
        name: fileOpenResult.plainFiles?.[0]?.name,
        content: fileOpenResult.filesContent?.[0]?.content,
        errors: fileOpenResult.errors,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileOpenResult.filesContent, fileOpenResult.errors]);

  function handleAddLayer() {
    fields.push({
      label: '',
      type: 'shape',
      source: '',
      unitOfTime: 'days',
      isRelativeTimePeriod: false,
      distance: 7,
      blur: 10,
      radius: 10,
      precision: 3,
      startTime: new Date(),
      endTime: new Date(),
      colors: [theme.palette.grey[500]],
      parameters: {},
      ...defaultLayerValues,
    });

    if (layerOrder) {
      // add it to the end
      setValue('layerOrder', [...layerOrder, layerOrder.length]);
    }
  }

  function handleDeleteLayer() {
    fields.remove(deleteLayer.index);

    if (layerOrder) {
      const oldOrder = [...layerOrder];

      // get rid of where it was
      const oldIndex = oldOrder.indexOf(deleteLayer.index);
      oldOrder.splice(oldIndex, 1);

      // the layer indexes after the deleted one will decrease by one
      setValue(
        'layerOrder',
        oldOrder.map((o) => (o > deleteLayer.index ? o - 1 : o))
      );
    }

    setDeleteLayer(null);
  }

  function handleDeleteCancel() {
    setDeleteLayer(null);
  }

  function handleExpandClick(event) {
    const index = parseInt(event.currentTarget.value);

    if (expandedLayerIndex === index) {
      onExpanded(null);
    } else {
      onExpanded(index);
    }
  }

  function handleVirtualizationToggle(layer, index) {
    onVirtualizationChange({
      index,
      virtualize: layer.virtualize === undefined ? false : !layer.virtualize,
    });
  }

  function handleCopyClick(event) {
    const index = parseInt(event.currentTarget.value);
    const layer = fields.value[index];

    fields.insertAt(
      index + 1,
      _.cloneDeep(_.omit(layer, ['featureCollection', 'window', 'virtualize']))
    );

    if (layerOrder) {
      // all the layers after the copied one will have an index increase
      let newOrder = [...layerOrder].map((o) => (o > index ? o + 1 : o));
      const at = newOrder.indexOf(index);
      newOrder.splice(at + 1, 0, index + 1);
      setValue('layerOrder', newOrder);
    }
  }

  function handleDeleteClick(event) {
    const index = parseInt(event.currentTarget.value);
    setDeleteLayer({ label: fields.value[index].label, index });
  }

  function handleItemsClick(event) {
    const index = parseInt(event.currentTarget.value);
    onSelect({ layerIndex: index });
  }

  function handleRefreshClick(event) {
    const index = parseInt(event.currentTarget.value);
    onRefresh(index, fields.value[index]);
  }

  function handleDrawClick(event) {
    const index = parseInt(event.currentTarget.value);
    onDraw(index);
  }

  const handleTypeChange = (name) => () => {
    clearValue(`${name}.source`);
    clearValue(`${name}.parameters`);
    // clearValue(`${name}.filters`);
    clearValue(`${name}.clientFilters`);
    clearValue(`${name}.searchText`);
    clearValue(`${name}.sort`);
    clearValue(`${name}.featureCollection`);
    clearValue(`${name}.file`);
    clearValue(`${name}.primaryItemKey`);
    clearValue(`${name}.secondaryItemKey`);
    clearValue(`${name}.filters.event`);
    clearValue(`${name}.virtualize`);
  };

  const handleSourceChange = (name) => (event) => {
    if (['vehicleVisits', 'personVisits'].includes(event?.target?.value)) {
      setValue(`${name}.hideBoundary`, true);
    } else {
      setValue(`${name}.hideBoundary`, false);
    }

    clearValue(`${name}.parameters`);
    // clearValue(`${name}.filters`);
    clearValue(`${name}.clientFilters`);
    clearValue(`${name}.searchText`);
    clearValue(`${name}.sort`);
    clearValue(`${name}.featureCollection`);
    clearValue(`${name}.file`);
    clearValue(`${name}.primaryItemKey`);
    clearValue(`${name}.secondaryItemKey`);
    clearValue(`${name}.filters.event`);
    clearValue(`${name}.virtualize`);
  };

  const handleBoundaryTypeChange = (name) => () => {
    clearValue(`${name}.boundarySubtype`);
    clearValue(`${name}.boundaryIdentifier`);
    clearValue(`${name}.boundaryGeometry`);
  };

  const handleBoundarySubtypeChange = (name) => () => {
    clearValue(`${name}.boundaryIdentifier`);
    clearValue(`${name}.boundaryGeometry`);
  };

  function handleDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    if (expandedLayerIndex === result.source.index) {
      onExpanded(result.destination.index);
    } else {
      if (
        expandedLayerIndex &&
        expandedLayerIndex > result.source.index &&
        expandedLayerIndex <= result.destination.index
      ) {
        onExpanded(expandedLayerIndex - 1);
      }

      if (
        expandedLayerIndex &&
        expandedLayerIndex >= result.destination.index &&
        expandedLayerIndex < result.source.index
      ) {
        onExpanded(expandedLayerIndex + 1);
      }
    }

    const newOrder = rearrange(
      layerOrder ?? indexArray(fields.length),
      result.source.index,
      result.destination.index
    );

    setValue('layerOrder', newOrder);
    // fields.move(result.source.index, result.destination.index);
  }

  const handleBoundaryChange = (index) => (boundaryIdentifier) => {
    const layer = fields.value[index];

    onBoundaryChange(index, {
      ...layer,
      boundaryIdentifier,
    });
  };

  // https://stackoverflow.com/a/60980688/1350146
  function abbreviateNumber(num) {
    return new Intl.NumberFormat('en-GB', {
      maximumFractionDigits: 1,
      maximumSignificantDigits: 2,
      notation: 'compact',
      compactDisplay: 'short',
    }).format(num);
  }

  function estimateLabel(layer, limitsExceeded) {
    let label = '';

    if (errors.layers?.[layer.index]) {
      label = `Query errors detected`;
    } else if (layer.type === 'file') {
      label = undefined;
    } else if (layer.abortedDueToLimitExceeded) {
      label = `Fetch aborted due to ${layer.abortedDueToLimitExceeded} limit exceeded`;
    } else if (limitsExceeded) {
      // just get the first problem
      const key = Object.keys(limitsExceeded)[0];
      const unit = `${key === 'data' ? 'B' : ''}`;
      label =
        `The ${_.lowerCase(key)} estimate of` +
        ` ${abbreviateNumber(limitsExceeded[key].estimate)}${unit}` +
        ` exceeds limit of` +
        ` ${abbreviateNumber(limitsExceeded[key].limit)}${unit}`;
    } else if (layer.estimate?.irregular) {
      const items = layer.estimate.items;
      label = `Estimated > ${abbreviateNumber(
        items
      )} items, total unclear as data is irregular`;
    } else {
      label =
        `Estimated ${abbreviateNumber(layer.estimate?.items)} items` +
        `, ${abbreviateNumber(layer.estimate?.data)}B data` +
        ` & ${abbreviateNumber(
          layer.estimate?.mapFeaturesSize
        )} map features size`;
    }

    return (
      <Box sx={{ height: 16, pl: 1.5 }}>
        <Typography
          variant="caption"
          sx={{
            color:
              Boolean(errors.layers?.[layer.index]) ||
              layer.abortedDueToLimitExceeded ||
              limitsExceeded
                ? 'error.main'
                : 'text.secondary',
          }}
        >
          {layer.type !== 'file' && estimatingLayers.includes(layer.index)
            ? 'Estimating...'
            : layer.estimate && label}
        </Typography>
      </Box>
    );
  }

  const totalLimitKey = 'mapFeaturesSize'; // we only care about this for the total
  function totalEstimateLabel(estimate, limitsExceeded) {
    let color = 'text';
    let label = '';
    let message = '';

    if (limitsExceeded?.[totalLimitKey]) {
      color = 'common.white';

      const unit = `${totalLimitKey === 'data' ? 'B' : ''}`;
      label =
        `Total ${_.lowerCase(totalLimitKey)} estimate of` +
        ` ${abbreviateNumber(limitsExceeded[totalLimitKey].estimate)}${unit}` +
        ` exceeds limit of` +
        ` ${abbreviateNumber(limitsExceeded[totalLimitKey].limit)}${unit}`;
      message = 'Fetching results is disabled until total estimate reduced';
    } else {
      return <Fragment />;
    }

    return (
      <Box
        sx={{
          p: 1,
          mx: 1,
          color: 'common.white',
          background: 'error.main',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography variant="caption" sx={{ color }}>
          {label}
        </Typography>
        <Typography variant="caption" sx={{ color }}>
          {message}
        </Typography>
      </Box>
    );
  }

  function numberOfItems(layer) {
    let total = layer.featureCollection?.features?.length;

    // if "count" is a property, it's aggregated - add each count together
    if (layer.type === 'area') {
      return `${total} areas`;
    } else if (
      total > 0 &&
      layer.featureCollection.features[0].properties.count
    ) {
      const clusters = total;
      total = layer.featureCollection.features.reduce(
        (total, feature) => total + feature.properties.count,
        0
      );
      return `${total} items (${clusters} clusters)`;
    } else {
      return `${total} items`;
    }
  }

  const layers = fields.value;
  const { limitsExceeded: totalLimitsExceeded, totalEstimate } =
    totalExceedsLimits(layers);
  const disableAllFetches = !!totalLimitsExceeded;

  const renderLayers = () => {
    // for reordering the components
    const ordering = layerOrder ?? indexArray(fields.length);

    const components = fields.map((name, index) => {
      const layer = fields.value[index];
      const layerHasErrors =
        !!errors.layers?.[index] ||
        (layer.boundaryType === 'Custom' && !layer.boundaryGeometry);
      const limitsExceeded = exceedsLimits(layer);

      const background =
        layer.colors.length > 1
          ? `linear-gradient(${layer.colors.join()})`
          : layer.colors[0] || theme.palette.grey[500];

      const color = theme.palette.getContrastText(
        layer.colors[Math.floor(layer.colors.length / 2)] ||
          theme.palette.grey[500]
      );

      const customVisit = [
        'vehicleCustomVisits',
        'personCustomVisits',
      ].includes(layer.source);
      const knownVisit = ['vehicleVisits', 'personVisits'].includes(
        layer.source
      );
      const isFileSource = layer.type === 'file';
      let boundaryTypeOptions = customVisit
        ? atLeastOneBoundaryTypes
        : boundaryTypes;

      return (
        <Draggable
          key={name}
          draggableId={name}
          index={ordering.indexOf(index)}
          isDragDisabled={expandedLayerIndex === index}
        >
          {(provided, snapshot) => (
            <Card
              sx={{
                m: 1,
                mr: 0.5,
                overflow: 'visible',
                bgcolor:
                  hoveredItemIndex.layerIndex === index
                    ? 'action.hover'
                    : undefined,
              }}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <CardHeader
                avatar={
                  <Avatar
                    style={{
                      background,
                      color,
                    }}
                    title={types[layer.type].label}
                  >
                    {types[layer.type].icon}
                  </Avatar>
                }
                action={
                  <IconButton
                    onClick={handleExpandClick}
                    aria-label="Settings"
                    title="Settings"
                    value={index}
                  >
                    <ExpandMoreIcon
                      sx={(theme) => ({
                        transform:
                          expandedLayerIndex === index
                            ? 'rotate(180deg)'
                            : 'rotate(0deg)',
                        transition: theme.transitions.create('transform', {
                          duration: theme.transitions.duration.shortest,
                        }),
                      })}
                    />
                  </IconButton>
                }
                title={layer.label}
                subheader={
                  layer.source &&
                  (
                    sources[layer.type].find(
                      (source) => source.value === layer.source
                    ) || { label: 'Unknown' }
                  ).label
                }
              />
              <Collapse
                in={expandedLayerIndex === index}
                timeout="auto"
                unmountOnExit
              >
                <CardContent sx={{ pt: 1 }}>
                  <Field
                    fullWidth
                    sx={{ mb: 1 }}
                    label="Label"
                    name={`${name}.label`}
                    component={DebouncedTextField}
                  />
                  <Field
                    label="Type"
                    sx={{ mr: 1, mt: 1, width: 128 }}
                    name={`${name}.type`}
                    values={Object.values(types)
                      .map(({ label, value }) => ({
                        label,
                        value,
                      }))
                      .sort(compareLabels)}
                    component={SelectField}
                    onChange={handleTypeChange(name)}
                    ignoreTouched
                  />
                  <Field
                    label="Source"
                    sx={{ mt: 1, width: 240 }}
                    name={`${name}.source`}
                    values={sources[layer.type].sort(compareLabels)}
                    component={SelectField}
                    onChange={handleSourceChange(name)}
                    ignoreTouched
                  />
                  {
                    // disallow boundary drawing if it's a known location visit type or external file source
                    !knownVisit && !isFileSource && (
                      <Fragment>
                        <Divider sx={{ mt: 2, mb: 1 }} />
                        <Typography variant="subtitle2" color="textSecondary">
                          Boundary
                        </Typography>
                        <Field
                          label="Type"
                          sx={{ mr: 1, mt: 1, width: 128 }}
                          name={`${name}.boundaryType`}
                          values={boundaryTypeOptions}
                          component={SelectField}
                          onChange={handleBoundaryTypeChange(name)}
                          ignoreTouched
                          datacy="boundaryType" // for testing
                        />
                      </Fragment>
                    )
                  }
                  {!knownVisit &&
                    Object.keys(boundaries).includes(layer.boundaryType) && (
                      <Fragment>
                        <Field
                          label="Subtype"
                          sx={{ mr: 1, my: 1, width: 160 }}
                          name={`${name}.boundarySubtype`}
                          values={Object.keys(
                            boundaries[layer.boundaryType]
                          ).sort()}
                          component={SelectField}
                          onChange={handleBoundarySubtypeChange(name)}
                          ignoreTouched
                          datacy="boundarySubType" // for testing
                        />
                        {layer.boundarySubtype && (
                          <Field
                            fullWidth
                            label="Name"
                            sx={{ mr: 1.5, mt: 1 }}
                            name={`${name}.boundaryIdentifier`}
                            suggestions={(
                              boundaries[layer.boundaryType]?.[
                                layer.boundarySubtype
                              ] || []
                            ).sort(compareLabels)}
                            component={AutosuggestField}
                            onSelect={handleBoundaryChange(index)}
                            ignoreTouched
                            datacy="boundaryIdentifier" // for testing
                          />
                        )}
                      </Fragment>
                    )}
                  {!knownVisit && layer.boundaryType === 'Custom' && (
                    <FormControl>
                      <IconButton
                        sx={{ mt: 0.5 }}
                        title="Draw"
                        value={index}
                        onClick={handleDrawClick}
                      >
                        <CreateIcon
                          color={
                            Boolean(layer.boundaryGeometry)
                              ? 'primary'
                              : customVisit
                              ? 'error'
                              : 'inherit'
                          }
                        />
                      </IconButton>
                      <FormHelperText error={true}>
                        {customVisit && !layer.boundaryGeometry && 'Required'}
                      </FormHelperText>
                    </FormControl>
                  )}
                  <Divider sx={{ mt: 2, mb: 1 }} />
                  <TypeParameters type={layer.type} name={name} value={layer} />
                  {layer.source &&
                    sources[layer.type].find(
                      (source) => source.value === layer.source
                    ) && (
                      <Fragment>
                        {layer.type !== 'file' && (
                          <Divider sx={{ mt: 2, mb: 1 }} />
                        )}
                        <SourceFilters
                          type={layer.source}
                          name={`${name}.filters`}
                          filters={filters}
                        />
                      </Fragment>
                    )}
                  {layer?.type === 'heat' && (
                    <Fragment>
                      <Divider sx={{ mt: 2, mb: 1 }} />
                      <Typography variant="subtitle2" color="text.secondary">
                        Precision
                      </Typography>
                      <Box
                        id="precision"
                        sx={{
                          mt: 1,
                          mx: 1,
                          display: 'flex',
                          alignItems: 'center',
                          color: 'text.secondary',
                        }}
                      >
                        <LowPrecisionIcon />
                        <Field
                          sx={{ mx: 1.5 }}
                          name={`${name}.precision`}
                          component={SliderField}
                          type="number"
                          // InputProps={{ marks: true, min: 1, max: 7, step: 1 }}
                          marks
                          min={1}
                          max={7}
                          step={1}
                          // label={"Precision"}
                          fallbackValue={3} // default or initial are taken
                          fullWidth
                        />
                        <HighPrecisionIcon />
                      </Box>
                      <Box sx={{ mt: 0.5, ml: 1, color: 'text.secondary' }}>
                        <Typography variant="caption">
                          Lower precision can speed up the query by clustering
                        </Typography>
                      </Box>
                    </Fragment>
                  )}
                  {layer?.type === 'file' && (
                    <Fragment>
                      <Divider sx={{ mt: 2, mb: 1 }} />
                      <Typography
                        variant="subtitle2"
                        sx={{ color: 'text.secondary' }}
                      >
                        File upload
                      </Typography>
                      <Box sx={{ pt: 1.5 }}>
                        <Button
                          sx={{ width: 300, whiteSpace: 'nowrap' }}
                          color="primary"
                          variant="outlined"
                          onClick={() => openFileSelector()}
                          endIcon={<AttachFileOutlinedIcon />}
                        >
                          {layer.file?.name ? (
                            <Typography noWrap>{layer.file.name}</Typography>
                          ) : (
                            <Typography>No file selected</Typography>
                          )}
                        </Button>
                      </Box>
                      {layer.file?.errors.length > 0 && (
                        <Box sx={{ mt: 1, color: 'error.main' }}>
                          File too big
                        </Box>
                      )}
                      {layer.file?.content && (
                        <Fragment>
                          <Divider sx={{ mt: 2, mb: 1 }} />
                          <Typography variant="subtitle2" color="textSecondary">
                            Item
                          </Typography>
                          <Field
                            label="Primary Item Key"
                            sx={{ mr: 1, mt: 1, width: 190 }}
                            name={`${name}.primaryItemKey`}
                            initialValue={layer.primaryItemKey}
                            values={
                              Object.keys(
                                layer?.file?.content?.features?.[0].properties
                                  .fileProperties
                              ) || []
                            }
                            component={SelectField}
                            ignoreTouched
                          />
                          <Field
                            label="Secondary Item Key"
                            sx={{ mr: 1, mt: 1, width: 190 }}
                            name={`${name}.secondaryItemKey`}
                            initialValue={layer.secondaryItemKey}
                            values={
                              Object.keys(
                                layer?.file?.content?.features?.[0].properties
                                  .fileProperties
                              ) || []
                            }
                            component={SelectField}
                            ignoreTouched
                          />
                        </Fragment>
                      )}
                    </Fragment>
                  )}
                </CardContent>
              </Collapse>
              <CardActions
                disableSpacing
                sx={{
                  flexDirection: 'column',
                  width: '100%',
                  alignItems: 'flex-start',
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', width: 1 }}>
                  <IconButton
                    aria-label="Fetch"
                    title={loadingLayers.includes(index) ? 'Cancel' : 'Fetch'}
                    onClick={handleRefreshClick}
                    value={index}
                    disabled={
                      !(layer.source && layer.type) ||
                      !!limitsExceeded ||
                      estimatingLayers.includes(index) ||
                      (layer.type === 'file' &&
                        (!layer.file?.content ||
                          !layer.primaryItemKey ||
                          !layer.secondaryItemKey)) ||
                      disableAllFetches ||
                      layerHasErrors
                    }
                  >
                    {!!limitsExceeded || disableAllFetches ? (
                      <FetchDisallowedIcon />
                    ) : loadingLayers.includes(index) ? (
                      <CloseIcon color="error" />
                    ) : (
                      <AutorenewIcon />
                    )}
                  </IconButton>
                  <IconButton
                    aria-label="Delete"
                    title="Delete"
                    color="error"
                    onClick={handleDeleteClick}
                    value={index}
                  >
                    <DeleteIcon />
                  </IconButton>
                  <IconButton
                    aria-label="Copy"
                    title="Copy"
                    onClick={handleCopyClick}
                    value={index}
                    disabled={layer.type === 'file'}
                  >
                    <CopyIcon />
                  </IconButton>
                  {layer.featureCollection && (
                    <Button
                      onClick={handleItemsClick}
                      value={index}
                      title="Results"
                      disabled={layer.featureCollection?.features?.length === 0}
                    >
                      {numberOfItems(layer)}
                    </Button>
                  )}
                  {loadingLayers.includes(index) && (
                    <CircularProgress sx={{ m: 1 }} size={16} thickness={6} />
                  )}
                  <Box style={{ flex: 1 }} />
                  {(tooManyMapItems(layer) ||
                    layer.virtualize !== undefined) && (
                    <IconButton
                      onClick={() => handleVirtualizationToggle(layer, index)}
                      color={layer.virtualize === false ? 'warning' : undefined}
                      title={
                        layer.virtualize === false
                          ? 'Showing all items on map (may decrease performance)'
                          : 'Locked items shown on map to those visible in results list'
                      }
                      value={index}
                    >
                      {layer.virtualize === false ? (
                        <VirtualiseMapOffIcon />
                      ) : (
                        <VirtualiseMapOnIcon />
                      )}
                    </IconButton>
                  )}
                  <Field
                    name={`${name}.hidden`}
                    falseIcon={VisibilityIcon}
                    trueIcon={VisibilityOffIcon}
                    component={IconButtonField}
                  />
                </Box>
                <Box>{estimateLabel({ ...layer, index }, limitsExceeded)}</Box>
              </CardActions>
            </Card>
          )}
        </Draggable>
      );
    });

    // return components;
    return ordering.map((i) => components?.[i]).filter(Boolean);
  };

  return (
    <Fragment>
      <Toolbar
        variant="dense"
        disableGutters
        sx={{ pt: 1, pl: 2, pb: 0, pr: 0.5 }}
      >
        <Typography sx={{ flexGrow: 1 }} variant="subtitle1">
          Layers
        </Typography>
        <IconButton title="Add layer" size="small" onClick={handleAddLayer}>
          <Avatar
            sx={{
              color: 'secondary.contrastText',
              backgroundColor: 'secondary.main',
              width: 24,
              height: 24,
              fontSize: 16,
            }}
          >
            <AddIcon fontSize="inherit" />
          </Avatar>
        </IconButton>
      </Toolbar>
      {layers.length === 0 ? (
        <Box
          sx={{
            height: 1,
            m: 1,
            mr: 0.5,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            borderStyle: 'dashed',
            borderRadius: 1,
            borderColor: 'divider',
          }}
        >
          <DashboardCustomizeTwoToneIcon
            sx={{ color: 'divider', width: 140, height: 140 }}
          />
          <Typography sx={{ color: 'text.secondary' }}>
            Add some layers...
          </Typography>
        </Box>
      ) : (
        <Fragment>
          {totalEstimateLabel(totalEstimate, totalLimitsExceeded)}
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <Box
                  sx={{ overflow: 'auto' }}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {renderLayers()}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </DragDropContext>
          <ConfirmationDialog
            action="Delete"
            open={deleteLayer !== null}
            itemId={(deleteLayer && deleteLayer.label) || 'Untitled'}
            onOk={handleDeleteLayer}
            onCancel={handleDeleteCancel}
          />
        </Fragment>
      )}
    </Fragment>
  );
}
