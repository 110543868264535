import {
  Avatar,
  Button,
  Paper,
  CardActions,
  CardHeader,
  Divider,
  ListSubheader,
  Typography,
  Box,
} from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import moment from 'moment';
import { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Form } from 'react-final-form';
import { Helmet } from 'react-helmet-async';
import _ from 'lodash';
import { useSnackbar } from '../Snackbar';
import {
  CREATE_QUERY,
  DELETE_QUERY,
  FETCH_QUERY_SUCCESS,
  FETCH_QUERY,
  UPDATE_QUERY,
  FETCH_CRIME_FILTERS,
  FETCH_INCIDENT_FILTERS,
  FETCH_INTELLIGENCE_FILTERS,
  FETCH_STOP_CHECK_FILTERS,
  FETCH_BRIEFS,
} from '../../actions';
import { doesIdExist } from '../../apis/utilities';
import ConfirmationDialog from '../dialogs/ConfirmationDialog';
import { sourceTypes } from '../../data/constants';
import {
  Field,
  TextField,
  SelectField,
  BooleanSelectField,
  DateTimeField,
  ContentField,
  CheckboxField,
  TypesField,
  SelectMultipleField,
  GeometryField,
  required,
  validateIdentifier,
  StyledField,
} from '../fields';
import Parameters from './parameters';
import { Section } from '../controls';

const { maxUploadSize, personGroups } = window.config;

const initialValues = {
  type: 'Query',
};

const mutators = {
  clearValue: ([name], state, { changeValue }) => {
    changeValue(state, name, () => undefined);
  },
  resetFilter: ({ 1: name }, state, { changeValue }) => {
    function wipeSelections(filter) {
      delete filter.value;
      return filter;
    }

    changeValue(state, name, wipeSelections);
  },
};

export default function Query() {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const query = useSelector((state) => state.queries.query, _.isEqual);
  const error = useSelector((state) => state.queries.error);
  const briefs = useSelector((state) => state.briefs.briefNames, _.isEqual);
  const filters = useSelector(
    (state) => ({
      incidents: state.filters.incidents,
      crimes: state.filters.crimes,
      intelligence: state.filters.intelligence,
      stopChecks: state.filters.stopChecks,
    }),
    _.isEqual
  );
  const [deleteOpen, setDeleteOpen] = useState(false);
  const snackbar = useSnackbar();

  useEffect(() => {
    if (error) {
      snackbar.notify('error', error);
    }
  }, [error, snackbar]);

  useEffect(() => {
    if (id === 'new') {
      dispatch({
        type: FETCH_QUERY_SUCCESS,
        payload: null,
      });
    } else {
      dispatch({
        type: FETCH_QUERY,
        payload: id,
      });
    }
    dispatch({
      type: FETCH_BRIEFS,
    });
  }, [id, dispatch]);

  useEffect(() => {
    if (!filters.incidents) {
      dispatch({
        type: FETCH_INCIDENT_FILTERS,
      });
    }
  }, [filters.incidents, dispatch]);

  useEffect(() => {
    if (!filters.crimes) {
      dispatch({
        type: FETCH_CRIME_FILTERS,
      });
    }
  }, [filters.crimes, dispatch]);

  useEffect(() => {
    if (!filters.intelligence) {
      dispatch({
        type: FETCH_INTELLIGENCE_FILTERS,
      });
    }
  }, [filters.intelligence, dispatch]);

  useEffect(() => {
    if (!filters.stopChecks) {
      dispatch({
        type: FETCH_STOP_CHECK_FILTERS,
      });
    }
  }, [filters.stopChecks, dispatch]);

  function handleDelete() {
    if (query) {
      dispatch({
        type: DELETE_QUERY,
        payload: encodeURIComponent(query.identifier),
      });
    }
  }

  async function validate(values) {
    const errors = {};

    if (!query) {
      if (values.identifier) {
        errors.identifier = validateIdentifier(values.identifier);
        const exists = await doesIdExist('queries', values.identifier);
        if (exists) {
          errors.identifier = 'Exists';
        }
      }
    }

    const blob = new Blob([JSON.stringify(values)], {
      type: 'application/json',
    });

    if (blob.size > maxUploadSize) {
      snackbar.notify('warning', 'Unable to save, content greater than 5MB');
      errors.description = 'Content greater than 5MB';
    }

    // start/end validation
    /*
    errors.timePeriod = {};
    errors.timePeriod.startTime = requiredDateBefore(values.timePeriod.endTime)(
      values.timePeriod.startTime
    );
    errors.timePeriod.endTime = requiredDateAfter(values.timePeriod.startTime)(
      values.timePeriod.endTime
    );
    */

    return errors;
  }

  function handleSubmit(values) {
    if (query) {
      dispatch({
        type: UPDATE_QUERY,
        payload: values,
      });
    } else {
      dispatch({
        type: CREATE_QUERY,
        payload: values,
        navigate,
      });
    }
  }

  return (
    <Form
      initialValues={query || initialValues}
      validate={validate}
      onSubmit={handleSubmit}
      mutators={mutators}
      render={({
        handleSubmit,
        form: { mutators, reset },
        submitting,
        dirty,
        pristine,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <Helmet>
            <title>IR3 | Query{values.title ? ` | ${values.title}` : ''}</title>
          </Helmet>
          <Paper sx={{ m: 1, minWidth: 240 }}>
            <CardHeader
              avatar={
                <Avatar>
                  <SearchIcon />
                </Avatar>
              }
              title={values.title}
              subheader={values.identifier}
            />
            <Box>
              {values.created && (
                <Box sx={{ pl: 2 }}>
                  <Typography variant="caption">
                    {`Created by ${values.created.userId} ${moment(
                      values.created.time
                    ).format('DD/MM/YYYY, HH:mm')}`}
                  </Typography>
                </Box>
              )}
              {values.lastEdit && (
                <Box sx={{ pl: 2 }}>
                  <Typography variant="caption">
                    {`Last edited by ${values.lastEdit.userId} ${moment(
                      values.lastEdit.time
                    ).format('DD/MM/YYYY, HH:mm')}`}
                  </Typography>
                </Box>
              )}
              <ListSubheader disableSticky>Key Information</ListSubheader>
              <Section>
                <StyledField
                  name="title"
                  component={TextField}
                  label="Title"
                  validate={required}
                />
                <StyledField
                  name="identifier"
                  component={TextField}
                  label="Identifier"
                  disabled={query && 'identifier' in query}
                  validate={required}
                />
                <StyledField
                  name="source"
                  component={SelectField}
                  label="Source"
                  onChange={() => mutators.clearValue('parameters')}
                  values={Object.entries(sourceTypes).map((source) => {
                    return {
                      value: source[0],
                      label: source[1],
                    };
                  })}
                />
              </Section>
              <Divider />
              <ListSubheader disableSticky>Description</ListSubheader>
              <Section>
                <Field
                  name="description"
                  component={ContentField}
                  sx={{ flex: 1, mb: 1 }}
                />
              </Section>
              <Divider />
              <ListSubheader disableSticky>Time Period</ListSubheader>
              <Section>
                <StyledField
                  name="isRelativeTimePeriod"
                  component={BooleanSelectField}
                  label="Time Period Type"
                  trueLabel="Relative"
                  falseLabel="Absolute"
                  onChange={() => mutators.clearValue('timePeriod')}
                />
                {values.isRelativeTimePeriod !== undefined &&
                values.isRelativeTimePeriod ? (
                  <Fragment>
                    <StyledField
                      name="timePeriod.isRetrospective"
                      label="Direction"
                      component={BooleanSelectField}
                      trueLabel="Last"
                      falseLabel="Next"
                      sx={{ width: 100 }}
                    />
                    <StyledField
                      name="timePeriod.amount"
                      type="number"
                      label="Amount"
                      component={TextField}
                      sx={{ width: 100 }}
                    />
                    <StyledField
                      name="timePeriod.unit"
                      label="Unit"
                      component={SelectField}
                      values={['days', 'weeks', 'months'].map((type) => {
                        return { value: type, label: type };
                      })}
                      sx={{ width: 100 }}
                    />
                    <StyledField
                      name="timePeriod.includeCurrent"
                      component={CheckboxField}
                      label={`Include current ${(
                        (values.timePeriod || {}).unit || ''
                      ).slice(0, -1)}`}
                    />
                  </Fragment>
                ) : (
                  <Fragment>
                    <StyledField
                      name="timePeriod.startTime"
                      component={DateTimeField}
                      label="Start Time"
                      maxDate={values.endTime || '2100-01-01'}
                    />
                    <StyledField
                      name="timePeriod.endTime"
                      component={DateTimeField}
                      label="End Time"
                      minDate={values.startTime || '1900-01-01'}
                    />
                  </Fragment>
                )}
              </Section>
              <Divider />
              <ListSubheader disableSticky>Parameters</ListSubheader>
              <Section>
                <Parameters
                  name="parameters"
                  filters={filters[values.source] || {}}
                  source={values.source}
                />
              </Section>
              <Divider />
              <ListSubheader disableSticky>Groups & Areas</ListSubheader>
              <Section>
                <Field
                  name="areas"
                  types={personGroups}
                  component={TypesField}
                />
              </Section>
              <Divider />
              <ListSubheader disableSticky>Briefs</ListSubheader>
              <Section>
                <Field
                  name="briefs"
                  fullWidth
                  component={SelectMultipleField}
                  suggestions={briefs.map((query) => ({
                    label: query.title,
                    value: query.identifier,
                  }))}
                />
              </Section>
              {values.source === 'Incidents' && (
                <Fragment>
                  <Divider />
                  <ListSubheader disableSticky>Boundary</ListSubheader>
                  <Field
                    name="boundary"
                    component={GeometryField}
                    geoType="Polygon"
                  />
                </Fragment>
              )}
            </Box>
            <CardActions disableSpacing>
              <Button
                color="primary"
                type="submit"
                disabled={pristine || submitting}
              >
                Save
              </Button>
              <Button
                color="primary"
                disabled={pristine || submitting}
                onClick={reset}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                disabled={dirty || submitting}
                onClick={() => navigate(`${id}/contents`)}
              >
                Contents
              </Button>
              <Button
                color="error"
                onClick={() => setDeleteOpen(true)}
                disabled={query === null}
              >
                Delete
              </Button>
            </CardActions>
          </Paper>
          <ConfirmationDialog
            action="Delete"
            open={deleteOpen}
            itemId={values.title || values.identifier}
            onOk={handleDelete}
            onCancel={() => setDeleteOpen(false)}
          />
        </form>
      )}
    />
  );
}
