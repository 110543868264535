import { IconButton } from '@mui/material';
import { PlayArrow as PlayArrowIcon } from '@mui/icons-material';

import { useNavigate } from 'react-router-dom';

export default function ReplayLink({ entry }) {
  const navigate = useNavigate();

  const handleClick = (identifier) => (event) => {
    event.stopPropagation();

    navigate(`/replay/trips/${identifier}`);
  };

  return (
    <IconButton
      title="View"
      size="small"
      aria-label="View"
      onClick={handleClick(entry.identifier)}
    >
      <PlayArrowIcon />
    </IconButton>
  );
}
