import {
  Avatar,
  Box,
  Button,
  CardActions,
  CardHeader,
  Divider,
  ListSubheader,
  Paper,
  Typography,
} from '@mui/material';
import { Assignment as AssignmentIcon } from '@mui/icons-material';
import moment from 'moment';
import { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Form } from 'react-final-form';
import _ from 'lodash';
import { useSnackbar } from '../Snackbar';
import {
  CREATE_TAG,
  DELETE_TAG,
  FETCH_TAG,
  FETCH_TAG_SUCCESS,
  UPDATE_TAG,
} from '../../actions';
import { doesIdExist, urlInvalidCharactersRegex } from '../../apis/utilities';
import ConfirmationDialog from '../dialogs/ConfirmationDialog';
import {
  Field,
  ItemsField,
  TextField,
  ContentField,
  required,
  StyledField,
} from '../fields';
import { Section } from '../controls';

const initialValues = {
  type: 'Tag',
};

export default function Tag() {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const tag = useSelector((state) => state.tags.tag, _.isEqual);
  const error = useSelector((state) => state.tags.error);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const snackbar = useSnackbar();

  useEffect(() => {
    if (error) {
      snackbar.notify('error', error);
    }
  }, [error, snackbar]);

  useEffect(() => {
    if (id === 'new') {
      dispatch({
        type: FETCH_TAG_SUCCESS,
        payload: null,
      });
    } else {
      dispatch({
        type: FETCH_TAG,
        payload: id,
      });
    }
  }, [id, dispatch]);

  function handleDelete() {
    if (tag) {
      dispatch({
        type: DELETE_TAG,
        payload: encodeURIComponent(tag.identifier),
      });
    }
  }

  async function validate(values) {
    const errors = {};

    if (!tag) {
      if (values.identifier) {
        if (values.identifier === 'new') {
          errors.identifier = 'Invalid';
        }
        if (urlInvalidCharactersRegex.test(values.identifier)) {
          errors.identifier = 'Invalid characters';
        }
        const exists = await doesIdExist('tags', values.identifier);
        if (exists) {
          errors.identifier = 'Exists';
        }
      }
    }

    return errors;
  }

  function onSubmit(values) {
    if (tag) {
      dispatch({
        type: UPDATE_TAG,
        payload: values,
      });
    } else {
      dispatch({
        type: CREATE_TAG,
        payload: values,
        navigate,
      });
    }
  }

  return (
    <Form
      initialValues={tag || initialValues}
      validate={validate}
      onSubmit={onSubmit}
      render={({
        handleSubmit,
        form: { reset },
        submitting,
        dirty,
        pristine,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <Helmet>
            <title>
              IR3 | Tag{values.identifier ? ` | ${values.identifier}` : ''}
            </title>
          </Helmet>
          <Paper sx={{ m: 1, minWidth: 240 }}>
            <CardHeader
              avatar={
                <Avatar>
                  <AssignmentIcon />
                </Avatar>
              }
              title={values.identifier}
            />
            <Box>
              {values.created && (
                <Box sx={{ pl: 2 }}>
                  <Typography variant="caption">
                    {`Created by ${values.created.userId} ${moment(
                      values.created.time
                    ).format('DD/MM/YYYY, HH:mm')}`}
                  </Typography>
                </Box>
              )}
              {values.lastEdit && (
                <Box sx={{ pl: 2 }}>
                  <Typography variant="caption">
                    {`Last edited by ${values.lastEdit.userId} ${moment(
                      values.lastEdit.time
                    ).format('DD/MM/YYYY, HH:mm')}`}
                  </Typography>
                </Box>
              )}
              <ListSubheader disableSticky>Key Information</ListSubheader>
              <Section>
                <StyledField
                  name="identifier"
                  component={TextField}
                  label="Code"
                  validate={required}
                  disabled={tag && 'identifier' in tag}
                />
              </Section>
              <Divider />
              <ListSubheader disableSticky>Description</ListSubheader>
              <Section>
                <Field
                  name="description"
                  component={ContentField}
                  sx={{ flex: 1, mb: 1 }}
                />
              </Section>
              <Divider />
              {!_.isEmpty(values.items) && (
                <Fragment>
                  <ListSubheader disableSticky>Items</ListSubheader>
                  <Fragment>
                    {values.items && (
                      <Field name="items" component={ItemsField} />
                    )}
                  </Fragment>
                </Fragment>
              )}
            </Box>
            <CardActions disableSpacing>
              <Button
                color="primary"
                type="submit"
                disabled={pristine || submitting}
              >
                Save
              </Button>
              <Button
                color="primary"
                disabled={pristine || submitting}
                onClick={reset}
              >
                Cancel
              </Button>
              <Button
                color="error"
                onClick={() => setDeleteOpen(true)}
                disabled={tag === null}
              >
                Delete
              </Button>
            </CardActions>
          </Paper>
          <ConfirmationDialog
            action="Delete"
            open={deleteOpen}
            itemId={values.identifier}
            onOk={handleDelete}
            onCancel={() => setDeleteOpen(false)}
          />
        </form>
      )}
    />
  );
}
