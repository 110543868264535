import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import {
  FETCH_HOME_STATIONS,
  FETCH_HOME_STATIONS_SUCCESS,
  FETCH_HOME_STATIONS_FAILURE,
  FETCH_WARDS,
  FETCH_WARDS_SUCCESS,
  FETCH_WARDS_FAILURE,
  FETCH_LOCATIONS,
  FETCH_LOCATIONS_SUCCESS,
  FETCH_LOCATIONS_FAILURE,
  FETCH_LOCATION,
  FETCH_LOCATION_SUCCESS,
  FETCH_LOCATION_FAILURE,
  CREATE_LOCATION,
  CREATE_LOCATION_SUCCESS,
  CREATE_LOCATION_FAILURE,
  UPDATE_LOCATION,
  UPDATE_LOCATION_SUCCESS,
  UPDATE_LOCATION_FAILURE,
  DELETE_LOCATION,
  DELETE_LOCATION_SUCCESS,
  DELETE_LOCATION_FAILURE,
} from '../actions';
import { fromAjax } from '../apis';
import {
  getHeaders,
  log,
  areasToGroups,
  groupsToAreas,
} from '../apis/utilities';

const { homeStationTypes } = window.config;

export function fetchHomeStationsEpic(action$) {
  return action$.pipe(
    ofType(FETCH_HOME_STATIONS),
    mergeMap(() =>
      fromAjax('/locations', {
        params: {
          query: {
            type: { $in: homeStationTypes },
          },
          projection: { code: true, name: true },
          sort: { name: 1 },
        },
        headers: getHeaders(),
      }).pipe(
        map(({ response: payload }) => ({
          type: FETCH_HOME_STATIONS_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_HOME_STATIONS_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

export function fetchWardsEpic(action$) {
  return action$.pipe(
    ofType(FETCH_WARDS),
    mergeMap(() =>
      fromAjax('/locations', {
        params: {
          query: {
            type: 'Ward',
          },
          projection: { code: true, name: true },
          sort: { name: 1 },
        },
        headers: getHeaders(),
      }).pipe(
        map(({ response: payload }) => ({
          type: FETCH_WARDS_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_WARDS_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

export function fetchLocationsEpic(action$) {
  return action$.pipe(
    ofType(FETCH_LOCATIONS),
    mergeMap(({ payload: type }) =>
      fromAjax('/locations', {
        params: {
          ...(type !== 'All' ? { query: { type } } : {}),
          projection: {
            code: true,
            name: true,
            type: true,
            picture: true,
            tranmanIdentifier: true,
          },
          sort: { name: 1 },
        },
        headers: getHeaders(),
      }).pipe(
        map(({ response }) => {
          const payload = response.map((location) => ({
            ...location,
            searchString: [location.name, location.code]
              .join('+')
              .toLowerCase(),
          }));

          log('Read', 'Locations', { type });

          return {
            type: FETCH_LOCATIONS_SUCCESS,
            payload,
          };
        }),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_LOCATIONS_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

export function fetchLocationEpic(action$) {
  return action$.pipe(
    ofType(FETCH_LOCATION),
    mergeMap(({ payload: id }) =>
      fromAjax(`/locations/${id}`, {
        params: {
          projection: {
            code: true,
            name: true,
            type: true,
            areas: true,
            district: true,
            subtype: true,
            boundary: true,
            startTime: true,
            endTime: true,
            picture: true,
            tranmanIdentifier: true,
            groups: true,
          },
        },
        headers: getHeaders(),
      }).pipe(
        map(({ response: { areas, groups, ...response } }) => {
          const payload = {
            ...response,
            areas:
              groups && Object.keys(groups).length > 0
                ? groupsToAreas(groups)
                : areas,
            groups:
              areas && (!groups || Object.keys(groups).length === 0)
                ? areasToGroups(areas)
                : groups,
          };

          log('Read', 'Location', { id });

          return {
            type: FETCH_LOCATION_SUCCESS,
            payload,
          };
        }),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_LOCATION_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

export function createLocationEpic(action$) {
  return action$.pipe(
    ofType(CREATE_LOCATION),
    mergeMap(({ payload: body, navigate }) =>
      fromAjax(`/locations`, {
        body,
        method: 'POST',
        headers: { ...getHeaders(), 'Content-Type': 'application/json' },
      }).pipe(
        map(({ response: payload }) => {
          log('Create', 'Location', payload);

          navigate(`../${payload.code}`, {
            replace: true,
            state: { created: true },
          });

          return {
            type: CREATE_LOCATION_SUCCESS,
            payload,
          };
        }),
        catchError(({ message: payload }) =>
          of({
            type: CREATE_LOCATION_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

export function updateLocationEpic(action$) {
  return action$.pipe(
    ofType(UPDATE_LOCATION),
    mergeMap(({ payload: body }) =>
      fromAjax(`/locations/${body.code}`, {
        body,
        method: 'PATCH',
        headers: {
          ...getHeaders(),
          'Content-Type': 'application/merge-patch+json',
        },
      }).pipe(
        map(({ response: payload }) => {
          log('Update', 'Location', payload);

          return {
            type: UPDATE_LOCATION_SUCCESS,
            payload,
          };
        }),
        catchError(({ message: payload }) =>
          of({
            type: UPDATE_LOCATION_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

export function deleteLocationEpic(action$) {
  return action$.pipe(
    ofType(DELETE_LOCATION),
    mergeMap(({ payload: id, navigate }) =>
      fromAjax(`/locations/${id}`, {
        method: 'DELETE',
        headers: getHeaders(),
      }).pipe(
        map(({ response }) => {
          log('Delete', 'Location', { id });

          navigate('.');

          return {
            type: DELETE_LOCATION_SUCCESS,
            payload: response.code,
          };
        }),
        catchError(({ message: payload }) =>
          of({
            type: DELETE_LOCATION_FAILURE,
            payload,
          })
        )
      )
    )
  );
}
