import { Paper, IconButton, Toolbar, Typography, Box } from '@mui/material';
import { PlayArrow as PlayArrowIcon } from '@mui/icons-material';
import _ from 'lodash';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useSnackbar } from '../Snackbar';
import Container from '../Container';
import {
  Parameters,
  TablePagination,
  Table,
  ExportPicker,
  FilterPicker,
} from '../controls';
import {
  getPersonPolls,
  shortPersonHeaders,
  radioPollHeaders,
} from '../../apis/utilities';
import ChunkedDownloader from './ChunkedDownloader';
import usePaging from './usePaging';

const {
  rowsPerPageOptions,
  useReducedResourceInformation,
  events: {
    eventFilters: { personLocationVisits: eventFilters = [] } = {},
  } = {},
} = window.config;
const { extraTrailHeaders } = window.reportHeaders;

const csvHeaders = [...shortPersonHeaders, ...radioPollHeaders];

const headers = [
  ...extraTrailHeaders,
  { label: 'Location Type', key: 'location.type', type: 'text', filter: true },
  { label: 'Location Name', key: 'location.name', type: 'text', filter: true },
  { label: 'Start Time', key: 'startTime', type: 'date', filter: false },
  { label: 'End Time', key: 'endTime', type: 'date', filter: false },
  {
    label: 'Duration (minutes)',
    key: 'durationMinutes',
    serverKey: 'durationSeconds',
    type: 'number',
    filter: false,
  },
];

function ReplayLink({ entry }) {
  const navigate = useNavigate();

  const handleViewClick = (identifier) => () => {
    navigate(`/replay/personLocationIntersections/${identifier}`);
  };

  return (
    <IconButton
      title="View"
      size="small"
      aria-label="View"
      onClick={handleViewClick(entry.identifier)}
    >
      <PlayArrowIcon />
    </IconButton>
  );
}

function DownloadPersonPollsLink({ entry }) {
  async function fetchDataToConvert() {
    const polls = await getPersonPolls(
      entry.person.radioSsi,
      entry.startTime,
      entry.endTime
    );

    const data = polls.map((feature) => ({
      ...(useReducedResourceInformation
        ? { staffId: entry.person.code }
        : {
            name: [entry.person.forenames, entry.person.surname].join(' '),
            personRole: entry.person.role,
            collarNumber: entry.person.collarNumber,
          }),
      ...feature,
      time: new Date(feature.time),
      locationType: entry.location.type,
      locationName: entry.location.name,
      startTime: new Date(entry.startTime),
      endTime: new Date(entry.endTime),
      durationMinutes: entry.durationMinutes,
      radioSsi: feature.ssi,
      longitude: feature.position ? feature.position.coordinates[0] : 0,
      latitude: feature.position ? feature.position.coordinates[1] : 0,
    }));

    return data;
  }

  return (
    <ExportPicker
      title="download"
      fetchDataToConvert={fetchDataToConvert}
      filename="Person Location Visit"
      headers={csvHeaders}
    />
  );
}

const replayColumn = {
  label: '',
  key: 'replay',
  type: 'component',
  component: ReplayLink,
};

const downloadColumn = {
  label: '',
  key: 'download',
  type: 'component',
  component: DownloadPersonPollsLink,
};

export default function PersonLocationVisits() {
  const total = useSelector((state) => state.events.personLocationVisits.total);
  const visits = useSelector(
    (state) => state.events.personLocationVisits.list,
    _.isEqual
  );
  const totals = useSelector((state) => state.events.trails.totals, _.isEqual);
  const isLoading = useSelector(
    (state) => state.events.personLocationVisits.isLoading
  );
  const error = useSelector((state) => state.events.personLocationVisits.error);
  const filter = useSelector(
    (state) => state.events.personLocationVisits.filter,
    _.isEqual
  );
  const query = useSelector(
    (state) => state.events.personLocationVisits.query,
    _.isEqual
  );
  const snackbar = useSnackbar();
  const eventType = 'personLocationVisits';
  const paging = usePaging({ eventType, headers });

  useEffect(() => {
    if (error) {
      snackbar.notify('error', error);
    }
  }, [error, snackbar]);

  const filename = 'Person Location Visits.csv';
  const csvMapping = (visit) => ({
    ...visit,
    startTime: new Date(visit.startTime),
    endTime: new Date(visit.endTime),
    durationMinutes: _.round(visit.durationMinutes || 0.0, 2),
  });

  return (
    <Container title="Person Location Visits">
      <Parameters
        onFetch={paging.handleFetch}
        onCancel={paging.handleCancel}
        onChange={paging.handleQueryChange}
        isFetching={isLoading}
        sx={{ mt: 1, width: 264 }}
        value={query}
        person
        location
        eventFilters={eventFilters}
      />
      <Box
        sx={{
          flex: 1,
          height: 'calc(100vh - 48px)',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <Helmet>
          <title>IR3 | Person Location Visits</title>
        </Helmet>
        <Toolbar variant="dense" disableGutters sx={{ p: 1, pb: 0 }}>
          <Typography sx={{ flexGrow: 1 }} variant="subtitle1">
            Person Location Visits
          </Typography>
          <FilterPicker
            headers={headers}
            data={visits}
            filter={filter}
            filterValues={filter.filterValues}
            onFilterChange={paging.onFilterChange}
          />
          <ChunkedDownloader
            eventType={eventType}
            filename={filename}
            headers={headers}
            csvMapping={csvMapping}
          />
        </Toolbar>
        <Paper sx={{ m: [0, 1, 1], minWidth: 240 }}>
          <Box sx={isLoading ? { opacity: 0.5 } : undefined}>
            <Table
              styles={{
                tableContainer: {
                  height: 'calc(100vh - 172px)',
                  overflowY: 'scroll',
                },
                table: {
                  minWidth: 750,
                },
              }}
              data={visits}
              headers={headers.concat(replayColumn).concat(downloadColumn)}
              rowsPerPage={filter.rowsPerPage}
              page={0}
              order={filter.order}
              orderBy={filter.orderBy}
              onOrderChange={paging.handleOrderChange}
              onOrderByChange={paging.handleOrderByChange}
              totals={totals}
            />
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              component="div"
              count={total ?? 0}
              rowsPerPage={filter.rowsPerPage}
              page={filter.page}
              onPageChange={paging.handlePageChange}
              onRowsPerPageChange={paging.handleRowsPerPageChange}
            />
          </Box>
        </Paper>
      </Box>
    </Container>
  );
}
