import {
  TextField,
  CheckboxField,
  SelectField,
  StyledField,
  required,
} from '../fields';
import { useAuth } from '../Auth';
import { vinErrors } from './TelematicsBoxList/utilities';
import { Fragment } from 'react';

const {
  vehicleRoles,
  vehicleTypes,
  vehicleEditableFields,
  useRestricted,
  useReducedResourceInformation,
} = window.config;

export default function VehicleKeyFields({ vehicle, disabled }) {
  const auth = useAuth();
  const canEdit = auth.isAuthorised('vehicles', true);

  function isDisabled(fieldName) {
    return (
      !!disabled || !(canEdit && (vehicleEditableFields[fieldName] || !vehicle))
    );
  }

  const composeValidators =
    (...validators) =>
    (value) =>
      validators.reduce(
        (error, validator) => error || validator(value),
        undefined
      );

  function validateIfNotDisabled(disabled, validateFunction) {
    if (disabled) {
      return undefined;
    } else {
      return validateFunction;
    }
  }

  return (
    <Fragment>
      <StyledField
        name="identificationNumber"
        component={TextField}
        label="VIN"
        validate={validateIfNotDisabled(
          isDisabled('identificationNumber'),
          composeValidators(required, vinErrors)
        )}
        disabled={isDisabled('identificationNumber')}
      >
        {(props) => (
          <TextField
            {...props}
            value={props.input.value}
            inputProps={{
              spellCheck: 'false',
            }}
          />
        )}
      </StyledField>
      {!useReducedResourceInformation && (
        <StyledField
          name="registrationNumber"
          component={TextField}
          label="Registration"
          validate={validateIfNotDisabled(
            isDisabled('registrationNumber'),
            required
          )}
          disabled={isDisabled('registrationNumber')}
        >
          {(props) => (
            <TextField
              {...props}
              value={props.input.value}
              inputProps={{
                spellCheck: 'false',
              }}
            />
          )}
        </StyledField>
      )}
      <StyledField
        name="fleetNumber"
        component={TextField}
        label="Fleet Number"
        validate={validateIfNotDisabled(isDisabled('fleetNumber'), required)}
        disabled={isDisabled('fleetNumber')}
      >
        {(props) => (
          <TextField
            {...props}
            value={props.input.value}
            inputProps={{
              spellCheck: 'false',
            }}
          />
        )}
      </StyledField>
      {useReducedResourceInformation ? (
        <StyledField
          name="type"
          component={SelectField}
          label="Type"
          validate={validateIfNotDisabled(isDisabled('type'), required)}
          values={vehicleTypes}
          disabled={isDisabled('type')}
        />
      ) : (
        <StyledField
          name="role"
          component={SelectField}
          label="Role"
          validate={validateIfNotDisabled(isDisabled('role'), required)}
          values={vehicleRoles}
          disabled={isDisabled('role')}
        />
      )}
      {useRestricted && (
        <StyledField
          name="restricted"
          component={CheckboxField}
          label="Restricted"
          disabled={isDisabled('restricted')}
        />
      )}
    </Fragment>
  );
}
