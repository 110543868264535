import LiveListItem from './LiveListItem';

function ObjectiveLiveListItem(props) {
  return (
    <LiveListItem
      type="objectives"
      primaryPath="title"
      secondaryPath="id"
      hideFollow={!props.item.boundary}
      {...props}
    />
  );
}

export default ObjectiveLiveListItem;
