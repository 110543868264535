import { useState, useEffect } from 'react';
import TextField from './TextField';
import { useDebounce } from '../../hooks';

export default function DebouncedTextField({
  input: { onChange, value, ...input },
  ignoreTouched,
  ...props
}) {
  const [text, setText] = useState(value);
  const debouncedText = useDebounce(text, 300);

  useEffect(() => {
    onChange(debouncedText);
  }, [debouncedText, onChange]);

  return (
    <TextField
      {...props}
      {...input}
      ignoreTouched
      onChange={({ target: { value } }) => {
        setText(value);
      }}
      value={text}
    />
  );
}
