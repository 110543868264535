import { Fragment } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import {
  Flag as FlagIcon,
  Person as PersonIcon,
  OutlinedFlag as OutlinedFlagIcon,
  PlayArrow as PlayArrowIcon,
} from '@mui/icons-material';
import { Helmet } from 'react-helmet-async';
import moment from 'moment';
import _ from 'lodash';
import humanizeDuration from 'humanize-duration';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import {
  RetrospectiveTypeIcon,
  LocationTypeIcon,
} from '../../../data/constants';
import { ExportPicker } from '../../controls';
import {
  getVehiclePolls,
  shortPersonHeaders,
  shortVehicleHeaders,
  telematicsBoxPollHeaders,
} from '../../../apis/utilities';
import { getPrimaryText, getSecondaryText } from '../constants';

const { useReducedResourceInformation } = window.config;

export default function VehicleVisit({
  color,
  background,
  identifier,
  tripIdentifier,
  driver,
  vehicle,
  visitLocation,
  startTime,
  endTime,
  distanceKilometres,
  maxSpeedKilometresPerHour,
  trips,
}) {
  const navigate = useNavigate();
  const { id, layerIndex, itemIndex } = useParams();

  function Row({ data, index, style }) {
    const feature = {
      properties: {
        ...data[index],
        source: 'vehicleTrips',
      },
    };

    const { driver } = data[index];
    let driverName = undefined;
    if (driver.forenames || driver.surname) {
      driverName = [driver.forenames, driver.surname].join(' ');
    }

    return (
      <ListItem
        dense
        button
        key={index}
        style={style}
        component={Link}
        to={`/retrospective/${
          id || 'untitled'
        }/${layerIndex}/${itemIndex}/${index}`}
      >
        <ListItemAvatar>
          <Avatar
            style={{
              background,
              color,
            }}
          >
            <RetrospectiveTypeIcon type={'vehicleTrips'} />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={driverName ?? getPrimaryText(feature)}
          secondary={getSecondaryText(feature)}
        />
      </ListItem>
    );
  }

  function handleViewClick() {
    navigate(`/replay/intersections/${identifier}`);
  }

  async function fetchDataToConvert() {
    const polls = await getVehiclePolls(
      vehicle.telematicsBoxImei,
      startTime,
      endTime
    );

    const data = polls.map((poll) => ({
      ...poll,
      ...(useReducedResourceInformation
        ? { staffId: driver?.code, type: vehicle.type }
        : {
            name: driver ? driver.forenames + ' ' + driver.surname : '',
            collarNumber: driver?.collarNumber,
            personRole: driver?.role,
          }),
      vehicleRole: vehicle.role,
      identificationNumber: vehicle.identificationNumber,
      registrationNumber: vehicle.registrationNumber,
      fleetNumber: vehicle.fleetNumber,
      time: new Date(poll.time),
      longitude: poll.position ? poll.position.coordinates[0] : 0,
      latitude: poll.position ? poll.position.coordinates[1] : 0,
      speedLimitMilesPerHour: poll.speedLimitMilesPerHour,
    }));

    return data;
  }

  const vehicleVisitHeaders = [
    ...shortPersonHeaders,
    ...shortVehicleHeaders,
    ...telematicsBoxPollHeaders,
  ];

  const cardHeaderButtons = (
    <Box>
      {identifier && (
        <IconButton
          aria-label="replay"
          title="View Replay"
          onClick={handleViewClick}
          size="large"
        >
          <PlayArrowIcon />
        </IconButton>
      )}
      <ExportPicker
        title="Download Vehicle Visit"
        fetchDataToConvert={fetchDataToConvert}
        filename="Vehicle Visit"
        headers={vehicleVisitHeaders}
      />
    </Box>
  );

  let title, subheader;
  if (vehicle.unassociated) {
    title = 'Unassociated IMEI';
    subheader = vehicle.telematicsBoxImei;
  } else {
    title = useReducedResourceInformation
      ? vehicle.fleetNumber
      : vehicle.registrationNumber;
    subheader = useReducedResourceInformation
      ? vehicle.identificationNumber
      : vehicle.fleetNumber;
  }

  return (
    <Card sx={{ m: [1, 0.5, 1, 1] }}>
      <Helmet>
        <title>IR3 | Vehicle Visit |{` ${title}`}</title>
      </Helmet>
      <CardHeader
        avatar={
          <Avatar
            style={{
              background,
              color,
            }}
            title="Vehicle Visit"
          >
            <RetrospectiveTypeIcon type="vehicleVisits" />
          </Avatar>
        }
        title={title}
        subheader={subheader}
        action={cardHeaderButtons}
      />
      <CardContent>
        {vehicle.identificationNumber && (
          <Fragment>
            <Typography variant="subtitle2" color="textSecondary">
              Vehicle
            </Typography>
            <Table size="small" sx={{ mt: 1, mb: 2 }}>
              <TableBody>
                {!useReducedResourceInformation && !!vehicle.role && (
                  <TableRow>
                    <TableCell>
                      <Typography variant="caption" color="textSecondary">
                        Role
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="caption">{vehicle.role}</Typography>
                    </TableCell>
                  </TableRow>
                )}
                {!!vehicle.homeStation && (
                  <TableRow>
                    <TableCell>
                      <Typography variant="caption" color="textSecondary">
                        {useReducedResourceInformation
                          ? 'Home Location'
                          : 'Home Station'}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="caption">
                        {vehicle.homeStation}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                {(vehicle.areas || []).map((area, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Typography variant="caption" color="textSecondary">
                        {_.startCase(area.type)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="caption">{area.name}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Fragment>
        )}
        {visitLocation.name !== '<custom>' && (
          <Fragment>
            <Typography variant="subtitle2" color="textSecondary">
              Location
            </Typography>
            <ListItem component="div" sx={{ my: 1 }}>
              <ListItemAvatar>
                <Avatar title={visitLocation.type}>
                  <LocationTypeIcon type={visitLocation.type} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={visitLocation.name}
                secondary={visitLocation.type}
              />
            </ListItem>
          </Fragment>
        )}
        {(driver || {}).identificationReference && (
          <Fragment>
            <Typography variant="subtitle2" color="textSecondary">
              Driver
            </Typography>
            <ListItem component="div" sx={{ my: 1 }}>
              <ListItemAvatar>
                <Avatar>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  useReducedResourceInformation
                    ? driver?.code
                    : `${driver?.forenames || ''}  ${driver?.surname || ''}`
                }
                secondary={driver?.collarNumber}
              />
            </ListItem>
          </Fragment>
        )}
        <Typography variant="subtitle2" color="textSecondary">
          Timeline
        </Typography>
        <Stepper orientation="vertical">
          <Step active>
            <StepLabel
              icon={
                <Avatar sx={{ width: 30, height: 30 }}>
                  <FlagIcon fontSize="inherit" />
                </Avatar>
              }
              optional={
                <Typography variant="caption">
                  {moment(startTime).format('DD/MM/YYYY, HH:mm:ss')}
                </Typography>
              }
            >
              Start
            </StepLabel>
            <StepContent />
          </Step>
          <Step active>
            <StepLabel
              icon={
                <Avatar sx={{ width: 30, height: 30 }}>
                  <OutlinedFlagIcon fontSize="inherit" />
                </Avatar>
              }
              optional={
                <Typography variant="caption">
                  {moment(endTime).format('DD/MM/YYYY, HH:mm:ss')}
                </Typography>
              }
            >
              End
            </StepLabel>
            <StepContent />
          </Step>
        </Stepper>
        {trips?.length ? (
          <Fragment>
            <Typography variant="subtitle2" color="textSecondary">
              Trips
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flex: '1 1 auto',
                flexDirection: 'column',
                overflow: 'hidden',
                my: 1,
              }}
            >
              <Box
                sx={{
                  overflowY: 'auto',
                  overflowX: 'hidden',
                  height: trips.length * 56,
                }}
              >
                <AutoSizer>
                  {({ width, height }) => (
                    <FixedSizeList
                      width={width}
                      height={trips.length * 56}
                      overscanCount={10}
                      itemData={trips}
                      itemCount={trips.length}
                      itemSize={56}
                      // onItemsRendered={debouncedHandleItemsRenderedChange}
                    >
                      {Row}
                    </FixedSizeList>
                  )}
                </AutoSizer>
              </Box>
            </Box>
          </Fragment>
        ) : (
          <Fragment> </Fragment>
        )}
        <Typography variant="subtitle2" color="textSecondary">
          Metrics
        </Typography>
        <Table size="small" sx={{ mt: 1, mb: 2 }}>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography variant="caption" color="textSecondary">
                  Distance
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">
                  {`${_.round(distanceKilometres * 0.62137119, 2)} miles`}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="caption" color="textSecondary">
                  Maximum Speed
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">
                  {`${_.round(maxSpeedKilometresPerHour * 0.62137119, 2)} MPH`}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="caption" color="textSecondary">
                  Duration
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">
                  {humanizeDuration(moment(endTime).diff(moment(startTime)))}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}
