import { Field as FinalFormField } from 'react-final-form';

const identity = (value) => value;

// TODO: Get rid of this hack once PATCH is in place

/* This wraps react-final-form's <Field/> component.
 * The identity function ensures form values never get set to null,
 * but rather, empty strings.
 *
 * See https://github.com/final-form/react-final-form/issues/130
 */
export default function Field(props) {
  return <FinalFormField parse={identity} {...props} />;
}
