import { AppBar, Box, IconButton, Toolbar, Typography } from '@mui/material';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { LoginAvatar, MenuButton } from '../controls';

export default function Container({
  showBack,
  title,
  children,
  extraButtonsRender,
  noShadow,
}) {
  const navigate = useNavigate();

  function handleBackClick() {
    navigate(-1);
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
      }}
    >
      <AppBar elevation={noShadow ? 0 : 1} position="static">
        <Toolbar variant="dense" sx={{ px: 1.5 }}>
          {showBack ? (
            <IconButton
              color="inherit"
              sx={{ mr: 1.25 }}
              onClick={handleBackClick}
              size="large"
            >
              <ArrowBackIcon />
            </IconButton>
          ) : (
            <MenuButton color="inherit" sx={{ mr: 1.25 }} />
          )}
          <Typography variant="h6" color="inherit">
            {title}
          </Typography>
          <Box sx={{ flex: 1 }}></Box>
          {extraButtonsRender && extraButtonsRender()}
          <LoginAvatar />
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          display: 'flex',
          height: 1,
          overflow: 'hidden',
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
