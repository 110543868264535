import { Box, Paper, Toolbar, Typography } from '@mui/material';
import _ from 'lodash';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Container from '../Container';
import { FilterPicker, Parameters, TablePagination, Table } from '../controls';
import { useSnackbar } from '../Snackbar';
import ChunkedDownloader from './ChunkedDownloader';
import usePaging from './usePaging';

const { rowsPerPageOptions } = window.config;
const { extraOnBoardDiagnosticHeaders } = window.reportHeaders;

const headers = [
  ...extraOnBoardDiagnosticHeaders,
  { label: 'Time', key: 'time', type: 'date', filter: false },
  { label: 'Class', key: 'class', type: 'text', filter: true },
  { label: 'Code', key: 'code', type: 'text', filter: true },
  { label: 'Confirmed', key: 'isConfirmed', type: 'text', filter: true },
  { label: 'Area', key: 'area', type: 'text', filter: true },
  { label: 'Description', key: 'description', type: 'text', filter: true },
];

export default function OnBoardDiagnostics() {
  const diagnostics = useSelector(
    (state) => state.events.onBoardDiagnostics.list,
    _.isEqual
  );
  const total = useSelector((state) => state.events.onBoardDiagnostics.total);
  const isLoading = useSelector(
    (state) => state.events.onBoardDiagnostics.isLoading
  );
  const error = useSelector((state) => state.events.onBoardDiagnostics.error);
  const filter = useSelector(
    (state) => state.events.onBoardDiagnostics.filter,
    _.isEqual
  );
  const query = useSelector(
    (state) => state.events.onBoardDiagnostics.query,
    _.isEqual
  );
  const snackbar = useSnackbar();
  const eventType = 'onBoardDiagnostics';

  const paging = usePaging({ eventType, headers });

  useEffect(() => {
    if (error) {
      snackbar.notify('error', error);
    }
  }, [error, snackbar]);

  const filename = 'On Board Diagnostics.csv';
  const csvMapping = (diagnostics) => ({
    ...diagnostics,
    'vehicle.fleetNumber': diagnostics.vehicle.fleetNumber,
    'vehicle.type': diagnostics.vehicle.type,
    'vehicle.registrationNumber': diagnostics.vehicle.registrationNumber,
    'vehicle.role': diagnostics.vehicle.role,
    time: moment(diagnostics.time).format('YYYY-MM-DD HH:mm:ss'),
  });

  return (
    <Container title="On-Board Diagnostics">
      <Parameters
        onFetch={paging.handleFetch}
        onCancel={paging.handleCancel}
        isFetching={isLoading}
        value={query}
        onChange={paging.handleQueryChange}
        sx={{ mt: 1, width: 264 }}
        vehicle
        pointEvent
      />
      <Box
        sx={{
          flex: 1,
          height: 'calc(100vh - 48px)',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <Helmet>
          <title>IR3 | On-Board Diagnostics</title>
        </Helmet>
        <Toolbar variant="dense" disableGutters sx={{ p: 1, pb: 0 }}>
          <Typography sx={{ flexGrow: 1 }} variant="subtitle1">
            On-Board Diagnostics
          </Typography>
          <FilterPicker
            headers={headers}
            data={diagnostics}
            filter={filter}
            onFilterChange={paging.onFilterChange}
          />
          <ChunkedDownloader
            eventType={eventType}
            filename={filename}
            headers={headers}
            csvMapping={csvMapping}
          />
        </Toolbar>
        <Paper sx={{ m: [0, 1, 1], minWidth: 240 }}>
          <Box sx={isLoading ? { opacity: 0.5 } : undefined}>
            <Table
              styles={{
                tableContainer: {
                  height: 'calc(100vh - 172px)',
                  overflowY: 'scroll',
                },
                table: {
                  minWidth: 750,
                },
              }}
              data={diagnostics}
              headers={headers}
              rowsPerPage={filter.rowsPerPage}
              page={filter.page}
              order={filter.order}
              orderBy={filter.orderBy}
              onOrderChange={paging.handleOrderChange}
              onOrderByChange={paging.handleOrderByChange}
            />
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              component="div"
              count={total ?? 0}
              rowsPerPage={filter.rowsPerPage}
              page={0}
              onPageChange={paging.handlePageChange}
              onRowsPerPageChange={paging.handleRowsPerPageChange}
            />
          </Box>
        </Paper>
      </Box>
    </Container>
  );
}
