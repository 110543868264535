import { TextField, Typography, InputAdornment } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { Search as SearchIcon } from '@mui/icons-material';

export default function SearchBox({ sx, count, ...props }) {
  return (
    <TextField
      size="small"
      placeholder="Search..."
      variant="outlined"
      type="search"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon fontSize="inherit" />
          </InputAdornment>
        ),
        endAdornment: count && (
          <InputAdornment position="end" disableTypography>
            <Typography variant="caption" color="textSecondary">
              {count}
            </Typography>
          </InputAdornment>
        ),
      }}
      sx={{
        '& .MuiOutlinedInput-root': {
          '& > fieldset': {
            border: 'none',
            bgcolor: alpha('#000', 0.15),
          },
        },
        '& .MuiOutlinedInput-root:hover': {
          '& > fieldset': {
            border: 'none',
            bgcolor: alpha('#000', 0.1),
          },
        },
        ...sx,
      }}
      {...props}
    />
  );
}
