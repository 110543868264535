import { Fragment, useState } from 'react';
import {
  IconButton,
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  // ListItemText,
  ListSubheader,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Typography,
} from '@mui/material';
import { FormatListBulleted as ParametersIcon } from '@mui/icons-material';
import _ from 'lodash';
import FilterSummary from '../../live/FilterSummary';
import SearchSummary from '../../live/SearchSummary';
import { singularToPluralTypeMap } from '../../live/constants';

// a mongo query will look something like this
// const query = {
//   endTime: {$gte: "2020-05-31T23:00:00.000Z"},
//   "location.code": {$in: ["ACORN"]},
//   startTime: {$lt: "2020-06-02T23:00:00.000Z"},
//   "vehicle.areas.name": {$in: ["North East", "North West", "South East"]},
//   "vehicle.role": {$in: ["Inspectors", "Pool", "Test Role", "Traffic Officer"]},
// }
// but the filter summary expects something like:
// const filters = {
//   locations: [
//     {field: "code", condition: "eq", value: ["ACORN"]},
//   ],
//   vehicles: [
//     {field: "areas", condition: "eq", value: ["North East", "North West", "South East"]},
//     {field: "role", condition: "eq", value: ["Inspectors", "Pool", "Test Role", "Traffic Officer"]},
//   ],
//  // there's nothing for plain properties like startTime/endTime yet
// }
function convertMongoQueryToFilters(mongoQuery) {
  let filters = { general: [] };
  Object.keys(mongoQuery).forEach((path) => {
    const [condition, value] = Object.entries(mongoQuery[path] || {})?.[0];
    const parts = path.split('.');
    // do we know what type it is? (.shift will remove this first part from the path)
    const type = singularToPluralTypeMap[parts.shift()];
    if (type) {
      // have we seen this type before? if not add a new array for it
      if (!filters[type]) {
        filters[type] = [];
      }

      // add the filter
      filters[type].push({
        field: parts.join(' '),
        condition,
        value,
      });
    } else {
      // might be a plain thing like startTime or endTime
      filters.general.push({
        field: path,
        condition,
        value,
      });
    }
  });

  return filters;
}

// some parameters are empty or just id which is shown in the link - boring
function interesting(parameters) {
  const keys = Object.keys(parameters || {});

  return keys.length > 0 && !(keys.length === 1 && keys[0] === 'id');
}

// check every entry has an object for the value and the first key begins with $
function isQuery(parameters) {
  const keys = Object.keys(
    (typeof parameters === 'object' && parameters) || {}
  );
  return (
    keys.length > 0 &&
    keys.every(
      (key) =>
        typeof parameters[key] === 'object' &&
        Object.entries(parameters[key] || {})[0]?.[0]?.startsWith('$')
    )
  );
}

function PlainDetails({ parameters }) {
  if (typeof parameters !== 'object') {
    return <Fragment />;
  }

  // return (<List>
  //   {Object.keys(parameters).map((key, i) => (
  //     <ListItem key={i}>
  //       <ListSubheader disableSticky primary={`${_.startCase(key)}: ${parameters[key]}`} />
  //     </ListItem>
  //   ))}
  // </List>);

  return (
    <Table size="small">
      <TableBody>
        {Object.keys(parameters)
          .filter((key) => typeof parameters[key] === 'string')
          .map((key, i) => (
            <TableRow key={i}>
              <TableCell>
                <Typography variant="caption" color="textSecondary">
                  {_.startCase(key)}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">{parameters[key]}</Typography>
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
}

function BeforeAfter({ before, after }) {
  const beforeKeys = Object.keys(before);
  const afterKeys = Object.keys(after);

  // this is like toString obviously but we don't want the user seeing [object Object]
  function asString(value) {
    return typeof value === 'string' ? value : '<complex>';
  }

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Property</TableCell>
          <TableCell>New Value</TableCell>
          <TableCell>Old Value</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {afterKeys // new keys are in after but not before
          .filter((key) => !beforeKeys.includes(key))
          .map((key) => (
            <TableRow key={key}>
              <TableCell>
                <Typography variant="caption" sx={{ color: 'success.main' }}>
                  {`+ ${_.startCase(key)}`}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption" sx={{ color: 'success.main' }}>
                  {asString(after[key])}
                </Typography>
              </TableCell>
              <TableCell />
            </TableRow>
          ))}
        {afterKeys // common keys are in after and before
          .filter((key) => beforeKeys.includes(key))
          .map((key) => (
            <TableRow key={key}>
              <TableCell>
                <Typography variant="caption">{_.startCase(key)}</Typography>
              </TableCell>
              <TableCell>
                <Typography
                  variant="caption"
                  sx={
                    after[key] !== before[key]
                      ? { color: 'success.main' }
                      : undefined
                  }
                >
                  {asString(after[key])}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  variant="caption"
                  sx={
                    after[key] !== before[key]
                      ? {
                          color: 'error.main',
                          textDecoration: 'line-through',
                        }
                      : undefined
                  }
                >
                  {asString(before[key])}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        {beforeKeys // deleted keys were in before but not after
          .filter((key) => !afterKeys.includes(key))
          .map((key) => (
            <TableRow key={key}>
              <TableCell>
                <Typography
                  variant="caption"
                  sx={
                    after[key] !== before[key]
                      ? {
                          color: 'error.main',
                          textDecoration: 'line-through',
                        }
                      : undefined
                  }
                >
                  {`- ${_.startCase(key)}`}
                </Typography>
              </TableCell>
              <TableCell />
              <TableCell>
                <Typography
                  variant="caption"
                  sx={
                    after[key] !== before[key]
                      ? {
                          color: 'error.main',
                          textDecoration: 'line-through',
                        }
                      : undefined
                  }
                >
                  {asString(before[key])}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
}

export default function Parameters({ entry }) {
  // LOAD has the query in entry.parameters.query
  // READ has the query in entry.parameters
  // Live has the filters in entry.parameters.advancedFilters, and search in .searchTexts
  const parameters = entry.parameters || {};
  const query = parameters.query || (isQuery(parameters) && parameters);
  const filters = query
    ? convertMongoQueryToFilters(query)
    : parameters.advancedFilters;
  const searches = parameters.searchTexts;
  const [showDialog, setShowDialog] = useState(false);
  const { before, after } = parameters;
  // test before/after CUD display
  // const before = {
  //   common1: 'old string',
  //   common2: {},
  //   common3: 'same',
  //   removed: 'this was removed',
  // };
  // const after = {
  //   added: 'this was added',
  //   common1: 'new string',
  //   common2: {},
  //   common3: 'same',
  // };

  const isInteresting = interesting(parameters) || filters || (before && after);

  return (
    <Fragment>
      {isInteresting && (
        <Fragment>
          <IconButton onClick={() => setShowDialog(true)} size="large">
            <ParametersIcon />
          </IconButton>

          <Dialog open={showDialog} fullWidth>
            <DialogTitle>{`${entry.action} ${entry.dataType} parameters`}</DialogTitle>
            <DialogContent>
              <List>
                {!searches && !filters && !(before && after) && (
                  <Fragment>
                    <ListSubheader disableGutters disableSticky>
                      Details
                    </ListSubheader>
                    <PlainDetails parameters={parameters} />
                  </Fragment>
                )}
                {before && after && (
                  <Fragment>
                    <ListSubheader disableGutters disableSticky>
                      Changes
                    </ListSubheader>
                    <BeforeAfter before={before} after={after} />
                  </Fragment>
                )}
                {searches && (
                  <Fragment>
                    <ListSubheader disableGutters disableSticky>
                      Search texts
                    </ListSubheader>
                    <SearchSummary searchTexts={searches} />
                  </Fragment>
                )}
                {filters && (
                  <Fragment>
                    <ListSubheader disableGutters disableSticky>
                      Filters
                    </ListSubheader>
                    <FilterSummary filters={filters} />
                  </Fragment>
                )}
                <Fragment>
                  <ListSubheader disableGutters disableSticky>
                    JSON
                  </ListSubheader>
                  <ListItem>{JSON.stringify(parameters)}</ListItem>
                </Fragment>
              </List>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setShowDialog(false)}>Ok</Button>
            </DialogActions>
          </Dialog>
        </Fragment>
      )}
    </Fragment>
  );
}
