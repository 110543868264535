import { createTheme } from '@mui/material';
import { blue, yellow } from '@mui/material/colors';

const theme = createTheme({
  palette: {
    primary: {
      light: blue[200],
      main: blue[500],
      dark: blue[800],
      background: blue[100],
    },
    secondary: {
      light: yellow.A200,
      main: yellow.A400,
      dark: yellow.A700,
    },
    background: {
      default: '#f9f9f9',
      paper: '#fff',
    },
    type: 'light',
  },
  overrides: {
    MuiTableRow: {
      root: {
        '&:last-child td': {
          borderBottom: 0,
        },
      },
      footer: {
        '& > td ': {
          fontSize: '110%',
          color: 'black',
          height: 30,
          minHeight: 30,
        },
        height: 30,
      },
    },
    MuiListItemText: {
      root: {
        width: '100%',
      },
      primary: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
      secondary: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
    },
  },
});

export default theme;
