import {
  Button,
  Paper,
  CardActions,
  CardHeader,
  Divider,
  ListSubheader,
  Menu,
  MenuItem,
  Box,
} from '@mui/material';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import _ from 'lodash';
import { useSnackbar } from '../Snackbar';
import { useAuth } from '../Auth';
import {
  CREATE_LOCATION,
  DELETE_LOCATION,
  FETCH_LOCATION,
  FETCH_LOCATION_SUCCESS,
  UPDATE_LOCATION,
  UPDATE_EVENTS_QUERY,
} from '../../actions';
import { locationTypes } from '../../data/constants';
import { ConfirmationDialog } from '../dialogs';
import {
  Field,
  AvatarField,
  TextField,
  SelectField,
  DateTimeField,
  GroupsField,
  GeometryField,
  required,
  requiredDateBefore,
  requiredDateAfter,
  StyledField,
} from '../fields';
import { BoundaryMap, Section, TagControl } from '../controls';
import { doesIdExist } from '../../apis/utilities';

const {
  showTranmanIdentifier,
  locationSubtypes,
  baseType,
  locationGroups,
  locationEditableFields,
} = window.config;

export default function Location() {
  const { id, locationType } = useParams();
  const dispatch = useDispatch();
  const location = useSelector((state) => state.locations.location, _.isEqual);
  const error = useSelector((state) => state.locations.error);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const auth = useAuth();
  const snackbar = useSnackbar();
  const canEdit = auth.isAuthorised('locations', true);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (error) {
      snackbar.notify('error', error);
    }
  }, [error, snackbar]);

  useEffect(() => {
    if (id === 'new') {
      dispatch({
        type: FETCH_LOCATION_SUCCESS,
        payload: null,
      });
    } else {
      dispatch({
        type: FETCH_LOCATION,
        payload: id,
      });
    }
  }, [id, dispatch]);

  async function onSubmit(values) {
    if (location) {
      dispatch({
        type: UPDATE_LOCATION,
        payload: values,
      });
    } else {
      const exists = await doesIdExist('locations', values.code);
      if (exists) {
        return { code: 'Exists' };
      }

      dispatch({
        type: CREATE_LOCATION,
        payload: values,
        navigate,
      });
    }
  }

  function handleDelete() {
    if (location) {
      dispatch({
        type: DELETE_LOCATION,
        payload: encodeURIComponent(location.code),
      });
    }
  }

  function isDisabled(fieldName) {
    return !(
      canEdit &&
      (locationEditableFields[fieldName] || location === null)
    );
  }

  function validate(values) {
    let errors = {};

    // start/end validation
    errors.startTime = requiredDateBefore(values.endTime)(values.startTime);
    errors.endTime = requiredDateAfter(values.startTime)(values.endTime);

    return errors;
  }

  const navigateToEvents = (eventType, pathname, type) => () => {
    dispatch({
      type: UPDATE_EVENTS_QUERY,
      payload: {
        eventType,
        query: {
          'location.code': {
            $in: [id],
          },
        },
      },
    });

    navigate(`/events/${type}/${pathname}`);
  };

  function handleEventsClick(event) {
    setAnchorEl(event.currentTarget);
  }
  function handleEventsClose() {
    setAnchorEl(null);
  }

  return (
    <Form
      initialValues={location || { type: locationTypes[locationType]?.name }}
      onSubmit={onSubmit}
      validate={validate}
      render={({
        handleSubmit,
        form: { reset },
        submitting,
        dirty,
        pristine,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <Helmet>
            <title>
              IR3 | {locationTypes[locationType].name}
              {values.name ? ` | ${values.name}` : ''}
            </title>
          </Helmet>
          <Paper sx={{ m: 1, minWidth: 240 }}>
            <CardHeader
              avatar={
                <Field
                  name="picture"
                  component={AvatarField}
                  icon={locationTypes[locationType].icon}
                  disabled={isDisabled('picture')}
                />
              }
              title={values.name || ''}
              subheader={values.code || ''}
            />
            <Box>
              <ListSubheader disableSticky>Key Information</ListSubheader>
              <Section>
                <StyledField
                  name="code"
                  component={TextField}
                  label="Code"
                  validate={required}
                  disabled={!(canEdit && location === null)}
                />
                <StyledField
                  name="name"
                  component={TextField}
                  label="Name"
                  validate={required}
                  disabled={isDisabled('name')}
                />
                {showTranmanIdentifier && values.type === baseType.label && (
                  <StyledField
                    name="tranmanIdentifier"
                    component={TextField}
                    label="Tranman Identifier"
                    disabled={isDisabled('tranmanIdentifier')}
                  />
                )}
                <StyledField
                  name="subtype"
                  component={SelectField}
                  label="Type"
                  values={
                    values.type && locationSubtypes[values.type]
                      ? locationSubtypes[values.type].sort()
                      : []
                  }
                  disabled={isDisabled('subtype')}
                />
              </Section>
              <Divider />
              <ListSubheader disableSticky>Active Time Period</ListSubheader>
              <Section>
                <StyledField
                  name="startTime"
                  component={DateTimeField}
                  label="Start Time"
                  maxDate={values.endTime || '2100-01-01'}
                  validate={required}
                  disabled={isDisabled('startTime')}
                />
                <StyledField
                  name="endTime"
                  component={DateTimeField}
                  label="End Time"
                  minDate={values.startTime || '1900-01-01'}
                  validate={required}
                  disabled={isDisabled('endTime')}
                />
              </Section>
              <Divider />
              <ListSubheader disableSticky>Groups & Areas</ListSubheader>
              <Section>
                <Field
                  name="groups"
                  groups={locationGroups}
                  component={GroupsField}
                  disabled={isDisabled('groups')}
                />
              </Section>
              <Divider />
              <ListSubheader disableSticky>Tags</ListSubheader>
              <Section>
                <TagControl item={{ id }} type={'locations'} sx={{ pb: 1.5 }} />
              </Section>
              <Divider />
              <ListSubheader disableSticky>Boundary</ListSubheader>
              {isDisabled('boundary') ? (
                <BoundaryMap boundary={values.boundary} />
              ) : (
                <Field
                  name="boundary"
                  component={GeometryField}
                  geoType="Polygon"
                  validate={required}
                />
              )}
            </Box>
            <CardActions disableSpacing>
              <Button
                color="primary"
                type="submit"
                disabled={pristine || submitting}
              >
                Save
              </Button>
              <Button
                color="primary"
                disabled={pristine || submitting}
                onClick={reset}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                aria-owns={anchorEl ? 'events-menu' : undefined}
                aria-haspopup="true"
                onClick={handleEventsClick}
                disabled={dirty || submitting || location === null}
              >
                Events
              </Button>
              <Menu
                id="events-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleEventsClose}
              >
                <MenuItem
                  onClick={navigateToEvents(
                    'vehicleLocationVisits',
                    'locationvisits',
                    'vehicles'
                  )}
                >
                  Vehicle Visits
                </MenuItem>
                <MenuItem
                  onClick={navigateToEvents(
                    'personLocationVisits',
                    'locationvisits',
                    'people'
                  )}
                >
                  Person Visits
                </MenuItem>
              </Menu>
              {canEdit && (
                <Button
                  color="error"
                  onClick={() => setDeleteOpen(true)}
                  disabled={location === null}
                >
                  Delete
                </Button>
              )}
            </CardActions>
          </Paper>
          <ConfirmationDialog
            action="Delete"
            open={deleteOpen}
            itemId={values.name || values.code}
            onOk={handleDelete}
            onCancel={() => setDeleteOpen(false)}
          />
        </form>
      )}
    />
  );
}
