import {
  Paper,
  Typography,
  IconButton,
  Toolbar,
  Menu,
  ListSubheader,
  MenuItem,
  Box,
} from '@mui/material';
import { PlayArrow as PlayArrowIcon } from '@mui/icons-material';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import Container from '../Container';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import {
  FETCH_ATTENDANCES,
  FETCH_ATTENDANCES_CANCELLED,
  UPDATE_ATTENDANCES_FILTER,
  UPDATE_ATTENDANCES_QUERY,
} from '../../actions';
import { FilterPicker, Parameters, Table, TablePagination } from '../controls';
import { useState, useEffect } from 'react';
import { useSnackbar } from '../Snackbar';
import { filterLocally } from '../../data/utilities';
import {
  GetApp as GetAppIcon,
  BarChart as GroupByIcon,
} from '@mui/icons-material';
import { downloadCSV } from '../../apis/utilities';
import {
  startOfDay,
  startOfHour,
  format,
  startOfWeek,
  isAfter,
  isBefore,
  addHours,
  addWeeks,
} from 'date-fns';
import { getKeyLabel } from '../../data/constants';
import { shortHumanizer } from '../../apis/utilities';

const {
  rowsPerPageOptions,
  events: { eventFilters: { attendances: eventFilters = [] } = {} } = {},
} = window.config;

function ReplayLink({ entry }) {
  const navigate = useNavigate();

  const handleViewClick = (identifier) => () => {
    navigate(`/replay/personObjectiveAttendances/${identifier}`);
  };

  return (
    <IconButton
      title="View"
      aria-label="View"
      onClick={handleViewClick(entry.identifier)}
      size="large"
    >
      <PlayArrowIcon />
    </IconButton>
  );
}

function AttendancePeriod({ entry, groupedBy }) {
  const [orderBy, setOrderBy] = useState('period');
  const [order, setOrder] = useState('asc');

  function handleOrderChange(order) {
    setOrder(order);
  }

  function handleOrderByChange(orderBy) {
    setOrderBy(orderBy);
    setOrder('asc');
  }

  const headers = [
    {
      label: '',
      key: 'expand',
      type: 'expand',
      component: Attendance,
      filter: false,
    },
  ];

  let data;
  switch (groupedBy) {
    case 'hourly':
      headers.push(
        { label: 'Hour', key: 'period', type: 'text', filter: false },
        {
          label: 'Compliant',
          key: 'hourCompliant',
          type: 'boolean',
          filter: false,
        }
      );
      data = entry.attendancesByHour;
      break;

    case 'daily':
      headers.push(
        { label: 'Date', key: 'period', type: 'dateonly', filter: false },
        {
          label: 'Compliant',
          key: 'dateCompliant',
          type: 'boolean',
          filter: false,
        }
      );
      data = entry.attendancesByDay;
      break;
    case 'driver':
      headers.push(
        {
          label: 'Collar Number',
          key: 'collarNumber',
          type: 'text',
          filter: false,
        },
        {
          label: 'Compliant #',
          key: 'compliantCount',
          type: 'text',
          filter: false,
        },
        {
          label: 'Non-Compliant #',
          key: 'nonCompliantCount',
          type: 'text',
          filter: false,
        },
        {
          label: 'Compliant Time',
          key: 'compliantCumulativeTime',
          type: 'text',
          filter: false,
        },
        {
          label: 'Non Compliant Time',
          key: 'nonCompliantCumulativeTime',
          type: 'text',
          filter: false,
        }
      );
      data = entry.attendancesByDriver;
      break;
    default:
      break;
  }

  return (
    <Table
      data={data}
      headers={headers}
      rowsPerPage={data.length}
      orderBy={orderBy}
      order={order}
      onOrderChange={handleOrderChange}
      onOrderByChange={handleOrderByChange}
      page={0}
      keyName="identifier"
      disableSticky
    />
  );
}

function Attendance({ entry }) {
  const [orderBy, setOrderBy] = useState('startTime');
  const [order, setOrder] = useState('asc');

  const areas = Array.from(
    new Set(entry.attendances.flatMap((record) => Object.keys(record.areas)))
  );

  const attendanceHeaders = [
    {
      label: 'Collar Number',
      key: 'person.collarNumber',
      type: 'text',
      filter: true,
    },
    {
      label: 'Forenames',
      key: 'person.forenames',
      type: 'text',
      filter: true,
    },
    {
      label: 'Surname',
      key: 'person.surname',
      type: 'text',
      filter: true,
    },
    {
      label: 'Role',
      key: 'person.role',
      type: 'text',
      filter: true,
    },
    ...areas.map((area) => ({
      label: _.startCase(area),
      key: ['areas', area],
      type: 'text',
      filter: true,
    })),
    {
      label: 'Start Time',
      key: 'startTime',
      type: 'date',
      filter: false,
    },
    {
      label: 'End Time',
      key: 'endTime',
      type: 'date',
      filter: false,
    },
    {
      label: 'Compliant',
      key: 'compliant',
      type: 'boolean',
      filter: true,
    },
    {
      label: '',
      key: 'replay',
      type: 'component',
      component: ReplayLink,
      filter: false,
    },
  ];

  function handleOrderChange(order) {
    setOrder(order);
  }

  function handleOrderByChange(orderBy) {
    setOrderBy(orderBy);
    setOrder('asc');
  }

  return (
    <Table
      data={entry.attendances}
      headers={attendanceHeaders}
      rowsPerPage={entry.attendances.length}
      page={0}
      keyName="identifier"
      orderBy={orderBy}
      order={order}
      onOrderChange={handleOrderChange}
      onOrderByChange={handleOrderByChange}
      disableSticky
    />
  );
}

const objectiveFilterHeaders = [
  {
    label: 'Objective Identifier',
    key: 'identifier',
    type: 'text',
    filter: true,
  },
  {
    label: 'Objective Title',
    key: 'title',
    type: 'text',
    filter: true,
  },
  {
    label: 'Objective Type',
    key: 'type',
    type: 'text',
    filter: true,
  },
];

function objectifyKey(headers) {
  return headers.map((header) => ({
    ...header,
    key: 'objective.' + header.key,
  }));
}

const attendanceFilterHeaders = (areas) => [
  {
    label: 'Collar Number',
    key: 'person.collarNumber',
    type: 'text',
    filter: true,
  },
  {
    label: 'Forenames',
    key: 'person.forenames',
    type: 'text',
    filter: true,
  },
  {
    label: 'Surname',
    key: 'person.surname',
    type: 'text',
    filter: true,
  },
  {
    label: 'Role',
    key: 'person.role',
    type: 'text',
    filter: true,
  },
  {
    label: 'Compliant',
    key: 'compliant',
    type: 'boolean',
    filter: true,
  },
  ...areas.map((area, index) => ({
    label: _.startCase(area),
    key: `person.areas.${index}.name`,
    type: 'text',
    filter: true,
  })),
];

export default function Attendances() {
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => state.events.attendances.isLoading);
  const error = useSelector((state) => state.events.attendances.error);

  const [groupMenuAnchor, setGroupMenuAnchor] = useState(null);

  function handleGroupMenuOpen(target) {
    setGroupMenuAnchor(target);
  }

  function handleGroupMenuClose() {
    setGroupMenuAnchor(null);
  }

  const groupByValues = ['frequency', 'driver'];

  const [groupedBy, setGroupedBy] = useState('frequency');

  function handleGroupByFieldChanged(groupedBy) {
    setGroupedBy(groupedBy);
    setGroupMenuAnchor(null);
  }

  const attendances = useSelector(
    (state) => state.events.attendances.list,
    _.isEqual
  );

  const areas = Array.from(
    new Set(
      attendances.flatMap((record) =>
        record.person.areas.map((area) => area.type)
      )
    )
  );

  const filter = useSelector(
    (state) => state.events.attendances.filter,
    _.isEqual
  );

  const query = useSelector(
    (state) => state.events.attendances.query,
    _.isEqual
  );

  function getGroupingForObjective(objective) {
    if (groupedBy === 'frequency') {
      return objective.requiredFrequency;
    } else {
      return groupedBy;
    }
  }

  const objectiveHeaders = [
    {
      label: '',
      key: 'expand',
      type: 'expand',
      component: ({ entry }) => {
        if (entry.requiredFrequency === 'total' && groupedBy === 'frequency') {
          return Attendance({ entry });
        } else {
          return AttendancePeriod({
            entry,
            groupedBy: getGroupingForObjective(entry),
          });
        }
      },
      filter: false,
    },
    {
      label: 'Identifier',
      key: 'identifier',
      type: 'text',
      filter: true,
    },
    {
      label: 'Title',
      key: 'title',
      type: 'text',
      filter: true,
    },
    {
      label: 'Start Time',
      key: 'startTime',
      type: 'date',
      filter: false,
    },
    {
      label: 'End Time',
      key: 'endTime',
      type: 'date',
      filter: false,
    },
    {
      label: '# Compliant',
      key: 'compliantCount',
      type: 'text',
      filter: false,
    },
    {
      label: '# Non-Compliant',
      key: 'nonCompliantCount',
      type: 'text',
      filter: false,
    },
    groupedBy === 'frequency' && {
      label: 'Achieved',
      key: 'objectiveAchieved',
      type: 'boolean',
      filter: false,
    },
  ];

  const snackbar = useSnackbar();

  useEffect(() => {
    if (error) {
      snackbar.notify('error', error);
    }
  }, [error, snackbar]);

  function getData() {
    const filteredAttendances = filterLocally(filter, attendances);

    function groupBy(objectArray, keyFunction) {
      return objectArray.reduce(function (acc, obj) {
        let key = keyFunction(obj);
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
      }, {});
    }

    const attendancesGroupedByObjective = Object.values(
      groupBy(filteredAttendances, (item) => item.objective.identifier)
    );

    function isTotalObjectiveAchieved(objective) {
      const compliantAttendances = objective.attendances.filter(
        (a) => a.compliant
      );
      return objective.requiredVisits <= compliantAttendances;
    }

    function isObjectiveAchieved(objective) {
      // special handling for total as the algorithm is much simpler and doesn't require any magic
      if (objective.requiredFrequency === 'total') {
        return isTotalObjectiveAchieved(objective);
      }

      // daily and hourly handled below
      const listOfStartDates = [];

      // generate an array of beginnings of all weeks that fall between startTime and endtime
      let startDate = new Date(objective.startTime);
      const endDate = new Date(objective.endTime);
      while (isBefore(startDate, endDate)) {
        let start = startOfWeek(startDate);
        listOfStartDates.push(start);
        startDate = addWeeks(startDate, 1);
      }
      startDate = new Date(objective.startTime);

      let hours;
      let normalizeTimeFunction;

      if (objective.requiredFrequency === 'hourly') {
        normalizeTimeFunction = (attendance) =>
          startOfHour(new Date(attendance.startTime)).getTime();
        // convert our schedule to a list of integers,
        // each representing number of hours since the beginning of the week when the attendance is expected to happen
        hours = objective.schedule
          .flat()
          .map((e, index) => {
            if (e) {
              return index;
            } else {
              return null;
            }
          })
          .filter((e) => e !== null);
      } else if (objective.requiredFrequency === 'daily') {
        normalizeTimeFunction = (attendance) =>
          startOfDay(new Date(attendance.startTime)).getTime();
        hours = objective.schedule
          .map((e, index) => {
            if (e.includes(true)) {
              // for daily we're only interested in beginning of days,
              // but to keep is consistent we still represent these in hours since the beginning of the week
              return index * 24;
            } else {
              return null;
            }
          })
          .filter((e) => e !== null);
      } else {
        return false;
      }

      const scheduleTimes = listOfStartDates.flatMap((startOfWeek) =>
        hours
          .map((hourSinceStartOfWeek) =>
            addHours(startOfWeek, hourSinceStartOfWeek)
          )
          .filter(
            (entry) => isAfter(entry, startDate) && isBefore(entry, endDate)
          )
          .map((entry) => entry.getTime())
      );

      // we use Map for a better lookup performance
      // keys in this map represent times where attendance is required, values represent number of required visits
      // (at the beginning values are identical for all keys and are equal to a requiredVisits property of the objective)
      const scheduleMap = new Map(
        scheduleTimes.map((hour) => [hour, +objective.requiredVisits])
      );

      objective.attendances.forEach((attendance) => {
        if (attendance.compliant) {
          const attendanceTimeNormalized = normalizeTimeFunction(attendance);
          const requiredVisitsLeft =
            scheduleMap.get(attendanceTimeNormalized) ?? 1;
          if (requiredVisitsLeft === 1) {
            // if it's the last required visit left
            // or the key wasn't found, which might happen when there are more attendances than required -> delete the key
            scheduleMap.delete(attendanceTimeNormalized);
          } else {
            // if there's more than 1 required visit left, just decrement
            scheduleMap.set(attendanceTimeNormalized, requiredVisitsLeft - 1);
          }
        }
      });

      // for achieved objectives, we expect the entire scheduleMap to be empty
      return scheduleMap.size === 0;
    }

    return attendancesGroupedByObjective.map((attendancesPerObjective) => {
      const objective = attendancesPerObjective[0].objective;
      const requiredCompliantVisitsCount = objective.requiredVisits;
      const compliantAttendancesCount = attendancesPerObjective.filter(
        (item) => item.compliant
      ).length;
      const nonCompliantAttendancesCount =
        attendancesPerObjective.length - compliantAttendancesCount;

      switch (getGroupingForObjective(objective)) {
        case 'hourly':
          const attendancesByHour = Object.entries(
            groupBy(attendancesPerObjective, (item) =>
              format(new Date(item.startTime), 'yyyy/MM/dd HH:00')
            )
          ).map(([period, attendances]) => ({
            period,
            hourCompliant:
              attendances.filter((attendance) => attendance.compliant).length >=
              requiredCompliantVisitsCount,
            attendances,
          }));
          const compliantHours = attendancesByHour.filter(
            (item) => item.hourCompliant
          ).length;
          const nonCompliantHours = attendancesByHour.length - compliantHours;
          return {
            ...objective,
            compliantCount: compliantHours,
            nonCompliantCount: nonCompliantHours,
            objectiveAchieved: isObjectiveAchieved({
              ...objective,
              attendances: attendancesPerObjective,
            }),
            attendancesByHour,
          };
        case 'daily':
          const attendancesByDay = Object.entries(
            groupBy(attendancesPerObjective, (item) =>
              startOfDay(new Date(item.startTime)).toISOString()
            )
          ).map(([key, attendances]) => ({
            period: new Date(key),
            dateCompliant:
              attendances.filter((attendance) => attendance.compliant).length >=
              requiredCompliantVisitsCount,
            attendances,
          }));
          const compliantDays = attendancesByDay.filter(
            (item) => item.dateCompliant
          ).length;
          const nonCompliantDays = attendancesByDay.length - compliantDays;
          return {
            ...objective,
            compliantCount: compliantDays,
            nonCompliantCount: nonCompliantDays,
            attendancesByDay,
            objectiveAchieved: isObjectiveAchieved({
              ...objective,
              attendances: attendancesPerObjective,
            }),
          };
        case 'total':
          return {
            ...objective,
            compliantCount: compliantAttendancesCount,
            nonCompliantCount: nonCompliantAttendancesCount,
            attendances: attendancesPerObjective,
            objectiveAchieved: isObjectiveAchieved({
              ...objective,
              attendances: attendancesPerObjective,
            }),
          };
        case 'driver':
          const attendancesByDriver = Object.entries(
            groupBy(attendancesPerObjective, (item) => item.person.collarNumber)
          ).map(([collarNumber, attendances]) => {
            const compliantCountForDriver = attendances.filter(
              (item) => item.compliant
            ).length;

            const nonCompliantCountForDriver =
              attendances.length - compliantCountForDriver;

            const compliantCumulativeTime = shortHumanizer(
              1000 *
                attendances
                  .filter((item) => item.compliant)
                  .map(
                    (compliantAttendance) => compliantAttendance.durationSeconds
                  )
                  .reduce((duration, acc) => duration + acc, 0)
            );

            const nonCompliantCumulativeTime = shortHumanizer(
              1000 *
                attendances
                  .filter((item) => !item.compliant)
                  .map(
                    (nonCompliantAttendance) =>
                      nonCompliantAttendance.durationMinutes
                  )
                  .reduce((duration, acc) => duration + acc, 0)
            );

            return {
              collarNumber,
              compliantCount: compliantCountForDriver,
              nonCompliantCount: nonCompliantCountForDriver,
              compliantCumulativeTime: compliantCumulativeTime,
              nonCompliantCumulativeTime: nonCompliantCumulativeTime,
              attendances,
            };
          });
          return {
            ...objective,
            compliantCount: compliantAttendancesCount,
            nonCompliantCount: nonCompliantAttendancesCount,
            attendancesByDriver,
          };
        default:
          return null;
      }
    });
  }

  function handleDownloadClick() {
    const filename = 'Objective Attendances.csv';
    const attendanceHeaders = [
      {
        label: 'Collar Number',
        key: 'person.collarNumber',
        type: 'text',
      },
      {
        label: 'Forenames',
        key: 'person.forenames',
        type: 'text',
      },
      {
        label: 'Surname',
        key: 'person.surname',
        type: 'text',
      },
      {
        label: 'Role',
        key: 'person.role',
        type: 'text',
      },
      ...areas.map((area) => ({
        label: _.startCase(area),
        key: area,
        type: 'text',
      })),
      {
        label: 'Start Time',
        key: 'startTime',
        type: 'date',
      },
      {
        label: 'End Time',
        key: 'endTime',
        type: 'date',
      },
      {
        label: 'Compliant',
        key: 'compliant',
        type: 'boolean',
      },
    ];

    const dataToDownload = attendances.map((attendance) => ({
      ...attendance,
      'person.collarNumber': attendance.person.collarNumber,
      'person.forenames': attendance.person.forenames,
      'person.surname': attendance.person.surname,
      'person.role': attendance.person.role,
      ...attendance.areas,
      startTime: new Date(attendance.startTime),
      endTime: new Date(attendance.endTime),
      compliant: attendance.compliant,
    }));

    downloadCSV(dataToDownload, filename, attendanceHeaders);
  }

  function handleQueryChange(query) {
    dispatch({
      type: UPDATE_ATTENDANCES_QUERY,
      payload: query,
    });
  }

  function handleFetch(event, query) {
    dispatch({
      type: FETCH_ATTENDANCES,
      payload: query,
    });
  }

  function handleCancel() {
    dispatch({
      type: FETCH_ATTENDANCES_CANCELLED,
    });
  }

  function updateFilter(update) {
    onFilterChange({
      ...filter,
      ...update,
    });
  }

  function onFilterChange(payload) {
    dispatch({
      type: UPDATE_ATTENDANCES_FILTER,
      payload,
    });
  }

  function handlePageChange(event, page) {
    updateFilter({ page });
  }

  function handleRowsPerPageChange(event) {
    updateFilter({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  }

  function handleOrderChange(order) {
    updateFilter({ order });
  }

  function handleOrderByChange(orderBy) {
    updateFilter({
      orderBy,
      order: 'asc',
    });
  }

  const groupedAttendanceData = getData();

  return (
    <Container title="Objective Attendances">
      <Parameters
        onFetch={handleFetch}
        onCancel={handleCancel}
        isFetching={isLoading}
        value={query}
        onChange={handleQueryChange}
        sx={{ mt: 1, width: 264 }}
        person
        objective
        eventFilters={eventFilters}
      />
      <Box
        sx={{
          flex: 1,
          height: 'calc(100vh - 48px)',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <Helmet>
          <title>IR3 | Objective Attendances</title>
        </Helmet>
        <Toolbar variant="dense" disableGutters sx={{ p: 1, pb: 0 }}>
          <Typography sx={{ flexGrow: 1 }} variant="subtitle1">
            Objective Attendances
          </Typography>
          <IconButton
            title="Group by"
            aria-owns={groupMenuAnchor ? 'date-menu' : undefined}
            aria-haspopup="true"
            onClick={(event) => handleGroupMenuOpen(event.target)}
          >
            <GroupByIcon />
          </IconButton>
          <Menu
            id="date-menu"
            anchorEl={groupMenuAnchor}
            open={Boolean(groupMenuAnchor)}
            onClose={() => handleGroupMenuClose()}
          >
            <ListSubheader disableSticky>Group by</ListSubheader>

            {Object.values(groupByValues).map((value) => (
              <MenuItem
                key={value}
                value={value}
                selected={value === groupedBy}
                onClick={() => handleGroupByFieldChanged(value)}
              >
                {getKeyLabel(value)}
              </MenuItem>
            ))}
          </Menu>
          <FilterPicker
            headers={objectifyKey(objectiveFilterHeaders).concat(
              attendanceFilterHeaders(areas)
            )}
            data={attendances}
            filter={filter}
            onFilterChange={onFilterChange}
          />
          <IconButton
            disabled={groupedAttendanceData.length === 0}
            title="Download"
            onClick={handleDownloadClick}
            size="large"
          >
            <GetAppIcon />
          </IconButton>
        </Toolbar>
        <Paper sx={{ m: [0, 1, 1], minWidth: 240 }}>
          <Table
            styles={{
              tableContainer: {
                height: 'calc(100vh - 172px)',
                overflowY: 'scroll',
              },
              table: {
                minWidth: 750,
              },
            }}
            data={groupedAttendanceData}
            headers={objectiveHeaders}
            rowsPerPage={filter.rowsPerPage}
            page={filter.page}
            order={filter.order}
            orderBy={filter.orderBy}
            onOrderChange={handleOrderChange}
            onOrderByChange={handleOrderByChange}
          />
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={groupedAttendanceData.length}
            rowsPerPage={filter.rowsPerPage}
            page={filter.page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </Paper>
      </Box>
    </Container>
  );
}
