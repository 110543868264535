import { mergeMap, map, catchError } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { ofType } from 'redux-observable';
import {
  FETCH_PLANS,
  FETCH_PLANS_SUCCESS,
  FETCH_PLANS_FAILURE,
  FETCH_PLAN,
  FETCH_PLAN_SUCCESS,
  FETCH_PLAN_FAILURE,
  CREATE_PLAN,
  CREATE_PLAN_SUCCESS,
  CREATE_PLAN_FAILURE,
  UPDATE_PLAN,
  UPDATE_PLAN_SUCCESS,
  UPDATE_PLAN_FAILURE,
  DELETE_PLAN,
  DELETE_PLAN_SUCCESS,
  DELETE_PLAN_FAILURE,
} from '../actions';
import api from '../apis';
import { getHeaders, log } from '../apis/utilities';

async function fetchPlansRequest() {
  const response = await api.get('/plans', {
    params: {
      projection: {
        identifier: true,
        title: true,
        type: true,
        description: true,
        items: true,
        created: true,
      },
      sort: { type: 1, title: 1 },
    },
    headers: getHeaders(),
  });

  log('Read', 'Plans', {});

  return response.data;
}

export function fetchPlansEpic(action$) {
  return action$.pipe(
    ofType(FETCH_PLANS),
    mergeMap(() =>
      from(fetchPlansRequest()).pipe(
        map((payload) => ({
          type: FETCH_PLANS_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_PLANS_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function fetchPlanRequest(id) {
  const response = await api.get(`/plans/${id}`, {
    params: {
      projection: {
        identifier: true,
        title: true,
        type: true,
        description: true,
        startTime: true,
        endTime: true,
        areas: true,
        briefs: true,
        boundary: true,
        subtype: true,
        created: true,
        lastEdit: true,
      },
    },
    headers: getHeaders(),
  });

  log('Read', 'Plan', { id });

  return response.data;
}

export function fetchPlanEpic(action$) {
  return action$.pipe(
    ofType(FETCH_PLAN),
    mergeMap(({ payload: id }) =>
      from(fetchPlanRequest(id)).pipe(
        map((payload) => ({
          type: FETCH_PLAN_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_PLAN_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function createPlanRequest(values, navigate) {
  const response = await api.post(`/plans`, values, {
    headers: getHeaders(),
  });

  navigate(`../${response.data.identifier}`, {
    replace: true,
    state: { created: true },
  });

  return response.data;
}

export function createPlanEpic(action$) {
  return action$.pipe(
    ofType(CREATE_PLAN),
    mergeMap(({ payload: values, navigate }) =>
      from(createPlanRequest(values, navigate)).pipe(
        map((payload) => ({
          type: CREATE_PLAN_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: CREATE_PLAN_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function updatePlanRequest(values) {
  await api.patch(
    `/plans/${values.identifier}`,
    {
      ...values,
    },
    {
      headers: {
        ...getHeaders(),
        'Content-Type': 'application/merge-patch+json',
      },
    }
  );

  return values;
}

export function updatePlanEpic(action$) {
  return action$.pipe(
    ofType(UPDATE_PLAN),
    mergeMap(({ payload: values }) =>
      from(updatePlanRequest(values)).pipe(
        map((payload) => ({
          type: UPDATE_PLAN_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: UPDATE_PLAN_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function deletePlanRequest(id, navigate) {
  await api.delete(`/plans/${id}`, {
    params: {
      permanent: true,
    },
    headers: getHeaders(),
  });
  navigate('.');
  return id;
}

export function deletePlanEpic(action$) {
  return action$.pipe(
    ofType(DELETE_PLAN),
    mergeMap(({ payload: id, navigate }) =>
      from(deletePlanRequest(id, navigate)).pipe(
        map((payload) => ({
          type: DELETE_PLAN_SUCCESS,
          payload: decodeURIComponent(payload),
        })),
        catchError(({ message: payload }) =>
          of({
            type: DELETE_PLAN_FAILURE,
            payload,
          })
        )
      )
    )
  );
}
