import {
  Avatar,
  Box,
  Button,
  Collapse,
  Divider,
  IconButton,
  InputAdornment,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {
  Add as AddIcon,
  ArrowUpward as ArrowUpwardIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material';
import _ from 'lodash';
import { format } from 'date-fns';
import { useState, useEffect } from 'react';
import { useAuth } from '../Auth';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, Outlet } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Helmet } from 'react-helmet-async';
import { FixedSizeList } from 'react-window';
import { GeoJSON, WKT } from 'ol/format';
import { FETCH_LOCATIONS } from '../../actions';
import { locationTypes } from '../../data/constants';
import Container from '../Container';
import {
  downloadCSV,
  downloadGeoJSON,
  getLocations,
} from '../../apis/utilities';
import { NavLink, SearchBox } from '../controls';

const locationHeaders = [
  { label: 'Code', key: 'code' },
  { label: 'Name', key: 'name' },
  { label: 'Type', key: 'type' },
  { label: 'Subtype', key: 'subtype' },
  { label: 'Start Time', key: 'startTime' },
  { label: 'End Time', key: 'endTime' },
  { label: 'Boundary WKT', key: 'boundaryWkt' },
];

async function getLocationsAndHeaders(type) {
  const data = await getLocations(type);

  const areas = Array.from(
    new Set(
      [].concat(
        ...data.map((record) => (record.areas || []).map((area) => area.type))
      )
    )
  );

  const locations = data.map(
    ({ areas, boundary, startTime, endTime, ...vehicle }) => {
      const geometry = new GeoJSON().readGeometry(boundary, {
        // featureProjection: 'EPSG:3857',
      });

      const boundaryWkt = new WKT().writeGeometry(geometry, {
        dataProjection: 'EPSG:4326',
        rightHanded: true,
      });

      return {
        ...vehicle,
        boundaryWkt,
        startTime: startTime
          ? format(new Date(startTime), 'dd/MM/yyyy HH:mm:ss')
          : '',
        endTime: endTime
          ? format(new Date(endTime), 'dd/MM/yyyy HH:mm:ss')
          : '',
        ...(areas || []).reduce((accumulator, area) => {
          if (area.name !== undefined) {
            accumulator[area.type] = area.name;
          }

          return accumulator;
        }, {}),
      };
    }
  );

  return {
    locations,
    headers: [
      ...locationHeaders,
      ...areas.map((area) => ({
        label: _.startCase(area),
        key: area,
        type: 'text',
      })),
    ],
  };
}

function Row({ data, index, style }) {
  const [list, id, locationType] = data;
  const item = list[index];

  return (
    <ListItemButton
      dense
      key={index}
      style={style}
      component={NavLink}
      to={`/locations/${locationType}/${encodeURIComponent(item.code)}`}
      selected={id === item.code}
    >
      <ListItemAvatar>
        <Avatar src={item.picture}>{locationTypes[locationType].icon}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={item.name}
        secondary={item.code}
        primaryTypographyProps={{ noWrap: true }}
        secondaryTypographyProps={{ noWrap: true }}
      />
    </ListItemButton>
  );
}

export default function LocationList() {
  const { id, locationType } = useParams();
  const dispatch = useDispatch();
  const locations = useSelector(
    (state) => state.locations.locations,
    _.isEqual
  );
  const [searchText, setSearchText] = useState('');
  const [showSettings, setShowSettings] = useState(false);
  const [sortBy, setSortBy] = useState('name');
  const [sortDesc, setSortDesc] = useState(false);
  const isXs = useMediaQuery((theme) => theme.breakpoints.only('xs'));
  const auth = useAuth();
  const canCreate = auth.isAuthorised('locations', true);

  const sortOptions = [
    { label: 'Name', value: 'name' },
    { label: 'Code', value: 'code' },
  ];

  useEffect(() => {
    dispatch({
      type: FETCH_LOCATIONS,
      payload: locationTypes[locationType].name,
    });
  }, [dispatch, locationType]);

  const filteredList = _.orderBy(
    locations.filter(
      (item) =>
        item.searchString.indexOf(searchText.toLowerCase()) > -1 ||
        searchText === ''
    ),
    [sortBy],
    [sortDesc ? 'desc' : 'asc']
  );

  function handleSearchChange(event) {
    setSearchText(event.target.value);
  }

  function handleSettingsToggle() {
    setShowSettings(!showSettings);
  }

  function handleSortByChange(event) {
    setSortBy(event.target.value);
  }

  function handleSortToggle() {
    setSortDesc(!sortDesc);
  }

  async function handleCsvClick() {
    const data = await getLocationsAndHeaders(locationTypes[locationType].name);

    downloadCSV(data.locations, 'locations.csv', data.headers);
  }

  async function handleGeoJsonClick() {
    const data = await getLocations(locationTypes[locationType].name);

    downloadGeoJSON(data, 'locations.json');
  }

  return (
    <Container
      title={`${locationTypes[locationType].name}s`}
      showBack={isXs && id}
    >
      <Box sx={{ display: 'flex', height: 1, width: 1 }}>
        <Helmet>
          <title>{`IR3 | ${locationTypes[locationType].name}s`}</title>
        </Helmet>
        {(!isXs || !id) && (
          <Box
            sx={(theme) => ({
              display: 'flex',
              flexDirection: 'column',
              width: 280,
              [theme.breakpoints.down('sm')]: {
                width: 1,
              },
              height: 1,
            })}
          >
            <Box>
              <Stack
                direction="row"
                sx={{ p: 1, pr: 0.5 }}
                spacing={0.5}
                alignItems="center"
              >
                <SearchBox
                  value={searchText}
                  onChange={handleSearchChange}
                  count={`${filteredList.length}/${locations.length}`}
                  sx={{ flexGrow: 1 }}
                />
                <IconButton
                  size="small"
                  title={showSettings ? 'Hide settings' : 'Show settings'}
                  onClick={handleSettingsToggle}
                >
                  <SettingsIcon
                    fontSize="inherit"
                    color={showSettings ? 'primary' : 'inherit'}
                  />
                </IconButton>
                {canCreate && (
                  <IconButton
                    title="Add new"
                    component={Link}
                    to={`/locations/${locationType}/new`}
                    size="small"
                  >
                    <Avatar
                      sx={{
                        color: 'secondary.contrastText',
                        backgroundColor: 'secondary.main',
                        width: 24,
                        height: 24,
                        fontSize: 16,
                      }}
                    >
                      <AddIcon fontSize="inherit" />
                    </Avatar>
                  </IconButton>
                )}
              </Stack>
              <Collapse in={showSettings} unmountOnExit>
                <Stack sx={{ p: 1 }} spacing={1.5}>
                  <TextField
                    size="small"
                    select
                    fullWidth
                    label="Sort by"
                    value={sortBy}
                    onChange={handleSortByChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <IconButton
                            title={sortDesc ? 'Descending' : 'Ascending'}
                            sx={(theme) =>
                              sortDesc
                                ? {
                                    transform: 'rotate(180deg)',
                                    transition: theme.transitions.create(
                                      'transform',
                                      {
                                        duration:
                                          theme.transitions.duration.shortest,
                                      }
                                    ),
                                  }
                                : {
                                    transform: 'rotate(0deg)',
                                    transition: theme.transitions.create(
                                      'transform',
                                      {
                                        duration:
                                          theme.transitions.duration.shortest,
                                      }
                                    ),
                                  }
                            }
                            onClick={handleSortToggle}
                            size="small"
                          >
                            <ArrowUpwardIcon fontSize="inherit" />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  >
                    {sortOptions.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Stack>
                <Stack sx={{ px: 1 }} spacing={1.5}>
                  <Divider>
                    <Typography variant="subtitle2" color="textSecondary">
                      Export
                    </Typography>
                  </Divider>
                  <Stack
                    direction="row"
                    // sx={{ p: 1 }}
                    spacing={1.5}
                    justifyContent="center"
                  >
                    <Button
                      fullWidth
                      color="primary"
                      variant="contained"
                      disableElevation
                      onClick={handleCsvClick}
                    >
                      CSV
                    </Button>
                    <Button
                      fullWidth
                      color="secondary"
                      variant="contained"
                      disableElevation
                      onClick={handleGeoJsonClick}
                    >
                      GeoJSON
                    </Button>
                  </Stack>
                  <Divider />
                </Stack>
              </Collapse>
            </Box>
            {filteredList.length > 0 && (
              <Box sx={{ height: 1 }}>
                <AutoSizer>
                  {({ width, height }) => (
                    <FixedSizeList
                      width={width}
                      height={height}
                      overscanCount={10}
                      itemData={[filteredList, id, locationType]}
                      itemCount={filteredList.length}
                      itemSize={56}
                    >
                      {Row}
                    </FixedSizeList>
                  )}
                </AutoSizer>
              </Box>
            )}
          </Box>
        )}
        {(!isXs || id) && (
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              // width: 1,
              height: 1,
              overflow: 'auto',
            }}
          >
            <Outlet />
          </Box>
        )}
      </Box>
    </Container>
  );
}
