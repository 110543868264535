import {
  Button,
  Paper,
  CardActions,
  CardHeader,
  Divider,
  ListSubheader,
  FormHelperText,
  FormControl,
  Input,
  Box,
} from '@mui/material';
import { DirectionsCar as CarIcon } from '@mui/icons-material';
import moment from 'moment';
import { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Form } from 'react-final-form';
import _ from 'lodash';
import { useSnackbar } from '../Snackbar';
import { useAuth } from '../Auth';
import {
  CREATE_VEHICLE,
  DELETE_VEHICLE,
  FETCH_HOME_STATIONS,
  FETCH_VEHICLE,
  FETCH_VEHICLE_SUCCESS,
  UPDATE_VEHICLE,
} from '../../actions';
import { ConfirmationDialog } from '../dialogs';
import {
  Field,
  AvatarField,
  TextField,
  SelectField,
  GroupsField,
  DateTimeField,
  isDateValidOrEmpty,
  AutosuggestField,
  StyledField,
} from '../fields';
import { doesIdExist, imeiValid } from '../../apis/utilities';
import TelematicsBoxVehicles from './TelematicsBoxList/TelematicsBoxVehicles';
import { useCommissioningDialog } from './TelematicsBoxList/CommissioningDialog';
import VehicleKeyFields from './VehicleKeyFields';

import { uniqueLabelValues } from './utilities';
import { Section } from '../controls';

const {
  vehicleTypes,
  vehicleFuelTypes,
  vehicleGroups,
  vehicleEditableFields,
  useDallasKeys,
} = window.config;

const NO_DRIVER_ID = 'Not installed';

export default function ReducedVehicle() {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const vehicles = useSelector((state) => state.vehicles.vehicles) ?? [];
  const vehicle = useSelector((state) => state.vehicles.vehicle, _.isEqual);
  const error = useSelector((state) => state.vehicles.error);
  const homeStationNames = useSelector(
    (state) => state.locations.homeStationNames,
    _.isEqual
  );
  const [deleteOpen, setDeleteOpen] = useState(false);
  const auth = useAuth();
  const snackbar = useSnackbar();
  const canEdit = auth.isAuthorised('vehicles', true);
  const canCommission = auth.getAuthorisation().commissioning.write && canEdit;
  const commissioningDialog = useCommissioningDialog();
  const installLocations = uniqueLabelValues(
    vehicles,
    (v) => v.installLocation
  );
  const driverIdLocations = uniqueLabelValues(
    vehicles,
    (v) => v.driverIdLocation
  );

  useEffect(() => {
    if (error) {
      snackbar.notify('error', error);
    }
  }, [error, snackbar]);

  useEffect(() => {
    if (id === 'new') {
      dispatch({
        type: FETCH_VEHICLE_SUCCESS,
        payload: null,
      });
    } else {
      dispatch({
        type: FETCH_VEHICLE,
        payload: id,
      });
    }
    dispatch({
      type: FETCH_HOME_STATIONS,
    });
  }, [id, dispatch]);

  async function onSubmit(values) {
    const validImei = await imeiValid(
      values.telematicsBoxImei,
      values.identificationNumber
    );
    if (!validImei) {
      return { telematicsBoxImei: 'In use' };
    }

    if (vehicle) {
      dispatch({
        type: UPDATE_VEHICLE,
        payload: values,
      });
    } else {
      const exists = await doesIdExist('vehicles', values.identificationNumber);
      if (exists) {
        return { identificationNumber: 'Exists' };
      }

      dispatch({
        type: CREATE_VEHICLE,
        payload: {
          ...values,
          navigate,
        },
      });
    }
  }

  function handleDelete() {
    if (vehicle) {
      dispatch({
        type: DELETE_VEHICLE,
        payload: vehicle.identificationNumber,
      });
    }
  }

  function isDisabled(fieldName) {
    return !(canEdit && (vehicleEditableFields[fieldName] || vehicle === null));
  }

  const noVehicle = vehicle === null;
  const needToRemoveAssociation = !!vehicle?.telematicsBoxImei;

  return (
    <Form
      initialValues={vehicle || {}}
      onSubmit={onSubmit}
      render={({
        handleSubmit,
        form: { reset },
        submitting,
        dirty,
        pristine,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <Paper sx={{ m: 1, minWidth: 240 }}>
            <Helmet>
              <title>
                IR3 | Vehicle
                {values.fleetNumber ? ` | ${values.fleetNumber}` : ''}
              </title>
            </Helmet>
            <CardHeader
              avatar={
                <Field
                  name="picture"
                  component={AvatarField}
                  icon={<CarIcon />}
                  disabled={isDisabled('picture')}
                />
              }
              title={values.fleetNumber}
              subheader={values.identificationNumber}
            />
            <Box>
              <ListSubheader disableSticky>Key Information</ListSubheader>
              <Section>
                <VehicleKeyFields vehicle={vehicle} />
              </Section>
              <Divider />
              <ListSubheader disableSticky>Details</ListSubheader>
              <Section>
                <StyledField
                  name="type"
                  component={SelectField}
                  label="Type"
                  values={vehicleTypes}
                  disabled={isDisabled('type')}
                />
                <StyledField
                  name="fuelType"
                  component={SelectField}
                  label="Fuel Type"
                  values={vehicleFuelTypes}
                  disabled={isDisabled('fuelType')}
                />
              </Section>
              <Divider />
              <ListSubheader disableSticky>Home</ListSubheader>
              <Section>
                <StyledField
                  name="homeStation"
                  component={SelectField}
                  label="Location"
                  values={homeStationNames.map((station) => ({
                    label: station.name,
                    value: station.code,
                  }))}
                  disabled={isDisabled('homeStation')}
                />
              </Section>
              <Divider />
              <ListSubheader disableSticky>Groups & Areas</ListSubheader>
              <Section>
                <Field
                  name="groups"
                  groups={vehicleGroups}
                  component={GroupsField}
                  disabled={isDisabled('groups')}
                />
              </Section>
              <Divider />
              <ListSubheader disableSticky>Telematics Box</ListSubheader>
              <Box sx={{ px: 2 }}>
                <TelematicsBoxVehicles
                  identificationNumber={vehicle?.identificationNumber}
                />
                <Section sx={{ pt: 1 }}>
                  <StyledField
                    label="Last Poll Time"
                    name="lastPollTime"
                    component={TextField}
                    format={(value) =>
                      value ? moment(value).fromNow() : 'never'
                    }
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  {canCommission && (
                    <FormControl>
                      <Input
                        disableUnderline
                        inputComponent={() => (
                          <Button
                            color="primary"
                            variant="contained"
                            disabled={
                              noVehicle || needToRemoveAssociation || dirty
                            } // save vehicle info first so no conflict
                            onClick={() =>
                              commissioningDialog.show({
                                vehicle,
                                redirect: false,
                              })
                            }
                          >
                            commission new
                          </Button>
                        )}
                      />
                      <FormHelperText>
                        {(() => {
                          if (noVehicle || dirty) {
                            return 'Must save vehicle first';
                          } else if (needToRemoveAssociation) {
                            return 'Remove existing association first';
                          }
                        })()}
                      </FormHelperText>
                    </FormControl>
                  )}
                </Section>
              </Box>
              <Section>
                <StyledField
                  name="installLocation"
                  suggestions={installLocations}
                  label="Unit Location"
                  component={AutosuggestField}
                  freeSolo
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                />
                <StyledField
                  name="driverIdLocation"
                  suggestions={driverIdLocations.concat({
                    label: NO_DRIVER_ID,
                    value: NO_DRIVER_ID,
                  })}
                  label={`${
                    useDallasKeys ? 'Dallas Key' : 'RFID Card'
                  } Reader Location`}
                  component={AutosuggestField}
                  freeSolo
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                />
                <StyledField
                  name="commissionDate"
                  component={DateTimeField}
                  label="Commission Date"
                  validate={isDateValidOrEmpty}
                />
              </Section>
              <Divider />
              <ListSubheader disableSticky>Notes</ListSubheader>
              <Section>
                <Field
                  fullWidth
                  sx={{ mb: 1.5 }}
                  name="notes"
                  placeholder="Add notes..."
                  component={TextField}
                  multiline
                  minRows={4}
                />
              </Section>
              <Divider />
              <ListSubheader disableSticky>Odometer</ListSubheader>
              <Section>
                <StyledField
                  name="lastOdometerReading.time"
                  component={DateTimeField}
                  label="Reading Date & Time"
                  disabled={isDisabled('lastOdometerReading')}
                  validate={isDateValidOrEmpty}
                />
                <StyledField
                  name="lastOdometerReading.distanceMiles"
                  type="number"
                  component={TextField}
                  label="Reading Miles"
                  disabled={isDisabled('lastOdometerReading')}
                />
              </Section>
              <Divider />
              <ListSubheader disableSticky>Disposed</ListSubheader>
              <Section>
                <StyledField
                  name="disposalDate"
                  component={DateTimeField}
                  label="Date & Time"
                  disabled={isDisabled('disposalDate')}
                  validate={isDateValidOrEmpty}
                />
              </Section>
            </Box>
            <CardActions>
              {canEdit && (
                <Fragment>
                  <Button
                    color="primary"
                    // type="submit"
                    onClick={handleSubmit}
                    disabled={pristine || submitting}
                  >
                    Save
                  </Button>
                  <Button
                    color="primary"
                    disabled={pristine || submitting}
                    onClick={reset}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="error"
                    onClick={() => setDeleteOpen(true)}
                    disabled={vehicle === null}
                  >
                    Delete
                  </Button>
                </Fragment>
              )}
            </CardActions>
          </Paper>
          <ConfirmationDialog
            action="Delete"
            open={deleteOpen}
            itemId={values.registrationNumber || values.fleetNumber}
            onOk={handleDelete}
            onCancel={() => setDeleteOpen(false)}
          />
        </form>
      )}
    />
  );
}
