import { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  FormControlLabel,
  Switch,
  Box,
} from '@mui/material';
import {
  SortVariantLock as VirtualiseMapOnIcon,
  SortVariantLockOpen as VirtualiseMapOffIcon,
  // MapLegend as VirtualiseMapIcon,
} from 'mdi-material-ui';
import { tooManyMapItems } from './constants';

const storageKey = 'do_not_show_retro_map_virtualisation_dialog';
const dontShow = window.localStorage.getItem(storageKey);

export default function MapVirtualisationDialog({ layers = [] }) {
  const [open, setOpen] = useState(false);
  const [on, setOn] = useState(true);
  const [neverShow, setNeverShow] = useState(false);
  const openedAlready = window.sessionStorage.getItem(storageKey);

  function handleClose() {
    setOpen(false);
    window.sessionStorage.setItem(storageKey, true);

    if (neverShow) {
      window.localStorage.setItem(storageKey, true);
    }
  }

  useEffect(() => {
    if (!dontShow && !openedAlready) {
      if (layers.some(tooManyMapItems)) {
        setOpen(true);
      }
    }
  }, [layers, openedAlready]);

  return (
    <Dialog onClose={handleClose} open={!openedAlready && open}>
      <DialogTitle>Locked map items to list for performance</DialogTitle>
      <DialogContent>
        <Box sx={{ m: 2 }}>
          One or more layers have fetched a large number of items. Showing too
          many items on the map at once may cause the map to slow down or become
          unresponsive.
        </Box>
        <Box sx={{ m: 2 }}>
          When this happens the layer will automatically lock the map items to
          the results shown in the items list. As you scroll down the items
          list, the map items shown will update to those seen.
        </Box>
        <Box sx={{ m: 2 }}>
          This behaviour can be turned on or off with the lock-map-to-list
          button:
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          <IconButton
            color={!on ? 'warning' : 'default'}
            onClick={() => setOn((o) => !o)}
            size="large"
          >
            {on ? <VirtualiseMapOnIcon /> : <VirtualiseMapOffIcon />}
          </IconButton>
        </Box>
      </DialogContent>
      <DialogActions>
        <FormControlLabel
          sx={{ ml: 1 }}
          control={
            <Switch
              checked={neverShow}
              onChange={() => setNeverShow((n) => !n)}
              name="checkedA"
            />
          }
          label="Don't show again"
        />

        <Box style={{ flex: 1 }} />
        <Button onClick={handleClose} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
