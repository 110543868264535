// bug with select and WebGLMap: https://github.com/openlayers/openlayers/issues/9688
// not going to be fixed apparently
// import { Select as SelectInteraction } from 'ol/interaction';

import {
  List,
  ListItem,
  Avatar,
  ListItemText,
  ListSubheader,
  useMediaQuery,
  Box,
} from '@mui/material';
import AvatarGroup from '@mui/material/AvatarGroup';
import _ from 'lodash';

import { typeIcons } from './constants';
import {
  statusIconColoursByType,
  commonResourceColours,
} from '../../data/constants';
import { clipPathForType } from './LiveList/avatarUtility';

const {
  locationTypes = [
    'Police Station',
    'Workshop',
    'Base',
    'Hospital',
    'Court',
    'Ward',
  ],
} = window.config.liveOptions;

function difference(objA, objB) {
  if (!objA) {
    return {};
  }

  let copy = { ...objA };

  Object.keys(objB).forEach((key) => delete copy[key]);

  return copy;
}

const {
  isFleet,
  liveOptions: {
    vehiclesHaveCallsigns = true,
    showCallsignsOnLegend = true, // default true
  },
} = window.config;

function andJoin(strings) {
  return strings
    .map((s) => s.toLowerCase())
    .map(_.startCase)
    .join(', ')
    .replace(/, ([^,]*)$/, ' and $1');
}

function sectionLength(s) {
  return s.types?.length ?? Object.keys(s.statusColours).length;
}

function assembleLegend(authorisedTypes) {
  const callSigns = showCallsignsOnLegend ? 'callSigns' : undefined;
  // start with callsigns, then vehicles (- callsigns), then people (-callsigns),
  // then the others
  let statusColoursForIcons = [
    {
      statusColours: commonResourceColours,
      icons: isFleet
        ? []
        : vehiclesHaveCallsigns
        ? [callSigns, 'vehicles', 'people'].filter(Boolean)
        : [callSigns, 'people'].filter(Boolean),
    },
    {
      statusColours: difference(
        statusIconColoursByType['vehicle'],
        commonResourceColours
      ),
      icons: ['vehicles', 'MOTORCYCLE'],
      authType: 'objectives', // override auth checking for MOTORCYCLE
    },
    {
      statusColours: difference(
        statusIconColoursByType['person'],
        commonResourceColours
      ),
      icons: ['people'],
    },
    {
      // title: 'Incidents (number is grade)',
      title: 'Incidents',
      statusColours: statusIconColoursByType['incident'],
      icons: ['incidents'],
    },
    {
      title: 'Objectives',
      statusColours: statusIconColoursByType['objective'],
      icons: ['objectives'],
      authType: 'objectives', // override auth checking for Patrol, Visit etc
    },
    {
      title: 'Locations',
      types: locationTypes,
      icons: ['locations', 'Workshop'],
      authType: 'locations', // override auth checking for sub types
    },
    {
      title: 'Events',
      types: ['Accelerometer'],
      icons: ['events'],
      authType: 'events', // override auth checking for sub types
    },
    {
      title: 'Plans',
      types: ['Plan'], // could have subtypes but collections/features is totally broken atm
      icons: ['plans'],
      authType: 'plans', // override auth checking for sub types
    },
    // {
    //   title: 'Common',
    //   statusColours: statusIconColoursByType.default,
    //   icons: [
    //     'vehicles',
    //     'people',
    //     'locations',
    //     'objectives',
    //     'plans',
    //     'events',
    //   ],
    // },
  ];

  // go through these and eliminate the unauthorised types
  statusColoursForIcons.forEach((s) => {
    // if a list has a general auth type use that
    if (!!s.authType) {
      if (!authorisedTypes.includes(s.authType)) {
        s.icons = [];
      }
    } else {
      s.icons = s.icons.filter((i) => authorisedTypes.includes(i));
    }
  });

  // filter out the ones that have no icons left, or have no status/types
  statusColoursForIcons = statusColoursForIcons.filter(
    (s) => s.icons.length > 0 && sectionLength(s) > 0
  );

  // if any have the same icons left, merge them
  let coloursByUniqueIcons = {};
  statusColoursForIcons.forEach((s) => {
    const key = s.icons.join();
    // if it exists, merge it
    if (coloursByUniqueIcons[key]) {
      coloursByUniqueIcons[key] = {
        ...coloursByUniqueIcons[key],
        statusColours: {
          ...(coloursByUniqueIcons[key].statusColours || {}),
          ...s.statusColours,
        },
      };
    } else {
      // otherwise add it as new
      coloursByUniqueIcons[key] = s;
    }
  });
  statusColoursForIcons = Object.values(coloursByUniqueIcons);

  // sort these by # of colours so hopefully legend looks ok
  return _.orderBy(statusColoursForIcons, sectionLength, ['asc']);
}

export default function LiveLegend({ authorisedTypes }) {
  const legend = assembleLegend(authorisedTypes);

  let columns = 1;
  if (useMediaQuery((theme) => theme.breakpoints.up('sm'))) {
    columns++;
  }
  if (useMediaQuery((theme) => theme.breakpoints.up('md'))) {
    columns++;
  }
  if (useMediaQuery((theme) => theme.breakpoints.up('lg'))) {
    columns++;
  }

  let table = [...Array(columns)].map((a) => []);
  let section = legend.pop();
  while (section) {
    // find whichever has the min columns
    let min = undefined;
    let minIndex = 0;
    for (let i = 0; i < columns; i++) {
      const height = table[i].reduce((total, s) => total + sectionLength(s), 0);
      min = min ?? height;
      if (height < min) {
        min = height;
        minIndex = i;
      }
    }

    // add this section to that
    table[minIndex].push(section);
    section = legend.pop();
  }

  function iconify(key) {
    const ti = typeIcons;
    const Icon = ti[key];
    if (Icon) {
      return <Icon sx={{ width: 16, height: 16 }} />;
    }

    return key;
  }

  const itemHeight = 48;
  const max = table.reduce((max, col) => {
    const height =
      col.length + col.reduce((total, s) => total + sectionLength(s), 0);
    return height > max ? height : max;
  }, 0);
  const maxHeight = itemHeight * max;

  function getAvatar({ key, type, elementKey, colours, content }) {
    const [borderColor, background, color] = colours;

    // if there's a clipPath it's an odd shape and we'll have to
    // fudge an outline/border as css border won't work
    const clipPath =
      clipPathForType(key) ||
      clipPathForType(type, key) ||
      clipPathForType(type);

    return (
      <Avatar
        variant={clipPath ? 'square' : 'circular'}
        key={elementKey}
        style={{
          borderColor,
          background: clipPath ? 'unset' : background,
          color,
          border: clipPath ? 'revert' : [2, 'solid', borderColor],
          width: clipPath ? 32 : 28,
          height: clipPath ? 32 : 28,
        }}
      >
        {clipPath ? (
          <svg width="32px" height="32px">
            <g>
              <g>
                <rect
                  style={{ clipPath }}
                  height="32px"
                  width="32px"
                  fill={borderColor}
                ></rect>
              </g>
              <g transform="translate(2,2)">
                <rect
                  style={{ clipPath }}
                  height="28px"
                  width="28px"
                  fill={background}
                ></rect>
              </g>
            </g>
            {typeof content === 'string' ? (
              <g transform="scale(1,1),translate(10,22)">
                <text fill={color}>{content}</text>
              </g>
            ) : (
              <g transform="scale(.5,.5),translate(16,17)">{content}</g>
            )}
          </svg>
        ) : (
          content
        )}
      </Avatar>
    );
  }

  return (
    <Box sx={{ display: 'flex' }}>
      {table.map((column, i) => (
        <List
          key={i}
          dense
          sx={{ display: 'flex', flexFlow: 'column wrap', overflow: 'auto' }}
          style={maxHeight ? { maxHeight } : {}}
        >
          {column.map((section, i) => (
            <Box key={i}>
              <ListSubheader sx={{ lineHeight: '40px' }} disableSticky>
                {section.title || andJoin(section.icons)}
              </ListSubheader>
              {section.types &&
                section.types.map((key) => {
                  const mainType = section.icons[0];

                  return (
                    <ListItem key={key}>
                      {getAvatar({
                        key,
                        type: mainType,
                        elementKey: key,
                        colours:
                          section.statusColours?.[key] ||
                          statusIconColoursByType.default.default,
                        content: iconify(typeIcons[key] ? key : mainType),
                      })}
                      <ListItemText sx={{ ml: 1 }} primary={_.startCase(key)} />
                    </ListItem>
                  );
                })}
              {section.statusColours &&
                Object.keys(section.statusColours).map((key) => {
                  return (
                    <ListItem key={key}>
                      <AvatarGroup max={7}>
                        {(section.content || section.icons).map((i) => {
                          const mainType = section.content ?? section.icons[0];

                          return getAvatar({
                            key: i,
                            type: mainType,
                            elementKey: i + key,
                            colours: section.statusColours[key],
                            content: iconify(i),
                          });
                        })}
                      </AvatarGroup>
                      <ListItemText sx={{ ml: 1 }} primary={_.startCase(key)} />
                    </ListItem>
                  );
                })}
            </Box>
          ))}
        </List>
      ))}
    </Box>
    // <ResponsiveMasonry
    //   columnsCountBreakPoints={{ 250: 1, 500: 2, 750: 3, 1000: 4 }}
    // >
    //   <Masonry>
    //   </Masonry>
    // </ResponsiveMasonry>
  );
}
