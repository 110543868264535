import {
  IconButton,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemText,
  FormControlLabel,
  Checkbox,
  Divider,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  ListSubheader,
  Box,
} from '@mui/material';
import { Save as SaveIcon, Public as GlobalIcon } from '@mui/icons-material';
import { FilterVariantRemove as ClearFiltersIcon } from 'mdi-material-ui';
import { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import { alpha } from '@mui/material/styles';
import {
  UPDATE_LIVE_ADVANCED_FILTERS,
  UPDATE_LIVE_VIEW_KEY,
  UPDATE_LIVE_SORTS,
  UPDATE_LIVE_LAYER_VISIBILITIES,
  UPDATE_LIVE_SETTINGS,
  FETCH_LIVE_VIEWS,
  ADD_LIVE_VIEW,
  DELETE_LIVE_VIEW,
  UPDATE_LIVE_FOLLOW_OVERRIDE,
  UPDATE_LIVE_FILTER_OVERRIDE,
  FETCH_LIVE_VIEWS_SUCCESS,
} from '../../actions';
import { useAuth } from '../Auth';
import { usePrevious } from '../../hooks';
import FilterSummary from './FilterSummary';
import { ConfirmationDialog } from '../dialogs';

// const exampleFilter = {
//   title: 'CLEAR ALL',
//   tab: 'callSigns',
//   filters: {},
//   mapLayers: ['people', 'callSigns'],
//   global: true,
//   // application: 'Live',
//   identifier: 'FILTERSDEFAULT',
//   user: 'always defined',
// };

function SaveFilterDialog({
  open,
  onSave,
  onDelete,
  onClose,
  identifier,
  filters = {},
  layerVisibilities = {},
  title: initialTitle,
  global: initialGlobal,
  tab: initialTab,
  authorisedTypes,
  canEditGlobal = false,
  allViews,
  user,
}) {
  const [title, setTitle] = useState(initialTitle);
  const [global, setGlobal] = useState(initialGlobal);
  const [tab, setTab] = useState(initialTab);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showOverwriteConfirmation, setShowOverwriteConfirmation] =
    useState(false);

  function filterFullyDefined(f) {
    return (
      !!f.field &&
      (Array.isArray(f.value)
        ? f.value.length > 0
        : typeof f.value !== 'undefined' && f.value !== null)
    );
  }

  const filtersWithSomeDefined = Object.keys(filters).filter((type) =>
    filters[type].some(filterFullyDefined)
  );

  function selectedMapLayersAsString() {
    const allLayerKeys = authorisedTypes.filter(
      (key) => !['tags', 'callSigns'].includes(key)
    );
    const shownLayerKeys = allLayerKeys.filter(
      (key) =>
        typeof layerVisibilities[key] === 'undefined' || layerVisibilities[key]
    );

    if (allLayerKeys.length === shownLayerKeys.length) {
      return '(All)';
    }

    if (shownLayerKeys.length === 0) {
      return '(None)';
    }

    return shownLayerKeys
      .map((key) => _.startCase(key))
      .join(', ')
      .replace(/, ([^,]*)$/, ' and $1');
  }

  function doubleCheckDelete() {
    setShowDeleteConfirmation(true);
  }

  const noFilters = filtersWithSomeDefined.length === 0;
  const existingView = allViews.find(
    (v) =>
      v.identifier !== (identifier || -1) &&
      v.title.toLowerCase() === title?.toLowerCase()
  );
  const overwriting = typeof existingView !== 'undefined';
  // save only if not overwriting, I own it, or it's global and I can edit global
  const allowedToEdit =
    !overwriting ||
    existingView.user === user ||
    (existingView.global && canEditGlobal);

  let helperText = undefined;
  switch (true) {
    case !title:
      helperText = 'Please enter a title';
      break;
    case overwriting:
      helperText = 'Warning: another view has this title';
      break;
    default:
      break;
  }

  return (
    <Dialog open={open} onClose={onClose} sx={{ width: 1 }} fullWidth>
      <DialogTitle id="alert-dialog-title">{`Save Filters`}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {noFilters
            ? `No filters defined`
            : `Review the current view before saving`}
        </DialogContentText>
        {noFilters ? (
          <Fragment />
        ) : (
          <List>
            <ListSubheader disableSticky>View</ListSubheader>
            <ListItem sx={{ display: 'flex', alignItems: 'flex-end' }}>
              <Box style={{ display: 'flex' }}>
                <TextField
                  autoFocus
                  id="name"
                  label="Title"
                  type="text"
                  value={title}
                  error={!!helperText}
                  helperText={helperText}
                  fullWidth
                  onChange={(event) => setTitle(event.target.value)}
                />
                {/* <FormGroup row> */}
                <FormControl sx={{ width: 250, mx: 1 }}>
                  <InputLabel shrink id="default-tab">
                    Default Tab
                  </InputLabel>
                  <Select
                    labelId="default-tab"
                    id="default-tab"
                    value={tab || ''}
                    onChange={(e) => {
                      setTab(e.target.value);
                    }}
                    displayEmpty
                  >
                    <MenuItem value="">
                      <em>Not set</em>
                    </MenuItem>
                    {(authorisedTypes || [])
                      .filter((t) => t !== 'tags')
                      .map((type) => (
                        <MenuItem key={type} value={type}>
                          {_.startCase(type)}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                {/* </FormGroup> */}
                {canEditGlobal && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={!!global}
                        onChange={() => {
                          setGlobal(!global);
                        }}
                        name="Global"
                      />
                    }
                    label="Global"
                  />
                )}
              </Box>
            </ListItem>
            <Divider />
            <ListSubheader disableSticky>Map Layers Shown</ListSubheader>
            <ListItem>
              <ListItemText
                disableTypography
                sx={{ px: 4, fontSize: '.725 em' }}
                primary={selectedMapLayersAsString()}
              />
            </ListItem>
            <Divider />
            <ListSubheader disableSticky>Filter Summary</ListSubheader>
            <FilterSummary filters={filters} />
          </List>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          color={overwriting ? 'error' : 'primary'}
          disabled={!title?.trim() || noFilters || !allowedToEdit}
          onClick={() =>
            !overwriting
              ? onSave(title, global, tab)
              : setShowOverwriteConfirmation(true)
          }
          autoFocus
        >
          {overwriting ? 'Overwrite' : 'Save'}
        </Button>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          disabled={!identifier || !allowedToEdit}
          onClick={doubleCheckDelete}
          color="error"
        >
          Delete
        </Button>
        <ConfirmationDialog
          action="Overwrite"
          open={showOverwriteConfirmation}
          itemId={title}
          onOk={() => {
            onDelete(existingView.identifier);
            onSave(title, global, tab);
            onClose();
          }}
          onCancel={() => setShowOverwriteConfirmation(false)}
        />
        <ConfirmationDialog
          action="Delete"
          open={showDeleteConfirmation}
          itemId={title}
          onOk={() => {
            onDelete(identifier);
            onClose();
          }}
          onCancel={() => setShowDeleteConfirmation(false)}
        />
      </DialogActions>
    </Dialog>
  );
}

function viewByIdentifier(liveViews, identifier) {
  if (!identifier || typeof identifier === 'undefined') {
    return null;
  }

  return (liveViews || []).find((f) => f.identifier === identifier);
}
const {
  liveCanEditViewsGroup,
  liveCanEditGlobalViewsGroup,
  liveCanEditViewsGroups,
  liveCanEditGlobalViewsGroups,
} = window.config;

export default function LiveViews({ authorisedTypes }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { type } = useParams();
  const user = localStorage.getItem('username');

  const [selected, setSelected] = useState(null);
  const [showSaveDialog, setShowSaveDialog] = useState(false);
  const [dropdownIdentifier, setDropdownIdentifier] = useState('');

  const selectedLiveViewIdentifier = useSelector(
    (state) => state.live.selectedLiveViewIdentifier
  );
  const liveViews = useSelector((state) => state.live.liveViews);
  const nothingSelectedText = liveViews
    ? 'No view selected'
    : 'Loading views...';
  const currentFilters = useSelector((state) => state.live.advancedFilters);
  const currentSorts = useSelector((state) => state.live.sorts);
  const currentShowLabels = useSelector(
    (state) => state.live.settings.showLabels
  );
  const currentLayerVisibilities = useSelector(
    (state) => state.live.layerVisibilities
  );
  const temporaryOverrideInPlace = useSelector(
    (state) => state.live.filteredInIdsByTypeOverride
  );

  const auth = useAuth();
  function checkAuth(group) {
    if (Array.isArray(group)) {
      return group.some((g) => auth.getGroupNames()?.includes(g));
    }

    return !!group && auth.getGroupNames()?.includes(group);
  }
  const canEdit = checkAuth(liveCanEditViewsGroup ?? liveCanEditViewsGroups);
  const canEditGlobal = checkAuth(
    liveCanEditGlobalViewsGroup ?? liveCanEditGlobalViewsGroups
  );

  // when it loads, get all the liveViews
  useEffect(() => {
    dispatch({
      type: FETCH_LIVE_VIEWS,
    });
  }, [dispatch]);

  // if our set was modified, we may need to reselect the one we edited/created
  const prevViews = usePrevious(liveViews);
  useEffect(() => {
    if (liveViews && prevViews !== liveViews) {
      // if it just loaded
      if (!prevViews && selectedLiveViewIdentifier) {
        const prevView = viewByIdentifier(
          liveViews,
          selectedLiveViewIdentifier
        );

        if (prevView) {
          setSelected(prevView);
          setDropdownIdentifier(selectedLiveViewIdentifier);
        }
      } else if (
        selected &&
        !viewByIdentifier(liveViews, selected.identifier)
      ) {
        // or if it was one we just saved/edited we need to reselect it sorta
        // as it may not have had an identifier until now
        const found = liveViews.find((f) => f.title === selected.title);

        if (found) {
          setSelected(found);
          setDropdownIdentifier(found.identifier);

          dispatch({
            type: UPDATE_LIVE_VIEW_KEY,
            payload: found.identifier,
          });
        }
      }

      // if (found) {
      //   setValue(found);
      // }
    }
  }, [liveViews, prevViews, selected, selectedLiveViewIdentifier, dispatch]);

  function saveLiveView(title, global = false, tab) {
    const liveView = {
      title: title || selected?.title,
      identifier: selected?.identifier,
      filters: currentFilters,
      sorts: currentSorts,
      showLabels: currentShowLabels,
      layerVisibilities: currentLayerVisibilities,
      global,
      tab,
    };

    // the ADD_LIVE_VIEW will also update the list via an epic,
    // but let's do it ahead of time so the user isn't waiting
    dispatch({
      type: FETCH_LIVE_VIEWS_SUCCESS,
      payload: [
        ...liveViews.filter((v) => v.identifier !== selected?.identifier),
        {
          ...liveView,
          identifier: selected?.identifier || 'TEMP',
        },
      ],
    });
    setSelected(liveView);

    // this will overwrite what we have above anyways
    dispatch({
      type: ADD_LIVE_VIEW,
      payload: liveView,
    });

    closeSaveDialog();
  }

  function deleteLiveView(view) {
    if (!view) {
      return;
    }

    dispatch({
      type: DELETE_LIVE_VIEW,
      payload: view,
    });

    setSelected(null);
    setDropdownIdentifier('');
  }

  function applySelectedView({
    identifier,
    filters,
    sorts,
    layerVisibilities,
    tab,
    showLabels,
  }) {
    dispatch({
      type: UPDATE_LIVE_VIEW_KEY,
      payload: identifier,
    });

    dispatch({
      type: UPDATE_LIVE_ADVANCED_FILTERS,
      payload: filters,
    });

    if (sorts) {
      dispatch({
        type: UPDATE_LIVE_SORTS,
        payload: sorts,
      });
    }

    if (layerVisibilities) {
      dispatch({
        type: UPDATE_LIVE_LAYER_VISIBILITIES,
        payload: layerVisibilities,
      });
    }

    dispatch({
      type: UPDATE_LIVE_SETTINGS,
      payload: {
        showLabels,
      },
    });

    if (!!tab) {
      navigate(`/live/${tab}`);
    }
  }

  function handleSelect({ target: { value: identifier } }) {
    const selected = (liveViews || []).find((f) => f.identifier === identifier);

    if (selected) {
      applySelectedView(selected);
      setSelected(selected);
      setDropdownIdentifier(identifier);
    } else {
      setSelected(null);
      setDropdownIdentifier('');
    }
  }

  function openSaveDialog() {
    setShowSaveDialog(true);
  }

  function wipeView() {
    applySelectedView({
      identifier: undefined,
      title: '',
      filters: {},
      sorts: {},
      layerVisibilities: {},
      tab: undefined,
    });

    dispatch({
      type: UPDATE_LIVE_FOLLOW_OVERRIDE,
      payload: null,
    });

    dispatch({
      type: UPDATE_LIVE_FILTER_OVERRIDE,
      payload: null,
    });

    setSelected(null);
    setDropdownIdentifier('');
  }

  function closeSaveDialog() {
    setShowSaveDialog(false);
  }

  function onlyHidden(layerVisibilities) {
    let result = {};
    Object.keys(layerVisibilities).forEach((type) => {
      if (layerVisibilities[type] === false) {
        result[type] = false;
      }
    });
    return result;
  }
  const modified =
    selected &&
    !_.isEqual(
      {
        filters: selected.filters,
        sorts: selected.sorts,
        showLabels: selected.showLabels,
        layerVisibilities: onlyHidden(selected.layerVisibilities),
        tab: !!selected.tab ? selected.tab : type,
      },
      {
        filters: currentFilters,
        sorts: currentSorts,
        showLabels: currentShowLabels,
        layerVisibilities: onlyHidden(currentLayerVisibilities),
        tab: type,
      }
    );

  const userIsOwner = selected?.user === user;
  const selectedIsGlobal = selected?.global === true;
  const allowedToSave =
    liveViews &&
    !temporaryOverrideInPlace &&
    (!selected || userIsOwner || (selectedIsGlobal && canEditGlobal));

  return (
    // <Paper sx={classes.toolbar}>
    <Box
      sx={{
        width: 1,
        minHeight: 48,
        maxHeight: 48,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}
    >
      <IconButton color="inherit" onClick={wipeView} size="large">
        <ClearFiltersIcon />
      </IconButton>
      <FormControl
        variant="standard"
        sx={(theme) => ({
          width: 1,
          borderRadius: 1,
          bgcolor: alpha(theme.palette.common.black, 0.15),
          '&:hover': {
            bgcolor: alpha(theme.palette.common.black, 0.1),
          },
          pl: 1,
        })}
      >
        <Select
          disabled={!liveViews}
          id="select-view"
          value={dropdownIdentifier}
          displayEmpty
          // onChange={handleSelect}
          disableUnderline
          sx={{
            color: 'common.white',
            '& .MuiSvgIcon-root': {
              color: 'white',
            },
          }}
          renderValue={
            dropdownIdentifier === ''
              ? undefined
              : (value) =>
                  `${modified ? '*' : ''}${
                    viewByIdentifier(liveViews, value)?.title || ''
                  }`
          }
        >
          <MenuItem
            value=""
            onClick={() => handleSelect({ target: { value: '' } })}
          >
            <em>{nothingSelectedText}</em>
          </MenuItem>
          {_.orderBy(liveViews || [], 'title').map((view) => (
            <MenuItem
              key={view.identifier}
              value={view.identifier}
              onClick={() =>
                handleSelect({ target: { value: view.identifier } })
              }
            >
              <Box
                sx={{
                  display: 'flex',
                  width: 1,
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  height: 24,
                }}
              >
                <Box
                  sx={{
                    maxWidth: 200,
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                >
                  {view.title}
                </Box>
                <Box sx={{ height: 24 }}>
                  {canEdit && view.global && <GlobalIcon />}
                </Box>
              </Box>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {canEdit && (
        <Fragment>
          <IconButton
            disabled={!allowedToSave}
            onClick={openSaveDialog}
            size="large"
            color="inherit"
          >
            <SaveIcon />
          </IconButton>
          <SaveFilterDialog
            key={selected?.identifier}
            open={showSaveDialog}
            onSave={saveLiveView}
            onDelete={(identifier) =>
              deleteLiveView(
                viewByIdentifier(liveViews, identifier) || selected
              )
            }
            onClose={closeSaveDialog}
            identifier={selected?.identifier}
            filters={currentFilters}
            layerVisibilities={currentLayerVisibilities}
            title={selected?.title}
            tab={selected?.tab}
            global={selected?.global}
            // these aren't game changers so no need to review when saving view
            // sorts={currentSorts}
            // showLabels={currentShowLabels}
            authorisedTypes={authorisedTypes}
            canEditGlobal={canEditGlobal}
            allViews={liveViews || []}
            user={user}
          />
        </Fragment>
      )}
    </Box>
  );
}
