import 'react-app-polyfill/stable';
import {
  CssBaseline,
  ThemeProvider,
  StyledEngineProvider,
} from '@mui/material';
import { AdapterDateFns as DateAdapter } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { HelmetProvider } from 'react-helmet-async';
import enLocale from 'date-fns/locale/en-GB';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import 'typeface-roboto';
import App from './components/App';
import { AuthProvider } from './components/Auth';
import { OptionsProvider } from './components/options';
import store from './store';
import theme from './theme';
import './css/index.css';
const { sentry, basename } = window.config;

// connect app to the Sentry project
if (sentry.enable) {
  Sentry.init({
    dsn: sentry.dsn,
    integrations: [new Integrations.BrowserTracing()],
    environment: sentry.environment,
    tracesSampleRate: sentry.tracesSampleRate,
    release: `v${process.env.REACT_APP_VERSION}`,
  });
}
// MUI pickers dateFormats docs: https://mui.com/x/api/date-pickers/localization-provider/
// date-fns parse docs https://date-fns.org/docs/parse
const dateFormats = {
  year: 'yyyy',
  fullDateTime24h: 'dd/MM/yyyy, HH:mm',
  hours24h: 'HH:mm',
  fullDate: 'dd/MM/yyyy',
};

ReactDOM.render(
  <Provider store={store}>
    <HelmetProvider>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <LocalizationProvider
            dateAdapter={DateAdapter}
            adapterLocale={enLocale}
            dateFormats={dateFormats}
          >
            <CssBaseline />
            <BrowserRouter basename={basename}>
              <AuthProvider>
                <OptionsProvider>
                  <App />
                </OptionsProvider>
              </AuthProvider>
            </BrowserRouter>
          </LocalizationProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </HelmetProvider>
  </Provider>,
  document.getElementById('root')
);
