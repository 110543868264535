import {
  Avatar,
  Box,
  IconButton,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  useMediaQuery,
} from '@mui/material';
import { Add as AddIcon, More as MoreIcon } from '@mui/icons-material';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, Outlet } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import _ from 'lodash';
import { FETCH_TAGS } from '../../actions';
import Container from '../Container';
import { NavLink, SearchBox } from '../controls';

export default function TagList() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const tags = useSelector((state) => state.tags.tags, _.isEqual);
  const [filterText, setFilterText] = useState('');
  const isXs = useMediaQuery((theme) => theme.breakpoints.only('xs'));

  useEffect(() => {
    dispatch({
      type: FETCH_TAGS,
    });
  }, [dispatch]);

  function renderListItems() {
    const sortedNames = tags
      .filter(
        (tag) =>
          `${tag.identifier}`.toLowerCase().indexOf(filterText.toLowerCase()) >
            -1 || filterText === ''
      )
      .sort((a, b) => `${a.identifier}`.localeCompare(`${b.identifier}`));
    return sortedNames.map((tag) => (
      <ListItemButton
        dense
        key={tag.identifier}
        to={`/tags/${encodeURIComponent(tag.identifier)}`}
        component={NavLink}
        selected={id === tag.identifier}
      >
        <ListItemAvatar>
          <Avatar>
            <MoreIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={tag.identifier}
          secondary={''}
          primaryTypographyProps={{ noWrap: true }}
          secondaryTypographyProps={{ noWrap: true }}
        />
      </ListItemButton>
    ));
  }

  function handleFilterChange(event) {
    setFilterText(event.target.value);
  }
  return (
    <Container title="Tags" showBack={isXs && id}>
      <Helmet>
        <title>IR3 | Tags</title>
      </Helmet>
      {(!isXs || !id) && (
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            width: 280,
            [theme.breakpoints.down('sm')]: {
              width: 1,
            },
            height: 1,
          })}
        >
          <Stack
            direction="row"
            sx={{ p: 1, pr: 0.5 }}
            spacing={0.5}
            alignItems="center"
          >
            <SearchBox
              value={filterText}
              onChange={handleFilterChange}
              sx={{ flexGrow: 1 }}
            />
            <IconButton
              title="Add new"
              component={Link}
              to={'/tags/new'}
              size="small"
            >
              <Avatar
                sx={{
                  color: 'secondary.contrastText',
                  backgroundColor: 'secondary.main',
                  width: 24,
                  height: 24,
                  fontSize: 16,
                }}
              >
                <AddIcon fontSize="inherit" />
              </Avatar>
            </IconButton>
          </Stack>
          <List
            dense
            disablePadding
            sx={{
              height: 'calc(100vh - 96px)',
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
          >
            {renderListItems()}
          </List>
        </Box>
      )}
      {(!isXs || id) && (
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            // width: 1,
            height: 1,
            overflow: 'auto',
          }}
        >
          <Outlet />
        </Box>
      )}
    </Container>
  );
}
