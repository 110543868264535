import { Chip, Avatar, Typography, useTheme, Box } from '@mui/material';
import { amber } from '@mui/material/colors';
import {
  Error as ErrorIcon,
  Input as InputIcon,
  Navigation as NavigationIcon,
  VolumeUp as VolumeUpIcon,
  VpnKey as VpnKeyIcon,
  Warning as WarningIcon,
  WbIncandescent as WbIncandescentIcon,
} from '@mui/icons-material';
import RvHookupIcon from '@mui/icons-material/RvHookup';
import {
  AlarmLight as AlarmLightIcon,
  CarSide as CarSideIcon,
  Engine as EngineIcon,
  RadioHandheld as RadioHandheldIcon,
  CarLightHigh as HeadlightsFlashIcon,
  // Speedometer as SpeedometerIcon,
} from 'mdi-material-ui';
import _ from 'lodash';
import moment from 'moment';
import { Fragment, cloneElement } from 'react';
import LiveListItem, { getIfNotAlreadyShown } from './LiveListItem';

const {
  useReducedResourceInformation,
  dioStates,
  speedRuleLabels = {},
} = window.config;

function VehicleListItem({ item: vehicle, tertiaryPath, ...props }) {
  const {
    driverName,
    speedKilometresPerHour,
    headingDegrees,
    ignitionOn,
    sirensOn,
    beaconsOn,
    headlightsFlashOn,
    malfunctionIndicatorLightOn,
    accelerometerAlert,
    rearBlueLightsOn,
    rearRedLightsOn,
    strikeButtonOn,
    frontPWEOn,
    rearPWEOn,
    airwaveOn,
    ancillaryEquipmentOn,
    amberLightsOn,
    trailerOn,
    currentSpeedRules,
  } = vehicle;

  const speedRulesTooltip = currentSpeedRules
    ?.map((r) => speedRuleLabels?.[r] ?? r)
    .join(', ');

  const theme = useTheme();
  const labelOverridePath =
    window.config.liveOptions?.labelOverridePaths?.['vehicles'];
  const primaryOverride = _.get(vehicle, labelOverridePath);

  const primaryPath = useReducedResourceInformation
    ? 'fleetNumber'
    : 'registrationNumber';
  let shownPaths = [
    primaryPath,
    'speedKilometresPerHour',
    'driverName',
    'headingDegrees',
    'ignitionOn',
  ].concat(Object.keys(dioStates));
  let tertiaryValue = primaryOverride
    ? _.get(vehicle, primaryPath)
    : getIfNotAlreadyShown(vehicle, tertiaryPath, shownPaths);

  // if the value is a date, format it for local time - do simple fast tests first for iso
  if (tertiaryValue?.length === 24 && tertiaryValue[23] === 'Z') {
    const date = moment(tertiaryValue);
    const format = date.isSame(new Date(), 'day')
      ? 'HH:mm:ss'
      : date.isSame(new Date(), 'year')
      ? 'DD/MM HH:mm:ss'
      : 'DD/MM/YYYY HH:mm:ss';
    if (date.isValid()) {
      tertiaryValue = date.format(format);
    }
  }

  let speedLimit = undefined;
  const knownLimit = !(vehicle.reverseGeocode?.unknownLimit ?? true);
  if (knownLimit) {
    speedLimit = Math.round(
      vehicle.reverseGeocode?.speedLimitKilometresPerHour * 0.62137119
    );
  }

  const secondaryComponent = (
    <Fragment>
      {
        <Box style={{ display: 'flex' }}>
          <Box style={{ fontStyle: ignitionOn ? undefined : 'italic' }}>
            {driverName}
          </Box>
          <Typography
            title={tertiaryValue}
            sx={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              flex: 1,
              textAlign: 'right',
              fontSize: 12,
              ml: 2,
            }}
          >
            {tertiaryValue}
          </Typography>
        </Box>
      }
      {
        <Box style={{ display: 'flex' }}>
          <NavigationIcon
            // fromCharCode so degrees symbol doesn't stop chrome debugger mapping
            titleAccess={'Heading ' + headingDegrees + String.fromCharCode(176)}
            sx={{ fontSize: 16, verticalAlign: 'text-top', mr: 0.5 }}
            style={{
              transform: `rotate(${headingDegrees}deg)`,
            }}
            color="action"
          />
          {ignitionOn !== undefined && (
            <VpnKeyIcon
              titleAccess={'Ignition ' + (ignitionOn ? 'on' : 'off')}
              sx={{ fontSize: 16, verticalAlign: 'text-top', mr: 0.5 }}
              htmlColor={
                ignitionOn ? amber[700] : theme.palette.action.disabled
              }
            />
          )}
          {sirensOn !== undefined && (
            <VolumeUpIcon
              titleAccess={'Siren ' + (sirensOn ? 'on' : 'off')}
              sx={{ fontSize: 16, verticalAlign: 'text-top', mr: 0.5 }}
              htmlColor={sirensOn ? amber[700] : theme.palette.action.disabled}
            />
          )}
          {beaconsOn !== undefined && (
            <AlarmLightIcon
              titleAccess={'Beacons ' + (beaconsOn ? 'on' : 'off')}
              sx={{ fontSize: 16, verticalAlign: 'text-top', mr: 0.5 }}
              htmlColor={beaconsOn ? amber[700] : theme.palette.action.disabled}
            />
          )}
          {headlightsFlashOn !== undefined && (
            <HeadlightsFlashIcon
              titleAccess={
                'Headlights flash ' + (headlightsFlashOn ? 'on' : 'off')
              }
              sx={{ fontSize: 16, verticalAlign: 'text-top', mr: 0.5 }}
              htmlColor={
                headlightsFlashOn ? amber[700] : theme.palette.action.disabled
              }
            />
          )}
          {malfunctionIndicatorLightOn !== undefined && (
            <EngineIcon
              titleAccess={
                'Malfunction Indicator Light ' +
                (malfunctionIndicatorLightOn ? 'on' : 'off')
              }
              sx={{ fontSize: 16, verticalAlign: 'text-top', mr: 0.5 }}
              htmlColor={
                malfunctionIndicatorLightOn
                  ? amber[700]
                  : theme.palette.action.disabled
              }
            />
          )}
          {accelerometerAlert !== undefined && (
            <WarningIcon
              sx={{ fontSize: 16, verticalAlign: 'text-top', mr: 0.5 }}
              htmlColor={
                accelerometerAlert ? amber[700] : theme.palette.action.disabled
              }
            />
          )}
          {rearBlueLightsOn !== undefined && (
            <WbIncandescentIcon
              titleAccess={
                'Rear Blue Lights ' + (rearBlueLightsOn ? 'on' : 'off')
              }
              sx={{ fontSize: 16, verticalAlign: 'text-top', mr: 0.5 }}
              color={rearBlueLightsOn ? 'primary' : 'disabled'}
            />
          )}
          {rearRedLightsOn !== undefined && (
            <WbIncandescentIcon
              titleAccess={
                'Rear Red Lights ' + (rearRedLightsOn ? 'on' : 'off')
              }
              sx={{ fontSize: 16, verticalAlign: 'text-top', mr: 0.5 }}
              color={rearRedLightsOn ? 'error' : 'disabled'}
            />
          )}
          {strikeButtonOn !== undefined && (
            <ErrorIcon
              sx={{ fontSize: 16, verticalAlign: 'text-top', mr: 0.5 }}
              htmlColor={
                strikeButtonOn ? amber[700] : theme.palette.action.disabled
              }
            />
          )}
          {(frontPWEOn !== undefined || rearPWEOn !== undefined) && (
            <CarSideIcon
              sx={{ fontSize: 16, verticalAlign: 'text-top', mr: 0.5 }}
              component={(svgProps) => {
                return (
                  <svg {...svgProps}>
                    <defs>
                      <linearGradient id="gradient1">
                        <stop
                          offset="50%"
                          stopColor={
                            rearPWEOn
                              ? theme.palette.primary.dark
                              : theme.palette.action.disabled
                          }
                        />
                        <stop
                          offset="50%"
                          stopColor={
                            frontPWEOn
                              ? theme.palette.primary.dark
                              : theme.palette.action.disabled
                          }
                        />
                      </linearGradient>
                    </defs>
                    {cloneElement(svgProps.children[0], {
                      fill: 'url(#gradient1)',
                    })}
                  </svg>
                );
              }}
            />
          )}
          {airwaveOn !== undefined && (
            <RadioHandheldIcon
              sx={{ fontSize: 16, verticalAlign: 'text-top', mr: 0.5 }}
              htmlColor={airwaveOn ? amber[700] : theme.palette.action.disabled}
            />
          )}
          {ancillaryEquipmentOn !== undefined && (
            <InputIcon
              sx={{ fontSize: 16, verticalAlign: 'text-top', mr: 0.5 }}
              htmlColor={
                ancillaryEquipmentOn
                  ? amber[700]
                  : theme.palette.action.disabled
              }
            />
          )}
          {amberLightsOn !== undefined && (
            <AlarmLightIcon
              titleAccess={
                amberLightsOn ? 'Amber lights on' : 'Amber lights off'
              }
              sx={{ fontSize: 16, verticalAlign: 'text-top', mr: 0.5 }}
              htmlColor={
                amberLightsOn ? amber[700] : theme.palette.action.disabled
              }
            />
          )}
          {trailerOn !== undefined && ( //nature rv_hookup
            <RvHookupIcon
              titleAccess={trailerOn ? 'Trailer on' : 'No trailer'}
              sx={{ fontSize: 16, verticalAlign: 'text-top', mr: 0.5 }}
              htmlColor={trailerOn ? amber[700] : theme.palette.action.disabled}
            />
          )}
          {/* {currentSpeedRules?.length > 0 && (
            <Box style={{ color: red['700'] }} title={speedRulesTooltip}>
              <SpeedometerIcon
                title={speedRulesTooltip}
                sx={{ fontSize: 16, verticalAlign: 'text-top', mr: 0.5 }}
                htmlColor={theme.palette.error}
                style={{ marginRight: 0 }}
              />
              {currentSpeedRules?.length > 1 && 'x' + currentSpeedRules.length}
            </Box>
          )} */}
          <Box
            style={{
              flex: 1,
            }}
          ></Box>
          <Chip
            title={speedRulesTooltip}
            sx={{
              fontSize: '.6rem',
              height: 18,
              '.MuiChip-avatarSmall': {
                bgcolor: 'background.paper',
                color: vehicle.speeding ? 'error.main' : undefined,
                height: 14,
                width: 14,
                marginLeft: 0.25,
              },
            }}
            color={currentSpeedRules?.length ? 'error' : undefined}
            size="small"
            avatar={
              speedLimit && (vehicle.speeding || currentSpeedRules?.length) ? (
                <Avatar>
                  <Box
                    style={{
                      color: currentSpeedRules?.length
                        ? theme.palette.text.primary
                        : undefined,
                    }}
                  >
                    {speedLimit}
                  </Box>
                </Avatar>
              ) : undefined
            }
            label={`${Math.round(speedKilometresPerHour * 0.62137119)} MPH`}
          />
          {/* <Typography
            style={{
              flex: 1,
              textAlign: 'right',
              fontSize: 12,
              color: vehicle.speeding ? red[700] : 'inherit',
              // marginRight: theme.spacing(1),
            }}
          >
            {`${Math.round(speedKilometresPerHour * 0.62137119)} MPH`}
          </Typography> */}
        </Box>
      }
    </Fragment>
  );

  return (
    <LiveListItem
      type="vehicles"
      primaryPath={primaryPath}
      secondaryComponent={secondaryComponent}
      item={vehicle}
      {...props}
    />
  );
}

export default VehicleListItem;
