import {
  AccessTime as StaleIcon,
  Assignment as PlanIcon,
  Business as LocationIcon,
  DataUsage as ObjectivesIcon,
  DirectionsCar as CarIcon,
  TwoWheeler as MotorcycleIcon,
  DoneAll as TaskIcon,
  Feedback as IncidentIcon,
  FilterList as FilteredIcon,
  Person as PersonIcon,
  More as TagsIcon,
  GroupWork as CallSignIcon,
  NotInterested as NotInterestedIcon,
  ThreeSixty as ThreeSixtyIcon,
  Beenhere as BeenhereIcon,
  Build as WorkshopIcon,
  LocalHospital as HospitalIcon,
  AccountBalance as CourtIcon,
  School as SchoolIcon,
} from '@mui/icons-material';
import { AxisArrow as EventIcon } from 'mdi-material-ui';
import moment from 'moment';
import EventItem from './LiveList/EventLiveItem';
import EventListItem from './LiveList/EventLiveListItem';
import IncidentItem from './LiveList/IncidentLiveItem';
import IncidentListItem from './LiveList/IncidentLiveListItem';
import LocationItem from './LiveList/LocationLiveItem';
import LocationListItem from './LiveList/LocationLiveListItem';
import ObjectiveItem from './LiveList/ObjectiveLiveItem';
import ObjectiveListItem from './LiveList/ObjectiveLiveListItem';
import PeopleListItem from './LiveList/PeopleLiveListItem';
import PersonItem from './LiveList/PersonLiveItem';
import PlanItem from './LiveList/PlanLiveItem';
import PlanListItem from './LiveList/PlanLiveListItem';
import TaskItem from './LiveList/TaskLiveItem';
import TaskListItem from './LiveList/TaskLiveListItem';
import VehicleItem from './LiveList/VehicleLiveItem';
import VehicleListItem from './LiveList/VehicleLiveListItem';
import TagDetail from './LiveList/TagDetail';
import TagListItem from './LiveList/TagListItem';
import CallSignLiveListItem from './LiveList/CallSignLiveListItem';
import CallSignDetail from './LiveList/CallSignDetail';

export const typeIcons = {
  vehicles: CarIcon,
  MOTORCYCLE: MotorcycleIcon,
  locations: LocationIcon,
  Workshop: WorkshopIcon,
  Hospital: HospitalIcon,
  Court: CourtIcon,
  School: SchoolIcon,
  people: PersonIcon,
  callSigns: CallSignIcon,
  events: EventIcon,
  incidents: IncidentIcon,
  tasks: TaskIcon,
  plans: PlanIcon,
  objectives: ObjectivesIcon,
  Patrol: ThreeSixtyIcon,
  Visit: BeenhereIcon,
  Exclusion: NotInterestedIcon,
  stale: StaleIcon,
  filtered: FilteredIcon,
  tags: TagsIcon,
};

export const singularToPluralTypeMap = {
  vehicle: 'vehicles',
  location: 'locations',
  person: 'people',
  event: 'events',
  incident: 'incidents',
  task: 'tasks',
  plan: 'plans',
  objective: 'objectives',
  tag: 'tags',
  callSign: 'callSigns',
};

export const pluralToSingularTypeMap = {
  vehicles: 'vehicle',
  locations: 'location',
  people: 'person',
  events: 'event',
  incidents: 'incident',
  tasks: 'task',
  plans: 'plan',
  objectives: 'objective',
  tags: 'tag',
  callSigns: 'callSign',
};

const { useReducedResourceInformation } = window.config;
const {
  locationTypes = [
    'Police Station',
    'Workshop',
    'Base',
    'Hospital',
    'Court',
    'Ward',
  ],
} = window.config.liveOptions;

const {
  liveOptions: {
    incidentLookbackMinutes = 60 * 24 * 7,
    incidentLookbackExemptGrades = [],
    radioLookbackMinutes = 120,
    accelAlertLookbackMinutes = 120,
  },
} =
  process.env.NODE_ENV === 'development'
    ? {
        liveOptions: {
          incidentLookbackMinutes: 60 * 24 * 365 * 10,
          incidentLookbackExemptGrades: [1, 2],
          radioLookbackMinutes: 60 * 24 * 365 * 5,
          accelAlertLookbackMinutes: 60 * 24 * 365 * 5,
        },
      }
    : window.config;

export const labelPaths = {
  vehicles: useReducedResourceInformation
    ? 'fleetNumber'
    : 'registrationNumber',
  locations: 'name',
  people: 'name', // the useReducedResourceInformation is covered in the epic
  events: 'code',
  incidents: 'number',
  tasks: 'task',
  plans: 'title',
  objectives: 'title',
  // tags: 'name', // doesn't have a map label
  // callSigns: 'name', // doesn't have a map label
};

export const listComponentsByType = {
  vehicles: {
    label: 'Vehicles',
    listItemComponent: VehicleListItem,
    itemComponent: VehicleItem,
  },
  locations: {
    label: 'Locations',
    listItemComponent: LocationListItem,
    itemComponent: LocationItem,
  },
  people: {
    label: 'People',
    listItemComponent: PeopleListItem,
    itemComponent: PersonItem,
  },
  events: {
    label: 'Events',
    listItemComponent: EventListItem,
    itemComponent: EventItem,
  },
  incidents: {
    label: 'Incidents',
    listItemComponent: IncidentListItem,
    itemComponent: IncidentItem,
  },
  tasks: {
    label: 'Tasks',
    listItemComponent: TaskListItem,
    itemComponent: TaskItem,
  },
  plans: {
    label: 'Plans',
    listItemComponent: PlanListItem,
    itemComponent: PlanItem,
  },
  objectives: {
    label: 'Objectives',
    listItemComponent: ObjectiveListItem,
    itemComponent: ObjectiveItem,
  },
  tags: {
    label: 'Tags',
    listItemComponent: TagListItem,
    itemComponent: TagDetail,
  },
  callSigns: {
    label: 'Call Signs',
    listItemComponent: CallSignLiveListItem,
    itemComponent: CallSignDetail,
  },
};

export const liveSubscription = {
  objectives: {
    query: {
      startTime: { $lte: moment().toISOString() },
      endTime: { $gte: moment().toISOString() },
    },
    projection: {
      identifier: true,
      type: true,
      boundaryType: true,
      boundarySubtype: true,
      boundaryIdentifier: true,
      boundary: true,
      startTime: true,
      endTime: true,
      schedule: true,
      complianceSeconds: true,
      title: true,
      description: true,
      areas: true,
      created: true,
      lastEdit: true,
      //briefs: true,
      occurrenceNumber: true,
      requiredFrequency: true,
      requiredVisits: true,
    },
  },
  tasks: {
    projection: {
      number: true,
      title: true,
      description: true,
      status: true,
      type: true,
      priority: true,
      dueTime: true,
      assignedPerson: true,
      subject: true,
      occurrences: true,
      requiresReview: true,
      group: true,
      created: true,
      lastEdit: true,
    },
  },
  people: {
    query: {
      radioSsi: { $nin: [null, ''] },
      lastPollTime: {
        $gte: moment().add(-radioLookbackMinutes, 'm').toISOString(),
      },
    },
    projection: {
      code: true,
      collarNumber: true,
      forenames: true,
      surname: true,
      category: true,
      duties: true,
      radioSsi: true,
      rfidCards: true,
      skills: true,
      position: true,
      lastPollTime: true,
      currentLocations: true,
      headingDegrees: true,
      speedKilometresPerHour: true,
      gpsAvailable: true,
      emergencyButtonOn: true,
      role: true,
      // tags: true,
      // tagChanged: true,
      assignments: true,
      homeStation: true,
      areas: true,
      rank: true,
      groups: true,
    },
  },
  locations: {
    query: {
      type: { $in: locationTypes },
    },
    projection: {
      code: true,
      name: true,
      type: true,
      areas: true,
      district: true,
      subtype: true,
      boundary: true,
      groups: true,
      // tags: true,
      // tagChanged: true,
    },
  },
  // plans: {
  //   query: {
  //     type: { $eq: 'Plan' },
  //     startTime: { $lte: moment().toISOString() },
  //     endTime: { $gte: moment().toISOString() },
  //   },
  //   projection: {
  //     identifier: true,
  //     type: true,
  //     boundary: true,
  //     startTime: true,
  //     endTime: true,
  //     title: true,
  //     description: true,
  //     areas: true,
  //     briefs: true,
  //     // tags: true,
  //     // tagChanged: true,
  //   },
  // },
  accelerometerAlerts: {
    query: {
      eventType: 'ACCELEROMETERALERT',
      time: {
        $gte: moment().add(-accelAlertLookbackMinutes, 'm').toISOString(),
      },
    },
    projection: {
      identifier: true,
      eventType: true,
      vehicle: true,
      driver: true,
      locations: true,
      time: true,
      point: true,
      // tags: true,
      // tagChanged: true,
    },
  },
  accelerometerEvents: {
    query: {
      eventType: 'ACCELEROMETEREVENT',
      startTime: {
        $gte: moment().add(-accelAlertLookbackMinutes, 'm').toISOString(),
      },
    },
    projection: {
      identifier: true,
      accelerometerData: true,
      deviceProperties: true,
      startTime: true,
      endTime: true,
      vehicle: true,
      maximumForces: true,
    },
  },
  incidents: {
    query: {
      $or: [
        {
          openedTime: {
            $gte: moment().add(-incidentLookbackMinutes, 'm').toISOString(),
          },
        },
        { grade: { $in: incidentLookbackExemptGrades } },
      ],
      status: { $ne: 'closed' },
      grade: { $ne: 0 },
    },
    projection: {
      number: true,
      description: true,
      address: true,
      type: true,
      category: true,
      grade: true,
      point: true,
      openedTime: true,
      assignedTime: true,
      attendedTime: true,
      closedTime: true,
      status: true,
      closingCodes: true,
      // tags: true,
      // tagChanged: true,
      responseCategory: true,
      date: true,
      reference: true,
      locations: true,
    },
  },
  vehicles: {
    query: {
      telematicsBoxImei: { $nin: [null, ''] },
      lastPollTime: { $exists: true },
      disposalDate: { $in: [null, ''] },
    },
    projection: {
      identificationNumber: true,
      telematicsBoxImei: true,
      fleetNumber: true,
      registrationNumber: true,
      make: true,
      model: true,
      colour: true,
      role: true,
      homeStation: true,
      areas: true,
      lastPollTime: true,
      position: true,
      headingDegrees: true,
      speedKilometresPerHour: true,
      malfunctionIndicatorLightOn: true,
      ignitionOn: true,
      assignedIncidentNumber: true,
      currentLocations: true,
      driver: true,
      lastDriver: true,
      type: true,
      // tags: true,
      // tagChanged: true,
      lastIgnitionOffTime: true,
      assignments: true,
      beaconsOn: true,
      sirensOn: true,
      rearRedLightsOn: true,
      rearBlueLightsOn: true,
      ...Object.fromEntries(
        Object.keys(window.config.dioStates).map((k) => [k, true])
      ),
      equipment: true,
      reverseGeocode: true,
      groups: true,
      currentSpeedRules: true,
    },
  },
};
