import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  useMediaQuery,
} from '@mui/material';
import moment from 'moment';

import {
  signalStrengthIcon,
  convertSoftwareId,
  mapCanBusStatus,
  mapCanBusStatusAbbreviated,
  OnOffIcon,
} from './utilities';
import DynamicTableCell from './DynamicTableCell';
import { Fragment } from 'react';

const { hideLonLat } = window.config.dioOptions;

export default function PollTable({ polls, pollsToShow }) {
  const isMedium = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const breakValAt = (val, n) => {
    return val.toString().substring(0, n) + '\n' + val.toString().substring(n);
  };

  const pollLabels = () => {
    return isMedium ? (
      <TableRow>
        <DynamicTableCell>F/W</DynamicTableCell>
        <DynamicTableCell>Time</DynamicTableCell>
        {!hideLonLat && (
          <Fragment>
            <DynamicTableCell>Lat</DynamicTableCell>
            <DynamicTableCell>Lon</DynamicTableCell>
          </Fragment>
        )}
        <DynamicTableCell>GPS</DynamicTableCell>
        <DynamicTableCell>Ign</DynamicTableCell>
        <DynamicTableCell>Batt</DynamicTableCell>
        <DynamicTableCell>Signal</DynamicTableCell>
        <DynamicTableCell>CAN</DynamicTableCell>
      </TableRow>
    ) : (
      <TableRow>
        <DynamicTableCell>Firmware Version</DynamicTableCell>
        <DynamicTableCell>Time</DynamicTableCell>
        {!hideLonLat && (
          <Fragment>
            <DynamicTableCell>Latitude</DynamicTableCell>
            <DynamicTableCell>Longitude</DynamicTableCell>
          </Fragment>
        )}
        <DynamicTableCell>Location Valid</DynamicTableCell>
        <DynamicTableCell>Ignition</DynamicTableCell>
        <DynamicTableCell>Battery</DynamicTableCell>
        <DynamicTableCell>Signal Strength</DynamicTableCell>
        <DynamicTableCell>CAN Bus Status</DynamicTableCell>
      </TableRow>
    );
  };

  const pollRows = (polls, numberToShow = 12) => {
    const yearFormat = isMedium ? 'DD/MM/YY ' : 'DD/MM/YYYY, ';

    return polls.slice(0, numberToShow).map((poll) => {
      if (!poll || !poll.position) {
        return null;
      }
      const time = moment(poll.time);
      let [lon, lat] = poll.position.coordinates;
      lon = parseFloat(lon).toFixed(6);
      lat = parseFloat(lat).toFixed(6);
      return (
        <TableRow key={poll.identifier}>
          <DynamicTableCell>
            {convertSoftwareId(poll.deviceProperties.softwareId)}
          </DynamicTableCell>
          <DynamicTableCell>
            {time.format(`${yearFormat}HH:mm:ss`)}
          </DynamicTableCell>
          {!hideLonLat && (
            <Fragment>
              <DynamicTableCell>
                {isXSmall ? breakValAt(lat, 5) : lat}
              </DynamicTableCell>
              <DynamicTableCell>
                {isXSmall ? breakValAt(lon, 5) : lon}
              </DynamicTableCell>
            </Fragment>
          )}
          <DynamicTableCell>
            <OnOffIcon
              style={{ margin: '0 auto' }}
              on={poll.deviceProperties.isValidated}
              onTooltip="valid"
              offTooltip="invalid"
            />
          </DynamicTableCell>
          <DynamicTableCell>
            <OnOffIcon style={{ margin: '0 auto' }} on={poll.ignitionOn} />
          </DynamicTableCell>
          <DynamicTableCell>
            {poll.deviceProperties.batteryVoltage}V
          </DynamicTableCell>
          <DynamicTableCell>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                maxHeight: 20,
              }}
            >
              {signalStrengthIcon(poll.deviceProperties.deviceSignalStrength)}
            </div>
          </DynamicTableCell>
          <DynamicTableCell
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: 64,
            }}
          >
            {(isXSmall ? mapCanBusStatusAbbreviated : mapCanBusStatus)(
              poll.deviceProperties.canBusStatus
            )}
          </DynamicTableCell>
        </TableRow>
      );
    });
  };

  return (
    <Table>
      <TableHead>{pollLabels()}</TableHead>
      <TableBody>{pollRows(polls, pollsToShow)}</TableBody>
    </Table>
  );
}
