import { keyframes } from '@emotion/react';
import _ from 'lodash';
import { match } from '../components/retrospective/constants';

export const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export function applySearch(item, searchTexts) {
  if (!searchTexts || searchTexts === '' || !item) {
    return true;
  }

  return _.includes(item.searchString, searchTexts);
}

// export function applyExtendedFilters(item, filters) {
//   if (!item) return true;

//   return filters
//     ? Object.entries(filters).every(([key, options]) =>
//         // for single select: val !== '' ? item[key] === val : true
//         options.length !== 0
//           ? options.some(option => item[key] === option.value)
//           : true
//       )
//     : [];
// }

export function applyAdvancedFilters(item, filters) {
  if (!item || !filters) {
    return true;
  }

  return filters.every((filter) => {
    const itemValue = _.get(item, filter.field);
    const filterValueIsArray = Array.isArray(filter.value);
    const specialValue = (value) =>
      filterValueIsArray
        ? filter.value.some((f) => f === value)
        : filter.value === value;
    const matchAnyValue = specialValue('*Any');
    const matchNoValue = specialValue('*None');

    const unfinishedFilterDefinition =
      filter.field == null ||
      filter.field === '' ||
      filter.value == null ||
      filter.value === '' ||
      (filterValueIsArray && filter.value.length === 0);

    // if the filter isn't complete don't exclude (i.e. return true)
    if (unfinishedFilterDefinition) {
      return true;
    }

    // if the item's value is an array (e.g. skills)
    // test that any of item's values match
    if (Array.isArray(itemValue)) {
      if (itemValue.length > 0 ? matchAnyValue : matchNoValue) {
        return true;
      }

      const itemArrayMatch = (option) =>
        itemValue.some((element) => match(element, filter.condition, option));

      // if the filter's value is also an array...
      if (filterValueIsArray) {
        return filter.value.some((filterOption) =>
          itemArrayMatch(filterOption)
        );
      }

      return itemArrayMatch(filter.value);
    }

    // the item has a value and the "*Any" option is selected it's a match
    // similarly if the item has no value and "*None" is selected
    const hasValue = itemValue !== undefined && itemValue !== '';
    if (hasValue ? matchAnyValue : matchNoValue) {
      return true;
    }

    // if the filter is an array test the item's value
    // against any of the filter's values
    if (filterValueIsArray) {
      return filter.value.some((filterOption) =>
        match(itemValue, filter.condition, filterOption)
      );
    }

    // otherwise match filter value against item value
    return match(itemValue, filter.condition, filter.value);
  });
}

// handle filtering in the epic, whenever the filters/search texts change,
// adjust the filteredInIdsByType
// helper for filtering a dictionary
export function objFilter(obj, predicate, arg) {
  return Object.keys(obj)
    .filter((key) => predicate(obj[key], arg))
    .reduce((result, key) => {
      result[key] = obj[key];
      return result;
    }, {});
}

// filters an item dictionary based on searchTexts (e.g. "abc") and filters
// (e.g. vehicle.registrations = ["REG1234"])
export function filterDict(dict, searchText, filters, advancedFilters) {
  dict = dict && objFilter(dict, applySearch, searchText?.toLowerCase());
  // dict = dict && objFilter(dict, applyExtendedFilters, filters);
  dict = dict && objFilter(dict, applyAdvancedFilters, advancedFilters);

  return dict;
}

export function filterList(list, searchText, filters, advancedFilters) {
  list =
    list && list.filter((item) => applySearch(item, searchText?.toLowerCase()));
  // list = list && list.filter(item => applyExtendedFilters(item, filters));
  list =
    list && list.filter((item) => applyAdvancedFilters(item, advancedFilters));

  return list;
}

//general filtering function to filter fetched data locally
export function filterLocally(filter, data) {
  return data.filter((dataItem) => {
    return Object.entries(filter.fields).every(([key, value]) => {
      return value === null || value === _.get(dataItem, key);
    });
  });
}

export const idProperties = {
  vehicles: 'identificationNumber',
  people: 'code',
  incidents: 'number',
  accelerometerAlerts: 'identifier',
  accelerometerEvents: 'identifier',
  plans: 'identifier',
  objectives: 'identifier',
  locations: 'code',
  tasks: 'number',
};

export class NormalDistribution {
  constructor(mean, sd) {
    if (!mean) {
      mean = 0.0;
    }
    if (!sd) {
      sd = 1.0;
    }
    this.mean = mean;
    this.sd = sd;
    this.Sqrt2 = 1.4142135623730950488016887;
    this.Sqrt2PI = 2.50662827463100050242;
    this.lnconstant = -Math.log(this.Sqrt2PI * sd);
  }

  sample() {}

  cumulativeProbability(x) {
    var z = (x - this.mean) / (this.Sqrt2 * this.sd);
    return 0.5 + 0.5 * this.errorFunc(z);
  }

  invCumulativeProbability(p) {
    var Z = this.Sqrt2 * this.invErrorFunc(2 * p - 1);
    return Z * this.sd + this.mean;
  }

  errorFunc(z) {
    var t = 1.0 / (1.0 + 0.5 * Math.abs(z));

    // use Horner's method
    var ans =
      1 -
      t *
        Math.exp(
          -z * z -
            1.26551223 +
            t *
              (1.00002368 +
                t *
                  (0.37409196 +
                    t *
                      (0.09678418 +
                        t *
                          (-0.18628806 +
                            t *
                              (0.27886807 +
                                t *
                                  (-1.13520398 +
                                    t *
                                      (1.48851587 +
                                        t *
                                          (-0.82215223 + t * 0.17087277))))))))
        );
    return z >= 0 ? ans : -ans;
  }

  invErrorFunc(x) {
    var z;
    var a = 0.147;
    var the_sign_of_x;
    if (0 === x) {
      the_sign_of_x = 0;
    } else if (x > 0) {
      the_sign_of_x = 1;
    } else {
      the_sign_of_x = -1;
    }

    if (0 !== x) {
      var ln_1minus_x_sqrd = Math.log(1 - x * x);
      var ln_1minusxx_by_a = ln_1minus_x_sqrd / a;
      var ln_1minusxx_by_2 = ln_1minus_x_sqrd / 2;
      var ln_etc_by2_plus2 = ln_1minusxx_by_2 + 2 / (Math.PI * a);
      var first_sqrt = Math.sqrt(
        ln_etc_by2_plus2 * ln_etc_by2_plus2 - ln_1minusxx_by_a
      );
      var second_sqrt = Math.sqrt(first_sqrt - ln_etc_by2_plus2);
      z = second_sqrt * the_sign_of_x;
    } else {
      // x is zero
      z = 0;
    }
    return z;
  }
}
