export function filterFullyDefined(f) {
  return (
    !!f.field &&
    (Array.isArray(f.value)
      ? f.value.length > 0
      : typeof f.value !== 'undefined' && f.value !== null)
  );
}

export function addTagItem(tags, type, id, tag) {
  return {
    ...tags,
    [tag]: {
      id: tag,
      searchString: tag.toLowerCase(),
      itemsByType: {
        ...(tags[tag]?.itemsByType || {}),
        [type]: {
          ...(tags[tag]?.itemsByType?.[type] || {}),
          [id]: true,
        },
      },
    },
  };
}

export function deleteTagItem(tags, type, id, tag) {
  if (tags[tag]?.itemsByType?.[type]?.[id]) {
    delete tags[tag].itemsByType[type][id];

    const remaining = Object.keys(tags[tag].itemsByType).reduce(
      (total, type) => total + Object.keys(tags[tag].itemsByType[type]).length,
      0
    );
    if (remaining === 0) {
      delete tags[tag];
    }
  }

  return { ...tags };
}

export function createLiveTag(tags, type, id, tag) {
  return {
    id: tag,
    searchString: tag.toLowerCase(),
    itemsByType: {
      ...(tags[tag]?.itemsByType || {}),
      [type]: {
        ...(tags[tag]?.itemsByType?.[type] || {}),
        [id]: true,
      },
    },
  };
}

export function getUserTags(tags) {
  const tagObjs = [];
  if (Array.isArray(tags)) {
    tags.forEach((tag) => {
      if (tag.items) {
        Object.keys(tag?.items).forEach((itemKey) => {
          tag?.items[itemKey]?.forEach((tagItem) => {
            const liveTag = {
              type: itemKey,
              id: tagItem,
              tag: tag.identifier,
            };
            tagObjs.push(liveTag);
          });
        });
      }
    });
  }
  return tagObjs;
}

export const getTypesInLocation = (
  typeItems,
  locationCode,
  type,
  showStale
) => {
  const relatedItems = {};
  const types = [];

  Object.keys(typeItems).forEach((key) => {
    const exists = typeItems[key]?.currentLocations?.some(
      (obj) => obj.code === locationCode
    );
    if (exists) {
      types.push(typeItems[key]);
    }
  });

  if (!showStale) {
    relatedItems[type] = types.filter((o) => o.stale === showStale);
  } else {
    relatedItems[type] = types;
  }

  return relatedItems;
};

export function addResourceInLocations(state, type) {
  const showStale =
    !('stale' in state.layerVisibilities) || state.layerVisibilities['stale'];
  Object.keys(state.locations).forEach((key) => {
    const staleResourcesInLocations = [];
    const nonStaleResourcesInLocations = [];
    Object.keys(state[type]).forEach((typeKey) => {
      state[type][typeKey]?.currentLocations?.forEach((l) => {
        if (l.code === key) {
          if (showStale) {
            staleResourcesInLocations.push(typeKey);
          }

          if (!showStale && state[type][typeKey]?.stale === showStale) {
            nonStaleResourcesInLocations.push(typeKey);
          }
        }
      });
    });

    const allResourcesInLocations = [
      ...staleResourcesInLocations,
      ...nonStaleResourcesInLocations,
    ];
    state.locations[key][type] = allResourcesInLocations.length;
  });
}
