import { useNavigate, Link, useParams } from 'react-router-dom';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import {
  Flag as FlagIcon,
  OutlinedFlag as OutlinedFlagIcon,
  PlayArrow as PlayArrowIcon,
} from '@mui/icons-material';
import { Helmet } from 'react-helmet-async';
import moment from 'moment';
import _ from 'lodash';
import humanizeDuration from 'humanize-duration';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import {
  RetrospectiveTypeIcon,
  LocationTypeIcon,
} from '../../../data/constants';
import { ExportPicker } from '../../controls';
import {
  getPersonPolls,
  shortPersonHeaders,
  radioPollHeaders,
} from '../../../apis/utilities';
import { getPrimaryText, getSecondaryText } from '../constants';
import { Fragment } from 'react';

const { useReducedResourceInformation } = window.config;

export default function PersonVisit({
  color,
  background,
  identifier,
  person,
  visitLocation,
  startTime,
  endTime,
  trails,
}) {
  const navigate = useNavigate();
  const { id, layerIndex, itemIndex } = useParams();

  function Row({ data, index, style }) {
    const feature = {
      properties: {
        ...data[index],
        source: 'personTrails',
      },
    };

    return (
      <ListItemButton
        dense
        key={index}
        style={style}
        component={Link}
        to={`/retrospective/${
          id || 'untitled'
        }/${layerIndex}/${itemIndex}/${index}`}
      >
        <ListItemAvatar>
          <Avatar
            style={{
              background,
              color,
            }}
          >
            <RetrospectiveTypeIcon type={'personTrails'} />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={getPrimaryText(feature)}
          secondary={getSecondaryText(feature)}
        />
      </ListItemButton>
    );
  }

  function handleViewClick() {
    navigate(`/replay/personLocationIntersections/${identifier}`);
  }

  const csvHeaders = [...shortPersonHeaders, ...radioPollHeaders];

  async function fetchDataToConvert() {
    const polls = await getPersonPolls(person.radioSsi, startTime, endTime);

    const data = polls.map((feature) => ({
      ...feature,
      ...(useReducedResourceInformation
        ? { staffId: person.code }
        : {
            name: person.forenames + ' ' + person.surname,
            personRole: person.role,
            collarNumber: person.collarNumber,
          }),
      time: new Date(feature.time),
      locationType: visitLocation.type,
      locationName: visitLocation.name,
      radioSsi: feature.ssi,
      longitude: feature.position ? feature.position.coordinates[0] : 0,
      latitude: feature.position ? feature.position.coordinates[1] : 0,
    }));

    return data;
  }

  const cardHeaderButtons = (
    <Box>
      {identifier && (
        <IconButton
          aria-label="replay"
          title="View Replay"
          onClick={handleViewClick}
          size="large"
        >
          <PlayArrowIcon />
        </IconButton>
      )}
      <ExportPicker
        title="Download Person Visit"
        fetchDataToConvert={fetchDataToConvert}
        filename="Person Visit"
        headers={csvHeaders}
      />
    </Box>
  );

  let title, subheader;

  if (person.unassociated) {
    title = 'Unassociated SSI';
    subheader = person.radioSsi;
  } else {
    title = useReducedResourceInformation
      ? person.code
      : `${person.forenames || ''}  ${person.surname || ''}`;
    subheader = person.collarNumber;
  }

  return (
    <Card sx={{ m: [1, 0.5, 1, 1] }}>
      <Helmet>
        <title>IR3 | Person Visit |{` ${title}`}</title>
      </Helmet>
      <CardHeader
        avatar={
          <Avatar
            style={{
              background,
              color,
            }}
            title="Person Visit"
          >
            <RetrospectiveTypeIcon type="personVisits" />
          </Avatar>
        }
        title={title}
        subheader={subheader}
        action={cardHeaderButtons}
      />
      <CardContent>
        {!!person.code && (
          <Fragment>
            <Typography variant="subtitle2" color="textSecondary">
              Person
            </Typography>
            <Table size="small" sx={{ mt: 1, mb: 2 }}>
              <TableBody>
                {!useReducedResourceInformation && !!person.role && (
                  <TableRow>
                    <TableCell>
                      <Typography variant="caption" color="textSecondary">
                        Role
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="caption">{person.role}</Typography>
                    </TableCell>
                  </TableRow>
                )}
                {person.homeStation && (
                  <TableRow>
                    <TableCell>
                      <Typography variant="caption" color="textSecondary">
                        {useReducedResourceInformation
                          ? 'Home Location'
                          : 'Home Station'}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="caption">
                        {person.homeStation}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                {(person.areas || []).map((area, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Typography variant="caption" color="textSecondary">
                        {_.startCase(area.type)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="caption">{area.name}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Fragment>
        )}
        {visitLocation.name !== '<custom>' && (
          <Fragment>
            <Typography variant="subtitle2" color="textSecondary">
              Location
            </Typography>
            <ListItem component="div" sx={{ my: 1 }}>
              <ListItemAvatar>
                <Avatar title={visitLocation.type}>
                  <LocationTypeIcon type={visitLocation.type} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={visitLocation.name}
                secondary={visitLocation.type}
              />
            </ListItem>
          </Fragment>
        )}
        <Typography variant="subtitle2" color="textSecondary">
          Timeline
        </Typography>
        <Stepper orientation="vertical" sx={{ mt: 2, ml: 2 }}>
          <Step active>
            <StepLabel
              icon={
                <Avatar sx={{ width: 30, height: 30 }}>
                  <FlagIcon fontSize="inherit" />
                </Avatar>
              }
              optional={
                <Typography variant="caption">
                  {moment(startTime).format('DD/MM/YYYY, HH:mm:ss')}
                </Typography>
              }
            >
              Start
            </StepLabel>
            <StepContent />
          </Step>
          <Step active>
            <StepLabel
              icon={
                <Avatar sx={{ width: 30, height: 30 }}>
                  <OutlinedFlagIcon fontSize="inherit" />
                </Avatar>
              }
              optional={
                <Typography variant="caption">
                  {moment(endTime).format('DD/MM/YYYY, HH:mm:ss')}
                </Typography>
              }
            >
              End
            </StepLabel>
            <StepContent />
          </Step>
        </Stepper>
        {trails?.length ? (
          <Fragment>
            <Typography variant="subtitle2" color="textSecondary">
              Trails
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flex: [1, 1, 'auto'],
                flexDirection: 'column',
                overflow: 'hidden',
                my: 1,
              }}
            >
              <Box
                sx={{
                  overflowY: 'auto',
                  overflowX: 'hidden',
                  height: trails.length * 56,
                }}
              >
                <AutoSizer>
                  {({ width, height }) => (
                    <FixedSizeList
                      width={width}
                      height={trails.length * 56}
                      overscanCount={10}
                      itemData={trails}
                      itemCount={trails.length}
                      itemSize={56}
                      // onItemsRendered={debouncedHandleItemsRenderedChange}
                    >
                      {Row}
                    </FixedSizeList>
                  )}
                </AutoSizer>
              </Box>
            </Box>
          </Fragment>
        ) : (
          <Fragment />
        )}
        <Typography variant="subtitle2" color="textSecondary">
          Metrics
        </Typography>
        <Table size="small" sx={{ mt: 1, mb: 2 }}>
          <TableBody>
            {/* <TableRow>
              <TableCell>
                <Typography variant="caption" color="textSecondary">
                  Distance
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">
                  {`${_.round(distanceKilometres * 0.62137119, 2)} miles`}
                </Typography>
              </TableCell>
            </TableRow> */}
            <TableRow>
              <TableCell>
                <Typography variant="caption" color="textSecondary">
                  Duration
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">
                  {humanizeDuration(moment(endTime).diff(moment(startTime)))}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}
