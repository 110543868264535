import {
  Avatar,
  Button,
  Divider,
  IconButton,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Popover,
  Box,
} from '@mui/material';
import { AccountCircle as AccountCircleIcon } from '@mui/icons-material';
import { Fragment, useState } from 'react';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../Auth';

const { useReducedResourceInformation, showAdGroups } = window.config;

export default function LoginAvatar() {
  const navigate = useNavigate();
  const auth = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const profile = auth.getProfile();
  const open = Boolean(anchorEl);
  const id = open ? 'login-popover' : undefined;

  function handleAvatarClick(event) {
    if (auth.isAuthenticated()) {
      setAnchorEl(event.currentTarget);
    } else {
      auth.login();
    }
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleProfileClick() {
    navigate(`/resources/people/${profile.code}`);
    handleClose();
  }

  function handleLogoutClick() {
    auth.logout();
    handleClose();
  }

  return (
    <Fragment>
      <IconButton
        sx={{ p: 0 }}
        aria-describedby={id}
        onClick={handleAvatarClick}
        id="login"
        size="large"
      >
        <Avatar
          sx={{ width: 36, height: 36, fontSize: 16, bgcolor: 'primary.dark' }}
          src={auth.isAuthenticated() ? profile.picture : undefined}
        >
          {auth.isAuthenticated() ? (
            `${profile.forenames ? profile.forenames[0] : ''}${
              profile.surname ? profile.surname[0] : ''
            }` || <AccountCircleIcon color="secondary" />
          ) : (
            <AccountCircleIcon />
          )}
        </Avatar>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography variant="caption">
            Logged in as{' '}
            <Box component="span" sx={{ fontWeight: 'bold' }}>
              {auth.getUsername()}
            </Box>
          </Typography>
        </Box>
        <Divider />
        {profile.code && (
          <Fragment>
            <Box sx={{ p: 2 }}>
              {useReducedResourceInformation ? (
                <Typography variant="subtitle2">{`${profile.forenames} ${profile.surname}`}</Typography>
              ) : (
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography variant="subtitle2">{`${profile.forenames} ${profile.surname}`}</Typography>
                  <Typography variant="subtitle2" color="textSecondary">
                    {profile.collarNumber}
                  </Typography>
                </Box>
              )}
              <Table size="small">
                <TableBody>
                  {!useReducedResourceInformation && (
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption" color="textSecondary">
                          Rank
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {(profile.rank || {}).name}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                  {!useReducedResourceInformation && (
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption" color="textSecondary">
                          Role
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {profile.role}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                  {Object.entries(profile.groups || {}).map(
                    ([type, values], index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Typography variant="caption" color="textSecondary">
                            {_.startCase(type)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="caption">
                            {values.join(', ')}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </Box>
            <Divider />
          </Fragment>
        )}
        <Box sx={{ p: 2 }}>
          <Typography sx={{ pb: 1 }} variant="subtitle2" color="textSecondary">
            Authorisation Groups
          </Typography>
          {auth.getGroupNames().map((name) => (
            <Box key={name}>
              <Typography variant="caption" color="textSecondary">
                {name}
              </Typography>
            </Box>
          ))}
        </Box>
        {showAdGroups && (
          <Fragment>
            <Divider />
            <Box sx={{ p: 2 }}>
              <Typography
                sx={{ pb: 1 }}
                variant="subtitle2"
                color="textSecondary"
              >
                Active Directory Groups
              </Typography>
              {auth.getDirectoryGroupIdentifiers().map((identifier) => (
                <Box key={identifier}>
                  <Typography variant="caption" color="textSecondary">
                    {identifier}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Fragment>
        )}

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 1 }}>
          {profile.code && (
            <Button
              size="small"
              variant="outlined"
              onClick={handleProfileClick}
            >
              Profile
            </Button>
          )}
          <Button
            size="small"
            variant="outlined"
            onClick={handleLogoutClick}
            sx={{ ml: 1 }}
          >
            Logout
          </Button>
        </Box>
      </Popover>
    </Fragment>
  );
}
