import {
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Table,
  TableBody,
  Typography,
  IconButton,
} from '@mui/material';
import {
  LocationSearching as FollowIcon,
  Edit as EditIcon,
} from '@mui/icons-material';
import { Fragment, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useSelector, useDispatch } from 'react-redux';
import avatarForItem from './avatarUtility';
import { areasToLabelAccessors, ItemRows } from './ItemRows';
import { FETCH_LIVE_LOCATION } from '../../../actions/types';
import { usePrevious } from '../../../hooks';
import { TagControl } from '../../controls';
import { locationTypes } from '../../../data/constants';
import { getTypesInLocation } from '../utilities';
import PeopleListItem from './PeopleLiveListItem';
import VehicleListItem from './VehicleLiveListItem';
import TotalTypesInLocation from './TotalTypesInLocation';

// example object
// $type: "add"
// areas: Array(0)
// boundary:
// $reql_type$: "GEOMETRY"
// coordinates: [Array(6)]
// type: "Polygon"
// code: "Braunstone"
// subtype: ""
// name: "Braunstone"
// searchString: "+[object object]+braunstone++braunstone+police station+add+true"
// tagChanged: true
// type: "Police Station"

function LocationLiveItem({
  item,
  onSubItemClick,
  onSubItemHover,
  onFollowToggle,
  followedIdsByType,
  hoveredId,
}) {
  const hideFollow = !item.boundary;
  const state = useSelector((state) => state.live);
  const locationRecord = useSelector(
    (state) => state.live.locationRecordsById[item.id]
  );
  const layerVisibilities = useSelector(
    (state) => state.live.layerVisibilities
  );
  const STALE = 'stale';
  const showStale = !(STALE in layerVisibilities) || layerVisibilities[STALE];
  const resourcesInLocation = getTypesInLocation(
    state.people,
    item.code,
    'people',
    showStale
  );
  const vehiclesInLocation = getTypesInLocation(
    state.vehicles,
    item.code,
    'vehicles',
    showStale
  );

  const typesInLocation = { ...resourcesInLocation, ...vehiclesInLocation };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const prevId = usePrevious(item.id);
  useEffect(() => {
    if (prevId !== item.id && !locationRecord) {
      dispatch({
        type: FETCH_LIVE_LOCATION,
        payload: item.id,
      });
    }
  }, [dispatch, item.id, prevId, locationRecord]);

  const rowItems = [
    { label: 'Type', value: item.type },
    { label: 'Subtype', value: item.subtype },
    ...areasToLabelAccessors(item.areas),
  ];

  const type = 'locations';
  const following = followedIdsByType?.[type]?.[item.id];
  const subtype =
    Object.keys(locationTypes).find(
      (k) => locationTypes[k].name === item.type
    ) || item.type?.toLowerCase() + 's';
  const link = `/${type}/${subtype}/${item.id ?? item.code}`;

  const typeToListItemMap = {
    vehicles: VehicleListItem,
    people: PeopleListItem,
  };

  const orderedTypes = {
    people: 'People',
    vehicles: 'Vehicles',
  };

  return (
    <Card sx={{ m: 1 }}>
      <Helmet>
        <title>{`IR3 | Live | Locations | ${item.name}`}</title>
      </Helmet>
      <CardHeader
        avatar={avatarForItem(item, 'locations')}
        title={item.name}
        subheader={item.code}
        action={
          <Fragment>
            <TotalTypesInLocation typesInLocation={typesInLocation} />
            <IconButton
              aria-label="Edit"
              onClick={() => navigate(link)}
              title="Edit"
              size="large"
            >
              <EditIcon fontSize="small" />
            </IconButton>
            {!hideFollow && (
              <IconButton
                aria-label="Toggle follow"
                onClick={() => onFollowToggle(type, item.id)}
                title="Toggle follow"
                size="large"
              >
                <FollowIcon
                  fontSize="small"
                  color={following ? 'primary' : 'inherit'}
                />
              </IconButton>
            )}
          </Fragment>
        }
      />
      {locationRecord && locationRecord.picture && (
        <CardMedia style={{ height: 420 }} image={locationRecord.picture} />
      )}

      <CardContent>
        <TagControl
          live
          item={item}
          type={'locations'}
          sx={{ py: 2 }}
          label="Tags"
        />
        <Typography variant="subtitle2" color="textSecondary">
          Details
        </Typography>
        <Table size="small" sx={{ mt: 1, mb: 2 }}>
          <TableBody>{ItemRows(rowItems, item)}</TableBody>
        </Table>

        {Object.keys(typesInLocation).map((type) => (
          <Fragment key={type}>
            <Typography variant="subtitle2" color="textSecondary">
              {typesInLocation[type].length > 0 && orderedTypes[type]}
            </Typography>
            {typesInLocation[type].map((obj) => {
              const ListComponent = typeToListItemMap[type];
              return (
                <ListComponent
                  key={obj.id}
                  onClick={onSubItemClick}
                  highlighted={hoveredId === obj.id}
                  onHoverItem={onSubItemHover}
                  onFollowToggle={onFollowToggle}
                  followedIdsByType={followedIdsByType}
                  item={obj}
                />
              );
            })}
          </Fragment>
        ))}
      </CardContent>
    </Card>
  );

  /*return (
    <Fragment>
      <div className={{ root: { flexGrow: 1 } }}>
        <Grid container style={{ padding: '8px' }}>
          <Grid item xs={12}>
            <h4>Resource Status</h4>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.cardText}>
              Code: {item.code}
            </Typography>
            <Typography className={classes.cardText}>
              Type: {item.type}
            </Typography>
            <Typography className={classes.cardText}>
              Subype: {item.subtype}
            </Typography>
            <Typography className={classes.cardText}>
              District: {item.district}
            </Typography>
            <Typography className={classes.cardText}>Areas: </Typography>
            <Grid style={{ paddingLeft: '8px' }}>
              {item.areas &&
                item.areas.length > 0 &&
                item.areas.map((area, i) => (
                  <Typography className={classes.cardText} key={i}>
                    {area.name}
                  </Typography>
                ))}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Fragment>
  );*/
}

export default LocationLiveItem;
