import {
  Avatar,
  Paper,
  CardContent,
  Collapse,
  IconButton,
  LinearProgress,
  Toolbar,
  Typography,
  Box,
  Stack,
  Menu,
  ListSubheader,
  MenuItem,
} from '@mui/material';
import {
  green,
  orange,
  red,
  teal,
  lightBlue,
  purple,
} from '@mui/material/colors';
import {
  FilterList as FilterListIcon,
  GetApp as GetAppIcon,
  BarChart as BarChartIcon,
} from '@mui/icons-material';
import _ from 'lodash';
import { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import {
  Bar,
  BarChart,
  Brush,
  Label,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useSnackbar } from '../../Snackbar';
import {
  FILTER_VEHICLE_HOURLY_UTILISATION,
  FETCH_VEHICLE_HOURLY_UTILISATION,
  FETCH_VEHICLE_HOURLY_UTILISATION_CANCELLED,
  LOAD_VEHICLE_HOURLY_UTILISATION_QUERY,
} from '../../../actions';
import Container from '../../Container';
import { SelectMultiple, Parameters, CustomTooltip } from '../../controls';
import { getKeyLabel } from '../../../data/constants';
import { downloadCSV } from '../../../apis/utilities';
import { useDebounce } from '../../../hooks';
import { ToggleListField } from '../../fields';

const { useReducedResourceInformation, homeOtherSplit } = window.config;

const hours = [...Array(24).keys()];
const days = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];

const classificationSettings = {
  activity: {
    bars: [
      ...(homeOtherSplit
        ? [
            { name: 'Stopped @ Home Base', colour: red[800] },
            { name: 'Stopped @ Other Base', colour: red[500] },
            { name: 'Idle @ Home Base', colour: purple[700] },
            { name: 'Idle @ Other Base', colour: purple[300] },
          ]
        : [
            { name: 'Stopped @ Base', colour: red[800] },
            { name: 'Idle @ Base', colour: red[500] },
          ]),
      { name: 'Stopped @ Workshop', colour: orange[800] },
      { name: 'Idle @ Workshop', colour: orange[500] },
      { name: 'Stopped Elsewhere', colour: teal[800] },
      { name: 'Idle Elsewhere', colour: teal[500] },
      { name: 'Moving', colour: green[500] },
      { name: 'Unaccounted', colour: lightBlue[400] },
    ],
  },
  status: {
    bars: [
      { name: 'Unused', colour: red[800] },
      { name: 'Unavailable', colour: orange[800] },
      { name: 'Used', colour: green[500] },
      { name: 'Unaccounted', colour: lightBlue[400] },
    ],
  },
};

const chartTypes = ['minutes', 'percentage'];
const labels = new Map([
  ['minutes', 'Minutes'],
  ['percentage', 'Percentage'],
  ['activity', 'Activity'],
  ['status', 'Status'],
]);

export default function HourlyUtilisation() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.utilisation.hourly.data, _.isEqual);
  const [viewMenuAnchor, setViewMenuAnchor] = useState(null);
  const classifyBy = useSelector(
    (state) => state.utilisation.hourly.classifyBy
  );
  const chartType = useSelector((state) => state.utilisation.hourly.chartType);
  const query = useSelector(
    (state) => state.utilisation.hourly.query,
    _.isEqual
  );
  const isLoading = useSelector((state) => state.utilisation.hourly.isLoading);
  const isFiltering = useSelector(
    (state) => state.utilisation.hourly.isFiltering
  );
  const error = useSelector((state) => state.utilisation.hourly.error);
  const filter = useSelector(
    (state) => state.utilisation.hourly.filter,
    _.isEqual
  );
  const filterValues = useSelector(
    (state) => state.utilisation.hourly.filterValues,
    _.isEqual
  );
  const [showFilter, setShowFilter] = useState(false);
  const [hiddenBars, setHiddenBars] = useState([]);
  const snackbar = useSnackbar();

  const { bars } = classificationSettings[classifyBy];

  const [filterParams, setFilterParams] = useState({
    query,
    filter,
    classifyBy,
    chartType,
  });
  const debouncedFilterParams = useDebounce(filterParams, 1000);

  useEffect(() => {
    dispatch({
      type: FILTER_VEHICLE_HOURLY_UTILISATION,
      payload: debouncedFilterParams,
    });
  }, [dispatch, debouncedFilterParams]);

  useEffect(() => {
    if (error) {
      snackbar.notify('error', error);
    }
  }, [error, snackbar]);

  useEffect(() => {
    dispatch({
      type: LOAD_VEHICLE_HOURLY_UTILISATION_QUERY,
    });
  }, [dispatch]);

  function handleFilterToggle() {
    setShowFilter(!showFilter);
  }

  function handleFetch(event, query) {
    dispatch({
      type: FETCH_VEHICLE_HOURLY_UTILISATION,
      payload: {
        query,
        filter,
        classifyBy,
        chartType: chartType,
      },
    });
  }

  function handleCancel() {
    dispatch({
      type: FETCH_VEHICLE_HOURLY_UTILISATION_CANCELLED,
    });
  }

  function handleClassificationClick(value) {
    setViewMenuAnchor(null);
    updateTransformOptions({ classifyBy: value });
  }

  function handleFilterFieldChanged(name, values) {
    const newFilter =
      name in filterParams.filter
        ? {
            ...filterParams.filter,
            [name]: values || [],
          }
        : {
            ...filterParams.filter,
            areas: {
              ...filterParams.filter.areas,
              [name]: values || [],
            },
          };

    updateTransformOptions({ filter: newFilter });
  }

  function handlePercentOrHoursClick(value) {
    setViewMenuAnchor(null);
    updateTransformOptions({ chartType: value });
  }

  function updateTransformOptions(optionChanges) {
    setFilterParams({
      query,
      filter,
      classifyBy,
      chartType,
      ...optionChanges,
    });
  }

  function handleLegendClick(selectedBar) {
    const index = hiddenBars.indexOf(selectedBar);

    if (index === -1) {
      setHiddenBars(hiddenBars.concat(selectedBar));
    } else {
      setHiddenBars(
        hiddenBars.slice(0, index).concat(hiddenBars.slice(index + 1))
      );
    }
  }

  function handleViewMenuOpen(event) {
    setViewMenuAnchor(event.target);
  }

  function handleViewMenuClose() {
    setViewMenuAnchor(null);
  }

  async function handleDownloadClick() {
    const filename = 'Vehicle Hourly Utilisation.csv';

    downloadCSV(data, filename);
  }

  return (
    <Container title="Hourly Utilisation">
      {isFiltering && (
        <LinearProgress
          sx={{ position: 'absolute', width: 1, height: 8 }}
          color="secondary"
        />
      )}
      <Parameters
        onFetch={handleFetch}
        onCancel={handleCancel}
        isFetching={isLoading && !isFiltering}
        value={query}
        sx={{ mt: 1, width: 264 }}
        pointEvent
        dateOnly
        utc
        vehicle
      />
      <Box
        sx={{
          width: 1,
          height: 'calc(100vh - 48px)',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <Helmet>
          <title>IR3 | Hourly Utilisation</title>
        </Helmet>
        <Toolbar variant="dense" disableGutters sx={{ p: 1, pb: 0 }}>
          <Typography variant="subtitle1">Hourly Utilisation</Typography>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton
            title={showFilter ? 'Hide filter' : 'Show filter'}
            onClick={handleFilterToggle}
          >
            <FilterListIcon color={showFilter ? 'primary' : 'inherit'} />
          </IconButton>
          <IconButton
            title="Change view"
            aria-owns={viewMenuAnchor ? 'date-menu' : undefined}
            aria-haspopup="true"
            onClick={(event) => handleViewMenuOpen(event)}
          >
            <BarChartIcon />
          </IconButton>
          <Menu
            anchorEl={viewMenuAnchor}
            open={Boolean(viewMenuAnchor)}
            onClose={handleViewMenuClose}
          >
            <ListSubheader disableSticky>View as</ListSubheader>
            {chartTypes.map((value) => (
              <MenuItem
                key={value}
                value={value}
                selected={value === chartType}
                onClick={() => handlePercentOrHoursClick(value)}
              >
                {labels.get(value)}
              </MenuItem>
            ))}
            <ListSubheader disableSticky>Classify by</ListSubheader>
            {Object.keys(classificationSettings).map((value) => (
              <MenuItem
                key={value}
                value={value}
                selected={value === classifyBy}
                onClick={() => handleClassificationClick(value)}
              >
                {labels.get(value)}
              </MenuItem>
            ))}
          </Menu>
          <IconButton
            title="Download data"
            disabled={data.length === 0}
            onClick={handleDownloadClick}
          >
            <GetAppIcon />
          </IconButton>
        </Toolbar>
        <Collapse in={showFilter} timeout="auto">
          <Stack spacing={1.5} sx={{ flex: 1, p: 1.5 }}>
            {!useReducedResourceInformation && (
              <SelectMultiple
                disabled={isFiltering}
                label="Registration Number"
                placeholder="Select..."
                value={filterParams.filter.registrationNumber}
                labelValue
                onChange={(value) =>
                  handleFilterFieldChanged('registrationNumber', value)
                }
                suggestions={filterValues.registrationNumber.map((value) => ({
                  label: value,
                  value,
                }))}
              />
            )}
            <SelectMultiple
              disabled={isFiltering}
              label="Fleet Number"
              placeholder="Select..."
              value={filterParams.filter.fleetNumber}
              labelValue
              onChange={(value) =>
                handleFilterFieldChanged('fleetNumber', value)
              }
              suggestions={filterValues.fleetNumber.map((value) => ({
                label: value,
                value,
              }))}
            />
            {useReducedResourceInformation ? (
              <SelectMultiple
                disabled={isFiltering}
                label="Type"
                placeholder="Select..."
                value={filterParams.filter.type}
                labelValue
                onChange={(value) => handleFilterFieldChanged('type', value)}
                suggestions={filterValues.type.map((value) => ({
                  label: value,
                  value,
                }))}
              />
            ) : (
              <SelectMultiple
                disabled={isFiltering}
                label="Role"
                placeholder="Select..."
                value={filterParams.filter.role}
                labelValue
                onChange={(value) => handleFilterFieldChanged('role', value)}
                suggestions={filterValues.role.map((value) => ({
                  label: value,
                  value,
                }))}
              />
            )}
            {Object.entries(filterValues.areas).map((entry) => (
              <SelectMultiple
                key={entry[0]}
                disabled={isFiltering}
                label={getKeyLabel(entry[0])}
                placeholder="Select..."
                value={filterParams.filter.areas[entry[0]] || []}
                labelValue
                onChange={(value) => handleFilterFieldChanged(entry[0], value)}
                suggestions={entry[1].map((value) => ({
                  label: value,
                  value,
                }))}
              />
            ))}
            <ToggleListField
              disabled={isFiltering}
              input={{
                onChange: (value) => handleFilterFieldChanged('hour', value),
                value: filterParams.filter.hour,
              }}
              values={hours}
              label={'Hours'}
            />
            <ToggleListField
              disabled={isFiltering}
              input={{
                onChange: (value) => handleFilterFieldChanged('day', value),
                value: filterParams.filter.day,
              }}
              values={days}
              label={'Days'}
            />
          </Stack>
        </Collapse>
        <Paper sx={{ m: [0, 1], minWidth: 240, fontSize: 12 }}>
          <CardContent sx={{ p: 0, pt: 0.5 }}>
            <Box
              sx={{
                pl: 8,
                pr: 2,
                pb: 1,
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
              }}
            >
              {bars.map((bar) => (
                <Box
                  key={bar.name}
                  sx={{
                    p: 0.5,
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleLegendClick(bar.name)}
                >
                  <Avatar
                    sx={{
                      width: 12,
                      height: 12,
                      mr: 0.5,
                      bgcolor: !hiddenBars.includes(bar.name) && bar.colour,
                    }}
                  >
                    <Fragment />
                  </Avatar>
                  <Typography variant="caption">{bar.name}</Typography>
                </Box>
              ))}
            </Box>
            <ResponsiveContainer width="99%" height={600}>
              <BarChart
                data={data}
                margin={{ top: 0, right: 16, left: 0, bottom: 32 }}
                barCategoryGap={4}
              >
                <XAxis dataKey="Hour">
                  <Label value="Hour" offset={36} position="bottom" />
                </XAxis>
                <YAxis>
                  <Label
                    value={labels.get(chartType)}
                    angle={-90}
                    position="left"
                    offset={-24}
                  />
                </YAxis>
                <Tooltip
                  cursor={false}
                  content={
                    <CustomTooltip
                      unit={chartType === 'percentage' ? '%' : 'minutes'}
                    />
                  }
                />
                {data.length > 0 && <Brush dataKey="Hour" height={24} />}
                {bars.map((bar) => (
                  <Bar
                    key={bar.name}
                    dataKey={bar.name}
                    stackId="a"
                    fill={bar.colour}
                    hide={hiddenBars.includes(bar.name)}
                  />
                ))}
              </BarChart>
            </ResponsiveContainer>
          </CardContent>
        </Paper>
      </Box>
    </Container>
  );
}
