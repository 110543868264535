import { Box, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import Container from './Container';

export default function Home() {
  return (
    <Container title="Not Found">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: 'calc(100vh - 96px)',
          flex: 1,
        }}
      >
        <Helmet>
          <title>IR3 | Not Found</title>
        </Helmet>
        <Box sx={{ mt: 8, mb: 2 }}>
          <Box sx={{ maxWidth: 600, margin: [0, 'auto'], p: [8, 0, 6] }}>
            <Typography
              variant="h6"
              align="center"
              color="textSecondary"
              component="p"
            >
              Whoops! I'm not sure how you got here but there's nothing here.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
