import Dexie from 'dexie';

const db = new Dexie('ir3');
db.version(1).stores({
  vehicleHourlyUtilisation: '++id',
  vehicleDailyUtilisation: '++id',
  vehicleInBaseTime: '++id',
  personHourlyActivity: '++id',
  personDailyActivity: '++id',
  drivingScores: '++id',
});
db.version(2).stores({
  vehiclesInLocations: '++id',
});
db.version(3).stores({
  parameters: '&store',
});
db.version(4).stores({
  live: '&dataType',
});

export default db;

export async function fetchCachedData(report) {
  const data = await db[report]?.toArray();

  if (!data) {
    return [];
  }

  // if the first entry is an array and there are no
  // other entries, the first entry is the data blob
  // blob in the first array position is much faster
  const blob = data[0] || [];
  if (Array.isArray(blob) && !data[1]) {
    return blob;
  } else {
    // otherwise return data (not just the first entry)
    return data;
  }
}
