import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FETCH_LIVE_INCIDENT } from '../../../actions/types';
import { usePrevious } from '../../../hooks';

function useLazyLoadIncident(id) {
  const [loading, setLoading] = useState(false);
  const incident = useSelector((state) => state.live.incidents[id]);

  const dispatch = useDispatch();
  const prevId = usePrevious(id);
  useEffect(() => {
    if (prevId !== id && !incident && !loading) {
      setLoading(true);

      dispatch({
        type: FETCH_LIVE_INCIDENT,
        payload: { id, loading: true },
      });
    }
  }, [dispatch, id, prevId, incident, loading]);

  let placeholder = undefined;
  if (!incident || incident.loading) {
    placeholder = {
      id: id,
      primary: '[Loading...]',
    };
  } else if (incident.unavailable) {
    incident.primary = '[Unavailable]';
  } else {
    incident.primary = incident?.type?.name;
  }

  return placeholder || incident;
}

export default useLazyLoadIncident;

export function incidentTitle(incident) {
  if (incident.reference !== undefined && incident.date !== undefined) {
    return `Incident ${incident.reference}, ${incident.date
      .split('-')
      .reverse()
      .join('/')}`;
  }

  return incident.number;
}
