import { useState } from 'react';
import { AppBar, Box, Tab, Tabs } from '@mui/material';
import Container from './IndividualContainer';
import { Helmet } from 'react-helmet-async';
import IndividualTrips from './IndividualTrips';
import IndividualSummary from './IndividualSummary';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Box>
  );
}

export default function IndividualDriving() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container title="Driving" noShadow>
      <Helmet>
        <title>Driving</title>
      </Helmet>
      <Box sx={{ flexGrow: 1, bgcolor: 'background.paper' }}>
        <AppBar position="static" title="Driving">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="driving"
            variant="fullWidth"
          >
            <Tab label="Summary" />
            <Tab label="Trips" />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <IndividualSummary />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <IndividualTrips />
        </TabPanel>
      </Box>
    </Container>
  );
}
