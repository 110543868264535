import { TextField as MuiTextField } from '@mui/material';

export default function TextField({ input, meta, ignoreTouched, ...props }) {
  return (
    <MuiTextField
      size="small"
      helperText={
        (ignoreTouched || meta.touched) && (meta.error || meta.submitError)
      }
      error={(ignoreTouched || meta.touched) && meta.invalid}
      {...input}
      {...props}
    />
  );
}
