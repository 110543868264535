import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

export default function CustomTooltip({
  active,
  payload,
  label,
  unit,
  height,
  labelFormatter,
  captureMouseMove,
}) {
  if (active) {
    return (
      <div onMouseMove={(e) => captureMouseMove && e.stopPropagation()}>
        <Paper style={height ? { height, overflowY: 'auto' } : null}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell colSpan={2}>
                  {labelFormatter ? labelFormatter(label) : label}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(payload || []).reverse().map((row) => (
                <TableRow key={row.name}>
                  <TableCell>
                    <Typography
                      variant="caption"
                      style={{ color: row.stroke || row.fill }}
                    >
                      {row.name}
                    </Typography>
                  </TableCell>
                  <TableCell
                    style={{
                      color: row.stroke || row.fill,
                      textAlign: 'right',
                    }}
                  >
                    <Typography variant="caption">{`${row.value}${
                      unit ? ` ${unit}` : ''
                    }`}</Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </div>
    );
  }

  return null;
}
