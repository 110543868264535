import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { Field, GeometryField, required } from '.';
import { BoundaryMap } from '../controls';

export default function BoundaryField({ type }) {
  const location = useSelector((state) => state.locations.location, _.isEqual);
  const feature = useSelector((state) => state.features.feature, _.isEqual);

  function getBoundary(type) {
    switch (type) {
      case 'Perimeter':
        return (feature || {}).geometry;
      case 'Location':
        return (location || {}).boundary;
      default:
        return null;
    }
  }

  const boundary = getBoundary(type);

  return type === 'Custom' ? (
    <Field
      name="boundary"
      component={GeometryField}
      geoType="Polygon"
      validate={required}
    />
  ) : boundary ? (
    <BoundaryMap boundary={boundary} />
  ) : (
    <Fragment />
  );
}
