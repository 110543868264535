import _ from 'lodash';
import { TableRow, TableCell, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';

export function ItemRows(labelValuePairs) {
  return labelValuePairs.map((labelValuePair) => (
    <TableRow
      key={labelValuePair.label + labelValuePair.value}
      style={{ backgroundColor: labelValuePair.disabled ? grey[300] : '' }}
    >
      <TableCell>
        <Typography
          style={labelValuePair.labelStyle}
          variant="caption"
          color="textSecondary"
        >
          {labelValuePair.label}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography style={labelValuePair.valueStyle} variant="caption">
          {labelValuePair.value}
        </Typography>
      </TableCell>
    </TableRow>
  ));
}

export function toLabelAccessors(arr, labelKey, valueKey) {
  return (arr || []).map((a) => ({
    label: _.startCase(a[labelKey]),
    value: a[valueKey],
  }));
}

export function areasToLabelAccessors(areas) {
  return (areas || []).map((a) => ({
    label: _.startCase(a.type),
    value: a.name,
  }));
}
